<div class="page-wrapper">
  <div class="page-header">
    <div class="title"> {{ "USERS.TITLE" | translate}} </div>
  </div>

  <div class="users-table" *ngIf="(users$ |async) as users">
    <table mat-table [dataSource]="users">

      <ng-container matColumnDef="firstName">
        <th mat-header-cell *matHeaderCellDef>{{ 'ADMIN.USERS_LIST.FIRST_NAME' | translate }}</th>
        <td mat-cell *matCellDef="let element"> {{element.firstName}} </td>
      </ng-container>

      <ng-container matColumnDef="lastName">
        <th mat-header-cell *matHeaderCellDef>{{ 'ADMIN.USERS_LIST.LAST_NAME' | translate }}</th>
        <td mat-cell *matCellDef="let element"> {{element.lastName}} </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>{{ 'ADMIN.USERS_LIST.EMAIL' | translate }}</th>
        <td mat-cell *matCellDef="let element"> {{element.email}} </td>
      </ng-container>

      <ng-container matColumnDef="role">
        <th mat-header-cell *matHeaderCellDef>{{ 'ADMIN.USERS_LIST.ROLE' | translate }}</th>
        <td mat-cell *matCellDef="let element"> {{element.role}} </td>
      </ng-container>

      <ng-container matColumnDef="language">
        <th mat-header-cell *matHeaderCellDef>{{ 'ADMIN.USERS_LIST.LANGUAGE' | translate }}</th>
        <td mat-cell *matCellDef="let element"> {{element.language ? element.language : '-'}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let user; columns: displayedColumns;" (click)="openUserProjects(user)"></tr>
    </table>
  </div>


  <div class="paginator-wrapper">
    <mat-paginator [length]="totalElements$ | async" [pageSize]="size" [pageSizeOptions]="[5, 10, 25]">
    </mat-paginator>
  </div>
</div>