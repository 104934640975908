import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-cookie-banner',
  templateUrl: './cookie-banner.component.html',
  styleUrls: ['./cookie-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CookieBannerComponent {
  constructor(private _dialogRef: MatDialogRef<CookieBannerComponent>) {}

  public setCookieStatus(status: string): void {
    localStorage.setItem('cookie', status);
    this._dialogRef.close(status);
  }
}
