import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AUTH_CONTEXT } from 'src/services/context-tokens/intercept.context-token';

import { TranslateResponse } from '../app/user-flow/interfaces/translate';

@Injectable({
  providedIn: 'root',
})
export class GoogleTranslateService {
  constructor(private _http: HttpClient) {}

  public translate(originalContents: string[], targetLanguageCode: string): Observable<TranslateResponse> {
    const body = {
      originalContents,
      targetLanguageCode,
      sourceLanguageCode: 'DE',
    };
    return this._http.post<TranslateResponse>(environment.API_URL + '3rdparty/google-translate', body, { context: AUTH_CONTEXT });
  }
}
