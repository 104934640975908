import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, filter, map, Observable, of, switchMap, take, tap } from 'rxjs';

import { ExportService } from './export.service';
import { ReconstructionStateService } from './reconstruction-state.service';
import { IVersion } from '../../../models/project';
import { ISaveVersion, IUpdateVersion } from '../../../services/model/project-version';
import { ProjectsVersionsHttpService } from '../../../services/projects-versions-http.service';
import { ResourcesHttpService } from '../../../services/resources-http.service';
import { Notification } from '../../notifications/model/notification';
import { NotificationsService } from '../../notifications/services/notifications.service';
import { setOverlayLoadingSpinner, setUnsavedChanges } from '../../store/actions/shared.actions';
import {
  activeProjectFile,
  activeProjectHandledBackgroundFile,
  activeProjectId,
  activeProjectProposalData,
  activeProjectRender,
  activeProjectVersionId,
} from '../../store/selectors/shared.selector';

@Injectable()
export class VersionService {
  constructor(
    private _store: Store,
    private _reconstructionStateService: ReconstructionStateService,
    private _resourcesHttpService: ResourcesHttpService,
    private _exportService: ExportService,
    private _projectsVersionsHttpService: ProjectsVersionsHttpService,
    private _notificationsService: NotificationsService,
    private _translateService: TranslateService,
    private _router: Router
  ) {}

  private _showSuccessUpdateVersionNotification(): void {
    this._notificationsService.addNotification(
      new Notification({
        title: this._translateService.instant('NOTIFICATIONS.TITLE.SUCCESS'),
        text: this._translateService.instant('NOTIFICATIONS.MESSAGES.VERSION_UPDATED'),
        level: 'success',
        options: { timeout: 2 },
      })
    );
  }

  private _showSuccessSaveVersionNotification(): void {
    this._notificationsService.addNotification(
      new Notification({
        title: this._translateService.instant('NOTIFICATIONS.TITLE.SUCCESS'),
        text: this._translateService.instant('NOTIFICATIONS.MESSAGES.VERSION_SAVED'),
        level: 'success',
        options: { timeout: 2 },
      })
    );
  }

  public saveVersion(): Observable<{ projectId: string; version: IVersion }> {
    this._store.dispatch(setOverlayLoadingSpinner({ status: true }));

    return this._prepareSaveUpdateData(true).pipe(
      switchMap(({ data, id }) => {
        return this._projectsVersionsHttpService.saveVersion(data as ISaveVersion, id).pipe(map(version => ({ version, projectId: id })));
      }),
      tap(() => {
        this._store.dispatch(setOverlayLoadingSpinner({ status: false }));
        this._store.dispatch(setUnsavedChanges({ state: false }));
        this._showSuccessSaveVersionNotification();
      })
    );
  }

  public updateVersion(): Observable<IVersion> {
    this._store.dispatch(setOverlayLoadingSpinner({ status: true }));

    return this._prepareSaveUpdateData(false).pipe(
      switchMap(({ data }) => this._projectsVersionsHttpService.updateVersion(data as IUpdateVersion)),
      tap(() => {
        this._showSuccessUpdateVersionNotification();
        this._store.dispatch(setOverlayLoadingSpinner({ status: false }));
        this._store.dispatch(setUnsavedChanges({ state: false }));
      })
    );
  }

  private _prepareSaveUpdateData(isNew: boolean): Observable<{ data: ISaveVersion | IUpdateVersion; id: string }> {
    return this._store
      .select(activeProjectHandledBackgroundFile)
      .pipe(take(1))
      .pipe(
        switchMap((file: File) => {
          if (!file) return this._store.select(activeProjectFile).pipe(take(1));
          else return of(file);
        }),
        filter((file: File) => !!file),
        switchMap((file: File) => {
          return this._reconstructionStateService.getSaveData().pipe(map(data => ({ data, file })));
        }),
        switchMap(({ file, data }) => {
          const result1$ = this._resourcesHttpService.uploadResource(file);
          const result2$ = this._exportService.createSceneFile(data).pipe(
            switchMap((file: File) => {
              return this._resourcesHttpService.uploadResource(file);
            })
          );
          const stylefinder$ = this._store.select(activeProjectProposalData);
          const render$ = this._store.select(activeProjectRender);

          const projectId$ = this._store.select(activeProjectId);

          const dataArray$ = [result1$, result2$, stylefinder$, render$, projectId$];
          if (!isNew) {
            const versionId$ = this._store.select(activeProjectVersionId);
            dataArray$.push(versionId$);
          }

          return combineLatest(dataArray$).pipe(
            take(1),
            map((res: any) => {
              if (isNew) {
                const data: ISaveVersion = {
                  image: res[0].id,
                  model: res[1].id,
                  type: res[2].type,
                  style: res[2].style,
                  budget: res[2].budget,
                  name: res[2].versionName,
                };
                return { data, id: res[4] };
              } else {
                const data: IUpdateVersion = {
                  image: res[0].id,
                  model: res[1].id,
                  type: res[2].type,
                  style: res[2].style,
                  budget: res[2].budget,
                  id: res[5],
                };
                return { data, id: res[4] };
              }
            })
          );
        })
      );
  }
}
