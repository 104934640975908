import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';

import { Render } from '../../../../models/project';
import { loadRenders } from '../../../store/actions/shared.actions';

@Component({
  selector: 'app-download-button',
  templateUrl: './download-button.component.html',
  styleUrls: ['./download-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadButtonComponent {
  @Input() public renders: Render[];
  @Input() public projectId: string;
  @Input() public versionId?: string;

  public get isDownloadAvailable(): boolean {
    return !!this.renders.length;
  }

  public get downloadIconSrc(): string {
    return this.isDownloadAvailable ? './../../../../assets/icons/Gruppe 3154.svg' : './../../../../assets/icons/Gruppe 3258.svg';
  }

  constructor(private _store: Store) {}

  public click(): void {
    if (this.isDownloadAvailable) {
      this._store.dispatch(
        loadRenders({
          renders: this.renders,
          projectId: this.projectId,
          selectedVersionId: this.versionId,
        })
      );
    }
  }
}
