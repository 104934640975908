export interface UndoableState {
  past: any[];
  present: any;
  future: any[];
}

export interface CustomAction {
  name: string;
  data: any;
}
export enum UndoRedo {
  UNDO = 'undo',
  REDO = 'redo',
}

export interface UndoRedoState {
  undoActions: CustomAction[];
  redoActions: CustomAction[];
  current: {
    action: CustomAction;
    command: UndoRedo;
  };
}
