import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { activeProjectSrc } from 'src/app/store/selectors/shared.selector';

import { InputType } from '../enum/input-types.enum';
import { Option } from '../interfaces/option';
import { ReconstructionStateService } from '../services/reconstruction-state.service';
import { RefreshNavigationService } from '../services/refresh-navigation.service';
import { RoomService } from '../services/room.service';

@UntilDestroy()
@Component({
  selector: 'app-room-reconstruct-preview',
  templateUrl: './room-reconstruct-preview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoomReconstructPreviewComponent implements OnInit {
  public options: Option[] = [
    {
      label: 'USER_FLOW.LABELS.YES',
      checked: true,
      value: '1',
      notAvailable: this._roomService.interiorResultInvalid$,
    },
    {
      label: 'USER_FLOW.LABELS.EDIT_ROOM',
      checked: false,
      value: '2',
    },
  ];

  public img$: Observable<string> = this._store.select(activeProjectSrc);
  public selected = new FormControl();
  public inputType: InputType = InputType.RADIO;

  constructor(
    private _router: Router,
    private _store: Store,
    private _reconstructionStateService: ReconstructionStateService,
    private _refreshNavigationService: RefreshNavigationService,
    private _roomService: RoomService
  ) {}

  public ngOnInit(): void {
    this._refreshNavigationService.refreshPageEvent$.pipe(untilDestroyed(this)).subscribe(() => this._router.navigate(['choose-file']));

    this._roomService.interiorResultInvalid$.pipe(untilDestroyed(this)).subscribe(resultInvalid => {
      this.options = [
        { ...this.options.at(0), checked: !resultInvalid },
        { ...this.options.at(1), checked: resultInvalid },
      ];
    });
  }

  public onChange(event): void {
    if (event.action === 'back') {
      this._router.navigate(['remove-preview']);
    } else {
      this._reconstructionStateService.save().subscribe(() => {
        if (event.value === '1') {
          this._router.navigate(['reconstruct-editor']);
        } else {
          this._router.navigate(['manual-reconstruct-editor']);
        }
      });
    }
  }
}
