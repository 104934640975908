import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class FileSizeService {
  public showError: Subject<boolean> = new Subject();

  constructor() {}

  public fileSizeLess10MB(file: File): boolean {
    if (file.size / 1000000 > 11) {
      this.showError.next(true);
      return false;
    }
    this.showError.next(false);
    return true;
  }
}
