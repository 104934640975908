import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { ChoosePlanComponent } from './components/choose-plan/choose-plan.component';
import { ConfirmRegisterComponent } from './components/confirm-register/confirm-register.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { FormFieldComponent } from './components/form-field/form-field.component';
import { InfoPageComponent } from './components/info-page/info-page.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { VerifyEmailComponent } from './components/verify-email/verify-email.component';
import { TRANSLATE_MODULE_CONFIG } from '../shared/configs/translate.config';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'change-password/:token', component: ResetPasswordComponent },
  { path: 'verify-register/:token', component: ConfirmRegisterComponent },
  { path: 'choose-plan', component: ChoosePlanComponent },
  { path: 'verify-email', component: VerifyEmailComponent },
];

@NgModule({
  declarations: [
    LoginComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    FormFieldComponent,
    ConfirmRegisterComponent,
    ChoosePlanComponent,
    VerifyEmailComponent,
    InfoPageComponent,
  ],
  exports: [FormFieldComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatFormFieldModule,
    FormsModule,
    MatInputModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatCardModule,
    TranslateModule.forChild(TRANSLATE_MODULE_CONFIG),
  ],
})
export class AuthModule {}
