import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { fabric } from 'fabric';
import { combineLatest, distinctUntilChanged, filter, map, Observable, switchMap, take, tap } from 'rxjs';
import { IdeaFurnitureMobileComponent } from 'src/app/shared/components/idea-furniture/idea-furniture-mobile/idea-furniture-mobile.component';
import { LeftPanelState } from 'src/app/shared/components/idea-furniture/left-panel-state.enum';
import { Plans } from 'src/app/shared/enums/plans.enum';
import { Roles } from 'src/app/shared/enums/roles.enum';
import { clearActiveIdea, loadIdeaSimilarFurniture, viewIdeaFurniture } from 'src/app/store/actions/ideas.actions';
import { activeIdea, ideaFurniture } from 'src/app/store/selectors/ideas.selectors';
import { userPlan, userRole } from 'src/app/store/selectors/shared.selector';
import { MAX_HEIGHT_MOBILE, MAX_WIDTH_MOBILE } from 'src/app/user-flow/const/windows-dimentions';
import { Resolution } from 'src/app/user-flow/export-settings/resolution.class';
import { DEVICES_RESOLUTIONS } from 'src/app/user-flow/export-settings/resolutions.const';
import { ResolutionService } from 'src/app/user-flow/services/resolution.service';

import { DetectedFurnitureState, Idea } from '../../interfaces/idea';

@UntilDestroy()
@Component({
  selector: 'app-idea-page',
  templateUrl: './idea-page.component.html',
  styleUrls: ['./idea-page.component.scss'],
})
export class IdeaPageComponent implements AfterViewInit {
  public idea$: Observable<Idea>;
  public leftPanelOpen = false;
  public leftPanelPage: LeftPanelState;
  private _src: string;
  private _width: number;
  private _height: number;
  private _finalAspectRatio: number;
  private _canvas: fabric.Canvas = new fabric.Canvas('editor', {
    backgroundColor: '#ccc',
    selectionColor: '#FC6E6E',
  });
  private _detectedFurniture: DetectedFurnitureState[];
  @ViewChild('pageContent', { static: false })
  private _pageContent: ElementRef;

  public isHighQualityDownloadDisabled$ = combineLatest(this._store.select(userPlan), this._store.select(userRole)).pipe(
    filter(([plan, role]) => !!plan && !!role),
    map(([plan, role]) => plan === Plans.FREE && role === Roles.USER),
    take(1)
  );

  public freeResolutions: Resolution[] = [];
  public payedResolution: Resolution[] = [];
  public resolutionControl = new FormControl<Resolution>(null);

  constructor(
    public resolutionService: ResolutionService,
    private _store: Store,
    private _router: Router,
    private _dialog: MatDialog,
    @Inject(DOCUMENT) private _document: Document
  ) {}

  public ngAfterViewInit(): void {
    this._canvas = new fabric.Canvas('editor', {
      selection: false,
      hoverCursor: 'pointer',
    });
    this._canvas.clear();
    this.idea$ = this._store.select(activeIdea);
    this._store
      .select(activeIdea)
      .pipe(
        distinctUntilChanged(),
        filter(idea => !!idea && !!idea.src && !!idea.blob),
        switchMap(res =>
          this._loadBgImage(res).pipe(
            tap(res => (this._src = res.src)),
            map(() => {
              return res.detectedFurniture.map(furniture => {
                const cropWidth = furniture.boundBoxX2 - furniture.boundBoxX1;
                const cropHeight = furniture.boundBoxY2 - furniture.boundBoxY1;
                const centerX = (cropWidth / 2 + furniture.boundBoxX1) * this._finalAspectRatio;
                const centerY = (cropHeight / 2 + furniture.boundBoxY1) * this._finalAspectRatio;
                return {
                  centerX,
                  centerY,
                  skus: furniture.skus,
                };
              });
            })
          )
        ),
        untilDestroyed(this)
      )
      .subscribe((data: DetectedFurnitureState[]) => {
        this._detectedFurniture = data;
        this._showDots(data);
        this._getResolutions();
      });

    if (this.resolutionService.isMobileResolution) {
      this._mobileSubscribeToSelectedMovingObjects();
    }
  }

  public back(): void {
    this._store.dispatch(clearActiveIdea());
    this._router.navigate(['result']);
  }

  public downloadImage(): void {
    const selectedResolution = this.resolutionControl.getRawValue();
    const exportCanvas = this._document.createElement('canvas');
    const image = this._document.createElement('img');
    image.src = this._src;
    image.onload = (): void => {
      const { width, height } = selectedResolution ?? image;
      const exportContext = exportCanvas.getContext('2d');
      exportCanvas.width = width;
      exportCanvas.height = height;
      exportContext.drawImage(image, 0, 0, width, height);
      const fileName = 'result.jpg';
      const a: HTMLAnchorElement = this._document.createElement('a');
      a.href = exportCanvas.toDataURL();
      a.download = fileName;
      a.click();
    };
  }

  public openLeftPanel(status: boolean): void {
    this.leftPanelOpen = status;
  }

  private _showDots(detectedFurniture: DetectedFurnitureState[]): void {
    detectedFurniture.forEach(res => {
      fabric.loadSVGFromURL('../../../assets/icons/Ellipse 455.svg', (objects, options) => {
        const obj = fabric.util.groupSVGElements(objects, options);
        obj.set({
          top: res.centerY,
          left: res.centerX,
          width: 20,
          height: 20,
          originX: 'center',
          originY: 'center',
          lockMovementX: true,
          lockMovementY: true,
          hasControls: false,
          hasBorders: false,
        });
        obj.on('mousedown', e => {
          const index = this._detectedFurniture.findIndex(
            el => el.centerX.toFixed(2) === e.target.left.toFixed(2) && el.centerY.toFixed(2) === e.target.top.toFixed(2)
          );
          if (index !== -1) {
            const ids = this._detectedFurniture[index].skus;
            this._store.dispatch(loadIdeaSimilarFurniture({ ids: ids }));
            this.leftPanelOpen = true;
            this._store
              .select(ideaFurniture)
              .pipe(
                filter(data => !!data && !!data.length),
                untilDestroyed(this)
              )
              .subscribe(data => {
                if (data.length === 1) {
                  this.leftPanelPage = LeftPanelState.DETAILS_PANEL;
                  this._store.dispatch(viewIdeaFurniture({ data: data[0] }));
                } else {
                  this.leftPanelPage = LeftPanelState.FURNITURE_LIST;
                }
              });
          }
        });

        this._canvas.add(obj).renderAll();
      });
    });
  }

  private _loadBgImage(idea: Idea): Observable<{ file: File; src: string; width: number; height: number }> {
    return new Observable<{ file: File; src: string; width: number; height: number }>(subscriber => {
      const imageObj = new Image();
      imageObj.src = idea.src;
      imageObj.onload = (): void => {
        this._canvas.clear();
        const image = new fabric.Image(imageObj);
        [this._width, this._height, this._finalAspectRatio] = this._resizeImage(imageObj.width, imageObj.height);
        this._canvas.setHeight(this._height || 600);
        this._canvas.setWidth(this._width || 600);
        image.scale(this._finalAspectRatio);
        this._canvas.setBackgroundImage(image, this._canvas.renderAll.bind(this._canvas));
        subscriber.next({ file: new File([idea.blob], 'idea.png'), src: idea.src, width: this._width, height: this._height });
      };
    });
  }

  private _resizeImage(realWidth = 600, realHeight = 600): number[] {
    const height = this._pageContent?.nativeElement.clientHeight;
    const width = this._pageContent?.nativeElement.clientWidth;
    const widthAspectRatio = (this.resolutionService.isMobileResolution ? MAX_WIDTH_MOBILE : width - 500) / realWidth;
    const heightAspectRatio = (this.resolutionService.isMobileResolution ? MAX_HEIGHT_MOBILE : height) / realHeight;

    const finalAspectRatio = Math.min(widthAspectRatio, heightAspectRatio);
    const imageHeight = realHeight * finalAspectRatio;
    const imageWidth = realWidth * finalAspectRatio;

    return [imageWidth, imageHeight, finalAspectRatio];
  }

  private _mobileSubscribeToSelectedMovingObjects(): void {
    this._store
      .select(ideaFurniture)
      .pipe(
        filter(data => !!data && !!data.length),
        untilDestroyed(this)
      )
      .subscribe(data => {
        if (data.length === 1) {
          this._store.dispatch(viewIdeaFurniture({ data: data[0] }));
        }
        this._dialog.open(IdeaFurnitureMobileComponent, {
          position: { bottom: '0' },
          maxWidth: '100vw',
          maxHeight: '93vh',
          panelClass: 'mobile-dialog',
          data: { furnitureCount: data.length },
        });
      });
  }

  private _getResolutions(): void {
    const image = new Image();
    image.src = this._src;
    image.onload = (): void => {
      const originalResolution: Resolution = new Resolution(image.width, image.height, 'Original');
      const relatedResolutions = originalResolution.relateWith(DEVICES_RESOLUTIONS);
      this.freeResolutions = [relatedResolutions[relatedResolutions.length - 1]];
      this.payedResolution = [originalResolution, ...relatedResolutions.slice(0, -1)];
      this.resolutionControl.setValue(this.freeResolutions.at(0));
    };
  }
}
