<div class="page-container">
  <div class="header">
    <p class="title">{{ "AUTH.FORGOT_PASSWORD.TITLE" | translate}}</p>
  </div>
    <form [formGroup]="form" class="page-content">
      <p>
        <app-form-field
          name="email"
          label="AUTH.REGISTRATION_PAGE.EMAIL"
          type="text"
          [abstractControl]="form.get('email')"
        ></app-form-field>
      </p>
      <div>
        <button class="button" color="primary" type="submit" mat-raised-button (click)="submit()">{{ "AUTH.FORGOT_PASSWORD.SEND" | translate}}</button>
        <div class="create">
          <span>{{ "AUTH.REGISTRATION_PAGE.ACCOUNT_EXIST" | translate}}</span>
          <a class="link" routerLink="/login">{{ "AUTH.LOGIN_PAGE.LOGIN" | translate}}</a>
        </div>
      </div>
    </form>
</div>
