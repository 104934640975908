import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { updateActiveProject } from 'src/app/store/actions/shared.actions';
import { IVersion } from 'src/models/project';

@Injectable()
export class ImageFileParamsService {
  constructor(private _store: Store) {}

  public getImageParamsSrc(src: string, availableWidth: number, availableHeight: number): Observable<{ width: number; height: number }> {
    return new Observable(subscriber => {
      const image = new Image();
      image.src = src;
      image.onload = (): void => {
        const widthAspectRatio = availableWidth / image.width;
        const heightAspectRatio = availableHeight / image.height;
        const finalAspectRatio = Math.min(widthAspectRatio, heightAspectRatio);

        const width = image.width * finalAspectRatio;
        const height = image.height * finalAspectRatio;

        subscriber.next({
          width,
          height,
        });
      };
    });
  }

  public getImageParams(
    blob: File | Blob,
    filename: string,
    availableWidth?: number,
    availableHeight?: number
  ): Observable<{ width: number; height: number; file: File; src: string }> {
    return new Observable(subscriber => {
      const src = URL.createObjectURL(blob);
      const image = new Image();
      image.src = src;
      image.onload = (): void => {
        let widthAspectRatio;
        let heightAspectRatio;
        widthAspectRatio = availableWidth / image.width;
        heightAspectRatio = availableHeight / image.height;
        if (!widthAspectRatio) {
          widthAspectRatio = document.defaultView.innerWidth / image.width;
        }
        if (!heightAspectRatio) {
          heightAspectRatio = document.defaultView.innerHeight / image.height;
        }

        const finalAspectRatio = Math.min(widthAspectRatio, heightAspectRatio);

        const width = image.width * finalAspectRatio;
        const height = image.height * finalAspectRatio;
        const file = new File([blob], filename);

        subscriber.next({
          width,
          height,
          file,
          src,
        });
      };
    });
  }

  public updateVersionWithImage(
    blob: File | Blob,
    filename: string,
    availableWidth: number,
    availableHeight: number
  ): Observable<IVersion> {
    return new Observable<IVersion>(subscriber => {
      this.getImageParams(blob, filename, availableWidth, availableHeight).subscribe(params => {
        this._store.dispatch(updateActiveProject({ projectInfo: params }));
        subscriber.next(params);
      });
    });
  }
}
