<div class="page-container">

    <ng-container [ngSwitch]="type">
        <ng-container *ngSwitchCase="types.DEFAULT">
            <app-create-idea [type]="type"></app-create-idea>
        </ng-container>
        <ng-container *ngSwitchCase="types.STYLESWAP">
            <app-style-switch></app-style-switch>
        </ng-container>
        <ng-container *ngSwitchCase="types.NO_FURNITURE">
            <app-create-idea [type]="type"></app-create-idea>
        </ng-container>
    </ng-container>
    
</div>