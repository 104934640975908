import { createFeatureSelector, createSelector } from '@ngrx/store';

import { AdminState } from '../admin.state';

export const selectImageState = createFeatureSelector<AdminState>('admin');

export const users = createSelector(selectImageState, state => state.users?.content);

export const usersTotalElements = createSelector(selectImageState, state => state.users?.totalElements);

export const userProjects = createSelector(selectImageState, state => state.userProjects?.content);

export const userProjectsTotalElements = createSelector(selectImageState, state => state.userProjects?.totalElements);

export const userProjectsPageSize = createSelector(selectImageState, state => state.userProjects?.size);

export const userProjectsPageIndex = createSelector(selectImageState, state => state.userProjects?.number);

export const activeUserEmail = createSelector(selectImageState, state => state.activeUserEmail);
