import { Injectable } from '@angular/core';

@Injectable()
export class ResolutionService {
  public get isMobileResolution(): boolean {
    return this._isMobileResolution;
  }

  public get windowHeight(): number {
    return this._windowHeight;
  }

  private _isMobileResolution = false;
  private _windowHeight: number;

  public init(window: Window): void {
    this._updateState(window);
  }

  public subscribeToWindowResize(window: Window): void {
    window.addEventListener('resize', () => {
      this._updateState(window);
    });
  }

  private _updateState(window: Window): void {
    this._isMobileResolution = window.innerWidth < 600;
    this._windowHeight = window.innerHeight;
  }

  public unsubscribeFromWindowResize(window: Window): void {
    window.removeEventListener('resize', () => this._updateState(window));
  }
}
