import { ImageActions, RenderActions, SharedActions } from '../store/action-types';

export interface UndoableOperation {
  type: string;
  hint?: string;
}

export const UNDOABLE_OPERATIONS: { type: string; hint?: string }[] = [
  { hint: 'make new proposal', type: SharedActions.REFRESH_PROPOSAL },
  { hint: 'refresh type', type: SharedActions.REFRESH_TYPE },
  { hint: 'set room height', type: RenderActions.SET_ROOM_HEIGHT },
  { hint: 'add light point', type: RenderActions.ADD_LIGHT_POINT },
  { hint: 'delete light point', type: RenderActions.DELETE_LIGHT_POINT },
  { hint: 'move light point', type: RenderActions.MOVE_LIGHT_POINT },
  { hint: 'add model', type: RenderActions.ADD_MODEL },
  { hint: 'delete model', type: RenderActions.DELETE_MODEL },
  { hint: 'move model', type: RenderActions.MOVE_MODEL },
  { hint: 'add plane', type: RenderActions.ADD_PLANE },
  { hint: 'delete plane', type: RenderActions.DELETE_PLANE },
  { hint: 'add wall corner', type: RenderActions.ADD_WALL_CORNER },
  { hint: 'delete wall corner', type: RenderActions.DELETE_WALL_CORNER },
  { hint: 'move wall corner', type: RenderActions.MOVE_WALL_CORNER },
  { hint: 'move model mouse', type: RenderActions.MOVE_MODEL_MOUSE },
  { hint: 'delete models', type: RenderActions.DELETE_MODELS },
  { hint: 'rename model', type: RenderActions.RENAME_MODEL },
  { hint: 'lock model', type: RenderActions.LOCK_MODEL },
  { hint: 'change color', type: RenderActions.CHANGE_WALL_COLOR },
  { hint: 'clear image', type: ImageActions.CLEAR_IMAGE },
];
