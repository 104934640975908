import { createReducer, on } from '@ngrx/store';

import { AdminActions } from '../action-types';
import { initialAdminState } from '../admin.state';

export const adminReducer = createReducer(
  initialAdminState,
  on(AdminActions.getUsersSuccess, (state, action) => {
    return {
      ...state,
      users: action.users,
    };
  }),
  on(AdminActions.getUserProjectsSuccess, (state, action) => {
    return {
      ...state,
      userProjects: action.projects,
    };
  }),
  on(AdminActions.userProjectSrcLoaded, (state, action) => {
    const projects = state.userProjects;
    const projectsUpdateContent = projects.content.map(project => ({
      ...project,
      src: action.data.id === project.id ? action.data.src : project.src,
    }));

    return {
      ...state,
      userProjects: {
        ...projects,
        content: projectsUpdateContent,
      },
    };
  }),
  on(AdminActions.removeProject, (state, action) => {
    const projects = state.userProjects;
    const projectsUpdateContent = projects.content.filter(project => project.id !== action.id);

    return {
      ...state,
      userProjects: {
        ...projects,
        content: projectsUpdateContent,
      },
    };
  }),
  on(AdminActions.getUserProjects, (state, action) => {
    return {
      ...state,
      activeUserEmail: action.email,
    };
  }),
  on(AdminActions.clearAdminState, state => {
    return {
      ...state,
      ...initialAdminState,
    };
  })
);
