<div class="page-container">
  <div class="tabs-container">
      <p class="m-35 title">{{ "RESULTS.TITLE" | translate }}</p>
      <div class="tabs" *ngIf="!resolutionService.isMobileResolution;else select">
          <div 
              *ngFor="let tab of tabs" 
              (click)="selectTab(tab)" 
              class="tab" [ngClass]="{'active-tab': activeTab === tab}"
              aria-hidden="true"
          >
              <p class="r-18">{{ tab | tabName | translate}}</p>
          </div>
      </div>
  </div>
  <router-outlet></router-outlet>
</div>

<ng-template #select>
  <div class="tabs-select-container">
      <mat-form-field class="tabs-select" >
          <mat-select [(ngModel)]="activeTab" (selectionChange)="selectTab($event.value)">
              <mat-option *ngFor="let tab of tabs" [value]="tab">
                  <p class="m-18">{{ getTranslationForTab(tab) }}</p>
              </mat-option>
          </mat-select>
          <img 
              matSuffix 
              src="../../../../assets//icons/Gruppe 3096.svg"
              alt="arrow down"
          >
      </mat-form-field>
  </div>
</ng-template>

