import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Object3D, Vector3 } from 'three';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls';

import { CornersControlsConfig } from './model/config/corners-controlss-config.model';
import { moveWallCorner } from '../../store/actions/render.actions';

@Injectable()
export class CornersControlsService {
  private _config: CornersControlsConfig;
  private _cornersControls: TransformControls;

  constructor(private _store: Store) {}

  public configure(config: CornersControlsConfig): void {
    this._config = config;
  }

  public addCornersControls(movesCallback: (object: Object3D, start: Vector3) => void, refreshCallback: () => void): void {
    this._cornersControls = new TransformControls(this._config.camera, this._config.domElement);
    this._cornersControls.name = 'cornersControls';

    let start: Vector3;
    this._cornersControls.addEventListener('mouseDown', () => {
      start = new Vector3().copy(this._cornersControls.object.position);
    });
    this._cornersControls.addEventListener('mouseUp', () => {
      if (this._cornersControls?.object) {
        this._store.dispatch(
          moveWallCorner({
            data: {
              id: this._cornersControls.object.uuid,
              start,
              end: this._cornersControls.object.position,
            },
          })
        );

        const object = this._cornersControls.object;

        movesCallback(object, start);

        refreshCallback();
      }
    });

    this._config.scene.add(this._cornersControls);
  }

  public getIntersectionPart(): Object3D[] {
    return this._cornersControls?.children[0].children[0].children;
  }

  public detachCornersControls(): void {
    this._cornersControls?.detach();
  }

  public attach(object: Object3D): void {
    this._cornersControls?.attach(object);
  }
}
