import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { distinctUntilChanged, Observable, switchMap, take } from 'rxjs';
import { AuthService } from 'src/app/auth/services/auth.service';
import { Notification } from 'src/app/notifications/model/notification';
import { NotificationsService } from 'src/app/notifications/services/notifications.service';
import { IdeaTypes } from 'src/app/shared/enums/idea-type.enum';
import { Plans } from 'src/app/shared/enums/plans.enum';
import { Roles } from 'src/app/shared/enums/roles.enum';
import { BlobService } from 'src/app/shared/services/blob.service';
import { ImportantNotificationsService } from 'src/app/shared/services/important-notifications.service';
import { generationStyleSwitchStart, getIdeas } from 'src/app/store/actions/ideas.actions';
import { ideas } from 'src/app/store/selectors/ideas.selectors';
import { userName, userPlanRoleRemainingIdeas } from 'src/app/store/selectors/shared.selector';
import { ResolutionService } from 'src/app/user-flow/services/resolution.service';

import { STYLE_SWITCH_DEFAULT_IMAGES } from '../../const/style-switch-default-images.const';
import { IdeasService } from '../../services/ideas.service';

@UntilDestroy()
@Component({
  selector: 'app-style-switch',
  templateUrl: './style-switch.component.html',
  styleUrls: ['./style-switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StyleSwitchComponent implements OnInit {
  @ViewChild('roomImageFile', { static: true }) public roomFile: ElementRef;
  @ViewChild('styleImageFile', { static: true }) public styleFile: ElementRef;
  public dragAreaClassFirst = 'dragarea';
  public dragAreaClassSecond = 'dragarea';
  public incorrectTypeError = false;
  @HostListener('dragover', ['$event']) public onDragOver(event: any): void {
    event.target.id === 'roomImageFile' ? (this.dragAreaClassFirst = 'droparea') : (this.dragAreaClassSecond = 'droparea');
    event.preventDefault();
  }
  @HostListener('dragenter', ['$event']) public onDragEnter(event: any): void {
    event.target.id === 'roomImageFile' ? (this.dragAreaClassFirst = 'droparea') : (this.dragAreaClassSecond = 'droparea');
    event.preventDefault();
  }
  @HostListener('dragend', ['$event']) public onDragEnd(event: any): void {
    event.target.id === 'roomImageFile' ? (this.dragAreaClassFirst = 'dragarea') : (this.dragAreaClassSecond = 'dragarea');
    event.preventDefault();
  }
  @HostListener('dragleave', ['$event']) public onDragLeave(event: any): void {
    event.target.id === 'roomImageFile' ? (this.dragAreaClassFirst = 'dragarea') : (this.dragAreaClassSecond = 'dragarea');
    event.preventDefault();
  }
  @HostListener('drop', ['$event']) public onDrop(event: any): void {
    event.target.id === 'roomImageFile' ? (this.dragAreaClassFirst = 'dragarea') : (this.dragAreaClassSecond = 'dragarea');
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files) {
      this.incorrectTypeError = false;
      switch (event.target.id) {
        case 'roomImageFile': {
          this.userInputForm.get('roomImage').setValue(null);
          this.userInputForm.updateValueAndValidity();
          this._cdr.markForCheck();
          if (
            event.dataTransfer.files &&
            event.dataTransfer.files[0] &&
            this._ideasService._acceptableFormatCheck(event.dataTransfer.files[0])
          ) {
            this._setRoomImageFile(event.dataTransfer.files[0]);
          } else {
            this.incorrectTypeError = true;
          }
          break;
        }
        case 'styleImageFile': {
          this.userInputForm.get('styleImage').setValue(null);
          this.userInputForm.updateValueAndValidity();
          this._cdr.markForCheck();
          if (event.dataTransfer.files && this._ideasService._acceptableFormatCheck(event.dataTransfer.files[0])) {
            this._setStyleImageFile(event.dataTransfer.files[0]);
          } else {
            this.incorrectTypeError = true;
          }
          break;
        }
      }
    }
  }
  public userInputForm: FormGroup = new FormGroup({
    roomImage: new FormControl(null, Validators.required),
    styleImage: new FormControl(null, Validators.required),
  });
  public userPlanRoleRemainingIdeas$: Observable<{ plan: Plans; role: string; remainingIdeas: number }> =
    this._store.select(userPlanRoleRemainingIdeas);
  public roomImageBlob: Blob;
  public roomImageFileName: string;
  public styleImageBlob: Blob;
  public styleImageFileName: string;
  public user$: Observable<string> = this._store.select(userName);
  public defaultImages: string[] = STYLE_SWITCH_DEFAULT_IMAGES;
  public images: string[];
  public activeImage: string;
  public index = 0;

  public get isLoggedIn(): boolean {
    return this._authService.isLoggedIn();
  }

  public get isLoggedOut(): boolean {
    return this._authService.isLoggedOut();
  }

  private _clearBlob = false;

  constructor(
    public resolutionService: ResolutionService,
    private _authService: AuthService,
    private _store: Store,
    private _cdr: ChangeDetectorRef,
    private _ideasService: IdeasService,
    private _blobService: BlobService,
    private _importantNotificationsService: ImportantNotificationsService,
    private _notificationsService: NotificationsService,
    private _translateService: TranslateService
  ) {}

  public ngOnInit(): void {
    this._store.dispatch(getIdeas({ pageIndex: 0, pageSize: 3, ideaType: IdeaTypes.STYLESWAP }));
    this._setFormValues();

    this._store
      .select(ideas)
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe(data => {
        this.images = data && data.length && data[0].resizedSrc ? data.map(item => item.resizedSrc) : this.defaultImages;
        this.activeImage = this.images[0];
        this.index = 0;
        this._cdr.detectChanges();
      });
  }

  private _setFormValues(): void {
    const roomFileName = localStorage.getItem('room_image_name');
    const roomImageBase64 = localStorage.getItem('room_image');
    const styleFileName = localStorage.getItem('style_image_name');
    const styleImageBase64 = localStorage.getItem('style_image');

    if (roomImageBase64) {
      this._blobService
        .toBlob(`data:image/jpeg;base64,${roomImageBase64}`)
        .pipe(untilDestroyed(this))
        .subscribe((blob: Blob) => {
          const file = new File([blob], roomFileName);

          this._setRoomImageFile(file);
        });
    }
    if (styleImageBase64) {
      this._blobService
        .toBlob(`data:image/jpeg;base64,${styleImageBase64}`)
        .pipe(untilDestroyed(this))
        .subscribe((blob: Blob) => {
          const file = new File([blob], styleFileName);

          this._setStyleImageFile(file);
        });
    }
  }

  private _setRoomImageFile(file: File): void {
    this.userPlanRoleRemainingIdeas$.pipe(take(1), untilDestroyed(this)).subscribe(data => {
      if (data && data.plan === Plans.FREE && data.role === Roles.USER && data.remainingIdeas <= 0) {
        this._importantNotificationsService.limitNotification();
        this.roomFile.nativeElement.value = null;
      } else {
        this.userInputForm.get('roomImage').setValue('name');
        this.userInputForm.updateValueAndValidity();
        this._cdr.markForCheck();
        this._ideasService
          .convertFromHeicOrHeif(file)
          .pipe(
            switchMap(file => this._ideasService.resize(file, 'room_image', 'room_image_name')),
            untilDestroyed(this)
          )
          .subscribe(result => {
            this.roomImageBlob = result.blob;
            this.roomImageFileName = result.filename;
          });
      }
    });
  }

  private _setStyleImageFile(file: File): void {
    this.userPlanRoleRemainingIdeas$.pipe(take(1), untilDestroyed(this)).subscribe(data => {
      if (data && data.plan === Plans.FREE && data.role === Roles.USER && data.remainingIdeas <= 0) {
        this._importantNotificationsService.limitNotification();
        this.styleFile.nativeElement.value = null;
      } else {
        this.userInputForm.get('styleImage').setValue('name');
        this.userInputForm.updateValueAndValidity();
        this._cdr.markForCheck();
        this._ideasService
          .convertFromHeicOrHeif(file)
          .pipe(
            switchMap(file => this._ideasService.resize(file, 'style_image', 'style_image_name')),
            untilDestroyed(this)
          )
          .subscribe(result => {
            this.styleImageBlob = result.blob;
            this.styleImageFileName = result.filename;
          });
      }
    });
  }

  public onSubmit(): void {
    if (this.userInputForm.invalid) {
      this._notificationsService.addNotification(
        new Notification({
          title: this._translateService.instant('NOTIFICATIONS.TITLE.WARNING'),
          text: this._translateService.instant('NOTIFICATIONS.MESSAGES.ADD_IMAGE'),
          level: 'warning',
          options: { timeout: 2 },
        })
      );
    } else {
      this._store.dispatch(
        generationStyleSwitchStart({
          roomImage: new File([this.roomImageBlob], this.roomImageFileName),
          styleImage: new File([this.styleImageBlob], this.styleImageFileName),
        })
      );
      this._clearForm();
    }
  }

  private _clearForm(): void {
    localStorage.removeItem('room_image_name');
    localStorage.removeItem('room_image');
    localStorage.removeItem('style_image_name');
    localStorage.removeItem('style_image');
    this.userInputForm.reset();
  }

  public previous(): void {
    if (this.index - 1 >= 0) {
      this.activeImage = this.images[--this.index];
    }
  }

  public next(): void {
    if (this.index + 1 < this.images.length) {
      this.activeImage = this.images[++this.index];
    }
  }

  public onRoomImageFileChange(event): void {
    //this.incorrectTypeError = false;
    this.userInputForm.get('roomImage').setValue(null);
    this.userInputForm.updateValueAndValidity();

    //this._cdr.markForCheck();

    const selectedfile = (event.target as HTMLInputElement).files || ({} as FileList);
    if (selectedfile.length > 0) {
      this._setRoomImageFile(selectedfile[0]);
    }
  }

  public onStyleImageFileChange(event): void {
    //this.incorrectTypeError = false;
    this.userInputForm.get('styleImage').setValue(null);
    this.userInputForm.updateValueAndValidity();

    //this._cdr.markForCheck();

    const selectedfile = (event.target as HTMLInputElement).files || ({} as FileList);
    if (selectedfile.length > 0) {
      this._setStyleImageFile(selectedfile[0]);
    }
  }
}
