import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, filter, map, Observable, of, switchMap, tap } from 'rxjs';
import { OriginalImageSizeService } from 'src/app/user-flow/services/original-image-size.service';
import { Render, SingleRenderResult, SingleRenderResultType } from 'src/models/project';
import { ProjectsVersionsHttpService } from 'src/services/projects-versions-http.service';
import { ResourcesHttpService } from 'src/services/resources-http.service';

import { SharedActions } from '../action-types';
import { setOverlayLoadingSpinner, setUnsavedChanges, versionRendersLoaded } from '../actions/shared.actions';

@Injectable()
export class ActiveProjectEffects {
  // to map dots correctly need outputWidth and outputHeight of each rendering attempt
  // since rendering now happens to be exactly in the selected resolution, we can get params for each rendering
  public loadVersionRenders$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SharedActions.loadRenders),
      tap(() => this._store.dispatch(setOverlayLoadingSpinner({ status: true }))),
      filter(action => !!action.renders),
      switchMap(action => {
        return this._projectsVersionsHttpService.getVersions(action.projectId).pipe(
          map(versionsData => ({
            versions: action.selectedVersionId ? versionsData.content.filter(v => v.id === action.selectedVersionId) : versionsData.content,
          }))
        );
      }),
      switchMap(action => {
        const allRendersMetadata = action.versions
          .map(version => ({
            renders: version.renders,
            versionName: version.name,
          }))
          .flat();

        const renders$: Observable<{
          render: Render;
          versionName: string;
          outputWidth: number;
          outputHeight: number;
          regularRenderBlob: Blob;
        }>[] = [];

        allRendersMetadata.forEach(renderVersionMetadata => {
          renderVersionMetadata.renders.forEach(render => {
            renders$.push(
              this._resourcesHttpService.loadResource(render.image).pipe(
                switchMap(blob => {
                  return this._imageService.getImageSize(blob).pipe(
                    map(data => {
                      return {
                        regularRenderBlob: blob,
                        render: render,
                        outputWidth: data.width,
                        outputHeight: data.height,
                        versionName: renderVersionMetadata.versionName,
                      };
                    })
                  );
                })
              )
            );
          });
        });
        return combineLatest(renders$);
      }),
      switchMap(data => {
        const singleRenders$: Observable<SingleRenderResult>[] = [];
        data.forEach(renderMetadata => {
          if (renderMetadata.render.improvedImage) {
            singleRenders$.push(
              this._resourcesHttpService.loadResource(renderMetadata.render.improvedImage).pipe(
                map(blob => ({
                  name: renderMetadata.versionName,
                  type: SingleRenderResultType.IMPROVED,
                  renderDate: renderMetadata.render.creationDate,
                  blob,
                  detectedFurniture: renderMetadata.render.imageDetectedFurniture ?? [],
                  targetRenderHeight: renderMetadata.outputHeight,
                  targetRenderWidth: renderMetadata.outputWidth,
                }))
              )
            );
          }

          singleRenders$.push(
            of({
              name: renderMetadata.versionName,
              type: SingleRenderResultType.REGULAR,
              renderDate: renderMetadata.render.creationDate,
              blob: renderMetadata.regularRenderBlob,
              detectedFurniture: renderMetadata.render.imageDetectedFurniture ?? [],
              targetRenderHeight: renderMetadata.outputHeight,
              targetRenderWidth: renderMetadata.outputWidth,
            })
          );

          if (renderMetadata.render.alternativeIdea) {
            singleRenders$.push(
              this._resourcesHttpService.loadResource(renderMetadata.render.alternativeIdea).pipe(
                map(blob => ({
                  name: renderMetadata.versionName,
                  type: SingleRenderResultType.ALTERNATIVE,
                  renderDate: renderMetadata.render.creationDate,
                  blob,
                  detectedFurniture: renderMetadata.render.alternativeIdeaDetectedFurniture,
                }))
              )
            );
          }
        });

        return combineLatest(singleRenders$);
      }),
      switchMap(data => {
        const imageFiles$ = data.map(item => {
          return new Observable<SingleRenderResult>(subscriber => {
            const src = URL.createObjectURL(item.blob);
            subscriber.next({
              src,
              ...item,
            });
            subscriber.complete();
          });
        });
        return imageFiles$.length > 0 ? combineLatest(imageFiles$).pipe(map(data => ({ data }))) : of([]).pipe(map(() => ({ data: [] })));
      }),
      map(({ data }) => {
        this._store.dispatch(setOverlayLoadingSpinner({ status: false }));
        this._store.dispatch(setUnsavedChanges({ state: true }));

        return versionRendersLoaded({
          rendersResult: data,
        });
      }),
      tap(() => this._router.navigate(['product-page/3d-project']))
    )
  );

  constructor(
    private _actions$: Actions,
    private _projectsVersionsHttpService: ProjectsVersionsHttpService,
    private _resourcesHttpService: ResourcesHttpService,
    private _imageService: OriginalImageSizeService,
    private _store: Store,
    private _router: Router
  ) {}
}
