import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { Notification } from 'src/app/notifications/model/notification';
import { NotificationsService } from 'src/app/notifications/services/notifications.service';

import { RegisterForm } from '../../model/register.model';
import { AuthService } from '../../services/auth.service';

@UntilDestroy()
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  public form: FormGroup<RegisterForm> = new FormGroup<RegisterForm>(
    {
      email: new FormControl(null, [Validators.required, Validators.email]),
      firstName: new FormControl(null, [Validators.required]),
      lastName: new FormControl(null, [Validators.required]),
      password: new FormControl(null, [Validators.required]),
      repeatPassword: new FormControl(null, [Validators.required]),
    },
    { validators: this._checkPasswords() }
  );

  constructor(
    private _authService: AuthService,
    private _router: Router,
    private _notificationsService: NotificationsService,
    private _translateService: TranslateService
  ) {}

  public ngOnInit(): void {
    this.form.statusChanges.pipe(untilDestroyed(this)).subscribe(() => {
      const repeatPasswordControl = this.form.get('repeatPassword');
      const oldErrors = repeatPasswordControl.errors;
      if (this.form.getError('notSame')) {
        repeatPasswordControl.setErrors({ ...oldErrors, notSame: true }, { emitEvent: false });
      } else {
        if (oldErrors && oldErrors['notSame']) {
          delete oldErrors['notSame'];
        }
        repeatPasswordControl.setErrors(oldErrors, { emitEvent: false });
      }
    });
  }

  public submit(): void {
    this.form.updateValueAndValidity();
    if (this.form.valid) {
      const formValue = this.form.getRawValue();
      const dto = { ...formValue };
      delete dto.repeatPassword;
      this._authService
        .register(dto, localStorage.getItem('lang').toLocaleUpperCase())
        .pipe(untilDestroyed(this))
        .subscribe({
          next: () => {
            this._router.navigate(['verify-email'], { queryParams: { email: this.form.get('email').value } });
          },
          error: (error: HttpErrorResponse) => {
            this._notificationsService.addNotification(
              new Notification({
                title: this._translateService.instant('NOTIFICATIONS.TITLE.ERROR'),
                text: error.status === 409 ? this._translateService.instant('NOTIFICATIONS.MESSAGES.USER_EXIST') : error.error['detail'],
                level: 'error',
                options: { timeout: 2 },
              })
            );
          },
        });
    }
  }

  public toLogIn(): void {
    this._router.navigate(['login']);
  }

  private _checkPasswords(): ValidatorFn {
    return (group: AbstractControl): ValidationErrors | null => {
      const password: string = group.get('password').value;
      const confirm: string = group.get('repeatPassword').value;
      return password === confirm ? null : { notSame: true };
    };
  }
}
