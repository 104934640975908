import { createAction, props } from '@ngrx/store';
import { IList } from 'src/app/shared/interfaces/list';
import { IProject } from 'src/models/project';

import { User } from '../interfaces/user';

export const GET_USERS = '[Admin] get users';
export const GET_USERS_SUCCESS = '[Admin] get users success';
export const GET_USERS_PROJECTS = '[Admin] get user projects';
export const GET_USERS_PROJECTS_SUCCESS = '[Admin] get user projects success';
export const USERS_PROJECT_SRC_LOADED = '[Admin] user project src loaded';
export const REMOVE_PROJECT = '[Admin] remove project';
export const REMOVE_PROJECT_SUCCESS = '[Admin] remove project success';
export const CLEAR_ADMIN_STATE = '[App Component] clear admin state';

export const getUsers = createAction(GET_USERS, props<{ pageIndex: number; pageSize: number }>());

export const getUsersSuccess = createAction(GET_USERS_SUCCESS, props<{ users: IList<User> }>());

export const getUserProjects = createAction(
  GET_USERS_PROJECTS,
  props<{ email: string; pageIndex: number; pageSize: number; name: string }>()
);

export const getUserProjectsSuccess = createAction(GET_USERS_PROJECTS_SUCCESS, props<{ projects: IList<IProject> }>());

export const userProjectSrcLoaded = createAction(USERS_PROJECT_SRC_LOADED, props<{ data: { id: string; src: string } }>());

export const removeProject = createAction(REMOVE_PROJECT, props<{ id: string }>());

export const removeProjectSuccess = createAction(REMOVE_PROJECT_SUCCESS, props<{ id: string }>());

export const clearAdminState = createAction(CLEAR_ADMIN_STATE);
