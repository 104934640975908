<p class="r-16"><b>{{ 'USER_FLOW.CATALOG.TITLE' | translate}}</b></p>
<mat-form-field class="catalog-search">
  <mat-label class="r-16">{{ "USER_FLOW.CATALOG.SEARCH" | translate}}</mat-label>
  <mat-icon matPrefix>search</mat-icon>
  <input
    type="text"
    matInput
    [formControl]="type"
    [matAutocomplete]="auto"
    (focusin)="searchStart()"
    (focusout)="searchEnd()"
  >
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn.bind(this)">
    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
      {{ option.viewValue | translate }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
<div class="catalog-content">
  <div 
    class="article-item" 
    [draggable]="option.draggable"
    [ngClass]="{'dragarea': option.draggable  && dragAreaClass, 'droparea': option.draggable  && dropAreaClass }"
    (click)="openCategory(option)" 
    *ngFor="let option of filteredOptions | async; let i = index"
    aria-hidden="true"
  >
    <div class="image-wrapper" *ngIf="option.image">
      <img 
        [src]="option.image | safe: 'url'"
        alt="image"
      />
    </div>
    <div class="article-description">
      <div class="article-text r-14">{{ option.viewValue | translate }}</div>
    </div>
  </div>
</div>
