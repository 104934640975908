<mat-dialog-content>
  <p class="title m-30">{{ "USER_FLOW.HAS_UNSAVED_CHANGES_DIALOG.TITLE" | translate}}</p>
  <button class="cross" mat-icon-button aria-label="close dialog" (click)="close(null)">
    <mat-icon>close</mat-icon>
  </button>
</mat-dialog-content>
<mat-dialog-actions class="buttons-container">
  <button class="button button-gray m-20" mat-raised-button (click)="close(false)">{{'BUTTONS.DONT_SAVE' | translate}}</button>
  <button class="button m-20" mat-raised-button color="primary" (click)="close(true)">{{'BUTTONS.SAVE' | translate}}</button>
</mat-dialog-actions>

