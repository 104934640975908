import * as Hull from 'hull.js';
import { Quaternion, Shape, Triangle, Vector3 } from 'three';

export function getHorizontalHullPoints(points: Vector3[]): Vector3[] {
  const horizontalPoints = points.map(point => [point.x, point.z]);
  const y = points[0].y;
  const hull = Hull(horizontalPoints, 0.1);
  return hull.map(hullPoint => new Vector3(hullPoint[0], y, hullPoint[1]));
}

export function getXYQuaternion(points: Vector3[]): { xy: Quaternion; back: Quaternion } {
  const noColinear = selectThreeNonColinearPoints(points);
  const triangle = new Triangle(...noColinear);
  const normal = new Vector3();
  triangle.getNormal(normal);

  const baseNormal = new Vector3(0, 0, 1);
  const quaternion = new Quaternion().setFromUnitVectors(normal, baseNormal);
  const quaternionBack = new Quaternion().setFromUnitVectors(baseNormal, normal);

  return { xy: quaternion, back: quaternionBack };
}

export function getXYShape(points: Vector3[]): {
  baseShape: Shape;
  quaternionXY: Quaternion;
  quaternionBack: Quaternion;
  outlinePoints: Vector3[];
  z: number;
} {
  const quaternion = getXYQuaternion(points);

  const tempPoints: Vector3[] = [];
  points.map(p => tempPoints.push(p.clone().applyQuaternion(quaternion.xy)));
  let shapePoints: Vector3[] = [];
  const result = convertToConcaveSurface(tempPoints.slice());
  const baseShape = result.baseShape;
  shapePoints = result.shapePoints;
  const outlinePoints = shapePoints.slice().map(point => point.clone().applyQuaternion(quaternion.back));
  const z = shapePoints[0].z;

  return {
    baseShape,
    quaternionXY: quaternion.xy,
    quaternionBack: quaternion.back,
    outlinePoints,
    z,
  };
}

export function selectThreeNonColinearPoints(points: Vector3[]): Vector3[] {
  if (points.length <= 3) {
    return points;
  }

  for (let i = 0; i < points.length - 2; i++) {
    for (let j = i + 1; j < points.length - 1; j++) {
      for (let k = j + 1; k < points.length; k++) {
        const point_i = points[i].clone();
        const point_j = points[j].clone();
        const point_k = points[k].clone();

        const vector_ji = new Vector3().subVectors(point_j, point_i);
        const vector_ki = new Vector3().subVectors(point_k, point_i);

        if (vector_ji.cross(vector_ki).length() !== 0) {
          return [point_i, point_j, point_k];
        }
      }
    }
  }

  return points;
}

export function convertToConcaveSurface(points: Vector3[]): { shapePoints: Vector3[]; baseShape: Shape } {
  const shapePoints = sortPoints(points.map(p => p.clone()));

  const baseShape = new Shape();

  baseShape.moveTo(shapePoints[0].x, shapePoints[0].y);

  for (let i = 1; i < shapePoints.length; i++) {
    baseShape.lineTo(shapePoints[i].x, shapePoints[i].y);
  }

  baseShape.lineTo(shapePoints[0].x, shapePoints[0].y);
  return { shapePoints, baseShape };
}

export function sortPoints(points: Vector3[]): Vector3[] {
  const relativeCenter = new Vector3();

  for (const point of points) {
    relativeCenter.add(point);
  }

  relativeCenter.divideScalar(points.length);

  points.sort((a, b) => {
    const angleA = Math.atan2(a.y - relativeCenter.y, a.x - relativeCenter.x);
    const angleB = Math.atan2(b.y - relativeCenter.y, b.x - relativeCenter.x);

    return angleA - angleB;
  });

  return points;
}

export function isPointInsidePolygon(point: Vector3, polygon: Vector3[]): boolean {
  let isInside = false;
  const numPoints = polygon.length;

  for (let i = 0, j = numPoints - 1; i < numPoints; j = i++) {
    const xi = polygon[i].x,
      yi = polygon[i].y;
    const xj = polygon[j].x,
      yj = polygon[j].y;

    const intersect = yi > point.y != yj > point.y && point.x < ((xj - xi) * (point.y - yi)) / (yj - yi) + xi;
    if (intersect) isInside = !isInside;
  }

  return isInside;
}
