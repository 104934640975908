import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { AUTH_CONTEXT } from '../../../services/context-tokens/intercept.context-token';
import { RequestDto } from '../request/request-form';

@Injectable()
export class RequestHttpService {
  constructor(private _http: HttpClient) {}

  public sendVersionOffer(dto: RequestDto, id: string): Observable<any> {
    return this._http.post(environment.API_URL + `projects/versions/${id}/offer`, dto, { context: AUTH_CONTEXT });
  }

  public sendProjectOffer(dto: RequestDto, id: string): Observable<any> {
    return this._http.post(environment.API_URL + `projects/${id}/offer`, dto, { context: AUTH_CONTEXT });
  }

  public sendOffer(dto: RequestDto): Observable<any> {
    return this._http.post(environment.API_URL + `offer`, dto, { context: AUTH_CONTEXT });
  }

  public sendOfferWithoutAuthContext(dto: RequestDto): Observable<any> {
    return this._http.post(environment.API_URL + 'offer', dto);
  }
}
