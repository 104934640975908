import { ChangeDetectionStrategy, Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { clearFurnitureDetails } from 'src/app/store/actions/shared.actions';

import { CatalogDetailsComponent } from '../catalog/catalog-details/catalog-details.component';
import { LeftPanelState } from '../catalog/left-panel-container/left-panel-state.enum';
import { SceneDetailsComponent } from '../catalog/scene-details/scene-details.component';

export interface DialogData {
  from: LeftPanelState;
  uuid: string;
}

@Component({
  selector: 'app-details-dialog',
  templateUrl: './details-dialog.component.html',
  styleUrls: ['./details-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailsDialogComponent {
  @ViewChild('catalogDetails')
  private _catalogDetails: CatalogDetailsComponent;

  @ViewChild('sceneDetails')
  private _sceneDetails: SceneDetailsComponent;

  public leftPanelStates = LeftPanelState;

  constructor(
    private _dialogRef: MatDialogRef<DetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private _store: Store
  ) {}

  public tryClose(): void {
    const shouldCloseDialog = !(!!this._catalogDetails?.selectedVariant !== !!this._sceneDetails?.selectedVariant);

    if (shouldCloseDialog) {
      // this._store.dispatch(clearFurnitureDetails());
      this._dialogRef.close();
    } else {
      this._sceneDetails?.backFromVariant(null);
      this._catalogDetails?.backFromVariant(null);
    }
  }

  public close(): void {
    this._store.dispatch(clearFurnitureDetails());
    this._dialogRef.close();
  }
}
