<div class="content">
  <div class="page-container" [ngClass]="{ 'hidden': showLoading$ | async  }">
    <div class="info-container proposal" [ngClass]="{'page-indent': !!(isCatalogOpen$ | async) === false &&resolutionService.isMobileResolution}">
      <div *ngIf="resolutionService.isMobileResolution">
        <img 
          class="back-arrow" 
          src="./../../../assets/icons/arrow-left-short.svg" 
          alt="Go back" 
          (click)="back()"
          aria-hidden="true"
        >
      </div>
      <div class="page-info">
        <div *ngIf="resolutionService.isMobileResolution">
          <div  class="actions-container-mobile">
            <button class="button button-secondary" mat-button (click)="openCatalog()">
              {{'BUTTONS.CATALOG' | translate}}
              <img 
                class="icon" 
                src="./../../../assets/icons/Gruppe 3096.svg"
                alt="arrow down"
              >
            </button>
            <button class="button button-secondary" mat-button (click)="showRender()"
                    [disabled]="photoRealisticDisabled$ | async">
              {{'BUTTONS.RESULT' | translate}}
            </button>
          </div>
          <div class="button-wrapper circle-button-wrapper refresh-button-wrapper">
            <button  class="circle-button" mat-mini-fab (click)="makeProposal()">
              <img class="refresh-button" src="./../../../assets/icons/adjust-proposal.png" alt="next">
            </button>
            <p class="label r-14 refresh-label">{{"USER_FLOW.LABELS.NEW_PROPOSAL" | translate}}</p>
          </div>
        </div>

        <ng-container *ngIf="resolutionService.isMobileResolution && (isCatalogOpen$ | async), then catalog"></ng-container>

        <div class="page-content" *ngIf="!resolutionService.isMobileResolution, else canvas">
          <div class="left-panel-container" >
            <div class="left-panel-buttons">
              <img 
                *ngIf="(mode$ | async) === mods.EDIT" 
                class="back-arrow right-button-indent"
                src="./../../../assets/icons/arrow-left-short.svg" 
                alt="Go back" 
                (click)="back()"
                aria-hidden="true"
              >
              <button class="button button-secondary catalog-button" mat-stroked-button (click)="openLeftPanel(!this.leftPanelOpen)">
                <div class="catalog-button-content">
                  <p class="r-24 catalog-title">{{'BUTTONS.CATALOG' | translate}}</p>
                  <img 
                    class="icon" 
                    src="./../../../assets/icons/Gruppe 3096.svg"
                    alt="arrow down"
                  >
                </div>
              </button>
            </div>
            <app-left-panel-container [leftPanelStateMobile]="leftPanelStateMobile" [ngClass]="{'show-left-panel': leftPanelOpen, 'hide-left-panel': !leftPanelOpen}" #leftPanel (openLeftPanel)="openLeftPanel($event)" class="catalog"></app-left-panel-container>
          </div>
          <div class="scene" #scene>
            <div class="actions">
              <div class="action-buttons">
                <div class="button-wrapper circle-button-wrapper">
                  <button mat-icon-button (click)="deleteAllFurniture()">
                    <img mat-icon class="icon-circle" src="./../../../assets/icons/Frame 817 (1).svg" alt="delete All Furniture">
                  </button>
                  <p class="button-label r-14">{{"USER_FLOW.LABELS.DELETE_ALL" | translate}}</p>
                </div>
                <div class="button-wrapper circle-button-wrapper">
                  <button class="circle-button" mat-mini-fab [disabled]="undoDisable$ | async" (click)="backward()">
                    <img class="icon" src="./../../../assets/icons/previous-version-arrow.png" alt="previous">
                  </button>
                  <p  class="button-label r-14">{{"USER_FLOW.LABELS.BACK" | translate}}</p>
                </div>
                <div class="button-wrapper circle-button-wrapper">
                  <button class="circle-button" mat-mini-fab [disabled]="redoDisable$ | async" (click)="forward()">
                    <img class="icon" src="./../../../assets/icons/next-version-arrow.png" alt="next">
                  </button>
                  <p  class="button-label r-14">{{"USER_FLOW.LABELS.FORWARD" | translate}}</p>
                </div>
                <div class="button-wrapper circle-button-wrapper">
                  <button class="circle-button" mat-mini-fab (click)="makeProposal()">
                    <img class="icon" src="./../../../assets/icons/adjust-proposal.png" alt="next">
                  </button>
                  <p  class="button-label r-14">{{"USER_FLOW.LABELS.NEW_PROPOSAL" | translate}}</p>
                </div>
                <div class="button-wrapper circle-button-wrapper">
                  <button [matMenuTriggerFor]="afterMenu" class="circle-button" mat-mini-fab>
                    <img class="icon" src="./../../../assets/icons/save.svg" alt="next">
                  </button>
                  <mat-menu #afterMenu="matMenu" xPosition="after">
                    <button mat-menu-item class="menu-item" (click)="save()">
                      {{ "BUTTONS.SAVE" | translate}}
                    </button>
                    <button mat-menu-item (click)="saveAs()">
                      {{ "BUTTONS.SAVE_AS" | translate}}
                    </button>
                  </mat-menu>
                  <p class="button-label r-14">{{"USER_FLOW.LABELS.SAVE" | translate}}</p>
                </div>
              </div>
              <div class="action-buttons-right">
                <div class="admin-buttons">
                  <ng-container *ngIf="{ isSceneExport: (isSceneExportAvailable$ | async), isAdmin: (isUserAdmin$ | async)} as exportParams">
                    <div class="button-wrapper circle-button-wrapper export-button-wrapper" *ngIf="exportParams.isSceneExport || exportParams.isAdmin">
                      <button class="circle-button" mat-mini-fab  [matMenuTriggerFor]="menu">
                        <img class="icon" src="./../../../assets/icons/noun-ar-2300882.svg" alt="export scene">
                      </button>
                      <mat-menu #menu="matMenu" xPosition="after">
                        <button mat-menu-item class="menu-item" (click)="exportAsUSDZ()">{{"USER_FLOW.LABELS.EXPORT_IPHONE" | translate}}</button>
                        <button mat-menu-item class="menu-item" (click)="exportAsGlb()">{{"USER_FLOW.LABELS.EXPORT_ANDROID" | translate}}</button>
                        <button *ngIf="exportParams.isAdmin" mat-menu-item class="menu-item" (click)="exportAsAdminGltf()">{{"USER_FLOW.LABELS.EXPORT_ADMIN" | translate}}</button>
                      </mat-menu>
                      <p class="label r-14 button-label">{{"USER_FLOW.LABELS.EXPORT" | translate}}</p>
                    </div>
                    <div class="button-wrapper circle-button-wrapper export-button-wrapper" *ngIf="exportParams.isAdmin">
                      <button class="circle-button" mat-mini-fab (click)="exportSceneParams()">
                        <img class="icon" src="./../../../assets/icons/noun-ar-2300882.svg" alt="export scene">
                      </button>
                      <p class="label r-14 button-label">Export params</p>
                    </div>
                    <div class="button-wrapper circle-button-wrapper export-button-wrapper" *ngIf="exportParams.isAdmin">
                      <button class="circle-button" mat-mini-fab [matMenuTriggerFor]="renderMenu">
                        <img class="icon" src="./../../../assets/icons/noun-ar-2300882.svg" alt="render params">
                      </button>
                      <mat-menu #renderMenu xPosition="before" yPosition="below" [overlapTrigger]="false">
                        <form 
                          [formGroup]="renderSettingsForm" 
                          class="render-parameters-form" 
                          (click)="$event.stopPropagation()"
                          aria-hidden="true"
                        >
                          <mat-form-field class="select-render-samples">
                            <p class="select-label title no-margins r-18">Render samples</p>
                            <mat-select class="selector" formControlName="renderSamplesCount" (click)="$event.stopPropagation(); ($event.preventDefault)">
                              <mat-option *ngFor="let power of samplesOptions" [value]="power">{{ power }}</mat-option>
                            </mat-select>
                          </mat-form-field>
                          <mat-form-field class="select-shadow-samples">
                            <p class="select-label title no-margins r-18">Shadow samples</p>
                            <mat-select class="selector" formControlName="shadowSamplesCount" (click)="$event.stopPropagation(); ($event.preventDefault)">
                              <mat-option *ngFor="let power of samplesOptions" [value]="power">{{ power }}</mat-option>
                            </mat-select>
                          </mat-form-field>
                          <mat-form-field class="select-output-resolution">
                            <p class="select-label title no-margins r-18">Output resolution</p>
                            <mat-select class="selector" formControlName="resolution" (click)="$event.stopPropagation(); ($event.preventDefault)">
                              <mat-option *ngFor="let resolution of renderResolutionOptions" [value]="resolution">
                                {{ resolution.name }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </form>
                      </mat-menu>
                      <p class="label r-14 button-label">Render params</p>
                    </div>
                  </ng-container>
                </div>
                <button class="button button-secondary" mat-button (click)="showRender()"
                        [disabled]="photoRealisticDisabled$ | async">
                  {{'BUTTONS.RESULT' | translate}}
                </button>
                <button class="button" mat-raised-button color="primary" (click)="startRender()"
                        [disabled]="renderRealisticStateDisabled$ | async">
                  {{'BUTTONS.CREATE' | translate}}
                </button>
              </div>
            </div>
            <div class="render" #renderWrapper>
              <app-render
                #render
                [debugOutlineEnabled]="debugOutlineEnabled"
                [clickType]="clickType"
                [canScrollThrow]="false"
                (wallClick)="onWallClick($event)"
              >
              </app-render>
            </div>
            <app-accessories class="accessories"></app-accessories>
          </div>
        </div>

        <div class="actions-mobile"  *ngIf="!!(isCatalogOpen$ | async) === false">
          <div class="actions-block">
            <div class="button-wrapper circle-button-wrapper">
              <button  class="circle-button" mat-icon-button (click)="deleteAllFurniture()">
                <img mat-icon class="icon-mobile" src="./../../../assets/icons/Frame 817 (1).svg" alt="delete All Furniture">
              </button>
              <p class="label r-12">{{"USER_FLOW.LABELS.DELETE_ALL" | translate}}</p>
            </div>
            <div class="button-wrapper circle-button-wrapper">
              <button [matMenuTriggerFor]="afterMenu" class="circle-button" mat-mini-fab>
                <img class="icon" src="./../../../assets/icons/save.svg" alt="next">
              </button>
              <mat-menu #afterMenu="matMenu" xPosition="after">
                <button mat-menu-item class="menu-item" (click)="save()">
                  {{ "BUTTONS.SAVE" | translate}}
                </button>
                <button mat-menu-item (click)="saveAs()">
                  {{ "BUTTONS.SAVE_AS" | translate}}
                </button>
              </mat-menu>
              <p class="label r-12">{{"USER_FLOW.LABELS.SAVE" | translate}}</p>
            </div>
            <div class="button-wrapper circle-button-wrapper">
              <button class="circle-button" mat-icon-button (click)="openMovements()">
                <img *ngIf="!movingObjectSelected" class="icon-mobile" src="./../../../assets/icons/Gruppe 3276.svg" alt="previous">
                <img *ngIf="!!(movingMobileMenuActive$ | async) === false && movingObjectSelected" class="icon-mobile" src="./../../../assets/icons/Gruppe 3193.svg" alt="previous">
                <img *ngIf="(movingMobileMenuActive$ | async) && movingObjectSelected" class="icon-mobile" src="./../../../assets/icons/Gruppe 3193(1).svg" alt="previous">
              </button>
              <p class="label r-12">{{"USER_FLOW.LABELS.MOVEMENTS" | translate}}</p>
            </div>
            <div class="button-wrapper circle-button-wrapper"
                 [ngClass]="{'icon-button_active': clickType === CLICK_TYPES.MOVE_MODEL_MOUSE}">
              <button class="circle-button" mat-icon-button (click)="toggleFurnitureDetails()">
                <img *ngIf="clickType === CLICK_TYPES.MOVE_MODEL_MOUSE" class="icon-mobile" src="./../../../assets/icons/Gruppe 3062 (1).svg" alt="next">
                <img *ngIf="clickType === CLICK_TYPES.VIEW_FURNITURE_CLICK" class="icon-mobile" src="./../../../assets/icons/Gruppe 3187.svg" alt="next">
              </button>
              <p class="label r-12">{{"USER_FLOW.LABELS.DETAILS" | translate}}</p>
            </div>
            <div class="button-wrapper circle-button-wrapper">
              <button class="circle-button" mat-mini-fab [disabled]="undoDisable$ | async" (click)="backward()">
                <img class="icon" src="./../../../assets/icons/previous-version-arrow.png" alt="previous">
              </button>
              <p class="label r-12">{{"USER_FLOW.LABELS.BACK" | translate}}</p>
            </div>
            <div class="button-wrapper circle-button-wrapper">
              <button class="circle-button" mat-mini-fab [disabled]="redoDisable$ | async" (click)="forward()">
                <img class="icon" src="./../../../assets/icons/next-version-arrow.png" alt="next">
              </button>
              <p class="label r-12">{{"USER_FLOW.LABELS.FORWARD" | translate}}</p>
            </div>
          </div>
          <div>
            <button class="button" mat-raised-button color="primary" (click)="startRender()"
                    [disabled]="renderRealisticStateDisabled$ | async">
              {{'BUTTONS.CREATE' | translate}}
            </button>
          </div>

        </div>
        <app-accessories *ngIf="resolutionService.isMobileResolution"></app-accessories>
      </div>
    </div>
  </div>
  <div *ngIf="showLoading$ | async" class="c-spinner__container">
    <span class="loader"></span>
    <div class="text m-25">{{ loaderText$ | async | translate }}</div>
  </div>
</div>


  <ng-template #canvas>
    <div [ngClass]="{'hidden': (isCatalogOpen$ | async)}">
      <app-render class="render"
        #render
        [clickType]="clickType"
        [canScrollThrow]="false"
        (wallClick)="onWallClick($event)"
      >
      </app-render>
    </div>
  </ng-template>

  <ng-template #catalog>
    <app-left-panel-container (openLeftPanel)="openLeftPanel($event)" [leftPanelStateMobile]="leftPanelStateMobile"
            class="catalog"></app-left-panel-container>
  </ng-template>
