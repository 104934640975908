import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DataStoreService } from 'src/services/data-store.service';

import { Notification } from './notifications/model/notification';
import { NotificationsService } from './notifications/services/notifications.service';
import { ImportantNotificationsService } from './shared/services/important-notifications.service';
import { clearImageState } from './store/actions/image.actions';
import { clearSelectedProject } from './store/actions/projects.actions';
import { clearSharedState } from './store/actions/shared.actions';
import { errorHandler } from './store/selectors/shared.selector';
import { clearUndoRedoState } from './undo-redo/undo-redo.actions';
import { LightObjectsService } from './user-flow/services/light-objects.service';
import { MovingObjectsService } from './user-flow/services/moving-objects.service';
import { PhotorealisticRenderService } from './user-flow/services/photorealistic-render.service';
import { PlanesService } from './user-flow/services/planes.service';
import { ResolutionService } from './user-flow/services/resolution.service';
import { RoomParamsService } from './user-flow/services/room-params.service';
import { RoomService } from './user-flow/services/room.service';
import { GoogleAnalyticsService } from '../services/google-analytics.service';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  public error$: Observable<HttpErrorResponse> = this._store.select(errorHandler);
  constructor(
    private _router: Router,
    private _store: Store,
    private _notificationsService: NotificationsService,
    private _translateService: TranslateService,
    private _movingObjectsService: MovingObjectsService,
    private _lightObjectsService: LightObjectsService,
    private _dataStoreService: DataStoreService,
    private _photorealisticRenderService: PhotorealisticRenderService,
    private _planesService: PlanesService,
    private _roomParamsService: RoomParamsService,
    private _roomService: RoomService,
    private _resolutionService: ResolutionService,
    @Inject(DOCUMENT) private _document: Document,
    private readonly googleAnalyticsService: GoogleAnalyticsService,
    private _importantNotificationsService: ImportantNotificationsService
  ) {
    this._resolutionService.init(this._document.defaultView);
    this._resolutionService.subscribeToWindowResize(this._document.defaultView);

    this._router.events.subscribe(event => {
      window.scrollTo(0, 0);
      if (event instanceof NavigationEnd) {
        if (event.url.includes('result') && !event.url.includes('user-projects')) {
          this._store.dispatch(clearSharedState());
          this._store.dispatch(clearImageState());
          this._store.dispatch(clearUndoRedoState());
          this._store.dispatch(clearSelectedProject());
          this._clearServices();
        }

        if (event.url.includes('choose-file')) {
          this._store.dispatch(clearSharedState());
          this._store.dispatch(clearImageState());
          this._store.dispatch(clearUndoRedoState());
          this._store.dispatch(clearSelectedProject());
          this._clearServices();
        }

        if (event.url.includes('admin') || event.url.includes('user-projects')) {
          this._store.dispatch(clearSelectedProject());
        }
      }
    });
  }

  public ngOnInit(): void {
    if (environment.production && localStorage.getItem('cookie') === 'allow') {
      this.googleAnalyticsService.initialize();
      if (!this._document.getElementById('ms_clarity')) {
        const head = this._document.getElementsByTagName('head')[0];
        const script = this._document.createElement('script');
        script.type = 'text/javascript';
        script.id = 'ms_clarity';
        script.innerHTML = `(function(c,l,a,r,i,t,y){ c[a]=c[a]||function(){(c[a].q=c[a].q||[])
        .push(arguments)}; t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
         y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y); })(window, document, "clarity", "script", "jbvppqaaw7");`;
        head.insertBefore(script, head.firstChild);
      }
    }
    this.error$.pipe(untilDestroyed(this)).subscribe(err => {
      if (err) {
        if (err.status == 402) {
          this._importantNotificationsService.limitNotification();
        } else {
          this._notificationsService.addNotification(
            new Notification({
              title: this._translateService.instant('NOTIFICATIONS.TITLE.ERROR'),
              text: this._translateService.instant('NOTIFICATIONS.MESSAGES.ERROR_MESSAGE'),
              level: 'error',
              options: { timeout: 2 },
            })
          );
        }
      }
    });
  }

  public onActivate(): void {
    this._document.body.scrollTop = 0;
  }

  private _clearServices(): void {
    this._movingObjectsService.setInitialState();
    this._photorealisticRenderService.setInitialState();
    this._planesService.setInitialState();
    this._roomParamsService.setInitialState();
    this._roomService.setInitialState();
    this._dataStoreService.clearAll();
  }

  public ngOnDestroy(): void {
    this._resolutionService.unsubscribeFromWindowResize(this._document.defaultView);
  }
}
