import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs';
import { Notification } from 'src/app/notifications/model/notification';
import { NotificationsService } from 'src/app/notifications/services/notifications.service';
import { ResolutionService } from 'src/app/user-flow/services/resolution.service';

import { AuthService } from '../../services/auth.service';

@UntilDestroy()
@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VerifyEmailComponent implements OnInit {
  public email: string;

  constructor(
    public resolutionService: ResolutionService,
    private _authService: AuthService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _notificationsService: NotificationsService,
    private _translateService: TranslateService
  ) {}

  public ngOnInit(): void {
    this._activatedRoute.queryParamMap.pipe(map((params: ParamMap) => params.get('email'))).subscribe(email => (this.email = email));
  }

  public resendConfirmation(): void {
    this._authService
      .resend(this.email, localStorage.getItem('lang').toLocaleUpperCase())
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this._notificationsService.addNotification(
          new Notification({
            title: this._translateService.instant('NOTIFICATIONS.TITLE.EMAIL_SEND'),
            text: this._translateService.instant('NOTIFICATIONS.MESSAGES.CHECK_EMAIL'),
            level: 'success',
            options: { timeout: 2 },
          })
        );
      });
  }

  public toLogIn(): void {
    this._router.navigate(['login']);
  }
}
