import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs';
import { IdeaTypes } from 'src/app/shared/enums/idea-type.enum';
import { ResolutionService } from 'src/app/user-flow/services/resolution.service';

@UntilDestroy()
@Component({
  selector: 'app-create-tabs',
  templateUrl: './create-tabs.component.html',
  styleUrls: ['./create-tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateTabsComponent implements OnInit {
  public readonly tabs = [IdeaTypes.DEFAULT, IdeaTypes.STYLESWAP, IdeaTypes.NO_FURNITURE, IdeaTypes.PROJECTS];
  public activeTab: IdeaTypes;
  public types = IdeaTypes;
  public userEmail: string;
  public selectedTab = new FormControl('DEFAULT');

  constructor(
    public resolutionService: ResolutionService,
    private _translateService: TranslateService,
    private _router: Router
  ) {}

  public ngOnInit(): void {
    this.selectTab(this.identifyActiveTabType());
    this._router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.selectTab(this.identifyActiveTabType());
      });
  }

  public identifyActiveTabType(): IdeaTypes {
    if (location.pathname.includes(IdeaTypes.STYLESWAP.toLocaleLowerCase())) {
      return IdeaTypes.STYLESWAP;
    } else if (location.pathname.includes(IdeaTypes.PROJECTS.toLocaleLowerCase())) {
      return IdeaTypes.PROJECTS;
    } else if (location.pathname.includes('no-furniture')) {
      return IdeaTypes.NO_FURNITURE;
    } else {
      return IdeaTypes.DEFAULT;
    }
  }

  public selectTab(type: IdeaTypes): void {
    if (this.activeTab !== type || this.resolutionService.isMobileResolution) {
      this.activeTab = type;
      const route = this.activeTab === IdeaTypes.NO_FURNITURE ? 'no-furniture' : `${type.toLocaleLowerCase()}`;
      this._router.navigate(['create', `${route}`]);
    }
  }

  public getTranslationForTab(type: IdeaTypes): string {
    return this._translateService.instant(`SHARED.TABS.${type}`);
  }
}
