import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatTreeModule } from '@angular/material/tree';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { EffectsModule } from '@ngrx/effects';
import { TranslateModule } from '@ngx-translate/core';
import { ClickOutsideModule } from 'ng-click-outside';
import { SafePipeModule } from 'safe-pipe';
import { SharedEffects } from 'src/app/store/effects/shared-effects';

import { CookieBannerComponent } from './components/cookie-banner/cookie-banner.component';
import { DownloadButtonComponent } from './components/download-button/download-button.component';
import { FooterComponent } from './components/footer/footer.component';
import { DetailsPanelComponent } from './components/idea-furniture/details-panel/details-panel.component';
import { FurnitureListComponent } from './components/idea-furniture/furniture-list/furniture-list.component';
import { IdeaFurnitureContainerComponent } from './components/idea-furniture/idea-furniture-container/idea-furniture-container.component';
import { IdeaFurnitureMobileComponent } from './components/idea-furniture/idea-furniture-mobile/idea-furniture-mobile.component';
import { IdeaVariantDetailsComponent } from './components/idea-furniture/variant-details/idea-variant-details.component';
import { NoDataPageComponent } from './components/no-data-page/no-data-page.component';
import { ProductPageComponent } from './components/product-page/product-page.component';
import { ProjectHeaderComponent } from './components/project-header/project-header.component';
import { ProjectsListComponent } from './components/projects-list/projects-list.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { VersionsListComponent } from './components/versions-list/versions-list.component';
import { TRANSLATE_MODULE_CONFIG } from './configs/translate.config';
import { NotificationDialogComponent } from './dialogs/notification-dialog/notification-dialog.component';
import { LongPressDirective } from './directives/long-press.directive';
import { StylePaginatorDirective } from './directives/pagination.directive';
import { HasUnsavedChangesGuard } from './guards/has-unsaved-changes.guard';
import { BudgetPipe } from './pipes/budget.pipe';
import { ColorPipe } from './pipes/color.pipe';
import { LinkPipe } from './pipes/link.pipe';
import { MaterialPipe } from './pipes/material.pipe';
import { NamePipe } from './pipes/name.pipe';
import { SizePipe } from './pipes/size.pipe';
import { TabNamePipe } from './pipes/tab-name.pipe';
import { CookieService } from './services/cookie.service';
import { ImportantNotificationsService } from './services/important-notifications.service';
import { UserService } from './services/user.service';
import { NotificationsModule } from '../notifications/notifications.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forChild(TRANSLATE_MODULE_CONFIG),
    MatDialogModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatInputModule,
    MatExpansionModule,
    MatSliderModule,
    FontAwesomeModule,
    SafePipeModule,
    FormsModule,
    MatPaginatorModule,
    ReactiveFormsModule,
    MatDividerModule,
    MatSelectModule,
    ClickOutsideModule,
    EffectsModule.forFeature([SharedEffects]),
    NotificationsModule,
    MatTreeModule,
    RouterModule.forChild([]),
  ],
  declarations: [
    SpinnerComponent,
    ProjectHeaderComponent,
    NamePipe,
    ProjectsListComponent,
    DownloadButtonComponent,
    VersionsListComponent,
    FooterComponent,
    CookieBannerComponent,
    ProductPageComponent,
    IdeaFurnitureContainerComponent,
    FurnitureListComponent,
    DetailsPanelComponent,
    IdeaVariantDetailsComponent,
    IdeaFurnitureMobileComponent,
    ColorPipe,
    SizePipe,
    LinkPipe,
    MaterialPipe,
    StylePaginatorDirective,
    LongPressDirective,
    BudgetPipe,
    TabNamePipe,
    NotificationDialogComponent,
    NoDataPageComponent,
  ],
  providers: [UserService, CookieService, HasUnsavedChangesGuard, ImportantNotificationsService],
  exports: [
    SpinnerComponent,
    TranslateModule,
    ProjectHeaderComponent,
    ProjectsListComponent,
    DownloadButtonComponent,
    VersionsListComponent,
    FooterComponent,
    ProductPageComponent,
    IdeaFurnitureContainerComponent,
    FurnitureListComponent,
    DetailsPanelComponent,
    IdeaVariantDetailsComponent,
    IdeaFurnitureMobileComponent,
    ColorPipe,
    MaterialPipe,
    SizePipe,
    LinkPipe,
    StylePaginatorDirective,
    LongPressDirective,
    BudgetPipe,
    TabNamePipe,
    NotificationDialogComponent,
    NoDataPageComponent,
  ],
  entryComponents: [CookieBannerComponent, StylePaginatorDirective, NotificationDialogComponent],
})
export class SharedComponentsModule {}
