<div class="page-container" [ngClass]="{ 'choose-plan': choosePlan}">
  <div class="background">
    <div class="info-page">
      <p 
        class="info-page__title no-margin"
        [ngClass]="{'m-40': !resolutionService.isMobileResolution, 'm-30': resolutionService.isMobileResolution}"
      >{{ title }}</p>
      <p 
        class="info-page__message" 
        [ngClass]="{'r-20': !resolutionService.isMobileResolution, 'r-16': resolutionService.isMobileResolution}"
      >{{ message }}</p>
      <div class="info-page__actions">
        <div 
          class="buttons"
          [ngClass]="{'space-between': primaryButton && secondaryButton && !resolutionService.isMobileResolution}">
          <button 
            *ngIf="resolutionService.isMobileResolution" 
            class="button primary-button" 
            color="primary" 
            type="submit" 
            mat-raised-button 
            (click)="clickPrimaryButton()"
          >
            <p class="buttons__text">{{ primaryButton }}</p>
          </button>
          <button 
            *ngIf="secondaryButton" 
            class="button secondary-button"
            type="submit" 
            mat-raised-button 
            (click)="clickSecondaryButton()"
          >
            <p class="buttons__text">{{ secondaryButton }}</p>
          </button>
          <button 
            *ngIf="!resolutionService.isMobileResolution" 
            class="button resend-button" 
            color="primary" 
            type="submit" 
            mat-raised-button 
            (click)="clickPrimaryButton()"
          >
            <p class="buttons__text">{{ primaryButton }}</p>
          </button>
        </div>
        <p *ngIf="info" class="info-page__info r-14">{{ info }}</p>
      </div>
    </div>
  </div>
</div>
