<div *ngIf="(totalElements$ | async) > 0; else noData">
    <app-projects-list 
        [size]="size$ | async"
        [pageIndex]="pageIndex$ | async"
        [totalElements]="totalElements$ | async"
        [projects]="projects$ | async"
        [isAdminView]="true"
        (openProjectEvent)="handleOpen($event)"
        (removeProjectEvent)="handleRemove($event)"
        (updateProjects)="getProjects($event)"
        >
    </app-projects-list>
</div>

<ng-template #noData>
    <app-no-data-page
        message="PROJECTS.NO_DATA"
        [buttonDisabled]="true"
    >
    </app-no-data-page>
</ng-template>

