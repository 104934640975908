import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { Idea } from 'src/app/ideas/interfaces/idea';
import { IdeaTypes } from 'src/app/shared/enums/idea-type.enum';
import { Mode } from 'src/app/shared/enums/mode.enum';
import { RoomType } from 'src/app/user-flow/enum/room-type.enum';
import { StylefinderItem, StylefinderResponse } from 'src/app/user-flow/interfaces/stylefinder';
import { IStyle } from 'src/app/user-flow/interfaces/styles';
import { RequestDto } from 'src/app/user-flow/request/request-form';
import { IProjectAdditionalInfo, IVersion, Render, SingleRenderResult } from 'src/models/project';
import { ProposalState } from 'src/models/proposal';
import { InteriorResult } from 'src/services/model/detect-floor-result.model';

import { Article, CatalogData, CatalogDataAccessory, ProposalResponse } from '../../../services/model/proposal';
import { LeftPanelState } from '../../user-flow/catalog/left-panel-container/left-panel-state.enum';
import { MovingObjectInfo, ReplaceTypeInfo } from '../../user-flow/services/model/dto/moving-object.model';

export const PROPOSAL_FLOW = '[Render Component] proposal flow';
export const EMPTY_ROOM_FLOW = '[Render Component] empty room flow';
export const CLEAR_ACTIVE_PROJECT = '[Render Component] clear Active Project';
export const CLEAR_ACTIVE_PROJECT_UPDATE_FLAG = '[Render Component] clear Active Project Update Flag';
export const CLEAR_STYLES = '[Room type] clear styles';
export const DETECT_OBJECTS = '[Render Component] detect Objects';
export const DETECT_OBJECTS_SUCCESS = '[Render Component] detect Objects Success';
export const LOAD_VERSION_RENDER = '[Render Component] load Version Render';
export const MAKE_PROPOSAL = '[Render Component] make Proposal';
export const REFRESH_PROPOSAL = '[Render Component] refresh Proposal';
export const MAKE_PROPOSAL_SUCCESS = '[Render Component] makeProposal Success';
export const RECONSTRUCT = '[Render Component] reconstruct';
export const RECONSTRUCT_SUCCESS = '[Render Component] reconstruct Success';
export const VERSION_RENDER_LOADED = '[Render Component] Version Render loaded';
export const UPDATE_ACTIVE_PROJECT_VERSION = '[Render Component] update active project version';
export const SAVE_PROJECT_SUCCESS = '[Create Page] save Project Success';
export const SET_ACTIVE_VERSION = '[Projects Page] set Active Version';
export const SET_OVERLAY_LOADING_ACTION = '[Shared State] set overlay loading spinner';
export const SET_RENDER_LOADING_ACTION = '[Shared State] set render loading spinner';
export const SET_RENDER_JOB_ID = '[Shared State] set render job id';
export const SET_MODE = '[Shared State] set Mode';
export const SET_RENDER = '[Render Component] set Render';
export const SET_UNSAVED_CHANGES = '[Shared State] set Unsaved Changes';
export const UPDATE_ACTIVE_PROJECT = '[Create Page] update Active Project';
export const UPDATE_ACTIVE_VERSION_INFO = '[Projects Page] set Active Version Info';
export const CATALOG_DATA = '[Render Component] get catalog data';
export const SET_CATALOG_DATA = '[Render Component] set catalog data';
export const UPDATE_CATALOG_DATA = '[Render Component] update catalog data';
export const VIEW_FURNITURE = '[Render Component] view furniture';
export const VIEW_FURNITURE_SUCCESS = '[Render Component] view furniture success';
export const USER_NAME = '[App Header] get user name';
export const CLEAR_NAME = '[App Header] clear user name';
export const USER_NAME_SUCCESS = '[App Header] get user name Success';
export const USER_FLOW_SUCCESS = '[Render Component] user flow Success';
export const LOAD_ARTICLE = '[Catalog Page] load article';
export const LOAD_ARTICLE_SUCCESS = '[Catalog Page] load article Success';
export const CLEAR_SHARED_STATE = '[Projects Page] clear shared state';
export const ADD_GLOBAL_ERROR = '[ERROR] Global error';
export const SEND_OFFER = '[Contact Form] send offer';
export const SEND_OFFER_SUCCESS = '[Contact Form] send offer success';
export const LOAD_RENDERS = '[Download button] load renders';
export const ADD_ACCESSORY = '[Render Component] add accessory';
export const SET_ACCESSORIES = '[Render Component] set accessories';
export const DELETE_ACCESSORY = '[Render Component] delete accessory';
export const REPLACE_ACCESSORY = '[Render Component] replace accessory';
export const LOCK_ACCESSORY = '[Render Component] lock accessory';
export const USER_FIRST_LOGIN = '[Subscriptions] user first login';
export const USER_FIRST_LOGIN_SUCCESS = '[Subscriptions] user first login success';
export const CLEAR_FURNITURE_DETAILS = '[Reconstruct Editor] clear furniture details';
export const UPDATE_CATALOG_STATE = '[Catalog] update catalog state';
export const SET_LEFT_PANEL_STATE = '[Shared State] set left panel state';
export const ADD_MOODIMAGE = '[User Flow] add moodimage';
export const GET_STYLEFINDER_IDEAS = '[User Flow] get stylefinder ideas';
export const GET_STYLEFINDER_IDEAS_SUCCESS = '[User Flow] get stylefinder ideas success';
export const GET_STYLEFINDER_IDEAS_SRC_SUCCESS = '[User Flow] get stylefinder ideas src success';
export const SET_STYLES = '[User Flow] set styles';
export const ADD_STYLE = '[User Flow] add style';
export const GET_PROPOSED_FURNITURE = '[User Flow] get proposed furniture';
export const GET_PROPOSED_FURNITURE_SUCCESS = '[User Flow] get proposed furniture success';
export const REPLACE_WITH_VARIANT = '[User Flow] replace with variant';
export const REPLACE_WITH_VARIANT_SUCCESS = '[User Flow] replace with variant success';
export const SET_PROPOSAL = '[User Flow] set proposal';
export const REPLACE_TYPE = '[User Flow] replace type';
export const REFRESH_TYPE = '[User Flow] refresh type';
export const REPLACE_TYPE_SUCCESS = '[User Flow] replace type success';
export const REPLACE_ACCESSORY_TYPE = '[User Flow] replace accessory type';
export const INCLUDE_SKU = '[User Flow] include sku';
export const EXCLUDE_SKU = '[User Flow] exclude sku';
export const LOCK_PROPOSAL_SKU = '[User Flow] lock proposal sku';
export const DUPLICATE_PROPOSAL_SKU = '[User Flow] duplicate proposal sku';
export const CLEAR_PROPOSAL = '[User Flow] clear proposal';
export const UPDATE_SINGLE_ARTICLE = '[User Flow] update single article';
export const GET_ALTERNATIVES = '[User Flow] get alternatives';
export const GET_ALTERNATIVES_SUCCESS = '[User Flow] get alternatives success';
export const CLEAR_ALTERNATIVES = '[User Flow] clear alternatives';
export const SET_IDEA_ACTIVE_PAGE = '[User Flow] set idea active page';
export const CLEAR_ADMIN_ACTIVE_PAGES = '[User Flow] clear admin active pages';
export const SET_ACTIVE_SKU = '[User Flow] set active sku';
export const CHANGE_LANGUAGE = `[User Flow] change language`;
export const CHANGE_LANGUAGE_SUCCESS = `[User Flow] change language success`;
export const SET_LANGUAGE = `[User Flow] set language`;
export const TRANSLATE_FURNITURE_DESCRIPTION = '[User Flow] translate furniture description';
export const TRANSLATE_FURNITURE_DESCRIPTION_SUCCESS = '[User Flow] translate furniture description success';
export const CLEAR_RECONSTRUCT_RESULT = '[User Flow] clear reconstruct result';

export const setIdeaActivePage = createAction(SET_IDEA_ACTIVE_PAGE, props<{ tab: IdeaTypes; isAdmin: boolean; pageIndex: number }>());

export const clearAdminActivePages = createAction(CLEAR_ADMIN_ACTIVE_PAGES);

export const setOverlayLoadingSpinner = createAction(SET_OVERLAY_LOADING_ACTION, props<{ status: boolean }>());

export const setRenderLoadingSpinner = createAction(SET_RENDER_LOADING_ACTION, props<{ status: boolean; text: string }>());

export const setRenderJobId = createAction(SET_RENDER_JOB_ID, props<{ id: string }>());

export const setMode = createAction(SET_MODE, props<{ mode: Mode }>());

export const setUnsavedChanges = createAction(SET_UNSAVED_CHANGES, props<{ state: boolean }>());

export const reconstruct = createAction(RECONSTRUCT);

export const reconstructSuccess = createAction(RECONSTRUCT_SUCCESS, props<{ data: InteriorResult }>());

export const detectObjects = createAction(DETECT_OBJECTS);

export const detectObjectsSuccess = createAction(DETECT_OBJECTS_SUCCESS, props<{ data: InteriorResult }>());

export const makeProposal = createAction(MAKE_PROPOSAL);

export const refreshProposal = createAction(
  REFRESH_PROPOSAL,
  props<{
    data: {
      proposalSnapshotBefore: ProposalState;
      proposalSnapshotAfter: ProposalState;
      keyForFurnitureBefore: string;
      keyForFurnitureAfter: string;
    };
  }>()
);

export const refreshType = createAction(
  REFRESH_TYPE,
  props<{
    data: {
      proposalSnapshotBefore: ProposalState;
      proposalSnapshotAfter: ProposalState;
      keyForFurnitureBefore: string;
      keyForFurnitureAfter: string;
    };
  }>()
);

export const replaceType = createAction(REPLACE_TYPE, props<{ data: MovingObjectInfo }>());

export const replaceTypeSuccess = createAction(REPLACE_TYPE_SUCCESS);

export const replaceSku = createAction(REPLACE_TYPE, props<{ oldSku: string; newSku: string }>());

export const replaceAccessoryType = createAction(REPLACE_ACCESSORY_TYPE, props<{ data: ReplaceTypeInfo }>());

export const includeSku = createAction(INCLUDE_SKU, props<{ sku: string; is3d: boolean }>());

export const excludeSku = createAction(EXCLUDE_SKU, props<{ sku: string; is3d: boolean; excludeType: string; uuid?: string }>());

export const lockProposalSku = createAction(LOCK_PROPOSAL_SKU, props<{ sku: string }>());

export const duplicateProposalSku = createAction(DUPLICATE_PROPOSAL_SKU, props<{ sku: string }>());

export const replaceWithVariant = createAction(REPLACE_WITH_VARIANT, props<{ article: CatalogData; movingObjectInfo: MovingObjectInfo }>());

export const replaceWithVariantSuccess = createAction(REPLACE_WITH_VARIANT_SUCCESS);

export const makeProposalSuccess = createAction(MAKE_PROPOSAL_SUCCESS, props<{ data: ProposalResponse }>());

export const getCatalogData = createAction(
  CATALOG_DATA,
  props<{ furnitureType: string; start: number; limit: number; mode: 'more' | 'new' }>()
);

export const setCatalogData = createAction(SET_CATALOG_DATA, props<{ data: any }>());

export const updateCatalogData = createAction(UPDATE_CATALOG_DATA, props<{ data: any }>());

export const updateActiveProject = createAction(UPDATE_ACTIVE_PROJECT, props<{ projectInfo: IProjectAdditionalInfo }>());

export const loadRenders = createAction(LOAD_RENDERS, props<{ renders: Render[]; projectId: string; selectedVersionId?: string }>());

export const versionRendersLoaded = createAction(VERSION_RENDER_LOADED, props<{ rendersResult: SingleRenderResult[] }>());

export const saveProjectSuccess = createAction(SAVE_PROJECT_SUCCESS, props<{ id: string; name: string }>());

export const clearActiveProject = createAction(CLEAR_ACTIVE_PROJECT);

export const clearStyles = createAction(CLEAR_STYLES);

export const clearActiveProjectUpdateFlag = createAction(CLEAR_ACTIVE_PROJECT_UPDATE_FLAG);

export const viewFurniture = createAction(
  VIEW_FURNITURE,
  props<{ data: { article: CatalogData; from: LeftPanelState; translateDetails: boolean } }>()
);

export const viewFurnitureSuccess = createAction(VIEW_FURNITURE_SUCCESS, props<{ data: { article: CatalogData; from: LeftPanelState } }>());

export const getUser = createAction(USER_NAME);

export const clearUser = createAction(CLEAR_NAME);

export const getUserSuccess = createAction(USER_NAME_SUCCESS, props<{ data: any }>());

export const runUserFlow = createAction(PROPOSAL_FLOW, props<{ roomType: RoomType }>());

export const userFlowSuccess = createAction(USER_FLOW_SUCCESS);

export const addGlobalError = createAction(ADD_GLOBAL_ERROR, props<{ data: HttpErrorResponse }>());

export const clearSharedState = createAction(CLEAR_SHARED_STATE);

export const sendOffer = createAction(SEND_OFFER, props<{ data: RequestDto }>());

export const sendOfferSuccess = createAction(SEND_OFFER_SUCCESS);

export const addAccessory = createAction(ADD_ACCESSORY, props<{ data: CatalogData }>());

export const setAccessories = createAction(SET_ACCESSORIES, props<{ data: CatalogDataAccessory[] }>());

export const replaceAccessory = createAction(REPLACE_ACCESSORY, props<{ data: CatalogData; replaceSKU: string }>());

export const deleteAccessory = createAction(DELETE_ACCESSORY, props<{ data: string }>());

export const lockAccessory = createAction(LOCK_ACCESSORY, props<{ sku: string }>());

export const userFirstLogin = createAction(USER_FIRST_LOGIN);

export const userFirstLoginSuccess = createAction(USER_FIRST_LOGIN_SUCCESS);

export const clearFurnitureDetails = createAction(CLEAR_FURNITURE_DETAILS);

export const updateCatalogState = createAction(UPDATE_CATALOG_STATE, props<{ state?: boolean }>());

export const setLeftPanelState = createAction(SET_LEFT_PANEL_STATE, props<{ state: boolean }>());

export const addMoodImage = createAction(ADD_MOODIMAGE, props<{ file: File }>());

export const getStyleFinderIdeas = createAction(GET_STYLEFINDER_IDEAS);

export const getStyleFinderIdeasSuccess = createAction(GET_STYLEFINDER_IDEAS_SUCCESS, props<{ ideas: Idea[] }>());

export const getStyleFinderIdeasSrcSuccess = createAction(
  GET_STYLEFINDER_IDEAS_SRC_SUCCESS,
  props<{ data: { id: string; src: string }[] }>()
);

export const setStyles = createAction(SET_STYLES, props<{ styles: IStyle[] }>());

export const addStyle = createAction(ADD_STYLE, props<{ style: IStyle }>());

export const getProposedFurniture = createAction(GET_PROPOSED_FURNITURE, props<{ data: StylefinderItem[] }>());

export const getProposedFurnitureSuccess = createAction(GET_PROPOSED_FURNITURE_SUCCESS, props<{ data: StylefinderResponse }>());

export const setProposal = createAction(SET_PROPOSAL, props<{ data: ProposalState }>());

export const updateSingleArticle = createAction(UPDATE_SINGLE_ARTICLE, props<{ oldSku: string; newArticle: Article }>());

export const clearProposal = createAction(CLEAR_PROPOSAL);

export const getAlternatives = createAction(GET_ALTERNATIVES, props<{ sku: string }>());

export const setActiveSKU = createAction(SET_ACTIVE_SKU, props<{ sku: string }>());

export const getAlternativesSuccess = createAction(GET_ALTERNATIVES_SUCCESS, props<{ alternatives: CatalogData[]; sku: string }>());

export const clearAlternatives = createAction(CLEAR_ALTERNATIVES);

export const updateVersionRenders = createAction(UPDATE_ACTIVE_PROJECT_VERSION, props<{ version: IVersion }>());

export const changeLanguage = createAction(CHANGE_LANGUAGE, props<{ lang: string }>());

export const changeLanguageSuccess = createAction(CHANGE_LANGUAGE_SUCCESS, props<{ lang: string }>());

export const setLanguage = createAction(SET_LANGUAGE, props<{ lang: string }>());

export const translateFurnitureDescription = createAction(TRANSLATE_FURNITURE_DESCRIPTION, props<{ description: string }>());

export const translateFurnitureDescriptionSuccess = createAction(TRANSLATE_FURNITURE_DESCRIPTION_SUCCESS, props<{ description: string }>());

export const clearReconstructResult = createAction(CLEAR_RECONSTRUCT_RESULT);
