import { IProject } from 'src/models/project';

import { User } from './interfaces/user';
import { IList } from '../shared/interfaces/list';

export interface AdminState {
  users: IList<User>;
  userProjects: IList<IProject>;
  activeUserEmail: string;
}

export const initialAdminState: AdminState = {
  users: null,
  userProjects: null,
  activeUserEmail: null,
};
