<ng-container *ngIf="accessories$ | async as accessories">
    <div class="accessories" *ngIf="accessories.length">
        <p class="title m-18">{{ "USER_FLOW.ACCESSORIES.ALSO_SUITABLE" | translate }}</p>
        <p class="r-15">{{ "USER_FLOW.ACCESSORIES.NO_MODEL" | translate }}</p>
        <div class="accessories-container">
            <ng-container *ngFor="let accessory of accessories">
                <div 
                    class="accessory" 
                    (click)="openDetailsPanel(accessory)"
                    aria-hidden="true"
                >
                    <div class="accessory-image-container">
                        <img 
                            class="image" 
                            [src]="accessory.BasicArticleInformation.PackshotImages[0]"
                            alt="image"
                        >
                    </div>
                    <div class="accessory-info">
                        <p class="m-15 no-margin">{{accessory.BasicArticleInformation.Title}}</p>
                        <p class="r-14 no-margin">{{ "USER_FLOW.CATALOG." + accessory.BasicArticleInformation.Type | translate | uppercase}}</p>
                        <div class="accessory-info-count m-15">
                            {{accessory.count}} {{ "USER_FLOW.ACCESSORIES.COUNT" | translate }}
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</ng-container>