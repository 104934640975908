import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { updateActiveProject } from 'src/app/store/actions/shared.actions';
import { activeProjectBudget } from 'src/app/store/selectors/shared.selector';
import { IProjectAdditionalInfo } from 'src/models/project';

import { RefreshNavigationService } from '../services/refresh-navigation.service';
import { ResolutionService } from '../services/resolution.service';

@UntilDestroy()
@Component({
  selector: 'app-budget',
  templateUrl: './budget.component.html',
  styleUrls: ['./budget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BudgetComponent implements OnInit, AfterViewInit {
  public budget: FormControl;
  public projectInfo: IProjectAdditionalInfo = {};

  constructor(
    private _router: Router,
    private _store: Store,
    private _refreshNavigationService: RefreshNavigationService,
    public resolutionService: ResolutionService
  ) {}

  public ngOnInit(): void {
    this._store
      .select(activeProjectBudget)
      .pipe(untilDestroyed(this))
      .subscribe(budget => {
        budget ? (this.budget = new FormControl(budget, Validators.required)) : (this.budget = new FormControl(8000, Validators.required));
      });

    this._refreshNavigationService.refreshPageEvent$.pipe(untilDestroyed(this)).subscribe(() => this._router.navigate(['choose-file']));
  }

  public ngAfterViewInit(): void {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  public next(): void {
    this.projectInfo.budget = this.budget.value;
    this._store.dispatch(updateActiveProject({ projectInfo: this.projectInfo }));
    this._router.navigate(['remove-preview']);
  }

  public back(): void {
    this.projectInfo.budget = this.budget.value;
    this._store.dispatch(updateActiveProject({ projectInfo: this.projectInfo }));
    this._router.navigate(['style']);
  }
}
