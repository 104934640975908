<div class="create-idea-container" [ngClass]="{'white': isLoggedOut && resolutionService.isMobileResolution}">
    <app-ki-services-header
    [title]="title"
    [subtitle]="subTitle"
    ></app-ki-services-header>
    <form [formGroup]="userInputForm" class="form-container">
        <div class="choose-file">
            <div class="choose-file-container" draggable="true" ngClass="{{dragAreaClass}}" [ngClass]="{'selected': userInputForm.get('imageInput').valid}">
                <input #fileInput id="fileInput" type="file" class="hidden-input" accept=".heic,.jpeg,.png,.jpg,.heif" (change)="onFileChange($event)"/>
                <ng-container *ngIf="resolutionService.isMobileResolution; then uploadImage" ></ng-container>
                <p class="r-18"><b>{{ "IDEAS.DRAG_DROP_TITLE" | translate }}</b></p>
                <ng-container *ngIf="!resolutionService.isMobileResolution; then uploadImage" ></ng-container>
            </div>
            <div class="controls-container">
                <div class="type-style-container">
                    <div class="options-list">
                        <p class="r-14"><b>{{ "IDEAS.CHOOSE_TYPE" | translate }}</b></p>
                        <mat-form-field class="select" [ngClass]="{'create-form-field': isLoggedOut && resolutionService.isMobileResolution}">
                            <mat-select formControlName="type">
                                <mat-option *ngFor="let type of types" [value]="type.value">
                                    {{ getTranslationForDropdown(type.name) }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="options-list">
                        <p class="r-14"><b>{{ "IDEAS.CHOOSE_STYLE" | translate }}</b></p>
                        <mat-form-field class="select"  [ngClass]="{'create-form-field': isLoggedOut && resolutionService.isMobileResolution}">
                            <mat-select formControlName="style">
                                <mat-option *ngFor="let style of styles" [value]="style.value">
                                    {{ getTranslationForDropdown(style.name) }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="special-wishes">
                    <p class="r-14"><b>{{ "IDEAS.SPECIAL_WISHES" | translate }}</b></p>
                    <mat-form-field class="input-field"  [ngClass]="{'create-form-field': isLoggedOut && resolutionService.isMobileResolution}">
                        <input type="string" matInput [placeholder]="wishesPlaceholder$ | async"
                            formControlName="specialWishes">
                    </mat-form-field>
                    <mat-error *ngIf="userInputForm.get('specialWishes').invalid" class="error">{{ "IDEAS.LESS_30_LENGTH"
                        | translate
                        }}</mat-error>
                </div>
                <button
                    [disabled]="userInputForm.get('specialWishes').invalid || userInputForm.get('style').invalid || userInputForm.get('type').invalid"
                    class="button special-wishes-button"
                    mat-raised-button
                    color="primary" 
                    (click)="onSubmit()">
                    {{ "BUTTONS.CREATE_IDEA" | translate }}
                </button>
                <mat-error  *ngIf="incorrectTypeError">{{ "IDEAS.INCORRECT_TYPE" | translate }}</mat-error>
            </div>


            
        </div>
        <ng-container *ngIf="!resolutionService.isMobileResolution; then imagesTemplate"></ng-container>
    </form>
    <div *ngIf="resolutionService.isMobileResolution; then imagesTemplate"></div>
</div>

<ng-template #imagesTemplate>
    <div class="image-container">
        <img #image class="image" alt="active Image" [src]="activeImage | safe:'url'">
        <button class="icon-button previous" [disabled]="index-1<0" (click)="previous()">
            <img 
                *ngIf="index-1>=0;else leftDisabled" 
                alt="left arrow" 
                class="arrow" 
                src="../../../assets/icons/Gruppe 3005.svg"
            >
        </button>
        <button class="icon-button next" [disabled]="index+1>=images.length" (click)="next()">
            <img *ngIf="index+1<images.length;else rightDisabled" alt="right arrow" class="arrow transform" src="../../../assets/icons/Gruppe 3005.svg">
        </button>
    </div>
</ng-template>

<ng-template #leftDisabled>
    <img 
        alt="left arrow disabled" 
        class="arrow" 
        src="../../../assets/icons/Gruppe 3005 disabled.svg"
    >
</ng-template>

<ng-template #rightDisabled>
    <img 
    alt="right arrow disabled" 
    class="arrow transform" 
    src="../../../assets/icons/Gruppe 3005 disabled.svg"
>
</ng-template>

<ng-template #uploadImage>
    <img 
        class="upload-photo-icon" 
        [src]="userInputForm.get('imageInput').valid ? './../../../../assets/icons/white-upload.svg' : './../../../../assets/icons/share-green.svg'"
        alt="Upload photo"
    >
</ng-template>