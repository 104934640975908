import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, filter, map, Observable, take, tap } from 'rxjs';
import { Roles } from 'src/app/shared/enums/roles.enum';
import {
  accessories,
  alternatives,
  articleLock,
  furnitureDetailsArticle,
  furnitureDetailsDescriptionTranslate,
  proposal,
  userRole,
} from 'src/app/store/selectors/shared.selector';
import { MathUtils, SpotLight, Vector3 } from 'three';

import { BasicArticleInformation, CatalogData } from '../../../../services/model/proposal';
import {
  clearAlternatives,
  clearFurnitureDetails,
  excludeSku,
  getAlternatives,
  includeSku,
  lockAccessory,
  lockProposalSku,
  replaceAccessoryType,
  replaceType,
  replaceWithVariant,
  setActiveSKU,
  setLeftPanelState,
  setUnsavedChanges,
} from '../../../store/actions/shared.actions';
import { ImportService } from '../../services/import.service';
import { LightObjectsService, SpotLightSettingsForm } from '../../services/light-objects.service';
import { MovingObject, MovingObjectInfo, ReplaceTypeInfo } from '../../services/model/dto/moving-object.model';
import { MovingObjectsService } from '../../services/moving-objects.service';
import { ResolutionService } from '../../services/resolution.service';
import { RoomService } from '../../services/room.service';
import { Option } from '../catalog-details/option.model';

@UntilDestroy()
@Component({
  selector: 'app-scene-details',
  templateUrl: './scene-details.component.html',
  styleUrls: ['./scene-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SceneDetailsComponent implements OnInit {
  @Output() public closeDialog: EventEmitter<void> = new EventEmitter<void>();

  public isUserAdmin$ = this._store.select(userRole).pipe(map(roles => roles?.includes(Roles.ADMIN)));

  public description$: Observable<string | null> = this._store.select(furnitureDetailsDescriptionTranslate);

  public catalogData$: Observable<CatalogData | null> = this._store.select(furnitureDetailsArticle).pipe(
    map(data => data ?? null),
    tap(data => {
      if (data && data.BasicArticleInformation.PackshotImages) {
        this.activeImage = data.BasicArticleInformation.PackshotImages.at(0);
      }
    })
  );

  public alternatives$: Observable<CatalogData[] | null> = this._store.select(alternatives);

  public options: Option[] = [
    {
      name: 'USER_FLOW.DETAILS_PAGE.HORIZONTAL',
      actionPositive: (): void => {
        this._selectedObjectsIds.map(uuid => this._movingObjectsService.move(uuid, new Vector3(1, 0, 0)));
      },
      actionNegative: (): void => {
        this._selectedObjectsIds.map(uuid => this._movingObjectsService.move(uuid, new Vector3(-1, 0, 0)));
      },
      iconSrc: './../../../../assets/icons/Gruppe 3189.svg',
    },
    {
      name: 'USER_FLOW.DETAILS_PAGE.DEPTH',
      actionPositive: (): void => {
        this._selectedObjectsIds.map(uuid => this._movingObjectsService.move(uuid, new Vector3(0, 0, -1)));
      },
      actionNegative: (): void => {
        this._selectedObjectsIds.map(uuid => this._movingObjectsService.move(uuid, new Vector3(0, 0, 1)));
      },
      iconSrc: './../../../../assets/icons/Gruppe 3190.svg',
    },
    {
      name: 'USER_FLOW.DETAILS_PAGE.VERTICAL',
      actionPositive: (): void => {
        this._selectedObjectsIds.map(uuid => this._movingObjectsService.move(uuid, new Vector3(0, 1, 0)));
      },
      actionNegative: (): void => {
        this._selectedObjectsIds.map(uuid => this._movingObjectsService.move(uuid, new Vector3(0, -1, 0)));
      },
      iconSrc: './../../../../assets/icons/Gruppe 3188.svg',
    },
    {
      name: 'USER_FLOW.DETAILS_PAGE.SIZE',
      actionPositive: (): void => {
        this._selectedObjectsIds.map(uuid => this._movingObjectsService.scale(uuid, new Vector3(1, 1, 1)));
      },
      actionNegative: (): void => {
        this._selectedObjectsIds.map(uuid => this._movingObjectsService.scale(uuid, new Vector3(-1, -1, -1)));
      },
      iconSrc: './../../../../assets/icons/Gruppe 3169.svg',
    },
    {
      name: 'USER_FLOW.DETAILS_PAGE.ROTATION',
      actionPositive: (): void => {
        this._selectedObjectsIds.map(uuid => this._movingObjectsService.rotate(uuid, 1));
      },
      actionNegative: (): void => {
        this._selectedObjectsIds.map(uuid => this._movingObjectsService.rotate(uuid, -1));
      },
      iconSrc: './../../../../assets/icons/Gruppe 3124.svg',
    },
  ];
  public advancedOptions: Option[] = [
    {
      name: 'USER_FLOW.DETAILS_PAGE.HEIGHT',
      actionPositive: (): void => {
        this._selectedObjectsIds.map(uuid => this._movingObjectsService.scale(uuid, new Vector3(0, 1, 0)));
      },
      actionNegative: (): void => {
        this._selectedObjectsIds.map(uuid => this._movingObjectsService.scale(uuid, new Vector3(0, -1, 0)));
      },
      iconSrc: './../../../../assets/icons/Gruppe 3127.svg',
    },
    {
      name: 'USER_FLOW.DETAILS_PAGE.WIDTH',
      actionPositive: (): void => {
        this._selectedObjectsIds.map(uuid => this._movingObjectsService.scale(uuid, new Vector3(1, 0, 0)));
      },
      actionNegative: (): void => {
        this._selectedObjectsIds.map(uuid => this._movingObjectsService.scale(uuid, new Vector3(-1, 0, 0)));
      },
      iconSrc: './../../../../assets/icons/Gruppe 3128.svg',
    },
    {
      name: 'USER_FLOW.DETAILS_PAGE.LENGTH',
      actionPositive: (): void => {
        this._selectedObjectsIds.map(uuid => this._movingObjectsService.scale(uuid, new Vector3(0, 0, 1)));
      },
      actionNegative: (): void => {
        this._selectedObjectsIds.map(uuid => this._movingObjectsService.scale(uuid, new Vector3(0, 0, -1)));
      },
      iconSrc: './../../../../assets/icons/Gruppe 3129.svg',
    },
  ];
  public currentData: CatalogData = null;
  public selectedVariant: CatalogData = null;
  public optionsMobileFirst: Option[];
  public optionsMobileSecond: Option[];
  public articleLock$ = this._store.select(articleLock(this.currentData?.BasicArticleInformation.SKU));

  public defaultMaterial: string;
  public defaultSize: string;
  public materials: string[];
  public sizes: string[];

  public activeImage: string;
  public imageIndex = 0;
  public maxAngle: number = Math.PI / 2;

  private _uuid: string;

  public spotLightSettingsForm: FormGroup<SpotLightSettingsForm> = new FormGroup({
    angle: new FormControl<number>(0),
    decay: new FormControl<number>(0),
    distance: new FormControl<number>(0),
    penumbra: new FormControl<number>(0),
    power: new FormControl<number>(0),
    castShadow: new FormControl<boolean>(false),
  });

  // light management
  public get isLightHelperEnabled(): boolean {
    return this._lightObjectsService.isHelperExistForObject(this._uuid);
  }

  public get isLightEditor(): boolean {
    const object = this._movingObjectsService.movingObjects.find(mo => mo.id === this._uuid);
    return !!object && !!object.light && object.light instanceof SpotLight;
  }

  public get angleDegrees(): number {
    return this.spotLightSettingsForm.getRawValue().angle * MathUtils.RAD2DEG;
  }

  public get decay(): number {
    return this.spotLightSettingsForm.getRawValue().decay;
  }

  public get distance(): number {
    return this.spotLightSettingsForm.getRawValue().distance;
  }

  public get penumbra(): number {
    return this.spotLightSettingsForm.getRawValue().penumbra;
  }

  public get power(): number {
    return this.spotLightSettingsForm.getRawValue().power / 80;
  }

  private get _selectedObjectsIds(): string[] {
    return [...new Set([...this._roomService.highlightedObjectsIds, this._uuid])];
  }

  constructor(
    public resolutionService: ResolutionService,
    public _store: Store,
    private _movingObjectsService: MovingObjectsService,
    private _lightObjectsService: LightObjectsService,
    private _roomService: RoomService,
    private _importService: ImportService,
    private _cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.catalogData$
      .pipe(
        filter(data => !!data),
        tap(data => {
          if (data.BasicArticleInformation.SKU) {
            this._store.dispatch(getAlternatives({ sku: data.BasicArticleInformation.SKU }));
          } else {
            this._store.dispatch(clearAlternatives());
            this._store.dispatch(setActiveSKU({ sku: null }));
          }
        }),
        untilDestroyed(this)
      )
      .subscribe((catalogData: CatalogData) => {
        this.currentData = catalogData;
        this.sizes = catalogData.BasicArticleInformation?.Options.find(el => el.Name === 'Grösse')?.Value.split(', ');
        this.materials = catalogData.BasicArticleInformation?.Options.find(el => el.Name === 'Material')?.Value.split(', ');
        this.defaultSize = this.sizes ? this.sizes[0] : null;
        this.defaultMaterial = this.materials ? this.materials[0] : null;
        this.articleLock$ = this._store.select(articleLock(catalogData.BasicArticleInformation.SKU));
      });

    this._roomService.selectedMovingObjectId$
      .pipe(
        distinctUntilChanged(),
        filter(uuid => !!uuid),
        untilDestroyed(this)
      )
      .subscribe(uuid => {
        this._uuid = uuid;
      });

    this.spotLightSettingsForm.valueChanges.subscribe(values => {
      this._lightObjectsService.changeLightSettingsFor(this._uuid, {
        angle: values.angle,
        decay: values.decay,
        distance: values.distance,
        penumbra: values.penumbra,
        power: values.power,
        castShadow: values.castShadow,
      });
    });

    if (this.resolutionService.isMobileResolution) {
      this.optionsMobileFirst = [this.options[0], ...this.options.slice(2)];
      this.optionsMobileSecond = [this.options[1], ...this.advancedOptions];
    }
  }

  public setActiveVariant(data: CatalogData): void {
    this.selectedVariant = data;
  }

  public setActiveAlternative(data: CatalogData): void {
    this.selectedVariant = data;
  }

  public addModel(data: CatalogData): void {
    this._store
      .select(proposal)
      .pipe(
        take(1),
        map(state => state.ProposedProposal.Articles.find(article => article.Sku === data.BasicArticleInformation.SKU)),
        tap(article => {
          if (article) {
            if (this.checkModelLinkExist(data.BasicArticleInformation)) {
              const uuid = this._movingObjectsService.movingObjects.find(obj => obj.SKU === article.Sku).id;
              this._movingObjectsService.clone(uuid, true);
            } else {
              this._store.dispatch(
                includeSku({
                  sku: data.BasicArticleInformation.SKU,
                  is3d: this.checkModelLinkExist(data.BasicArticleInformation),
                })
              );
            }

            this._store.dispatch(setUnsavedChanges({ state: true }));
          } else {
            this._store.dispatch(
              includeSku({
                sku: data.BasicArticleInformation.SKU,
                is3d: this.checkModelLinkExist(data.BasicArticleInformation),
              })
            );
          }
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  public remove(data: CatalogData): void {
    if (this.checkIsManuallyUploaded(data.BasicArticleInformation)) {
      this._movingObjectsService.deleteFurniture(this._uuid, true);
      this._store.dispatch(clearFurnitureDetails());
      this._store.dispatch(setLeftPanelState({ state: false }));
    } else if (!this.checkModelLinkExist(data.BasicArticleInformation)) {
      this._store
        .select(accessories)
        .pipe(take(1), untilDestroyed(this))
        .subscribe(accessories => {
          if (accessories.find(el => el.BasicArticleInformation.SKU === data.BasicArticleInformation.SKU)) {
            this._store.dispatch(
              excludeSku({
                sku: data.BasicArticleInformation.SKU,
                is3d: false,
                excludeType: data.BasicArticleInformation.Type,
              })
            );
            if (this.resolutionService.isMobileResolution) {
              this.closeDialog.emit();
            }
          }
        });
    } else if (this._uuid) {
      this._store
        .select(proposal)
        .pipe(
          take(1),
          map(state => state.ProposedProposal.Articles.filter(article => article.NumberOfExemplars > 1)),
          tap(articlesCouldBeDeletedLocal => {
            if (articlesCouldBeDeletedLocal.find(article => article.Sku === data.BasicArticleInformation.SKU)) {
              this._movingObjectsService.deleteFurniture(this._uuid, true);
              this._store.dispatch(setUnsavedChanges({ state: true }));
            } else {
              this._store.dispatch(
                excludeSku({
                  sku: data.BasicArticleInformation.SKU,
                  is3d: this.checkModelLinkExist(data.BasicArticleInformation),
                  uuid: this._uuid,
                  excludeType: data.BasicArticleInformation.Type,
                })
              );
            }

            this._store.dispatch(setLeftPanelState({ state: false }));
          }),
          untilDestroyed(this)
        )
        .subscribe();
    }
  }

  public cloneObject(data: CatalogData): void {
    if (this.checkModelLinkExist(data.BasicArticleInformation) || this.checkIsManuallyUploaded(data.BasicArticleInformation)) {
      this._movingObjectsService.clone(this._uuid, true);
    } else {
      this._store.dispatch(includeSku({ sku: data.BasicArticleInformation.SKU, is3d: false }));
    }

    this._store.dispatch(setUnsavedChanges({ state: true }));
  }

  public lockObject(data: CatalogData): void {
    this._store.dispatch(lockProposalSku({ sku: data.BasicArticleInformation.SKU }));
    if (!this.checkModelLinkExist(data.BasicArticleInformation) && !this.checkIsManuallyUploaded(data.BasicArticleInformation)) {
      this._store.dispatch(lockAccessory({ sku: data.BasicArticleInformation.SKU }));
    } else if (this._uuid) {
      this._movingObjectsService.lock(this._uuid, true);
      this._store.dispatch(setUnsavedChanges({ state: true }));
    }
  }

  public replaceObjectAuto(catalogData: CatalogData): void {
    if (this.checkIsManuallyUploaded(catalogData.BasicArticleInformation)) {
      this._movingObjectsService.deleteFurniture(this._uuid, true);
      this._importService.loadModel();
    } else if (!this.checkModelLinkExist(catalogData.BasicArticleInformation)) {
      this._store
        .select(accessories)
        .pipe(take(1), untilDestroyed(this))
        .subscribe(accessories => {
          if (accessories.find(el => el.BasicArticleInformation.SKU === catalogData.BasicArticleInformation.SKU)) {
            const replaceTypeInfo: ReplaceTypeInfo = {
              articleSkuToReplace: catalogData.BasicArticleInformation.SKU,
              viewedArticles: [
                {
                  ArticleSku: catalogData.BasicArticleInformation.SKU,
                  VariantSkus: catalogData.Variants.map(variant => variant.BasicArticleInformation.SKU),
                },
              ],
            };
            this._store.dispatch(replaceAccessoryType({ data: replaceTypeInfo }));
            this._store.dispatch(setUnsavedChanges({ state: true }));
            if (this.resolutionService.isMobileResolution) {
              this.closeDialog.emit();
            }
          }
        });
    } else if (this._uuid) {
      const movingObject = this._movingObjectsService.movingObjects.find(mo => mo.id === this._uuid);
      const movingObjectInfo: MovingObjectInfo = this._createObjectInfo(movingObject, catalogData.Variants);
      this._store.dispatch(replaceType({ data: movingObjectInfo }));
    }
  }

  // light
  public onLightEditorExpand(): void {
    const settings = this._lightObjectsService.getLightSettings(this._uuid);
    this.spotLightSettingsForm.setValue({ ...settings }, { emitEvent: false });
  }

  public onSlideChange(event: MatSlideToggleChange): void {
    if (event.checked) {
      this._lightObjectsService.addLightHelper(this._uuid);
    } else {
      this._lightObjectsService.removeLightHelper(this._uuid);
    }
  }

  public onSlideButtonsClick(action: 'increment' | 'decrement'): void {
    const delta = 0.01;
    const settings = this.spotLightSettingsForm.getRawValue();
    this.spotLightSettingsForm.setValue({
      ...settings,
      power: settings.power + (action === 'increment' ? delta : -delta),
    });
  }

  private _createObjectInfo(movingObject: MovingObject, variants: CatalogData[]): MovingObjectInfo {
    const variantSkus = variants.map(variant => variant.BasicArticleInformation.SKU);
    return {
      id: movingObject.id,
      position: movingObject.object3D.position.clone(),
      rotation: new Vector3(
        movingObject.object3D.rotation.clone().x,
        movingObject.object3D.rotation.clone().y,
        movingObject.object3D.rotation.clone().z
      ),
      articleSkuToReplace: movingObject.SKU,
      viewedArticles: [
        {
          ArticleSku: movingObject.SKU,
          VariantSkus: variantSkus,
        },
      ],
    };
  }

  public flip(): void {
    this._selectedObjectsIds.map(uuid => this._movingObjectsService.flip(uuid));
  }

  public checkModelLinkExist(data: BasicArticleInformation): boolean {
    return !!(data.MetaData?.find(el => el.Key === '3D_GLB_LOW_ELI')?.Value || data.AR?.GLB || data.AR?.FBX);
  }

  public checkIsManuallyUploaded(data: BasicArticleInformation): boolean {
    return !!(!data.SKU && !data.MetaData && !data.AR && this._uuid);
  }

  public backFromVariant(variant: CatalogData): void {
    if (variant) {
      this.currentData = variant;
      const movingObject = this._movingObjectsService.movingObjects.find(mo => mo.id === this._uuid);
      this._store.dispatch(
        replaceWithVariant({ article: this.currentData, movingObjectInfo: this._createObjectInfo(movingObject, [variant]) })
      );

      if (this.resolutionService.isMobileResolution) {
        this.closeDialog.emit();
      }
    }

    this.selectedVariant = null;
    this._cdr.markForCheck();
  }

  public previous(images: string[]): void {
    if (this.imageIndex - 1 >= 0) {
      this.activeImage = images[--this.imageIndex];
    }
  }

  public next(images: string[]): void {
    if (this.imageIndex + 1 < images.length) {
      this.activeImage = images[++this.imageIndex];
    }
  }
}
