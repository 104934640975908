import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, filter, tap } from 'rxjs';
import { IdeaTypes } from 'src/app/shared/enums/idea-type.enum';
import { clearAdminState } from 'src/app/store/actions/admin.actions';
import { clearIdeasTable } from 'src/app/store/actions/ideas.actions';
import { clearAdminActivePages } from 'src/app/store/actions/shared.actions';
import { activeUserEmail } from 'src/app/store/selectors/admin.selectors';
import { ResolutionService } from 'src/app/user-flow/services/resolution.service';

@UntilDestroy()
@Component({
  selector: 'app-result-tabs',
  templateUrl: './result-tabs.component.html',
  styleUrls: ['./result-tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResultTabsComponent implements OnInit {
  public readonly tabs = [IdeaTypes.DEFAULT, IdeaTypes.STYLESWAP, IdeaTypes.NO_FURNITURE, IdeaTypes.PROJECTS];
  public activeTab: IdeaTypes;
  public types = IdeaTypes;
  public userEmail: string;
  public isAdmin: boolean;
  public selectedTab = new FormControl('DEFAULT');

  constructor(
    public resolutionService: ResolutionService,
    private _store: Store,
    private _translateService: TranslateService,
    private _route: ActivatedRoute,
    private _router: Router
  ) {}

  public ngOnInit(): void {
    combineLatest(this._route.queryParams, this._store.select(activeUserEmail))
      .pipe(
        tap(([params, email]) => {
          this.isAdmin = !!this.userEmail;
          this.userEmail = params['email'];
          if (!this.userEmail) {
            this._store.dispatch(clearAdminState());
          }
          if (this.userEmail && this.userEmail !== email) {
            this._store.dispatch(clearIdeasTable());
            this._store.dispatch(clearAdminActivePages());
            this.selectTab(IdeaTypes.DEFAULT, true);
          }
        }),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.selectTab(this.identifyActiveTabType(), !!this.userEmail);
      });

    this._router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        untilDestroyed(this)
      )
      .subscribe(() => {
        const tab = this.identifyActiveTabType();
        this.selectTab(tab, !!this.userEmail);
      });
  }

  public identifyActiveTabType(): IdeaTypes {
    if (location.pathname.includes(IdeaTypes.STYLESWAP.toLocaleLowerCase())) {
      return IdeaTypes.STYLESWAP;
    } else if (location.pathname.includes(IdeaTypes.PROJECTS.toLocaleLowerCase())) {
      return IdeaTypes.PROJECTS;
    } else if (location.pathname.includes('no-furniture')) {
      return IdeaTypes.NO_FURNITURE;
    } else {
      return IdeaTypes.DEFAULT;
    }
  }

  public selectTab(type: IdeaTypes, isAdmin: boolean): void {
    if (this.activeTab !== type || this.resolutionService.isMobileResolution) {
      this.activeTab = type;
      this._store.dispatch(clearIdeasTable());
      const route = this._getRoute(isAdmin);
      if (route !== 'projects') {
        this._router.navigate(['result', `${route}`], { queryParams: { email: this.userEmail } });
      } else {
        this._router.navigate(['result', `${route}`]);
      }
    }
  }

  // mat-select from mat-angular version 15.0 don't display default value if translate pipe is used in interpolated string
  public getTranslationForTab(type: IdeaTypes): string {
    return this._translateService.instant(`SHARED.TABS.${type}`);
  }

  private _getRoute(isAdmin: boolean): string {
    if (this.activeTab === IdeaTypes.NO_FURNITURE) {
      return 'no-furniture';
    } else if (this.activeTab === IdeaTypes.PROJECTS) {
      return isAdmin ? 'admin-projects' : 'projects';
    } else {
      return this.activeTab.toLocaleLowerCase();
    }
  }
}
