import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { undoRedoReducer } from './undo-redo.reducers';

@NgModule({
  declarations: [],
  imports: [CommonModule, StoreModule.forFeature('undoredo', undoRedoReducer)],
})
export class UndoRedoModule {}
