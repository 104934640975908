import { createEntityAdapter, EntityState, Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { IList } from 'src/app/shared/interfaces/list';
import { IProject, IVersion } from 'src/models/project';

import { ProjectsActions, VersionsActions } from '../action-types';

export interface ProjectsState extends EntityState<IProject> {
  totalElements: number;
  pageSize: number;
  pageIndex: number;
  selectedProject: IList<IVersion>;
}

export const adapter = createEntityAdapter<IProject>();

export const initialProjectsState = adapter.getInitialState({
  totalElements: 0,
  pageSize: 10,
  pageIndex: 0,
  selectedProject: null,
});

export const projectsVersionsReducer = createReducer(
  initialProjectsState,
  on(ProjectsActions.allProjectsLoaded, (state, action) => {
    return adapter.setAll(action.projects.content, {
      ...state,
      pageIndex: action.projects.number,
      pageSize: action.projects.size,
      totalElements: action.projects.totalElements,
    });
  }),
  on(ProjectsActions.projectSrcLoaded, (state, action) => {
    const update: Update<IProject> = {
      id: action.data.id,
      changes: {
        src: action.data.src,
      },
    };
    return adapter.updateOne(update, state);
  }),
  on(VersionsActions.allVersionsLoaded, (state, action) => {
    const update: Update<IProject> = {
      id: action.id,
      changes: {
        versions: action.versions.content,
        versionTotalElements: action.versions.totalElements,
      },
    };
    return adapter.updateOne(update, {
      ...state,
      selectedProject: action.versions,
    });
  }),
  on(VersionsActions.versionSrcLoaded, (state, action) => {
    const project = state.selectedProject;
    if (project) {
      const versionsUpdateContent = project?.content?.map(version => {
        return {
          ...version,
          src: version.id === action.data.id ? action.data.src : version.src,
        };
      });
      const versions = state.entities[action.data.projectId]?.versions.map(version => {
        return { ...version, src: action.data.id === version.id ? action.data.src : version.src };
      });
      const update: Update<IProject> = {
        id: action.data.projectId,
        changes: {
          versions: versions,
        },
      };
      return adapter.updateOne(update, {
        ...state,
        selectedProject: {
          ...project,
          content: versionsUpdateContent,
        },
      });
    } else {
      return state;
    }
  }),
  on(VersionsActions.versionLoaded, (state, action) => {
    const versions = state.entities[action.id]?.versions?.filter(version => version.id !== action.version.id);
    if (versions) {
      versions.push({ ...action.version, needUpdateVersion: true });
    }
    const update: Update<IProject> = {
      id: action.id,
      changes: { versions },
    };
    return adapter.updateOne(update, state);
  }),
  on(VersionsActions.clearNeedToUpdateVersion, (state, action) => {
    const version = state.entities[action.projectId]?.versions?.find(version => version.id === action.versionId);
    const versions = state.entities[action.projectId]?.versions?.filter(version => version.id !== action.versionId) || [];
    const update: Update<IProject> = {
      id: action.projectId,
      changes: { versions: [...versions, { ...version, needUpdateVersion: false }] },
    };
    return adapter.updateOne(update, state);
  }),
  on(VersionsActions.removeVersionSuccess, (state, action) => {
    return {
      ...state,
      selectedProject: {
        ...state.selectedProject,
        content: [...state.selectedProject.content.filter(version => version.id !== action.versionId)],
      },
    };
  }),
  on(ProjectsActions.clearSelectedProject, state => {
    return {
      ...state,
      selectedProject: null,
    };
  }),
  on(ProjectsActions.clearProjectsState, state => {
    return {
      ...state,
      ...initialProjectsState,
    };
  })
);

export const { selectAll, selectIds } = adapter.getSelectors();
