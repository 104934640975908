import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { RouterModule, Routes } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { SafePipeModule } from 'safe-pipe';
import { ProjectsVersionsHttpService } from 'src/services/projects-versions-http.service';

import { ProjectPageComponent } from './project-page/project-page.component';
import { CurrentUserProjectsListComponent } from './projects-list/current-user-projects-list.component';
import { AuthGuard } from '../auth/guards/auth.guard';
import { TRANSLATE_MODULE_CONFIG } from '../shared/configs/translate.config';
import { InterceptorsModule } from '../shared/interceptors/interceptors.module';
import { SharedComponentsModule } from '../shared/shared-components.module';
import { ProjectsEffects } from '../store/effects/projects.effects';
import { VersionsEffects } from '../store/effects/version.effects';
import { projectsVersionsReducer } from '../store/reducers/projects-versions.reducers';

const routes: Routes = [{ path: 'project/:id', component: ProjectPageComponent, canActivate: [AuthGuard] }];

@NgModule({
  declarations: [CurrentUserProjectsListComponent, ProjectPageComponent],
  exports: [CurrentUserProjectsListComponent, ProjectPageComponent],
  imports: [
    CommonModule,
    EffectsModule.forFeature([ProjectsEffects, VersionsEffects]),
    InterceptorsModule,
    MatButtonModule,
    MatPaginatorModule,
    RouterModule.forChild(routes),
    SafePipeModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    TranslateModule.forChild(TRANSLATE_MODULE_CONFIG),
    StoreModule.forFeature('projects', projectsVersionsReducer),
    SharedComponentsModule,
  ],
  providers: [ProjectsVersionsHttpService],
})
export class ProjectsModule {}
