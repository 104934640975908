import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { viewFurniture } from 'src/app/store/actions/shared.actions';
import { accessories } from 'src/app/store/selectors/shared.selector';
import { CatalogDataAccessory } from 'src/services/model/proposal';

import { LeftPanelState } from '../catalog/left-panel-container/left-panel-state.enum';

@Component({
  selector: 'app-accessories',
  templateUrl: './accessories.component.html',
  styleUrls: ['./accessories.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccessoriesComponent {
  public accessories$: Observable<CatalogDataAccessory[]> = this._store.select(accessories);

  constructor(private _store: Store) {}

  public openDetailsPanel(accessory): void {
    this._store.dispatch(viewFurniture({ data: { article: accessory, from: LeftPanelState.SCENE_DETAILS, translateDetails: true } }));
  }
}
