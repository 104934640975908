import { BehaviorSubject, defer, interval } from 'rxjs';
import { filter, map, reduce, share, take, withLatestFrom } from 'rxjs/operators';

import { PausableTimer } from './notification';

const REFRESHING_SCALE = 10;

export function getPausableTimer(timeout: number, pause: BehaviorSubject<boolean>): PausableTimer {
  const scaledInterval = 1000 / REFRESHING_SCALE;
  const scaledTimeout = timeout * REFRESHING_SCALE;
  const pausableTimer$ = defer(() => {
    let passedValues = 0;
    return interval(scaledInterval).pipe(
      withLatestFrom(pause),
      filter(([_, paused]) => !paused),
      take(scaledTimeout),
      map(() => passedValues++)
    );
  }).pipe(share());

  return {
    stepTimer: pausableTimer$,
    completeTimer: pausableTimer$.pipe(reduce((x, y) => y)),
    remainingPercent: pausableTimer$.pipe(map(v => ((scaledTimeout - v) / scaledTimeout) * 100)),
  };
}
