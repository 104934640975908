import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { inject, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { RouterModule, Routes } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { Observable } from 'rxjs';
import { SafePipeModule } from 'safe-pipe';

import { AccessoriesComponent } from './accessories/accessories.component';
import { BudgetComponent } from './budget/budget.component';
import { CatalogCategoriesComponent } from './catalog/catalog-categories/catalog-categories.component';
import { CatalogCategoryComponent } from './catalog/catalog-category/catalog-category.component';
import { CatalogDetailsComponent } from './catalog/catalog-details/catalog-details.component';
import { AllColorsComponent } from './catalog/color-select-container/all-colors/all-colors.component';
import { ColorSelectContainerComponent } from './catalog/color-select-container/color-select-container.component';
import { CurrentColorComponent } from './catalog/color-select-container/current-color/current-color.component';
import { LeftPanelContainerComponent } from './catalog/left-panel-container/left-panel-container.component';
import { SceneDetailsComponent } from './catalog/scene-details/scene-details.component';
import { VariantDetailsComponent } from './catalog/variant-details/variant-details.component';
import { ChooseFileComponent } from './choose-file/choose-file.component';
import { DetailsDialogComponent } from './details-dialog/details-dialog.component';
import { ExportSettingsComponent } from './export-settings/export-settings.component';
import { HasUnsavedChangesDialogComponent } from './has-unsaved-changes-dialog/has-unsaved-changes-dialog.component';
import { LoadModelDialogComponent } from './load-model-dialog/load-model-dialog.component';
import { ManualReconstructEditorComponent } from './manual-reconstruct-editor/manual-reconstruct-editor.component';
import { ManualReconstructInstructionComponent } from './manual-reconstruct-editor/manual-reconstruct-instruction/manual-reconstruct-instruction.component';
import { MovementDialogComponent } from './movement-dialog/movement-dialog.component';
import { OptionsPageComponent } from './options-page/options-page.component';
import { ProjectNameComponent } from './project-name/project-name.component';
import { ReconstructEditorComponent } from './reconstruct-editor/reconstruct-editor.component';
import { reloadResolver } from './reconstruct-editor/reload.resolver';
import { RemoveEditorComponent } from './remove-editor/remove-editor.component';
import { RemovePreviewComponent } from './remove-preview/remove-preview.component';
import { RenderLoaderComponent } from './render/render-loader/render-loader.component';
import { RenderComponent } from './render/render.component';
import { RotationDialogComponent } from './render/rotation-dialog/rotation-dialog.component';
import { RequestComponent } from './request/request.component';
import { RoomReconstructPreviewComponent } from './room-reconstruct-preview/room-reconstruct-preview.component';
import { RoomTypeComponent } from './room-type/room-type.component';
import { AutoPositioningService } from './services/auto-positioning.service';
import { CornersControlsService } from './services/corners-controls.service';
import { DeleteObjHttpService } from './services/delete-obj-http.service';
import { ExportService } from './services/export.service';
import { HighlightedObjectsService } from './services/highlighted-objects.service';
import { ImageFileParamsService } from './services/image-file-params.service';
import { ImportService } from './services/import.service';
import { KeyboardStateService } from './services/keyboard-state.service';
import { LightObjectsService } from './services/light-objects.service';
import { ManualReconstructionService } from './services/manual-reconstruction.service';
import { MovingObjectsService } from './services/moving-objects.service';
import { OriginalImageSizeService } from './services/original-image-size.service';
import { PhotorealisticRenderService } from './services/photorealistic-render.service';
import { PlanesService } from './services/planes.service';
import { RefreshNavigationService } from './services/refresh-navigation.service';
import { RequestHttpService } from './services/request-http.service';
import { ResolutionService } from './services/resolution.service';
import { RoomParamsService } from './services/room-params.service';
import { RoomService } from './services/room.service';
import { StyleFinderService } from './services/stylefinder.service';
import { VersionService } from './services/version.service';
import { SetHeightDialogComponent } from './set-height-dialog/set-height-dialog.component';
import { StyleComponent } from './style/style.component';
import { UploadFileComponent } from './upload-file/upload-file.component';
import { VersionNameComponent } from './version-name/version-name.component';
import { WallColorDialogComponent } from './wall-color-dialog/wall-color-dialog.component';
import { AuthModule } from '../auth/auth.module';
import { AuthGuard } from '../auth/guards/auth.guard';
import { IdeasHttpService } from '../ideas/services/ideas-http.service';
import { ProductPageComponent } from '../shared/components/product-page/product-page.component';
import { TRANSLATE_MODULE_CONFIG } from '../shared/configs/translate.config';
import { HasUnsavedChangesGuard } from '../shared/guards/has-unsaved-changes.guard';
import { InterceptorsModule } from '../shared/interceptors/interceptors.module';
import { FileSizeService } from '../shared/services/file-size.service';
import { SharedComponentsModule } from '../shared/shared-components.module';
import { ActiveProjectEffects } from '../store/effects/active-project.effects';
import { ImageEffects } from '../store/effects/image.effects';
import { ProjectsEffects } from '../store/effects/projects.effects';
import { RenderGUIEffects } from '../store/effects/render-gui.effects';
import { imageReducer } from '../store/reducers/image.reducers';

const routes: Routes = [
  {
    path: 'project-name',
    component: ProjectNameComponent,
    canActivate: [AuthGuard],
  },
  { path: 'room-type', component: RoomTypeComponent, canActivate: [AuthGuard] },
  { path: 'style', component: StyleComponent, canActivate: [AuthGuard] },
  { path: 'budget', component: BudgetComponent, canActivate: [AuthGuard] },
  {
    path: 'remove-preview',
    component: RemovePreviewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'reconstruct-preview',
    component: RoomReconstructPreviewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'remove-editor',
    component: RemoveEditorComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'manual-reconstruct-editor',
    component: ManualReconstructEditorComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'reconstruct-editor',
    component: ReconstructEditorComponent,
    canActivate: [AuthGuard],
    canDeactivate: [
      (component: ReconstructEditorComponent): Observable<boolean> => inject(HasUnsavedChangesGuard).canDeactivate(component),
    ],
    resolve: {
      reloadResult: reloadResolver,
    },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'render',
    component: ReconstructEditorComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'product-page/:type',
    component: ProductPageComponent,
    canActivate: [AuthGuard],
  },
  { path: 'request', component: RequestComponent },
];

@NgModule({
  declarations: [
    ChooseFileComponent,
    ProjectNameComponent,
    RoomTypeComponent,
    StyleComponent,
    BudgetComponent,
    RemovePreviewComponent,
    OptionsPageComponent,
    RoomReconstructPreviewComponent,
    RemoveEditorComponent,
    ReconstructEditorComponent,
    SetHeightDialogComponent,
    CatalogCategoryComponent,
    CatalogCategoriesComponent,
    LeftPanelContainerComponent,
    CatalogDetailsComponent,
    ExportSettingsComponent,
    RenderComponent,
    VersionNameComponent,
    RequestComponent,
    LoadModelDialogComponent,
    ManualReconstructInstructionComponent,
    ManualReconstructEditorComponent,
    DetailsDialogComponent,
    AccessoriesComponent,
    ColorSelectContainerComponent,
    CurrentColorComponent,
    AllColorsComponent,
    UploadFileComponent,
    SceneDetailsComponent,
    VariantDetailsComponent,
    HasUnsavedChangesDialogComponent,
    MovementDialogComponent,
    RenderLoaderComponent,
    WallColorDialogComponent,
    RotationDialogComponent,
  ],
  imports: [
    FontAwesomeModule,
    CommonModule,
    EffectsModule.forFeature([ProjectsEffects, ImageEffects, RenderGUIEffects, ActiveProjectEffects]),
    FormsModule,
    InterceptorsModule,
    InfiniteScrollModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    MatSliderModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatMenuModule,
    MatSidenavModule,
    DragDropModule,
    ReactiveFormsModule,
    MatExpansionModule,
    RouterModule.forChild(routes),
    TranslateModule.forChild(TRANSLATE_MODULE_CONFIG),
    SafePipeModule,
    StoreModule.forFeature('image', imageReducer),
    SharedComponentsModule,
    NgOptimizedImage,
    AuthModule,
    MatSlideToggleModule,
  ],
  providers: [
    DeleteObjHttpService,
    ExportService,
    ImageFileParamsService,
    ImportService,
    KeyboardStateService,
    MovingObjectsService,
    LightObjectsService,
    ManualReconstructionService,
    OriginalImageSizeService,
    PhotorealisticRenderService,
    PlanesService,
    RoomParamsService,
    RoomService,
    HighlightedObjectsService,
    AutoPositioningService,
    RequestHttpService,
    RefreshNavigationService,
    CornersControlsService,
    ResolutionService,
    StyleFinderService,
    IdeasHttpService,
    FileSizeService,
    VersionService,
  ],
  entryComponents: [CatalogCategoryComponent, CatalogCategoriesComponent],
  exports: [ChooseFileComponent],
})
export class UserFlowModule {}
