import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { setOverlayLoadingSpinner } from 'src/app/store/actions/shared.actions';
import { ResolutionService } from 'src/app/user-flow/services/resolution.service';

import { UserService } from './user.service';
import { NotificationDialogComponent } from '../dialogs/notification-dialog/notification-dialog.component';

@UntilDestroy()
@Injectable()
export class ImportantNotificationsService {
  constructor(
    private _resolutionService: ResolutionService,
    @Inject(DOCUMENT) private _document: Document,
    private _dialog: MatDialog,
    private _userService: UserService,
    private _store: Store
  ) {}

  public limitNotification(): void {
    this._dialog
      .open(NotificationDialogComponent, {
        width: '640px',
        maxWidth: '95vw',
        height: this._resolutionService.isMobileResolution ? '340px' : '425px',
        data: {
          title: 'SHARED.LIMIT_REACHED.TITLE',
          message: 'SHARED.LIMIT_REACHED.MESSAGE',
          buttonName: 'SHARED.LIMIT_REACHED.UPGRADE',
          info: 'SHARED.LIMIT_REACHED.INFO',
        },
        backdropClass: 'cdk-overlay-transparent-backdrop',
        panelClass: 'important-notification-container',
        disableClose: true,
      })
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((data: boolean) => {
        if (data) {
          this._store.dispatch(setOverlayLoadingSpinner({ status: true }));
          this._userService
            .getSubscriptionsLink()
            .pipe(untilDestroyed(this))
            .subscribe(link => {
              this._store.dispatch(setOverlayLoadingSpinner({ status: false }));
              this._document.location.href = link;
            });
        }
      });
  }

  public importantNotification(title: string, message: string, showIcon: boolean, limitWidth: boolean, height: string): void {
    const config = {
      width: '550px',
      maxWidth: '95vw',
      height,
      data: {
        title,
        message,
        showIcon,
        limitWidth,
      },
      backdropClass: 'cdk-overlay-transparent-backdrop',
      panelClass: 'important-notification-container',
      disableClose: true,
    };
    const mobileConfig = { ...config, position: { top: '10px' }, height: 'auto', minHeight: '160px' };
    this._dialog
      .open(NotificationDialogComponent, this._resolutionService.isMobileResolution ? mobileConfig : config)
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe();
  }
}
