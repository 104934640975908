import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { IdeaTypes } from 'src/app/shared/enums/idea-type.enum';

import { SharedState } from '../shared.state';

export const SHARED_STATE_NAME = 'shared';

const sharedState = createFeatureSelector<SharedState>(SHARED_STATE_NAME);

export const getOverlayLoading = createSelector(sharedState, state => state.showOverlayLoading);

export const getRenderLoading = createSelector(sharedState, state => state.showRenderLoading);

export const mode = createSelector(sharedState, state => state.mode);

export const hasChanges = createSelector(sharedState, state => state.changes);

export const activeProject = createSelector(sharedState, state => state?.activeProject);

export const activeProjectVersionId = createSelector(sharedState, state => state?.activeProject?.versionId);

export const activeProjectName = createSelector(sharedState, state => state.activeProject?.name);

export const activeProjectId = createSelector(sharedState, state => state.activeProject?.id);

export const activeProjectRender = createSelector(sharedState, state => state.activeProject?.renders);

export const activeProjectWidthHeight = createSelector(sharedState, state => {
  return {
    width: state.activeProject?.width,
    height: state.activeProject?.height,
  };
});

export const activeProjectProposalData = createSelector(sharedState, state => {
  return {
    versionName: state.activeProject?.versionName,
    type: state.activeProject?.type,
    style: state.activeProject?.style,
    budget: state.activeProject?.budget,
    accessories: state.activeProject?.accessories,
  };
});

export const detectObjectsResult = createSelector(sharedState, state => {
  return state.savedObjectDetectionResult;
});

export const proposalResult = createSelector(sharedState, state => {
  return state.proposalResult;
});

export const activeProjectFile = createSelector(sharedState, state => state.activeProject?.file);

export const activeProjectType = createSelector(sharedState, state => state.activeProject?.type);

export const activeProjectSrc = createSelector(sharedState, state => state.activeProject?.src);

export const activeProjectImage = createSelector(sharedState, state => state.activeProject?.image);

export const activeProjectImageSrc = createSelector(sharedState, state => state.activeProject?.src);

export const activeProjectHandledBackgroundFile = createSelector(sharedState, state => state.activeProject?.handledBackgroundFile);

export const activeProjectSelectedStyles = createSelector(sharedState, state => state.activeProject?.selectedStyles);

export const activeProjectBudget = createSelector(sharedState, state => state.activeProject?.budget);

export const objectDetectionResult = createSelector(sharedState, state => state.savedObjectDetectionResult);

export const viewPhotoRealisticDisabled = createSelector(sharedState, state => state.activeProject?.renders);

export const rendersResult = createSelector(sharedState, state => {
  return { renders: state.rendersResult };
});

export const renderPhotoRealisticDisabled = createSelector(sharedState, state => {
  return !!state.activeProject?.renderJobId;
});

export const catalogData = createSelector(sharedState, state => {
  return state.catalogData;
});

export const furnitureDetails = createSelector(sharedState, state => {
  return state.furnitureDetails;
});

export const furnitureDetailsArticle = createSelector(sharedState, state => state?.furnitureDetails?.article);

export const furnitureDetailsFrom = createSelector(sharedState, state => state?.furnitureDetails?.from);

export const furnitureDetailsDescriptionTranslate = createSelector(sharedState, state => state?.furnitureDetails?.descriptionTranslate);

export const userName = createSelector(sharedState, state => {
  if (!state.user) {
    return null;
  }
  return `${state.user?.firstName} ${state.user?.lastName}`;
});

export const userRole = createSelector(sharedState, state => {
  if (!state.user) {
    return null;
  }
  return state.user?.role;
});

export const userPlanRoleRemainingIdeas = createSelector(sharedState, state => {
  if (!state.user) {
    return null;
  }
  return { plan: state.user?.plan, role: state.user?.role, remainingIdeas: state.user?.remainingIdeas };
});

export const userRoleRemainingProjects = createSelector(sharedState, state => {
  if (!state.user) {
    return null;
  }
  return { role: state.user?.role, remainingProjects: state.user?.remainingProjects };
});

export const userHasLoggedIn = createSelector(sharedState, state => {
  if (!state.user) {
    return null;
  }
  return state.user?.hasLoggedIn;
});

export const userPlan = createSelector(sharedState, state => {
  if (!state.user) {
    return null;
  }
  return state.user?.plan;
});

export const reconstructionResult = createSelector(sharedState, state => state.reconstructResult);

export const selectedMovingObject = createSelector(sharedState, state => state.selectedMovingObject);

export const errorHandler = createSelector(sharedState, state => state.error);

export const accessories = createSelector(sharedState, state => state.activeProject?.accessories);

export const articleLock = (sku: string): MemoizedSelector<object, boolean, (s1: SharedState) => boolean> =>
  createSelector(sharedState, state => state.proposal?.ProposedProposal?.Articles.find(el => el.Sku === sku)?.IsLocked);

export const isCatalogOpen = createSelector(sharedState, state => state.catalogOpen);

export const leftPanelState = createSelector(sharedState, state => state.leftPanelOpen);

export const uploadedMoodImagesCount = createSelector(sharedState, state => state.uploadedMoodImages.length);

export const lastViewedIdeaImages = createSelector(sharedState, state => state.lastViewedIdeaImages);

export const lastViewedIdeaImagesLoaded = createSelector(sharedState, state => state.ideasLoaded);

export const styles = createSelector(sharedState, state => state.styles);

export const proposedFurnitureColors = createSelector(sharedState, state => state.proposedFurnitureColors.data);

export const proposedFurnitureColorsLoaded = createSelector(sharedState, state => state.proposedFurnitureColors.loaded);

export const proposal = createSelector(sharedState, state => state.proposal);

export const alternatives = createSelector(sharedState, state => state.alternatives);

export const ideasPageIndex = (isAdmin: boolean, ideaType: IdeaTypes): MemoizedSelector<object, number, (s1: SharedState) => number> =>
  createSelector(sharedState, state => {
    if (isAdmin) {
      switch (ideaType) {
        case IdeaTypes.DEFAULT:
          return state.adminIdeas.defaultPageIndex;
        case IdeaTypes.NO_FURNITURE:
          return state.adminIdeas.noFurniturePageIndex;
        case IdeaTypes.STYLESWAP:
          return state.adminIdeas.styleswapPageIndex;
      }
    } else {
      switch (ideaType) {
        case IdeaTypes.DEFAULT:
          return state.userIdeas.defaultPageIndex;
        case IdeaTypes.NO_FURNITURE:
          return state.userIdeas.noFurniturePageIndex;
        case IdeaTypes.STYLESWAP:
          return state.userIdeas.styleswapPageIndex;
      }
    }
    return 0;
  });

export const lang = createSelector(sharedState, state => state.lang);
