import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { ERROR_SKIP_INTERCEPT } from './context-tokens/error.context-token';
import { AUTH_CONTEXT, AUTH_INTERCEPT } from './context-tokens/intercept.context-token';
import { InteriorResult } from './model/detect-floor-result.model';
import { DetectObjectsResult } from '../app/user-flow/services/model/dto/detect-object-result.model';

@Injectable({
  providedIn: 'root',
})
export class DetectObjHttpService {
  constructor(private _http: HttpClient) {}

  public detectObj(image: File, provider: string): Observable<DetectObjectsResult> {
    const formData = new FormData();
    formData.append('image', image);
    formData.append('providers', provider);
    return this._http.post<DetectObjectsResult>(environment.API_URL + '3rdparty/api-edenai/v2/image/object-detection', formData, {
      context: AUTH_CONTEXT,
    });
  }

  public detectInterior(image: File, skipError?: boolean): Observable<InteriorResult> {
    const context = skipError
      ? new HttpContext().set(AUTH_INTERCEPT, true).set(ERROR_SKIP_INTERCEPT, true)
      : new HttpContext().set(AUTH_INTERCEPT, true);
    const formdata = new FormData();
    formdata.append('image', image);
    return this._http.post<InteriorResult>(environment.API_URL + '3rdparty/pim-client-wizart/vision-api/v3/interior', formdata, {
      context,
    });
  }
}
