import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import _ from 'lodash';
import { BehaviorSubject, combineLatest, distinctUntilChanged, filter, map, Observable, of, switchMap, take, tap } from 'rxjs';
import { Plans } from 'src/app/shared/enums/plans.enum';
import { Roles } from 'src/app/shared/enums/roles.enum';
import {
  clearFurnitureDetails,
  getProposedFurnitureSuccess,
  setAccessories,
  setLeftPanelState,
  setProposal,
  setRenderLoadingSpinner,
  updateCatalogState,
} from 'src/app/store/actions/shared.actions';

import { IProjectAdditionalInfo, IVersion } from '../../../models/project';
import { ResourcesHttpService } from '../../../services/resources-http.service';
import { Mode } from '../../shared/enums/mode.enum';
import { ComponentCanDeactivate } from '../../shared/guards/has-unsaved-changes.guard';
import {
  activeProject,
  activeProjectImage,
  activeProjectImageSrc,
  furnitureDetails,
  getRenderLoading,
  isCatalogOpen,
  leftPanelState,
  mode,
  renderPhotoRealisticDisabled,
  userPlan,
  userRole,
  viewPhotoRealisticDisabled,
} from '../../store/selectors/shared.selector';
import { getRedoAction, getUndoAction } from '../../undo-redo/undo-redo.selectors';
import { LeftPanelContainerComponent } from '../catalog/left-panel-container/left-panel-container.component';
import { LeftPanelState } from '../catalog/left-panel-container/left-panel-state.enum';
import { MAX_HEIGHT } from '../const/windows-dimentions';
import { DetailsDialogComponent } from '../details-dialog/details-dialog.component';
import { ClickType } from '../enum/click.enum';
import { RoomType } from '../enum/room-type.enum';
import { Resolution } from '../export-settings/resolution.class';
import { HasUnsavedChangesDialogComponent } from '../has-unsaved-changes-dialog/has-unsaved-changes-dialog.component';
import { RenderInputParametersForm } from '../interfaces/render-input-parameters';
import { MovementDialogComponent } from '../movement-dialog/movement-dialog.component';
import { Action } from '../render/render-input-data.model';
import { RenderComponent } from '../render/render.component';
import { ExportService } from '../services/export.service';
import { ImageFileParamsService } from '../services/image-file-params.service';
import { ImportService } from '../services/import.service';
import { RoomPlane } from '../services/model/dto/plane.model';
import { SceneSaveData } from '../services/model/saveData/scene-save-data.model';
import { MovingObjectsService } from '../services/moving-objects.service';
import { OriginalImageSizeService } from '../services/original-image-size.service';
import { PhotorealisticRenderService } from '../services/photorealistic-render.service';
import { ReconstructionStateService } from '../services/reconstruction-state.service';
import { ResolutionService } from '../services/resolution.service';
import { RoomService } from '../services/room.service';
import { VersionService } from '../services/version.service';
import { VersionNameComponent } from '../version-name/version-name.component';
import { WallColorDialogComponent } from '../wall-color-dialog/wall-color-dialog.component';

@UntilDestroy()
@Component({
  selector: 'app-reconstruct-editor',
  templateUrl: './reconstruct-editor.component.html',
  styleUrls: ['./reconstruct-editor.component.scss'],
})
export class ReconstructEditorComponent implements OnInit, ComponentCanDeactivate, AfterViewInit {
  public photoRealisticDisabled$ = this._store.select(viewPhotoRealisticDisabled).pipe(map(renders => !renders || renders.length === 0));
  // or clause is added because button is not locked immediately
  public renderRealisticStateDisabled$ = this._store.select(renderPhotoRealisticDisabled);

  public movingMobileMenuActive$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public undoDisable$ = this._store.select(getUndoAction).pipe(
    untilDestroyed(this),
    map(data => !data)
  );
  public redoDisable$ = this._store.select(getRedoAction).pipe(
    untilDestroyed(this),
    map(data => !data)
  );
  public isCatalogOpen$ = this._store.select(isCatalogOpen);
  public showLoading$ = this._store.select(getRenderLoading).pipe(map(data => data.status));
  public loaderText$ = this._store.select(getRenderLoading).pipe(map(data => data.text));
  public isSceneExportAvailable$ = combineLatest([this._store.select(userPlan), this._store.select(userRole)]).pipe(
    map(([plan, userRole]) => Plans.BUSINESS === plan || Plans.PRO === plan || userRole === Roles.ADMIN)
  );
  public isUserAdmin$ = this._store.select(userRole).pipe(map(roles => roles?.includes(Roles.ADMIN)));

  public readonly CLICK_TYPES = ClickType;
  public renderSettingsForm = new FormGroup<RenderInputParametersForm>({
    renderSamplesCount: new FormControl(this._photorealisticRenderService.renderInputParams$.getValue().renderSamplesCount, [
      Validators.required,
    ]),
    shadowSamplesCount: new FormControl(this._photorealisticRenderService.renderInputParams$.getValue().shadowSamplesCount, [
      Validators.required,
    ]),
    resolution: new FormControl(this._photorealisticRenderService.renderInputParams$.getValue().resolution, [Validators.required]),
  });
  public readonly samplesOptions = [8, 16, 32, 64, 128, 256, 512, 1024, 2048];
  public renderResolutionOptions = [];

  private _scaleToResolutions = [
    new Resolution(1280, 720, 'HD'),
    new Resolution(1920, 1080, 'Full HD'),
    new Resolution(2048, 1440, '2K DCI'),
  ];
  public debugOutlineEnabled = true;
  public mods = Mode;
  public clickType: ClickType = ClickType.MOVE_MODEL_MOUSE;
  public type = ClickType;
  public mode$ = this._store.select(mode);
  public leftPanelStateMobile: LeftPanelState;

  private _leftPanelOpen = false;

  private _mode: Mode;
  private _project: IProjectAdditionalInfo;
  private _movingObjectSelected = false;
  private _currentMovingObjectId: string | null = null;

  private readonly _maxHeightMobile = 430;

  public get movingObjectSelected(): boolean {
    return this._movingObjectSelected;
  }

  public get leftPanelOpen(): boolean {
    return this._leftPanelOpen;
  }

  @ViewChild('render')
  private _renderComponent: RenderComponent;
  @ViewChild('leftPanel', { static: false })
  private _leftPanel: LeftPanelContainerComponent;
  @ViewChild('scene', { static: false })
  private _scene: ElementRef;

  constructor(
    public resolutionService: ResolutionService,
    private _router: Router,
    private _store: Store,
    private _photorealisticRenderService: PhotorealisticRenderService,
    private _resourcesHttpService: ResourcesHttpService,
    private _importService: ImportService,
    private _imageFileParamsService: ImageFileParamsService,
    private _dialog: MatDialog,
    private _movingObjectsService: MovingObjectsService,
    private _reconstructionStateService: ReconstructionStateService,
    private _versionService: VersionService,
    private _changeDetectionRef: ChangeDetectorRef,
    private _exportService: ExportService,
    private _originalImageSizeService: OriginalImageSizeService,
    private _roomService: RoomService
  ) {}

  public ngOnInit(): void {
    combineLatest([this._store.select(activeProject), this._store.select(mode)])
      .pipe(
        filter(data => !!data && !!data[0] && data[1] !== null),
        take(1),
        untilDestroyed(this)
      )
      .subscribe(([project, mode]) => {
        this._project = project;
        this._mode = mode;
      });
    this._subscribeToDetailsPanel();
    this._subscribeToRoomService();
    this._subscribeToLeftPanel();
    this._trackRenderParameters();
  }

  public ngAfterViewInit(): void {
    const height = this._scene?.nativeElement.clientHeight;
    const width = this._scene?.nativeElement.clientWidth;

    if (!(!!this._project || !!this._mode)) return;

    switch (this._mode) {
      case Mode.CREATE:
        this._imageFileParamsService
          .getImageParams(
            this._project.file,
            this._project.file.name,
            this.resolutionService.isMobileResolution
              ? window.innerWidth - 40 // mobile case
              : width,
            this.resolutionService.isMobileResolution ? Math.min(this._maxHeightMobile, height) : MAX_HEIGHT
          )
          .pipe(
            switchMap(imageParams => {
              return this._renderComponent.run(Mode.CREATE, {
                src: this._project.src,
                width: imageParams.width,
                height: imageParams.height,
                type: this._project.type as RoomType,
                sceneSaveData: this._reconstructionStateService.load(),
                actions: [Action.HIDE_RECONSTRUCTION_COLOR, Action.MAKE_PROPOSAL_AND_RENDER],
              });
            }),
            untilDestroyed(this)
          )
          .subscribe();
        break;

      case Mode.EDIT:
        this._store.dispatch(
          setRenderLoadingSpinner({
            status: true,
            text: 'USER_FLOW.LABELS.OPEN_PROJECT',
          })
        );
        this._changeDetectionRef.detectChanges();

        combineLatest([this._loadBackground(MAX_HEIGHT, width), this._loadSceneFile()])
          .pipe(
            switchMap(data => {
              this._store.dispatch(setAccessories({ data: data[1].accessories }));
              this._store.dispatch(setProposal({ data: data[1].proposal }));
              this._store.dispatch(
                getProposedFurnitureSuccess({
                  data: data[1].proposedFurnitureColors,
                })
              );

              return this._renderComponent.run(Mode.EDIT, {
                src: data[0].src,
                width: data[0].width,
                height: data[0].height,
                type: data[0].type as RoomType,
                sceneSaveData: data[1],
              });
            }),
            tap(() => this._store.dispatch(setRenderLoadingSpinner({ status: false, text: '' }))),
            untilDestroyed(this)
          )
          .subscribe();
        break;
    }
  }

  private _openDetailsDialog(uuid: string = undefined, from?: LeftPanelState): void {
    this.leftPanelStateMobile = null;
    this._changeDetectionRef.markForCheck();
    this._dialog
      .open(DetailsDialogComponent, {
        position: { bottom: '0' },
        maxWidth: '100vw',
        width: '100vw',
        maxHeight: '93vh',
        height: '93vh',
        panelClass: 'mobile-dialog',
        data: { uuid, from },
        backdropClass: 'cdk-overlay-transparent-backdrop',
      })
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        if (from === LeftPanelState.CATALOG_DETAILS) {
          this.leftPanelStateMobile = LeftPanelState.CATALOG_CATEGORY;
        }
        this._changeDetectionRef.markForCheck();
      });
  }

  public openLeftPanel(status: boolean): void {
    this._store.dispatch(setLeftPanelState({ state: status }));

    if (this.leftPanelOpen) {
      this._leftPanel.leftPanelState = LeftPanelState.CATALOG_CATEGORIES;
    }
  }

  public openCatalog(): void {
    this.leftPanelStateMobile = LeftPanelState.CATALOG_CATEGORIES;
    this._store.dispatch(updateCatalogState({}));
  }

  private _loadSceneFile(): Observable<SceneSaveData> {
    return this._store.select(activeProject).pipe(
      filter(data => !!data),
      take(1),
      switchMap(activeProject => this._resourcesHttpService.loadResource(activeProject.model)),
      switchMap((blob: Blob) => this._importService.loadScene(blob)),
      untilDestroyed(this)
    );
  }

  private _subscribeToDetailsPanel(): void {
    this._store
      .select(furnitureDetails)
      .pipe(
        filter(data => !!data),
        distinctUntilChanged((a, b) => _.isEqual(a, b)),
        untilDestroyed(this)
      )
      .subscribe(data => {
        if (this.resolutionService.isMobileResolution) {
          this._openDetailsDialog(this._currentMovingObjectId, data.from);
        } else {
          this._store.dispatch(setLeftPanelState({ state: !!data?.article }));
          if (this._leftPanel) {
            this._leftPanel.leftPanelState = data?.from ?? LeftPanelState.CATALOG_CATEGORIES;
          }
        }
      });
  }

  private _subscribeToLeftPanel(): void {
    this._store
      .select(leftPanelState)
      .pipe(untilDestroyed(this))
      .subscribe(state => {
        this._leftPanelOpen = state;
      });
  }

  private _trackRenderParameters(): void {
    this.renderSettingsForm.valueChanges
      .pipe(
        filter(value => !!value && !!value.renderSamplesCount && !!value.shadowSamplesCount && !!value.resolution),
        untilDestroyed(this)
      )
      .subscribe(value => {
        this._photorealisticRenderService.renderInputParams$.next({
          renderSamplesCount: value.renderSamplesCount,
          shadowSamplesCount: value.shadowSamplesCount,
          resolution: value.resolution,
        });
      });

    this._store
      .select(activeProjectImageSrc)
      .pipe(
        filter(src => !!src),
        switchMap(src => this._originalImageSizeService.getImageParamsSrc(src)),
        untilDestroyed(this)
      )
      .subscribe(params => {
        const originalResolution = new Resolution(params.width, params.height, 'Original');
        const scaledFromOriginal = this._scaleToResolutions.map(r => originalResolution.scaleToWithFlip(r)).sort((a, b) => a.area - b.area);
        const highestResolutionAvailable = scaledFromOriginal.at(-1);
        this.renderResolutionOptions = [...scaledFromOriginal];

        if (originalResolution.area <= highestResolutionAvailable.area) {
          this.renderResolutionOptions.unshift(originalResolution);
          this.renderSettingsForm.controls.resolution.setValue(originalResolution, { emitEvent: false });
        } else {
          this.renderSettingsForm.controls.resolution.setValue(highestResolutionAvailable, { emitEvent: false });
        }
      });
  }

  private _subscribeToRoomService(): void {
    this._roomService.selectedMovingObjectId$.pipe(untilDestroyed(this)).subscribe(uuid => {
      this._movingObjectSelected = !!uuid;
      this._currentMovingObjectId = uuid ?? null;
    });
  }

  private _loadBackground(height: number, width: number): Observable<IVersion> {
    const mobileWidth = window.innerWidth - 40;
    return this._store.select(activeProjectImage).pipe(
      filter(data => !!data),
      take(1),
      switchMap(image =>
        this._resourcesHttpService.loadResource(image).pipe(
          map((blob: File) => {
            return { blob: blob, image };
          })
        )
      ),
      switchMap((data: { blob: File; image: string }) => {
        return this._imageFileParamsService.updateVersionWithImage(
          data.blob,
          data.image,
          this.resolutionService.isMobileResolution ? mobileWidth : width,
          this.resolutionService.isMobileResolution ? Math.min(this._maxHeightMobile, height) : height
        );
      }),
      switchMap(() =>
        this._store.select(activeProject).pipe(
          filter(data => !!data),
          take(1)
        )
      ),
      untilDestroyed(this)
    );
  }

  public toggleFurnitureDetails(): void {
    this.clickType = this.clickType === ClickType.MOVE_MODEL_MOUSE ? ClickType.VIEW_FURNITURE_CLICK : ClickType.MOVE_MODEL_MOUSE;
  }

  public back(): void {
    this._store.dispatch(setLeftPanelState({ state: false }));
    this._store.dispatch(updateCatalogState({ state: false }));
    this._store.dispatch(clearFurnitureDetails());
    this._router.navigate(['project/', this._project.id]);
  }

  public deleteAllFurniture(): void {
    this._renderComponent.makeActions([Action.CLEAR_FURNITURE]).pipe(untilDestroyed(this)).subscribe();
  }

  public backward(): void {
    this._renderComponent.makeActions([Action.BACKWARD]).pipe(untilDestroyed(this)).subscribe();
  }

  public forward(): void {
    this._renderComponent.makeActions([Action.FORWARD]).pipe(untilDestroyed(this)).subscribe();
  }

  public save(): void {
    this._renderComponent.makeActions([Action.UPDATE_VERSION]).pipe(untilDestroyed(this)).subscribe();
  }

  public saveAs(): void {
    this._movingObjectsService.disableKeyboard();
    this._dialog
      .open(VersionNameComponent, {
        height: '220px',
        width: '300px',
        disableClose: true,
      })
      .afterClosed()
      .subscribe(() => {
        this._movingObjectsService.disableKeyboard();
        this._renderComponent.makeActions([Action.SAVE_VERSION]).pipe(untilDestroyed(this)).subscribe();
      });
  }

  public exportAsUSDZ(): void {
    this._exportService.exportSceneUSDZ();
  }

  public exportAsGlb(): void {
    this._exportService.exportSceneGlb(this._roomService.getLightTarget());
  }

  public exportAsAdminGltf(): void {
    this._exportService.exportSceneWithWalls(this._roomService.getLightTarget());
  }

  public exportSceneParams(): void {
    const blob = new Blob([JSON.stringify(this._photorealisticRenderService.cameraRenderParams)]);
    const link = document.createElement('a');
    link.style.display = 'none';
    document.body.appendChild(link);
    link.href = URL.createObjectURL(blob);
    link.download = 'params.json';
    link.click();

    this._exportService.exportScene(this._roomService.getLightTarget());
  }

  public makeProposal(): void {
    this.clickType = ClickType.MOVE_MODEL_MOUSE;
    this._renderComponent.makeActions([Action.MAKE_PROPOSAL]).pipe(untilDestroyed(this)).subscribe();
  }

  public startRender(): void {
    this._renderComponent.makeActions([Action.START_RENDER]).pipe(untilDestroyed(this)).subscribe();
  }

  public showRender(): void {
    this._renderComponent.makeActions([Action.SHOW_RENDER]).pipe(untilDestroyed(this)).subscribe();
  }

  public hasChanges(): Observable<boolean> {
    return this._store.select(getUndoAction).pipe(
      take(1),
      map(data => !!data),
      untilDestroyed(this)
    );
  }

  public showDeactivateDialog(): Observable<boolean> {
    return this._dialog
      .open(HasUnsavedChangesDialogComponent, {
        width: '360px',
        height: '400px',
        panelClass: 'no-scroll-dialog',
      })
      .afterClosed()
      .pipe(
        switchMap(result => (result ? this._versionService.updateVersion() : of(result))),
        map(result => result != null),
        untilDestroyed(this)
      );
  }

  public openMovements(): void {
    // if truthy, nothing was selected hence nothing could be moved
    // could display notification that nothing was selected as part of user experience improvement
    if (!this._movingObjectSelected) return;

    this.movingMobileMenuActive$.next(true);
    this._dialog
      .open(MovementDialogComponent, {
        position: { bottom: '0' },
        width: '100vw',
        maxWidth: '100vw',
        maxHeight: '30vh',
        panelClass: 'mobile-dialog',
        backdropClass: 'cdk-overlay-transparent-backdrop',
      })
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(() => this.movingMobileMenuActive$.next(false));
  }

  public onWallClick(roomPlane: RoomPlane): void {
    if (this.resolutionService.isMobileResolution) {
      this._dialog.open(WallColorDialogComponent, {
        position: { bottom: '0' },
        maxWidth: '100vw',
        maxHeight: `93vh`,
        panelClass: 'mobile-dialog',
        data: { wall: roomPlane },
        backdropClass: 'cdk-overlay-transparent-backdrop',
      });
    } else {
      this._leftPanel.handleWallClick(roomPlane);
    }
  }
}
