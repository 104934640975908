import { createReducer, on } from '@ngrx/store';

import { IdeasActions } from '../action-types';
import { initialIdeasState } from '../ideas.state';

export const ideasReducer = createReducer(
  initialIdeasState,
  on(IdeasActions.getIdeasSuccess, (state, action) => {
    return {
      ...state,
      ideas: action.ideas,
    };
  }),
  on(IdeasActions.setIdeaImageSuccess, (state, action) => {
    const ideas = state.ideas;

    if (!ideas) {
      return {
        ...state,
        ideasLoaded: false,
      };
    }

    const ideasUpdateContent = ideas.content.map(idea => {
      return {
        ...idea,
        resizedSrc: idea.id === action.data.id ? action.data.src : idea.resizedSrc,
      };
    });

    return {
      ...state,
      ideas: {
        ...ideas,
        content: ideasUpdateContent,
      },
    };
  }),
  on(IdeasActions.getIdeaImageSuccess, (state, action) => {
    const idea = Object.assign(
      {},
      state.ideas.content.find(idea => idea.link === action.data.link)
    );
    idea.src = action.data.src;
    idea.blob = action.data.blob;

    return {
      ...state,
      activeIdea: idea,
    };
  }),
  on(IdeasActions.clearActiveIdea, state => {
    return {
      ...state,
      activeIdea: null,
    };
  }),
  on(IdeasActions.clearIdeasTable, state => {
    return {
      ...state,
      ideasLoaded: false,
      ideas: null,
    };
  }),
  on(IdeasActions.clearActiveIdeaFurniture, state => {
    return {
      ...state,
      searchResults: null,
      ideasSimilarFurniture: null,
      furnitureDetails: null,
    };
  }),
  on(IdeasActions.loadIdeaSimilarFurnitureSuccess, (state, action) => {
    return {
      ...state,
      ideasSimilarFurniture: action.data,
    };
  }),
  on(IdeasActions.viewIdeaFurnitureSuccess, (state, action) => {
    return {
      ...state,
      furnitureDetails: {
        article: action.data,
        descriptionTranslate: null,
      },
    };
  }),
  on(IdeasActions.translateIdeaFurnitureDescriptionSuccess, (state, action) => {
    return {
      ...state,
      furnitureDetails: {
        ...state.furnitureDetails,
        descriptionTranslate: action.description,
      },
    };
  }),
  on(IdeasActions.updateIdeaSuccess, (state, action) => {
    const ideas = state.ideas.content.map(idea =>
      idea.id === action.idea.id
        ? {
            ...idea,
            detectedFurniture: action.idea.detectedFurniture,
            lastDetectionDate: action.idea.lastDetectionDate,
          }
        : idea
    );
    return {
      ...state,
      ideas: {
        ...state.ideas,
        content: ideas,
      },
      activeIdea: action.idea,
    };
  })
);
