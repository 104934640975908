import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Injectable()
export class TranslationLoaderGuard {
  constructor(private translate: TranslateService) {}

  public canActivate(): Observable<boolean> {
    return new Observable(observer => {
      this.translate.get('LAST.DUMMY').subscribe({
        next: () => {
          observer.next(true);
        },
        error: error => {
          observer.next(false);
          observer.error(error);
        },
        complete: () => {
          observer.complete();
        },
      });
    });
  }

  public canActivateChild(): Observable<boolean> {
    return new Observable(observer => {
      this.translate.get('LAST.DUMMY').subscribe({
        next: () => {
          observer.next(true);
        },
        error: error => {
          observer.next(false);
          observer.error(error);
        },
        complete: () => {
          observer.complete();
        },
      });
    });
  }
}
