import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { updateActiveProject } from 'src/app/store/actions/shared.actions';
import { activeProjectType } from 'src/app/store/selectors/shared.selector';
import { IProjectAdditionalInfo } from 'src/models/project';

import { InputType } from '../enum/input-types.enum';
import { RoomType } from '../enum/room-type.enum';
import { Option } from '../interfaces/option';
import { RefreshNavigationService } from '../services/refresh-navigation.service';

@UntilDestroy()
@Component({
  selector: 'app-room-type',
  templateUrl: './room-type.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoomTypeComponent implements OnInit {
  public options: Option[] = [
    {
      label: 'USER_FLOW.LABELS.LIVING_ROOM',
      value: RoomType.LIVING,
      checked: true,
      img: './../../../assets/project-3d-images/Stylefinder_LivingRoom.jpg',
    },
    {
      label: 'USER_FLOW.LABELS.DINING_ROOM',
      value: RoomType.DINING,
      checked: false,
      img: './../../../assets/project-3d-images/dining-room.jpg',
    },
    {
      label: 'USER_FLOW.LABELS.BED_ROOM',
      value: RoomType.SLEEPING,
      checked: false,
      img: './../../../assets/project-3d-images/Stylefinder_SleepingRoom.jpg',
    },
    {
      label: 'USER_FLOW.LABELS.EMPTY_ROOM',
      value: RoomType.UNSET,
      checked: false,
      img: './../../../assets/project-3d-images/empty-room.jpg',
    },
  ];
  public projectInfo: IProjectAdditionalInfo = {};
  public inputType: InputType = InputType.RADIO;

  constructor(
    private _store: Store,
    private _router: Router,
    private _refreshNavigationService: RefreshNavigationService,
    private _cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this._store
      .select(activeProjectType)
      .pipe(untilDestroyed(this))
      .subscribe(type => {
        this.options = this.options.map(option => {
          if (option.value === type) {
            return {
              ...option,
              checked: true,
            };
          }
          return {
            ...option,
            checked: false,
          };
        });
        if (!type) {
          this.options[0].checked = true;
        }
        this._cdr.markForCheck();
      });

    this._refreshNavigationService.refreshPageEvent$.pipe(untilDestroyed(this)).subscribe(() => this._router.navigate(['choose-file']));
  }

  public next(value: string): void {
    this.projectInfo.type = value;
    this._store.dispatch(updateActiveProject({ projectInfo: this.projectInfo }));
    this._router.navigate(['style']);
  }

  public back(value: string): void {
    this.projectInfo.type = value;
    this._store.dispatch(updateActiveProject({ projectInfo: this.projectInfo }));
    this._router.navigate(['project-name']);
  }

  public onChange(event): void {
    if (event.action === 'back') {
      this.back(event.value);
    } else {
      this.next(event.value);
    }
  }
}
