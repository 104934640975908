<div 
  class="back" 
  (click)="back()"
  aria-hidden="true"
>
    <img class="icon" src="./../../../../assets/icons/arrow-left-slide.png" alt="next">
    <span class="r-16">{{ 'USER_FLOW.CATALOG.TITLE' | translate}}</span>
</div>
<div *ngIf="!(catalogData$ | async)?.length" class="no-data">{{ "USER_FLOW.CATALOG.NO_DATA" | translate}}</div>
<div class="catalog-content" 
  infinite-scroll 
  [infiniteScrollDistance]="2"
  [infiniteScrollThrottle]="500"
  (scrolled)="onScroll()"
  [scrollWindow]="false">
  <div 
    class="article-item" 
    (click)="viewDetails(furniture)" 
    *ngFor="let furniture of (catalogData$ | async); let i = index"
    aria-hidden="true"
    >
    <div class="image-wrapper" *ngIf="furniture.BasicArticleInformation.PackshotImages">
      <img 
        class="box" 
        [src]="furniture.BasicArticleInformation.PackshotImages[0] | safe: 'url'" 
        alt="image"
        />
    </div>
    <div class="article-description">
      <div class="article-text r-14">{{ furniture.BasicArticleInformation.Title }}</div>
    </div>
  </div>
</div>
