import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as Hull from 'hull.js';
import * as NCSColor from 'ncs-color';
import { Notification } from 'src/app/notifications/model/notification';
import { NotificationsService } from 'src/app/notifications/services/notifications.service';
import { getCurrentAction } from 'src/app/undo-redo/undo-redo.selectors';
import {
  BufferGeometry,
  Color,
  ColorRepresentation,
  DoubleSide,
  Float32BufferAttribute,
  Group,
  Material,
  MathUtils,
  Mesh,
  MeshBasicMaterial,
  MeshLambertMaterial,
  MeshStandardMaterial,
  Plane,
  PlaneGeometry,
  Quaternion,
  RepeatWrapping,
  ShadowMaterial,
  Shape,
  ShapeGeometry,
  SphereGeometry,
  TextureLoader,
  Vector2,
  Vector3,
} from 'three';
import { ConvexGeometry } from 'three/examples/jsm/geometries/ConvexGeometry';
import { Line2 } from 'three/examples/jsm/lines/Line2';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial';
import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer';

import { getPointBetween } from './autopositioning.function';
import { WallPointGroup } from './class/wall-point-group.class';
import { WallPoint } from './class/wall-point.class';
import { COLOR_WALL, SHADOW_WALL_BASIC, SHADOW_WALL_WINDOW } from './const/blender.const';
import { OUTLINE_COLOR, PLACE_OPACITY, PLANE_COLORS, WINDOW } from './const/colors.const';
import { PlaneType } from './enum/plane-type.enum';
import { PointPlace } from './enum/point-place.type';
import { hasSimilarCoordinates } from './functions/has-simillar-coordinates.function';
import { getXYShape } from './functions/surface.utils';
import { PlanesServiceConfig } from './model/config/planes-service-config.model';
import { PointPlane, RoomPlane } from './model/dto/plane.model';
import { PlanesSaveLoadData } from './model/saveData/planes-save-data.model';
import { ResolutionService } from './resolution.service';
import { RoomParamsService } from './room-params.service';
import {
  ADD_PLANE,
  ADD_WALL_CORNER,
  addPlane,
  addWallCorner,
  CHANGE_WALL_COLOR,
  DELETE_PLANE,
  DELETE_WALL_CORNER,
  deletePlane,
  MOVE_WALL_CORNER,
} from '../../store/actions/render.actions';
import { ColorGroup } from '../catalog/color-select-container/color-group.enum';
import { NcsColor } from '../catalog/color-select-container/ncs-color.model';
import { Current } from '../interfaces/current';
import { PointType } from '../manual-reconstruct-editor/holes.enum';

@Injectable()
export class PlanesService {
  private readonly _wallCornerBaseRadius = 0.08;
  private readonly _wallCornerMobileRadius = 0.16;

  private _planes: RoomPlane[] = [];
  private _config: PlanesServiceConfig;

  private _shadowGroup: Group;
  private _planesGroup: Group;
  private _wallCornersGroup: WallPointGroup;

  constructor(
    private _store: Store,
    private _roomParamsService: RoomParamsService,
    private _notificationsService: NotificationsService,
    private _translateService: TranslateService,
    private _resolutionService: ResolutionService,
    @Inject(DOCUMENT) private _document: Document
  ) {}

  public setInitialState(): void {
    this._shadowGroup = null;
    this._planesGroup = null;
    this._wallCornersGroup = null;
    this._config = null;
    this._planes = [];
  }

  private _getHullCenter(points: { x: number; y: number }[]): { x: number; y: number } {
    const first = points[0];
    const last = points[points.length - 1];

    if (first.x != last.x || first.y != last.y) {
      points.push(first);
    }

    let twiceArea = 0;
    let x = 0;
    let y = 0;
    let p1;
    let p2;
    let f;

    for (let i = 0, j = points.length - 1; i < points.length; j = i++) {
      p1 = points[i];
      p2 = points[j];
      f = p1.x * p2.y - p2.x * p1.y;
      twiceArea += f;
      x += (p1.x + p2.x) * f;
      y += (p1.y + p2.y) * f;
    }

    f = twiceArea * 3;

    return { x: x / f, y: y / f };
  }

  public getRoomCenter(): Vector3 {
    this._wallCornersGroup.updateMatrixWorld(true);

    const floorPoints = this._wallCornersGroup.children
      .filter(corner => corner.userData['planes'].find(planePoint => PlaneType.FLOOR === planePoint.type))
      .map(corner => corner.position);

    const floorY = floorPoints[0].y;

    const hullPoints: [number, number][] = Hull(
      floorPoints.map(point => [point.x, point.z]),
      0.1
    );

    const mappedHullPoints = hullPoints.map(pair => ({ x: pair[0], y: pair[1] }));

    const center2 = this._getHullCenter(mappedHullPoints);

    return new Vector3(center2.x, floorY, center2.y);
  }

  public configure(config: PlanesServiceConfig, planes?: RoomPlane[]): void {
    if (planes) {
      this._planes = planes;
    }
    this._config = config;

    this._shadowGroup = this._config.scene.getObjectByName('shadowGroup') as Group;
    this._planesGroup = this._config.scene.getObjectByName('planesGroup') as Group;
    this._wallCornersGroup = this._config.scene.getObjectByName('wallCornersGroup') as WallPointGroup;

    this._store.select(getCurrentAction).subscribe(current => {
      if (current) {
        switch (current.action.name) {
          case ADD_PLANE:
            this._addPlane(current);
            break;
          case DELETE_PLANE:
            this._deletePlane(current);
            break;
          case ADD_WALL_CORNER:
            this._addWallCorner(current);
            break;
          case DELETE_WALL_CORNER:
            this._deleteWallCorner(current);
            break;
          case MOVE_WALL_CORNER:
            this._moveWallCorner(current);
            break;
          case CHANGE_WALL_COLOR:
            this._changeWallColor(current);
            break;
        }
      }
    });
  }

  private _changeWallColor(current: Current): void {
    const from: NcsColor = current.action.data.from;
    const to: NcsColor = current.action.data.to;
    const id: string = current.action.data.id;

    const plane = this._planes.find(plane => plane.id === id);

    if (current.command === 'undo') {
      if (from?.Code) {
        this.colorWalls(plane, { color: from, colorFixed: false });
      } else {
        this.clearWallsColor(plane);
      }
    } else {
      if (to?.Code) {
        this.colorWalls(plane, { color: to, colorFixed: false });
      } else {
        this.clearWallsColor(plane);
      }
    }
  }

  public deleteDuplicateWallPoints(): void {
    const points = this._wallCornersGroup.children;
    const uniquePoints: WallPoint[] = [];

    points.forEach((currentPoint, currentIndex) => {
      const index = points.findIndex(point => hasSimilarCoordinates(currentPoint.position, point.position));

      if (index !== currentIndex) {
        const uniquePoint = uniquePoints.find(point => hasSimilarCoordinates(currentPoint.position, point.position));

        if (uniquePoint) {
          const uniquePlanes: PointPlane[] = uniquePoint.userData['planes'];
          const currentPlanes: PointPlane[] = currentPoint.userData['planes'];
          uniquePlanes.push(currentPlanes[0]);
        }
      } else {
        uniquePoints.push(currentPoint);
      }
    });

    this._wallCornersGroup.children = uniquePoints;

    this._planes = this._planes.map(plane => ({
      ...plane,
      corners: plane.corners
        .slice()
        .filter(point => point.userData['type'] === PointType.CORNER)
        .map(point => this._wallCornersGroup.children.find(corner => corner.position.distanceTo(point.position) < 0.1) as Mesh),
    }));
  }

  public clear(): void {
    this._planes = [];
  }

  public getAll(): RoomPlane[] {
    this._planes = this._planes.map(plane => ({
      ...plane,
      corners: plane.corners?.length > 0 ? plane.corners : this._getCorners(plane),
    }));

    return this._planes;
  }

  public get hasPlanes(): boolean {
    return this._planes?.length > 0;
  }

  public getSaveData(): PlanesSaveLoadData {
    return {
      planes: this._planes.map(plane => {
        const result = {
          ...plane,
          meshUUID: plane.mesh.uuid,
          cornersUUIDs: plane.corners.map(corner => corner.uuid),
          windowsUUIDs: plane.windows.map(window => window.map(point => point.uuid)),
          doorsUUIDs: plane.doors.map(door => door.map(point => point.uuid)),
        };
        delete result.mesh;
        return result;
      }),
    };
  }

  public delete(id: string, touchStore?: boolean): void {
    const index: number = this._planes.findIndex(object => object.id === id);
    const plane = this._planes[index];

    if (index > -1) {
      this._planes.splice(index, 1);
      if (plane.type === PlaneType.FLOOR || plane.type === PlaneType.CEILING) {
        this._roomParamsService.setParams({
          height3D: 0,
          heightMeters: 0,
          width3D: 0,
          depth3D: 0,
          objectStartPosition: new Vector3(),
        });
      }
    }

    const points = this._wallCornersGroup.children
      .filter(corner => corner.userData['planes'].some(pointPlane => pointPlane.planeId === plane.id))
      .map(corner => corner.position);

    this._shadowGroup.children = this._shadowGroup.children.filter(child => child.userData['id'] !== id);
    this._planesGroup.children = this._planesGroup.children.filter(child => child.userData['id'] !== id);
    this._wallCornersGroup.children = this._wallCornersGroup.children.filter(child => {
      child.userData['planes'] = child.userData['planes'].filter(plane => plane.planeId !== id);
      return child.userData['planes'].length > 0;
    });

    if (touchStore) {
      this._store.dispatch(deletePlane({ data: { id, points, type: plane.type } }));
    }
  }

  private _convertToConvexSurface(points: Vector3[]): Vector3[] {
    const hullPoints: number[][] = Hull(
      points.map(point => [point.x, point.y]),
      0.1
    );
    const notDistinctPoints = hullPoints.map(hullPoint => new Vector3(hullPoint[0], hullPoint[1], points[0].z));
    return notDistinctPoints.filter((p1, i1) => notDistinctPoints.findIndex(p2 => p2.equals(p1)) === i1);
  }

  private _getHoleShape(
    first: Vector3,
    second: Vector3,
    quaternionXY: Quaternion,
    z: number
  ): { shape: Shape; points: { position: Vector3; place: PointPlace }[] } {
    const leftTop = first.clone().applyQuaternion(quaternionXY).setZ(z);
    const rightBottom = second.clone().applyQuaternion(quaternionXY).setZ(z);
    const rightTop = new Vector3(rightBottom.x, leftTop.y, leftTop.z).setZ(z);
    const leftBottom = new Vector3(leftTop.x, rightBottom.y, leftTop.z).setZ(z);

    const points = [
      { position: leftTop, place: PointPlace.LEFT_TOP },
      { position: rightTop, place: PointPlace.RIGHT_TOP },
      { position: rightBottom, place: PointPlace.RIGHT_BOTTOM },
      { position: leftBottom, place: PointPlace.LEFT_BOTTOM },
    ];

    return {
      shape: new Shape(points.map(point => new Vector2(point.position.x, point.position.y))),
      points,
    };
  }

  private _createHoleMesh(points: Vector3[]): Mesh[] {
    return points.map(point => {
      const pointMesh = new Mesh();

      pointMesh.position.set(point.x, point.y, point.z);

      return pointMesh;
    });
  }

  private _getWallShapeGeometry(
    points: Vector3[],
    windows?: { first: Vector3; second: Vector3 }[],
    doors?: { first: Vector3; second: Vector3 }[],
    planeId?: string
  ): {
    geometry: BufferGeometry;
    quaternionBack: Quaternion;
    windows: WallPoint[][];
    doors: WallPoint[][];
  } {
    const { baseShape, quaternionXY, quaternionBack, z } = getXYShape(points);

    const finalWindows: WallPoint[][] = [];
    windows?.forEach(({ first, second }) => {
      const { shape, points } = this._getHoleShape(first, second, quaternionXY, z);
      const meshes = points.map(point =>
        this.getWallPointMesh(
          point.position.clone().applyQuaternion(quaternionBack),
          PointType.WINDOW,
          [{ planeId, type: PlaneType.WALL }],
          point.place
        )
      );

      finalWindows.push(meshes);
      baseShape.holes.push(shape);
    });

    const finalDoors: WallPoint[][] = [];
    doors?.forEach(({ first, second }) => {
      const { shape, points } = this._getHoleShape(first, second, quaternionXY, z);
      const meshes = points.map(point =>
        this.getWallPointMesh(
          point.position.clone().applyQuaternion(quaternionBack),
          PointType.DOOR,
          [{ planeId, type: PlaneType.WALL }],
          point.place
        )
      );

      finalDoors.push(meshes);
      baseShape.holes.push(shape);
    });

    const geometry = new ShapeGeometry(baseShape);
    const position: Float32BufferAttribute = geometry.attributes['position'] as Float32BufferAttribute;
    for (let i = 0; i < position.array.length; i++) {
      position.setZ(i, z);
    }
    position.needsUpdate = true;

    geometry.applyQuaternion(quaternionBack);

    return { geometry, quaternionBack, windows: finalWindows, doors: finalDoors };
  }

  private _getPlaneMesh(points: Vector3[], type: PlaneType): Mesh {
    return new Mesh(new ConvexGeometry(points), this._getPlaneMaterial(type));
  }

  private _getPlaneMaterial(type: PlaneType): Material {
    return new MeshBasicMaterial({
      color: PLANE_COLORS[type],
      transparent: true,
      opacity: PLACE_OPACITY[type],
      side: DoubleSide,
    });
  }

  private _getOutline(points: Vector3[]): Line2 {
    const positions = [];
    const colors = [];

    const color = new Color();
    color.setHex(OUTLINE_COLOR);

    points.forEach(point => {
      positions.push(point.x, point.y, point.z);
      colors.push(color.r, color.g, color.b);
    });

    const geometry = new LineGeometry();
    geometry.setPositions(positions);
    geometry.setColors(colors);

    const material = new LineMaterial({
      color: 0xe8c37f,
      transparent: false,
      linewidth: 0.005, // in pixels
      vertexColors: true,
      dashed: false,
    });

    const line = new Line2(geometry, material);
    line.computeLineDistances();
    line.scale.set(1, 1, 1);

    return line;
  }

  private _getGeometryFromXYShape(shape: Shape, quaternionBack: Quaternion, z: number): ShapeGeometry {
    const geometry = new ShapeGeometry(shape);
    const position: Float32BufferAttribute = geometry.attributes['position'] as Float32BufferAttribute;
    for (let i = 0; i < position.array.length; i++) {
      position.setZ(i, z);
    }
    position.needsUpdate = true;
    geometry.applyQuaternion(quaternionBack);
    return geometry;
  }

  public add(points: Vector3[], type: PlaneType, touchStore?: boolean, shouldOutline?: boolean, id?: string): void {
    if (points.length > 0) {
      const { baseShape, quaternionBack, outlinePoints, z } = getXYShape(points);

      const mesh = new Mesh(this._getGeometryFromXYShape(baseShape, quaternionBack, z), this._getPlaneMaterial(type));

      if (shouldOutline) {
        const outline = this._getOutline(outlinePoints);
        mesh.userData['hasOutline'] = true;
        mesh.add(outline);
      }

      const fake = new Mesh(mesh.geometry, new MeshBasicMaterial({ side: DoubleSide, colorWrite: false }));
      fake.name = COLOR_WALL;
      fake.receiveShadow = true;

      const shadow: Mesh = new Mesh(mesh.geometry, new ShadowMaterial({ transparent: true, side: DoubleSide, opacity: 0.5 }));
      shadow.name = SHADOW_WALL_BASIC;
      shadow.receiveShadow = true;

      fake.userData['type'] = type;
      mesh.userData['type'] = type;
      shadow.userData['type'] = type;

      this._planesGroup.add(mesh);
      this._shadowGroup.add(shadow);
      this._shadowGroup.add(fake);

      const plane: RoomPlane = {
        id: mesh.uuid,
        mesh: mesh,
        type,
        windows: [],
        doors: [],
        corners: points.map(point => this._addWallCornerForPlane(mesh.uuid, type, point)),
        quaternionBack,
        z,
        colorData: {
          color: {
            Code: undefined,
            Hex: undefined,
            Group: ColorGroup.NO_COLOR,
          },
          colorFixed: false,
        },
      };

      if (id) {
        plane.id = id;
      }

      fake.userData['id'] = plane.id;
      mesh.userData['id'] = plane.id;
      shadow.userData['id'] = plane.id;

      this._planes.push(plane);

      if (touchStore) {
        this._store.dispatch(addPlane({ data: { id: plane.id, points, type } }));
      }
    }
  }

  public checkRoomParams(): void {
    const floor = this._planes.find(plane => plane.type === PlaneType.FLOOR);
    const ceiling = this._planes.find(plane => plane.type === PlaneType.CEILING);
    if (!this._roomParamsService.canAutoscale && !!floor && !!ceiling) {
      this._roomParamsService.setRoomHeightDialog();
      this._roomParamsService.calculateParams();
    }
  }

  public addWallCorner(position: Vector3, touchStore?: boolean, planes?: PointPlane[], uuid?: string): Mesh {
    const wallPoint = this.getWallPointMesh(position, PointType.CORNER, planes);
    this._wallCornersGroup.add(wallPoint);
    if (uuid) {
      wallPoint.uuid = uuid;
    }
    if (touchStore) {
      this._store.dispatch(addWallCorner({ data: { id: wallPoint.uuid, position } }));
    }
    return wallPoint;
  }

  public addHelper(name: string, material: Material, position: Vector3, type: PlaneType): void {
    const helper = new Mesh(new PlaneGeometry(100, 100), material);
    helper.name = name;
    helper.position.set(position.x, position.y, position.z);
    switch (type) {
      case PlaneType.FLOOR:
        helper.rotateX(MathUtils.degToRad(-90));
        break;
      case PlaneType.CEILING:
        helper.rotateX(MathUtils.degToRad(90));
        break;
    }
    this._planesGroup.add(helper);
    this._planesGroup.updateMatrixWorld(true);
  }

  public removeHelper(name: string): void {
    const helper = this._planesGroup.getObjectByName(name);
    this._planesGroup.remove(helper);
  }

  public getWallPointMesh(position: Vector3, type: PointType, planes?: PointPlane[], place?: PointPlace): WallPoint {
    let color: ColorRepresentation;
    switch (type) {
      case PointType.CORNER:
        color = OUTLINE_COLOR;
        break;
      case PointType.WINDOW:
        color = 0xc468ff;
        break;
      case PointType.DOOR:
        color = 0x8adaff;
        break;
    }

    const radius = this._resolutionService.isMobileResolution ? this._wallCornerMobileRadius : this._wallCornerBaseRadius;

    const touchContainer = new WallPoint(
      new SphereGeometry(radius * 3, 10, 10),
      new MeshStandardMaterial({ transparent: true, opacity: 0 })
    );

    touchContainer.renderOrder = 1;

    const coloredMesh = new Mesh(new SphereGeometry(radius, 10, 10), new MeshBasicMaterial({ color }));

    touchContainer.add(coloredMesh);

    touchContainer.position.set(position.x, position.y, position.z);

    touchContainer.userData['planes'] = planes ?? [];
    touchContainer.userData['type'] = type;
    touchContainer.userData['place'] = place;

    return touchContainer;
  }

  public findOriginal(id: string): RoomPlane {
    return this._planes.find(plane => plane.id === id);
  }

  public find(id: string): RoomPlane {
    return this.getAll().find(plane => plane.id === id);
  }

  public getWindows(): Vector3[][] {
    return this._planes.reduce((prev, curr) => {
      if (curr.windows.length) {
        curr.windows.forEach(window => prev.push(window.map(point => point.position)));
      }
      return prev;
    }, []);
  }

  public clearAllTempPlanes(): void {
    this._planes.forEach(plane => this._clearTempPlanesAndCorners(plane));
  }

  private _clearTempPlanesAndCorners(plane: RoomPlane): void {
    const deletedPlaneMeshes = this._planesGroup.children
      .slice()
      .filter(planeMesh => !!planeMesh.userData['isTempPlane'] && plane.id === planeMesh.userData['basePlaneId']);

    deletedPlaneMeshes.forEach(meshForDeleting => {
      meshForDeleting.remove(meshForDeleting.children[0]);
      this._planesGroup.remove(meshForDeleting);
    });

    plane.windows.forEach(window => window.forEach(corner => this._wallCornersGroup.remove(corner)));
    plane.doors.forEach(window => window.forEach(corner => this._wallCornersGroup.remove(corner)));
  }

  private _createTempPlaneAndCorners(planes: WallPoint[][], showCorners: boolean): void {
    planes.forEach(plane => {
      plane.forEach(point => {
        this._wallCornersGroup.add(point);
        point.visible = showCorners;
      });
      this._createTempPlane(plane);
    });
  }

  public redrawWithHoles(
    id: string,
    windows: { first: Vector3; second: Vector3 }[],
    doors: { first: Vector3; second: Vector3 }[],
    showCorners: boolean
  ): void {
    const plane = this.findOriginal(id);

    if (plane) {
      this._clearTempPlanesAndCorners(plane);

      const data = this._getWallShapeGeometry(
        plane.corners.map(corner => corner.position),
        windows,
        doors,
        plane.id
      );
      const material = new MeshBasicMaterial({
        color: PLANE_COLORS[plane.type],
        transparent: true,
        opacity: PLACE_OPACITY[plane.type],
        side: DoubleSide,
      });
      const oldMesh = plane.mesh;
      oldMesh.geometry.dispose();
      (oldMesh.material as any).dispose();
      plane.mesh = new Mesh(data.geometry, material);
      plane.mesh.uuid = oldMesh.uuid;
      plane.mesh.userData['id'] = plane.id;
      plane.windows = data.windows;
      plane.doors = data.doors;
      plane.quaternionBack = data.quaternionBack;

      this._planesGroup.children = this._planesGroup.children.filter(plane => plane.uuid !== oldMesh.uuid);
      this._planesGroup.add(plane.mesh);

      this._createTempPlaneAndCorners(plane.windows, showCorners);
      this._createTempPlaneAndCorners(plane.doors, showCorners);

      const fake = new Mesh(data.geometry, new MeshBasicMaterial({ side: DoubleSide, colorWrite: false }));
      fake.name = COLOR_WALL;
      fake.receiveShadow = true;
      fake.userData['id'] = plane.id;

      const shadow: Mesh = new Mesh(data.geometry, new ShadowMaterial({ transparent: true, side: DoubleSide, opacity: 0.5 }));
      shadow.name = SHADOW_WALL_WINDOW;
      shadow.receiveShadow = true;
      shadow.userData['id'] = plane.id;

      this._shadowGroup.children = this._shadowGroup.children.filter(mesh => mesh.userData['id'] !== plane.id);
      this._shadowGroup.children.push(shadow);
      this._shadowGroup.children.push(fake);
    }
  }

  private _getTempPlaneLabel(type: PointType, points: Vector3[]): CSS2DObject {
    const center = getPointBetween(points[0], points[2], points[0].distanceTo(points[2]) / 2);

    const text = this._translateService.instant('BUTTONS.' + type.toUpperCase());

    const div = this._document.createElement('div');
    div.className = 'label';
    div.style.color = 'rgb(0,0,0)';
    div.textContent = text;

    const label = new CSS2DObject(div);
    label.position.copy(center);

    return label;
  }

  private _getTempPlaneMesh(points: Vector3[]): Mesh {
    const material = new MeshBasicMaterial({
      color: WINDOW.color,
      opacity: WINDOW.opacity,
      transparent: true,
    });
    const geometry = new ConvexGeometry(points);
    const mesh = new Mesh(geometry, material);
    mesh.name = 'TEMP';
    return mesh;
  }

  private _createTempPlane(points: WallPoint[]): void {
    if (points.length > 0) {
      const type = points[0].userData.type;
      const planeId = points[0].userData.planes[0].planeId;
      const vectorPoints = points.map(point => point.position.clone());
      const label = this._getTempPlaneLabel(type, vectorPoints);
      const mesh = this._getTempPlaneMesh(vectorPoints);
      const outline = this._getOutline([...vectorPoints, vectorPoints[0]]);
      mesh.userData['hasOutline'] = true;
      mesh.userData['isTempPlane'] = true;
      mesh.userData['basePlaneId'] = planeId;
      mesh.add(label);
      mesh.add(outline);
      this._planesGroup.add(mesh);
    }
  }

  private _getCorners(plane: RoomPlane): Mesh[] {
    return this._wallCornersGroup.children.filter(corner =>
      corner.userData['planes'].some(pointPlane => pointPlane.planeId === plane.id)
    ) as Mesh[];
  }

  private _addWallCornerForPlane(planeId: string, planeType: PlaneType, position: Vector3, touchStore?: boolean): Mesh {
    const planes: PointPlane[] = [
      {
        planeId: planeId,
        type: planeType,
      },
    ];
    return this.addWallCorner(position, touchStore, planes);
  }

  private _addPlane(current: Current): void {
    if (current.command === 'undo') {
      const id = current.action.data.id;
      this.delete(id, false);
    } else {
      this.add(current.action.data.points, current.action.data.type, false, current.action.data.id);
      this.deleteDuplicateWallPoints();
      this.checkRoomParams();
    }
  }

  private _deletePlane(current: Current): void {
    if (current.command === 'redo') {
      const id = current.action.data.id;
      this.delete(id, false);
    } else {
      this.add(current.action.data.points, current.action.data.type, false, current.action.data.id);
      this.deleteDuplicateWallPoints();
      this.checkRoomParams();
    }
  }

  private _addWallCorner(current: Current): void {
    if (current.command === 'undo') {
      this._wallCornersGroup.children = this._wallCornersGroup.children.filter(corner => corner.uuid !== current.action.data.id);
    } else {
      this.addWallCorner(current.action.data.position, false, [], current.action.data.id);
    }
  }

  private _deleteWallCorner(current: Current): void {
    if (current.command === 'redo') {
      this._wallCornersGroup.children = this._wallCornersGroup.children.filter(corner => corner.uuid !== current.action.data.id);
    } else {
      this.addWallCorner(current.action.data.position, false, [], current.action.data.id);
    }
  }

  private _moveWallCorner(current: Current): void {
    if (current.command === 'undo') {
      const id = current.action.data.id;
      const start = current.action.data.start;
      this._moveTo(id, start);
    } else {
      const id = current.action.data.id;
      const end = current.action.data.end;
      this._moveTo(id, end);
    }
  }

  private _moveTo(objectId: string, point: Vector3): void {
    const object = this._wallCornersGroup.getObjectByProperty('uuid', objectId) as WallPoint;
    object.position.set(point.x, point.y, point.z);
  }

  private _projectPointOnPlane(point: Vector3, plane: Plane): Vector3 {
    const target = new Vector3();
    plane.projectPoint(point, target);
    return target;
  }

  private _planeIsVisible(corners: Mesh[]): boolean {
    const sortedPoints = corners.map(corner => corner.position).sort((p1, p2) => (p1.y > p2.y ? 1 : -1));

    const cameraVector = new Vector3();

    this._config.camera.getWorldDirection(cameraVector);

    const firstDirection = sortedPoints[0].clone().setY(cameraVector.y);
    const secondDirection = sortedPoints[1].clone().setY(cameraVector.y);

    const angle = firstDirection.angleTo(secondDirection);

    return angle * MathUtils.RAD2DEG > 10;
  }

  private _planeHasWidth(corners: Mesh[], width: number): boolean {
    const sortedPoints = corners.map(corner => corner.position).sort((p1, p2) => (p1.y > p2.y ? 1 : -1));

    return sortedPoints[0].distanceTo(sortedPoints[1]) >= this._roomParamsService.get3dFromMeter(width);
  }

  public chooseMainWall(): number {
    let wall: RoomPlane;
    let width: number;

    const allWalls = this._planes.slice().filter(plane => plane.type === PlaneType.WALL);

    const allBigWalls = allWalls.slice().filter(plane => this._planeHasWidth(plane.corners, 2.5) && this._planeIsVisible(plane.corners));

    const allMediumWalls = allWalls.slice().filter(plane => this._planeHasWidth(plane.corners, 2) && this._planeIsVisible(plane.corners));

    const withoutHolesBigWalls = allBigWalls.slice().filter(plane => !plane.windows.length && !plane.doors.length);
    const withoutHolesMediumWalls = allMediumWalls.slice().filter(plane => !plane.windows.length && !plane.doors.length);

    if (withoutHolesBigWalls.length > 0) {
      if (withoutHolesBigWalls.length === 1) {
        wall = withoutHolesBigWalls[0];
      }

      if (withoutHolesBigWalls.length > 1) {
        const sortedByPerimeter = withoutHolesBigWalls.sort((p1, p2) =>
          this._getPerimeter(p1.corners) < this._getPerimeter(p2.corners) ? 1 : -1
        );
        wall = sortedByPerimeter[0];
      }
    } else if (withoutHolesMediumWalls.length > 0) {
      if (withoutHolesMediumWalls.length === 1) {
        wall = withoutHolesMediumWalls[0];
      }

      if (withoutHolesMediumWalls.length > 1) {
        const sortedByPerimeter = withoutHolesMediumWalls.sort((p1, p2) =>
          this._getPerimeter(p1.corners) < this._getPerimeter(p2.corners) ? 1 : -1
        );
        wall = sortedByPerimeter[0];
      }
    } else if (allBigWalls.length > 0) {
      wall = allBigWalls[0];
    } else if (allMediumWalls.length > 0) {
      wall = allMediumWalls[0];
    } else {
      wall = allWalls[0];
    }

    if (wall) {
      const floorPoints = wall?.corners?.filter(corner => corner.position.y < 0);
      width = this._roomParamsService.getMeterFrom3D(floorPoints[0].position.distanceTo(floorPoints[1].position));
      this._planes = this._planes.map(plane => ({ ...plane, isMainWall: plane.id === wall.id }));
    }

    return width;
  }

  public getMainWall(): RoomPlane {
    return this._planes.find(plane => plane.isMainWall);
  }

  private _getPerimeter(corners: Mesh[]): number {
    const top: Vector3[] = corners.filter(corner => corner.position.y > 0).map(corner => corner.position);
    const bottom: Vector3[] = corners.filter(corner => corner.position.y < 0).map(corner => corner.position);

    if (top.length > 1 && bottom.length > 1) {
      const left: Vector3[] = [top[0], bottom.sort((c1, c2) => (c1.distanceTo(top[0]) < c2.distanceTo(top[0]) ? 1 : -1))[0]];
      const right: Vector3[] = [top[1], bottom.sort((c1, c2) => (c1.distanceTo(top[1]) < c2.distanceTo(top[1]) ? 1 : -1))[0]];

      return top[0].distanceTo(top[1]) + bottom[0].distanceTo(bottom[1]) + left[0].distanceTo(left[1]) + right[0].distanceTo(right[1]);
    }

    return 0;
  }

  public clearWallsColor(mainWall: RoomPlane): void {
    const material = new MeshLambertMaterial({
      colorWrite: false,
    });

    mainWall.colorData = {
      color: {
        Code: undefined,
        Hex: undefined,
        Group: ColorGroup.NO_COLOR,
      },
      colorFixed: false,
    };

    const fake: Mesh = this._shadowGroup.children.find(
      mesh => mesh.name.includes(COLOR_WALL) && mesh.userData['id'] === mainWall.id
    ) as Mesh;
    fake.material = material;
    fake.name = COLOR_WALL;
  }

  public colorWalls(mainWall: RoomPlane, colorData: { color: NcsColor; colorFixed: boolean }): void {
    if (mainWall && colorData) {
      const textureLoader = new TextureLoader();
      const normalMap = textureLoader.load('assets/textures/concrete_floor_worn_001_nor_dx_1k.png');
      const diffuseMap = textureLoader.load('assets/textures/concrete_floor_worn_001_diff_1k.png');
      diffuseMap.wrapS = RepeatWrapping;
      diffuseMap.wrapT = RepeatWrapping;
      const combinedMap = textureLoader.load('assets/textures/concrete_floor_worn_001_arm_1k.png');

      const material = new MeshLambertMaterial({
        side: DoubleSide,
        colorWrite: true,
        map: diffuseMap,
        normalMap: normalMap,
        aoMap: combinedMap,
        color: colorData.color?.Hex ?? NCSColor.rgb(`NCS S ${colorData.color?.Code}`),
      });

      mainWall.colorData = colorData;

      const fake: Mesh = this._shadowGroup.children.find(
        mesh => mesh.name.includes(COLOR_WALL) && mesh.userData['id'] === mainWall.id
      ) as Mesh;
      fake.name = COLOR_WALL + '_0x' + colorData.color?.Hex?.toUpperCase().slice(1);
      fake.material = material;
    } else {
      this._notificationsService.addNotification(
        new Notification({
          title: this._translateService.instant('NOTIFICATIONS.TITLE.ERROR'),
          text: this._translateService.instant('NOTIFICATIONS.MESSAGES.CONVERT_NCL_TO_RGB'),
          level: 'error',
          options: { timeout: 2 },
        })
      );
    }
  }

  public clearAllWalls(): void {
    this._planes.forEach(plane => this.clearWallsColor(plane));
  }
}
