import { createReducer, on } from '@ngrx/store';

import { ImageActions } from '../action-types';
import { initialImageState } from '../image.state';

export const imageReducer = createReducer(
  initialImageState,
  on(ImageActions.deleteSelectionSuccess, (state, action) => {
    return {
      ...state,
      clearedImage: { image: action.src, updated: true },
    };
  }),
  on(ImageActions.updateImage, (state, action) => {
    return {
      ...state,
      clearedImage: { image: action.src, updated: false },
    };
  }),
  on(ImageActions.clearImageState, state => {
    return {
      ...state,
      ...initialImageState,
    };
  })
);
