import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, map, Observable, take, tap } from 'rxjs';
import { accessories, proposal, proposedFurnitureColors } from 'src/app/store/selectors/shared.selector';

import { ExportService } from './export.service';
import { SceneSaveData } from './model/saveData/scene-save-data.model';
import { MovingObjectsService } from './moving-objects.service';
import { PlanesService } from './planes.service';
import { RoomParamsService } from './room-params.service';
import { RoomService } from './room.service';

@Injectable({
  providedIn: 'root',
})
export class ReconstructionStateService {
  private _sceneSaveData: SceneSaveData;

  constructor(
    private _exportService: ExportService,
    private _movingObjectsService: MovingObjectsService,
    private _planesService: PlanesService,
    private _roomService: RoomService,
    private _roomParamsService: RoomParamsService,
    private _store: Store
  ) {}

  public save(): Observable<void> {
    return this.getSaveData().pipe(
      tap(saveData => (this._sceneSaveData = saveData)),
      map(() => {})
    );
  }

  public getSaveData(): Observable<SceneSaveData> {
    return combineLatest(this._store.select(accessories), this._store.select(proposal), this._store.select(proposedFurnitureColors)).pipe(
      take(1),
      map(data => {
        return {
          proposal: data[1],
          accessories: data[0] ?? [],
          movingObjectsData: this._movingObjectsService.getSaveData(),
          planesData: this._planesService.getSaveData(),
          roomData: this._roomService.getSaveData(),
          roomParams: this._roomParamsService.get(),
          proposedFurnitureColors: data[2],
        };
      })
    );
  }

  public load(): SceneSaveData {
    return this._sceneSaveData;
  }
}
