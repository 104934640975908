import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { DEFAULT_LANGUAGE, LANGUAGES, TRANSLATE_MODULE_CONFIG, TranslateLanguagesEnum } from '../shared/configs/translate.config';
import { setLanguage } from '../store/actions/shared.actions';

@NgModule({
  imports: [HttpClientModule, TranslateModule.forRoot(TRANSLATE_MODULE_CONFIG)],
  exports: [TranslateModule],
})
export class I18nModule {
  constructor(
    private _translateService: TranslateService,
    private _store: Store
  ) {
    this._translateService.addLangs(LANGUAGES.map((lang: { id: TranslateLanguagesEnum; value: string }) => lang.id.toString()));
    if (LANGUAGES.findIndex(lang => lang.id === localStorage.getItem('lang')) !== -1) {
      this._translateService.use(localStorage.getItem('lang'));
      this._store.dispatch(setLanguage({ lang: localStorage.getItem('lang') }));
    } else if (LANGUAGES.findIndex(lang => lang.id === this._translateService.getBrowserLang()) !== -1) {
      this._translateService.use(this._translateService.getBrowserLang());
      localStorage.setItem('lang', this._translateService.getBrowserLang());
      this._store.dispatch(setLanguage({ lang: this._translateService.getBrowserLang() }));
    } else {
      this._translateService.use(DEFAULT_LANGUAGE.id);
      localStorage.setItem('lang', DEFAULT_LANGUAGE.id);
      this._store.dispatch(setLanguage({ lang: DEFAULT_LANGUAGE.id }));
    }
  }
}

export function translateLoaderFactory(httpClient: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(httpClient);
}
