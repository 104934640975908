import { DOCUMENT } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { filter, Observable } from 'rxjs';
import { updateActiveProject } from 'src/app/store/actions/shared.actions';
import { activeProjectName, activeProjectSrc } from 'src/app/store/selectors/shared.selector';
import { IProjectAdditionalInfo } from 'src/models/project';

import { InputType } from '../enum/input-types.enum';
import { RefreshNavigationService } from '../services/refresh-navigation.service';

@UntilDestroy()
@Component({
  selector: 'app-project-name',
  templateUrl: './project-name.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectNameComponent implements OnInit, AfterViewInit {
  public projectName = 'Projekt 1';
  public inputType: InputType = InputType.INPUT;
  public projectInfo: IProjectAdditionalInfo = {};
  public img$: Observable<string> = this._store.select(activeProjectSrc);

  constructor(
    private _store: Store,
    private _router: Router,
    private _refreshNavigationService: RefreshNavigationService,
    @Inject(DOCUMENT) private _document: Document
  ) {}

  public ngOnInit(): void {
    this._refreshNavigationService.refreshPageEvent$.pipe(untilDestroyed(this)).subscribe(() => this._router.navigate(['choose-file']));

    this._store
      .select(activeProjectName)
      .pipe(
        filter(name => !!name),
        untilDestroyed(this)
      )
      .subscribe(name => {
        this.projectName = name;
      });
  }

  public ngAfterViewInit(): void {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    this._document.body.scrollTop = 0;
  }

  public nextEvent(value: string): void {
    this.projectInfo.name = value;
    this._store.dispatch(updateActiveProject({ projectInfo: this.projectInfo }));
    this._router.navigate(['room-type']);
  }

  public backEvent(): void {
    this._router.navigate(['create', 'projects']);
  }

  public onChange(event): void {
    if (event.action === 'back') {
      this.backEvent();
    } else {
      this.nextEvent(event.value);
    }
  }
}
