import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { filter, map, mergeMap, Observable, switchMap, take, tap } from 'rxjs';
import { Notification } from 'src/app/notifications/model/notification';
import { NotificationsService } from 'src/app/notifications/services/notifications.service';
import { IList } from 'src/app/shared/interfaces/list';
import { IVersion } from 'src/models/project';
import { ProjectsVersionsHttpService } from 'src/services/projects-versions-http.service';
import { ResourcesHttpService } from 'src/services/resources-http.service';

import { VersionService } from '../../user-flow/services/version.service';
import { VersionsActions } from '../action-types';
import { setOverlayLoadingSpinner } from '../actions/shared.actions';
import {
  allVersionsLoaded,
  loadAllVersions,
  removeVersionSuccess,
  saveVersionSuccess,
  updateVersionSuccess,
  versionLoaded,
  versionSrcLoaded,
} from '../actions/versions.actions';
import { activeProjectId } from '../selectors/shared.selector';

@UntilDestroy()
@Injectable()
export class VersionsEffects {
  public loadVersions$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(VersionsActions.loadAllVersions),
      tap(() => this._store.dispatch(setOverlayLoadingSpinner({ status: true }))),
      switchMap(action =>
        this._projectsVersionsHttpService.getVersions(action.id, action.pageIndex, action.pageSize, action.name).pipe(
          map((versions: IList<IVersion>) => {
            const projectId = action.id;
            return { projectId, versions };
          })
        )
      ),
      filter(data => data.versions.content !== null),
      map((data: { projectId: string; versions: IList<IVersion> }) => {
        this._store.dispatch(setOverlayLoadingSpinner({ status: false }));
        const allVersions = data.versions.content;
        allVersions.forEach(version => {
          const previewVersion = version.renders.length > 0 ? version.renders[0].image : version.image;
          if (previewVersion) {
            this._resourcesHttpService
              .loadCroppedResource(previewVersion, 320, 240)
              .pipe(
                map(blob => {
                  return { projectId: data.projectId, id: version.id, blob };
                }),
                mergeMap((data: { projectId: string; id: string; blob: Blob }) => {
                  return new Observable<{ id: string; src: string; projectId: string }>(subscriber => {
                    const src = URL.createObjectURL(data.blob);
                    subscriber.next({ src, id: data.id, projectId: data.projectId });
                  });
                }),
                untilDestroyed(this)
              )
              .subscribe((data: { id: string; src: string; projectId: string }) => this._store.dispatch(versionSrcLoaded({ data })));
          }
        });
        return allVersionsLoaded({ id: data.projectId, versions: data.versions });
      })
    );
  });

  public loadVersion$ = createEffect(() =>
    this._actions$.pipe(
      ofType(VersionsActions.loadVersion),
      tap(() => this._store.dispatch(setOverlayLoadingSpinner({ status: true }))),
      switchMap(action => this._projectsVersionsHttpService.getVersion(action.versionId)),
      switchMap(version =>
        this._store.select(activeProjectId).pipe(
          take(1),
          map(id => ({ id, version }))
        )
      ),
      map(data => {
        this._store.dispatch(setOverlayLoadingSpinner({ status: false }));
        return versionLoaded(data);
      })
    )
  );

  public removeVersion$ = createEffect(() =>
    this._actions$.pipe(
      ofType(VersionsActions.removeVersion),
      tap(() => this._store.dispatch(setOverlayLoadingSpinner({ status: true }))),
      switchMap(action =>
        this._projectsVersionsHttpService.removeVersion(action.versionId).pipe(
          map(() => {
            return { versionId: action.versionId, projectId: action.projectId, pageSize: action.pageSize, pageIndex: action.pageIndex };
          })
        )
      ),
      map(data => {
        this._notificationsService.addNotification(
          new Notification({
            title: this._translateService.instant('NOTIFICATIONS.TITLE.SUCCESS'),
            text: this._translateService.instant('NOTIFICATIONS.MESSAGES.VERSION_REMOVED'),
            level: 'success',
            options: { timeout: 2 },
          })
        );
        this._store.dispatch(setOverlayLoadingSpinner({ status: false }));
        this._store.dispatch(loadAllVersions({ id: data.projectId, pageIndex: data.pageIndex, pageSize: data.pageSize }));
        return removeVersionSuccess({ versionId: data.versionId, projectId: data.projectId });
      })
    )
  );

  public saveVersion$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(VersionsActions.saveVersion),
        switchMap(() => this._versionService.saveVersion()),
        map(() => saveVersionSuccess())
      ),
    { dispatch: false }
  );

  public updateVersion$ = createEffect(() =>
    this._actions$.pipe(
      ofType(VersionsActions.updateVersion),
      switchMap(() => this._versionService.updateVersion()),
      map(() => updateVersionSuccess())
    )
  );

  constructor(
    private _actions$: Actions,
    private _notificationsService: NotificationsService,
    private _projectsVersionsHttpService: ProjectsVersionsHttpService,
    private _resourcesHttpService: ResourcesHttpService,
    private _versionService: VersionService,
    private _store: Store,
    private _translateService: TranslateService
  ) {}
}
