import { createAction, props } from '@ngrx/store';
import { IList } from 'src/app/shared/interfaces/list';
import { IProject } from 'src/models/project';

export const ALL_PROJECTS_LOADED = '[Projects Resolver] All Projects Loaded';
export const LOAD_ALL_PROJECTS = '[Projects Resolver] Load All Projects';
export const LOAD_PROJECTS_SRC = '[Projects list] load Project Src';
export const PROJECTS_LOADED = '[Projects list] Projects Loaded';
export const PROJECT_SRC_LOADED = '[Projects list] Project Src Loaded';
export const REMOVE_PROJECT = '[Projects list] remove Project';
export const REMOVE_PROJECT_SUCCESS = '[Projects list] remove Project Success';
export const SAVE_PROJECT = '[Create Page] save Project';
export const CLEAR_SELECTED_PROJECT = '[App Component] clear Selected project';
export const CLEAR_PROJECTS_STATE = '[App Component] clear projects state';
export const UPDATE_PROJECTS = '[User Flow] update projects';

export const loadAllProjects = createAction(LOAD_ALL_PROJECTS, props<{ pageIndex: number; pageSize: number; name?: string }>());

export const allProjectsLoaded = createAction(ALL_PROJECTS_LOADED, props<{ projects: IList<IProject> }>());

export const loadProjectSrc = createAction(LOAD_PROJECTS_SRC, props<{ project: IProject }>());

export const projectsLoaded = createAction(PROJECTS_LOADED);

export const projectSrcLoaded = createAction(PROJECT_SRC_LOADED, props<{ data: { id: string; src: string } }>());

export const removeProject = createAction(REMOVE_PROJECT, props<{ id: string; size: number; index: number; total: number }>());

export const removeProjectSuccess = createAction(REMOVE_PROJECT_SUCCESS, props<{ id: string }>());

export const saveProject = createAction(SAVE_PROJECT, props<{ name: string }>());

export const clearSelectedProject = createAction(CLEAR_SELECTED_PROJECT);

export const clearProjectsState = createAction(CLEAR_PROJECTS_STATE);

export const updateProjects = createAction(UPDATE_PROJECTS);
