import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'budget',
})
export class BudgetPipe implements PipeTransform {
  public transform(budget: number): string {
    return budget.toString().substring(0, budget.toString().length - 3) + "'" + budget.toString().substring(budget.toString().length - 3);
  }
}
