import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tabName',
})
export class TabNamePipe implements PipeTransform {
  public transform(value: string): string {
    return `SHARED.TABS.${value}`;
  }
}
