import { Pipe, PipeTransform } from '@angular/core';
import { BasicArticleInformation } from 'src/services/model/proposal';

@Pipe({
  name: 'material',
})
export class MaterialPipe implements PipeTransform {
  public transform(basicArticleInformation: BasicArticleInformation): string {
    return basicArticleInformation?.Options.find(el => el.Name === 'Material')?.Value;
  }
}
