import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-no-data-page',
  templateUrl: './no-data-page.component.html',
  styleUrls: ['./no-data-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoDataPageComponent {
  @Input() public message;
  @Input() public buttonDisabled = false;
  @Output() public create = new EventEmitter<void>();

  public startNow(): void {
    this.create.emit();
  }
}
