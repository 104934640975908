import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IList } from 'src/app/shared/interfaces/list';
import { environment } from 'src/environments/environment';
import { IProject, IVersion } from 'src/models/project';

import { AUTH_CONTEXT } from './context-tokens/intercept.context-token';
import { ISaveVersion, IUpdateVersion } from './model/project-version';

export interface ISaveProject {
  name: string;
  id: string;
  preview: string;
}

@Injectable({
  providedIn: 'root',
})
export class ProjectsVersionsHttpService {
  constructor(private _http: HttpClient) {}

  public saveProject(name: string): Observable<ISaveProject> {
    const body: { name: string } = { name };
    return this._http.post<ISaveProject>(environment.API_URL + 'projects', body, { context: AUTH_CONTEXT });
  }

  public saveVersion(data: ISaveVersion, id: string): Observable<IVersion> {
    return this._http.post(environment.API_URL + `projects/${id}/versions`, data, { context: AUTH_CONTEXT });
  }

  public updateVersion(data: IUpdateVersion): Observable<IVersion> {
    return this._http.put(environment.API_URL + `projects/versions`, data, { context: AUTH_CONTEXT });
  }

  public getProjects(pageIndex = 0, pageSize = 0, name?: string): Observable<IList<IProject>> {
    let params;
    if (name) {
      params = new HttpParams().set('size', pageSize).set('page', pageIndex).set('name', name).set('sort', 'modificationDate,desc');
    } else {
      params = new HttpParams().set('size', pageSize).set('page', pageIndex).set('sort', 'modificationDate,desc');
    }
    return this._http.get<IList<IProject>>(environment.API_URL + 'projects', { params, context: AUTH_CONTEXT });
  }

  public getVersions(id: string, pageIndex = 0, pageSize = 0, name?: string): Observable<IList<IVersion>> {
    let params;
    if (name) {
      params = new HttpParams().set('size', pageSize).set('page', pageIndex).set('name', name).set('sort', 'modificationDate,desc');
    } else {
      params = new HttpParams().set('size', pageSize).set('page', pageIndex).set('sort', 'modificationDate,desc');
    }
    return this._http.get<IList<IVersion>>(environment.API_URL + `projects/${id}/versions`, { params, context: AUTH_CONTEXT });
  }

  public getVersion(versionId: string): Observable<IVersion> {
    return this._http.get(environment.API_URL + `projects/versions/${versionId}`, { context: AUTH_CONTEXT });
  }

  public getProject(projectId: string): Observable<IProject> {
    return this._http.get<IProject>(environment.API_URL + `projects/${projectId}`, { context: AUTH_CONTEXT });
  }

  public removeProject(id: string): Observable<void> {
    return this._http.delete<void>(environment.API_URL + `projects/${id}`, { context: AUTH_CONTEXT });
  }

  public removeVersion(id: string): Observable<void> {
    return this._http.delete<void>(environment.API_URL + `projects/versions/${id}`, { context: AUTH_CONTEXT });
  }
}
