<div class="page-container">
    <div class="info-container">
        <div class="page-info">
            <img 
                class="back-arrow-mobile" 
                src="./../../../assets/icons/arrow-left-short.svg" 
                alt="Go back" 
                (click)="back()"
                aria-hidden="true"
                >

            <div class="actions">
                <div class="action-buttons action-buttons-left">
                    <img 
                        class="back-arrow" 
                        src="./../../../assets/icons/arrow-left-short.svg" 
                        alt="Go back" 
                        (click)="back()"
                        aria-hidden="true"
                    >
                    
                </div>
                <div class="action-buttons action-buttons-right">
                    <div class="button-wrapper">
                        <button mat-icon-button color="white" (click)="backward()">
                            <img mat-icon class="icon-circle" src="./../../../assets/icons/Gruppe 3092.svg" alt="previous">
                        </button>
                        <p>{{"USER_FLOW.LABELS.BACK" | translate}}</p>
                    </div>
                    <div class="button-wrapper">
                        <button mat-icon-button color="white" (click)="forward()">
                            <img mat-icon class="icon-circle" src="./../../../assets/icons/Gruppe 3091.svg" alt="next">
                        </button>
                        <p>{{"USER_FLOW.LABELS.FORWARD" | translate}}</p>
                    </div>
                    <button class="button next-button" mat-raised-button color="primary"
                        (click)="next()">{{'BUTTONS.NEXT' | translate}}</button>
                </div>
            </div>

            <div class="page-content">
                <div class="canvases">
                    <div class="canvas-buttons" *ngIf="!resolutionService.isMobileResolution">
                        <div class="button-wrapper">
                            <mat-slider class="slider" min="1" max="50" step="1" value="10">
                                <input matSliderThumb [formControl]="drawWidth">
                            </mat-slider>
                            <div class="slider-labels">
                                <label class="label">{{ "USER_FLOW.LABELS.SMALL" | translate }}</label>
                                <label>
                                    <img 
                                        mat-icon 
                                        class="icon-circle" 
                                        src="../../../assets/icons/Gruppe 3076.svg"
                                        alt="rubber"
                                    >
                                </label>
                                <label class="label">{{ "USER_FLOW.LABELS.BIG" | translate }}</label>
                            </div>
                        </div>
                        <div class="button-wrapper">
                            <button mat-icon-button (click)="undoLast()">
                                <img 
                                    mat-icon 
                                    class="icon-circle" 
                                    src="../../../assets/icons/Gruppe 3085.svg"
                                    alt="undo last"
                                >
                            </button>
                            <p>{{"USER_FLOW.LABELS.ONE_STEP_BACK" | translate}}</p>
                        </div>
                        
                        <div class="button-wrapper">
                            <button mat-icon-button (click)="clearCanvas()">
                                <img 
                                    class="icon-circle" 
                                    mat-icon 
                                    src="../../../assets/icons/Gruppe 3084.svg"
                                    alt="clear canvas"
                                >
                            </button>
                            <p>{{"USER_FLOW.LABELS.REMOVE_ALL" | translate}}</p>
                        </div>
    
                        <div class="button-wrapper">
                            <button class="delete-selection" mat-button (click)="deleteSelection()">
                                <img 
                                    class="icon-circle" 
                                    mat-icon 
                                    src="../../../assets/icons/Komponente 6 – 67.svg"
                                    alt="delete selection"
                                >
                                {{"BUTTONS.DELETE" | translate}}
                            </button>
                        </div>
                    </div>
                    <canvas id="editor"></canvas>
                    <canvas id="result"></canvas> 
                </div>
            </div>

            <div class="actions-mobile">
                <div class="actions-block">
                    <div class="button-wrapper">
                        <mat-slider min="1" max="50" step="1" value="10">
                            <input matSliderThumb [formControl]="drawWidth">
                        </mat-slider>
                        <div class="slider-labels">
                            <label class="label">{{ "USER_FLOW.LABELS.SMALL" | translate }}</label>
                            <label>
                                <img 
                                    mat-icon 
                                    class="rubber" 
                                    src="../../../assets/icons/Gruppe 3076.svg"
                                    alt="rubber"
                                ></label>
                            <label class="label">{{ "USER_FLOW.LABELS.BIG" | translate }}</label>
                        </div>
                    </div>
                    
                    <div class="button-wrapper">
                        <button mat-icon-button (click)="clearCanvas()">
                            <img 
                                class="icon-circle" 
                                src="../../../assets/icons/Gruppe 3084.svg"
                                alt="clear canvas"
                            >
                        </button>
                        <p class="label">{{"USER_FLOW.LABELS.REMOVE_ALL" | translate}}</p>
                    </div>
                    <div class="button-wrapper">
                        <button mat-icon-button (click)="backward()">
                            <img class="icon-circle" src="./../../../assets/icons/Gruppe 3092.svg" alt="previous">
                        </button>
                        <p class="label">{{"USER_FLOW.LABELS.BACK" | translate}}</p>
                    </div>
                    <div class="button-wrapper">
                        <button mat-icon-button (click)="forward()">
                            <img class="icon-circle" src="./../../../assets/icons/Gruppe 3091.svg" alt="next">
                        </button>
                        <p class="label">{{"USER_FLOW.LABELS.FORWARD" | translate}}</p>
                    </div>
                </div>
                
                <div>
                    <button class="delete-selection" mat-button (click)="deleteSelection()">
                        <div class="delete-selection-text">
                            <img 
                                class="icon-circle" 
                                mat-icon src="../../../assets/icons/Komponente 6 – 67.svg"
                                alt="delete"
                            >
                            {{"BUTTONS.DELETE" | translate}}
                        </div>
                        
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="button-container"  *ngIf="resolutionService.isMobileResolution">
        <button class="button" mat-raised-button color="primary" (click)="next()">{{'BUTTONS.NEXT' | translate}}</button>
    </div>
</div>
