import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { IVersion } from 'src/models/project';

import { ProjectsState } from '../reducers/projects-versions.reducers';
import * as fromProjects from '../reducers/projects-versions.reducers';

export const selectProjectsState = createFeatureSelector<ProjectsState>('projects');

export const selectAllProjects = createSelector(selectProjectsState, fromProjects.selectAll);

export const totalElements = createSelector(selectProjectsState, state => state.totalElements);

export const projectsPageSize = createSelector(selectProjectsState, state => state.pageSize);

export const projectsPageIndex = createSelector(selectProjectsState, state => state.pageIndex);

export const versionsForProject = createSelector(selectProjectsState, state => state.selectedProject?.content);

export const versionForProject = (
  projectId: string,
  versionId: string
): MemoizedSelector<object, IVersion, (s1: ProjectsState) => IVersion> =>
  createSelector(selectProjectsState, state => {
    if (state.entities && state.entities[projectId] && state.entities[projectId].versions) {
      return state.entities[projectId].versions.find(version => version.id === versionId);
    }
    return null;
  });

export const versionsTotalElements = (id: string): MemoizedSelector<object, number, (s1: ProjectsState) => number> =>
  createSelector(selectProjectsState, state => {
    if (state.entities && state.entities[id]) {
      return state.entities[id].versionTotalElements;
    }
    return null;
  });
