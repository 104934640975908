import { DOCUMENT, Location } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { filter, take } from 'rxjs';
import { SingleRenderResult } from 'src/models/project';

import { Resolution } from './resolution.class';
import { DEVICES_RESOLUTIONS } from './resolutions.const';
import { Mode } from '../../shared/enums/mode.enum';
import { mode, rendersResult } from '../../store/selectors/shared.selector';
import { ExportType } from '../enum/export.enum';
import { RefreshNavigationService } from '../services/refresh-navigation.service';
import { ResolutionService } from '../services/resolution.service';

@UntilDestroy()
@Component({
  selector: 'app-export-settings',
  templateUrl: './export-settings.component.html',
  styleUrls: ['./export-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExportSettingsComponent implements AfterViewInit, OnInit {
  public readonly brightnessSlider = {
    min: 1,
    max: 200,
    step: 1,
    defaultValue: 100,
  };
  public settings = new FormGroup({
    brightness: new FormControl(this.brightnessSlider.defaultValue),
    resolution: new FormControl(null),
    format: new FormControl(ExportType.JPG),
  });
  public exportTypes: any[] = [
    { value: ExportType.JPG, viewValue: 'Jpg' },
    { value: ExportType.PNG, viewValue: 'Png' },
  ];
  public resolutions: Resolution[] = [];
  public renderResult: SingleRenderResult[];
  public activeRender: SingleRenderResult;
  public renderIndex = 0;

  @ViewChild('image')
  private _image: ElementRef;

  constructor(
    public resolutionService: ResolutionService,
    private _router: Router,
    private _store: Store,
    private _location: Location,
    private _refreshNavigationService: RefreshNavigationService,
    @Inject(DOCUMENT) private _document: Document
  ) {}

  public ngOnInit(): void {
    this._refreshNavigationService.refreshPageEvent$.pipe(untilDestroyed(this)).subscribe(() => this._location.back());
  }

  public ngAfterViewInit(): void {
    this._store
      .select(rendersResult)
      .pipe(
        filter(data => !!data),
        take(1)
      )
      .subscribe(renderResult => {
        this.renderResult = renderResult.renders;
        this.activeRender = this.renderResult[0];

        this._getResolutions();
      });

    this.settings
      .get('brightness')
      .valueChanges.subscribe(brightness => (this._image.nativeElement.style.filter = `brightness(${brightness}%)`));
  }

  public back(): void {
    this._store
      .select(mode)
      .pipe(take(1))
      .subscribe(mode => {
        switch (mode) {
          case Mode.EDIT:
          case Mode.CREATE:
            this._location.back();
            break;
          case Mode.UNSET:
            this._router.navigate(['result']);
        }
      });
  }

  public export(): void {
    let format: string;

    switch (this.settings.get('format').value) {
      case ExportType.JPG:
        format = '.jpg';
        break;
      case ExportType.PNG:
        format = '.png';
        break;
    }

    const resolution = this.settings.get('resolution').value;

    const exportCanvas = this._document.createElement('canvas');
    const image = this._document.createElement('img');
    image.src = this.activeRender.src;
    image.onload = (): void => {
      const { width, height } = resolution ?? image;
      exportCanvas.width = width;
      exportCanvas.height = height;
      const exportContext = exportCanvas.getContext('2d');
      exportContext.filter = this._image.nativeElement.style.filter;
      exportContext.drawImage(image, 0, 0, width, height);

      const a: HTMLAnchorElement = this._document.createElement('a');
      a.href = exportCanvas.toDataURL();
      a.download = 'result' + format;
      a.click();
    };
  }

  public resetBrightness(): void {
    this.settings.get('brightness').setValue(this.brightnessSlider.defaultValue);
  }

  private _getResolutions(): void {
    const image = new Image();
    image.src = this.activeRender.src;
    image.onload = (): void => {
      const originalResolution: Resolution = new Resolution(image.width, image.height, 'Original');
      const relatedResolutions = originalResolution.relateWith(DEVICES_RESOLUTIONS);

      this.resolutions = [originalResolution, ...relatedResolutions];
    };
  }

  public previous(): void {
    if (this.renderIndex - 1 >= 0) {
      this.activeRender = this.renderResult[--this.renderIndex];
      this.settings.patchValue({
        brightness: this.brightnessSlider.defaultValue,
        resolution: null,
        format: ExportType.JPG,
      });
      this.settings.updateValueAndValidity();
    }
  }

  public next(): void {
    if (this.renderIndex + 1 < this.renderResult.length) {
      this.activeRender = this.renderResult[++this.renderIndex];
      this.settings.patchValue({
        brightness: this.brightnessSlider.defaultValue,
        resolution: null,
        format: ExportType.JPG,
      });
      this.settings.updateValueAndValidity();
    }
  }
}
