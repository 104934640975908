import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { clearFurnitureDetails, getCatalogData, viewFurniture } from 'src/app/store/actions/shared.actions';
import { catalogData } from 'src/app/store/selectors/shared.selector';
import { CatalogData } from 'src/services/model/proposal';

import { LeftPanelState } from '../left-panel-container/left-panel-state.enum';

@UntilDestroy()
@Component({
  selector: 'app-catalog-category',
  templateUrl: './catalog-category.component.html',
  styleUrls: ['./catalog-category.component.scss'],
})
export class CatalogCategoryComponent implements OnInit {
  @Input() public furnitureType = 'SOFA';

  @Output() public openCategories: EventEmitter<void> = new EventEmitter<void>();
  @Output() public openDetailsEvent: EventEmitter<void> = new EventEmitter<void>();

  public catalogData$: Observable<CatalogData[]> = this._store.select(catalogData);

  private _start = 0;
  private _limit = 20;

  constructor(private _store: Store) {}

  public ngOnInit(): void {
    if (!this.furnitureType) {
      this._store.dispatch(clearFurnitureDetails());
    }
    this._store.dispatch(getCatalogData({ furnitureType: this.furnitureType, limit: this._limit, start: this._start, mode: 'new' }));
  }

  public back(): void {
    this.openCategories.emit();
  }

  public viewDetails(data: CatalogData): void {
    this._store.dispatch(viewFurniture({ data: { article: data, from: LeftPanelState.CATALOG_DETAILS, translateDetails: true } }));
    this.openDetailsEvent.emit();
  }

  public onScroll(): void {
    this._start += 10;
    this._store.dispatch(getCatalogData({ furnitureType: this.furnitureType, limit: 10, start: this._start, mode: 'more' }));
  }
}
