export enum ClickType {
  BASE = 'Base',
  MOVE_MODEL_MOUSE = 'Move Model Mouse',
  MOVE_WALL_POINT = 'Move Wall Point',
  MOVE_WINDOW_DOOR = 'Move Window/Door',
  ADD_LIGHT_POINT = 'Add Point Light',
  DELETE_LIGHT_POINT = 'Delete Light Point',
  SELECT_PLANE = 'Select Plane',
  SELECT_NEW_PLANE_POINT = 'Select New Plane Point',
  ADD_WALL_POINT = 'Add Wall Point',
  DELETE_WALL_POINT = 'Delete Wall Point',
  SET_CEILING_MANUAL = 'Set Ceiling',
  RECONSTRUCT_MANUAL = 'Manual Reconstruct',
  PLACE_WINDOW = 'Place Window',
  PLACE_DOOR = 'Place Door',
  VIEW_FURNITURE_CLICK = 'View Furniture Click',
}
