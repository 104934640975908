<div class="dialog" [ngClass]="{'important-notification': data.showIcon}">
  <button 
    class="close-button" 
    mat-icon-button 
    aria-label="close dialog" 
    (click)="close()"
  >
    <mat-icon>close</mat-icon>
  </button>
  <div class="icon" *ngIf="data.showIcon">
    <img 
			src="./../../../../assets/icons/Gruppe 3315.svg"
			alt="success"
		>
	</div>
  <div class="dialog__content">
    <p 
      class="dialog__content--title m-45"
      [ngClass]="{'m-45': !resolutionService.isMobileResolution, 'm-30': resolutionService.isMobileResolution}"
    >{{ data.title | translate }}</p>
    <p 
      class="dialog__content--message" 
      [ngClass]="{
        'r-20': !resolutionService.isMobileResolution, 
        'r-16': resolutionService.isMobileResolution,
        'message-width': data.limitWidth || (lang$ |async) === languages.ITALIAN
      }"
    >{{ data.message | translate }}</p>
  </div>

  <div class="dialog__actions" *ngIf="data.buttonName || data.info">
      <button 
        class="button primary-button" 
        color="primary" 
        type="submit" 
        mat-raised-button 
        (click)="clickPrimaryButton()"
      >
        <p class="buttons__text">{{ data.buttonName | translate }}</p>
      </button>
    <a href="https://www.autentica.space/en/preise/" *ngIf="data.info" class="dialog__actions--info r-14">{{ data.info | translate }}</a>
  </div>
</div>
