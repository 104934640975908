export interface MenuNode {
  name: string;
  parent?: string;
  children?: MenuNode[];
}

const MOBILE_TREE_DATA_FIRST: MenuNode[] = [
  {
    name: 'BUTTONS.CREATE',
    children: [
      { name: 'SHARED.TABS.DEFAULT', parent: 'create' },
      { name: 'SHARED.TABS.STYLESWAP', parent: 'create' },
      { name: 'SHARED.TABS.NO_FURNITURE', parent: 'create' },
      { name: 'SHARED.TABS.PROJECTS', parent: 'create' },
    ],
  },
  {
    name: 'BUTTONS.PROJECTS',
    children: [
      { name: 'SHARED.TABS.DEFAULT', parent: 'result' },
      { name: 'SHARED.TABS.STYLESWAP', parent: 'result' },
      { name: 'SHARED.TABS.NO_FURNITURE', parent: 'result' },
      { name: 'SHARED.TABS.PROJECTS', parent: 'result' },
    ],
  },
  {
    name: 'BUTTONS.CONTACT',
  },
  {
    name: 'SHARED.MENU.SUBSCRIPTION',
  },
];

export const MOBILE_TREE_DATA_USER: MenuNode[] = [
  ...MOBILE_TREE_DATA_FIRST,
  {
    name: 'SHARED.MENU.LOGOUT',
  },
];

export const MOBILE_TREE_DATA_ADMIN: MenuNode[] = [
  ...MOBILE_TREE_DATA_FIRST,
  {
    name: 'BUTTONS.USERS',
  },
  {
    name: 'SHARED.MENU.LOGOUT',
  },
];
