import { createAction, props } from '@ngrx/store';
import { ProposalState } from 'src/models/proposal';
import { Scene, Vector3 } from 'three';

import { NcsColor } from '../../user-flow/catalog/color-select-container/ncs-color.model';
import { PlaneType } from '../../user-flow/services/enum/plane-type.enum';

export const SET_ROOM_HEIGHT = '[Render Page] set room height';
export const UPDATE_SCENE = '[Render Page] update scene';
export const SET_SCENE = '[Render Page] set scene';
export const ADD_LIGHT_POINT = '[Render Page] add light point';
export const DELETE_LIGHT_POINT = '[Render Page] delete light point';
export const MOVE_LIGHT_POINT = '[Render Page] move light point';
export const ADD_MODEL = '[Render Page] add model';
export const DELETE_MODEL = '[Render Page] delete model';
export const DELETE_MODELS = '[Render Page] delete models';
export const MOVE_MODEL = '[Render Page] move model';
export const RENAME_MODEL = '[Render Page] rename model';
export const ADD_PLANE = '[Render Page] add plane';
export const DELETE_PLANE = '[Render Page] delete plane';
export const ADD_WALL_CORNER = '[Render Page] add wall corner';
export const DELETE_WALL_CORNER = '[Render Page] delete wall corner';
export const MOVE_WALL_CORNER = '[Render Page] move wall corner';
export const MOVE_MODEL_MOUSE = '[Render Page] move model mouse';
export const LOCK_MODEL = '[Render Page] lock model';
export const CHANGE_WALL_COLOR = '[Render Page] change wall color';

export const setRoomHeight = createAction(SET_ROOM_HEIGHT, props<{ height: number }>());

export const updateScene = createAction(UPDATE_SCENE, props<{ scene: Scene }>());

export const setScene = createAction(SET_SCENE, props<{ scene: Scene }>());

export const addLightPoint = createAction(ADD_LIGHT_POINT, props<{ data: { position: Vector3; uuid: string } }>());

export const deleteLightPoint = createAction(DELETE_LIGHT_POINT, props<{ data: { position: Vector3; uuid: string } }>());

export const moveLightPoint = createAction(MOVE_LIGHT_POINT, props<{ data: { start: Vector3; end: Vector3; uuid: string } }>());

export const addModel = createAction(
  ADD_MODEL,
  props<{ data: { id: string; sku?: string; proposalSnapshotBefore?: ProposalState; proposalSnapshotAfter?: ProposalState } }>()
);

export const deleteModel = createAction(
  DELETE_MODEL,
  props<{ data: { id: string; sku?: string; proposalSnapshotBefore: ProposalState; proposalSnapshotAfter: ProposalState } }>()
);

export const deleteModels = createAction(
  DELETE_MODELS,
  props<{ data: { ids: string[]; proposalSnapshotBefore: ProposalState; proposalSnapshotAfter: ProposalState } }>()
);

export const moveModel = createAction(MOVE_MODEL, props<{ data: { start: Vector3; end: Vector3; moveType: string } }>());

export const addPlane = createAction(ADD_PLANE, props<{ data: { id: string; points: Vector3[]; type: PlaneType } }>());

export const deletePlane = createAction(DELETE_PLANE, props<{ data: { id: string; points: Vector3[]; type: PlaneType } }>());

export const addWallCorner = createAction(ADD_WALL_CORNER, props<{ data: { id: string; position: Vector3 } }>());

export const deleteWallCorner = createAction(DELETE_WALL_CORNER, props<{ data: { id: string; position: Vector3 } }>());

export const moveWallCorner = createAction(MOVE_WALL_CORNER, props<{ data: { id: string; start: Vector3; end: Vector3 } }>());

export const moveModelMouse = createAction(
  MOVE_MODEL_MOUSE,
  props<{ data: { id: string; startPosition: Vector3; endPosition: Vector3; startRotation: Vector3; endRotation: Vector3 } }>()
);

export const renameModel = createAction(RENAME_MODEL, props<{ data: { newName: string; oldName: string; id: string } }>());

export const lockModel = createAction(LOCK_MODEL, props<{ data: { id: string } }>());

export const changeWallColor = createAction(CHANGE_WALL_COLOR, props<{ data: { id: string; from: NcsColor; to: NcsColor } }>());
