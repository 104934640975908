import { Pipe, PipeTransform } from '@angular/core';
import { BasicArticleInformation } from 'src/services/model/proposal';

@Pipe({
  name: 'link',
})
export class LinkPipe implements PipeTransform {
  public transform(basicArticleInformation: BasicArticleInformation): string {
    return basicArticleInformation?.MetaData?.find(data => data.Key === 'Shopify_Product_Link').Value;
  }
}
