<div class="container">
  <app-current-color
    *ngIf="currentColorOpen"
    (nextButtonClicked)="nextButtonClicked()"
    [colorData]="this.selectedWall?.colorData"
    (colorChanged)="colorChanged($event)"
  ></app-current-color>
  <app-all-colors
    *ngIf="allColorsOpen"
    [colorData]="this.selectedWall?.colorData"
    (colorChanged)="colorChanged($event)"
    (backToCurrentColor)="openCurrentColor()"
  ></app-all-colors>
</div>
