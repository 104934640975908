import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { getOverlayLoading } from 'src/app/store/selectors/shared.selector';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.css'],
})
export class SpinnerComponent implements OnInit {
  public showLoading$: Observable<boolean>;

  constructor(private _store: Store) {}

  public ngOnInit(): void {
    this.showLoading$ = this._store.select(getOverlayLoading);
  }
}
