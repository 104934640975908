<div class="container" (mouseenter)="pause()" (mouseleave)="unPause()">
	<div class="icon">
		<img 
			*ngIf="notification.level === 'success'" 
			src="./../../../assets/icons/success.svg"
			alt="success"
		>
		<img 
			*ngIf="notification.level === 'warning'"
			src="./../../../assets/icons/warning.svg"
			alt="warning"
		>
		<img 
			*ngIf="notification.level === 'error'" 
			src="./../../../assets/icons/error.svg"
			alt="error"
		>
	</div>
	<div class="content-container">
		<div class="title m-25"> {{notification.title}}</div>
		<div class="content r-18"> {{notification.text}} </div>
	</div>
	<button class="close-button" mat-icon-button aria-label="close dialog" (click)="remove()">
        <mat-icon>close</mat-icon>
    </button>
	
</div>