<div class="page">
    <div class="page-header">
        <div class="title-container">
            <img 
                class="back-arrow" 
                src="./../../../assets/icons/arrow-left-short.svg" 
                alt="Go back" 
                (click)="back()"
                aria-hidden="true"
            >
            <span class="m-35"> {{ "VERSIONS.TITLE" | translate}} </span>
        </div>
        <mat-form-field *ngIf="!resolutionService.isMobileResolution" class="search"  appearance="outline">
            <mat-label class="r-16">{{ "USER_FLOW.CATALOG.SEARCH" | translate}}</mat-label>
            <mat-icon matPrefix>search</mat-icon>
            <input matInput [formControl]="searchInput">
        </mat-form-field>
    </div>
    <ng-container *ngIf="(versions$ | async) as versions">
        <div class="table" [ngClass]="{'page-height': versions.length < 4}">
            
            <ul class="items">
                <li class="item" *ngFor="let version of versions; let i = index">
                    <div class="name-mobile truncate m-18" [title]="version.name">{{version.name}}</div>
                    <div class="image-container" *ngIf="version.src; else placeholder">
                        <img 
                            class="image" 
                            [src]="version.src | safe: 'url'" 
                            alt="image"
                        />
                        <div 
                            *ngIf="!resolutionService.isMobileResolution" 
                            class="open-image" 
                            (click)="editVersion(version)"
                            aria-hidden="true"
                        >
                            {{ "BUTTONS.OPEN" | translate}}
                        </div>
                        <button *ngIf="resolutionService.isMobileResolution" class="button edit-button" mat-icon-button color="white" (click)="editVersion(version)">
                            <img class="icon" src="./../../../../assets/icons/Gruppe 3155.svg" alt="open">
                        </button>
                    </div>
                    <div class="functional-container">
                        <div class="item-info">
                            <div class="item-info-name truncate m-18" [title]="version.name">{{ version.name }}</div>
                            <div class="item-info-creation-date r-16">{{ version.creationDate | date:'d MMM yyyy'}}</div>
                        </div>
                        <div class="buttons">
                            <app-download-button [renders]="version.renders" [projectId]="projectId" [versionId]="version.id"></app-download-button>
                            <button class="button" mat-icon-button color="white" (click)="editVersion(version)">
                                <img class="icon" src="./../../../../assets/icons/Gruppe 3155.svg" alt="edit">
                            </button>
                            <button class="button" mat-icon-button color="white" (click)="removeVersion(version.id)" [disabled]="versions.length === 1">
                                <img *ngIf="versions.length > 1" class="icon" src="./../../../../assets/icons/Gruppe 3153.svg" alt="remove">
                                <img *ngIf="versions.length === 1" class="icon" src="./../../../../assets/icons/Gruppe 3153 1.svg" alt="remove">
                            </button>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div class="paginator-wrapper" *ngIf="versions.length > 5">
            <mat-paginator class="paginator" stylePaginator showFirstLastButtons [showTotalPages]="3"
                [length]="totalElements$ | async" [pageSize]="size" (page)="onPageEvent($event)"
                color="primary">
            </mat-paginator>
        </div>
    </ng-container>
</div>

<ng-template #placeholder>
    <div class="placeholder-container image">
        <img 
            class="placeholder" 
            [src]="'../../../assets/Placeholder.png' | safe: 'url'" 
            alt="placeholder"
        />
    </div>
</ng-template>