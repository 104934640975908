<div class="page-container">
  <div class="header">
    <p class="m-25 title">{{ "USER_FLOW.REQUEST_PAGE.TITLE" | translate}}</p>
  </div>
  <section class="page-content">
    <form [formGroup]="form">
      <p>
        <mat-form-field class="form-field">
          <mat-label>{{ "USER_FLOW.REQUEST_PAGE.TOPIC" | translate}}</mat-label>
          <mat-select formControlName="topic">
              <mat-option *ngFor="let topic of topics" [value]="topic.value">
                  {{ topic.viewValue | translate }}
              </mat-option>
          </mat-select>
      </mat-form-field>
      </p>
      <p>
        <app-form-field
          class="contact-form-field"
          name="password"
          label="USER_FLOW.REQUEST_PAGE.ADDRESS"
          type="text"
          [abstractControl]="form.get('address')"
        ></app-form-field>
      </p>
      <p>
        <app-form-field
          class="contact-form-field"
          name="email"
          label="USER_FLOW.REQUEST_PAGE.PHONE"
          type="text"
          [abstractControl]="form.get('mobilePhone')"
        ></app-form-field>
      </p>
      <p>
        <app-form-field
          class="contact-form-field-description contact-form-field"
          name="repeatPassword"
          label="USER_FLOW.REQUEST_PAGE.COMMENT"
          type="text"
          formType="textarea"
          [abstractControl]="form.get('comment')"
        ></app-form-field>
      </p>
      <div>
        <button class="button" color="primary" type="submit" mat-raised-button (click)="submit()">{{ "USER_FLOW.REQUEST_PAGE.SEND_BUTTON" | translate}}</button>
      </div>
    </form>
  </section>
</div>
