import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Notification } from 'src/app/notifications/model/notification';
import { NotificationsService } from 'src/app/notifications/services/notifications.service';

import { LoginForm } from '../../model/login.model';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  public form: FormGroup<LoginForm> = new FormGroup<LoginForm>({
    email: new FormControl(null, [Validators.required, Validators.email]),
    password: new FormControl(null, [Validators.required]),
  });

  constructor(
    private _authService: AuthService,
    private _router: Router,
    private _notificationsService: NotificationsService,
    private _translateService: TranslateService
  ) {}

  public submit(): void {
    this.form.updateValueAndValidity();
    if (this.form.valid) {
      this._authService.login(this.form.getRawValue()).subscribe({
        next: () => {
          this._router.navigate(['result']);
        },
        error: (error: HttpErrorResponse) => {
          this._notificationsService.addNotification(
            new Notification({
              title: this._translateService.instant('NOTIFICATIONS.TITLE.ERROR'),
              text: this._translateService.instant(`NOTIFICATIONS.MESSAGES.${error.error['detail']}`),
              level: 'error',
              options: { timeout: 2 },
            })
          );
        },
      });
    }
  }

  public toForgotPassword(): void {
    this._router.navigate(['forgot-password']);
  }

  public toRegister(): void {
    this._router.navigate(['register']);
  }
}
