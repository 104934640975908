import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { NotificationComponent } from './notification/notification.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { NotificationsService } from './services/notifications.service';

@NgModule({
  imports: [CommonModule, MatIconModule, MatButtonModule],
  declarations: [NotificationsComponent, NotificationComponent],
  exports: [NotificationsComponent],
  providers: [NotificationsService],
  bootstrap: [],
})
export class NotificationsModule {}
