import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { lang } from 'src/app/store/selectors/shared.selector';
import { ResolutionService } from 'src/app/user-flow/services/resolution.service';

import { TranslateLanguagesEnum } from '../../configs/translate.config';
import { NotificationDialog } from '../../interfaces/notification-dialog';

@Component({
  selector: 'app-notification-dialog',
  templateUrl: './notification-dialog.component.html',
  styleUrls: ['./notification-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationDialogComponent {
  public lang$ = this._store.select(lang);
  public languages = TranslateLanguagesEnum;

  constructor(
    public resolutionService: ResolutionService,
    private _dialogRef: MatDialogRef<NotificationDialogComponent>,
    private _store: Store,
    @Inject(MAT_DIALOG_DATA) public data: NotificationDialog
  ) {}

  public close(): void {
    this._dialogRef.close();
  }

  public clickPrimaryButton(): void {
    this._dialogRef.close(true);
  }
}
