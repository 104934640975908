import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { GoogleAnalyticsService } from 'src/services/google-analytics.service';

import { CookieBannerComponent } from '../components/cookie-banner/cookie-banner.component';

@Injectable()
export class CookieService {
  constructor(
    private _dialog: MatDialog,
    private readonly googleAnalyticsService: GoogleAnalyticsService,
    @Inject(DOCUMENT) private _document: Document
  ) {}

  public collectCookie(): void {
    if (!localStorage.getItem('cookie')) {
      this._dialog
        .open(CookieBannerComponent, {
          width: '340px',
          height: '210px',
          position: {
            bottom: '20px',
            right: '20px',
          },
        })
        .afterClosed()
        .subscribe(res => {
          if (environment.production && res === 'allow') {
            this.googleAnalyticsService.initialize();
            const head = this._document.getElementsByTagName('head')[0];
            const script = this._document.createElement('script');
            script.type = 'text/javascript';
            script.id = 'ms_clarity';
            script.innerHTML = `(function(c,l,a,r,i,t,y){ c[a]=c[a]||function(){(c[a].q=c[a].q||[])
          .push(arguments)}; t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
           y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y); })(window, document, "clarity", "script", "jbvppqaaw7");`;
            head.insertBefore(script, head.firstChild);
          }
        });
    }
  }
}
