import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Notification } from '../model/notification';
import { NotificationsService } from '../services/notifications.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationComponent {
  @Input()
  public notification: Notification;

  constructor(private _notificationsService: NotificationsService) {}

  public pause(): void {
    this.notification.paused.next(true);
  }

  public unPause(): void {
    this.notification.paused.next(false);
  }

  public remove(): void {
    this._notificationsService.removeNotification();
  }
}
