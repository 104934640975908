<h2 mat-dialog-title>{{ "USER_FLOW.LOAD_MODEL_DIALOG.TITLE" | translate }}</h2>
<form [formGroup]="form">
  <mat-dialog-content class="content">
    <mat-form-field>
      <mat-label>{{ "USER_FLOW.LOAD_MODEL_DIALOG.LABELS.MODEL_NAME" | translate }}</mat-label>
      <input matInput formControlName="name">
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{label  | translate}}</mat-label>
      <input matInput formControlName="value">
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ "USER_FLOW.LOAD_MODEL_DIALOG.LABELS.VALUE_TYPE" | translate }}</mat-label>
      <mat-select formControlName="valueType">
        <mat-option *ngFor="let type of valueTypes" [value]="type.value">
          {{type.viewValue | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ "USER_FLOW.LOAD_MODEL_DIALOG.LABELS.TYPE" | translate }}</mat-label>
      <mat-select formControlName="type">
        <mat-option *ngFor="let type of types" [value]="type.value">
          {{type.viewValue | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ "USER_FLOW.LOAD_MODEL_DIALOG.LABELS.STICKNESS" | translate }}</mat-label>
      <mat-select formControlName="sticknessType">
        <mat-option *ngFor="let type of sticknessTypes" [value]="type.value">
          {{type.viewValue | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button color="'primary" [disabled]="form.invalid" (click)="close()">{{ "BUTTONS.CONFIRM" | translate }}</button>
  </mat-dialog-actions>
</form>
