import { FurnitureType } from '../../enum/furniture-types.enum';
import { SticknessType } from '../enum/stickness-type.enum';

export function getStickiness(type: FurnitureType): SticknessType {
  switch (type) {
    case FurnitureType.LAMP:
      return SticknessType.CEILING;
    case FurnitureType.PAINTING:
    case FurnitureType.CURTAIN:
    case FurnitureType.MIRROR:
    case FurnitureType.WALL_LAMP:
    case FurnitureType.KEY_STORAGE:
      return SticknessType.WALLS;
    default:
      return SticknessType.FLOOR;
  }
}
