import { Group } from 'three';

import { WallPoint } from './wall-point.class';

export class WallPointGroup extends Group {
  public override children: WallPoint[];

  public override getObjectByProperty(name: string, value: any): WallPoint | undefined {
    return super.getObjectByProperty(name, value) as WallPoint | undefined;
  }

  public override getObjectsByProperty(name: string, value: any): WallPoint[] {
    return super.getObjectsByProperty(name, value) as WallPoint[];
  }

  public override getObjectByName(name: string): WallPoint | undefined {
    return super.getObjectByName(name) as WallPoint | undefined;
  }

  public override getObjectById(id: number): WallPoint | undefined {
    return super.getObjectById(id) as WallPoint | undefined;
  }

  public override add(...wallPoints: WallPoint[]): this {
    return super.add(...wallPoints);
  }
}
