import { Vector3 } from 'three';

import { CurtainForWindowData } from './curtains-data.model';
import { MetersSize } from './positioning-element.model';
import { getObjectSize, getRotation, moveModel } from '../autopositioning.function';
import { MovingObject } from '../model/dto/moving-object.model';

export const curtainsAlgorithm = (
  objects: MovingObject[],
  data: {
    curtainsForWindows: CurtainForWindowData[];
    roomHeight: number;
    ceilingY: number;
    roomCenter: Vector3;
  },
  meterPerPixel: number
): void => {
  const size = getObjectSize(objects[0].object3D);
  const metersSize: MetersSize = {
    width: size.x * meterPerPixel,
    height: size.y * meterPerPixel,
    depth: size.z * meterPerPixel,
  };

  data.curtainsForWindows.forEach(curtainForWindow => {
    const ySorted = curtainForWindow.window.sort((p1, p2) => (p1.y < p2.y ? 1 : -1));
    const xSorted = ySorted.slice(0, 2).sort((p1, p2) => (p1.x > p2.x ? 1 : -1));

    const wallCorners: [Vector3, Vector3] = [xSorted[0], xSorted[1]];

    if (curtainForWindow?.left?.object && curtainForWindow?.left?.position) {
      const first = curtainForWindow.left.object.object3D;
      const firstPosition = curtainForWindow.left.position;

      first.position.set(firstPosition.x, data.ceilingY, firstPosition.z);
      moveModel(
        first,
        metersSize,
        { down: size => size.height / 2 + 0.05, forward: size => size.depth / 2 },
        wallCorners,
        data.roomCenter,
        1 / meterPerPixel
      );
      first.lookAt(getRotation(first.position, wallCorners).forward);
    }

    if (curtainForWindow?.right?.object && curtainForWindow?.right?.position) {
      const second = curtainForWindow.right.object.object3D;
      const secondPosition = curtainForWindow.right.position;

      second.position.set(secondPosition.x, data.ceilingY, secondPosition.z);
      moveModel(
        second,
        metersSize,
        { down: size => size.height / 2 + 0.05, forward: size => size.depth / 2 },
        wallCorners,
        data.roomCenter,
        1 / meterPerPixel
      );
      second.lookAt(getRotation(second.position, wallCorners).forward);
    }
  });
};
