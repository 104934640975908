import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, of, switchMap, tap, throwError } from 'rxjs';
import { DeleteObjHttpService } from 'src/app/user-flow/services/delete-obj-http.service';
import { DetectObjHttpService } from 'src/services/detect-obj-http.service';

import { ImageActions } from '../action-types';
import { deleteSelectionSuccess } from '../actions/image.actions';
import { addGlobalError, setOverlayLoadingSpinner } from '../actions/shared.actions';

@Injectable()
export class ImageEffects {
  public deleteSelection$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ImageActions.deleteSelection),
      tap(() => this._store.dispatch(setOverlayLoadingSpinner({ status: true }))),
      switchMap(action => this._deleteObjHttpService.deleteObjClipdrop(action.image, action.mask)),
      switchMap((res: any) => {
        const src = res?.data?.image;
        if (!src && res.constructor !== ArrayBuffer) {
          return throwError(new Error());
        }
        return typeof src === 'string' ? of(src) : of(URL.createObjectURL(new Blob([new Uint8Array(res)], { type: 'image/jpeg' })));
      }),
      catchError(err => {
        this._store.dispatch(setOverlayLoadingSpinner({ status: false }));
        this._store.dispatch(addGlobalError({ data: err }));
        return throwError(err);
      }),
      map((src: string) => {
        this._store.dispatch(setOverlayLoadingSpinner({ status: false }));
        return deleteSelectionSuccess({ src });
      })
    )
  );

  constructor(
    private _actions$: Actions,
    private _detectObjHttpService: DetectObjHttpService,
    private _deleteObjHttpService: DeleteObjHttpService,
    private _store: Store
  ) {}
}
