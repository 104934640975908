import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { updateActiveProject } from 'src/app/store/actions/shared.actions';

@Component({
  selector: 'app-version-name',
  templateUrl: './version-name.component.html',
  styleUrls: ['./version-name.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VersionNameComponent {
  public name: FormControl = new FormControl(null, Validators.required);

  constructor(
    private _dialogRef: MatDialogRef<VersionNameComponent>,
    private _store: Store
  ) {}

  public close(): void {
    const projectInfo = {
      versionName: this.name.value,
    };
    this._store.dispatch(updateActiveProject({ projectInfo }));
    this._dialogRef.close();
  }
}
