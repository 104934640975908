<div class="page-container">
    <div *ngIf="resolutionService.isMobileResolution">
        <img 
            class="back-arrow" src="./../../../assets/icons/arrow-left-short.svg" 
            alt="Go back" 
            (click)="back()"
            aria-hidden="true"
        >
    </div>
    <div class="page-header">
        <p class="subheader" [ngClass]="{'r-24': !resolutionService.isMobileResolution, 'r-16': resolutionService.isMobileResolution}">{{ "USER_FLOW.LABELS.STYLE_SUBHEADER" | translate }}</p>
        <p class="header" [ngClass]="{'m-40': !resolutionService.isMobileResolution, 'm-25': resolutionService.isMobileResolution}">{{ "USER_FLOW.LABELS.STYLE_HEADER" | translate }}</p>
    </div>
    <div class="info-container">
        <div class="navigation-buttons">
            <img 
                class="back-arrow" 
                src="./../../../assets/icons/arrow-left-short.svg" 
                alt="Go back" 
                (click)="back()"
                aria-hidden="true"
            >
            <button class="button" mat-raised-button color="primary"
                    (click)="next()">{{'BUTTONS.NEXT' | translate}}</button>
        </div>
        <div class="page-info">

            <div class="grid">
                <app-upload-file class="image-wrapper" (fileUploaded)="fileUploaded($event)" *ngIf="(uploadedMoodImagesCount$ | async) < 5"></app-upload-file>
                <div 
                    class="image-wrapper" 
                    (click)="updateClass(style)" 
                    [ngClass]="{'selected': style.selected}" 
                    *ngFor="let style of styles"
                    aria-hidden="true"
                >
                    <img 
                        class="image" 
                        [src]="style.image | safe: 'url'"
                        alt="image"
                    >
                    <span class="count" *ngIf="style.selected">{{ positionCount(style) }}</span>
                </div>

            </div>
            <div class="button-wrapper" *ngIf="resolutionService.isMobileResolution">
                <button class="button" mat-raised-button color="primary"
                    (click)="next()">{{'BUTTONS.NEXT' | translate}}</button>
            </div>

        </div>
    </div>
</div>