import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { filter, map, switchMap, take } from 'rxjs';
import { clearStyles } from 'src/app/store/actions/shared.actions';

import { Mode } from '../../shared/enums/mode.enum';
import { activeProject, reconstructionResult } from '../../store/selectors/shared.selector';
import { ClickType } from '../enum/click.enum';
import { InputType } from '../enum/input-types.enum';
import { RoomType } from '../enum/room-type.enum';
import { Option } from '../interfaces/option';
import { Action, RenderInputData } from '../render/render-input-data.model';
import { RenderComponent } from '../render/render.component';
import { ImageFileParamsService } from '../services/image-file-params.service';
import { ResolutionService } from '../services/resolution.service';

@UntilDestroy()
@Component({
  selector: 'app-options-page',
  templateUrl: './options-page.component.html',
  styleUrls: ['./options-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptionsPageComponent implements AfterViewInit, OnChanges {
  @ViewChild('render')
  private _renderComponent: RenderComponent;

  @Input() public selectedValue: string;
  @Input() public header: string;
  @Input() public subHeader: string;
  @Input() public options: Option[];
  @Input() public imgIsRender = false;
  @Input() public img: string;
  @Input() public imageChange: boolean;
  @Input() public inputType: InputType;
  @Input() public value: string;
  @Input() public scrollOnRender: boolean;
  @Output() public update = new EventEmitter();
  public selected = new FormControl(null, Validators.required);
  public types = InputType;
  public clickType: ClickType = ClickType.BASE;
  public renderInputData: RenderInputData;
  public isMobile = false;
  public initialType: string;

  private readonly _maxWidthPC = 700;

  constructor(
    public resolutionService: ResolutionService,
    private _store: Store,
    private _imageFileParamsService: ImageFileParamsService,
    @Inject(DOCUMENT) private _document: Document
  ) {}

  public ngAfterViewInit(): void {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    this._document.body.scrollTop = 0;
    const renderElement = document.getElementsByClassName('grid')[0];
    if (renderElement && renderElement.clientWidth && renderElement.clientHeight) {
      this._store
        .select(activeProject)
        .pipe(
          filter(data => !!data && !!data.file),
          take(1),
          switchMap(activeProject =>
            this._imageFileParamsService
              .getImageParams(
                activeProject.file,
                activeProject.file.name,
                this.resolutionService.isMobileResolution ? renderElement.clientWidth - 40 : this._maxWidthPC,
                this.resolutionService.isMobileResolution ? null : renderElement.clientHeight - 80
              )
              .pipe(
                take(1),
                map(imageParams => ({ imageParams, activeProject })),
                untilDestroyed(this)
              )
          ),
          switchMap(data =>
            this._store.select(reconstructionResult).pipe(
              take(1),
              map(reconstructionResult => ({
                imageParams: data.imageParams,
                activeProject: data.activeProject,
                reconstructionResult,
              }))
            )
          ),
          untilDestroyed(this)
        )
        .subscribe(data => {
          if (data) {
            this.renderInputData = data.reconstructionResult
              ? {
                  src: data.activeProject.src,
                  width: data.imageParams.width,
                  height: data.imageParams.height,
                  type: data.activeProject.type as RoomType,
                  actions: [Action.LOAD_AUTO_RECONSTRUCT],
                }
              : {
                  src: data.activeProject.src,
                  width: data.imageParams.width,
                  height: data.imageParams.height,
                  type: data.activeProject.type as RoomType,
                  actions: [Action.MAKE_AUTO_RECONSTRUCT],
                };

            this._renderComponent?.run(Mode.CREATE, this.renderInputData).pipe(untilDestroyed(this)).subscribe();
          }
        });
    }
  }

  public ngOnChanges(): void {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    this._document.body.scrollTop = 0;
    this._initOptions();
  }

  private _initOptions(): void {
    if (this.inputType === InputType.RADIO) {
      this.initialType = this.options.find(option => option.checked)?.value;
      this.selected.setValue(this.initialType);
    }
    if (this.inputType === InputType.INPUT) {
      this.selected.setValue(this.value);
    }
  }

  public selectOption(value: string): void {
    this.selected.setValue(value);
  }

  public back(): void {
    this.update.next({ action: 'back', value: this.selected.value });
    this.selected.reset();
    this.selected.updateValueAndValidity();
  }

  public next(): void {
    if (this.inputType === InputType.RADIO && this.imageChange && this.initialType !== this.selected.value) {
      this._store.dispatch(clearStyles());
    }
    this.update.next({ action: 'next', value: this.selected.value });
    this.selected.reset();
    this.selected.updateValueAndValidity();
  }
}
