import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { filter, map, Observable, take, tap } from 'rxjs';
import { furnitureDetails, proposal } from 'src/app/store/selectors/shared.selector';

import { BasicArticleInformation, CatalogData } from '../../../../services/model/proposal';
import { includeSku, setUnsavedChanges } from '../../../store/actions/shared.actions';
import { MovingObjectsService } from '../../services/moving-objects.service';
import { ResolutionService } from '../../services/resolution.service';
import { LeftPanelState } from '../left-panel-container/left-panel-state.enum';

@UntilDestroy()
@Component({
  selector: 'app-catalog-details',
  templateUrl: './catalog-details.component.html',
  styleUrls: ['./catalog-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CatalogDetailsComponent implements OnInit {
  @Output() public closeDialog: EventEmitter<void> = new EventEmitter<void>();
  @Output() public backToCategories: EventEmitter<void> = new EventEmitter<void>();

  public description$: Observable<string> = this._store.select(furnitureDetails).pipe(
    filter(data => {
      if (this.resolutionService.isMobileResolution) {
        return !!data;
      }

      return !!data && data.from === LeftPanelState.CATALOG_DETAILS;
    }),
    map(data => data.descriptionTranslate)
  );

  public catalogData$: Observable<CatalogData> = this._store.select(furnitureDetails).pipe(
    filter(data => {
      if (this.resolutionService.isMobileResolution) {
        return !!data;
      }

      return !!data && data.from === LeftPanelState.CATALOG_DETAILS;
    }),
    map(data => data.article),
    tap(data => (this.activeImage = data.BasicArticleInformation.PackshotImages[0]))
  );

  public currentData: CatalogData = null;
  public selectedVariant: CatalogData = null;

  public defaultMaterial: string;
  public defaultSize: string;
  public materials: string[];
  public sizes: string[];

  public isAlreadyOnScene = false;

  public activeImage: string;
  public imageIndex = 0;

  constructor(
    public resolutionService: ResolutionService,
    private _store: Store,
    private _cdr: ChangeDetectorRef,
    private _movingObjectsService: MovingObjectsService
  ) {}

  public ngOnInit(): void {
    this.catalogData$
      .pipe(
        filter(data => !!data),
        untilDestroyed(this)
      )
      .subscribe((catalogData: CatalogData) => {
        this.currentData = catalogData;
        this.isAlreadyOnScene = !!this._movingObjectsService.movingObjects.find(obj => obj.SKU === catalogData.BasicArticleInformation.SKU);
        this.sizes = catalogData.BasicArticleInformation?.Options.find(el => el.Name === 'Grösse')?.Value.split(', ');
        this.materials = catalogData.BasicArticleInformation?.Options.find(el => el.Name === 'Material')?.Value.split(', ');
        this.defaultSize = this.sizes ? this.sizes[0] : null;
        this.defaultMaterial = this.materials ? this.materials[0] : null;
      });
  }

  public setActiveVariant(data: CatalogData): void {
    this.selectedVariant = data;
  }

  public back(): void {
    this.backToCategories.emit();
  }

  public addModel(data: CatalogData): void {
    this._store
      .select(proposal)
      .pipe(
        take(1),
        map(state => state.ProposedProposal.Articles.find(article => article.Sku === data.BasicArticleInformation.SKU)),
        tap(article => {
          if (article) {
            if (this.checkModelLinkExist(data.BasicArticleInformation)) {
              const uuid = this._movingObjectsService.movingObjects.find(obj => obj.SKU === article.Sku).id;
              this._movingObjectsService.clone(uuid, true);
            } else {
              this._store.dispatch(includeSku({ sku: data.BasicArticleInformation.SKU, is3d: false }));
            }

            this._store.dispatch(setUnsavedChanges({ state: true }));
          } else {
            this._store.dispatch(
              includeSku({
                sku: data.BasicArticleInformation.SKU,
                is3d: this.checkModelLinkExist(data.BasicArticleInformation),
              })
            );

            this.isAlreadyOnScene = true;
          }
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  public checkModelLinkExist(data: BasicArticleInformation): boolean {
    return !!(data.MetaData.find(el => el.Key === '3D_GLB_LOW_ELI')?.Value || data.AR.GLB || data.AR.FBX);
  }

  public backFromVariant(variant: CatalogData): void {
    if (variant) {
      this.currentData = variant;

      if (this.resolutionService.isMobileResolution) {
        this.closeDialog.emit();
      }
    }

    this.selectedVariant = null;
    this._cdr.markForCheck();
  }

  public previous(images: string[]): void {
    if (this.imageIndex - 1 >= 0) {
      this.activeImage = images[--this.imageIndex];
    }
  }

  public next(images: string[]): void {
    if (this.imageIndex + 1 < images.length) {
      this.activeImage = images[++this.imageIndex];
    }
  }
}
