<nav class="header">
    <ul>
        <li>
            <img 
                class="logo-icon" 
                (click)="goToChild('result', 'default')" 
                src="./../../../../assets/icons/logo.svg" 
                alt="Kaqtu logo"
                aria-hidden="true"
            >
        </li>
        <li *ngIf="resolutionService.isMobileResolution">
            <mat-select 
                class="lang" 
                hideSingleSelectionIndicator 
                [value]="translateService.currentLang" 
                class="lang" #langSelect (selectionChange)="useLanguage(langSelect.value)"
            >
                <mat-option 
                    *ngFor="let lang of translateService.getLangs()" 
                    [value]="lang"
                >
                   {{ lang }}
                </mat-option>
            </mat-select>
        </li>
        <li 
            class="burger" 
            (click)="toggleMenu()"
            aria-hidden="true"
        >
            <i 
                class="material-icons" 
                [ngClass]="{'close': isMenuOpen}">
                {{ isMenuOpen ? "close" : "menu" }}
            </i>
        </li>
        <li class="menu" *ngIf="!resolutionService.isMobileResolution" [class.is-open]="isMenuOpen">
            <div class="menu-item subscription" *ngIf="isMenuOpen && isLoggedIn">
                <button class="button header-button" mat-button (click)="openSubscriptions()">
                    {{ "SHARED.MENU.SUBSCRIPTION" | translate}}
                    <mat-icon>person_outline</mat-icon>
                </button>
            </div>
            <div class="menu-item" *ngIf="(userRole$ | async) === userRoles.ADMIN">
                <button 
                    class="button header-button" 
                    mat-button
                    routerLink="/admin"
                    routerLinkActive="active"
                >
                    {{ "BUTTONS.USERS" | translate}}
                </button>
            </div>
            <div class="create-menu">
                <button 
                    (click)="goToChild('create', 'default')"
                    [class.active]="createMenuActive"
                    class="button header-button create-menu-button"
                    mat-button>{{ "BUTTONS.CREATE" | translate}}</button>
                <div class="create-menu-content">
                    <ng-container *ngIf="isLoggedIn && (userName$ | async) as userName" >
                        <mat-divider></mat-divider> 
                        <div aria-hidden="true" (click)="goToChild('create', 'default')">
                            <span class="submenu-item">{{ "SHARED.TABS.DEFAULT" | translate}}</span>
                        </div>
                        <mat-divider></mat-divider> 
                        <div aria-hidden="true" (click)="goToChild('create', 'styleswap')">
                            <span class="submenu-item">{{ "SHARED.TABS.STYLESWAP" | translate}}</span>
                        </div>
                        <mat-divider></mat-divider>
                        <div aria-hidden="true" (click)="goToChild('create', 'no-furniture')">
                            <span class="submenu-item">{{ "SHARED.TABS.NO_FURNITURE" | translate}}</span>
                        </div>
                        <mat-divider></mat-divider>
                        <div aria-hidden="true" (click)="goToChild('create', 'projects')">
                            <span class="submenu-item">{{ "SHARED.TABS.PROJECTS" | translate}}</span>
                        </div>
                    </ng-container>
                    <div aria-hidden="true" class="login" *ngIf="isLoggedOut" (click)="logIn()">
                        <span class="submenu-item">{{ "SHARED.LOGIN" | translate}}</span>
                    </div>
                </div>
            </div>
            <div class="result-menu">
                <button 
                    (click)="goToChild('result', 'default')"
                    [class.active]="resultMenuActive"
                    class="button header-button result-menu-button"
                    mat-button>{{ "BUTTONS.PROJECTS" | translate}}</button>
                <div class="result-menu-content">
                    <ng-container *ngIf="isLoggedIn && (userName$ | async) as userName" >
                        <div aria-hidden="true" (click)="goToChild('result', 'default')">
                            <span class="submenu-item">{{ "SHARED.TABS.DEFAULT" | translate}}</span>
                        </div>
                        <mat-divider></mat-divider> 
                        <div aria-hidden="true" (click)="goToChild('result', 'styleswap')">
                            <span class="submenu-item">{{ "SHARED.TABS.STYLESWAP" | translate}}</span>
                        </div>
                        <mat-divider></mat-divider>
                        <div aria-hidden="true" (click)="goToChild('result', 'no-furniture')">
                            <span class="submenu-item">{{ "SHARED.TABS.NO_FURNITURE" | translate}}</span>
                        </div>
                        <mat-divider></mat-divider>
                        <div aria-hidden="true" (click)="goToChild('result', 'projects')">
                            <span class="submenu-item">{{ "SHARED.TABS.PROJECTS" | translate}}</span>
                        </div>
                    </ng-container>
                    <div aria-hidden="true" class="login" *ngIf="isLoggedOut" (click)="logIn()">
                        <span class="submenu-item">{{ "SHARED.LOGIN" | translate}}</span>
                    </div>
                </div>
              </div>
            <div class="menu-item" >
                <button 
                    class="button header-button" 
                    mat-button 
                    routerLinkActive="active" 
                    routerLink="/request"
                >
                    {{ "BUTTONS.CONTACT" | translate}}
                </button>
            </div>
            <div class="icons-group" *ngIf="!resolutionService.isMobileResolution">
                <mat-select 
                    hideSingleSelectionIndicator 
                    [value]="translateService.currentLang" 
                    class="lang" 
                    #langSelect 
                    (selectionChange)="useLanguage(langSelect.value)"
                >
                    <mat-option 
                    *ngFor="let lang of translateService.getLangs()" 
                    [value]="lang"
                    >
                        {{ lang }}
                    </mat-option>
                </mat-select>
                <div class="menu-item" *ngIf="!isMenuOpen">
                    <button mat-icon-button [matMenuTriggerFor]="afterMenu">
                        <img class="l-icon" src="./../../../../assets/icons/person.svg" alt="Person">
                    </button>
                </div>
            </div>
            
            <div class="menu-item logout" *ngIf="isLoggedIn">
                <button class="button header-button" mat-button (click)="logOut()">
                    <mat-icon>exit_to_app</mat-icon>
                    {{ "SHARED.MENU.LOGOUT" | translate}}
                </button>
            </div>
            <div class="menu-item" *ngIf="isLoggedOut && resolutionService.isMobileResolution">
                <button class="button header-button" mat-button (click)="logIn()">
                    {{ "SHARED.LOGIN" | translate}}
                </button>
            </div>
        </li>
        <li class="menu" *ngIf="resolutionService.isMobileResolution" (clickOutside)="onClickedOutside($event)" [class.is-open]="isMenuOpen">
            <img 
                (click)="toggleMenu()" 
                class="close-icon" 
                src="./../../../../assets/icons/cancel mobile.svg" 
                alt="close menu"
                aria-hidden="true"
            >
            <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="mobile-tree">
                <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle (click)="onNodeClick(node)" class="mobile-tree__node">
                    <span class="node-text r-18">{{ node.name | translate }}</span>
                </mat-tree-node>
                <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                    <div class="mat-tree-node">
                        <div class="nested-node" matTreeNodeToggle
                                [attr.aria-label]="'Toggle ' + node.name">
                            <mat-icon class="icon">
                                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                            </mat-icon>
                            <span class="node-text r-18">{{ node.name | translate }}</span>
                        </div>
                    </div>
                    <!-- There is inline padding applied to this div using styles.
                        This padding value depends on the mat-icon-button width.  -->
                    <div [class.mobile-tree-invisible]="!treeControl.isExpanded(node)"
                        role="group">
                        <ng-container matTreeNodeOutlet></ng-container>
                    </div>
                </mat-nested-tree-node>
            </mat-tree>
            
        </li>
        <mat-menu #afterMenu="matMenu" xPosition="before" class="dropdown__menu">
            <ng-container *ngIf="isLoggedIn && (userName$ | async) as userName" >
                <div mat-menu-item class="user-name">
                    <button mat-mini-fab color="primary">
                        {{ userName | name }}
                    </button>
                    <div class="menu-item truncate">{{ userName }}</div>
                </div>
                <mat-divider></mat-divider> 
                <button mat-menu-item (click)="openSubscriptions()">
                    <mat-icon>person_outline</mat-icon>
                    <span class="menu-item">{{ "SHARED.MENU.SUBSCRIPTION" | translate}}</span>
                </button>
                <mat-divider></mat-divider>
                <button class="button" mat-menu-item (click)="logOut()">
                    <mat-icon>exit_to_app</mat-icon>
                    <span class="menu-item">{{ "SHARED.MENU.LOGOUT" | translate}}</span>
                </button>
            </ng-container>
            <button class="button login" mat-menu-item *ngIf="isLoggedOut" (click)="logIn()">
                <span class="menu-item">{{ "SHARED.LOGIN" | translate}}</span>
            </button>
        </mat-menu>
    </ul>
    <mat-divider></mat-divider>
</nav>
