import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, filter, finalize, map, Observable, of, switchMap, take, tap } from 'rxjs';
import { AuthService } from 'src/app/auth/services/auth.service';
import { Idea } from 'src/app/ideas/interfaces/idea';
import { IdeasHttpService } from 'src/app/ideas/services/ideas-http.service';
import { Notification } from 'src/app/notifications/model/notification';
import { NotificationsService } from 'src/app/notifications/services/notifications.service';
import { DEFAULT_LANGUAGE, LANGUAGES } from 'src/app/shared/configs/translate.config';
import { UserService } from 'src/app/shared/services/user.service';
import { StylefinderResponse } from 'src/app/user-flow/interfaces/stylefinder';
import { TranslateResponse } from 'src/app/user-flow/interfaces/translate';
import { RequestHttpService } from 'src/app/user-flow/services/request-http.service';
import { StyleFinderService } from 'src/app/user-flow/services/stylefinder.service';
import { GoogleTranslateService } from 'src/services/translate.service';

import { SharedActions } from '../action-types';
import {
  changeLanguage,
  changeLanguageSuccess,
  getProposedFurnitureSuccess,
  getStyleFinderIdeasSrcSuccess,
  getStyleFinderIdeasSuccess,
  getUserSuccess,
  sendOfferSuccess,
  setOverlayLoadingSpinner,
  translateFurnitureDescription,
  translateFurnitureDescriptionSuccess,
  userFirstLoginSuccess,
  viewFurnitureSuccess,
} from '../actions/shared.actions';
import { activeProjectId, activeProjectVersionId, lang } from '../selectors/shared.selector';

@Injectable()
export class SharedEffects {
  public loadUserName$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SharedActions.getUser),
      switchMap(() => this._userService.getUser()),
      finalize(() => this._store.dispatch(setOverlayLoadingSpinner({ status: false }))),
      map(data => {
        this.setLanguage(data.language);
        localStorage.setItem('role', data.role);
        if (!data.hasLoggedIn) {
          this._router.navigate(['/choose-plan']);
        }
        return getUserSuccess({ data });
      })
    )
  );

  public changeLanguage$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SharedActions.changeLanguage),
      switchMap(action => this._userService.setLanguage(action.lang.toUpperCase())),
      finalize(() => this._store.dispatch(setOverlayLoadingSpinner({ status: false }))),
      map(data => changeLanguageSuccess({ lang: data.language }))
    )
  );

  public setLanguage(lang: string): void {
    if (lang) {
      localStorage.setItem('lang', lang.toLocaleLowerCase());
      this._translateService.use(lang.toLocaleLowerCase());
    } else if (LANGUAGES.findIndex(lang => lang.id === this._translateService.getBrowserLang()) !== -1) {
      const browserLang = this._translateService.getBrowserLang();
      this._translateService.use(browserLang);
      localStorage.setItem('lang', browserLang);
      this._store.dispatch(changeLanguage({ lang: browserLang }));
    } else {
      this._translateService.use(DEFAULT_LANGUAGE.id);
      localStorage.setItem('lang', DEFAULT_LANGUAGE.id);
      this._store.dispatch(changeLanguage({ lang: DEFAULT_LANGUAGE.id }));
    }
  }

  public sendOffer$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SharedActions.sendOffer),
      tap(() => this._store.dispatch(setOverlayLoadingSpinner({ status: true }))),
      switchMap(action => {
        return combineLatest([this._store.select(activeProjectId), this._store.select(activeProjectVersionId)]).pipe(
          take(1),
          map(data => ({ projectId: data[0], versionId: data[1], form: action.data }))
        );
      }),
      switchMap(data => {
        if (data.versionId) {
          return this._requestHttpService.sendVersionOffer(data.form, data.versionId);
        } else if (data.projectId) {
          return this._requestHttpService.sendProjectOffer(data.form, data.projectId);
        } else {
          return this._authService.isLoggedIn()
            ? this._requestHttpService.sendOffer(data.form)
            : this._requestHttpService.sendOfferWithoutAuthContext(data.form);
        }
      }),
      map(() => {
        this._notificationsService.addNotification(
          new Notification({
            title: this._translateService.instant('NOTIFICATIONS.TITLE.SUCCESS'),
            text: this._translateService.instant('NOTIFICATIONS.MESSAGES.REQUEST_SUCCESS'),
            level: 'success',
            options: { timeout: 2 },
          })
        );
        return sendOfferSuccess();
      }),
      tap(() => {
        this._authService.isLoggedIn() ? this._router.navigate(['result']) : this._router.navigate(['login']);
        this._store.dispatch(setOverlayLoadingSpinner({ status: false }));
      })
    )
  );

  public userFirstLogin$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SharedActions.userFirstLogin),
      switchMap(() => this._userService.completeFirstLogin()),
      map(() => {
        return userFirstLoginSuccess();
      })
    )
  );

  public getStylefinderIdeas$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SharedActions.getStyleFinderIdeas),
      tap(() => this._store.dispatch(setOverlayLoadingSpinner({ status: true }))),
      switchMap(() => this._styleFinderService.getIdeas()),
      switchMap((ideas: Idea[]) => {
        this._store.dispatch(getStyleFinderIdeasSuccess({ ideas }));
        const previews$: Observable<{
          id: string;
          blob: Blob;
        }>[] = [];
        ideas.forEach(idea => {
          if (idea.link) {
            previews$.push(
              this._ideasHttpService.loadOriginalImage(idea.link).pipe(
                map(blob => {
                  return { id: idea.id, blob };
                })
              )
            );
          }
        });
        return previews$.length > 0 ? combineLatest(previews$) : of([]);
      }),
      switchMap((data: { id: string; blob: Blob }[]) => {
        const imageFiles$ = data.map(item => {
          return new Observable<{ id: string; src: string }>(subscriber => {
            const src = URL.createObjectURL(item.blob);
            subscriber.next({ src, id: item.id });
          });
        });
        return imageFiles$.length > 0 ? combineLatest(imageFiles$) : of([]);
      }),
      map((data: { id: string; src: string }[]) => {
        this._store.dispatch(setOverlayLoadingSpinner({ status: false }));
        return getStyleFinderIdeasSrcSuccess({ data });
      })
    )
  );

  public getProposedFurniture$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SharedActions.getProposedFurniture),
      switchMap(action => this._styleFinderService.getProposedFurniture(action.data)),
      map((data: StylefinderResponse) => {
        return getProposedFurnitureSuccess({ data });
      })
    )
  );

  public viewFurniture$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SharedActions.viewFurniture),
      tap(({ data }) => {
        if (data.translateDetails) {
          this._store.dispatch(translateFurnitureDescription({ description: data.article.BasicArticleInformation.Description }));
        }
      }),
      map(({ data }) => {
        return viewFurnitureSuccess({ data });
      })
    )
  );

  public translateFurnitureDescription$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SharedActions.translateFurnitureDescription),
      switchMap(data =>
        this._store.select(lang).pipe(
          filter(lang => !!lang),
          map(lang => {
            return { data: data, lang };
          })
        )
      ),
      switchMap(({ data, lang }) =>
        lang.toLocaleLowerCase() === 'de'
          ? of(data.description)
          : this._googleTranslateService.translate([data.description], lang).pipe(
              map((res: TranslateResponse) => {
                return res.translatedContents;
              })
            )
      ),
      map((description: string) => {
        return translateFurnitureDescriptionSuccess({ description });
      })
    )
  );

  constructor(
    private _actions$: Actions,
    private _authService: AuthService,
    private _userService: UserService,
    private _store: Store,
    private _requestHttpService: RequestHttpService,
    private _translateService: TranslateService,
    private _notificationsService: NotificationsService,
    private _router: Router,
    private _styleFinderService: StyleFinderService,
    private _ideasHttpService: IdeasHttpService,
    private _googleTranslateService: GoogleTranslateService
  ) {}
}
