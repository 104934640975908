<div class="content">
  <img 
    class="icon" src="./../../../../assets/icons/arrow-left-slide.png" 
    alt="arrow-left" 
    (click)="back()"
    aria-hidden="true"
  >
  <div class="header">
    <div class="actions">
      <button class="button" mat-stroked-button (click)="clearSelectedColor()">
        <img class="icon" src="./../../../../../assets/icons/trash-bin-green.svg" alt="trash-bin">
        {{'BUTTONS.DELETE' | translate}}
      </button>
    </div>
  </div>
  <div class="filters">
    <div class="search">
      <input
        class="search-input"
        type="text"
        name="search"
        [placeholder]="'USER_FLOW.COLOR_PAGE.SEARCH_PLACEHOLDER' | translate"
        [ngModel]="search"
        (ngModelChange)="onSearch($event)"
        (focusin)="searchStart()"
        (focusout)="searchEnd()"
      >
    </div>
    <svg xmlns="http://www.w3.org/2000/svg" width="17.25" height="18.985" viewBox="0 0 17.25 18.985" class="delete" (click)="clearSearch()">
      <g id="Gruppe_3004" data-name="Gruppe 3004" transform="translate(-200.375 -114.508)">
        <line id="Linie_868" data-name="Linie 868" x1="15" y2="17" transform="translate(201.5 115.5)" fill="none" stroke="#3c7f7d" stroke-width="3"/>
        <line id="Linie_869" data-name="Linie 869" x1="15" y1="17" transform="translate(201.5 115.5)" fill="none" stroke="#3c7f7d" stroke-width="3"/>
      </g>
    </svg>
    <div class="show-code">
      <input class="show-code_input" name="show-code" type="checkbox" [(ngModel)]="showCode" >
      <div class="show-code_label">{{ 'USER_FLOW.COLOR_PAGE.SHOW_CODE' | translate }}</div>
    </div>
  </div>
  <div class="all-colors">
    <div class="all-colors_groups">
      <div
        class="color-group"
        *ngFor="let group of viewGroups"
        [style.background-color]="group"
        [ngClass]="{ 'color-group__active': group === selectedGroup }"
        (click)="selectGroup(group)"
        aria-hidden="true"
      >
      </div>
    </div>
    <div class="all-colors_list">
      <div
        class="color"
        *ngFor="let arrayColor of colors"
        [style.background-color]="arrayColor.Hex"
        [ngClass]="{ 'color__active': arrayColor.Code === colorData?.color?.Code }"
        (click)="selectColor(arrayColor)"
        aria-hidden="true"
      >
        <div class="color_code" *ngIf="showCode">{{ arrayColor.Code }}</div>
      </div>
    </div>
  </div>
</div>
