import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { setOverlayLoadingSpinner } from 'src/app/store/actions/shared.actions';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private _loadingCount = 0;

  constructor(private _store: Store) {}

  public setLoading(value: boolean): void {
    if (value) {
      this._store.dispatch(setOverlayLoadingSpinner({ status: true }));
      this._loadingCount++;
    } else if (this._loadingCount > 0) {
      this._loadingCount--;
    }
    if (!this._loadingCount) {
      this._store.dispatch(setOverlayLoadingSpinner({ status: false }));
    }
  }
}
