import { Injectable } from '@angular/core';
import { MathUtils, Object3D, Vector3 } from 'three';

import { getObjectSize, getPointBetween, moveModel, rotateModel } from './autopositioning.function';
import { PlaneType } from './enum/plane-type.enum';
import { LightObjectsService } from './light-objects.service';
import { MovingObject } from './model/dto/moving-object.model';
import { MovingObjectsService } from './moving-objects.service';
import { PlanesService } from './planes.service';
import { SLEEPING_ROOM_DOUBLE_BED_WIDTH, SLEEPING_ROOM_SCHEMA } from './positioning-rules/bedroom.schema';
import { CurtainForWindowData } from './positioning-rules/curtains-data.model';
import { DINING_ROOM_SCHEMA } from './positioning-rules/dining-room.schema';
import { LIVING_ROOM_SCHEMA } from './positioning-rules/living-room.schema';
import { MetersSize, PositioningElement } from './positioning-rules/positioning-element.model';
import { RoomSchema } from './positioning-rules/schema.model';
import { TableType } from './positioning-rules/table-type.enum';
import { RoomParamsService } from './room-params.service';
import { Dimensions } from '../../../services/model/proposal';
import { FurnitureType } from '../enum/furniture-types.enum';
import { RoomType } from '../enum/room-type.enum';

@Injectable()
export class AutoPositioningService {
  private _leftCornerPosition: Vector3;
  private _rightCornerPosition: Vector3;

  constructor(
    private _movingObjectsService: MovingObjectsService,
    private _roomParamsService: RoomParamsService,
    private _planesService: PlanesService,
    private _lightObjectsService: LightObjectsService
  ) {}

  public run(type: RoomType, leftCorner: Object3D, rightCorner: Object3D): void {
    let schema: RoomSchema;

    switch (type) {
      case RoomType.LIVING:
        schema = LIVING_ROOM_SCHEMA;
        break;
      case RoomType.DINING:
        schema = DINING_ROOM_SCHEMA;
        break;
      case RoomType.SLEEPING:
        schema = SLEEPING_ROOM_SCHEMA;
        break;
    }

    const roomHeightObject = new Object3D();
    roomHeightObject.name = 'roomHeightObject';
    roomHeightObject.position.setY(this._roomParamsService.get().height3D);

    const roomCenterObject = new Object3D();
    roomCenterObject.name = 'roomHeightObject';
    const roomCenter = this._planesService.getRoomCenter();
    roomCenterObject.position.set(roomCenter.x, roomCenter.y, roomCenter.z);

    this._leftCornerPosition = leftCorner.position;
    this._rightCornerPosition = rightCorner.position;

    const realObjects: { [key: string]: Object3D | MovingObject[] } = {
      LEFT_CORNER: leftCorner,
      RIGHT_CORNER: rightCorner,
      ROOM_HEIGHT: roomHeightObject,
      ROOM_CENTER: roomCenterObject,
    };

    schema.elements.forEach(element => (realObjects[element.type] = this._findModelAll(element.type as FurnitureType)));
    schema.elements.forEach(element => this._handleElement(element, realObjects, type, roomCenter));

    this._lightObjectsService.synchronizeLightsWithObjects();
  }

  private _handleSleepingRoomSideTables(tables: MovingObject[], isDoubleBed: boolean): void {
    let sideTablesDelta = 0;

    if (isDoubleBed) {
      // only two side tables remain
      if (tables.length > 2) {
        sideTablesDelta = tables.length - 2;
      }

      // add one side table
      if (tables.length === 1) {
        sideTablesDelta = 1;
      }
    } else {
      // remove all tables except one
      if (tables.length > 1) {
        sideTablesDelta = 1 - tables.length;
      }
    }

    this._adjustNumberOfElements(tables, sideTablesDelta);
  }

  private _cloneDiningChairs(chairs: MovingObject[], tableWidth: number, tableType: TableType, dimensions: Dimensions): void {
    // number of chairs to remove\add during autopositioning
    let chairDelta: number;
    switch (tableType) {
      case TableType.CIRCLE:
        {
          chairDelta = dimensions.DiameterInCm > 130 ? 6 - chairs.length : 3 - chairs.length;
        }
        break;
      case TableType.SQUARE:
        {
          chairDelta = 3 - chairs.length;
        }
        break;
      case TableType.RECTANGULAR:
        {
          const chairWidth = getObjectSize(chairs[0].object3D).x;
          const paddings = 2 * this._roomParamsService.get3dFromMeter(0.1);
          const gap = this._roomParamsService.get3dFromMeter(0.1);
          const possibleChairsCount = Math.floor((tableWidth - paddings) / (chairWidth + gap));
          chairDelta = possibleChairsCount * 2 - chairs.length;
        }
        break;
    }

    this._adjustNumberOfElements(chairs, chairDelta);
  }

  private _checkWindowsNear(window: Vector3[], windows: Vector3[][]): { leftPosition: Vector3; rightPosition: Vector3 } {
    const ySorted = window.slice().sort((p1, p2) => (p1.y < p2.y ? 1 : -1));
    const xSorted = ySorted.slice(0, 2).sort((p1, p2) => (p1.x > p2.x ? 1 : -1));

    const wallCorners: [Vector3, Vector3] = [xSorted[0], xSorted[1]];

    const left = windows
      .slice()
      .filter(arrayWindow => arrayWindow !== window)
      .some(window => window.some(point => point.distanceTo(wallCorners[0]) < this._roomParamsService.get3dFromMeter(0.5)));

    const right = windows
      .slice()
      .filter(arrayWindow => arrayWindow !== window)
      .some(window => window.some(point => point.distanceTo(wallCorners[1]) < this._roomParamsService.get3dFromMeter(0.5)));

    return {
      leftPosition: !left ? wallCorners[0] : null,
      rightPosition: !right ? wallCorners[1] : null,
    };
  }

  private _cloneCurtains(curtains: MovingObject[], windows: Vector3[][]): CurtainForWindowData[] {
    const curtainsForWindow: CurtainForWindowData[] = [];

    if (curtains.length !== windows.length * 2) {
      const cloneCount = Math.max(windows.length * 2 - curtains.length, 0);

      for (let i = 0; i < cloneCount; i++) {
        curtains.push(this._movingObjectsService.clone(curtains[0].id, false));
      }
    }

    windows.forEach((window, index) => {
      const { leftPosition, rightPosition } = this._checkWindowsNear(window, windows);

      const newData: CurtainForWindowData = {
        window,
        left: { object: curtains[index * 2], position: leftPosition },
        right: { object: curtains[index * 2 + 1], position: rightPosition },
      };

      curtainsForWindow.push(newData);
    });

    return curtainsForWindow;
  }

  private _getTableType(table: MovingObject): TableType {
    if (!!table.dimensions.LengthInCm && !!table.dimensions.WidthInCm && table.dimensions.LengthInCm === table.dimensions.WidthInCm) {
      return TableType.SQUARE;
    }

    if (table.dimensions.DiameterInCm) {
      return TableType.CIRCLE;
    }

    return TableType.RECTANGULAR;
  }

  private _isPositioningElement(value: any): boolean {
    return value?.type && value?.rules;
  }

  private _isIndexElement(value: any): boolean {
    return typeof value === 'number';
  }

  private _handleElement(element: PositioningElement, realObjects: object, roomType: RoomType, roomCenter: Vector3): void {
    let object: Object3D;
    let objects: MovingObject[];
    let userData: any;

    const wallCorners: [Vector3, Vector3] = [this._leftCornerPosition, this._rightCornerPosition];

    if (Array.isArray(realObjects[element.type])) {
      objects = realObjects[element.type];
      if (objects.length) {
        object = objects[0].object3D;
      }
    } else {
      object = realObjects[element.type];
    }

    if (element.type === FurnitureType.SOFA) {
      const sofaMovingObject: MovingObject = realObjects[element.type][0];
      if (sofaMovingObject) {
        const categoryUppercase = sofaMovingObject.underCategory.toUpperCase();
        const isCornerSofa = categoryUppercase.includes('L-SOFA') || categoryUppercase.includes('ECKSOFA');
        const sofaOrientation = categoryUppercase.includes('RECHTS') ? 'right' : 'left';

        userData = {
          isCornerSofa,
          sofaOrientation,
          sideTable: realObjects[FurnitureType.SIDE_TABLE][0]?.object3D,
          wallCorners,
        };
      }
    }

    if (element.type === FurnitureType.CURTAIN) {
      const windows = this._planesService.getWindows();

      if (objects.length > 0 && windows.length > 0) {
        userData = {
          curtainsForWindows: this._cloneCurtains(objects, windows),
          roomHeight: this._roomParamsService.get().heightMeters,
          ceilingY: this._planesService.getAll().find(plane => plane.type === PlaneType.CEILING)?.corners[0].position.y,
        };
      }
    }

    if (element.type === FurnitureType.DINING_TABLE_CHAIR) {
      const diningTables: MovingObject[] = realObjects[FurnitureType.DINING_TABLE];

      if (objects.length > 0 && diningTables.length > 0) {
        const tableWidth = getObjectSize(diningTables[0].object3D).x;
        const tableType = this._getTableType(diningTables[0]);
        this._cloneDiningChairs(objects, tableWidth, tableType, diningTables[0].dimensions);
        userData = {
          table: diningTables[0],
          tableType,
          wallCorners,
          gap: this._roomParamsService.get3dFromMeter(0.1),
          metersPerPixel: this._roomParamsService.getMeterFrom3D(1),
        };
      }
    }

    if (element.type === FurnitureType.SIDE_TABLE && roomType === RoomType.SLEEPING) {
      const beds: MovingObject[] = realObjects[FurnitureType.BED];

      if (beds.length > 0) {
        const bedSize = getObjectSize(beds[0].object3D);
        const bedWidth = this._roomParamsService.getMeterFrom3D(bedSize.x);

        this._handleSleepingRoomSideTables(objects, bedWidth > SLEEPING_ROOM_DOUBLE_BED_WIDTH);
        userData = { beds, wallCorners };
      }
    }

    if (element.type === FurnitureType.PAINTING) {
      userData = { heightMeters: this._roomParamsService.get().heightMeters };
    }

    if (element.type === FurnitureType.LAMP) {
      userData = { floorY: this._roomParamsService.roomBoundaries.yMin, heightMeters: this._roomParamsService.get().heightMeters };
    }

    if (element.type === FurnitureType.TABLE_LAMP) {
      const tables: MovingObject[] = realObjects[FurnitureType.SIDE_TABLE];

      if (tables.length > 0) {
        const deltaLamps = tables.length - objects.length;
        this._adjustNumberOfElements(objects, deltaLamps);

        userData = { tables, wallCorners };
      }
    }

    if (element.type === FurnitureType.BED) {
      userData = { wallCorners };
    }

    userData = { ...userData, roomCenter };

    if (object) {
      const size = object.name === 'roomHeightObject' ? new Vector3(0, object.position.y, 0) : getObjectSize(object);
      const metersSize: MetersSize = {
        width: this._roomParamsService.getMeterFrom3D(size.x),
        height: this._roomParamsService.getMeterFrom3D(size.y),
        depth: this._roomParamsService.getMeterFrom3D(size.z),
      };

      element.rules.forEach(rule => {
        switch (rule.action) {
          case 'SET_AT_WALL':
            const distance = this._roomParamsService.get3dFromMeter(rule.distance(metersSize));

            let additional = 0;

            rule?.relatedObjects?.forEach(type => {
              const object3D = realObjects[type][0]?.object3D;

              if (object3D) {
                additional = Math.max(getObjectSize(object3D).x, getObjectSize(object3D).z);
                return;
              }
            });

            const position = getPointBetween(this._leftCornerPosition, this._rightCornerPosition, distance + additional);

            object.position.set(position.x, position.y, position.z);
            break;
          case 'MOVE':
            const realRelatedObject: { action: Object3D; condition: Object3D } = { action: null, condition: null };
            const realRelatedObjectMetersSize: {
              action: MetersSize;
              condition: MetersSize;
            } = { action: null, condition: null };

            if (rule?.relatedObject) {
              if (this._isPositioningElement(rule.relatedObject.inAction)) {
                const value = realObjects[rule.relatedObject.inAction['type']];
                if (Array.isArray(value) && value.length) {
                  realRelatedObject.action = value[0].object3D;
                } else if (!Array.isArray(value)) {
                  realRelatedObject.action = value;
                }
              } else if (this._isIndexElement(rule.relatedObject.inAction)) {
                const index = rule.relatedObject.inAction as number;
                realRelatedObject.action = objects[index]?.object3D;
              }

              if (this._isPositioningElement(rule.relatedObject.inCondition)) {
                const value = realObjects[rule.relatedObject.inCondition['type']];
                if (Array.isArray(value) && value.length) {
                  realRelatedObject.condition = value[0].object3D;
                } else if (!Array.isArray(value)) {
                  realRelatedObject.condition = value;
                }
              } else if (this._isIndexElement(rule.relatedObject.inCondition)) {
                const index = rule.relatedObject.inCondition as number;
                realRelatedObject.condition = objects[index]?.object3D;
              }

              if (realRelatedObject.action) {
                realRelatedObjectMetersSize.action = {
                  width: this._roomParamsService.getMeterFrom3D(getObjectSize(realRelatedObject.action).x),
                  height: this._roomParamsService.getMeterFrom3D(getObjectSize(realRelatedObject.action).y),
                  depth: this._roomParamsService.getMeterFrom3D(getObjectSize(realRelatedObject.action).z),
                };
              }

              if (realRelatedObject.condition) {
                realRelatedObjectMetersSize.condition = {
                  width: this._roomParamsService.getMeterFrom3D(getObjectSize(realRelatedObject.condition).x),
                  height: this._roomParamsService.getMeterFrom3D(getObjectSize(realRelatedObject.condition).y),
                  depth: this._roomParamsService.getMeterFrom3D(getObjectSize(realRelatedObject.condition).z),
                };
              }
            }

            if (rule.index) {
              const object = objects[rule.index];

              if (object) {
                moveModel(
                  object.object3D,
                  metersSize,
                  rule,
                  wallCorners,
                  roomCenter,
                  this._roomParamsService.get3dFromMeter(1),
                  realRelatedObject.action,
                  realRelatedObjectMetersSize.action
                );
              }

              return;
            }

            if (
              (!rule.relatedObject?.inAction || (realRelatedObject.action && realRelatedObjectMetersSize.action)) &&
              (!rule.relatedObject?.inCondition || (realRelatedObject.condition && realRelatedObjectMetersSize.condition)) &&
              (!rule.condition || rule.condition(metersSize, realRelatedObjectMetersSize.condition))
            ) {
              objects.forEach(object => {
                moveModel(
                  object.object3D,
                  metersSize,
                  rule,
                  wallCorners,
                  roomCenter,
                  this._roomParamsService.get3dFromMeter(1),
                  realRelatedObject.action,
                  realRelatedObjectMetersSize.action
                );
              });
            }

            break;
          case 'LOOK_AT':
            {
              let lookAt: Vector3;

              if (this._isPositioningElement(rule.value)) {
                let realRelatedObject: Object3D;

                const value = realObjects[rule.value['type']];
                if (Array.isArray(value) && value.length) {
                  realRelatedObject = value[0].object3D;
                } else if (!Array.isArray(value)) {
                  realRelatedObject = value;
                }

                lookAt = realRelatedObject?.position;
              } else if (this._isIndexElement(rule.value)) {
                const index = rule.value as number;

                lookAt = objects[index].object3D.position;
              }

              if (rule.index) {
                const object = objects[rule.index];

                rotateModel(object.object3D, lookAt, wallCorners);
              } else {
                objects.forEach(object => rotateModel(object.object3D, lookAt, wallCorners));
              }
            }
            break;
          case 'ROTATE':
            {
              const realRelatedObject: {
                action: Object3D;
                condition: Object3D;
              } = { action: null, condition: null };

              if (rule.relatedObject?.inAction) {
                const value = realObjects[rule.relatedObject.inAction.type];
                if (Array.isArray(value) && value.length) {
                  realRelatedObject.action = value[0].object3D;
                } else if (!Array.isArray(value)) {
                  realRelatedObject.action = value;
                }
              }

              if (realRelatedObject.action) {
                object.rotation.set(
                  realRelatedObject.action.rotation.x,
                  realRelatedObject.action.rotation.y,
                  realRelatedObject.action.rotation.z
                );
              }

              if (!rule.condition || rule.condition(metersSize)) {
                switch (rule.asix) {
                  case 'x':
                    object.rotateX(MathUtils.degToRad(rule.value));
                    break;
                  case 'y':
                    object.rotateY(MathUtils.degToRad(rule.value));
                    break;
                  case 'z':
                    object.rotateZ(MathUtils.degToRad(rule.value));
                    break;
                }
              }
            }

            break;
          case 'SET_POSITION': {
            const rulePosition = rule.position(object.userData);

            object.position.set(rulePosition.x, rulePosition.y, rulePosition.z);

            break;
          }
          case 'SCALE':
            const scale = new Vector3(
              rule.width ?? metersSize.width / metersSize.width,
              rule.height ?? metersSize.height / metersSize.height,
              rule.depth ?? metersSize.depth / metersSize.depth
            );

            object.scale.set(scale.x, scale.y, scale.z);

            break;
          case 'SET_CUSTOM': {
            const meterPerPixel = this._roomParamsService.getMeterFrom3D(1);

            rule.callback(objects, userData, meterPerPixel);
            break;
          }
        }

        let lightPosition: Vector3;
        switch (element.type) {
          case FurnitureType.LAMP:
            lightPosition = object.position.clone();
            break;
          case FurnitureType.FLOOR_LAMP:
          case FurnitureType.TABLE_LAMP:
            lightPosition = object.position.clone().add(new Vector3(0, size.y * 0.9, 0));
            break;
        }

        if (lightPosition) {
          objects[0].light.position.set(lightPosition.x, lightPosition.y, lightPosition.z);
        }
      });
    }
  }

  // either adds or removes number of elements from proposal
  private _adjustNumberOfElements(objects: MovingObject[], delta: number): void {
    for (let i = 0; i < Math.abs(delta); i++) {
      if (delta > 0) {
        const cloned = this._movingObjectsService.clone(objects[0].id, false);
        objects.push(cloned);
      } else {
        const removed = objects.pop();
        this._movingObjectsService.removeObject(removed, false);
      }
    }
  }

  private _findModelAll(type: FurnitureType): MovingObject[] {
    return this._movingObjectsService.movingObjects.filter(furniture => furniture.type === type);
  }
}
