import { createReducer, on } from '@ngrx/store';

import { UndoRedoActions } from './undo-redo.action-types';
import { UndoRedo, UndoRedoState } from './undo-redo.state';

const initialUndoRedoState: UndoRedoState = {
  undoActions: [],
  redoActions: [],
  current: null,
};

export const undoRedoReducer = createReducer(
  initialUndoRedoState,
  on(UndoRedoActions.redoAction, state => {
    if (state.redoActions.length === 0) {
      return state;
    }
    return {
      ...state,
      undoActions: [...state.undoActions, state.redoActions[0]],
      redoActions: state.redoActions.slice(1),
      current: {
        action: state.redoActions[0],
        command: UndoRedo.REDO,
      },
    };
  }),
  on(UndoRedoActions.undoAction, state => {
    if (state.undoActions.length === 0) {
      return state;
    }
    return {
      ...state,
      undoActions: state.undoActions.slice(0, state.undoActions.length - 1),
      redoActions: [state.undoActions[state.undoActions.length - 1], ...state.redoActions],
      current: {
        action: state.undoActions[state.undoActions.length - 1],
        command: UndoRedo.UNDO,
      },
    };
  }),
  on(UndoRedoActions.add, (state, action) => {
    return {
      ...state,
      undoActions: [...state.undoActions, action.payload],
      redoActions: [],
    };
  }),
  on(UndoRedoActions.clearUndoRedoState, state => {
    return {
      ...state,
      ...initialUndoRedoState,
    };
  })
);
