<button class="close-button" mat-icon-button aria-label="close dialog" mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>
<div class="instruction">
  <div class="name m-25">{{ 'USER_FLOW.MANUAL_RECONSTRUCTION.INSTRUCTION.NAME' | translate }}</div>
  <img 
    class="image" 
    src="../../../../assets/manual-reconstruction/instruction.png"
    alt="instruction"
  >
  <div class="paragraph r-18">
    {{ 'USER_FLOW.MANUAL_RECONSTRUCTION.INSTRUCTION.1' | translate }}
  </div>
  <div class="paragraph r-18">
    {{ 'USER_FLOW.MANUAL_RECONSTRUCTION.INSTRUCTION.2' | translate }}
  </div>
  <div class="paragraph r-18">
    {{ 'USER_FLOW.MANUAL_RECONSTRUCTION.INSTRUCTION.3' | translate }}
  </div>
  <div class="paragraph r-18 paragraph_with-image">
    {{ 'USER_FLOW.MANUAL_RECONSTRUCTION.INSTRUCTION.4' | translate }}
    <img 
      class="direction" 
      src="../../../../assets/manual-reconstruction/direction.png"
      alt="direction"
    >
  </div>
  <div class="paragraph r-18">
    {{ 'USER_FLOW.MANUAL_RECONSTRUCTION.INSTRUCTION.5' | translate }}
    &nbsp;
    <button
        class="button button-ellipse"
        mat-raised-button
        color="white"
      >
        {{'BUTTONS.RECONSTRUCT' | translate}}
      </button>
  </div>
  <div class="paragraph r-18">
    {{ 'USER_FLOW.MANUAL_RECONSTRUCTION.INSTRUCTION.6' | translate }}
  </div>
</div>
