import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { catchError, Observable, of, switchMap, throwError } from 'rxjs';
import { setOverlayLoadingSpinner } from 'src/app/store/actions/shared.actions';

import { AUTH_INTERCEPT } from '../../../services/context-tokens/intercept.context-token';
import { LoginResponse } from '../model/login.model';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private _router: Router,
    private _authService: AuthService,
    private _store: Store
  ) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<any> {
    const token = localStorage.getItem('token');
    const shouldIntercept = request.context.has(AUTH_INTERCEPT);

    if (token && shouldIntercept) {
      return next
        .handle(
          request.clone({
            headers: request.headers.set('Authorization', 'Bearer ' + token),
          })
        )
        .pipe(
          catchError(error => {
            if (error.status === 401 || error.status === 403) {
              return this._refreshSession(request, next);
            }
            return throwError(() => error);
          })
        );
    }

    return next.handle(request);
  }

  private _refreshSession(request: HttpRequest<unknown>, next: HttpHandler): Observable<string | HttpEvent<any>> {
    return this._authService.refresh().pipe(
      switchMap((data: LoginResponse) => {
        this._authService.setSession(data);
        request = request.clone({
          headers: request.headers.set('Authorization', 'Bearer ' + data.token),
        });
        return next.handle(request).pipe(catchError(error => this._handleAuthError(error)));
      }),
      catchError(error => this._handleAuthError(error))
    );
  }

  private _handleAuthError(error: HttpErrorResponse): Observable<string> {
    if (error.status === 401 || error.status === 403) {
      this._store.dispatch(setOverlayLoadingSpinner({ status: false }));
      this._authService.logout();
      this._router.navigate(['login']);
      return of(error.message);
    }
    return throwError(() => error);
  }
}
