import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, filter, map, Observable, of, take } from 'rxjs';
import { Notification } from 'src/app/notifications/model/notification';
import { NotificationsService } from 'src/app/notifications/services/notifications.service';
import {
  ADD_LIGHT_POINT,
  addLightPoint,
  addWallCorner,
  DELETE_LIGHT_POINT,
  deleteLightPoint,
  deleteWallCorner,
  MOVE_LIGHT_POINT,
  MOVE_MODEL_MOUSE,
  moveLightPoint,
  moveModelMouse,
} from 'src/app/store/actions/render.actions';
import { reconstruct, setUnsavedChanges } from 'src/app/store/actions/shared.actions';
import { activeProjectWidthHeight, reconstructionResult } from 'src/app/store/selectors/shared.selector';
import { RoomType } from 'src/app/user-flow/enum/room-type.enum';
import { MovingObject } from 'src/app/user-flow/services/model/dto/moving-object.model';
import {
  AmbientLight,
  Group,
  Intersection,
  Line3,
  Mesh,
  MeshBasicMaterial,
  Object3D,
  PlaneGeometry,
  Raycaster,
  SphereGeometry,
  Vector2,
  Vector3,
} from 'three';
import { DragControls } from 'three/examples/jsm/controls/DragControls';
import { ConvexGeometry } from 'three/examples/jsm/geometries/ConvexGeometry';
import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer';

import { AutoPositioningService } from './auto-positioning.service';
import { getForwardDirection, getObjectSize, getPointBetween, getRotation } from './autopositioning.function';
import { setUpCamera } from './camera.function';
import { WallPointGroup } from './class/wall-point-group.class';
import { WallPoint } from './class/wall-point.class';
import { MAIN_WINDOW_LIGHT, SUP_WINDOW_LIGHT } from './const/blender.const';
import { WINDOW } from './const/colors.const';
import { CornersControlsService } from './corners-controls.service';
import { PlaneType } from './enum/plane-type.enum';
import { SticknessType } from './enum/stickness-type.enum';
import { ExportService } from './export.service';
import { hasSimilarCoordinates } from './functions/has-simillar-coordinates.function';
import { getHorizontalHullPoints, getXYQuaternion } from './functions/surface.utils';
import { ImportService } from './import.service';
import { LightObjectsService } from './light-objects.service';
import { getPointLightSource, patchSpotLightTargetPosition } from './light.utils';
import { ManualReconstructionService } from './manual-reconstruction.service';
import { RoomServiceConfig } from './model/config/room-service-config.model';
import { DetectObjectsResult } from './model/dto/detect-object-result.model';
import { PointPlane, RoomPlane } from './model/dto/plane.model';
import { Hole } from './model/hole.model';
import { RoomSaveData } from './model/saveData/room-save-data.model';
import { MovingObjectsService } from './moving-objects.service';
import { PlanesService } from './planes.service';
import { ResolutionService } from './resolution.service';
import { RoomParamsService } from './room-params.service';
import { InteriorPlane, InteriorResult, Point } from '../../../services/model/detect-floor-result.model';
import { getCurrentAction } from '../../undo-redo/undo-redo.selectors';
import { FurnitureType } from '../enum/furniture-types.enum';
import { Current } from '../interfaces/current';
import { PointType } from '../manual-reconstruct-editor/holes.enum';

@Injectable()
export class RoomService {
  private _activeLightPoint!: Object3D;
  private _ambientLight: AmbientLight = new AmbientLight();
  private _objectsGroup: Group = new Group();
  private _planesGroup: Group = new Group();
  private _shadowGroup: Group = new Group();
  private _wallCornersGroup: WallPointGroup = new WallPointGroup();
  private _lightPointsGroup: Group = new Group();
  private _autoPositionGroup: Group = new Group();
  private _selectedPlaneIntersection: Intersection<Mesh>;
  private _newWallPoints: Mesh[] = [];
  private _selectedFloorPoint: Mesh;
  private _selectedCorner: WallPoint;
  private _selectedCorners: { uuid: string; position: Vector3 }[];
  private _selectedDeleteHole: { index: number; planeId: string; type: PointType };

  private _selectedMovingObjects: { mo: MovingObject; initialPosition: Vector3; initialRotation: Vector3 }[] = [];

  private _raycaster = new Raycaster();
  private _collision = false;
  private _mouse: { x: number; y: number };
  private _selectedMovingObject: MovingObject;
  private _lightPointControls: DragControls;

  private _config: RoomServiceConfig;
  private _firstTime = false;
  private _wallCopy: Mesh;
  private _start: Vector3;
  private _detectObjectResult: DetectObjectsResult;

  private _highlightedObjectsIds: string[] = [];
  private _interiorResultValid$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _selectedMovingObjectId$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  public selectedMovingObjectId$ = this._selectedMovingObjectId$.asObservable();
  public interiorResultInvalid$ = this._interiorResultValid$.asObservable().pipe(map(val => !val));

  public get selectedMovingObjects(): Object3D<THREE.Event>[] {
    return this._selectedMovingObjects.map(metaMo => metaMo.mo.object3D);
  }

  public set highlightedObjectsIds(values: string[]) {
    this._highlightedObjectsIds = [...values];
  }

  public get highlightedObjectsIds(): string[] {
    return this._highlightedObjectsIds;
  }

  constructor(
    private _dialog: MatDialog,
    private _movingObjectsService: MovingObjectsService,
    private _lightObjectsService: LightObjectsService,
    private _notificationsService: NotificationsService,
    private _planesService: PlanesService,
    private _roomParamsService: RoomParamsService,
    private _manualReconstructionService: ManualReconstructionService,
    private _importService: ImportService,
    private _store: Store,
    private _translateService: TranslateService,
    private _exportService: ExportService,
    private _autoPositioningSerice: AutoPositioningService,
    private _cornerControlsService: CornersControlsService,
    private _resolutionService: ResolutionService,
    @Inject(DOCUMENT) private _document: Document
  ) {}

  public getLightsCount(): number {
    return this._config.scene.getObjectsByProperty('isPointLight', true).length;
  }

  public setInitialState(): void {
    this._activeLightPoint = null;
    this._ambientLight = new AmbientLight();
    this._objectsGroup = new Group();
    this._planesGroup = new Group();
    this._shadowGroup = new Group();
    this._wallCornersGroup = new WallPointGroup();
    this._lightPointsGroup = new Group();
    this._autoPositionGroup = new Group();
    this._selectedMovingObjects = [];
    this._selectedPlaneIntersection = null;
    this._newWallPoints = [];
    this._selectedFloorPoint = null;
    this._raycaster = new Raycaster();
    this._collision = false;
    this._mouse = null;
    this._selectedMovingObject = null;
    this._lightPointControls = null;
    this._config = null;
  }

  public deleteSelectedLightPoint(mouse: { x: number; y: number }): void {
    this._raycaster.setFromCamera(mouse, this._config.camera);
    const intersects = this._raycaster.intersectObjects(this._lightPointsGroup.children);
    if (intersects.length > 0) {
      this._deleteLightPoint(intersects[0].object.uuid, true);
    }
  }

  public configure(config: RoomServiceConfig): void {
    this._config = config;

    this._objectsGroup = this._config.scene.getObjectByName('objectsGroup') as Group;
    this._wallCornersGroup = this._config.scene.getObjectByName('wallCornersGroup') as WallPointGroup;
    this._lightPointsGroup = this._config.scene.getObjectByName('lightPointsGroup') as Group;
    this._planesGroup = this._config.scene.getObjectByName('planesGroup') as Group;
    this._shadowGroup = this._config.scene.getObjectByName('shadowGroup') as Group;
    this._autoPositionGroup = this._config.scene.getObjectByName('autoPositionGroup') as Group;

    this._ambientLight = this._config.scene.getObjectByProperty('isAmbientLight', true) as AmbientLight;
    if (!this._ambientLight) {
      this._ambientLight = new AmbientLight(0xffffff, 0.5);
    }

    this._manualReconstructionService.configure({
      camera: config.camera,
      canvasHeight: config.domElement.clientHeight,
      wallCornersGroup: this._wallCornersGroup,
      planesGroup: this._planesGroup,
    });

    this._cornerControlsService.configure({
      wallCornersGroup: this._wallCornersGroup,
      camera: config.camera,
      scene: config.scene,
      domElement: config.domElement,
    });

    this._store.select(getCurrentAction).subscribe(current => {
      if (current) {
        switch (current.action.name) {
          case ADD_LIGHT_POINT:
            this._addLightPointStore(current);
            break;
          case DELETE_LIGHT_POINT:
            this._deleteLightPointStore(current);
            break;
          case MOVE_LIGHT_POINT:
            this._moveLightPointStore(current);
            break;
          case MOVE_MODEL_MOUSE:
            this._moveModelMouse(current);
            break;
        }
      }
    });
  }

  public changeSelectedMovingObject(uuid: string | null): void {
    this._selectedMovingObjectId$.next(uuid);
  }

  public selectPlane(mouse: { x: number; y: number }): void {
    this._raycaster.setFromCamera(mouse, this._config.camera);
    const intersects = this._raycaster.intersectObjects(this._planesGroup.children);
    if (intersects.length > 0) {
      this._setSelectedPlane(intersects[0] as Intersection<Mesh>);
    }
  }

  public selectMoveWallPoint(mouse: { x: number; y: number }): void {
    this._raycaster.setFromCamera(mouse, this._config.camera);
    const intersectsObjects = this._raycaster.intersectObjects(
      this._wallCornersGroup.children.filter(corner => corner.userData['type'] === PointType.CORNER)
    );

    const intersectionPart = this._cornerControlsService.getIntersectionPart();
    if (intersectionPart) {
      const intersectsControls = this._raycaster.intersectObjects([...intersectionPart]);

      if (intersectsControls.length === 0) {
        this.setSelectedCorner(null);
        this._cornerControlsService.detachCornersControls();
      }
    }

    if (intersectsObjects.length > 0) {
      this.setSelectedCorner(intersectsObjects[0].object as WallPoint);
      this._selectedCorner.enableColorHighlight();
      this._cornerControlsService.detachCornersControls();
      this._cornerControlsService.attach(intersectsObjects[0].object);
    }
  }

  private _getCornerFullData(object: Mesh): { index: number; type: PointType; plane: RoomPlane } {
    const type = object.userData['type'] as PointType;
    const plane = this._planesService.getAll().find(plane => plane.id === object.userData['planes'][0].planeId);

    let index: number;
    switch (type) {
      case PointType.WINDOW:
        index = plane.windows.findIndex(window => window.find(corner => corner.uuid === object.uuid));
        break;
      case PointType.DOOR:
        index = plane.doors.findIndex(door => door.find(corner => corner.uuid === object.uuid));
        break;
    }

    return { index, type, plane };
  }

  public moveWindowDoor(mouse: { x: number; y: number }): void {
    this._raycaster.setFromCamera(mouse, this._config.camera);

    const corners = this._wallCornersGroup.children.filter(
      corner => corner.userData['type'] === PointType.DOOR || corner.userData['type'] === PointType.WINDOW
    );

    const holesPlanes = this._planesService
      .getAll()
      .filter(plane => plane.windows.length > 0 || plane.doors.length > 0)
      .map(plane => [...plane.windows, ...plane.doors])
      .filter(array => array.length > 0)
      .reduce((prev, curr) => [...prev, ...curr], [])
      .map(hole => {
        const mesh = new Mesh(new ConvexGeometry(hole.map(point => point.position)));
        mesh.userData['planes'] = hole[0].userData['planes'];
        mesh.userData['type'] = hole[0].userData['type'];
        mesh.userData['allPoints'] = true;
        mesh.uuid = hole[0].uuid;
        return mesh;
      });

    const intersectsObjects = this._raycaster.intersectObjects([...corners, ...holesPlanes]);

    const intersectsControls = this._raycaster.intersectObjects([...this._cornerControlsService.getIntersectionPart()]);

    if (intersectsControls.length === 0) {
      this._cornerControlsService.detachCornersControls();
    }

    if (intersectsObjects.length > 0) {
      this._start = intersectsObjects[0].point;

      const data = this._getCornerFullData(intersectsObjects[0].object as Mesh);

      if (intersectsObjects[0].object.userData['allPoints']) {
        switch (data.type) {
          case PointType.WINDOW:
            this._selectedCorners = data.plane.windows[data.index].map(corner => ({
              uuid: corner.uuid,
              position: corner.position.clone(),
            }));
            break;
          case PointType.DOOR:
            this._selectedCorners = data.plane.doors[data.index].map(corner => ({ uuid: corner.uuid, position: corner.position.clone() }));
            break;
        }
      } else {
        this.setSelectedCorner(intersectsObjects[0].object as WallPoint);
      }

      const wall = this._planesService.getAll().find(plane => plane.id === intersectsObjects[0].object.userData['planes'][0].planeId);
      this._wallCopy = new Mesh(new ConvexGeometry(wall.corners.map(corner => corner.position)), new MeshBasicMaterial());

      this._selectedDeleteHole = {
        planeId: data.plane.id,
        index: data.index,
        type: data.type,
      };
    } else {
      this._selectedDeleteHole = null;
    }
  }

  public getRotationConfig(mouse: { x: number; y: number }): null | { right: number; top: number; movingObject: MovingObject } {
    this._raycaster.setFromCamera(mouse, this._config.camera);
    const intersectsObjects = this._raycaster.intersectObjects(this._objectsGroup.children);

    if (intersectsObjects.length > 0) {
      const selectedObject = this._getMainObject(intersectsObjects[0].object);
      const movingObject = this._selectedMovingObjects.find(object => object.mo.id === selectedObject.uuid);

      if (movingObject) {
        const canvasWidth = this._config.domElement.clientWidth;
        const canvasHeight = this._config.domElement.clientHeight;

        const movingObjectCanvasPosition = this._movingObjectsService.convertToTwoDimensions(
          movingObject.mo.object3D.position.clone(),
          canvasWidth,
          canvasHeight,
          this._config.camera
        );

        return {
          right: canvasWidth - movingObjectCanvasPosition.x,
          top: movingObjectCanvasPosition.y,
          movingObject: movingObject.mo,
        };
      }
    }

    return null;
  }

  public updateObjectRotation(object: MovingObject): void {
    const moMeta = this._selectedMovingObjects.find(moMeta => moMeta.mo.id === object.id);

    if (moMeta) {
      this.dispatchMoveModelAction(moMeta);
    }
  }

  public selectMoveModel(mouse: { x: number; y: number }, multiPressed: boolean): boolean {
    if (!multiPressed) {
      this._selectedMovingObjects = [];
    }

    this._raycaster.setFromCamera(mouse, this._config.camera);
    const intersectsObjects = this._raycaster.intersectObjects(this._objectsGroup.children);

    if (intersectsObjects.length > 0) {
      const selectedObject = this._getMainObject(intersectsObjects[0].object);

      const movingObjectToSelect = this._movingObjectsService.movingObjects.find(object => object.id === selectedObject.uuid);

      if (this._selectedMovingObjects.length === 0) {
        const position = movingObjectToSelect.object3D.position.clone();
        const rotation = movingObjectToSelect.object3D.rotation.clone();
        this._selectedMovingObjects.push({
          mo: movingObjectToSelect,
          initialPosition: position,
          initialRotation: new Vector3(rotation.x, rotation.y, rotation.z),
        });
      }

      if (movingObjectToSelect.sticknessType !== this._selectedMovingObjects.at(0).mo.sticknessType) {
        return false;
      } else {
        this._selectedMovingObject = movingObjectToSelect;
      }

      if (multiPressed && this._selectedMovingObjects.findIndex(obj => obj.mo.id === this._selectedMovingObject.id) === -1) {
        this._addToMultiMovementGroup(this._selectedMovingObject);
      }

      this._selectedMovingObjectId$.next(this._selectedMovingObject.id);

      return true;
    }

    this._selectedMovingObjectId$.next(null);

    return false;
  }

  private _addToMultiMovementGroup(object: MovingObject): boolean {
    if (this._selectedMovingObjects.at(0).mo.sticknessType === object.sticknessType) {
      const position = object.object3D.position.clone();
      const rotation = object.object3D.rotation.clone();
      this._selectedMovingObjects.push({
        mo: object,
        initialPosition: position,
        initialRotation: new Vector3(rotation.x, rotation.y, rotation.z),
      });

      return true;
    }

    return false;
  }

  public deselectMoveModel(): void {
    if (this._selectedMovingObject) {
      const dispatchedResult = this._selectedMovingObjects.map(moMeta => this.dispatchMoveModelAction(moMeta));

      if (dispatchedResult.some(value => !!value)) {
        this._selectedMovingObjects = [];
      }

      this._selectedMovingObject = null;
    }
  }

  public dispatchMoveModelAction(moMeta: { mo: MovingObject; initialPosition: Vector3; initialRotation: Vector3 }): boolean {
    const endPosition = moMeta.mo.object3D.position.clone();
    const endEulerRotation = moMeta.mo.object3D.rotation.clone();
    const endRotation = new Vector3(endEulerRotation.x, endEulerRotation.y, endEulerRotation.z);
    const id = moMeta.mo.id;
    if (!moMeta.mo.object3D.position.equals(moMeta.initialPosition) || !endRotation.equals(moMeta.initialRotation)) {
      this._store.dispatch(
        moveModelMouse({
          data: {
            startPosition: moMeta.initialPosition.clone(),
            endPosition,
            id,
            startRotation: moMeta.initialRotation.clone(),
            endRotation: endRotation,
          },
        })
      );

      return true;
    }

    return false;
  }

  public selectAddWallPoint(mouse: { x: number; y: number }): void {
    this._raycaster.setFromCamera(mouse, this._config.camera);
    const intersects = this._raycaster.intersectObjects(this._wallCornersGroup.children);
    if (intersects.length > 0) {
      this._newWallPoints.push(intersects[0].object as Mesh);
    } else {
      this._newWallPoints = [];
    }
    this._notificationsService.addNotification(
      new Notification({
        title: this._translateService.instant('NOTIFICATIONS.TITLE.INFO'),
        text: this._translateService.instant('NOTIFICATIONS.MESSAGES.WALL_POINTS_COUNT'),
        level: 'success',
        options: { timeout: 2 },
      })
    );
  }

  public getSaveData(): RoomSaveData {
    return {
      scene: this._exportService.getExportedScene().toJSON(),
      camera: this._config.camera.toJSON(),
    };
  }

  public getLightTarget(): Object3D {
    const object = new Object3D();
    object.name = 'LIGHT_TARGET';

    let position: Vector3;
    switch (this._config.roomType) {
      case RoomType.LIVING:
        const sofa = this._findModelSingle(FurnitureType.SOFA)?.object3D;
        position = sofa?.position ?? this.getRoomCenter() ?? new Vector3();
        break;
      case RoomType.SLEEPING:
        const bed = this._findModelSingle(FurnitureType.BED)?.object3D;
        position = bed?.position ?? this.getRoomCenter() ?? new Vector3();
        break;
      case RoomType.DINING:
        const diningTable = this._findModelSingle(FurnitureType.DINING_TABLE)?.object3D;
        position = diningTable?.position ?? this.getRoomCenter() ?? new Vector3();
        break;
      default:
        position = this.getRoomCenter() ?? new Vector3();
        break;
    }

    object.position.set(position.x, position.y, position.z);

    return object;
  }

  public reconstructAuto(): Observable<void> {
    this._store.dispatch(reconstruct());
    return this._store.select(reconstructionResult).pipe(
      filter(data => !!data),
      take(1),
      map(() => {})
    );
  }

  private _moveObjectWithFloorStickiness(object: MovingObject, delta: Vector3): void {
    const objectCurrentPosition = object.object3D.position.clone();
    object.object3D.position.add(delta.clone());
    object.object3D.position.setY(objectCurrentPosition.y);
    // lights movement
    if (object.light) {
      this._lightObjectsService.moveLightWithinObject(object, delta, objectCurrentPosition);
    }
  }

  private _moveObjectWithCeilingStickiness(object: MovingObject, delta: Vector3): void {
    const objectCurrentPosition = object.object3D.position.clone();
    object.object3D.position.add(delta.clone());
    object.object3D.position.setY(objectCurrentPosition.y);

    // lights movement
    if (object.light) {
      this._lightObjectsService.moveLightWithinObject(object, delta, objectCurrentPosition);
    }
  }

  private _moveObjectWithWallsStickiness(object: MovingObject, delta: Vector3, wallPoints: [Vector3, Vector3]): void {
    object.object3D.position.add(delta.clone());
    const rotation = getRotation(object.object3D.position, wallPoints);
    object.object3D.lookAt(rotation.forward);

    // lights movement
    if (object.light) {
      object.light.position.add(delta.clone());
    }
  }

  // drag and drop for multiple objects algorithm:
  // 1. move first selected object, note that only multiple objects could be moved only with the same stickiness type
  // 2. calculate difference between initial and last position for selected object
  // 3. apply same diff vector
  public updateMouse(mouse: { x: number; y: number }): void {
    this._mouse = mouse;

    this._raycaster.setFromCamera(this._mouse, this._config.camera);
    const all = this._planesService.getAll().map(plane => plane.mesh);
    const intersections = this._raycaster.intersectObjects(all);

    if (!intersections.length) return;

    switch (this._selectedMovingObject?.sticknessType) {
      case SticknessType.FLOOR:
        {
          const position = intersections[0].point;
          const deltaV = position.clone().sub(this._selectedMovingObject.object3D.position.clone());
          this._selectedMovingObjects.forEach(moMeta => this._moveObjectWithFloorStickiness(moMeta.mo, deltaV.clone()));
        }
        break;
      case SticknessType.CEILING:
        {
          const position = intersections[0].point;
          const currentY = this._selectedMovingObject.object3D.position.y;
          position.setY(currentY);
          const deltaV = position.clone().sub(this._selectedMovingObject.object3D.position.clone());

          this._selectedMovingObjects.forEach(moMeta => this._moveObjectWithCeilingStickiness(moMeta.mo, deltaV.clone()));
        }
        break;
      case SticknessType.WALLS:
        {
          const walls = this._planesService
            .getAll()
            .filter(plane => plane.type === PlaneType.WALL)
            .map(plane => plane.mesh);
          const intersections = this._raycaster.intersectObjects(walls);
          if (intersections?.length) {
            const position = intersections[0].point;
            const wallId = intersections[0].object.userData['id'];
            const click = intersections[0].point;
            const wallPoints = this._findFloorCorners(wallId, click);
            position.add(
              getForwardDirection(position, wallPoints, this.getRoomCenter())
                .vector.normalize()
                .multiplyScalar(getObjectSize(this._selectedMovingObject.object3D).z / 2)
            );

            const rotation = getRotation(position, wallPoints).forward;

            this._selectedMovingObject.object3D.position.set(position.x, position.y, position.z);
            this._selectedMovingObject.object3D.lookAt(rotation.x, rotation.y, rotation.z);

            // lights movement
            if (this._selectedMovingObject.light) {
              const objectQuaternion = this._selectedMovingObject.object3D.quaternion;
              const endDirection = new Vector3(0, 0, 1).applyQuaternion(objectQuaternion);
              const adjustedPosition = this._selectedMovingObject.object3D.position
                .clone()
                .addScaledVector(endDirection, this._selectedMovingObject.pixelsSize.x / 2);
              this._selectedMovingObject.light.position.copy(adjustedPosition);

              patchSpotLightTargetPosition(this._selectedMovingObject.light);
            }
          }
        }
        break;
    }
  }

  private _changeRelatedHoleCornersPosition(movingPoint: Mesh, points: Mesh[]): void {
    const { xy, back } = getXYQuaternion(points.map(corner => corner.position));

    const sorted = points
      .slice()
      .map(corner => ({ mesh: corner, position: corner.position.clone().applyQuaternion(xy) }))
      .sort((c1, c2) => (c1.position.x > c2.position.x ? 1 : -1));

    const leftTop = sorted[0].position.y > sorted[1].position.y ? sorted[0] : sorted[1];
    const leftBottom = sorted[0].position.y > sorted[1].position.y ? sorted[1] : sorted[0];
    const rightBottom = sorted[2].position.y > sorted[3].position.y ? sorted[3] : sorted[2];
    const rightTop = sorted[2].position.y > sorted[3].position.y ? sorted[2] : sorted[3];

    switch (movingPoint.uuid) {
      case leftBottom.mesh.uuid:
        leftTop.position.setX(leftBottom.position.x);
        rightBottom.position.setY(leftBottom.position.y);
        break;
      case rightBottom.mesh.uuid:
        rightTop.position.setX(rightBottom.position.x);
        leftBottom.position.setY(rightBottom.position.y);
        break;
      case leftTop.mesh.uuid:
        leftBottom.position.setX(leftTop.position.x);
        rightTop.position.setY(leftTop.position.y);
        break;
      case rightTop.mesh.uuid:
        rightBottom.position.setX(rightTop.position.x);
        leftTop.position.setY(rightTop.position.y);
        break;
    }

    leftTop.position.applyQuaternion(back);
    leftBottom.position.applyQuaternion(back);
    rightBottom.position.applyQuaternion(back);
    rightTop.position.applyQuaternion(back);

    leftTop.mesh.position.set(leftTop.position.x, leftTop.position.y, leftTop.position.z);
    leftBottom.mesh.position.set(leftBottom.position.x, leftBottom.position.y, leftBottom.position.z);
    rightBottom.mesh.position.set(rightBottom.position.x, rightBottom.position.y, rightBottom.position.z);
    rightTop.mesh.position.set(rightTop.position.x, rightTop.position.y, rightTop.position.z);
  }

  public handleMoveCornerMouseUp(): void {
    if (this._selectedCorners?.length) {
      const selectedCorner = this._wallCornersGroup.getObjectByProperty('uuid', this._selectedCorners[0].uuid) as WallPoint;
      const selectedWall = this._planesService.getAll().find(plane => plane.id === selectedCorner.userData['planes'][0].planeId);
      const type = selectedCorner.userData['type'] as PointType;
      let hole: Mesh[];
      switch (type) {
        case PointType.WINDOW:
          hole = selectedWall.windows.find(window => !!window.find(corner => corner.uuid === selectedCorner.uuid));
          break;
        case PointType.DOOR:
          hole = selectedWall.doors.find(door => !!door.find(corner => corner.uuid === selectedCorner.uuid));
          break;
      }

      this._changeRelatedHoleCornersPosition(selectedCorner, hole);

      const windows = selectedWall.windows.map(window => this._getTwoFromFourWindowPoints(window));
      const doors = selectedWall.doors.map(door => this._getTwoFromFourWindowPoints(door));

      this._selectedCorners = null;

      this._planesService.redrawWithHoles(selectedWall.id, windows, doors, true);
    }

    if (this._selectedCorner?.userData['planes']?.length > 0 && !!this._selectedCorner.userData['planes'][0]) {
      const selectedWall = this._planesService.getAll().find(plane => plane.id === this._selectedCorner.userData['planes'][0].planeId);
      const type = this._selectedCorner.userData['type'] as PointType;
      let hole: Mesh[];
      switch (type) {
        case PointType.WINDOW:
          hole = selectedWall.windows.find(window => !!window.find(corner => corner.uuid === this._selectedCorner.uuid));
          break;
        case PointType.DOOR:
          hole = selectedWall.doors.find(door => !!door.find(corner => corner.uuid === this._selectedCorner.uuid));
          break;
      }

      this._changeRelatedHoleCornersPosition(this._selectedCorner, hole);

      const windows = selectedWall.windows.map(window => this._getTwoFromFourWindowPoints(window));
      const doors = selectedWall.doors.map(door => this._getTwoFromFourWindowPoints(door));

      this.setSelectedCorner(null);

      this._planesService.redrawWithHoles(selectedWall.id, windows, doors, true);
    }
  }

  public updateCornerMouse(mouse: { x: number; y: number }): void {
    let position: Vector3;

    if (this._selectedCorners && this._start) {
      this._raycaster.setFromCamera(mouse, this._config.camera);
      const intersections = this._raycaster.intersectObject(this._wallCopy);

      if (intersections.length > 0) {
        const finish = intersections[0].point;
        const delta = finish.clone().sub(this._start);

        this._selectedCorners.forEach(cornerData => {
          const corner = this._wallCornersGroup.getObjectByProperty('uuid', cornerData.uuid) as WallPoint;
          const currentPosition = cornerData.position.clone().add(delta);
          corner.position.set(currentPosition.x, currentPosition.y, currentPosition.z);
        });
      }
    }

    if (this._selectedCorner?.userData['planes']?.length > 0 && !!this._selectedCorner.userData['planes'][0]) {
      this._raycaster.setFromCamera(mouse, this._config.camera);

      const wall = this._planesService.getAll().find(plane => plane.id === this._selectedCorner.userData['planes'][0].planeId);
      const intersections = this._raycaster.intersectObject(this._wallCopy);

      if (intersections?.length) {
        position = intersections[0].point;
        const click = intersections[0].point;
        const wallPoints = this._findFloorCorners(wall.id, click);
        position.add(
          getForwardDirection(position, wallPoints, this.getRoomCenter())
            .vector.normalize()
            .multiplyScalar(getObjectSize(this._selectedCorner).z / 2)
        );
      }

      if (position) {
        this._selectedCorner.position.set(position.x, position.y, position.z);
      }
    }
  }

  public addLight(mouse: { x: number; y: number }, isWindow?: boolean, name?: string): Mesh {
    this._raycaster.setFromCamera(mouse, this._config.camera);
    const intersects = this._raycaster.intersectObjects(this._planesGroup.children);
    if (intersects.length > 0) {
      return this._addLightPoint(intersects[0].point, true, undefined, name);
    }
    return null;
  }

  public getIntersectionPlanePoint(
    click: { x: number; y: number },
    first: { x: number; y: number },
    second: { x: number; y: number }
  ): { firstPoint: Vector3; secondPoint: Vector3 } {
    this._raycaster.setFromCamera(click, this._config.camera);
    const walls = this._planesService
      .getAll()
      .filter(plane => plane.type === PlaneType.WALL)
      .map(plane => plane.mesh);
    const intersects = this._raycaster.intersectObjects(walls);

    if (intersects.length > 0) {
      const firstPoint = this._repeatIntersection(intersects[0].object as Mesh, first, 100, 'topleft');
      const secondPoint = this._repeatIntersection(intersects[0].object as Mesh, second, 100, 'rightbottom');

      if (firstPoint && secondPoint) {
        return { firstPoint, secondPoint };
      }
    }

    return null;
  }

  private _repeatIntersection(mesh: Mesh, mouse: { x: number; y: number }, times: number, position: 'topleft' | 'rightbottom'): Vector3 {
    this._raycaster.setFromCamera(mouse, this._config.camera);
    const intersects = this._raycaster.intersectObject(mesh);

    if (intersects.length > 0) {
      return intersects[0].point;
    }

    if (times > 0) {
      mouse.y = position === 'topleft' ? mouse.y - 0.01 : mouse.y + 0.01;
      return this._repeatIntersection(mesh, mouse, --times, position);
    }

    return null;
  }

  public deleteHole(): boolean {
    if (this._selectedDeleteHole) {
      const type = this._selectedDeleteHole.type;
      const plane = this._planesService.getAll().find(plane => plane.id === this._selectedDeleteHole.planeId);

      if (plane && type) {
        switch (type) {
          case PointType.DOOR:
            {
              plane.doors[this._selectedDeleteHole.index].forEach(point => this._wallCornersGroup.remove(point));
              plane.doors.splice(this._selectedDeleteHole.index, 1);
            }
            break;
          case PointType.WINDOW:
            {
              plane.windows[this._selectedDeleteHole.index].forEach(point => this._wallCornersGroup.remove(point));
              plane.windows.splice(this._selectedDeleteHole.index, 1);
            }
            break;
        }

        const windows = plane.windows.map(window => this._getTwoFromFourWindowPoints(window));
        const doors = plane.doors.map(door => this._getTwoFromFourWindowPoints(door));

        this._planesService.redrawWithHoles(plane.id, windows, doors, true);

        this._selectedDeleteHole = null;
        this.setSelectedCorner(null);
        this._selectedCorners = [];

        return true;
      }

      this._selectedDeleteHole = null;
      this.setSelectedCorner(null);
      this._selectedCorners = [];
    }

    return false;
  }

  public addHoles(
    mappedWindows: { click: { x: number; y: number }; firstPoint: Vector3; secondPoint: Vector3 }[],
    mappedDoors: { click: { x: number; y: number }; firstPoint: Vector3; secondPoint: Vector3 }[],
    showCorners: boolean
  ): void {
    const windowsForWalls: { [wallUuid: string]: { first: Vector3; second: Vector3 }[] } = {};

    mappedWindows.forEach(mappedWindow => {
      this._raycaster.setFromCamera(mappedWindow.click, this._config.camera);
      const intersects = this._raycaster.intersectObjects(this._planesGroup.children);
      if (intersects.length > 0) {
        const windows = windowsForWalls[intersects[0].object.uuid];
        if (windows?.length > 0) {
          windows.push({ first: mappedWindow.firstPoint, second: mappedWindow.secondPoint });
        } else {
          windowsForWalls[intersects[0].object.uuid] = [{ first: mappedWindow.firstPoint, second: mappedWindow.secondPoint }];
        }
      }
    });

    const doorsForWalls: { [wallUuid: string]: { first: Vector3; second: Vector3 }[] } = {};

    mappedDoors.forEach(mappedDoor => {
      this._raycaster.setFromCamera(mappedDoor.click, this._config.camera);
      const intersects = this._raycaster.intersectObjects(this._planesGroup.children);
      if (intersects.length > 0) {
        const doors = doorsForWalls[intersects[0].object.uuid];
        if (doors?.length > 0) {
          doors.push({ first: mappedDoor.firstPoint, second: mappedDoor.secondPoint });
        } else {
          doorsForWalls[intersects[0].object.uuid] = [{ first: mappedDoor.firstPoint, second: mappedDoor.secondPoint }];
        }
      }
    });

    Object.keys(windowsForWalls).forEach(uuid =>
      this._planesService.redrawWithHoles(uuid, windowsForWalls[uuid], doorsForWalls[uuid], showCorners)
    );
  }

  public addControls(domElement: HTMLElement): void {
    const movesCallback = (object: Object3D, start: Vector3): void => {
      this._cornerControlsService.detachCornersControls();

      if (this._planesService.getAll().length > 0) {
        const sort = this._saveCornersSort();

        this._moveInWalls(object, start);
        this._moveInFloorAndCeiling(object, start);

        this._restoreCornersSort(sort);
      } else {
        this._manualReconstructionService.moveWallCorner(object as Mesh);
      }

      if (!this._resolutionService.isMobileResolution) {
        this.setSelectedCorner(null);
      }
    };
    const refreshCallback = (): void => {
      if (this._planesService.getAll().length > 0) {
        this.refreshReconstruction();
      }
    };

    if (!this._resolutionService.isMobileResolution) {
      this._cornerControlsService.addCornersControls(movesCallback, refreshCallback);
    }

    this._addLightPointControls(domElement);
  }

  private _saveCornersSort(): string[] {
    return this._wallCornersGroup.children.map(corner => corner.uuid);
  }

  private _restoreCornersSort(sort: string[]): void {
    this._wallCornersGroup.children = sort.map(uuid => this._wallCornersGroup.getObjectByProperty('uuid', uuid) as WallPoint);
  }

  public handleAutopositioning(): boolean {
    const mainWall = this._planesService.getMainWall();

    if (mainWall) {
      const [startPoint, finishPoint] = this._findFloorCorners(mainWall.id, new Vector3());
      const leftCorner = new Object3D();
      leftCorner.position.add(startPoint);
      const rightCorner = new Object3D();
      rightCorner.position.add(finishPoint);

      this._autoPositioningSerice.run(this._config.roomType, leftCorner, rightCorner);

      return true;
    }

    return false;
  }

  private _getHoles(result: DetectObjectsResult, label: string, confidence: number, width: number, height: number): Hole[] {
    return result.clarifai.items
      .filter(object => object.label === label && object.confidence > confidence)
      .sort((first, second) => {
        const firstWidth = (first.x_max - first.x_min) * width;
        const firstHeight = (first.y_max - first.y_min) * height;
        const firstSquare = firstWidth * firstHeight;
        const secondWidth = (second.x_max - second.x_min) * width;
        const secondHeight = (second.y_max - second.y_min) * height;
        const secondSquare = secondWidth * secondHeight;
        return firstSquare < secondSquare ? 1 : -1;
      });
  }

  private _addLightPoints(windows: Hole[], width: number, height: number): void {
    windows.forEach((window, index) => {
      const top = window.y_min * height;
      const left = window.x_min * width;
      const imgWidth = (window.x_max - window.x_min) * width;
      const imgHeight = (window.y_max - window.y_min) * height;
      let x = imgWidth / 2 + left;
      let y = imgHeight / 4 + top;
      const x_new = width / 2 - x;
      const y_new = height / 2 - y;
      x = -x_new;
      y = y_new;
      x = (x / width) * 2;
      y = (y / height) * 2;
      const name = index ? SUP_WINDOW_LIGHT : MAIN_WINDOW_LIGHT;
      this.addLight({ x, y }, true, name);
    });
  }

  public saveWindowsResult(result: DetectObjectsResult): void {
    this._detectObjectResult = result;
  }

  public setSavedWindows(): Observable<DetectObjectsResult> {
    if (this._detectObjectResult) {
      return this.handleWindowsResult(this._detectObjectResult, true);
    }

    return of(null);
  }

  public handleWindowsResult(result: DetectObjectsResult, showCorners: boolean): Observable<DetectObjectsResult> {
    this._lightPointsGroup.children.forEach(point => {
      this._deleteLightPoint(point.uuid, false);
    });

    return this._store.select(activeProjectWidthHeight).pipe(
      take(1),
      filter(data => !!data && !!data.width && !!data.height),
      map(data => {
        const windows = this._getHoles(result, 'Window', 0.5, data.width, data.height);
        const doors = this._getHoles(result, 'Door', 0.5, data.width, data.height);

        this._addLightPoints(windows, data.width, data.height);

        const mappedWindows = this._mapHole(windows, data.width, data.height);
        const mappedDoors = this._mapHole(doors, data.width, data.height);

        this.addHoles(mappedWindows ?? [], mappedDoors ?? [], showCorners);

        return result;
      })
    );
  }

  private _mapHole(
    holes: Hole[],
    width: number,
    height: number
  ): { click: { x: number; y: number }; firstPoint: Vector3; secondPoint: Vector3 }[] {
    return holes
      .map(hole => {
        const top = hole.y_min * height;
        const left = hole.x_min * width;
        const right = hole.x_max * width;
        const bottom = hole.y_max * height;

        const first2DPoint = this._calc2DPosition(left, top, width, height);
        const second2DPoint = this._calc2DPosition(right, bottom, width, height);

        const click = { x: (first2DPoint.x + second2DPoint.x) / 2, y: (first2DPoint.y + second2DPoint.y) / 2 };
        const data = this.getIntersectionPlanePoint(click, first2DPoint, second2DPoint);

        if (data) {
          return { click, firstPoint: data.firstPoint, secondPoint: data.secondPoint };
        }

        return null;
      })
      .filter(hole => !!hole);
  }

  private _calc2DPosition(x: number, y: number, width: number, height: number): { x: number; y: number } {
    const x_new = width / 2 - x;
    const y_new = height / 2 - y;
    x = -x_new;
    y = y_new;
    x = (x / width) * 2;
    y = (y / height) * 2;
    return { x, y };
  }

  public handleInteriorResults(result: InteriorResult): void {
    if (result.reconstruction.walls.length >= 2) {
      this._interiorResultValid$.next(true);
      this._recreateRoom(result);
      this._planesService.deleteDuplicateWallPoints();
      setUpCamera(this._config.camera, result);
      const roomHeight = result.reconstruction.walls[0].height;
      this._roomParamsService.setRoomHeightInput(roomHeight);
      this._roomParamsService.calculateParams();
      this._placeRoomHeightInfo();
    } else {
      this._planesGroup.clear();
      this._wallCornersGroup.clear();
      this._interiorResultValid$.next(false);
      this._notificationsService.addNotification(
        new Notification({
          title: this._translateService.instant('NOTIFICATIONS.TITLE.ERROR'),
          text: this._translateService.instant('NOTIFICATIONS.MESSAGES.NO_RECONSTRUCTION'),
          level: 'error',
          options: { timeout: 4 },
        })
      );
    }
  }

  public completeManualReconstruction(): void {
    this._planesGroup.clear();
    this._shadowGroup.clear();

    const reconstruction = this._manualReconstructionService.completeReconstruction();

    this._planesService.clear();
    this._wallCornersGroup.clear();

    this._placeFloor(reconstruction.floorPoints, false, true);
    this._placeCeiling(reconstruction.ceilingPoints, false, true);
    this._placeWalls(reconstruction.wallPoints, false, true);

    this._roomParamsService.calculateParams();

    this._planesService.deleteDuplicateWallPoints();
  }

  public refreshReconstruction(): void {
    this._planesService.clearAllTempPlanes();
    this._planesGroup.clear();
    this._shadowGroup.clear();

    let selectedCornerPosition: Vector3;

    if (this._selectedCorner) {
      selectedCornerPosition = this._selectedCorner.position.clone();
    }

    const wallsPoints = this._planesService
      .getAll()
      .filter(plane => plane.type === PlaneType.WALL)
      .map(plane => plane.corners.map(corner => corner.position));
    const floorPoints = this._planesService
      .getAll()
      .filter(plane => plane.type === PlaneType.FLOOR || plane.type === PlaneType.FLOOR_CONTINUE)
      .map(plane => plane.corners.map(corner => corner.position))[0];
    const ceilingPoints = this._planesService
      .getAll()
      .filter(plane => plane.type === PlaneType.CEILING || plane.type === PlaneType.CEILING_CONTINUE)
      .map(plane => plane.corners.map(corner => corner.position))[0];
    this._planesService.clear();
    this._wallCornersGroup.clear();

    this._placeWalls(wallsPoints, false, true);
    this._placeFloor(floorPoints, false, true);
    this._placeCeiling(ceilingPoints, false, true);
    this._roomParamsService.calculateParams();

    this._planesService.deleteDuplicateWallPoints();

    if (selectedCornerPosition) {
      const newSelectedCorner = this._wallCornersGroup.children.find(corner =>
        hasSimilarCoordinates(corner.position, selectedCornerPosition)
      );
      this.setSelectedCorner(newSelectedCorner, true);
    }
  }

  public addWallPoint(mouse: { x: number; y: number }): void {
    this._raycaster.setFromCamera(mouse, this._config.camera);
    const intersects = this._raycaster.intersectObjects(this._planesGroup.children);

    if (intersects.length > 0) {
      const wallPoint = this._planesService.getWallPointMesh(intersects[0].point, PointType.CORNER);
      this._wallCornersGroup.add(wallPoint);
      this._store.dispatch(addWallCorner({ data: { id: wallPoint.uuid, position: wallPoint.position } }));
    } else {
      this._planesService.addHelper('FLOOR_HELPER', new MeshBasicMaterial({ color: 0xff0000 }), new Vector3(0, -1.5, -2), PlaneType.FLOOR);
      this._planesService.addHelper(
        'CEILING_HELPER',
        new MeshBasicMaterial({ color: 0x00ff00 }),
        new Vector3(0, 1.5, -2),
        PlaneType.CEILING
      );

      const secondTry = this._raycaster.intersectObjects(this._planesGroup.children);
      if (secondTry.length > 0) {
        const wallPoint = this._planesService.getWallPointMesh(secondTry[0].point, PointType.CORNER);
        this._wallCornersGroup.add(wallPoint);
        this._store.dispatch(addWallCorner({ data: { id: wallPoint.uuid, position: wallPoint.position } }));
      }

      this._planesService.removeHelper('FLOOR_HELPER');
      this._planesService.removeHelper('CEILING_HELPER');
    }
  }

  public deleteWallPoint(mouse: { x: number; y: number }): void {
    this._raycaster.setFromCamera(mouse, this._config.camera);
    const intersects = this._raycaster.intersectObjects(this._wallCornersGroup.children);
    if (intersects.length > 0) {
      const wallPoint = intersects[0].object;
      const pointForPlanes: PointPlane[] = wallPoint.userData['planes'];
      if (pointForPlanes.length > 0) {
        pointForPlanes.forEach(pointForPlane => this._planesService.delete(pointForPlane.planeId, true));
      } else {
        const corner = this._wallCornersGroup.children.find(corner => corner.uuid === wallPoint.uuid);
        if (corner) {
          const index = this._wallCornersGroup.children.indexOf(corner);
          this._wallCornersGroup.children.splice(index, 1);
          this._store.dispatch(deleteWallCorner({ data: { id: corner.uuid, position: corner.position } }));
        }
      }
    }
  }

  private _getTwoFromFourWindowPoints(points: Mesh[]): { first: Vector3; second: Vector3 } {
    const { xy, back } = getXYQuaternion(points.map(corner => corner.position));

    const sorted = points
      .slice()
      .map(corner => corner.position.clone().applyQuaternion(xy))
      .sort((c1, c2) => (c1.x > c2.x ? 1 : -1));

    const leftTop = sorted[0].y > sorted[1].y ? sorted[0] : sorted[1];
    const rightBottom = sorted[2].y > sorted[3].y ? sorted[3] : sorted[2];

    return { first: leftTop.clone().applyQuaternion(back), second: rightBottom.clone().applyQuaternion(back) };
  }

  private _getHolePoints(corners: Mesh[]): { first: Vector3; second: Vector3 } {
    const { first, second } = this._getTwoFromFourWindowPoints(corners);
    return {
      first: getPointBetween(first, second, first.distanceTo(second) / 3),
      second: getPointBetween(first, second, (first.distanceTo(second) * 2) / 3),
    };
  }

  public placeHole(mouse: { x: number; y: number }, type: PointType): boolean {
    this._raycaster.setFromCamera(mouse, this._config.camera);
    const walls = this._planesService
      .getAll()
      .filter(plane => plane.type === PlaneType.WALL)
      .map(plane => plane.mesh);
    const intersects = this._raycaster.intersectObjects(walls);

    if (intersects.length > 0) {
      const planeId = intersects[0].object.userData['id'];
      const plane = this._planesService.getAll().find(plane => plane.id === planeId);

      const oldWindows =
        plane.windows.length > 0
          ? [
              ...plane.windows.map(window => ({
                first: window.find(point => point.userData['place'] === 'leftTop').position,
                second: window.find(point => point.userData['place'] === 'rightBottom').position,
              })),
            ]
          : [];

      const oldDoors =
        plane.doors.length > 0
          ? [
              ...plane.doors.map(door => ({
                first: door.find(point => point.userData['place'] === 'leftTop').position,
                second: door.find(point => point.userData['place'] === 'rightBottom').position,
              })),
            ]
          : [];

      const { first, second } = this._getHolePoints(plane.corners);

      let doors: { first: Vector3; second: Vector3 }[];
      let windows: { first: Vector3; second: Vector3 }[];

      switch (type) {
        case PointType.WINDOW:
          if (plane.windows.length < 3) {
            windows = [...oldWindows, { first, second }];
            doors = oldDoors;
          }
          break;
        case PointType.DOOR:
          if (plane.windows.length < 3) {
            windows = oldWindows;
            doors = [...oldDoors, { first, second }];
          }
          break;
      }

      this._planesService.redrawWithHoles(planeId, windows, doors, true);

      this._selectedDeleteHole = {
        planeId: planeId,
        index: type === PointType.DOOR ? doors.length - 1 : windows.length - 1,
        type,
      };

      return true;
    }

    return false;
  }

  public createCeiling(mouse: { x: number; y: number }): void {
    if (!this._selectedFloorPoint) {
      this._raycaster.setFromCamera(mouse, this._config.camera);
      const intersects = this._raycaster.intersectObjects(this._wallCornersGroup.children);
      if (intersects.length > 0) {
        this._selectedFloorPoint = intersects[0].object as Mesh;
      }
    } else {
      const mesh = new Mesh(new PlaneGeometry(100, 100), new MeshBasicMaterial());
      mesh.position.set(this._selectedFloorPoint.position.x, this._selectedFloorPoint.position.y, this._selectedFloorPoint.position.z);
      this._objectsGroup.add(mesh);
      this._objectsGroup.updateMatrixWorld(true);

      this._raycaster.setFromCamera(mouse, this._config.camera);
      const intersects = this._raycaster.intersectObject(mesh);
      if (intersects.length > 0) {
        const ceilingPoint = intersects[0].point;

        const floorPoints = this._wallCornersGroup.children
          .filter(corner => corner.userData['planes'].some(pointPlane => pointPlane.type === PlaneType.FLOOR))
          .map(corner => corner.position);

        const ceilingPoints = floorPoints.map(point => new Vector3(point.x, ceilingPoint.y, point.z));
        this._placeCeiling(ceilingPoints, true, false);
        this._planesService.checkRoomParams();
      }

      this._objectsGroup.remove(mesh);
      this._selectedFloorPoint = null;
    }
  }

  private _addLightPoint(point: Vector3, touchStore: boolean, uuid?: string, name?: string): Mesh {
    const lightSource: Object3D = getPointLightSource(point, name);
    const lightPoint: Mesh = new Mesh(new SphereGeometry(0.2, 16, 12), new MeshBasicMaterial({ color: 0x3352ff }));
    lightPoint.position.copy(point);
    lightPoint.userData['lightSource'] = lightSource.uuid;
    lightPoint.visible = false;
    if (uuid) {
      lightPoint.uuid = uuid;
    }
    this._lightPointsGroup.add(lightPoint);
    this._config.scene.add(lightSource);
    if (touchStore) {
      this._store.dispatch(addLightPoint({ data: { position: point, uuid: lightPoint.uuid } }));
    }
    return lightPoint;
  }

  private _recreateRoom(result: InteriorResult): void {
    this._planesGroup.clear();
    this._wallCornersGroup.clear();

    const ceilingPoints = this._findPoints(result, 'ceiling');
    const floorPoints = this._findPoints(result, 'floor');

    const floorPointsExpandedTowardsCamera = this._getExpandedPlane(floorPoints);
    const ceilingPointsExpandedTowardsCamera = this._getExpandedPlane(ceilingPoints);

    const floorPointsContinuation = this._expandPlaneToTheSides(floorPointsExpandedTowardsCamera.map(vector => vector.clone()));
    const ceilingPointsContinuation = this._expandPlaneToTheSides(ceilingPointsExpandedTowardsCamera.map(vector => vector.clone()));

    const wallsPoints = this._getRightWalls(result);
    this._planesService.clear();
    this._wallCornersGroup.clear();

    this._placeWalls(wallsPoints, false, true);
    this._placeFloor(floorPointsExpandedTowardsCamera, false, true);
    this._placeCeiling(ceilingPointsExpandedTowardsCamera, false, true);

    // just for movement outside room
    this._addPlane(floorPointsContinuation, false, false, PlaneType.FLOOR_CONTINUE);
    this._addPlane(ceilingPointsContinuation, false, false, PlaneType.CEILING_CONTINUE);
  }

  private _placeRoomHeightInfo(): void {
    const text = this._translateService.instant('USER_FLOW.LABELS.ROOM_HEIGHT') + ': ';

    const heightValue = this._roomParamsService.get().heightMeters.toFixed(1);

    const rootDiv = this._document.createElement('div');

    const containerDiv = this._document.createElement('div');
    containerDiv.style.display = 'flex';
    containerDiv.style.alignItems = 'center';
    containerDiv.style.color = 'rgb(0,0,0)';
    containerDiv.style.backgroundColor = 'white';
    containerDiv.style.padding = '10px';
    containerDiv.style.borderRadius = '10px';
    containerDiv.style.opacity = `${WINDOW.opacity}`;
    containerDiv.style.gap = '10px';

    const textDiv = this._document.createElement('div');
    textDiv.textContent = text;
    textDiv.style.fontFamily = 'Work Sans';
    textDiv.style.fontSize = '17px';

    const input = this._document.createElement('input');
    input.style.display = 'none';
    input.type = 'number';

    input.value = new Intl.NumberFormat('en').format(Number(heightValue));
    input.max = '10';
    input.min = '0';
    input.style.width = '48px';
    input.style.height = '20px';
    input.style.padding = '0';
    input.style.outline = 'none';
    input.style.border = 'none';
    input.style.fontFamily = 'Work Sans';
    input.style.fontSize = '17px';
    input.autofocus = true;
    input.step = '0.1';
    input.lang = 'en-US';

    const valueText = this._document.createElement('div');
    valueText.style.fontFamily = 'Work Sans';
    valueText.style.fontSize = '17px';
    valueText.style.width = '48px';
    valueText.innerText = heightValue + 'm';
    valueText.addEventListener('click', () => {
      input.style.display = 'block';
      input.focus();
      valueText.style.display = 'none';
    });

    const icon = this._document.createElement('img');
    icon.src = 'assets/icons/edit.svg';
    icon.style.width = '16px';
    icon.style.height = '16px';

    const closeInput = (): void => {
      const value = Number(input.value);
      valueText.innerText = value + 'm';
      this._roomParamsService.setRoomHeightInput(value);
      input.style.display = 'none';
      valueText.style.display = 'block';
    };

    input.addEventListener('keypress', event => {
      if (event.key === 'Enter') {
        event.preventDefault();
        closeInput();
      }
    });

    input.addEventListener('focusout', () => {
      closeInput();
    });

    icon.addEventListener('click', () => {
      const inputIsHide = input.style.display === 'none';

      if (!inputIsHide) {
        const value = Number(input.value);
        valueText.innerText = value + 'm';
        this._roomParamsService.setRoomHeightInput(value);
      }

      input.style.display = inputIsHide ? 'block' : 'none';
      valueText.style.display = inputIsHide ? 'none' : 'block';

      if (inputIsHide) {
        input.focus();
      }
    });

    containerDiv.append(textDiv, valueText, input, icon);

    rootDiv.append(containerDiv);

    const label = new CSS2DObject(rootDiv);
    label.position.copy(new Vector3(0, 0, -2));

    this._planesGroup.add(label);
  }

  private _placeCeiling(ceilingPoints: Vector3[], touchStore: boolean, shouldOutline: boolean): void {
    this._planesService.add(ceilingPoints, PlaneType.CEILING, touchStore, shouldOutline);
  }

  private _addPlane(points: Vector3[], touchStore: boolean, shouldOutline: boolean, planeType: PlaneType): void {
    this._planesService.add(points, planeType, touchStore, shouldOutline);
  }

  private _placeFloor(floorPoints: Vector3[], touchStore: boolean, shouldOutline: boolean): void {
    this._planesService.add(floorPoints, PlaneType.FLOOR, touchStore, shouldOutline);
  }

  private _findPoints(result: InteriorResult, planeType: 'floor' | 'ceiling'): Vector3[] {
    const planePoints = result.reconstruction.walls
      .map(wall => wall.points)
      .flat()
      .filter(point => (planeType === 'floor' ? point.y < 0 : point.y > 0));

    return planePoints
      .filter((p1, i1) => planePoints.findIndex(p2 => p2.x === p1.x && p2.z === p1.z) === i1)
      .map(point => new Vector3(point.x, point.y, point.z));
  }

  private _getExpandedPlane(points: Vector3[]): Vector3[] {
    const hullVectors: Vector3[] = getHorizontalHullPoints(points);

    const hullLines: Line3[] = [];

    for (let i = 0; i < hullVectors.length - 1; i++) {
      hullLines.push(new Line3(hullVectors[i], hullVectors[i + 1]));
    }

    const cameraPosition = new Vector3();
    const nearestLine = hullLines.slice().sort((line1, line2) => {
      const firstDistance = line1.closestPointToPoint(cameraPosition, true, new Vector3()).distanceTo(cameraPosition);
      const secondDistance = line2.closestPointToPoint(cameraPosition, true, new Vector3()).distanceTo(cameraPosition);
      return firstDistance - secondDistance;
    })[0];

    if (nearestLine) {
      const startPoints = [nearestLine.start, nearestLine.end];
      const endPoints = startPoints.map(p => new Vector3(p.x, p.y, 0));
      const allPoints = [...points, ...endPoints];
      const horizontalHullPoints = getHorizontalHullPoints(allPoints);
      return horizontalHullPoints.filter((p1, i1) => horizontalHullPoints.findIndex(p2 => p2.equals(p1)) === i1);
    }

    return [];
  }

  private _expandPlaneToTheSides(points: Vector3[], valueToAdd = 2): Vector3[] {
    const result: Vector3[] = [];

    const sortedByCameraDistance = points.sort((a, b) => a.z - b.z);

    // expand horizontally
    sortedByCameraDistance
      .filter(p => p.z === 0)
      .forEach(p => {
        p.x += Math.sign(p.x) * valueToAdd;
        result.push(p);
      });

    const countOfPointsBeforeCamera = result.length;

    // expand backwards and horizontally
    for (let idx = 0; idx < countOfPointsBeforeCamera; idx++) {
      const furthestFromCameraPoint = sortedByCameraDistance[idx];
      furthestFromCameraPoint.x += Math.sign(furthestFromCameraPoint.x) * valueToAdd;
      furthestFromCameraPoint.z -= valueToAdd;

      result.push(furthestFromCameraPoint);
    }

    return result;
  }

  private _getRightWalls(result: InteriorResult): Vector3[][] {
    const rightWalls: InteriorPlane[] = [];
    const oldWalls: InteriorPlane[] = result.reconstruction.walls;

    oldWalls.forEach(wall => {
      const points: Vector3[] = wall.points.map(point => new Vector3(point.x, point.y, point.z));

      if (points.length <= 5 && this._wallInFrontOfCamera(points)) {
        rightWalls.push(wall);
      }
    });

    if (rightWalls.length === 3) {
      const middleWall = this._getMiddleWall(rightWalls);

      if (middleWall) {
        const index = rightWalls.indexOf(middleWall);
        rightWalls.splice(index, 1);

        const newMiddleWallPoints: Point[] = [];
        rightWalls.forEach(rightWall => {
          newMiddleWallPoints.push(...this._getMiddleWallPoints(rightWall));
        });

        rightWalls.push({
          points: newMiddleWallPoints,
        });
      }
    }

    if (rightWalls.length === 0) {
      this._notificationsService.addNotification(
        new Notification({
          title: this._translateService.instant('NOTIFICATIONS.TITLE.ERROR'),
          text: this._translateService.instant('NOTIFICATIONS.MESSAGES.WRONG_RECONSTRUCTION'),
          level: 'error',
          options: { timeout: 2 },
        })
      );
    }

    result.reconstruction.walls = rightWalls;

    return rightWalls.map(interiorPlane => interiorPlane.points.map(point => new Vector3(point.x, point.y, point.z)));
  }

  private _placeWalls(walls: Vector3[][], touchStore: boolean, shouldOutline: boolean): void {
    walls.forEach(points => {
      this._planesService.add(points, PlaneType.WALL, touchStore, shouldOutline);
    });
  }

  private _getMiddleWall(walls: InteriorPlane[]): InteriorPlane {
    let middleWall!: InteriorPlane;

    const wallsCopy: InteriorPlane[] = Object.assign([], walls);
    const allFloorPoints = wallsCopy
      .map(wall => [...this._getFloorPoints(wall)])
      .reduce((prev, curr) => {
        prev.push(...curr);
        return prev;
      }, []);

    walls.forEach(wall => {
      const [p1, p2] = this._getFloorPoints(wall);

      if (this._count(allFloorPoints, p1) === 2 && this._count(allFloorPoints, p2) === 2) {
        middleWall = wall;
        return;
      }
    });

    return middleWall;
  }

  private _count(points: Point[], initPoint: Point): number {
    const initPointV = new Vector3(initPoint.x, initPoint.y, initPoint.z);
    let count = 0;

    points.forEach(point => {
      const pointV = new Vector3(point.x, point.y, point.z);

      if (hasSimilarCoordinates(pointV, initPointV)) {
        count++;
      }
    });

    return count;
  }

  private _getMiddleWallPoints(plane: InteriorPlane): [Point, Point] {
    const sortedPoints = plane.points.sort((p1, p2) => (p1.z > p2.z ? 1 : -1));
    return [sortedPoints[0], sortedPoints[1]];
  }

  private _getFloorPoints(plane: InteriorPlane): [Point, Point] {
    const sortedPoints = plane.points.sort((p1, p2) => (p1.y < p2.y ? 1 : -1));
    return [sortedPoints[0], sortedPoints[1]];
  }

  private _wallInFrontOfCamera(points: Vector3[]): boolean {
    return points.every(point => point.z < 0);
  }

  private _findModelSingle(type: FurnitureType): MovingObject {
    return this._movingObjectsService.movingObjects.find(furniture => furniture.type === type);
  }

  public getRoomCenter(): Vector3 {
    this._wallCornersGroup.updateMatrixWorld(true);
    const floorPoints = this._wallCornersGroup.children
      .filter(corner => corner.userData['planes'].find(planePoint => PlaneType.FLOOR === planePoint.type))
      .map(corner => corner.position);

    if (floorPoints.length === 3) {
      const p01 = getPointBetween(floorPoints[0], floorPoints[1], floorPoints[0].distanceTo(floorPoints[1]) / 2);
      return getPointBetween(p01, floorPoints[2], p01.distanceTo(floorPoints[2]) / 2);
    } else if (floorPoints.length >= 4) {
      const p01 = getPointBetween(floorPoints[0], floorPoints[1], floorPoints[0].distanceTo(floorPoints[1]) / 2);
      const p23 = getPointBetween(floorPoints[2], floorPoints[3], floorPoints[2].distanceTo(floorPoints[3]) / 2);
      return getPointBetween(p01, p23, p01.distanceTo(p23) / 2);
    }

    return null;
  }

  private _findFloorCorners(wallId: string, click: Vector3): [Vector3, Vector3] {
    this._wallCornersGroup.updateMatrixWorld(true);
    const plane = this._planesService.find(wallId);

    const mainWallPoints = this._wallCornersGroup.children
      .filter(corner => corner.userData['planes'].find(planePoint => plane.id === planePoint.planeId))
      .sort((p1, p2) => (p2.position.y < p1.position.y ? 1 : -1));

    const firstCorner = mainWallPoints[0] as Mesh;
    const secondCorner = mainWallPoints[1] as Mesh;

    return click.distanceTo(firstCorner.position) < click.distanceTo(secondCorner.position)
      ? [secondCorner.position, firstCorner.position]
      : [firstCorner.position, secondCorner.position];
  }

  private _deleteLightPoint(uuid: string, touchStore: boolean): void {
    const lightPoint = this._lightPointsGroup.getObjectByProperty('uuid', uuid);
    if (lightPoint) {
      this._lightPointsGroup.remove(lightPoint);
      const lightSource = this._config.scene.getObjectByProperty('uuid', lightPoint.userData['lightSource']);
      this._config.scene.remove(lightSource);
      if (touchStore) {
        this._store.dispatch(deleteLightPoint({ data: { position: lightPoint.position, uuid: lightPoint.uuid } }));
      }
      this._store.dispatch(setUnsavedChanges({ state: true }));
    }
  }

  private _getMainObject(child: Object3D): Object3D {
    if (!child) {
      return null;
    }
    return child.parent?.userData['isMainObject'] ? child.parent : this._getMainObject(child.parent);
  }

  private _setSelectedPlane(intersection: Intersection<Mesh>): void {
    const object = intersection.object;
    const planeId = object.userData['id'];

    if (planeId) {
      this._selectedPlaneIntersection = intersection;
    }
  }

  private _addLightPointStore(current: Current): void {
    if (current.command === 'undo') {
      const uuid = current.action.data.uuid;
      this._deleteLightPoint(uuid, false);
    } else {
      const position = current.action.data.position;
      const uuid = current.action.data.uuid;
      this._addLightPoint(position, false, uuid);
    }
  }

  private _deleteLightPointStore(current): void {
    if (current.command === 'redo') {
      const uuid = current.action.data.uuid;
      this._deleteLightPoint(uuid, false);
    } else {
      const position = current.action.data.position;
      const uuid = current.action.data.uuid;
      this._addLightPoint(position, false, uuid);
    }
  }

  private _moveLightPointStore(current: Current): void {
    if (current.command === 'undo') {
      const uuid = current.action.data.uuid;
      const start = current.action.data.start;
      this._moveLightPointTo(uuid, start);
    } else {
      const uuid = current.action.data.uuid;
      const end = current.action.data.end;
      this._moveLightPointTo(uuid, end);
    }
  }

  private _moveModelMouse(current: Current): void {
    if (current.command === 'undo') {
      const id = current.action.data.id;
      const startPosition = current.action.data.startPosition;
      const startRotation = current.action.data.startRotation;
      this._moveModelTo(id, startPosition, startRotation);
    } else {
      const id = current.action.data.id;
      const endPosition = current.action.data.endPosition;
      const endRotation = current.action.data.endRotation;
      this._moveModelTo(id, endPosition, endRotation);
    }
  }

  private _moveLightPointTo(uuid: string, point: Vector3): void {
    const object = this._lightPointsGroup.getObjectByProperty('uuid', uuid);
    object.position.set(point.x, point.y, point.z);
  }

  private _moveModelTo(uuid: string, position: Vector3, rotation: Vector3): void {
    const object = this._objectsGroup.getObjectByProperty('uuid', uuid);
    object.position.set(position.x, position.y, position.z);
    object.rotation.set(rotation.x, rotation.y, rotation.z);
    const mo = this._movingObjectsService.movingObjects.find(obj => obj.id === uuid);
    mo && this._lightObjectsService.adjustLightPosition(mo);
  }

  private _moveInFloorAndCeiling(object: Object3D, start: Vector3): void {
    const relatedCorners = this._wallCornersGroup.children
      .slice()
      .filter(
        corner =>
          corner !== object &&
          corner.userData['planes'].some(
            pointPlane =>
              pointPlane.type === PlaneType.CEILING ||
              pointPlane.type === PlaneType.CEILING_CONTINUE ||
              pointPlane.type === PlaneType.FLOOR ||
              pointPlane.type === PlaneType.FLOOR_CONTINUE
          )
      )
      .filter(corner => Math.abs(corner.position.y - start.y) < 0.1);

    relatedCorners.forEach(relatedCorner => {
      relatedCorner.position.set(relatedCorner.position.x, object.position.y, relatedCorner.position.z);
    });
  }

  private _moveInWalls(object: Object3D, start: Vector3): void {
    const wallsIds: string[] = object.userData['planes']
      ?.filter(planePoint => planePoint.type === PlaneType.WALL)
      .map(planePoint => planePoint.planeId);
    const wallsCorners = this._wallCornersGroup.children
      .slice()
      .filter(
        corner =>
          corner !== object &&
          corner.userData['planes'].some(pointPlane => pointPlane.type === PlaneType.WALL && wallsIds?.includes(pointPlane.planeId))
      )
      .sort((c1, c2) => {
        const d1 = new Vector2(c1.position.x, c1.position.z).distanceTo(new Vector2(start.x, start.z));
        const d2 = new Vector2(c2.position.x, c2.position.z).distanceTo(new Vector2(start.x, start.z));
        return d1 > d2 ? 1 : -1;
      });

    if (wallsCorners.length > 0) {
      const relatedCorner = wallsCorners[0];
      relatedCorner.position.set(object.position.x, relatedCorner.position.y, object.position.z);
    }
  }

  private _addLightPointControls(domElement: HTMLElement): void {
    this._lightPointControls = new DragControls(this._lightPointsGroup.children, this._config.camera, domElement);
    let start: Vector3;
    this._lightPointControls.addEventListener('dragstart', (event: any) => {
      if (event.object) {
        start = new Vector3().copy(event.object.position);
        this._activeLightPoint = event.object;
        this._lightPointsGroup.children.forEach((el: any) => {
          el.material.color.setHex(0x3352ff);
        });
        event.object.material.color.setHex(0x9333ff);
      }
    });

    this._lightPointControls.addEventListener('dragend', (event: any) => {
      if (event.object) {
        this._raycaster.setFromCamera(this._mouse, this._config.camera);
        const intersects = this._raycaster.intersectObjects(this._planesGroup.children);
        if (intersects.length > 0) {
          const point: Vector3 = intersects[0].point;
          event.object.position.set(point.x, point.y, point.z);
          const lightSource = this._config.scene.getObjectByProperty('uuid', event.object.userData.lightSource);
          if (lightSource) {
            lightSource.position.set(point.x, point.y, point.z);
          }
          this._store.dispatch(moveLightPoint({ data: { start, end: point, uuid: event.object.uuid } }));
        }
      }
    });
  }

  public clearScene(): void {
    this._planesService.clearAllTempPlanes();

    this._planesGroup.clear();
    this._shadowGroup.clear();
    this._wallCornersGroup.clear();

    this._planesService.clear();
    this._manualReconstructionService.clear();
  }

  public hideReconstructionColor(): void {
    this._lightPointsGroup.visible = false;
    this._planesGroup.visible = false;
    this._wallCornersGroup.visible = false;
  }

  public viewFurniture(): void {
    this._importService.viewFurniture(this._selectedMovingObject);
  }

  public selectWall(mouse): RoomPlane {
    this._raycaster.setFromCamera(mouse, this._config.camera);
    const walls = this._planesService
      .getAll()
      .filter(plane => plane.type === PlaneType.WALL)
      .map(plane => plane.mesh);
    const intersectsObjects = this._raycaster.intersectObjects(walls);

    if (intersectsObjects.length > 0) {
      return this._planesService.find(intersectsObjects[0].object.userData['id']);
    }

    return null;
  }

  public moveWallPoint(meters: number, deltaMovement: Vector3): void {
    if (this._selectedCorner) {
      this._cornerControlsService.detachCornersControls();

      const delta = this._roomParamsService.get3dFromMeter(meters);

      const start = this._selectedCorner.position.clone();

      this._selectedCorner.position.add(deltaMovement.multiplyScalar(delta));

      if (this._planesService.getAll().length > 0) {
        const sort = this._saveCornersSort();

        this._moveInWalls(this._selectedCorner, start);
        this._moveInFloorAndCeiling(this._selectedCorner, start);

        this._restoreCornersSort(sort);

        this.refreshReconstruction();
      } else {
        this._manualReconstructionService.moveWallCorner(this._selectedCorner);
      }
    }
  }

  public setSelectedCorner(corner: Mesh, enableHighlightImmediate = false): void {
    if (this._selectedCorner) {
      this._selectedCorner.disableColorHighlight();
    }

    this._selectedCorner = corner as WallPoint;
    enableHighlightImmediate && this._selectedCorner.enableColorHighlight();
  }
}
