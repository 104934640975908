<div class="page-container">
    <div>
        <div *ngIf="resolutionService.isMobileResolution">
            <img 
                class="back-arrow" 
                src="./../../../assets/icons/arrow-left-short.svg" 
                alt="Go back" 
                (click)="back()" 
                aria-hidden="true"
            >
        </div>
        <div class="page-header">
            <p class="subheader"
                [ngClass]="{'r-24': !resolutionService.isMobileResolution, 'r-16': resolutionService.isMobileResolution}">{{
                "USER_FLOW.LABELS.BUDGET_SUBHEADER" | translate }}</p>
            <p class="header" [ngClass]="{'m-40': !resolutionService.isMobileResolution, 'm-25': resolutionService.isMobileResolution}">{{ "USER_FLOW.LABELS.BUDGET_HEADER" | translate }}</p>
        </div>
    </div>

    <div class="container">
        <div class="navigation-buttons">
            <img 
                class="back-arrow" 
                src="./../../../assets/icons/arrow-left-short.svg" 
                alt="Go back" 
                (click)="back()"
                aria-hidden="true"
            >
            <button class="button" mat-raised-button color="primary" [disabled]="budget.invalid"
                (click)="next()">{{'BUTTONS.NEXT' | translate}}</button>
        </div>
        <div class="info-container">
            <div class="page-info-wrapper">
                <div class="page-info">
                    <div class="slider-labels-mobile"  *ngIf="resolutionService.isMobileResolution">
                        <label class="r-16">{{ "USER_FLOW.LABELS.MIN_BUDGET" | translate }}</label>
                        <label class="r-16">{{ "USER_FLOW.LABELS.MAX_BUDGET" | translate }}</label>
                    </div>
                    <mat-slider class="slider" [max]="30000" [min]="2500" [step]="100">
                        <input matSliderThumb [formControl]="budget">
                    </mat-slider>
                    <div class="slider-labels">
                        <label *ngIf="!resolutionService.isMobileResolution"  class="r-16">{{ "USER_FLOW.LABELS.MIN_BUDGET" | translate }}</label>
                        <label *ngIf="budget?.value" class="r-16">{{ "USER_FLOW.LABELS.CHF" | translate }} {{budget?.value | budget}}</label>
                        <label *ngIf="!resolutionService.isMobileResolution"  class="r-16">{{ "USER_FLOW.LABELS.MAX_BUDGET" | translate }}</label>
                    </div>
                </div>
            </div>
            <div class="image-container">
                <img 
                    src="../../../assets/project-3d-images/Stylefinder_Budget.jpg" 
                    class="image"
                    alt="image"
                >
            </div>
        </div>
        <div class="button-wrapper" *ngIf="resolutionService.isMobileResolution">
            <button class="button" mat-raised-button color="primary" [disabled]="budget.invalid"
                (click)="next()">{{'BUTTONS.NEXT' | translate}}</button>
        </div>
    </div>
</div>