import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-has-unsaved-changes-dialog',
  templateUrl: './has-unsaved-changes-dialog.component.html',
  styleUrls: ['./has-unsaved-changes-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HasUnsavedChangesDialogComponent {
  constructor(
    private _dialogRef: MatDialogRef<HasUnsavedChangesDialogComponent>,
    private _store: Store,
    private _router: Router
  ) {}

  public close(result: boolean | null): void {
    this._dialogRef.close(result);
  }
}
