import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';

import { NcsColor } from './ncs-color.model';
import { changeWallColor } from '../../../store/actions/render.actions';
import { RoomPlane } from '../../services/model/dto/plane.model';
import { PlanesService } from '../../services/planes.service';

@Component({
  selector: 'app-color-select-container',
  templateUrl: './color-select-container.component.html',
  styleUrls: ['./color-select-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColorSelectContainerComponent {
  @Input()
  public selectedWall: RoomPlane;

  public currentColorOpen = true;
  public allColorsOpen = false;

  constructor(
    private _planesService: PlanesService,
    private _store: Store
  ) {}

  public nextButtonClicked(): void {
    this.allColorsOpen = true;
    this.currentColorOpen = false;
  }

  public colorChanged(colorData: { color: NcsColor; colorFixed: boolean }): void {
    if (colorData.color.Code && this.selectedWall) {
      this._store.dispatch(
        changeWallColor({ data: { id: this.selectedWall.id, from: this.selectedWall?.colorData?.color, to: colorData.color } })
      );
      this._planesService.colorWalls(this.selectedWall, colorData);
    } else {
      this._store.dispatch(
        changeWallColor({ data: { id: this.selectedWall.id, from: this.selectedWall?.colorData?.color, to: colorData.color } })
      );
      this._planesService.clearWallsColor(this.selectedWall);
    }
  }

  public openCurrentColor(): void {
    this.allColorsOpen = false;
    this.currentColorOpen = true;
  }
}
