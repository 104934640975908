import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Notification } from 'src/app/notifications/model/notification';
import { NotificationsService } from 'src/app/notifications/services/notifications.service';
import { ResolutionService } from 'src/app/user-flow/services/resolution.service';

import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-confirm-register',
  templateUrl: './confirm-register.component.html',
})
export class ConfirmRegisterComponent implements OnInit {
  constructor(
    public resolutionService: ResolutionService,
    private _authService: AuthService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _notificationsService: NotificationsService,
    private _translateService: TranslateService
  ) {}

  public ngOnInit(): void {
    const token: string = this._activatedRoute.snapshot.url[this._activatedRoute.snapshot.url.length - 1].path;
    this._authService.verify(token).subscribe({
      error: (error: HttpErrorResponse) =>
        this._notificationsService.addNotification(
          new Notification({
            title: this._translateService.instant('NOTIFICATIONS.TITLE.ERROR'),
            text: error.error.detail,
            level: 'error',
            options: { timeout: 2 },
          })
        ),
    });
  }

  public toLogIn(): void {
    this._router.navigate(['login']);
  }
}
