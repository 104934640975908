<div class="dialog">
    <button class="close-button" mat-icon-button aria-label="close dialog" (click)="tryClose()">
        <mat-icon>close</mat-icon>
      </button>
      <app-idea-furniture *ngIf="pageType === leftPanelStates.FURNITURE_LIST" (closePanel)="close()"
          (openDetailsEvent)="openDetailsEvent()">
      </app-idea-furniture>
      <app-shared-catalog-details #detailsPage *ngIf="pageType === leftPanelStates.DETAILS_PANEL"
          (openFurnitureList)="openFurnitureList()" (closeDialog)="close()">
      </app-shared-catalog-details>
</div>
