import { createAction, props } from '@ngrx/store';

import { CustomAction } from './undo-redo.state';

export const UNDO = '[UndoRedo] Undo action';
export const REDO = '[UndoRedo] Redo action';
export const ADD = '[UndoRedo] Add action';
export const CLEAR_UNDO_REDO_STATE = '[UndoRedo] Clear';

export const undoAction = createAction(UNDO);

export const redoAction = createAction(REDO);

export const add = createAction(ADD, props<{ payload: CustomAction }>());

export const clearUndoRedoState = createAction(CLEAR_UNDO_REDO_STATE);
