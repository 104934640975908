<div class="idea-page">
  <div class="actions">
    <img
      class="back-arrow"
      src="./../../../assets/icons/arrow-left-short.svg"
      alt="Go back"
      (click)="back()"
      aria-hidden="true"
    />
    <button
      [matMenuTriggerFor]="afterMenu"
      class="button"
      color="primary"
      mat-raised-button
    >
      {{ "BUTTONS.DOWNLOAD" | translate }}
    </button>
    <mat-menu
      #afterMenu="matMenu"
      xPosition="after"
      class="download-menu"
      [hasBackdrop]="false"
    >
      <form 
        (click)="$event.stopPropagation()" 
        aria-hidden="true"
        >
        <mat-form-field class="select form-field">
          <p class="title no-margins" r-18>
            {{ "USER_FLOW.EXPORT_PAGE.RESOLUTION" | translate }}
          </p>
          <mat-select
            class="selector"
            [formControl]="resolutionControl"
            (click)="$event.stopPropagation(); ($event.preventDefault)"
          >
            <mat-option
              *ngFor="let resolution of payedResolution"
              [value]="resolution"
              [disabled]="isHighQualityDownloadDisabled$ | async"
            >
              {{ resolution.name }}
            </mat-option>
            <mat-option
              *ngFor="let resolution of freeResolutions"
              [value]="resolution"
            >
              {{ resolution.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form>
      <button mat-raised-button class="button download-button" color="primary" (click)="downloadImage()">
        {{ "BUTTONS.DOWNLOAD" | translate }}
      </button>
    </mat-menu>
  </div>
  <div class="page-content" #pageContent>
    <div class="left-panel-container">
      <app-idea-furniture-container
        [pageType]="leftPanelPage"
        [ngClass]="{
          'show-left-panel': leftPanelOpen,
          'hide-left-panel': !leftPanelOpen
        }"
        #leftPanel
        (openLeftPanel)="openLeftPanel($event)"
        *ngIf="!resolutionService.isMobileResolution; else canvas"
        class="catalog"
      ></app-idea-furniture-container>
    </div>

    <div class="image-container" #imageContainer>
      <canvas id="editor"></canvas>
    </div>
  </div>
</div>

<ng-template #canvas>
  <div class="image-container">
    <canvas id="editor"></canvas>
  </div>
</ng-template>
