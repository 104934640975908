<div class="page">
    <div class="page-header" [ngClass]="{'page-header-admin': isAdminView}">
        <div [ngClass]="{'search-field': !isAdminView}">
            <mat-form-field *ngIf="!resolutionService.isMobileResolution"   appearance="outline">
                <mat-label class="r-16">{{ "USER_FLOW.CATALOG.SEARCH" | translate}}</mat-label>
                <mat-icon matPrefix>search</mat-icon>
                <input matInput [formControl]="searchInput">
            </mat-form-field>
        </div>
    </div>
    <ng-container>
        <div class="table">
            <ul class="items">
                <li class="item" *ngFor="let project of projects">
                    <div>
                        <div class="name-mobile truncate m-18" [title]="project.name">{{project.name}}</div>
                        <div class="image-container" *ngIf="project.src; else placeholder">
                            <img 
                                class="image" 
                                [src]="project.src | safe: 'url'" 
                                alt="image"
                            />
                            <div 
                                *ngIf="!resolutionService.isMobileResolution" 
                                class="open-image" 
                                (click)="openProject(project)"
                                aria-hidden="true"
                            >   
                                {{ "BUTTONS.OPEN" | translate}}
                            </div>
                            <button *ngIf="resolutionService.isMobileResolution" class="button edit-button" mat-icon-button color="white" (click)="openProject(project)">
                                <img class="icon" src="./../../../../assets/icons/Gruppe 3155.svg" alt="open">
                            </button>
                        </div>
                        <div class="functional-container">
                            <div class="item-info">
                                <div class="item-info-name truncate m-18" [title]="project.name">{{project.name}}</div>
                                <div class="item-info-creation-date r-16">{{ project.creationDate | date:'d. MMM yyyy'}}</div>
                            </div>
                            <div class="buttons">
                                <app-download-button [renders]="project.lastRenders" [projectId]="project.id"></app-download-button>
                                <button class="button" mat-icon-button color="white" (click)="openProject(project)">
                                    <img class="icon" src="./../../../../assets/icons/Gruppe 3155.svg" alt="open">
                                </button>
                                <button class="button" mat-icon-button color="white" (click)="removeProject(project.id)">
                                    <img class="icon" src="./../../../../assets/icons/Gruppe 3153.svg" alt="remove">
                                </button>
                            </div>
                        </div>
                    </div>
                   
                </li>
            </ul>
        </div>
        <div class="paginator-wrapper" *ngIf="projects?.length">
            <mat-paginator [ngClass]="{'paginator': pages > 2}" stylePaginator showFirstLastButtons [showTotalPages]="3"
                [length]="totalElements" [pageSize]="size" (page)="onPageEvent($event)" [pageIndex]="pageIndex"
                color="primary">
            </mat-paginator>
        </div>
    </ng-container>
</div>

<ng-template #placeholder>
    <div class="placeholder-container image">
        <img 
            class="placeholder" 
            [src]="'../../../assets/Placeholder.png' | safe: 'url'" 
            alt="placeholder"
        />
    </div>
</ng-template>