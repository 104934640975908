import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import {
  Alternatives,
  AlternativesResponseItem,
  IProposal,
  ProposalArticleExclude,
  ProposalArticleReplaceBySku,
  ProposalArticleReplaceByType,
  ProposalArticleReplaceResponse,
  ProposalArticlesIncludeBySku,
  UpdateProposal,
} from 'src/models/proposal';

import { ERROR_SKIP_INTERCEPT } from './context-tokens/error.context-token';
import { CatalogData, ProposalResponse } from './model/proposal';

@Injectable({
  providedIn: 'root',
})
export class FurnitureHttpService {
  constructor(private http: HttpClient) {}

  public getNewProposal(data: IProposal): Observable<ProposalResponse> {
    return this.http.post<ProposalResponse>('https://app.kaqtu.ch/proposal/new', data);
  }

  public updateProposal(data: UpdateProposal): Observable<ProposalResponse> {
    return this.http.post<ProposalResponse>('https://app.kaqtu.ch/proposal/update', data);
  }

  public proposalReplaceSKU(data: ProposalArticleReplaceBySku): Observable<ProposalArticleReplaceResponse> {
    return this.http.post<ProposalArticleReplaceResponse>('https://app.kaqtu.ch/proposal/article/replace/sku', data);
  }

  public proposalReplaceType(data: ProposalArticleReplaceByType): Observable<ProposalArticleReplaceResponse> {
    return this.http.post<ProposalArticleReplaceResponse>('https://app.kaqtu.ch/proposal/article/replace/type', data);
  }

  public proposalExclude(data: ProposalArticleExclude): Observable<ProposalResponse> {
    return this.http.post<ProposalResponse>('https://app.kaqtu.ch/proposal/article/exclude', data);
  }

  public proposalIncludeSKU(data: ProposalArticlesIncludeBySku): Observable<ProposalResponse> {
    return this.http.post<ProposalResponse>('https://app.kaqtu.ch/proposal/article/include/sku', data);
  }

  public getAlternatives(data: Alternatives): Observable<CatalogData[]> {
    return this.http
      .post<AlternativesResponseItem[]>('https://app.kaqtu.ch/article/alternatives', data)
      .pipe(map(data => data.map(item => item.Article)));
  }

  public getCatalogData(type: string, limit: number, start: number): Observable<CatalogData[]> {
    const params = new HttpParams().set('limit', limit).set('start', start).set('type', type);
    return this.http.get<CatalogData[]>('https://app.kaqtu.ch/paging/articles', { params });
  }

  public getSingleItem(sku: string, skipError?: boolean): Observable<{ Article: CatalogData }> {
    const context = new HttpContext();
    if (skipError) {
      context.set(ERROR_SKIP_INTERCEPT, true);
    }
    return this.http.get<{ Article: CatalogData }>('https://app.kaqtu.ch/article/' + sku, { context });
  }
}
