import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map, Subject, Subscription } from 'rxjs';

@Injectable()
export class RefreshNavigationService implements OnDestroy {
  private _refreshNavigationSubject$ = new Subject<void>();
  public refreshPageEvent$ = this._refreshNavigationSubject$.asObservable();

  private _eventSub: Subscription = new Subscription();

  constructor(private readonly _router: Router) {
    this._eventSub = this._router.events
      .pipe(
        filter(event => event['routerEvent'] instanceof NavigationEnd),
        map(event => event['routerEvent']),
        filter((event: NavigationEnd) => event.id === 1)
      )
      .subscribe(() => {
        this._refreshNavigationSubject$.next();
      });
  }

  public ngOnDestroy(): void {
    this._eventSub.unsubscribe();
    this._refreshNavigationSubject$.complete();
  }
}
