import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

import { CATALOG_OPTIONS } from '../const/catalog-options';
import { CatalogOption } from '../interfaces/catalog-option';
import { SticknessType } from '../services/enum/stickness-type.enum';
import { ValueType } from '../services/model/dto/moving-object.model';
@Component({
  selector: 'app-load-model-dialog',
  templateUrl: './load-model-dialog.component.html',
  styleUrls: ['./load-model-dialog.component.scss'],
})
export class LoadModelDialogComponent {
  public readonly types: CatalogOption[] = CATALOG_OPTIONS;
  public readonly valueTypes = [
    { value: ValueType.HEIGHT, viewValue: 'USER_FLOW.LOAD_MODEL_DIALOG.LABELS.HEIGHT' },
    { value: ValueType.WIDTH, viewValue: 'USER_FLOW.LOAD_MODEL_DIALOG.LABELS.WIDTH' },
    { value: ValueType.DEPTH, viewValue: 'USER_FLOW.LOAD_MODEL_DIALOG.LABELS.DEPTH' },
  ];
  public readonly sticknessTypes = [
    { value: SticknessType.FLOOR, viewValue: 'USER_FLOW.LOAD_MODEL_DIALOG.LABELS.FLOOR' },
    { value: SticknessType.WALLS, viewValue: 'USER_FLOW.LOAD_MODEL_DIALOG.LABELS.WALLS' },
    { value: SticknessType.CEILING, viewValue: 'USER_FLOW.LOAD_MODEL_DIALOG.LABELS.CEILING' },
  ];

  public form: FormGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    value: new FormControl(null, Validators.required),
    valueType: new FormControl(null, Validators.required),
    type: new FormControl(null, Validators.required),
    sticknessType: new FormControl(null, Validators.required),
  });

  public label = 'USER_FLOW.LOAD_MODEL_DIALOG.LABELS.VALUE';

  constructor(private _dialogRef: MatDialogRef<LoadModelDialogComponent>) {}

  public close(): void {
    this.form.updateValueAndValidity();
    if (this.form.valid) {
      this._dialogRef.close(this.form.value);
    }
  }
}
