import { createReducer, on } from '@ngrx/store';
import { IdeaTypes } from 'src/app/shared/enums/idea-type.enum';
import { CatalogDataAccessory } from 'src/services/model/proposal';

import { RenderActions, SharedActions } from '../action-types';
import { setOverlayLoadingSpinner, setRenderJobId, setRenderLoadingSpinner } from '../actions/shared.actions';
import { initialState } from '../shared.state';

export const sharedReducer = createReducer(
  initialState,
  on(setOverlayLoadingSpinner, (state, action) => {
    return {
      ...state,
      showOverlayLoading: action.status,
    };
  }),
  on(setRenderLoadingSpinner, (state, action) => {
    return {
      ...state,
      showRenderLoading: action,
    };
  }),
  on(setRenderJobId, (state, action) => {
    return {
      ...state,
      activeProject: {
        ...state.activeProject,
        renderJobId: action.id,
      },
    };
  }),
  on(SharedActions.setMode, (state, action) => {
    return {
      ...state,
      mode: action.mode,
    };
  }),
  on(SharedActions.saveProjectSuccess, (state, action) => {
    let activeProject = state.activeProject;
    activeProject = { ...activeProject, id: action.id, name: action.name, versionName: `${action.name} (V1)` };
    return {
      ...state,
      activeProject,
    };
  }),
  on(SharedActions.updateActiveProject, (state, action) => {
    let activeProject = state.activeProject;
    activeProject = { ...activeProject, ...action.projectInfo };
    return {
      ...state,
      activeProject,
    };
  }),
  on(SharedActions.versionRendersLoaded, (state, action) => {
    return {
      ...state,
      rendersResult: action.rendersResult,
    };
  }),
  on(SharedActions.reconstructSuccess, (state, action) => {
    return {
      ...state,
      reconstructResult: action.data,
    };
  }),
  on(SharedActions.detectObjectsSuccess, (state, action) => {
    return {
      ...state,
      savedObjectDetectionResult: action.data,
    };
  }),
  on(SharedActions.makeProposalSuccess, (state, action) => {
    return {
      ...state,
      proposalResult: action.data,
    };
  }),
  on(SharedActions.setUnsavedChanges, (state, action) => {
    return {
      ...state,
      changes: action.state,
    };
  }),
  on(SharedActions.clearActiveProject, state => {
    return {
      ...state,
      activeProject: null,
    };
  }),
  on(SharedActions.clearActiveProjectUpdateFlag, state => {
    const newActiveProject = { ...state.activeProject };
    delete newActiveProject.needUpdateVersion;
    return {
      ...state,
      activeProject: newActiveProject,
    };
  }),
  on(SharedActions.setCatalogData, (state, action) => {
    return {
      ...state,
      catalogData: action.data,
    };
  }),
  on(SharedActions.updateCatalogData, (state, action) => {
    const newData = state.catalogData ? [...state.catalogData, ...action.data] : action.data;
    return {
      ...state,
      catalogData: newData,
    };
  }),
  on(SharedActions.viewFurnitureSuccess, (state, action) => {
    return {
      ...state,
      furnitureDetails: {
        ...state.furnitureDetails,
        ...action.data,
      },
    };
  }),
  on(SharedActions.translateFurnitureDescriptionSuccess, (state, action) => {
    return {
      ...state,
      furnitureDetails: {
        ...state.furnitureDetails,
        descriptionTranslate: action.description,
      },
    };
  }),
  on(SharedActions.clearFurnitureDetails, state => {
    return {
      ...state,
      furnitureDetails: null,
    };
  }),
  on(SharedActions.getUserSuccess, (state, action) => {
    return {
      ...state,
      user: action.data,
    };
  }),
  on(SharedActions.clearUser, state => {
    return {
      ...state,
      user: null,
    };
  }),
  on(SharedActions.addGlobalError, (state, action) => {
    return {
      ...state,
      error: action.data,
    };
  }),
  on(SharedActions.clearSharedState, state => {
    return {
      ...state,
      ...initialState,
      user: state.user,
      // save active tab after navigation
      adminIdeas: state.adminIdeas,
      userIdeas: state.userIdeas,
      lang: state.lang,
    };
  }),
  on(SharedActions.clearStyles, state => {
    return {
      ...state,
      styles: null,
      activeProject: {
        ...state.activeProject,
        selectedStyles: null,
      },
    };
  }),
  on(SharedActions.setAccessories, (state, action) => {
    return {
      ...state,
      activeProject: {
        ...state.activeProject,
        accessories: action.data,
      },
    };
  }),
  on(SharedActions.addAccessory, (state, action) => {
    const data = state.activeProject.accessories?.length ? state.activeProject.accessories : [];
    const accessory = data.find(el => el.BasicArticleInformation.SKU === action.data.BasicArticleInformation.SKU);
    const countMapper = (el: CatalogDataAccessory): CatalogDataAccessory =>
      el.BasicArticleInformation.SKU === action.data.BasicArticleInformation.SKU ? { ...el, count: el.count + 1 } : el;
    const singleCountAccessories = [...data, { ...action.data, count: 1, lock: false }];
    const accessories = accessory ? data.map(countMapper) : singleCountAccessories;
    return {
      ...state,
      activeProject: {
        ...state.activeProject,
        accessories,
      },
    };
  }),
  on(SharedActions.replaceAccessory, (state, action) => {
    const data = state.activeProject.accessories?.length ? state.activeProject.accessories : [];
    const accessory = data.find(el => el.BasicArticleInformation.SKU === action.replaceSKU);
    let accessories = data;
    accessory.count === 1
      ? (accessories = data.filter(el => el.BasicArticleInformation.SKU !== action.replaceSKU))
      : (accessories = data.map(el => {
          if (el.BasicArticleInformation.SKU === action.replaceSKU) {
            return {
              ...el,
              count: el.count - 1,
            };
          }
          return el;
        }));
    const newAccessory = accessories.find(el => el.BasicArticleInformation.SKU === action.data.BasicArticleInformation.SKU);
    accessories = newAccessory
      ? accessories.map(el => {
          return el.BasicArticleInformation.SKU === action.data.BasicArticleInformation.SKU ? { ...el, count: el.count + 1 } : el;
        })
      : [...accessories, { ...action.data, count: 1, lock: false }];
    return {
      ...state,
      activeProject: {
        ...state.activeProject,
        accessories,
      },
    };
  }),
  on(SharedActions.deleteAccessory, (state, action) => {
    const accessories = state.activeProject.accessories
      ?.map(el => {
        return el.BasicArticleInformation.SKU === action.data ? { ...el, count: el.count - 1 } : el;
      })
      .filter(el => el.count !== 0);
    return {
      ...state,
      activeProject: {
        ...state.activeProject,
        accessories,
      },
    };
  }),
  on(SharedActions.lockAccessory, (state, action) => {
    const accessories = state.activeProject.accessories?.map(el =>
      el.BasicArticleInformation.SKU === action.sku ? { ...el, lock: !el.lock } : el
    );
    return {
      ...state,
      activeProject: {
        ...state.activeProject,
        accessories,
      },
    };
  }),
  on(SharedActions.updateCatalogState, (state, action) => {
    return {
      ...state,
      catalogOpen: action.state ? action.state : !state.catalogOpen,
    };
  }),
  on(SharedActions.setLeftPanelState, (state, action) => {
    return {
      ...state,
      leftPanelOpen: action.state,
    };
  }),
  on(SharedActions.addMoodImage, (state, action) => {
    return {
      ...state,
      uploadedMoodImages: [...state.uploadedMoodImages, action.file],
    };
  }),
  on(SharedActions.getStyleFinderIdeasSuccess, (state, action) => {
    return {
      ...state,
      lastViewedIdeaImages: action.ideas,
    };
  }),
  on(SharedActions.getStyleFinderIdeasSrcSuccess, (state, action) => {
    const ideas = state.lastViewedIdeaImages;
    const lastViewedIdeaImagesUpdateContent = ideas?.map(idea => {
      const item = action.data.find(el => el.id === idea.id);
      return {
        ...idea,
        resizedSrc: item.src,
      };
    });

    return {
      ...state,
      lastViewedIdeaImages: lastViewedIdeaImagesUpdateContent,
      ideasLoaded: true,
    };
  }),
  on(SharedActions.setStyles, (state, action) => {
    return {
      ...state,
      styles: action.styles,
    };
  }),
  on(SharedActions.addStyle, (state, action) => {
    return {
      ...state,
      styles: [action.style, ...state.styles],
    };
  }),
  on(SharedActions.getProposedFurnitureSuccess, (state, action) => {
    return {
      ...state,
      proposedFurnitureColors: {
        loaded: true,
        data: {
          articles: action.data?.articles,
          dominantColors: action.data?.dominantColors.length > 3 ? action.data.dominantColors.slice(0, 3) : action.data?.dominantColors,
        },
      },
    };
  }),
  on(SharedActions.setProposal, (state, action) => {
    return {
      ...state,
      proposal: action.data,
    };
  }),
  on(SharedActions.updateSingleArticle, (state, action) => {
    const proposalArticles = state.proposal.ProposedProposal.Articles.map(el =>
      el.Sku === action.oldSku
        ? {
            IsExternal: false,
            IsLocked: el.IsLocked,
            NumberOfExemplars: el.NumberOfExemplars,
            ProposalSlotId: action.newArticle.ProposalSlotId,
            Sku: action.newArticle.Article.BasicArticleInformation.SKU,
            Type: action.newArticle.Article.BasicArticleInformation.Type,
          }
        : el
    );

    return {
      ...state,
      proposal: {
        ...state.proposal,
        ProposedProposal: {
          ...state.proposal.ProposedProposal,
          Articles: proposalArticles,
        },
      },
    };
  }),
  on(SharedActions.lockProposalSku, (state, action) => {
    const proposalArticles = state.proposal.ProposedProposal.Articles.map(el =>
      el.Sku === action.sku
        ? {
            ...el,
            IsLocked: !el.IsLocked,
          }
        : el
    );
    return {
      ...state,
      proposal: {
        ...state.proposal,
        ProposedProposal: {
          ...state.proposal.ProposedProposal,
          Articles: proposalArticles,
        },
      },
    };
  }),
  on(SharedActions.duplicateProposalSku, (state, action) => {
    const proposalArticles = state.proposal.ProposedProposal.Articles.map(el =>
      el.Sku === action.sku
        ? {
            ...el,
            NumberOfExemplars: el.NumberOfExemplars + 1,
          }
        : el
    );
    return {
      ...state,
      proposal: {
        ...state.proposal,
        ProposedProposal: {
          ...state.proposal.ProposedProposal,
          Articles: proposalArticles,
        },
      },
    };
  }),
  on(SharedActions.getAlternativesSuccess, (state, action) => {
    return {
      ...state,
      alternatives: state.activeSku === action.sku ? action.alternatives : null,
    };
  }),
  on(SharedActions.clearAlternatives, state => {
    return {
      ...state,
      alternatives: null,
    };
  }),
  on(SharedActions.setActiveSKU, (state, action) => {
    return {
      ...state,
      activeSku: action.sku,
    };
  }),
  on(SharedActions.updateVersionRenders, (state, action) => {
    return {
      ...state,
      activeProject: {
        ...state.activeProject,
        ...action.version,
        versionId: action.version.id,
        id: state.activeProject.id,
      },
    };
  }),
  on(SharedActions.setIdeaActivePage, (state, action) => {
    return action.isAdmin
      ? {
          ...state,
          adminIdeas: {
            defaultPageIndex: action.tab === IdeaTypes.DEFAULT ? action.pageIndex : state.adminIdeas.defaultPageIndex,
            styleswapPageIndex: action.tab === IdeaTypes.STYLESWAP ? action.pageIndex : state.adminIdeas.styleswapPageIndex,
            noFurniturePageIndex: action.tab === IdeaTypes.NO_FURNITURE ? action.pageIndex : state.adminIdeas.noFurniturePageIndex,
          },
        }
      : {
          ...state,
          userIdeas: {
            defaultPageIndex: action.tab === IdeaTypes.DEFAULT ? action.pageIndex : state.userIdeas.defaultPageIndex,
            styleswapPageIndex: action.tab === IdeaTypes.STYLESWAP ? action.pageIndex : state.userIdeas.styleswapPageIndex,
            noFurniturePageIndex: action.tab === IdeaTypes.NO_FURNITURE ? action.pageIndex : state.userIdeas.noFurniturePageIndex,
          },
        };
  }),
  on(SharedActions.clearAdminActivePages, state => {
    return {
      ...state,
      adminIdeas: {
        defaultPageIndex: null,
        styleswapPageIndex: null,
        noFurniturePageIndex: null,
      },
    };
  }),
  on(RenderActions.addModel, (state, action) => {
    return state.proposal
      ? {
          ...state,
          proposal: {
            ...state.proposal,
            ProposedProposal: {
              ...state.proposal.ProposedProposal,
              Articles: state.proposal.ProposedProposal.Articles.map(el =>
                el.Sku === action.data.sku
                  ? {
                      ...el,
                      NumberOfExemplars: el.NumberOfExemplars + 1,
                    }
                  : el
              ),
            },
          },
        }
      : state;
  }),
  on(RenderActions.deleteModel, (state, action) => {
    const proposalArticles = state.proposal.ProposedProposal.Articles.map(el =>
      el.Sku === action.data.sku && el.NumberOfExemplars > 1
        ? {
            ...el,
            NumberOfExemplars: el.NumberOfExemplars - 1,
          }
        : el
    );

    return {
      ...state,
      proposal: {
        ...state.proposal,
        ProposedProposal: {
          ...state.proposal.ProposedProposal,
          Articles: proposalArticles,
        },
      },
    };
  }),
  on(SharedActions.changeLanguageSuccess, (state, action) => {
    return {
      ...state,
      lang: action.lang,
    };
  }),
  on(SharedActions.setLanguage, (state, action) => {
    return {
      ...state,
      lang: action.lang,
    };
  }),
  on(SharedActions.clearReconstructResult, state => {
    return {
      ...state,
      reconstructResult: null,
    };
  })
);
