import { AfterViewInit, ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { Observable, tap } from 'rxjs';
import { getUsers } from 'src/app/store/actions/admin.actions';
import { User } from 'src/app/store/interfaces/user';
import { users, usersTotalElements } from 'src/app/store/selectors/admin.selectors';

@UntilDestroy()
@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersListComponent implements AfterViewInit {
  public users$: Observable<User[]>;
  public totalElements$: Observable<number>;
  public size = 5;

  @ViewChild(MatPaginator)
  public paginator: MatPaginator;
  public displayedColumns: string[] = ['firstName', 'lastName', 'email', 'role', 'language'];

  constructor(
    private _store: Store,
    private _router: Router
  ) {}

  public ngAfterViewInit(): void {
    this.getUsers();
    this.paginator.page
      .pipe(
        tap(() => this.getUsers()),
        untilDestroyed(this)
      )
      .subscribe();
  }

  public getUsers(): void {
    this._store.dispatch(getUsers({ pageIndex: this.paginator?.pageIndex, pageSize: this.paginator?.pageSize }));
    this.users$ = this._store.select(users);
    this.totalElements$ = this._store.select(usersTotalElements);
  }

  public openUserProjects(user: User): void {
    this._router.navigate(['result'], { queryParams: { email: user.email } });
  }
}
