import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, filter, map, Subject, switchMap, take, tap } from 'rxjs';
import { lang, proposal } from 'src/app/store/selectors/shared.selector';
import { GoogleTranslateService } from 'src/services/translate.service';

import { BasicArticleInformation, CatalogData } from '../../../../services/model/proposal';
import { includeSku, setUnsavedChanges } from '../../../store/actions/shared.actions';
import { TranslateResponse } from '../../interfaces/translate';
import { MovingObjectsService } from '../../services/moving-objects.service';
import { ResolutionService } from '../../services/resolution.service';

@UntilDestroy()
@Component({
  selector: 'app-variant-details',
  templateUrl: './variant-details.component.html',
  styleUrls: ['./variant-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VariantDetailsComponent implements OnInit, OnChanges {
  @Input() public variant: CatalogData;

  @Output() public closeDialog: EventEmitter<void> = new EventEmitter<void>();
  @Output() public back: EventEmitter<CatalogData> = new EventEmitter<CatalogData>();

  public isAlreadyOnScene = false;

  public activeImage: string;
  public imageIndex = 0;

  public description$: Subject<string> = new Subject();

  constructor(
    public resolutionService: ResolutionService,
    private _store: Store,
    private _movingObjectsService: MovingObjectsService,
    private _googleTranslateService: GoogleTranslateService
  ) {}

  public ngOnChanges(): void {
    this._store
      .select(lang)
      .pipe(
        distinctUntilChanged(),
        tap(lang => {
          if (lang.toLocaleLowerCase() === 'de') {
            this.description$.next(this.variant.BasicArticleInformation.Description);
          }
        }),
        filter(lang => lang && lang.toLocaleLowerCase() !== 'de'),
        switchMap(lang => this._googleTranslateService.translate([this.variant.BasicArticleInformation.Description], lang)),
        untilDestroyed(this)
      )
      .subscribe((res: TranslateResponse) => {
        this.description$.next(res.translatedContents);
      });
  }

  public ngOnInit(): void {
    this.isAlreadyOnScene = !!this._movingObjectsService.movingObjects.find(obj => obj.SKU === this.variant.BasicArticleInformation.SKU);
    this.activeImage = this.variant.BasicArticleInformation.PackshotImages[0];
  }

  public addModel(data: CatalogData): void {
    this._store
      .select(proposal)
      .pipe(
        take(1),
        map(state => state.ProposedProposal.Articles.find(article => article.Sku === data.BasicArticleInformation.SKU)),
        tap(article => {
          if (article) {
            if (this.checkModelLinkExist(data.BasicArticleInformation)) {
              const uuid = this._movingObjectsService.movingObjects.find(obj => obj.SKU === article.Sku).id;
              this._movingObjectsService.clone(uuid, true);
            } else {
              this._store.dispatch(
                includeSku({
                  sku: data.BasicArticleInformation.SKU,
                  is3d: false,
                })
              );
            }

            this._store.dispatch(setUnsavedChanges({ state: true }));
          } else {
            this._store.dispatch(
              includeSku({
                sku: data.BasicArticleInformation.SKU,
                is3d: this.checkModelLinkExist(data.BasicArticleInformation),
              })
            );

            this.isAlreadyOnScene = true;
          }
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  public checkModelLinkExist(data: BasicArticleInformation): boolean {
    return !!(data.MetaData.find(el => el.Key === '3D_GLB_LOW_ELI')?.Value || data.AR.GLB || data.AR.FBX);
  }

  public close(): void {
    this.back.emit(null);
  }

  public setVariant(variant: CatalogData): void {
    this.back.emit(variant);
  }

  public previous(images: string[]): void {
    if (this.imageIndex - 1 >= 0) {
      this.activeImage = images[--this.imageIndex];
    }
  }

  public next(images: string[]): void {
    if (this.imageIndex + 1 < images.length) {
      this.activeImage = images[++this.imageIndex];
    }
  }
}
