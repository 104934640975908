<div class="catalog-container">
  <div class="catalog">
    <app-catalog-categories
      *ngIf="leftPanelState === leftPanelStates.CATALOG_CATEGORIES"
      (categoryChange)="onCategoryChange($event)"></app-catalog-categories>
    <app-catalog-category
      *ngIf="leftPanelState === leftPanelStates.CATALOG_CATEGORY || (leftPanelState === leftPanelStates.CATALOG_DETAILS && resolutionService.isMobileResolution)"
      (openCategories)="openCategories()" (openDetailsEvent)="openDetailsPanel()" [furnitureType]="furnitureType">
    </app-catalog-category>
    <app-catalog-details
      *ngIf="leftPanelState === leftPanelStates.CATALOG_DETAILS && !resolutionService.isMobileResolution"
      (backToCategories)="backToCategories()"></app-catalog-details>
    <app-scene-details
      *ngIf="leftPanelState === leftPanelStates.SCENE_DETAILS && !resolutionService.isMobileResolution"></app-scene-details>
    <app-color-select-container [selectedWall]="mainWall"
      *ngIf="leftPanelState === leftPanelStates.CURRENT_COLOR"></app-color-select-container>
  </div>
</div>