<div class="page-container">
    <div class="left-panel">
        <div class="back" (click)="back()" aria-hidden="true">
            <img class="icon" src="./../../../../assets/icons/arrow-left-slide.png" alt="next">
            <span class="r-18">{{ 'USER_FLOW.EXPORT_PAGE.BACK' | translate}}</span>
        </div>
        <div class="data r-18" *ngIf="activeRender?.renderDate">{{ activeRender.renderDate + 'Z' | date:'MMM dd yyyy HH:mm' }}</div>
        <div *ngIf="!resolutionService.isMobileResolution, then form">
    </div>
    
    </div>

    <div class="page-content">
        <div class="image-container">
            <img 
                #image 
                class="background-image" 
                [src]="activeRender?.src | safe:'url'"
                alt="image"
            >
            <div *ngIf="renderResult?.length > 1">
                <button class="icon-button previous" [disabled]="renderIndex-1<0" (click)="previous()">
                    <img 
                        *ngIf="renderIndex-1>=0;else leftDisabled" 
                        class="arrow" 
                        src="../../../assets/icons/Gruppe 3005.svg"
                        alt="previous"
                    >
                </button>
                <button class="icon-button next" [disabled]="renderIndex+1>=renderResult.length" (click)="next()">
                    <img 
                        *ngIf="renderIndex+1<renderResult.length;else rightDisabled" 
                        class="arrow transform" 
                        src="../../../assets/icons/Gruppe 3005.svg"
                        alt="next"
                    >
                </button>
                <span class="image-count">{{renderIndex + 1}}/{{renderResult.length}}</span>
            </div>
        </div>
        
        <div *ngIf="resolutionService.isMobileResolution, then form"></div>
    </div>
</div>

<ng-template #leftDisabled>
    <img 
        class="arrow" 
        src="../../../assets/icons/Gruppe 3005 disabled.svg"
        alt="left arrow disabled"
    >
</ng-template>

<ng-template #rightDisabled>
    <img 
        class="arrow transform" 
        src="../../../assets/icons/Gruppe 3005 disabled.svg"
        alt="right arrow disabled"
    >
</ng-template>


<ng-template #form>
    
    <form [formGroup]="settings" class="form">
        <div class="form-field">
            <div>
                <label class="r-18">{{ "USER_FLOW.EXPORT_PAGE.BRIGHTNESS" | translate}}</label>
            </div>
            <mat-slider class="slider" [max]="brightnessSlider.max" [min]="brightnessSlider.min" [step]="brightnessSlider.step">
                <input matSliderThumb formControlName="brightness">
            </mat-slider>
            <button class="button" mat-stroked-button (click)="resetBrightness()">{{ "BUTTONS.RESET" | translate}}</button>
        </div>
        <div>
            <p class="r-14">{{ "USER_FLOW.EXPORT_PAGE.RESOLUTION" | translate}}</p>
            <mat-form-field  class="form-field">
                <mat-select formControlName="resolution">
                    <mat-option *ngFor="let resolution of resolutions" [value]="resolution">
                        {{ resolution.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
       
        

        <div>
            <p class="r-14">{{ "USER_FLOW.EXPORT_PAGE.FORMAT" | translate}}</p>
            <mat-form-field class="form-field">
                <mat-select formControlName="format">
                    <mat-option *ngFor="let type of exportTypes" [value]="type.value">
                        {{type.viewValue}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </form>
    <button class="export-button button" color="primary" mat-raised-button (click)="export()">{{'BUTTONS.DOWNLOAD_PHOTO' | translate}}</button>
</ng-template>