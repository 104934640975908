<div *ngIf="(projects$ | async).length; else noData">
    <app-projects-list 
        [size]="size$ | async"
        [pageIndex]="pageIndex$ | async"
        [totalElements]="totalElements$ | async"
        [projects]="projects$ | async"
        [isAdminView]="false"
        (openProjectEvent)="handleOpen($event)"
        (removeProjectEvent)="handleRemove($event)"
        (updateProjects)="getProjects($event)"
        >
    </app-projects-list>
</div>

<ng-template #noData>
    <app-no-data-page
        message="PROJECTS.NO_DATA"
        (create)="createProjects()"
    >
    </app-no-data-page>
</ng-template>