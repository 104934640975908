import { createFeatureSelector, createSelector } from '@ngrx/store';

import { UndoRedoState } from './undo-redo.state';

export const selectUndoredoState = createFeatureSelector<UndoRedoState>('undoredo');

export const getUndoAction = createSelector(
  selectUndoredoState,
  (state: UndoRedoState) => state.undoActions.length > 0 && state.undoActions[state.undoActions.length - 1]
);

export const getRedoAction = createSelector(
  selectUndoredoState,
  (state: UndoRedoState) => state.redoActions.length > 0 && state.redoActions[0]
);

export const getCurrentAction = createSelector(selectUndoredoState, (state: UndoRedoState) => state.current);
