import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ResolutionService } from 'src/app/user-flow/services/resolution.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  constructor(public resolutionService: ResolutionService) {}
}
