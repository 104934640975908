import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, filter, interval, map, Observable, of, race, Subject, Subscription, switchMap, take, takeUntil, tap } from 'rxjs';
import { Notification } from 'src/app/notifications/model/notification';
import { NotificationsService } from 'src/app/notifications/services/notifications.service';
import { ImportantNotificationsService } from 'src/app/shared/services/important-notifications.service';
import {
  loadRenders,
  setOverlayLoadingSpinner,
  setRenderJobId,
  setUnsavedChanges,
  updateActiveProject,
  updateVersionRenders,
} from 'src/app/store/actions/shared.actions';
import { versionLoaded } from 'src/app/store/actions/versions.actions';
import { activeProject, activeProjectVersionId } from 'src/app/store/selectors/shared.selector';
import { clearUndoRedoState } from 'src/app/undo-redo/undo-redo.actions';
import { IProjectAdditionalInfo, IVersion } from 'src/models/project';
import { AUTH_CONTEXT } from 'src/services/context-tokens/intercept.context-token';
import { ProjectsVersionsHttpService } from 'src/services/projects-versions-http.service';
import { Scene } from 'three';

import { ExportService } from './export.service';
import { PhotorealisticRenderConfig } from './model/config/photorealistic-render-config.model';
import { RenderAnswer } from './model/dto/render-answer.model';
import { RenderProgress } from './model/dto/render-progress.model';
import { CameraRenderParams } from './model/params/camera-render-params.model';
import { MovingObjectsService } from './moving-objects.service';
import { RoomService } from './room.service';
import { environment } from '../../../environments/environment';
import { Resolution } from '../export-settings/resolution.class';
import { RenderInputParameters } from '../interfaces/render-input-parameters';

@UntilDestroy()
@Injectable()
export class PhotorealisticRenderService {
  private _config!: PhotorealisticRenderConfig;

  private _renderingSubject = new Subject<RenderProgress>();

  private _renderResult: Blob;
  private _renderingSubscriptionComposite: Subscription = new Subscription();
  private _pollingStopper: Subject<void>;

  private _currentVersionId?: string;
  private _currentVersionRenderJobId?: string;
  private _currentPollingRenderJobId?: string;

  // better to set number of cycles to power of two
  public renderInputParams$: BehaviorSubject<RenderInputParameters> = new BehaviorSubject<RenderInputParameters>({
    renderSamplesCount: 256,
    shadowSamplesCount: 16,
    resolution: null,
  });

  public get cameraRenderParams(): CameraRenderParams {
    const inputParams = this.renderInputParams$.getValue();

    let outputHeight = inputParams.resolution ? inputParams.resolution.height : this._config.originalHeight;
    let outputWidth = inputParams.resolution ? inputParams.resolution.width : this._config.originalWidth;

    const outputResolution = new Resolution(outputWidth, outputHeight);

    const targetResolution = new Resolution(2048, 1440, '2K');
    if (outputResolution.area > targetResolution.area) {
      const updTarget = outputResolution.scaleToWithFlip(targetResolution);
      outputHeight = updTarget.height;
      outputWidth = updTarget.width;
    }

    const cameraRenderParams: CameraRenderParams = {
      width: this._config.originalWidth,
      height: this._config.originalHeight,
      vfov: this._config.camera.fov,
      pitch: this._config.camera.rotation.x,
      roll: this._config.camera.rotation.z,
      samples: inputParams.shadowSamplesCount,
      samplesRender: inputParams.renderSamplesCount,
      outputHeight: outputHeight,
      outputWidth: outputWidth,
      lightsCount: this._roomService.getLightsCount(),
      furnitures: this._movingObjectService.getFurnitureInfoForRendering(outputWidth, outputHeight, this._config.camera),
    };
    return cameraRenderParams;
  }

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private _dialog: MatDialog,
    private _exportService: ExportService,
    private _http: HttpClient,
    private _notificationsService: NotificationsService,
    private _store: Store,
    private _translateService: TranslateService,
    private _movingObjectService: MovingObjectsService,
    private _roomService: RoomService,
    private _projectsVersionsHttpService: ProjectsVersionsHttpService,
    private _importantNotificationsService: ImportantNotificationsService
  ) {
    this._store
      .select(activeProject)
      .pipe(untilDestroyed(this))
      .subscribe(project => {
        this._currentVersionId = project?.versionId ?? null;
        this._currentVersionRenderJobId = project?.renderJobId ?? null;
      });
  }

  public setInitialState(): void {
    this._postRenderClean();

    this._config = null;
    this._renderResult = null;
  }

  public configure(config: PhotorealisticRenderConfig): void {
    this._config = config;

    this._checkForRendering();
  }

  public updateRenderedProject(version: IVersion): Observable<IProjectAdditionalInfo> {
    return this._store.select(activeProject).pipe(
      take(1),
      map(activeProject => ({
        oldActiveProject: activeProject,
        version: version,
      })),
      tap(data => {
        this._store.dispatch(versionLoaded({ id: data.oldActiveProject.id, version: data.version }));
      }),
      switchMap(({ oldActiveProject, version }) => {
        const projectId = oldActiveProject.id;
        const projectInfo: IProjectAdditionalInfo = {
          ...version,
          id: projectId,
          versionId: version.id,
        };

        this._store.dispatch(updateActiveProject({ projectInfo }));
        this._store.dispatch(clearUndoRedoState());
        this._store.dispatch(setRenderJobId({ id: null }));

        return of(projectInfo);
      }),
      untilDestroyed(this)
    );
  }

  private _pollingClosed(closureVersion: string): Observable<void> {
    const renderFinished = this._pollingStopper.asObservable();
    const versionChanged = this._store.select(activeProjectVersionId).pipe(
      filter(currentVersion => currentVersion && currentVersion !== closureVersion),
      take(1),
      map(() => undefined),
      untilDestroyed(this)
    );

    return race([versionChanged, renderFinished]);
  }

  public render(componentRef: any, showLoader?: boolean, versionId?: string): void {
    if (showLoader) {
      this._store.dispatch(setOverlayLoadingSpinner({ status: true }));
    }

    this._store
      .select(activeProject)
      .pipe(
        take(1),
        filter(activeProject => !!activeProject),
        switchMap(activeProject => {
          const file = activeProject.handledBackgroundFile ?? activeProject.file;

          const scene = this._exportService.getExportedScene(this._roomService.getLightTarget());
          const background = file;

          const cameraRenderParams = this.cameraRenderParams;

          this._importantNotificationsService.importantNotification(
            'NOTIFICATIONS.TITLE.RENDERING_PROGRESS',
            'NOTIFICATIONS.MESSAGES.CREATION_START',
            true,
            true,
            '370px'
          );

          if (versionId) {
            return this._runRender(scene, background, cameraRenderParams, versionId, false, false);
          }

          return this._runRender(scene, background, cameraRenderParams, activeProject.versionId, true, showLoader);
        }),
        untilDestroyed(componentRef)
      )
      .subscribe(() => {});
  }

  private _subscribeToNotifications(): void {
    const sub = this._renderingSubject.subscribe(progress => {
      if (progress) {
        if (progress.error) {
          this._notificationsService.addNotification(
            new Notification({
              title: this._translateService.instant('NOTIFICATIONS.TITLE.ERROR'),
              text: this._translateService.instant('NOTIFICATIONS.MESSAGES.INTERNAL_SERVER_ERROR'),
              level: 'error',
              options: { timeout: 2 },
            })
          );
          return;
        }
      } else {
        this._notificationsService.addNotification(
          new Notification({
            title: this._translateService.instant('NOTIFICATIONS.TITLE.RENDERING_FINISHED'),
            text: this._translateService.instant('NOTIFICATIONS.MESSAGES.PROGRESS_FINISHED'),
            level: 'success',
            options: { timeout: 2 },
          })
        );
      }
    });

    this._renderingSubscriptionComposite.add(sub);
  }

  public openRendersWindow(): void {
    this._store
      .select(activeProject)
      .pipe(
        take(1),
        filter(project => project.versionId === this._currentVersionId),
        untilDestroyed(this)
      )
      .subscribe(version => {
        this._store.dispatch(
          loadRenders({
            renders: version.renders,
            projectId: version.id,
            selectedVersionId: version.versionId,
          })
        );
      });
  }

  private _postRenderClean(): void {
    this._renderingSubscriptionComposite.unsubscribe();
    this._renderingSubscriptionComposite = new Subscription();
    this._pollingStopper?.next();
    this._pollingStopper?.complete();
    this._pollingStopper = null;
    this._renderingSubject.complete();
    this._renderingSubject = new Subject<RenderProgress>();
    this._store.dispatch(setUnsavedChanges({ state: true }));
  }

  private _getPolling(version: string, renderJobId: string): Observable<void> {
    this._pollingStopper = new Subject<void>();

    return interval(5000).pipe(
      takeUntil(this._pollingClosed(version)),
      switchMap(() =>
        this._http.get<RenderProgress>(environment.API_URL + 'blender/' + renderJobId + '/progress', { context: AUTH_CONTEXT })
      ),
      tap((progress: RenderProgress) => {
        this._renderingSubject.next(progress);
        // changes to backend applied only after progress request returns empty body
        this._currentPollingRenderJobId = progress?.id ?? this._currentPollingRenderJobId;
      }),
      filter((progress: RenderProgress) => !progress || progress.error),
      take(1),
      switchMap(() => {
        if (this._currentVersionRenderJobId === this._currentVersionRenderJobId) {
          return this._projectsVersionsHttpService.getVersion(this._currentVersionId).pipe(
            map(updatedVersion => {
              this._store.dispatch(updateVersionRenders({ version: updatedVersion }));
              // add line before to open export settings for successful rendering in opened version
              // this.openRendersWindow();
            })
          );
        }

        return of(null);
      }),
      tap(() => {
        this._postRenderClean();
        return null;
      })
    );
  }

  private _sendRenderRequest(
    version: string,
    scene: Scene,
    background: File,
    cameraRenderParams: CameraRenderParams
  ): Observable<RenderAnswer> {
    return this._exportService.getSceneFileBuffer(scene).pipe(
      switchMap(gltf => {
        const gltfString = JSON.stringify(gltf, null, 2);

        const formFata = new FormData();
        formFata.append('gltf', new Blob([gltfString]));
        formFata.append('background', background);
        formFata.append('params', JSON.stringify(cameraRenderParams));
        formFata.append('version', version);

        return this._http.post<RenderAnswer>(environment.API_URL + 'blender/render', formFata, { context: AUTH_CONTEXT });
      })
    );
  }

  private _runRender(
    scene: Scene,
    background: File,
    cameraRenderParams: CameraRenderParams,
    version: string,
    triggerPolling: boolean,
    showLoader?: boolean
  ): Observable<void> {
    this._subscribeToNotifications();

    return this._sendRenderRequest(version, scene, background, cameraRenderParams).pipe(
      switchMap((answer: RenderAnswer) => {
        if (triggerPolling) {
          this._store.dispatch(setRenderJobId({ id: answer.id }));
        }

        if (showLoader) {
          this._store.dispatch(setOverlayLoadingSpinner({ status: false }));
        }

        if (!triggerPolling) return of(undefined);

        return this._getPolling(version, answer.id);
      })
    );
  }

  private _checkForRendering(): void {
    this._subscribeToNotifications();

    this._store
      .select(activeProject)
      .pipe(
        filter(data => !!data),
        take(1),
        filter(activeProject => !!activeProject?.renderJobId),
        switchMap(activeProject => this._getPolling(activeProject.versionId, activeProject.renderJobId)),
        untilDestroyed(this)
      )
      .subscribe(() => {});
  }
}
