import { HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModuleConfig } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export enum TranslateLanguagesEnum {
  ENGLISH = 'en',
  GERMAN = 'de',
  ITALIAN = 'it',
  FRENCH = 'fr',
}

export function TranslateLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

export const TRANSLATE_MODULE_CONFIG: TranslateModuleConfig = {
  loader: {
    provide: TranslateLoader,
    useFactory: TranslateLoaderFactory,
    deps: [HttpClient],
  },
};

export const LANGUAGES: { id: TranslateLanguagesEnum; value: string }[] = [
  {
    id: TranslateLanguagesEnum.ENGLISH,
    value: 'English',
  },
  {
    id: TranslateLanguagesEnum.GERMAN,
    value: 'German',
  },
  {
    id: TranslateLanguagesEnum.ITALIAN,
    value: 'Italian',
  },
  {
    id: TranslateLanguagesEnum.FRENCH,
    value: 'French',
  },
];

export const DEFAULT_LANGUAGE: { id: TranslateLanguagesEnum; value: string } = {
  id: TranslateLanguagesEnum.GERMAN,
  value: 'German',
};
