import { Object3D, Vector3 } from 'three';

import { curtainsAlgorithm } from './common';
import { PositioningElement } from './positioning-element.model';
import { RoomSchema } from './schema.model';
import { FurnitureType } from '../../enum/furniture-types.enum';
import { getForwardDirection, getObjectSize, moveModel } from '../autopositioning.function';

let isCornerSofa = false;
const LAMP_ABOVE_FLOOR = 1.6;

function checkSofaOrientation(
  sofa3D: Object3D,
  sofaOrientation: 'left' | 'right',
  wallCorners: [Vector3, Vector3],
  roomCenter: Vector3
): void {
  const sofaPosition = sofa3D.position.clone();
  const wallOrientation = getForwardDirection(sofaPosition, wallCorners, roomCenter).orientation;
  if (sofaOrientation !== wallOrientation) {
    sofa3D.scale.x *= -1;
  }
}

function handleCornerSofa(
  objects,
  userData: {
    isCornerSofa: boolean;
    sofaOrientation: 'left' | 'right';
    sideTable: Object3D;
    wallCorners: [Vector3, Vector3];
    roomCenter: Vector3;
  },
  meterPerPixel
): void {
  isCornerSofa = userData.isCornerSofa;

  if (userData.isCornerSofa) {
    checkSofaOrientation(objects[0].object3D, userData.sofaOrientation, userData.wallCorners, userData.roomCenter);

    moveModel(
      objects[0].object3D,
      {
        width: getObjectSize(objects[0].object3D).x * meterPerPixel,
        height: getObjectSize(objects[0].object3D).y * meterPerPixel,
        depth: getObjectSize(objects[0].object3D).z * meterPerPixel,
      },
      {
        left: () => (userData.sideTable ? 0.6 + getObjectSize(userData.sideTable).x * meterPerPixel : 0),
      },
      userData.wallCorners,
      userData.roomCenter,
      1 / meterPerPixel
    );
  }
}

const sofa: PositioningElement = {
  type: FurnitureType.SOFA,
  rules: [
    {
      action: 'SET_AT_WALL',
      relatedObjects: [FurnitureType.EASY_CHAIR, FurnitureType.FLOOR_LAMP],
      distance: size => size.width / 2 + 0.6,
    },
    {
      action: 'MOVE',
      forward: size => size.depth / 2 + 0.05,
    },
    {
      action: 'LOOK_AT',
      value: 'FORWARD',
    },
    {
      action: 'SET_CUSTOM',
      callback: handleCornerSofa,
    },
  ],
};

const sideTable: PositioningElement = {
  type: FurnitureType.SIDE_TABLE,
  rules: [
    {
      action: 'MOVE',
      relatedObject: { inAction: sofa },
      condition: () => !isCornerSofa,
      forward: (size, relatedSize) => size.depth / 2 + relatedSize.depth / 2 + 0.35,
    },
    {
      action: 'MOVE',
      relatedObject: { inAction: sofa },
      condition: () => isCornerSofa,
      forward: (size, relatedSize) => size.depth / 2 + relatedSize.depth / 3,
      right: (size, relatedSize) => relatedSize.width / 4,
    },
    {
      action: 'LOOK_AT',
      value: sofa,
    },
  ],
};

const carpet: PositioningElement = {
  type: FurnitureType.CARPET,
  rules: [
    {
      action: 'MOVE',
      relatedObject: { inAction: sofa, inCondition: sofa },
      condition: (size, relatedSize): boolean => {
        const longestCarpetSize = Math.max(size.width, size.depth);
        const longestSofaSize = Math.max(relatedSize.width, relatedSize.depth);

        return longestCarpetSize > longestSofaSize;
      },
      forward: (size, relatedSize): number => {
        const shortestCarpetSize = Math.min(size.width, size.depth);
        const shortestSofaSize = Math.min(relatedSize.width, relatedSize.depth);

        return (shortestCarpetSize / 2 + shortestSofaSize / 2 + 0.35) / 2;
      },
      up: size => size.height,
    },
    {
      action: 'MOVE',
      relatedObject: { inAction: sideTable, inCondition: sofa },
      condition: (size, relatedSize): boolean => {
        const longestCarpetSize = Math.max(size.width, size.depth);
        const longestSofaSize = Math.max(relatedSize.width, relatedSize.depth);

        return longestCarpetSize <= longestSofaSize;
      },
      up: size => size.height,
    },
    {
      action: 'LOOK_AT',
      value: sofa,
    },
    {
      action: 'ROTATE',
      condition: size => size.depth > size.width,
      asix: 'y',
      value: 90,
    },
  ],
};

const loungeChair: PositioningElement = {
  type: FurnitureType.EASY_CHAIR,
  rules: [
    {
      action: 'MOVE',
      relatedObject: { inAction: sofa },
      condition: () => !isCornerSofa,
      left: (size, relatedSize) => relatedSize.width / 2 + 0.5,
      forward: size => size.depth / 2,
    },
    {
      action: 'MOVE',
      relatedObject: { inAction: sofa },
      condition: () => isCornerSofa,
      right: (size, relatedSize) => relatedSize.width / 2 + 0.5,
      forward: size => size.depth / 2,
    },
    {
      action: 'LOOK_AT',
      value: sideTable,
    },
  ],
};

const pedantLamp: PositioningElement = {
  type: FurnitureType.LAMP,
  rules: [
    {
      action: 'MOVE',
      relatedObject: { inAction: sideTable },
      up: (size, relatedSize) => size.height / 2 - relatedSize.height / 2,
    },
    {
      action: 'SET_CUSTOM',
      callback: (pedantLamps, { floorY, heightMeters }, meterPerPixel): void => {
        if (pedantLamps?.length > 0 && heightMeters && floorY) {
          const newLampY = LAMP_ABOVE_FLOOR / meterPerPixel + floorY;

          pedantLamps[0].object3D.position.setY(newLampY);
        }
      },
    },
  ],
};

const floorLamp: PositioningElement = {
  type: FurnitureType.FLOOR_LAMP,
  rules: [
    {
      action: 'MOVE',
      relatedObject: { inAction: sofa },
      condition: () => !isCornerSofa,
      left: (size, relatedSize) => relatedSize.width / 2 + 0.1,
      backward: (size, relatedSize) => relatedSize.depth / 2 - size.depth / 2,
    },
    {
      action: 'MOVE',
      relatedObject: { inAction: sofa },
      condition: () => isCornerSofa,
      right: (size, relatedSize) => relatedSize.width / 2 + 0.1,
      backward: (size, relatedSize) => relatedSize.depth / 2 - size.depth / 2,
    },
  ],
};

const plant: PositioningElement = {
  type: FurnitureType.PLANT,
  rules: [
    {
      action: 'MOVE',
      relatedObject: { inAction: sofa },
      condition: () => !isCornerSofa,
      left: (size, relatedSize) => relatedSize.width / 2 + 0.5,
      backward: (size, relatedSize) => relatedSize.depth / 2 - size.depth / 2,
    },
    {
      action: 'MOVE',
      relatedObject: { inAction: sofa },
      condition: () => isCornerSofa,
      right: (size, relatedSize) => relatedSize.width / 2 + 0.5,
      backward: (size, relatedSize) => relatedSize.depth / 2 - size.depth / 2,
    },
  ],
};

const image: PositioningElement = {
  type: FurnitureType.PAINTING,
  rules: [
    {
      action: 'MOVE',
      index: 0,
      relatedObject: { inAction: sofa },
      left: (size, relatedSize) => relatedSize.width / 8,
      backward: (size, relatedSize) => relatedSize.depth / 2 + 0.05,
    },
    {
      action: 'MOVE',
      index: 1,
      relatedObject: { inAction: 0 },
      left: (size, relatedSize) => relatedSize.width / 2 + size.width / 2 + 0.1,
      up: () => 0.1,
    },
    {
      action: 'SET_CUSTOM',
      callback: (images, { heightMeters }): void => {
        if (images && heightMeters) {
          images.forEach(image => image.object3D.position.add(new Vector3(0, heightMeters / 2, 0)));
        }
      },
    },
    {
      action: 'LOOK_AT',
      value: 'FORWARD',
    },
  ],
};

const curtain: PositioningElement = {
  type: FurnitureType.CURTAIN,
  rules: [
    {
      action: 'SET_CUSTOM',
      callback: curtainsAlgorithm,
    },
  ],
};

export const LIVING_ROOM_SCHEMA: RoomSchema = {
  elements: [sofa, sideTable, carpet, loungeChair, pedantLamp, floorLamp, plant, image, curtain],
};
