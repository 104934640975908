<div class="footer">
    <div class="footer-columns">
        <ng-container *ngIf="resolutionService.isMobileResolution; then social"></ng-container>
        <div class="footer-column">
            <p class="footer-column-title m-22">{{ "FOOTER.CONTACT" | translate }}</p>
            <div class="footer-column-content">
                <a href="mailto:info@autenica.space" target="_blank" class="r-20">{{ "FOOTER.CONTACT_NOW" | translate }}</a>
                <a href="mailto:info@autenica.space" target="_blank" class="r-20">{{ "FOOTER.COMMENT_US" | translate }}</a>
            </div>
        </div>
        <div class="footer-column">
            <p class="footer-column-title m-22">{{ "FOOTER.SEE_ALSO" | translate }}</p>
            <div class="footer-column-content">
                <a href="../../../../assets/pdf/agb.pdf" target="_blank" class="r-20">{{ "FOOTER.AGB" | translate }}</a>
                <a href="../../../../assets/pdf/impressum.pdf" target="_blank" class="r-20">{{ "FOOTER.IMPRINT" | translate }}</a>
                <a href="../../../../assets/pdf/privacy-policy.pdf" target="_blank" class="r-20">{{ "FOOTER.DATA_PROTECTION" | translate }}</a>
            </div>
        </div>
        <ng-container *ngIf="!resolutionService.isMobileResolution; then social"></ng-container>
    </div>
</div>

<ng-template #social>
    <div class="footer-column">
        <p class="footer-column-title m-22" [ngClass]="{'first-footer-section': resolutionService.isMobileResolution}">{{ "FOOTER.FOLLOW_US" | translate }}</p>
        <div class="footer-column-content">
            <div class="socials">
                <div class="social">
                    <a href="https://www.facebook.com/KaqtuDesign/" target="_blank">
                        <img 
                            class="icon" 
                            src="../../../../assets/icons/facebook.svg"
                            alt="facebook"
                        >
                    </a>
                </div>
                <div class="social">
                    <a class="icon" href="https://www.instagram.com/kaqtu_design" target="_blank">
                        <img 
                            class="icon" 
                            src="../../../../assets/icons/instagram.svg" 
                            alt="instagram"
                        >
                    </a>
                </div>
                <div class="social">
                    <a href="https://www.linkedin.com/company/kaqtu-design-ag/" target="_blank">
                        <img 
                            class="icon" 
                            src="../../../../assets/icons/linkedin.svg" 
                            alt="linkedin"
                        >
                    </a>
                </div>
                <div class="social">
                    <a href="https://www.pinterest.ch/kaqtu_design/" target="_blank">
                        <img 
                            class="icon" 
                            src="../../../../assets/icons/pinterest.svg" 
                            alt="pinterest"
                        >
                    </a>
                </div>
                <div class="social">
                    <a href="https://www.tiktok.com/@kaqtu_design" target="_blank">
                        <img 
                            class="icon" 
                            src="../../../../assets/icons/tiktok-svgrepo-com.svg" 
                            alt="tiktok"
                        >
                    </a>
                </div>
            </div>
        </div>
    </div>
</ng-template>
