import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Notification } from 'src/app/notifications/model/notification';
import { NotificationsService } from 'src/app/notifications/services/notifications.service';

import { ResetPasswordForm } from '../../model/reset-password.model';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  public form: FormGroup<ResetPasswordForm> = new FormGroup<ResetPasswordForm>({
    token: new FormControl(null, [Validators.required]),
    newPassword: new FormControl(null, [Validators.required]),
  });

  constructor(
    private _authService: AuthService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _notificationsService: NotificationsService,
    private _translateService: TranslateService
  ) {}

  public ngOnInit(): void {
    const token: string = this._activatedRoute.snapshot.url[this._activatedRoute.snapshot.url.length - 1].path;
    this.form.setValue({ token, newPassword: null });
  }

  public submit(): void {
    this.form.updateValueAndValidity();
    if (this.form.valid) {
      this._authService.reset(this.form.getRawValue()).subscribe({
        next: () => {
          this._notificationsService.addNotification(
            new Notification({
              title: this._translateService.instant('NOTIFICATIONS.TITLE.SUCCESS'),
              text: this._translateService.instant('NOTIFICATIONS.MESSAGES.PASSWORD_CHANGED'),
              level: 'success',
              options: { timeout: 2 },
            })
          );
          this._router.navigate(['login']);
        },
        error: (error: HttpErrorResponse) =>
          this._notificationsService.addNotification(
            new Notification({
              title: this._translateService.instant('NOTIFICATIONS.TITLE.ERROR'),
              text: error.error['detail'],
              level: 'error',
              options: { timeout: 2 },
            })
          ),
      });
    }
  }
}
