import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { RouterModule, Routes } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { SafePipeModule } from 'safe-pipe';

import { UsersHttpService } from './services/users-http.service';
import { UserProjectsComponent } from './user-projects/user-projects.component';
import { UsersListComponent } from './users-list/users-list.component';
import { AuthGuard } from '../auth/guards/auth.guard';
import { ProjectsModule } from '../projects/projects.module';
import { TRANSLATE_MODULE_CONFIG } from '../shared/configs/translate.config';
import { SharedComponentsModule } from '../shared/shared-components.module';
import { AdminEffects } from '../store/effects/admin.effects';
import { adminReducer } from '../store/reducers/admin.reducers';

const routes: Routes = [
  {
    path: 'admin',
    component: UsersListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'user-projects',
    component: UserProjectsComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  declarations: [UsersListComponent, UserProjectsComponent],
  imports: [
    CommonModule,
    EffectsModule.forFeature([AdminEffects]),
    MatButtonModule,
    MatPaginatorModule,
    RouterModule.forChild(routes),
    SafePipeModule,
    ProjectsModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatTableModule,
    TranslateModule.forChild(TRANSLATE_MODULE_CONFIG),
    StoreModule.forFeature('admin', adminReducer),
    SharedComponentsModule,
  ],
  providers: [UsersHttpService],
  exports: [UserProjectsComponent],
})
export class AdminModule {}
