import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

import { KeyboardStateService } from '../../../services/keyboard-state.service';
import { ColorGroup } from '../color-group.enum';
import { colorGroups } from '../colors';
import { NcsColor } from '../ncs-color.model';

@Component({
  selector: 'app-all-colors',
  templateUrl: './all-colors.component.html',
  styleUrls: ['./all-colors.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AllColorsComponent implements OnChanges {
  public readonly viewGroups = Object.keys(colorGroups);

  @Output()
  public colorChanged = new EventEmitter<{ color: NcsColor; colorFixed: boolean }>();

  @Output()
  public backToCurrentColor: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  public colorData: { color: NcsColor; colorFixed: boolean };

  public get selectedGroup(): ColorGroup {
    return this._selectedGroup;
  }

  public showCode = false;
  public colors: NcsColor[] = [];
  public search = '';

  private _selectedGroup: ColorGroup;

  constructor(private _keyboardService: KeyboardStateService) {}

  public ngOnChanges(): void {
    if (this.colorData) {
      const group = Object.keys(colorGroups).find(group =>
        colorGroups[group].find(color => color.Code === this.colorData.color.Code)
      ) as ColorGroup;

      if (group) {
        this.selectGroup(group);
      }
    }
  }

  public selectGroup(group: string): void {
    this._selectedGroup = group as ColorGroup;
    this._updateColors();
  }

  public selectColor(color: NcsColor): void {
    this.colorData = { color: color, colorFixed: false };
    this.colorChanged.next(this.colorData);
  }

  public onSearch(search: string): void {
    this.search = search;
    this._updateColors();
  }

  public clearSearch(): void {
    this.search = '';
  }

  public clearSelectedColor(): void {
    this.colorData = null;
    this.colorChanged.next({
      color: {
        Code: undefined,
        Hex: undefined,
        Group: ColorGroup.NO_COLOR,
      },
      colorFixed: false,
    });
  }

  private _updateColors(): void {
    this.colors = colorGroups[this._selectedGroup].slice().filter(color => color.Code.includes(this.search));
  }

  public searchStart(): void {
    this._keyboardService.keyboardEnabled = false;
  }

  public searchEnd(): void {
    this._keyboardService.keyboardEnabled = true;
  }

  public back(): void {
    this.backToCurrentColor.next();
  }
}
