import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { IdeaTypes } from 'src/app/shared/enums/idea-type.enum';
import { userName } from 'src/app/store/selectors/shared.selector';

import { BannerConfig } from '../../interfaces/banner-config';

@Component({
  selector: 'app-ideas-page',
  templateUrl: './ideas-page.component.html',
  styleUrls: ['./ideas-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IdeasPageComponent {
  public type: IdeaTypes;
  public types = IdeaTypes;

  public readonly banner3dConfig: BannerConfig = {
    title: 'IDEAS.3D_BANNER_TITLE',
    subTitle: 'IDEAS.3D_BANNER_SUBTITLE',
    buttonName: 'BUTTONS.START_NOW_FOR_FREE',
    image: './../../../assets/icons/Homepage_Promotion.png',
  };
  public user$: Observable<string> = this._store.select(userName);

  constructor(
    private _store: Store,
    private route: ActivatedRoute
  ) {
    this.type = route.snapshot.data['type'] as IdeaTypes;
  }
}
