import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { catchError, Observable, of } from 'rxjs';
import { Notification } from 'src/app/notifications/model/notification';
import { NotificationsService } from 'src/app/notifications/services/notifications.service';
import { IdeaTypes } from 'src/app/shared/enums/idea-type.enum';
import { IList } from 'src/app/shared/interfaces/list';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { environment } from 'src/environments/environment';
import { AUTH_CONTEXT } from 'src/services/context-tokens/intercept.context-token';

import { Idea } from '../interfaces/idea';
import { XimilarResponse } from '../interfaces/ximilar-response';

@Injectable()
export class IdeasHttpService {
  constructor(
    private _http: HttpClient,
    private _notificationsService: NotificationsService,
    private _translateService: TranslateService,
    private _loadingService: LoadingService
  ) {}

  public generateImage(
    tabType: IdeaTypes,
    image: File,
    roomType: string,
    style: string,
    specialWishes: string
  ): Observable<{ id: string }> {
    const params = {
      type: roomType || '',
      style: style || '',
      specialWishes: specialWishes || '',
    };

    const formData = new FormData();
    formData.append('image', image);
    formData.append('params', JSON.stringify(params));

    const url =
      tabType === IdeaTypes.DEFAULT ? environment.API_URL + 'ideas/generate/default' : environment.API_URL + 'ideas/generate/no-furniture';

    return this._http.post<{ id: string }>(url, formData, { context: AUTH_CONTEXT }).pipe(
      catchError(error => {
        if (error.status === 400) {
          this._notificationsService.addNotification(
            new Notification({
              title: this._translateService.instant('NOTIFICATIONS.TITLE.ERROR'),
              text: this._translateService.instant('NOTIFICATIONS.MESSAGES.INCORRECT_PROMPT'),
              level: 'error',
              options: { timeout: 2 },
            })
          );
        }
        this._loadingService.setLoading(false);
        return of();
      })
    );
  }

  public generateStyleSwitchImage(roomImage: File, styleImage: File): Observable<{ id: string }> {
    const formData = new FormData();
    formData.append('initImage ', roomImage);
    formData.append('styleImage', styleImage);

    return this._http.post<{ id: string }>(environment.API_URL + 'ideas/generate/styleswap', formData, { context: AUTH_CONTEXT }).pipe(
      catchError(error => {
        if (error.status === 400) {
          this._notificationsService.addNotification(
            new Notification({
              title: this._translateService.instant('NOTIFICATIONS.TITLE.ERROR'),
              text: error.message,
              level: 'error',
              options: { timeout: 2 },
            })
          );
        }
        this._loadingService.setLoading(false);
        return of();
      })
    );
  }

  public generationProgress(id: string): Observable<{ value: string }> {
    return this._http.get<{ value: string }>(environment.API_URL + `ideas/generate/${id}/status`, { context: AUTH_CONTEXT });
  }

  public getIdeas(pageIndex = 0, pageSize = 0, type: IdeaTypes): Observable<IList<Idea>> {
    const params = new HttpParams().set('size', pageSize).set('page', pageIndex).set('type', type).set('sort', 'creationDate,desc');
    return this._http.get<IList<Idea>>(environment.API_URL + `ideas`, { params, context: AUTH_CONTEXT });
  }

  public getIdeasByEmail(pageIndex = 0, pageSize = 0, type: IdeaTypes, email: string): Observable<IList<Idea>> {
    const params = new HttpParams().set('size', pageSize).set('page', pageIndex).set('type', type).set('sort', 'creationDate,desc');
    return this._http.get<IList<Idea>>(environment.API_URL + `ideas/user/${email}`, { params, context: AUTH_CONTEXT });
  }

  public loadResizedImage(link: string, width: number, height: number): Observable<Blob> {
    const params = new HttpParams().append('width', width).append('height', height);
    return this._http.get(environment.API_URL + `ideas/image/${link}/resized`, { params, responseType: 'blob', context: AUTH_CONTEXT });
  }

  public loadCroppedImage(link: string, width: number, height: number): Observable<Blob> {
    const params = new HttpParams().append('width', width).append('height', height);
    return this._http.get(environment.API_URL + `ideas/image/${link}/crop`, { params, responseType: 'blob', context: AUTH_CONTEXT });
  }

  public loadOriginalImage(link: string): Observable<Blob> {
    return this._http.get(environment.API_URL + `ideas/image/${link}`, { responseType: 'blob', context: AUTH_CONTEXT });
  }

  public searchInCatalog(image: File): Observable<XimilarResponse> {
    const formData = new FormData();
    formData.append('image', image);
    return this._http.post<XimilarResponse>(environment.API_URL + '3rdparty/ximilar/search', formData, { context: AUTH_CONTEXT });
  }

  public updateIdea(id: string): Observable<Idea> {
    return this._http.post<Idea>(environment.API_URL + `ideas/${id}/update`, {}, { context: AUTH_CONTEXT });
  }

  public removeIdea(id: string): Observable<void> {
    return this._http.delete<void>(environment.API_URL + `ideas/${id}`, { context: AUTH_CONTEXT });
  }
}
