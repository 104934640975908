import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { map, mergeMap, Observable, switchMap, tap } from 'rxjs';
import { UsersHttpService } from 'src/app/admin/services/users-http.service';
import { Notification } from 'src/app/notifications/model/notification';
import { NotificationsService } from 'src/app/notifications/services/notifications.service';
import { IList } from 'src/app/shared/interfaces/list';
import { IProject } from 'src/models/project';
import { ProjectsVersionsHttpService } from 'src/services/projects-versions-http.service';
import { ResourcesHttpService } from 'src/services/resources-http.service';

import { AdminActions } from '../action-types';
import { getUserProjectsSuccess, getUsersSuccess, removeProjectSuccess, userProjectSrcLoaded } from '../actions/admin.actions';
import { setOverlayLoadingSpinner } from '../actions/shared.actions';
import { User } from '../interfaces/user';

@UntilDestroy()
@Injectable()
export class AdminEffects {
  public loadUsers$ = createEffect(() =>
    this._actions$.pipe(
      ofType(AdminActions.getUsers),
      tap(() => this._store.dispatch(setOverlayLoadingSpinner({ status: true }))),
      switchMap(action => this._usersHttpService.getUsers(action.pageIndex, action.pageSize)),
      map((users: IList<User>) => {
        this._store.dispatch(setOverlayLoadingSpinner({ status: false }));
        return getUsersSuccess({ users });
      })
    )
  );

  public loadUserProjects$ = createEffect(() =>
    this._actions$.pipe(
      ofType(AdminActions.getUserProjects),
      tap(() => this._store.dispatch(setOverlayLoadingSpinner({ status: true }))),
      switchMap(action => this._usersHttpService.getUserProjects(action.email, action.pageIndex, action.pageSize, action.name)),
      map((projects: IList<IProject>) => {
        this._store.dispatch(setOverlayLoadingSpinner({ status: false }));
        const allProjects = projects.content;
        allProjects.forEach(proj => {
          if (proj.preview) {
            this._resourcesHttpService
              .loadCroppedResource(proj.preview, 320, 240)
              .pipe(
                map(blob => {
                  return { id: proj.id, blob };
                }),
                mergeMap((data: { id: string; blob: Blob }) => {
                  return new Observable<{ id: string; src: string }>(subscriber => {
                    const src = URL.createObjectURL(data.blob);
                    subscriber.next({ src, id: data.id });
                  });
                }),
                untilDestroyed(this)
              )
              .subscribe((data: { id: string; src: string }) => this._store.dispatch(userProjectSrcLoaded({ data })));
          }
        });
        return getUserProjectsSuccess({ projects: projects });
      })
    )
  );

  public removeProject$ = createEffect(() =>
    this._actions$.pipe(
      ofType(AdminActions.removeProject),
      tap(() => this._store.dispatch(setOverlayLoadingSpinner({ status: true }))),
      switchMap(action => this._projectsVersionsHttpService.removeProject(action.id).pipe(map(() => action.id))),
      map(id => {
        this._notificationsService.addNotification(
          new Notification({
            title: this._translateService.instant('NOTIFICATIONS.TITLE.SUCCESS'),
            text: this._translateService.instant('NOTIFICATIONS.MESSAGES.PROJECT_REMOVED'),
            level: 'success',
            options: { timeout: 2 },
          })
        );
        this._store.dispatch(setOverlayLoadingSpinner({ status: false }));
        return removeProjectSuccess({ id });
      })
    )
  );

  constructor(
    private _actions$: Actions,
    private _notificationsService: NotificationsService,
    private _usersHttpService: UsersHttpService,
    private _store: Store,
    private _translateService: TranslateService,
    private _resourcesHttpService: ResourcesHttpService,
    private _projectsVersionsHttpService: ProjectsVersionsHttpService
  ) {}
}
