import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IdeasState } from '../ideas.state';

export const selectIdeasState = createFeatureSelector<IdeasState>('ideas');

export const ideas = createSelector(selectIdeasState, state => state.ideas?.content);

export const ideasTotalElements = createSelector(selectIdeasState, state => state.ideas?.totalElements);

export const ideasPageSize = createSelector(selectIdeasState, state => state.ideas?.size);

export const ideasPages = createSelector(selectIdeasState, state => (state.ideas ? state.ideas.totalElements / state.ideas.size : null));

export const activeIdea = createSelector(selectIdeasState, state => state.activeIdea);

export const areIdeasLoaded = createSelector(selectIdeasState, state => state.ideasLoaded);

export const searchResults = createSelector(selectIdeasState, state => state.searchResults);

export const ideaFurniture = createSelector(selectIdeasState, state => state.ideasSimilarFurniture);

export const ideaFurnitureDetails = createSelector(selectIdeasState, state => state.furnitureDetails);

export const ideaFurnitureDetailsDescription = createSelector(selectIdeasState, state => state.furnitureDetails.descriptionTranslate);
