import { CatalogData } from 'src/services/model/proposal';

import { Idea } from '../ideas/interfaces/idea';
import { XimilarResponse } from '../ideas/interfaces/ximilar-response';
import { IList } from '../shared/interfaces/list';

export interface IdeasState {
  ideas: IList<Idea>;
  activeIdea: Idea;
  ideasLoaded: boolean;
  searchResults: { res: XimilarResponse; coord: { top: number; left: number } }[];
  ideasSimilarFurniture: CatalogData[];
  furnitureDetails: { article: CatalogData; descriptionTranslate: string };
}

export const initialIdeasState: IdeasState = {
  ideas: null,
  activeIdea: null,
  ideasLoaded: false,
  searchResults: null,
  ideasSimilarFurniture: null,
  furnitureDetails: null,
};
