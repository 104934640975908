import { Event, Object3D, PointLight, SpotLight, Vector3 } from 'three';

const HSVSettings = {
  h: 0.15,
  s: 0.25,
  l: 1,
};

export function getPointLightSource(position: Vector3, name?: string): Object3D {
  const lightSource: PointLight = new PointLight(0xffffff, 1, 100, 2);

  lightSource.color.setHSL(HSVSettings.h, HSVSettings.s, HSVSettings.l);

  lightSource.position.copy(position);
  lightSource.shadow.camera.near = 0.1;
  lightSource.shadow.camera.far = 40;
  lightSource.shadow.bias = -0.002;
  lightSource.shadow.mapSize.set(1024 * 2, 1024 * 2);
  if (name) {
    lightSource.name = name;
  }
  return lightSource;
}

export function getSpotLightSource(position: Vector3, name: string, targetY: number): Object3D {
  const lightSource: SpotLight = new SpotLight(0xffffff);
  lightSource.color.setHSL(HSVSettings.h, HSVSettings.s, HSVSettings.l);
  lightSource.position.copy(position);

  const targetObject = new Object3D();
  targetObject.position.copy(position.clone().setY(targetY));
  lightSource.target = targetObject;

  lightSource.shadow.camera.near = 0.1;
  lightSource.shadow.camera.far = 40;
  lightSource.shadow.bias = -0.002;
  lightSource.shadow.mapSize.set(1024 * 2, 1024 * 2);

  if (name) {
    lightSource.name = name;
  }

  return lightSource;
}

export function patchSpotLightTargetPosition(light: Object3D<Event>): void {
  if (light instanceof SpotLight) {
    light.target.position.setX(light.position.x);
    light.target.position.setZ(light.position.z);

    light.target.updateMatrixWorld();
    light.target.updateMatrix();
    light.updateMatrix();
    light.updateMatrixWorld();
  }
}
