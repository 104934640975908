import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { loadAllVersions, removeVersion } from 'src/app/store/actions/versions.actions';
import { activeUserEmail } from 'src/app/store/selectors/admin.selectors';
import { versionsForProject, versionsTotalElements } from 'src/app/store/selectors/projects.selector';
import { IVersion } from 'src/models/project';

@UntilDestroy()
@Component({
  selector: 'app-project-page',
  templateUrl: './project-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectPageComponent implements OnInit {
  public versions$: Observable<IVersion[]>;
  public totalElements$: Observable<number>;
  public searchInput: FormControl = new FormControl();

  private _projectId: string;
  public size = 6;

  public get projectId(): string {
    return this._projectId;
  }

  @ViewChild(MatPaginator)
  public paginator: MatPaginator;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _store: Store
  ) {}

  public ngOnInit(): void {
    this._projectId = this._route.snapshot.paramMap.get('id');
    this.getVersions({ page: 0, size: 6, name: null });
  }

  public editVersion(version: IVersion): void {
    this._router.navigate(['reconstruct-editor'], { queryParams: { projectId: this._projectId, versionId: version.id } });
  }

  public removeVersion(data: { versionId: string; pageIndex: number; pageSize: number }): void {
    this._store.dispatch(
      removeVersion({ versionId: data.versionId, projectId: this._projectId, pageSize: data.pageSize, pageIndex: data.pageIndex })
    );
    this.versions$ = this._store.select(versionsForProject);
  }

  public getVersions(data: { page: number; size: number; name: string }): void {
    if (data.name) {
      this._store.dispatch(loadAllVersions({ id: this._projectId, pageIndex: data.page, pageSize: data.size, name: data.name }));
    } else {
      this._store.dispatch(loadAllVersions({ id: this._projectId, pageIndex: data.page, pageSize: data.size }));
    }
    this.versions$ = this._store.select(versionsForProject);
    this.totalElements$ = this._store.select(versionsTotalElements(this._projectId));
  }

  public back(): void {
    this._store
      .select(activeUserEmail)
      .pipe(untilDestroyed(this))
      .subscribe(email => {
        this._router.navigate(['result', 'projects'], { queryParams: { email } });
      });
  }
}
