import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { Vector3 } from 'three';

import { Option } from '../catalog/catalog-details/option.model';
import { MovingObjectsService } from '../services/moving-objects.service';
import { ResolutionService } from '../services/resolution.service';
import { RoomService } from '../services/room.service';

@UntilDestroy()
@Component({
  selector: 'app-movement-dialog',
  templateUrl: './movement-dialog.component.html',
  styleUrls: ['./movement-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MovementDialogComponent implements OnInit {
  public options: Option[] = [
    {
      name: 'USER_FLOW.DETAILS_PAGE.HORIZONTAL',
      actionPositive: (): void => {
        this._uuid && this._movingObjectsService.move(this._uuid, new Vector3(1, 0, 0));
      },
      actionNegative: (): void => {
        this._uuid && this._movingObjectsService.move(this._uuid, new Vector3(-1, 0, 0));
      },
      iconSrc: './../../../../assets/icons/Gruppe 3189.svg',
    },
    {
      name: 'USER_FLOW.DETAILS_PAGE.DEPTH',
      actionPositive: (): void => {
        this._uuid && this._movingObjectsService.move(this._uuid, new Vector3(0, 0, -1));
      },
      actionNegative: (): void => {
        this._uuid && this._movingObjectsService.move(this._uuid, new Vector3(0, 0, 1));
      },
      iconSrc: './../../../../assets/icons/Gruppe 3190.svg',
    },
    {
      name: 'USER_FLOW.DETAILS_PAGE.VERTICAL',
      actionPositive: (): void => {
        this._uuid && this._movingObjectsService.move(this._uuid, new Vector3(0, 1, 0));
      },
      actionNegative: (): void => {
        this._uuid && this._movingObjectsService.move(this._uuid, new Vector3(0, -1, 0));
      },
      iconSrc: './../../../../assets/icons/Gruppe 3188.svg',
    },
    {
      name: 'USER_FLOW.DETAILS_PAGE.SIZE',
      actionPositive: (): void => {
        this._uuid && this._movingObjectsService.scale(this._uuid, new Vector3(1, 1, 1));
      },
      actionNegative: (): void => {
        this._uuid && this._movingObjectsService.scale(this._uuid, new Vector3(-1, -1, -1));
      },
      iconSrc: './../../../../assets/icons/Gruppe 3169.svg',
    },
    {
      name: 'USER_FLOW.DETAILS_PAGE.ROTATION',
      actionPositive: (): void => {
        this._uuid && this._movingObjectsService.rotate(this._uuid, 1);
      },
      actionNegative: (): void => {
        this._uuid && this._movingObjectsService.rotate(this._uuid, -1);
      },
      iconSrc: './../../../../assets/icons/Gruppe 3124.svg',
    },
  ];
  public advancedOptions: Option[] = [
    {
      name: 'USER_FLOW.DETAILS_PAGE.HEIGHT',
      actionPositive: (): void => {
        this._uuid && this._movingObjectsService.scale(this._uuid, new Vector3(0, 1, 0));
      },
      actionNegative: (): void => {
        this._uuid && this._movingObjectsService.scale(this._uuid, new Vector3(0, -1, 0));
      },
      iconSrc: './../../../../assets/icons/Gruppe 3127.svg',
    },
    {
      name: 'USER_FLOW.DETAILS_PAGE.WIDTH',
      actionPositive: (): void => {
        this._uuid && this._movingObjectsService.scale(this._uuid, new Vector3(1, 0, 0));
      },
      actionNegative: (): void => {
        this._uuid && this._movingObjectsService.scale(this._uuid, new Vector3(-1, 0, 0));
      },
      iconSrc: './../../../../assets/icons/Gruppe 3129.svg',
    },
    {
      name: 'USER_FLOW.DETAILS_PAGE.LENGTH',
      actionPositive: (): void => {
        this._uuid && this._movingObjectsService.scale(this._uuid, new Vector3(0, 0, 1));
      },
      actionNegative: (): void => {
        this._uuid && this._movingObjectsService.scale(this._uuid, new Vector3(0, 0, -1));
      },
      iconSrc: './../../../../assets/icons/Gruppe 3128.svg',
    },
  ];

  public optionsMobileFirst: Option[];
  public optionsMobileSecond: Option[];

  private _uuid: string;

  constructor(
    private _store: Store,
    private _movingObjectsService: MovingObjectsService,
    private _roomService: RoomService,
    public resolutionService: ResolutionService
  ) {}

  public ngOnInit(): void {
    this.optionsMobileFirst = [this.options[0], ...this.options.slice(2)];
    this.optionsMobileSecond = [this.options[1], ...this.advancedOptions];

    this._roomService.selectedMovingObjectId$.pipe(untilDestroyed(this)).subscribe(id => (this._uuid = id));
  }

  public flip(): void {
    this._movingObjectsService.flip(this._uuid);
  }
}
