import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { sendOffer } from 'src/app/store/actions/shared.actions';

import { RequestForm } from './request-form';
import { Topics } from '../render/enum/topic.enum';

@UntilDestroy()
@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequestComponent {
  public readonly topics = [
    { value: Topics.ADVICE, viewValue: 'USER_FLOW.REQUEST_PAGE.ADVICE' },
    { value: Topics.OFFER, viewValue: 'USER_FLOW.REQUEST_PAGE.OFFER' },
    { value: Topics.SUPPORT, viewValue: 'USER_FLOW.REQUEST_PAGE.SUPPORT' },
  ];
  public form: FormGroup<RequestForm> = new FormGroup<RequestForm>({
    topic: new FormControl(null, [Validators.required]),
    mobilePhone: new FormControl(null, [Validators.required]),
    address: new FormControl(null),
    comment: new FormControl(null),
  });

  constructor(private _store: Store) {}

  public submit(): void {
    this.form.updateValueAndValidity();
    if (this.form.valid) {
      const dto = this.form.getRawValue();
      this._store.dispatch(sendOffer({ data: dto }));
    }
  }
}
