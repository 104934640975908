import { Vector3 } from 'three';

import { curtainsAlgorithm } from './common';
import { MetersSize, PositioningElement } from './positioning-element.model';
import { RoomSchema } from './schema.model';
import { TableType } from './table-type.enum';
import { FurnitureType } from '../../enum/furniture-types.enum';
import { getForwardDirection, getObjectSize, getRightDirection, getRotation, moveModel } from '../autopositioning.function';
import { MovingObject } from '../model/dto/moving-object.model';

const LAMP_ABOVE_FLOOR = 1.6;

const diningTable: PositioningElement = {
  type: FurnitureType.DINING_TABLE,
  rules: [
    {
      action: 'SET_AT_WALL',
      distance: size => size.width / 2 + 0.7,
    },
    {
      action: 'MOVE',
      forward: size => size.depth / 2 + 0.7,
    },
    {
      action: 'LOOK_AT',
      value: 'FORWARD',
    },
  ],
};

function handleRectangularTable(
  chairs,
  userData: {
    table: MovingObject;
    wallCorners: [Vector3, Vector3];
    gap: number;
    roomCenter: Vector3;
  },
  meterPerPixel: number
): void {
  const chairSize = getObjectSize(chairs[0].object3D);
  const chairMetersSize: MetersSize = {
    width: chairSize.x * meterPerPixel,
    height: chairSize.y * meterPerPixel,
    depth: chairSize.z * meterPerPixel,
  };
  const tableSize = getObjectSize(userData.table.object3D);
  const tableMetersSize: MetersSize = {
    width: tableSize.x * meterPerPixel,
    height: tableSize.y * meterPerPixel,
    depth: tableSize.z * meterPerPixel,
  };
  const tablePosition = userData.table.object3D.position;
  const padding = (tableSize.x - (chairSize.x * chairs.length) / 2 - userData.gap) / 2;
  const startPosition = tablePosition.clone().add(
    getRightDirection(userData.wallCorners)
      .negate()
      .normalize()
      .multiplyScalar(tableSize.x / 2 - padding - chairSize.x / 2)
  );
  for (let i = 0; i < chairs.length; i++) {
    const current = chairs[i].object3D;
    const j = i % (chairs.length / 2);

    const currentPosition = startPosition.clone().add(
      getRightDirection(userData.wallCorners)
        .normalize()
        .multiplyScalar(j * (chairSize.x + userData.gap))
    );
    current.position.set(currentPosition.x, currentPosition.y, currentPosition.z);

    const movements =
      i < chairs.length / 2
        ? { forward: (): number => tableMetersSize.depth / 2 + chairMetersSize.depth / 2 }
        : { backward: (): number => tableMetersSize.depth / 2 + chairMetersSize.depth / 2 };

    const rotation =
      i < chairs.length / 2
        ? getRotation(current.position, userData.wallCorners).backward
        : getRotation(current.position, userData.wallCorners).forward;

    moveModel(current, chairMetersSize, movements, userData.wallCorners, userData.roomCenter, 1 / meterPerPixel);
    current.lookAt(rotation);
  }
}

function handleCircleTable(
  chairs,
  userData: {
    table: MovingObject;
    wallCorners: [Vector3, Vector3];
    gap: number;
    roomCenter: Vector3;
  }
): void {
  if (chairs.length === 4) {
    handleSquareTable(chairs, userData);
  } else {
    const chairSize = getObjectSize(chairs[0].object3D);
    const tableSize = getObjectSize(userData.table.object3D);
    const tablePosition = userData.table.object3D.position;
    const left1Position = tablePosition
      .clone()
      .add(
        getRightDirection(userData.wallCorners)
          .negate()
          .normalize()
          .multiplyScalar(tableSize.x / 2 + chairSize.x / 2)
      )
      .add(
        getForwardDirection(tablePosition, userData.wallCorners, userData.roomCenter)
          .vector.negate()
          .normalize()
          .multiplyScalar(tableSize.z / 6 + chairSize.z / 2)
      );
    const left2Position = tablePosition
      .clone()
      .add(
        getRightDirection(userData.wallCorners)
          .negate()
          .normalize()
          .multiplyScalar(tableSize.x / 2 + chairSize.x / 2)
      )
      .add(
        getForwardDirection(tablePosition, userData.wallCorners, userData.roomCenter)
          .vector.normalize()
          .multiplyScalar(tableSize.z / 6 + chairSize.z / 2)
      );
    const right1Position = tablePosition
      .clone()
      .add(
        getRightDirection(userData.wallCorners)
          .normalize()
          .multiplyScalar(tableSize.x / 2 + chairSize.x / 2)
      )
      .add(
        getForwardDirection(tablePosition, userData.wallCorners, userData.roomCenter)
          .vector.negate()
          .normalize()
          .multiplyScalar(tableSize.z / 6 + chairSize.z / 2)
      );
    const right2Position = tablePosition
      .clone()
      .add(
        getRightDirection(userData.wallCorners)
          .normalize()
          .multiplyScalar(tableSize.x / 2 + chairSize.x / 2)
      )
      .add(
        getForwardDirection(tablePosition, userData.wallCorners, userData.roomCenter)
          .vector.normalize()
          .multiplyScalar(tableSize.z / 6 + chairSize.z / 2)
      );
    const forwardPosition = tablePosition.clone().add(
      getForwardDirection(tablePosition, userData.wallCorners, userData.roomCenter)
        .vector.normalize()
        .multiplyScalar(tableSize.y / 2 + chairSize.y / 2)
    );
    const backwardPosition = tablePosition.clone().add(
      getForwardDirection(tablePosition, userData.wallCorners, userData.roomCenter)
        .vector.negate()
        .normalize()
        .multiplyScalar(tableSize.y / 2 + chairSize.y / 2)
    );

    if (chairs.length >= 6) {
      chairs[0].object3D.position.set(left1Position.x, left1Position.y, left1Position.z);
      chairs[0].object3D.lookAt(tablePosition);
      chairs[1].object3D.position.set(right1Position.x, right1Position.y, right1Position.z);
      chairs[1].object3D.lookAt(tablePosition);
      chairs[2].object3D.position.set(left2Position.x, left2Position.y, left2Position.z);
      chairs[2].object3D.lookAt(tablePosition);
      chairs[3].object3D.position.set(right2Position.x, right2Position.y, right2Position.z);
      chairs[3].object3D.lookAt(tablePosition);
      chairs[4].object3D.position.set(forwardPosition.x, forwardPosition.y, forwardPosition.z);
      chairs[4].object3D.lookAt(tablePosition);
      chairs[5].object3D.position.set(backwardPosition.x, backwardPosition.y, backwardPosition.z);
      chairs[5].object3D.lookAt(tablePosition);
    } else {
      chairs[0].object3D.position.set(backwardPosition.x, backwardPosition.y, backwardPosition.z);
      chairs[0].object3D.lookAt(tablePosition);
      chairs[1].object3D.position.set(right2Position.x, right2Position.y, right2Position.z);
      chairs[1].object3D.lookAt(tablePosition);
      chairs[2].object3D.position.set(left2Position.x, left2Position.y, left2Position.z);
      chairs[2].object3D.lookAt(tablePosition);
    }
  }
}

function handleSquareTable(
  chairs: MovingObject[],
  userData: {
    table: MovingObject;
    wallCorners: [Vector3, Vector3];
    gap: number;
    roomCenter: Vector3;
  }
): void {
  const chairSize = getObjectSize(chairs[0].object3D);
  const tableSize = getObjectSize(userData.table.object3D);
  const tablePosition = userData.table.object3D.position;
  const leftPosition = tablePosition.clone().add(
    getRightDirection(userData.wallCorners)
      .negate()
      .normalize()
      .multiplyScalar(tableSize.x / 2 + chairSize.x / 2)
  );
  const rightPosition = tablePosition.clone().add(
    getRightDirection(userData.wallCorners)
      .normalize()
      .multiplyScalar(tableSize.x / 2 + chairSize.x / 2)
  );
  const forwardPosition = tablePosition.clone().add(
    getForwardDirection(tablePosition, userData.wallCorners, userData.roomCenter)
      .vector.normalize()
      .multiplyScalar(tableSize.y / 2 + chairSize.y / 2)
  );
  const backwardPosition = tablePosition.clone().add(
    getForwardDirection(tablePosition, userData.wallCorners, userData.roomCenter)
      .vector.negate()
      .normalize()
      .multiplyScalar(tableSize.y / 2 + chairSize.y / 2)
  );

  if (chairs.length >= 4) {
    chairs[0].object3D.position.set(leftPosition.x, leftPosition.y, leftPosition.z);
    chairs[0].object3D.lookAt(tablePosition);
    chairs[1].object3D.position.set(rightPosition.x, rightPosition.y, rightPosition.z);
    chairs[1].object3D.lookAt(tablePosition);
    chairs[2].object3D.position.set(forwardPosition.x, forwardPosition.y, forwardPosition.z);
    chairs[2].object3D.lookAt(tablePosition);
    chairs[3].object3D.position.set(backwardPosition.x, backwardPosition.y, backwardPosition.z);
    chairs[3].object3D.lookAt(tablePosition);
  }
}

const diningChair: PositioningElement = {
  type: FurnitureType.DINING_TABLE_CHAIR,
  rules: [
    {
      action: 'SET_CUSTOM',
      callback: (
        chairs,
        userData: {
          table: MovingObject;
          tableType: TableType;
          wallCorners: [Vector3, Vector3];
          gap: number;
          roomCenter: Vector3;
        },
        meterPerPixel: number
      ): void => {
        if (userData?.table) {
          switch (userData.tableType) {
            case TableType.RECTANGULAR:
              handleRectangularTable(chairs, userData, meterPerPixel);
              break;
            case TableType.CIRCLE:
              handleCircleTable(chairs, userData);
              break;
            case TableType.SQUARE:
              handleSquareTable(chairs, userData);
              break;
          }
        }
      },
    },
  ],
};

const carpet: PositioningElement = {
  type: FurnitureType.CARPET,
  rules: [
    {
      action: 'MOVE',
      relatedObject: { inAction: diningTable },
      up: size => size.height,
    },
    {
      action: 'ROTATE',
      condition: size => size.depth > size.width,
      relatedObject: { inAction: diningTable },
      asix: 'y',
      value: 90,
    },
  ],
};

const pedantLamp: PositioningElement = {
  type: FurnitureType.LAMP,
  rules: [
    {
      action: 'MOVE',
      relatedObject: { inAction: diningTable },
      up: (size, relatedSize) => size.height / 2 - relatedSize.height / 2,
    },
    {
      action: 'SET_CUSTOM',
      callback: (pedantLamps, { floorY, heightMeters }, meterPerPixel): void => {
        if (pedantLamps?.length > 0 && floorY && heightMeters) {
          const newLampY = LAMP_ABOVE_FLOOR / meterPerPixel + floorY;

          pedantLamps[0].object3D.position.setY(newLampY);
        }
      },
    },
  ],
};

const image: PositioningElement = {
  type: FurnitureType.PAINTING,
  rules: [
    {
      action: 'MOVE',
      index: 0,
      relatedObject: { inAction: diningTable },
      right: (size, relatedSize) => relatedSize.width / 8,
      backward: (size, relatedSize) => relatedSize.depth / 2 + 0.7,
    },
    {
      action: 'MOVE',
      index: 1,
      relatedObject: { inAction: 0 },
      left: (size, relatedSize) => relatedSize.width / 2 + size.width / 2 + 0.1,
      up: () => 0.1,
    },
    {
      action: 'SET_CUSTOM',
      callback: (images, { heightMeters }): void => {
        if (images && heightMeters) {
          images.forEach(image => image.object3D.position.add(new Vector3(0, heightMeters / 2, 0)));
        }
      },
    },
    {
      action: 'LOOK_AT',
      value: 'FORWARD',
    },
  ],
};

const curtain: PositioningElement = {
  type: FurnitureType.CURTAIN,
  rules: [
    {
      action: 'SET_CUSTOM',
      callback: curtainsAlgorithm,
    },
  ],
};

export const DINING_ROOM_SCHEMA: RoomSchema = {
  elements: [diningTable, diningChair, carpet, pedantLamp, image, curtain],
};
