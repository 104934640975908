import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from 'src/app/ideas/interfaces/dialog';

import { DetailsPanelComponent } from '../details-panel/details-panel.component';
import { LeftPanelState } from '../left-panel-state.enum';

@Component({
  selector: 'app-idea-furniture-mobile',
  templateUrl: './idea-furniture-mobile.component.html',
  styleUrls: ['./idea-furniture-mobile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IdeaFurnitureMobileComponent implements OnInit {
  public pageType: LeftPanelState;
  public leftPanelStates = LeftPanelState;

  @ViewChild('detailsPage')
  private _detailsPage: DetailsPanelComponent;
  constructor(
    private _dialogRef: MatDialogRef<IdeaFurnitureMobileComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  public ngOnInit(): void {
    this.pageType = this.data.furnitureCount === 1 ? LeftPanelState.DETAILS_PANEL : LeftPanelState.FURNITURE_LIST;
  }

  public close(): void {
    this._dialogRef.close();
  }

  public openDetailsEvent(): void {
    this.pageType = LeftPanelState.DETAILS_PANEL;
  }

  public openFurnitureList(): void {
    this.pageType = LeftPanelState.FURNITURE_LIST;
  }

  public tryClose(): void {
    const shouldCloseDialog = !this._detailsPage?.selectedVariant;

    if (shouldCloseDialog) {
      this._dialogRef.close();
    } else {
      this._detailsPage?.backFromVariant(null);
    }
  }
}
