import { RoomType } from '../enum/room-type.enum';
import { Style } from '../enum/style.enum';
import { StylefinderTypes } from '../enum/stylefinder-types';
import { IStyle } from '../interfaces/styles';

export const STYLES: IStyle[] = [
  {
    id: 1,
    image: '../../../assets/styles-dining-room/asia_new_scandi.jpg',
    style: Style.WABI_SABI,
    roomType: RoomType.DINING,
    type: StylefinderTypes.PREDEFINED,
  },
  {
    id: 2,
    image: '../../../assets/styles-dining-room/EZ1Industrial.jpg',
    style: Style.INDUSTRIAL,
    roomType: RoomType.DINING,
    type: StylefinderTypes.PREDEFINED,
  },
  {
    id: 3,
    image: '../../../assets/styles-dining-room/EZ2_Industrial.jpg',
    style: Style.INDUSTRIAL,
    roomType: RoomType.DINING,
    type: StylefinderTypes.PREDEFINED,
  },
  {
    id: 4,
    image: '../../../assets/styles-dining-room/EZ3_NewScandi.jpg',
    style: Style.NEW_SCANDINAVIAN,
    roomType: RoomType.DINING,
    type: StylefinderTypes.PREDEFINED,
  },
  {
    id: 5,
    image: '../../../assets/styles-dining-room/EZ4_NewScandi.jpg',
    style: Style.NEW_SCANDINAVIAN,
    roomType: RoomType.DINING,
    type: StylefinderTypes.PREDEFINED,
  },
  {
    id: 6,
    image: '../../../assets/styles-dining-room/EZ5_WabiSabi_Asia.jpg',
    style: Style.WABI_SABI,
    roomType: RoomType.DINING,
    type: StylefinderTypes.PREDEFINED,
  },
  {
    id: 7,
    image: '../../../assets/styles-dining-room/EZ6_WabiSabi_Asia.jpg',
    style: Style.WABI_SABI,
    roomType: RoomType.DINING,
    type: StylefinderTypes.PREDEFINED,
  },
  {
    id: 8,
    image: '../../../assets/styles-dining-room/EZ7_ModernCoutnry.jpg',
    style: Style.MODERN_COUNTRY,
    roomType: RoomType.DINING,
    type: StylefinderTypes.PREDEFINED,
  },
  {
    id: 9,
    image: '../../../assets/styles-dining-room/EZ8_moderncoutnry.jpg',
    style: Style.MODERN_COUNTRY,
    roomType: RoomType.DINING,
    type: StylefinderTypes.PREDEFINED,
  },
  {
    id: 10,
    image: '../../../assets/styles-dining-room/EZ9_BohoChic.jpg',
    style: Style.BOHO_CHIC,
    roomType: RoomType.DINING,
    type: StylefinderTypes.PREDEFINED,
  },
  {
    id: 11,
    image: '../../../assets/styles-dining-room/EZ10_BohoChic.jpg',
    style: Style.BOHO_CHIC,
    roomType: RoomType.DINING,
    type: StylefinderTypes.PREDEFINED,
  },
  {
    id: 12,
    image: '../../../assets/styles-dining-room/industrial_moderncountry1.jpg',
    style: Style.MODERN_COUNTRY,
    roomType: RoomType.DINING,
    type: StylefinderTypes.PREDEFINED,
  },
  {
    id: 13,
    image: '../../../assets/styles-dining-room/industrial.jpg',
    style: Style.INDUSTRIAL,
    roomType: RoomType.DINING,
    type: StylefinderTypes.PREDEFINED,
  },
  {
    id: 14,
    image: '../../../assets/styles-dining-room/industrial1_boho_wabisabi.jpg',
    style: Style.INDUSTRIAL,
    roomType: RoomType.DINING,
    type: StylefinderTypes.PREDEFINED,
  },
  {
    id: 15,
    image: '../../../assets/styles-dining-room/Industrial1.jpg',
    style: Style.INDUSTRIAL,
    roomType: RoomType.DINING,
    type: StylefinderTypes.PREDEFINED,
  },
  {
    id: 16,
    image: '../../../assets/styles-dining-room/modern_country1_wabisabi_new_scandi.jpg',
    style: Style.MODERN_COUNTRY,
    roomType: RoomType.DINING,
    type: StylefinderTypes.PREDEFINED,
  },
  {
    id: 17,
    image: '../../../assets/styles-dining-room/new_scandi1_wabisabi.jpg',
    style: Style.NEW_SCANDINAVIAN,
    roomType: RoomType.DINING,
    type: StylefinderTypes.PREDEFINED,
  },
  {
    id: 18,
    image: '../../../assets/styles-dining-room/scandi1_boho.jpg',
    style: Style.NEW_SCANDINAVIAN,
    roomType: RoomType.DINING,
    type: StylefinderTypes.PREDEFINED,
  },
  {
    id: 19,
    image: '../../../assets/styles-dining-room/wabisabi1_modern_country.jpg',
    style: Style.WABI_SABI,
    roomType: RoomType.DINING,
    type: StylefinderTypes.PREDEFINED,
  },
  {
    id: 20,
    image: '../../../assets/styles-dining-room/wabisabi1_new_scandi.jpg',
    style: Style.WABI_SABI,
    roomType: RoomType.DINING,
    type: StylefinderTypes.PREDEFINED,
  },
  {
    id: 21,
    image: '../../../assets/styles-living-room/asia_new_scandi1.jpg',
    style: Style.NEW_SCANDINAVIAN,
    roomType: RoomType.LIVING,
    type: StylefinderTypes.PREDEFINED,
  },
  {
    id: 22,
    image: '../../../assets/styles-living-room/boho_chic_2.jpg',
    style: Style.BOHO_CHIC,
    roomType: RoomType.LIVING,
    type: StylefinderTypes.PREDEFINED,
  },
  {
    id: 23,
    image: '../../../assets/styles-living-room/boho_chic_3.jpg',
    style: Style.BOHO_CHIC,
    roomType: RoomType.LIVING,
    type: StylefinderTypes.PREDEFINED,
  },
  {
    id: 24,
    image: '../../../assets/styles-living-room/boho_chic.jpg',
    style: Style.BOHO_CHIC,
    roomType: RoomType.LIVING,
    type: StylefinderTypes.PREDEFINED,
  },
  {
    id: 25,
    image: '../../../assets/styles-living-room/boho1_marroc.jpg',
    style: Style.BOHO_CHIC,
    roomType: RoomType.LIVING,
    type: StylefinderTypes.PREDEFINED,
  },
  {
    id: 26,
    image: '../../../assets/styles-living-room/boho1_scandi.jpg',
    style: Style.BOHO_CHIC,
    roomType: RoomType.LIVING,
    type: StylefinderTypes.PREDEFINED,
  },
  {
    id: 27,
    image: '../../../assets/styles-living-room/industrial.jpg',
    style: Style.INDUSTRIAL,
    roomType: RoomType.LIVING,
    type: StylefinderTypes.PREDEFINED,
  },
  {
    id: 28,
    image: '../../../assets/styles-living-room/industrial1_boho_chic.jpg',
    style: Style.INDUSTRIAL,
    roomType: RoomType.LIVING,
    type: StylefinderTypes.PREDEFINED,
  },
  {
    id: 29,
    image: '../../../assets/styles-living-room/industrial1_boho_wabisabi.jpg',
    style: Style.INDUSTRIAL,
    roomType: RoomType.LIVING,
    type: StylefinderTypes.PREDEFINED,
  },
  {
    id: 30,
    image: '../../../assets/styles-living-room/industrial1.jpg',
    style: Style.INDUSTRIAL,
    roomType: RoomType.LIVING,
    type: StylefinderTypes.PREDEFINED,
  },
  {
    id: 31,
    image: '../../../assets/styles-living-room/industrial2.jpg',
    style: Style.INDUSTRIAL,
    roomType: RoomType.LIVING,
    type: StylefinderTypes.PREDEFINED,
  },
  {
    id: 32,
    image: '../../../assets/styles-living-room/modern_country.jpg',
    style: Style.MODERN_COUNTRY,
    roomType: RoomType.LIVING,
    type: StylefinderTypes.PREDEFINED,
  },
  {
    id: 33,
    image: '../../../assets/styles-living-room/modern_country1_new_scandi.jpg',
    style: Style.MODERN_COUNTRY,
    roomType: RoomType.LIVING,
    type: StylefinderTypes.PREDEFINED,
  },
  {
    id: 34,
    image: '../../../assets/styles-living-room/modern_country1_wabisabi_new_scandi.jpg',
    style: Style.MODERN_COUNTRY,
    roomType: RoomType.LIVING,
    type: StylefinderTypes.PREDEFINED,
  },
  {
    id: 35,
    image: '../../../assets/styles-living-room/modern_country1.jpg',
    style: Style.MODERN_COUNTRY,
    roomType: RoomType.LIVING,
    type: StylefinderTypes.PREDEFINED,
  },
  {
    id: 36,
    image: '../../../assets/styles-living-room/new_scandi1_with_a_touch_of_boho.jpg',
    style: Style.NEW_SCANDINAVIAN,
    roomType: RoomType.LIVING,
    type: StylefinderTypes.PREDEFINED,
  },
  {
    id: 37,
    image: '../../../assets/styles-living-room/new_scandinavian.jpg',
    style: Style.NEW_SCANDINAVIAN,
    roomType: RoomType.LIVING,
    type: StylefinderTypes.PREDEFINED,
  },
  {
    id: 38,
    image: '../../../assets/styles-living-room/scandi1_boho.jpg',
    style: Style.NEW_SCANDINAVIAN,
    roomType: RoomType.LIVING,
    type: StylefinderTypes.PREDEFINED,
  },
  {
    id: 39,
    image: '../../../assets/styles-living-room/wabisabi_newscandinavian1.jpg',
    style: Style.NEW_SCANDINAVIAN,
    roomType: RoomType.LIVING,
    type: StylefinderTypes.PREDEFINED,
  },
  {
    id: 40,
    image: '../../../assets/styles-living-room/wabisabi1_colonial_marroc.jpg',
    style: Style.WABI_SABI,
    roomType: RoomType.LIVING,
    type: StylefinderTypes.PREDEFINED,
  },
  {
    id: 41,
    image: '../../../assets/styles-living-room/wabisabi1_scandi.jpg',
    style: Style.WABI_SABI,
    roomType: RoomType.LIVING,
    type: StylefinderTypes.PREDEFINED,
  },
  {
    id: 42,
    image: '../../../assets/styles-living-room/WZ1_Industria.jpg',
    style: Style.INDUSTRIAL,
    roomType: RoomType.LIVING,
    type: StylefinderTypes.PREDEFINED,
  },
  {
    id: 43,
    image: '../../../assets/styles-living-room/WZ2_Industrial.jpg',
    style: Style.INDUSTRIAL,
    roomType: RoomType.LIVING,
    type: StylefinderTypes.PREDEFINED,
  },
  {
    id: 44,
    image: '../../../assets/styles-living-room/WZ4_new_scandinavian.jpg',
    style: Style.NEW_SCANDINAVIAN,
    roomType: RoomType.LIVING,
    type: StylefinderTypes.PREDEFINED,
  },
  {
    id: 45,
    image: '../../../assets/styles-living-room/WZ5_WabiSabi_Asia.jpg',
    style: Style.WABI_SABI,
    roomType: RoomType.LIVING,
    type: StylefinderTypes.PREDEFINED,
  },
  {
    id: 46,
    image: '../../../assets/styles-living-room/WZ6_WabiSabi_Asia.jpg',
    style: Style.WABI_SABI,
    roomType: RoomType.LIVING,
    type: StylefinderTypes.PREDEFINED,
  },
  {
    id: 47,
    image: '../../../assets/styles-living-room/WZ7_ModernCountry.jpg',
    style: Style.MODERN_COUNTRY,
    roomType: RoomType.LIVING,
    type: StylefinderTypes.PREDEFINED,
  },
  {
    id: 48,
    image: '../../../assets/styles-living-room/WZ8modern_country.jpg',
    style: Style.MODERN_COUNTRY,
    roomType: RoomType.LIVING,
    type: StylefinderTypes.PREDEFINED,
  },
  {
    id: 49,
    image: '../../../assets/styles-living-room/WZ9_BohoChic.jpg',
    style: Style.BOHO_CHIC,
    roomType: RoomType.LIVING,
    type: StylefinderTypes.PREDEFINED,
  },
  {
    id: 50,
    image: '../../../assets/styles-living-room/WZ10_BohoChic.jpg',
    style: Style.BOHO_CHIC,
    roomType: RoomType.LIVING,
    type: StylefinderTypes.PREDEFINED,
  },
];
