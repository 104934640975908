import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ResolutionService } from 'src/app/user-flow/services/resolution.service';

@Component({
  selector: 'app-info-page',
  templateUrl: './info-page.component.html',
  styleUrls: ['./info-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoPageComponent {
  @Input() public title: string;
  @Input() public message: string;
  @Input() public info?: string;
  @Input() public primaryButton: string;
  @Input() public secondaryButton: string;
  @Input() public choosePlan = false;

  @Output() public primaryButtonClick = new EventEmitter();
  @Output() public secondaryButtonClick = new EventEmitter();

  constructor(public resolutionService: ResolutionService) {}

  public clickPrimaryButton(): void {
    this.primaryButtonClick.emit();
  }

  public clickSecondaryButton(): void {
    this.secondaryButtonClick.emit();
  }
}
