import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { catchError, delay, filter, forkJoin, map, mergeMap, Observable, of, switchMap, tap } from 'rxjs';
import { Idea } from 'src/app/ideas/interfaces/idea';
import { IdeasHttpService } from 'src/app/ideas/services/ideas-http.service';
import { Notification } from 'src/app/notifications/model/notification';
import { NotificationsService } from 'src/app/notifications/services/notifications.service';
import { IdeaTypes } from 'src/app/shared/enums/idea-type.enum';
import { IList } from 'src/app/shared/interfaces/list';
import { ImportantNotificationsService } from 'src/app/shared/services/important-notifications.service';
import { TranslateResponse } from 'src/app/user-flow/interfaces/translate';
import { FurnitureHttpService } from 'src/services/furniture-http.service';
import { CatalogData } from 'src/services/model/proposal';
import { GoogleTranslateService } from 'src/services/translate.service';

import { IdeasActions } from '../action-types';
import {
  generationFinished,
  generationFinishedSuccess,
  generationStartSuccess,
  getIdeaImageSuccess,
  getIdeas,
  getIdeasSuccess,
  loadIdeaSimilarFurnitureSuccess,
  removeIdeaSuccess,
  setIdeaImageSuccess,
  translateIdeaFurnitureDescription,
  translateIdeaFurnitureDescriptionSuccess,
  updateIdeaSuccess,
  viewIdeaFurnitureSuccess,
} from '../actions/ideas.actions';
import { getUser, setIdeaActivePage, setOverlayLoadingSpinner } from '../actions/shared.actions';
import { lang } from '../selectors/shared.selector';

@UntilDestroy()
@Injectable()
export class IdeasEffects {
  public generateImages$ = createEffect(() =>
    this._actions$.pipe(
      ofType(IdeasActions.generationStart),
      tap(() =>
        this._importantNotificationsService.importantNotification(
          'NOTIFICATIONS.TITLE.GENERATION_STARTED',
          'NOTIFICATIONS.MESSAGES.GENERATION_STARTED_CONFIRM',
          true,
          false,
          '370px'
        )
      ),
      switchMap(action =>
        this._ideasHttpService
          .generateImage(action.tabType, action.image, action.roomType, action.style, action.specialWishes)
          .pipe(map(data => ({ data, tabType: action.tabType })))
      ),
      tap(() => this._store.dispatch(getUser())),
      map((data: { data: { id: string }; tabType: IdeaTypes }) => {
        return generationStartSuccess({
          id: data.data.id,
          generationType: data.tabType === IdeaTypes.DEFAULT ? IdeaTypes.DEFAULT : IdeaTypes.NO_FURNITURE,
        });
      })
    )
  );

  public continuePolling$ = createEffect(() =>
    this._actions$.pipe(
      ofType(IdeasActions.generationStartSuccess),
      switchMap(action =>
        this._ideasHttpService.generationProgress(action.id).pipe(
          delay(2000),
          map(data => ({ data, id: action.id, generationType: action.generationType }))
        )
      ),
      map(data => {
        return data.data.value === 'IN_PROGRESS'
          ? generationStartSuccess({ id: data.id, generationType: data.generationType })
          : generationFinished({ generationType: data.generationType });
      })
    )
  );

  public pollingStopper$ = createEffect(() =>
    this._actions$.pipe(
      ofType(IdeasActions.generationFinished),
      delay(2000),
      map(data => {
        this._importantNotificationsService.importantNotification(
          'NOTIFICATIONS.TITLE.IDEA_CREATED',
          'NOTIFICATIONS.MESSAGES.IMAGES_GENERATED',
          true,
          false,
          '390px'
        );
        if (location.pathname === '/result') {
          this._store.dispatch(getIdeas({ pageIndex: 0, pageSize: 6, ideaType: data.generationType }));
        }
        return generationFinishedSuccess();
      })
    )
  );

  public generateStyleSwitchImages$ = createEffect(() =>
    this._actions$.pipe(
      ofType(IdeasActions.generationStyleSwitchStart),
      tap(() =>
        this._importantNotificationsService.importantNotification(
          'NOTIFICATIONS.TITLE.GENERATION_STARTED',
          'NOTIFICATIONS.MESSAGES.GENERATION_STARTED_CONFIRM',
          true,
          false,
          '370px'
        )
      ),
      switchMap(action => this._ideasHttpService.generateStyleSwitchImage(action.roomImage, action.styleImage)),
      tap(() => this._store.dispatch(getUser())),
      map((res: { id: string }) => {
        return generationStartSuccess({ id: res.id, generationType: IdeaTypes.STYLESWAP });
      })
    )
  );

  public loadIdeas$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(IdeasActions.getIdeas),
      tap(() => this._store.dispatch(setOverlayLoadingSpinner({ status: true }))),
      switchMap(action =>
        action.email
          ? this._ideasHttpService
              .getIdeasByEmail(action.pageIndex, action.pageSize, action.ideaType, action.email)
              .pipe(map(ideas => ({ ideas, ideaType: action.ideaType, isAdmin: !!action.email })))
          : this._ideasHttpService
              .getIdeas(action.pageIndex, action.pageSize, action.ideaType)
              .pipe(map(ideas => ({ ideas, ideaType: action.ideaType, isAdmin: !!action.email })))
      ),
      map((data: { ideas: IList<Idea>; ideaType: IdeaTypes; isAdmin: boolean }) => {
        this._store.dispatch(setOverlayLoadingSpinner({ status: false }));
        const allIdeas = data.ideas.content;
        allIdeas.forEach(idea => {
          if (idea.previewLink) {
            this._ideasHttpService
              .loadOriginalImage(idea.previewLink)
              .pipe(
                map(blob => {
                  return { id: idea.id, blob };
                }),
                mergeMap((data: { id: string; blob: Blob }) => {
                  return new Observable<{ id: string; src: string }>(subscriber => {
                    const src = URL.createObjectURL(data.blob);
                    subscriber.next({ src, id: data.id });
                  });
                }),
                untilDestroyed(this)
              )
              .subscribe((data: { id: string; src: string }) => this._store.dispatch(setIdeaImageSuccess({ data })));
          }
        });
        this._store.dispatch(setIdeaActivePage({ tab: data.ideaType, isAdmin: data.isAdmin, pageIndex: data.ideas.number }));
        return getIdeasSuccess({ ideas: data.ideas });
      })
    );
  });

  public loadIdea$ = createEffect(() =>
    this._actions$.pipe(
      ofType(IdeasActions.getIdeaImage),
      tap(() => this._store.dispatch(setOverlayLoadingSpinner({ status: true }))),
      switchMap(action => this._loadOriginalImage(action.link, action.download)),
      map((data: { link: string; src: string; blob: Blob; download: boolean }) => {
        if (data.download) {
          const fileName = 'result.jpg';
          const a: HTMLAnchorElement = document.createElement('a');
          a.href = data.src;
          a.download = fileName;
          a.click();
        }
        this._store.dispatch(setOverlayLoadingSpinner({ status: false }));
        return getIdeaImageSuccess({ data });
      }),
      tap(() => this._router.navigate(['product-page/idea']))
    )
  );

  private _loadOriginalImage(link: string, download: boolean): Observable<{ link: string; src: string; blob: Blob }> {
    return this._ideasHttpService.loadOriginalImage(link).pipe(
      switchMap(blob => {
        return new Observable<{ link: string; src: string; blob: Blob; download: boolean }>(subscriber => {
          subscriber.next({ src: URL.createObjectURL(blob), link, blob, download });
          subscriber.complete();
        });
      })
    );
  }

  public loadIdeaSimilarFurniture$ = createEffect(() =>
    this._actions$.pipe(
      ofType(IdeasActions.loadIdeaSimilarFurniture),
      tap(() => this._store.dispatch(setOverlayLoadingSpinner({ status: true }))),
      switchMap(action => {
        const furniture$: Observable<{ Article: CatalogData }>[] = [];
        action.ids.forEach(id => furniture$.push(this._furnitureHttpService.getSingleItem(id, true).pipe(catchError(() => of(undefined)))));
        return furniture$.length > 0 ? forkJoin(furniture$) : of([]);
      }),
      map((data: { Article: CatalogData }[]) => data.filter(el => !!el).map(el => el.Article)),
      map((data: CatalogData[]) => {
        this._store.dispatch(setOverlayLoadingSpinner({ status: false }));
        return loadIdeaSimilarFurnitureSuccess({ data });
      })
    )
  );

  public updateIdea$ = createEffect(() =>
    this._actions$.pipe(
      ofType(IdeasActions.updateIdea),
      tap(() => this._store.dispatch(setOverlayLoadingSpinner({ status: true }))),
      switchMap(action => this._ideasHttpService.updateIdea(action.id).pipe(map(idea => ({ idea, link: action.link })))),
      tap(data => this._store.dispatch(updateIdeaSuccess({ idea: data.idea }))),
      switchMap((data: { idea: Idea; link: string }) => this._loadOriginalImage(data.link, false)),
      map((data: { link: string; src: string; blob: Blob }) => {
        this._store.dispatch(setOverlayLoadingSpinner({ status: false }));
        return getIdeaImageSuccess({ data });
      })
    )
  );

  public removeIdea$ = createEffect(() =>
    this._actions$.pipe(
      ofType(IdeasActions.removeIdea),
      tap(() => this._store.dispatch(setOverlayLoadingSpinner({ status: true }))),
      switchMap(action =>
        this._ideasHttpService.removeIdea(action.id).pipe(
          map(() => ({
            id: action.id,
            size: action.size,
            index: action.index,
            total: action.total,
            ideaType: action.ideaType,
            email: action.email,
          }))
        )
      ),
      map(data => {
        this._notificationsService.addNotification(
          new Notification({
            title: this._translateService.instant('NOTIFICATIONS.TITLE.SUCCESS_IDEA_REMOVE'),
            text: '',
            level: 'success',
            options: { timeout: 2 },
          })
        );
        this._store.dispatch(setOverlayLoadingSpinner({ status: false }));
        let pages = Math.floor(data.total / data.size);
        let index = data.index;
        if (data.total % data.size !== 0) {
          pages++;
        }
        if (pages - 1 === data.index && data.total % data.size === 1) {
          index--;
        }
        this._store.dispatch(getIdeas({ pageIndex: index, pageSize: data.size, ideaType: data.ideaType, email: data.email }));
        return removeIdeaSuccess({ id: data.id });
      })
    )
  );

  public viewFurniture$ = createEffect(() =>
    this._actions$.pipe(
      ofType(IdeasActions.viewIdeaFurniture),
      tap(({ data }) => this._store.dispatch(translateIdeaFurnitureDescription({ description: data.BasicArticleInformation.Description }))),
      map(({ data }) => {
        return viewIdeaFurnitureSuccess({ data });
      })
    )
  );

  public translateFurnitureDescription$ = createEffect(() =>
    this._actions$.pipe(
      ofType(IdeasActions.translateIdeaFurnitureDescription),
      switchMap(data =>
        this._store.select(lang).pipe(
          filter(lang => !!lang),
          map(lang => {
            return { data: data, lang };
          })
        )
      ),
      switchMap(({ data, lang }) =>
        lang.toLocaleLowerCase() === 'de'
          ? of(data.description)
          : this._googleTranslateService.translate([data.description], lang).pipe(
              map((res: TranslateResponse) => {
                return res.translatedContents;
              })
            )
      ),
      map((description: string) => {
        return translateIdeaFurnitureDescriptionSuccess({ description });
      })
    )
  );

  constructor(
    private _actions$: Actions,
    private _store: Store,
    private _ideasHttpService: IdeasHttpService,
    private _translateService: TranslateService,
    private _notificationsService: NotificationsService,
    private _furnitureHttpService: FurnitureHttpService,
    private _router: Router,
    private _googleTranslateService: GoogleTranslateService,
    private _importantNotificationsService: ImportantNotificationsService
  ) {}
}
