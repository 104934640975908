<div class="choose-file">
    <div *ngIf="!resolutionService.isMobileResolution, else mobileUpload" class="choose-file-container" draggable="true" ngClass="{{dragAreaClass}}">
        <img class="upload-photo-icon" src="./../../../assets/icons/share-green.svg" alt="Upload photo">
        <p class="drag-drop-title m-16" [ngClass]="{'m-18': !resolutionService.isMobileResolution, 'r-18': resolutionService.isMobileResolution}">{{ "USER_FLOW.UPLOAD.TITLE" | translate }} </p>
        <p class="drag-drop-subtitle m-16" [ngClass]="{'m-18': !resolutionService.isMobileResolution, 'r-18': resolutionService.isMobileResolution}">{{ "USER_FLOW.UPLOAD.SUBTITLE" | translate }} </p>

        <label class="custom-file-upload" *ngIf="!resolutionService.isMobileResolution">
            <input type="file" class="hidden file" accept=".heic,.jpeg,.png,.jpg,.heif" (change)="onFileChange($event)"/>
            <span>{{ "BUTTONS.UPLOAD" | translate }}</span>
        </label>
        <div class="error" *ngIf="fileSizeService.showError | async">{{ "SHARED.FILE_MAX_SIZE" | translate }}</div>
    </div>
</div>

<ng-template #mobileUpload>
    <label class="choose-file-container">
        <input type="file" class="hidden file" accept=".heic,.jpeg,.png,.jpg,.heif" (change)="onFileChange($event)"/>
        <img class="upload-photo-icon" src="./../../../assets/icons/share-green.svg" alt="Upload photo">
        <p class="drag-drop-title m-16" [ngClass]="{'m-18': !resolutionService.isMobileResolution, 'r-18': resolutionService.isMobileResolution}">{{ "USER_FLOW.UPLOAD.TITLE" | translate }} </p>
        <p class="drag-drop-subtitle m-16" [ngClass]="{'m-18': !resolutionService.isMobileResolution, 'r-18': resolutionService.isMobileResolution}">{{ "USER_FLOW.UPLOAD.SUBTITLE" | translate }} </p>
    </label>
</ng-template>
