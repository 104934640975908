<div class="page" *ngIf="ideas$ | async as ideas">
    <ng-container *ngIf="ideas.length; else noData">
        <div class="table">
            <ul class="items">
                <li class="item" *ngFor="let idea of ideas">
                    <div>
                        <div class="item-info-mobile" *ngIf="resolutionService.isMobileResolution">
                            <div class="name-mobile truncate m-18" *ngIf="idea.type !== 'STYLESWAP'" [title]="idea.defaultDesc.style">
                                {{ idea.defaultDesc.style | style | translate }}
                            </div>
                            <div class="name-mobile truncate m-18" *ngIf="idea.type === 'STYLESWAP'">
                                {{ idea.styleswapDesc?.name }}
                            </div>
                            <div>{{ idea.creationDate + 'Z' | date: 'dd.MM.yyyy, HH:mm \'Uhr\''}}</div>
                        </div>
                       
                        <div class="image-container" *ngIf="idea.resizedSrc; else placeholder">
                            <img 
                                class="image" 
                                [src]="idea.resizedSrc | safe: 'url'" 
                                alt="image"
                            />
                            <div 
                                class="open-image" 
                                (click)="openIdea(idea)"
                                aria-hidden="true"
                            >{{ "BUTTONS.OPEN" | translate}}
                            </div>
                        </div>
                        <div class="functional-container">
                            <div class="item-info">
                                <span class="truncate r-16" *ngIf="idea.type !== 'STYLESWAP'" [title]="idea.defaultDesc.style">
                                    {{ idea.defaultDesc.style | style | translate }}, 
                                </span>
                                <span class="truncate r-16" *ngIf="idea.type === 'STYLESWAP'">
                                    {{ idea.styleswapDesc?.name }}, 
                                </span>
                                <span class="r-16">{{ idea.creationDate + 'Z' | date: 'dd.MM.yyyy, HH:mm \'Uhr\''}}</span>
                            </div>
                            <div class="buttons">
                                <button class="button" mat-icon-button color="white"  (click)="openIdea(idea)">
                                    <img class="icon" src="./../../../../assets/icons/Gruppe 3154.svg" alt="Upload photo">
                                </button>
                                <button class="button" mat-icon-button color="white" (click)="removeIdea(idea.id)">
                                    <img class="icon" src="./../../../../assets/icons/Gruppe 3153.svg" alt="remove">
                                </button>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div class="paginator-wrapper" *ngIf="ideas.length">
            <mat-paginator  [ngClass]="{'paginator': (pages$ | async) > 2}"
                stylePaginator  
                showFirstLastButtons
                [showTotalPages]="3"
                [length]="totalElements$ | async"
                [pageSize]="size$ | async" (page)="onPageEvent($event)" 
                [pageIndex]="pageIndex$ | async" 
                [color]="'primary'">
            </mat-paginator>
          </div>
    </ng-container>
</div>

<ng-template #placeholder>
    <div class="placeholder-container image">
        <img 
            class="placeholder" 
            [src]="'../../../assets/Placeholder.png' | safe: 'url'" 
            alt="placeholder"
        />
    </div>
</ng-template>

<ng-template #noData>
    <app-no-data-page
        message="IDEAS.NO_DATA"
        [buttonDisabled]="!!email"
        (create)="createIdeas()"
    >
    </app-no-data-page>
</ng-template>