import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { distinctUntilChanged, Observable, switchMap, take } from 'rxjs';
import { AuthService } from 'src/app/auth/services/auth.service';
import { Notification } from 'src/app/notifications/model/notification';
import { NotificationsService } from 'src/app/notifications/services/notifications.service';
import { IdeaTypes } from 'src/app/shared/enums/idea-type.enum';
import { Plans } from 'src/app/shared/enums/plans.enum';
import { Roles } from 'src/app/shared/enums/roles.enum';
import { BlobService } from 'src/app/shared/services/blob.service';
import { FileSizeService } from 'src/app/shared/services/file-size.service';
import { ImportantNotificationsService } from 'src/app/shared/services/important-notifications.service';
import { clearActiveIdeaFurniture, generationStart, getIdeas } from 'src/app/store/actions/ideas.actions';
import { ideas } from 'src/app/store/selectors/ideas.selectors';
import { userName, userPlanRoleRemainingIdeas } from 'src/app/store/selectors/shared.selector';
import { ResolutionService } from 'src/app/user-flow/services/resolution.service';

import { DEFAULT_IMAGES } from '../../const/default-images.const';
import { NO_FURNITURE_IMAGES } from '../../const/no-furniture-images';
import { STYLES } from '../../const/styles.const';
import { TYPES } from '../../const/types.const';
import { IdeasService } from '../../services/ideas.service';

@UntilDestroy()
@Component({
  selector: 'app-create-idea',
  templateUrl: './create-idea.component.html',
  styleUrls: ['./create-idea.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateIdeaComponent implements OnInit {
  @ViewChild('fileInput', { static: true }) public fileInput: ElementRef;
  @Input() public type: IdeaTypes;
  public userPlanRoleRemainingIdeas$: Observable<{ plan: Plans; role: string; remainingIdeas: number }> =
    this._store.select(userPlanRoleRemainingIdeas);
  public dragAreaClass = 'dragarea';
  public incorrectTypeError = false;
  @HostListener('dragover', ['$event']) public onDragOver(event: DragEvent): void {
    this.dragAreaClass = 'droparea';
    event.preventDefault();
  }
  @HostListener('dragenter', ['$event']) public onDragEnter(event: DragEvent): void {
    this.dragAreaClass = 'droparea';
    event.preventDefault();
  }
  @HostListener('dragend', ['$event']) public onDragEnd(event: DragEvent): void {
    this.dragAreaClass = 'dragarea';
    event.preventDefault();
  }
  @HostListener('dragleave', ['$event']) public onDragLeave(event: DragEvent): void {
    this.dragAreaClass = 'dragarea';
    event.preventDefault();
  }
  @HostListener('drop', ['$event']) public onDrop(event: DragEvent): void {
    this.dragAreaClass = 'dragarea';
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files) {
      this.incorrectTypeError = false;
      this.userInputForm.get('imageInput').setValue(null);
      this.userInputForm.updateValueAndValidity();
      this._cdr.markForCheck();
      if (
        event.dataTransfer.files &&
        event.dataTransfer.files[0] &&
        this._ideasService._acceptableFormatCheck(event.dataTransfer.files[0])
      ) {
        this._setFile(event.dataTransfer.files[0]);
      } else {
        this.incorrectTypeError = true;
      }
    }
  }

  public userInputForm: FormGroup = new FormGroup({
    type: new FormControl(null, Validators.required),
    style: new FormControl(null, Validators.required),
    specialWishes: new FormControl('', Validators.maxLength(30)),
    imageInput: new FormControl(null, Validators.required),
  });
  public types: { name: string; value: string }[] = TYPES;
  public styles: { name: string; value: string }[] = STYLES;
  public wishesPlaceholder$: Observable<string> = this._translateService.get('IDEAS.WISHES');
  public blob: Blob;
  public fileName: string;
  public user$: Observable<string> = this._store.select(userName);
  public defaultImages: string[] = DEFAULT_IMAGES;
  public noFurnitureImages: string[] = NO_FURNITURE_IMAGES;
  public images: string[];
  public activeImage: string;
  public index = 0;
  public title: string;
  public subTitle: string;

  public get isLoggedIn(): boolean {
    return this._authService.isLoggedIn();
  }

  public get isLoggedOut(): boolean {
    return this._authService.isLoggedOut();
  }

  constructor(
    public fileSizeService: FileSizeService,
    private _authService: AuthService,
    private _store: Store,
    private _translateService: TranslateService,
    private _cdr: ChangeDetectorRef,
    private _blobService: BlobService,
    public resolutionService: ResolutionService,
    private _ideasService: IdeasService,
    private _importantNotificationsService: ImportantNotificationsService,
    private _notificationsService: NotificationsService
  ) {}

  public ngOnInit(): void {
    this._store.dispatch(getIdeas({ pageIndex: 0, pageSize: 3, ideaType: this.type }));
    this.title = this.type === IdeaTypes.DEFAULT ? 'IDEAS.TABS.DEFAULT.TITLE' : 'IDEAS.TABS.NO_FURNITURE.TITLE';
    this.subTitle = this.type === IdeaTypes.DEFAULT ? 'IDEAS.TABS.DEFAULT.SUBTITLE' : 'IDEAS.TABS.NO_FURNITURE.SUBTITLE';
    this._store.dispatch(clearActiveIdeaFurniture());

    this._setFormValues();
    this._subscribeFormChanges();

    this._store
      .select(ideas)
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe(data => {
        const staticImages = this.type === IdeaTypes.DEFAULT ? this.defaultImages : this.noFurnitureImages;
        this.images = data && data.length && data[0].resizedSrc ? data.map(item => item.resizedSrc) : staticImages;
        this.activeImage = this.images[0];
        this.index = 0;
        this._cdr.detectChanges();
      });
  }

  public onFileChange(event): void {
    this.incorrectTypeError = false;
    this.userInputForm.get('imageInput').setValue(null);
    this.userInputForm.updateValueAndValidity();

    this._cdr.markForCheck();

    const selectedfile = (event.target as HTMLInputElement).files || ({} as FileList);
    if (selectedfile.length > 0) {
      this._setFile(selectedfile[0]);
    }
  }

  private _subscribeFormChanges(): void {
    this._subscribeControlChanges('type');
    this._subscribeControlChanges('style');
    this._subscribeControlChanges('specialWishes');
  }

  private _subscribeControlChanges(controlName: string): void {
    this.userInputForm
      .get(`${controlName}`)
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe(value => {
        if (value) {
          localStorage.setItem(`${controlName}`, value);
        } else {
          localStorage.removeItem(`${controlName}`);
        }
      });
  }

  public onSubmit(): void {
    if (this.userInputForm.get('imageInput').invalid) {
      this._notificationsService.addNotification(
        new Notification({
          title: this._translateService.instant('NOTIFICATIONS.TITLE.WARNING'),
          text: this._translateService.instant('NOTIFICATIONS.MESSAGES.ADD_IMAGE'),
          level: 'warning',
          options: { timeout: 2 },
        })
      );
    } else {
      this._store.dispatch(
        generationStart({
          tabType: this.type,
          image: new File([this.blob], this.fileName),
          roomType: this.userInputForm.get('type').value,
          style: this.userInputForm.get('style').value,
          specialWishes: this.userInputForm.get('specialWishes').value,
        })
      );
      this._clearForm();
    }
  }

  private _clearForm(): void {
    localStorage.removeItem('image');
    localStorage.removeItem('type');
    localStorage.removeItem('style');
    localStorage.removeItem('specialWishes');
    localStorage.getItem('image_name');
    this.userInputForm.reset();
  }

  private _setFormValues(): void {
    const fileName = localStorage.getItem('image_name');
    const base64 = localStorage.getItem('image');
    const style = localStorage.getItem('style');
    const type = localStorage.getItem('type');
    const specialWishes = localStorage.getItem('specialWishes');
    this.userInputForm.patchValue({
      type,
      style,
      specialWishes,
    });
    if (base64) {
      this._blobService
        .toBlob(`data:image/jpeg;base64,${base64}`)
        .pipe(untilDestroyed(this))
        .subscribe((blob: Blob) => {
          const file = new File([blob], fileName);

          this._setFile(file);
        });
    }
  }

  private _setFile(file: File): void {
    this.userPlanRoleRemainingIdeas$.pipe(take(1), untilDestroyed(this)).subscribe(data => {
      if (data && data.plan === Plans.FREE && data.role === Roles.USER && data.remainingIdeas <= 0) {
        this._importantNotificationsService.limitNotification();
        this.fileInput.nativeElement.value = null;
      } else {
        this.userInputForm.get('imageInput').setValue('name');
        this.userInputForm.updateValueAndValidity();
        this._cdr.markForCheck();
        this._ideasService
          .convertFromHeicOrHeif(file)
          .pipe(
            switchMap(file => this._ideasService.resize(file, 'image', 'image_name')),
            untilDestroyed(this)
          )
          .subscribe(result => {
            this.blob = result.blob;
            this.fileName = result.filename;
          });
      }
    });
  }

  public previous(): void {
    if (this.index - 1 >= 0) {
      this.activeImage = this.images[--this.index];
    }
  }

  public next(): void {
    if (this.index + 1 < this.images.length) {
      this.activeImage = this.images[++this.index];
    }
  }

  // mat-select from mat-angular version 15.0 don't display default value if translate pipe is used in interpolated string
  public getTranslationForDropdown(value: string): string {
    return this._translateService.instant(value);
  }
}
