import { PerspectiveCamera } from 'three';

import { InteriorResult } from '../../../services/model/detect-floor-result.model';

export function setUpCamera(camera: PerspectiveCamera, result: InteriorResult): void {
  camera.fov = result.analysis.camera.fov;
  camera.rotation.order = 'YXZ';
  camera.rotation.set(result.analysis.camera.pitch, 0, result.analysis.camera.roll);
  camera.updateProjectionMatrix();
}

export function setCameraToDefaults(camera: PerspectiveCamera): void {
  camera.rotation.set(0, 0, 0);
  // fov from wizart still in usage
  // camera.fov = 50;
  camera.updateProjectionMatrix();
}
