import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { CreateTabsComponent } from './create-tabs/create-tabs.component';
import { ResultTabsComponent } from './result-tabs/result-tabs.component';
import { AdminModule } from '../admin/admin.module';
import { UserProjectsComponent } from '../admin/user-projects/user-projects.component';
import { AuthGuard } from '../auth/guards/auth.guard';
import { IdeasPageComponent } from '../ideas/components/ideas-page/ideas-page.component';
import { IdeasTableComponent } from '../ideas/components/ideas-table/ideas-table.component';
import { IdeasModule } from '../ideas/ideas.module';
import { CurrentUserProjectsListComponent } from '../projects/projects-list/current-user-projects-list.component';
import { ProjectsModule } from '../projects/projects.module';
import { TRANSLATE_MODULE_CONFIG } from '../shared/configs/translate.config';
import { IdeaTypes } from '../shared/enums/idea-type.enum';
import { SharedComponentsModule } from '../shared/shared-components.module';
import { ChooseFileComponent } from '../user-flow/choose-file/choose-file.component';
import { UserFlowModule } from '../user-flow/user-flow.module';

const routes: Routes = [
  {
    path: 'result',
    component: ResultTabsComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'default', component: IdeasTableComponent, data: { type: IdeaTypes.DEFAULT } },
      { path: 'styleswap', component: IdeasTableComponent, data: { type: IdeaTypes.STYLESWAP } },
      { path: 'no-furniture', component: IdeasTableComponent, data: { type: IdeaTypes.NO_FURNITURE } },
      { path: 'admin-projects', component: UserProjectsComponent },
      { path: 'projects', component: CurrentUserProjectsListComponent },
    ],
  },
  {
    path: 'create',
    component: CreateTabsComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'default', component: IdeasPageComponent, data: { type: IdeaTypes.DEFAULT } },
      { path: 'styleswap', component: IdeasPageComponent, data: { type: IdeaTypes.STYLESWAP } },
      { path: 'no-furniture', component: IdeasPageComponent, data: { type: IdeaTypes.NO_FURNITURE } },
      { path: 'projects', component: ChooseFileComponent },
    ],
  },
];
@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forChild(TRANSLATE_MODULE_CONFIG),
    RouterModule.forChild(routes),
    IdeasModule,
    ProjectsModule,
    UserFlowModule,
    AdminModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    SharedComponentsModule,
  ],
  declarations: [ResultTabsComponent, CreateTabsComponent],
  providers: [],
  exports: [],
  entryComponents: [],
})
export class ProjectsIdeasModule {}
