import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, distinctUntilChanged, Observable, tap } from 'rxjs';
import { ResolutionService } from 'src/app/user-flow/services/resolution.service';
import { IVersion } from 'src/models/project';

@UntilDestroy()
@Component({
  selector: 'app-versions-list',
  templateUrl: './versions-list.component.html',
  styleUrls: ['./versions-list.component.scss'],
})
export class VersionsListComponent implements OnInit {
  public searchInput: FormControl = new FormControl();
  @Input() public projectId: string;
  @Input() public size = 6;
  @Input() public totalElements$: Observable<number>;
  @Input() public versions$: Observable<IVersion[]>;
  @Output() public editVersionEvent = new EventEmitter<IVersion>();
  @Output() public removeVersionEvent = new EventEmitter<{ versionId: string; pageIndex: number; pageSize: number }>();
  @Output() public updateVersions = new EventEmitter<{ page: number; size: number; name: string }>();
  @Output() public backVersionEvent = new EventEmitter<void>();
  @ViewChild(MatPaginator)
  public paginator: MatPaginator;

  constructor(public resolutionService: ResolutionService) {}

  public ngOnInit(): void {
    this.searchInput.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(value => {
          this.updateVersions.emit({ page: this.paginator?.pageIndex, size: this.paginator?.pageSize, name: value });
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  public onPageEvent = ($event): void => {
    this.updateVersions.emit({ page: $event.pageIndex, size: $event.pageSize, name: null });
  };

  public back(): void {
    this.backVersionEvent.emit();
  }

  public editVersion(version: IVersion): void {
    this.editVersionEvent.emit(version);
  }

  public removeVersion(id: string): void {
    this.removeVersionEvent.emit({ versionId: id, pageIndex: this.paginator?.pageIndex, pageSize: this.paginator?.pageSize });
  }
}
