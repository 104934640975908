import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as Hull from 'hull.js';
import { filter, switchMap, take, tap } from 'rxjs';
import { Notification } from 'src/app/notifications/model/notification';
import { NotificationsService } from 'src/app/notifications/services/notifications.service';
import { Mesh, MeshBasicMaterial, Raycaster, Vector3 } from 'three';

import { getPointBetween } from './autopositioning.function';
import { setCameraToDefaults, setUpCamera } from './camera.function';
import { CornersControlsService } from './corners-controls.service';
import { PlaneType } from './enum/plane-type.enum';
import { hasSimilarCoordinates } from './functions/has-simillar-coordinates.function';
import { getHorizontalHullPoints } from './functions/surface.utils';
import { ManualReconstructionConfig } from './model/config/manual-reconstruction-config.model';
import { PlanesService } from './planes.service';
import { RoomParamsService } from './room-params.service';
import { DetectObjHttpService } from '../../../services/detect-obj-http.service';
import { InteriorResult, Point } from '../../../services/model/detect-floor-result.model';
import { activeProjectFile, reconstructionResult } from '../../store/selectors/shared.selector';
import { PointType } from '../manual-reconstruct-editor/holes.enum';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class ManualReconstructionService {
  private readonly _mainWallDepth = -4;

  private _config: ManualReconstructionConfig;
  private _corners: { floorPoint: Mesh; ceilingPoint: Mesh }[] = [];
  private _raycaster = new Raycaster();
  private _reconstructManual: { mouseOnCanvas: any; mouseTo3D: any }[] = [];

  constructor(
    private _roomParamsService: RoomParamsService,
    private _planesService: PlanesService,
    private _detectObjHttpService: DetectObjHttpService,
    private _notificationService: NotificationsService,
    private _translateService: TranslateService,
    private _cornerControlsService: CornersControlsService,
    private _store: Store
  ) {}

  public handleClick(mouseOnCanvas: { x: number; y: number }, mouseTo3D: { x: number; y: number }): void {
    if (this._reconstructManual.length === 0) {
      this._placeMainWallPoint(mouseTo3D, this._mainWallDepth);
      this._reconstructManual.push({ mouseOnCanvas, mouseTo3D });
    } else if (this._reconstructManual.length === 1) {
      this._placeMainWallPoint(mouseTo3D, this._mainWallDepth);
      this._reconstructManual.push({ mouseOnCanvas, mouseTo3D });
      this._placeMainCorner(this._reconstructManual);
    } else if (this._reconstructManual.length === 2) {
      this._placeNextCorner(mouseTo3D);
    }
  }

  public clear(): void {
    this._corners = [];
  }

  public configure(config: ManualReconstructionConfig): void {
    this._config = config;
  }

  private _placeMainCorner(reconstructManual: { mouseOnCanvas: any; mouseTo3D: any }[]): void {
    if (reconstructManual) {
      this._store
        .select(activeProjectFile)
        .pipe(
          take(1),
          filter((file: File) => !!file),
          tap(() => this._config.wallCornersGroup.clear()),
          switchMap(() => {
            return this._roomParamsService.setRoomHeightDialog();
          })
        )
        .subscribe(roomHeightMeters => {
          const distance = this._calculateDistance(
            reconstructManual[0].mouseOnCanvas,
            reconstructManual[1].mouseOnCanvas,
            roomHeightMeters,
            this._config.canvasHeight
          );
          const z = Math.abs(distance) < 7.5 ? -distance : -7.5;
          const meshes = [
            this._placeMainWallPoint(reconstructManual[0].mouseTo3D, z),
            this._placeMainWallPoint({ x: reconstructManual[0].mouseTo3D.x, y: reconstructManual[1].mouseTo3D.y }, z),
          ];
          meshes.sort((first, second) => (first.position.y < second.position.y ? 1 : -1));
          this._corners.push({
            ceilingPoint: meshes[0],
            floorPoint: meshes[1],
          });
        });
    }
  }

  private _placeNextCorner(mouse: { x: number; y: number }): void {
    this._planesService.addHelper('FLOOR_HELPER', new MeshBasicMaterial(), this._corners[0].floorPoint.position, PlaneType.FLOOR);

    this._raycaster.setFromCamera(mouse, this._config.camera);
    const intersects = this._raycaster.intersectObjects(this._config.planesGroup.children);
    if (intersects.length > 0) {
      const floorPoint = this._planesService.getWallPointMesh(intersects[0].point, PointType.CORNER);
      this._config.wallCornersGroup.add(floorPoint);

      const ceilingPointPosition = new Vector3(intersects[0].point.x, this._corners[0].ceilingPoint.position.y, intersects[0].point.z);
      const ceilingPointMesh = this._planesService.getWallPointMesh(ceilingPointPosition, PointType.CORNER);
      this._config.wallCornersGroup.add(ceilingPointMesh);

      this._corners.push({
        floorPoint,
        ceilingPoint: ceilingPointMesh,
      });
    }

    this._planesService.removeHelper('FLOOR_HELPER');
  }

  public completeReconstruction(): {
    floorPoints: Vector3[];
    ceilingPoints: Vector3[];
    wallPoints: Vector3[][];
  } {
    this._config.wallCornersGroup.clear();

    const walls = this._getWallPoints();

    const floor = this._corners.map(corner => corner.floorPoint.position.clone());
    const ceiling = this._corners.map(corner => corner.ceilingPoint.position.clone());

    const floorPointsTowardsCamera = this._getExpandedPlane(floor);
    const ceilingPointsTowardsCamera = this._getExpandedPlane(ceiling);

    return {
      floorPoints: floorPointsTowardsCamera,
      ceilingPoints: ceilingPointsTowardsCamera,
      wallPoints: walls,
    };
  }

  private _getExpandedPlane(points: Vector3[]): Vector3[] {
    const leftSidePoints = points.filter(p => p.x > 0);
    const rightSidePoints = points.filter(p => p.x < 0);

    const leftSideSortedZ = leftSidePoints.map(p => p.clone()).sort((a, b) => a.z - b.z);
    const rightSideSortedZ = rightSidePoints.map(p => p.clone()).sort((a, b) => a.z - b.z);

    const leftSideClosest = new Vector3();
    if (leftSideSortedZ.length) {
      leftSideClosest.copy(leftSideSortedZ.at(0));
    }

    const rightSideClosest = new Vector3();
    if (rightSideSortedZ.length) {
      rightSideClosest.copy(rightSideSortedZ.at(0));
    }

    return [...points, rightSideClosest.setZ(0), leftSideClosest.setZ(0)];
  }

  public get cornersLength(): number {
    return this._corners.length;
  }

  private _getHullCorners(corners: { floorPoint: Mesh; ceilingPoint: Mesh }[]): { floorPoint: Mesh; ceilingPoint: Mesh }[] {
    const floorPoints = corners.map(corner => corner.floorPoint.position.clone());
    const hullPoints = getHorizontalHullPoints(floorPoints);
    hullPoints.splice(hullPoints.length - 1, 1);
    const hullCorners: { floorPoint: Mesh; ceilingPoint: Mesh }[] = [];
    hullPoints.forEach(hullPoint => {
      const corner = corners.find(corner => hasSimilarCoordinates(hullPoint, corner.floorPoint.position));
      if (corner) {
        hullCorners.push(corner);
      }
    });
    return hullCorners;
  }

  private _getWallPoints(): Vector3[][] {
    const walls: Vector3[][] = [];
    if (this._corners.length > 1) {
      for (let i = 0; i < this._corners.length; i++) {
        const firstCorner = this._corners[i];
        let secondCorner;
        if (i === this._corners.length - 1) {
          secondCorner = this._corners[0];
        } else {
          secondCorner = this._corners[i + 1];
        }
        walls.push([
          firstCorner.floorPoint.position.clone(),
          firstCorner.ceilingPoint.position.clone(),
          secondCorner.floorPoint.position.clone(),
          secondCorner.ceilingPoint.position.clone(),
        ]);
      }
    }

    let result = walls
      .slice()
      .sort((firstWP, secondWP) =>
        this._getDistancePoint(firstWP).distanceTo(this._config.camera.position) >
        this._getDistancePoint(secondWP).distanceTo(this._config.camera.position)
          ? 1
          : -1
      );

    const deletedWall = result[0];
    deletedWall.sort((p1, p2) => (p1.y > p2.y ? 1 : -1));

    result = result.splice(1);

    return result;
  }

  private _getDistancePoint(points: Vector3[]): Vector3 {
    const ySorted = points.slice().sort((p1, p2) => (p1.y > p2.y ? 1 : -1));

    return getPointBetween(ySorted[0], ySorted[1], ySorted[0].distanceTo(ySorted[1]) / 2);
  }

  private _placeMainWallPoint(mouseOnCanvas: { x: number; y: number }, z: number): Mesh {
    this._planesService.addHelper('HELPER', new MeshBasicMaterial(), new Vector3(0, 0, z), PlaneType.WALL);

    this._raycaster.setFromCamera(mouseOnCanvas, this._config.camera);
    const intersects = this._raycaster.intersectObjects(this._config.planesGroup.children);
    if (intersects.length > 0) {
      const wallPoint = this._planesService.getWallPointMesh(intersects[0].point, PointType.CORNER);
      this._config.wallCornersGroup.add(wallPoint);
      this._planesService.removeHelper('HELPER');
      return wallPoint;
    }

    this._planesService.removeHelper('HELPER');

    return null;
  }

  private _calculateDistance(
    p1: { x: number; y: number },
    p2: { x: number; y: number },
    roomHeightMeters: number,
    imageHeightPixels: number
  ): number {
    const focalLength = this._config.camera.getFocalLength();
    const focalHeight = 24;
    const roomHeightPixels = Math.abs(p1.y - p2.y);
    return (focalLength * roomHeightMeters * 1000 * imageHeightPixels) / (roomHeightPixels * focalHeight * 1000);
  }

  private _createCopy(object: Mesh): Mesh {
    const clone = object.clone();
    clone.uuid = object.uuid;

    const toRemove = this._config.wallCornersGroup.getObjectsByProperty('uuid', object.uuid);
    toRemove.forEach(child => this._config.wallCornersGroup.remove(child));

    this._config.wallCornersGroup.add(clone);

    this._corners.forEach(corner => {
      if (corner.floorPoint.uuid === object.uuid) {
        corner.floorPoint = clone;
        return;
      }

      if (corner.ceilingPoint.uuid === object.uuid) {
        corner.ceilingPoint = clone;
        return;
      }
    });

    return clone;
  }

  public moveWallCorner(object: Mesh): void {
    let isFloorPoint: boolean;

    object = this._createCopy(object);

    this._corners.forEach(corner => {
      let relatedCorner: Mesh;

      if (corner.ceilingPoint.uuid === object.uuid) {
        relatedCorner = corner.floorPoint;
        corner.ceilingPoint = object;
        isFloorPoint = false;
      }

      if (corner.floorPoint.uuid === object.uuid) {
        relatedCorner = corner.ceilingPoint;
        corner.floorPoint = object;
        isFloorPoint = true;
      }

      if (relatedCorner) {
        relatedCorner.position.set(object.position.x, relatedCorner.position.y, object.position.z);
        return;
      }
    });

    const relatedYCorners = this._corners.map(corner => (isFloorPoint ? corner.floorPoint : corner.ceilingPoint));
    relatedYCorners.forEach(relatedCorner => {
      if (relatedCorner.uuid !== object.uuid) {
        relatedCorner.position.set(relatedCorner.position.x, object.position.y, relatedCorner.position.z);
      }
    });
  }

  public clearReconstruction(): void {
    setCameraToDefaults(this._config.camera);
    this._cornerControlsService.detachCornersControls();
    this._corners = [];
    this._reconstructManual = [];
    this._notificationService.addNotification(
      new Notification({
        title: this._translateService.instant('NOTIFICATIONS.TITLE.MANUAL_RECONSTRUCTION_RESTARTED'),
        text: this._translateService.instant('NOTIFICATIONS.MESSAGES.MANUAL_RECONSTRUCTION_RESTARTED'),
        level: 'success',
        options: { timeout: 2 },
      })
    );
  }

  public setFromAutoReconstruct(): void {
    this._store
      .select(reconstructionResult)
      .pipe(
        filter(data => !!data),
        take(1)
      )
      .subscribe(data => this._setManualReconstruct(data));
  }

  private _placeCorner(first: Point, second: Point): void {
    const firstMesh = this._planesService.getWallPointMesh(new Vector3(first.x, first.y, first.z), PointType.CORNER);
    this._config.wallCornersGroup.add(firstMesh);
    const secondMesh = this._planesService.getWallPointMesh(new Vector3(second.x, second.y, second.z), PointType.CORNER);
    this._config.wallCornersGroup.add(secondMesh);
    const meshes = [firstMesh, secondMesh];
    meshes.sort((first, second) => (first.position.y < second.position.y ? 1 : -1));
    this._corners.push({
      ceilingPoint: meshes[0],
      floorPoint: meshes[1],
    });
  }

  private _checkInCorners(
    cornerForCheck: { floorPoint: Mesh; ceilingPoint: Mesh },
    corners: { floorPoint: Mesh; ceilingPoint: Mesh }[]
  ): boolean {
    return !!corners.find(corner => corner.floorPoint.uuid === cornerForCheck.floorPoint.uuid);
  }

  private _applyCornersSort(): void {
    const hull = Hull(
      this._corners.map(corner => [corner.floorPoint.position.x, corner.floorPoint.position.z]),
      0.1
    );

    this._corners.sort((c1, c2) => {
      const firstIndex = hull.findIndex(
        (points: [number, number]) => points[0] === c1.floorPoint.position.x && points[1] === c1.floorPoint.position.z
      );
      const secondIndex = hull.findIndex(
        (points: [number, number]) => points[0] === c2.floorPoint.position.x && points[1] === c2.floorPoint.position.z
      );
      return firstIndex > secondIndex ? 1 : -1;
    });
  }

  private _deleteCornersDuplicates(): void {
    const uniqueCorners = [];

    this._corners.forEach(corner => {
      if (!this._checkInCorners(corner, uniqueCorners)) {
        uniqueCorners.push(corner);
      }
    });

    this._corners = uniqueCorners;
  }

  private _replaceOldCorners(): void {
    this._corners.forEach(corner => {
      if (!this._config.wallCornersGroup.getObjectByProperty('uuid', corner.ceilingPoint.uuid)) {
        const replace = this._config.wallCornersGroup.children.find(
          wallCorner => wallCorner.position.distanceTo(corner.ceilingPoint.position) < 0.1
        );
        if (replace) {
          corner.ceilingPoint = replace as Mesh;
        }
      }

      if (!this._config.wallCornersGroup.getObjectByProperty('uuid', corner.floorPoint.uuid)) {
        const replace = this._config.wallCornersGroup.children.find(
          wallCorner => wallCorner.position.distanceTo(corner.floorPoint.position) < 0.1
        );
        if (replace) {
          corner.floorPoint = replace as Mesh;
        }
      }
    });
  }

  private _placeCorners(data: InteriorResult): void {
    if (data.reconstruction.walls.length >= 2) {
      data.reconstruction.walls.forEach(wall => {
        const sortedPoints = wall.points.slice().sort((p1, p2) => (p1.y > p2.y ? 1 : -1));
        const ceilingY = sortedPoints[sortedPoints.length - 1].y;
        const floorPoints = sortedPoints.slice(0, 2);

        this._placeCorner(floorPoints[0], { ...floorPoints[0], y: ceilingY });
        this._placeCorner(floorPoints[1], { ...floorPoints[1], y: ceilingY });
      });
    }
  }

  private _setManualReconstruct(data: InteriorResult): void {
    this._placeCorners(data);
    setUpCamera(this._config.camera, data);
    this._planesService.deleteDuplicateWallPoints();

    this._replaceOldCorners();
    this._deleteCornersDuplicates();
    this._applyCornersSort();
  }
}
