import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { Notification } from '../model/notification';
import { getPausableTimer } from '../model/utils';

@Injectable()
export class NotificationsService {
  private _notification$ = new BehaviorSubject<Notification | null>(null);
  private _pendingNotifications: Notification[] = [];

  public getNotification(): Observable<Notification> {
    return this._notification$;
  }

  public addNotification(notification: Notification): void {
    if (!this._notification$.value) {
      this._addNotification(notification);
      return;
    }

    if (this._isSameNotifications(notification, this._notification$.value)) {
      return;
    }

    this._pendingNotifications.push(notification);
  }

  public removeNotification(): void {
    const notification = this._notification$.getValue();
    this.next(null);
    notification.dispose();
    if (this._pendingNotifications.length > 0) {
      this._addNotification(this._pendingNotifications.shift());
    }
  }

  private _addNotification(notification: Notification): void {
    if (notification && notification.options && notification.options.timeout) {
      notification.obs = getPausableTimer(notification.options.timeout, notification.paused);
      // take(1) automatically disposes of subscription after one value is emitted from complete timer
      notification.obs.completeTimer.pipe(take(1)).subscribe(() => this.removeNotification());
    }

    this.next(notification);
  }

  private next(notification: Notification): void {
    this._notification$.next(notification);
  }

  private _isSameNotifications(a: Notification, b: Notification): boolean {
    return a.text === b.text && a.title === b.title;
  }
}
