import { BehaviorSubject, Observable } from 'rxjs';

export class Notification {
  public id: number;
  public title: string;
  public text: string;
  public params: string[];
  public options: { timeout?: number };
  public level: 'success' | 'warning' | 'error' = 'success';
  public paused: BehaviorSubject<boolean>;

  public obs?: PausableTimer;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(params: any = {}) {
    this.id = new Date().getTime();
    this.paused = new BehaviorSubject<boolean>(false);
    this.title = params.title;
    this.text = params.text;
    this.params = params.params;
    this.options = params.options ? params.options : {};
    this.level = params.level;
  }

  public dispose(): void {
    this.paused.complete();
  }
}

export interface PausableTimer {
  stepTimer: Observable<number>;
  completeTimer: Observable<number>;
  remainingPercent: Observable<number>;
}
