import { Injectable } from '@angular/core';
import { disposeMovingObjects } from 'src/app/user-flow/services/dispose.utils';

import { MovingObject } from '../app/user-flow/services/model/dto/moving-object.model';

@Injectable({
  providedIn: 'root',
})
export class DataStoreService {
  public movingObjects: MovingObject[] = [];
  public proposalMovingObjects: Map<string, MovingObject[]> = new Map();

  public addMovingObject(obj: MovingObject): void {
    if (!this.movingObjects.includes(obj)) {
      this.movingObjects.push(obj);
    }
  }

  public getMovingObject(id: string): MovingObject {
    return this.movingObjects.find(obj => obj.id === id);
  }

  public addProposalMovingObjects(key: string, objects: MovingObject[]): void {
    if (!this.proposalMovingObjects.get(key)) {
      this.proposalMovingObjects.set(key, objects);
    }
  }

  public getProposalMovingObjects(key: string): MovingObject[] {
    return this.proposalMovingObjects.get(key) ?? [];
  }

  public clearAll(): void {
    const objectsToDispose: MovingObject[] = [];
    objectsToDispose.push(...this.movingObjects);
    for (const proposalSnapshot of this.proposalMovingObjects.values()) {
      objectsToDispose.push(...proposalSnapshot);
    }

    disposeMovingObjects(objectsToDispose);

    this.movingObjects = [];
    this.proposalMovingObjects = new Map();
  }
}
