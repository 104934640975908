<mat-dialog-content class="rotation-dialog">
  <div class="rotation-dialog__wrapper">
    <div
      class="current-angle"
      cdkDrag
      cdkDragRootElement=".cdk-overlay-pane"
      cdkDragHandle
    >
      {{ currentValueDegrees }}&deg;
    </div>
    <mat-slider class="slider" max="180" min="-180" step="0.5">
      <input
        matSliderThumb
        [formControl]="rotateControl"
      />
    </mat-slider>
  </div>
  <div class="rotation-dialog__options">
    <div
      class="button-wrapper"
      [ngClass]="{
        active: currentSelectedAxis === 'y'
      }"
    >
      <button mat-icon-button (click)="selectAxis('y')">
        <img
          mat-icon
          class="icon-circle"
          src="./../../../../assets/icons/Gruppe 3124.svg"
          alt="rotate y"
        />
      </button>
    </div>
    <div
      class="button-wrapper"
      [ngClass]="{
        active: currentSelectedAxis === 'x'
      }"
    >
      <button mat-icon-button (click)="selectAxis('x')">
        <img
          mat-icon
          class="icon-circle"
          src="./../../../../assets/icons/Gruppe 3305.svg"
          alt="rotate x"
        />
      </button>
    </div>
    <div
      class="button-wrapper"
      [ngClass]="{
        active: currentSelectedAxis === 'z'
      }"
    >
      <button mat-icon-button (click)="selectAxis('z')">
        <img
          mat-icon
          class="icon-circle"
          src="./../../../../assets/icons/Gruppe 3306.svg"
          alt="rotate x"
        />
      </button>
    </div>
  </div>
  <div class="rotation-dialog__buttons">
    <button
      class="button reset__button"
      mat-stroked-button
      (click)="resetDegrees()"
    >
      {{ "BUTTONS.RESET" | translate }}
    </button>
  </div>
  <button
    class="close-button"
    mat-icon-button
    aria-label="close dialog"
    [mat-dialog-close]="true"
  >
    <mat-icon>close</mat-icon>
  </button>
</mat-dialog-content>
