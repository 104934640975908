import { Directive, ElementRef, EventEmitter, OnDestroy, Output } from '@angular/core';
import { filter, fromEvent, map, merge, of, Subscription, switchMap, timer } from 'rxjs';

@Directive({
  selector: '[longPress]',
})
export class LongPressDirective implements OnDestroy {
  private _eventSubscribe: Subscription;

  public threshold = 200;

  @Output()
  public mouseLongPress = new EventEmitter();

  constructor(private _elementRef: ElementRef) {
    const mouseDown = fromEvent<MouseEvent>(this._elementRef.nativeElement, 'mousedown').pipe(
      filter(event => event.button == 0),
      map(() => true)
    );

    const touchStart = fromEvent(this._elementRef.nativeElement, 'touchstart').pipe(map(() => true));

    const touchEnd = fromEvent(this._elementRef.nativeElement, 'touchend').pipe(map(() => false));

    const mouseUp = fromEvent<MouseEvent>(window, 'mouseup').pipe(
      filter(event => event.button == 0),
      map(() => false)
    );

    this._eventSubscribe = merge(mouseDown, mouseUp, touchStart, touchEnd)
      .pipe(
        switchMap(state => (state ? timer(this.threshold, 100) : of(null))),
        filter(value => !!value)
      )
      .subscribe(() => this.mouseLongPress.emit());
  }

  public ngOnDestroy(): void {
    if (this._eventSubscribe) {
      this._eventSubscribe.unsubscribe();
    }
  }
}
