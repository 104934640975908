import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { Observable, Subject, tap } from 'rxjs';
import { CookieService } from 'src/app/shared/services/cookie.service';
import { clearIdeasTable } from 'src/app/store/actions/ideas.actions';
import { clearProjectsState } from 'src/app/store/actions/projects.actions';
import { getUser } from 'src/app/store/actions/shared.actions';
import { environment } from 'src/environments/environment';
import { ERROR_SKIP_INTERCEPT } from 'src/services/context-tokens/error.context-token';

import { ForgotPasswordDto } from '../model/forgot-password.model';
import { LoginDto, LoginResponse } from '../model/login.model';
import { RegisterDto } from '../model/register.model';
import { ResetPasswordDto } from '../model/reset-password.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private _http: HttpClient,
    private _store: Store,
    private _router: Router,
    private _cookieService: CookieService
  ) {}

  public logout$ = new Subject<void>();

  public login(dto: LoginDto): Observable<LoginResponse> {
    const context = new HttpContext();
    context.set(ERROR_SKIP_INTERCEPT, true);
    return this._http.post<LoginResponse>(environment.API_URL + 'auth/login', dto, { context }).pipe(
      tap(response => {
        this.setSession(response);
        this._cookieService.collectCookie();
        this._store.dispatch(getUser());
      })
    );
  }

  public register(dto: RegisterDto, lang: string): Observable<void> {
    const params = new HttpParams().set('language', lang);
    const context = new HttpContext();
    context.set(ERROR_SKIP_INTERCEPT, true);
    return this._http.post<void>(environment.API_URL + 'auth/register', dto, { params, context });
  }

  public verify(token: string): Observable<void> {
    return this._http.post<void>(environment.API_URL + 'auth/verify', token);
  }

  public resend(email: string, lang: string): Observable<void> {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('language', lang);
    return this._http.post<void>(environment.API_URL + 'auth/resend-email', formData);
  }

  public reset(dto: ResetPasswordDto): Observable<void> {
    return this._http.post<void>(environment.API_URL + 'auth/reset-password', dto);
  }

  public forgot(dto: ForgotPasswordDto): Observable<void> {
    return this._http.post<void>(environment.API_URL + 'auth/forgot-password', dto);
  }

  public refresh(): Observable<LoginResponse> {
    const token = localStorage.getItem('refreshToken');
    return this._http.post<LoginResponse>(environment.API_URL + 'auth/refresh', token);
  }

  public setSession(authResult: LoginResponse): void {
    const expiresAt = moment().add(authResult.refreshExpiresAfter, 'second');

    localStorage.setItem('refreshToken', authResult.refreshToken);
    localStorage.setItem('refreshExpiresAfter', JSON.stringify(expiresAt.valueOf()));
    localStorage.setItem('token', authResult.token);
  }

  public logout(): void {
    this._clearLocalStorage();
    this._store.dispatch(clearIdeasTable());
    this._store.dispatch(clearProjectsState());
    this._router.navigate(['/login']);
    this.logout$.next();
  }

  public isLoggedIn(): boolean {
    return moment().isBefore(this.getExpiration());
  }

  public isLoggedOut(): boolean {
    return !this.isLoggedIn();
  }

  public getExpiration(): moment.Moment {
    const expiration = localStorage.getItem('refreshExpiresAfter');
    const expiresAt = JSON.parse(expiration);
    return moment(expiresAt);
  }

  private _clearLocalStorage(): void {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('refreshExpiresAfter');
    localStorage.removeItem('role');
    localStorage.removeItem('specialWishes');
    localStorage.removeItem('style');
    localStorage.removeItem('type');
    localStorage.removeItem('image');
    localStorage.removeItem('cookie');
    localStorage.removeItem('image_name');
    localStorage.removeItem('room_image_name');
    localStorage.removeItem('room_image');
    localStorage.removeItem('style_image_name');
    localStorage.removeItem('style_image');
  }
}
