export const STYLES: { name: string; value: string }[] = [
  { name: 'IDEAS.STYLES.ALL_WHITE', value: 'ALL_WHITE' },
  { name: 'IDEAS.STYLES.ASIA', value: 'ASIA' },
  { name: 'IDEAS.STYLES.BLACK_WHITE', value: 'BLACK_WHITE' },
  { name: 'IDEAS.STYLES.BOHO_CHIC', value: 'BOHO_CHIC' },
  { name: 'IDEAS.STYLES.CONTEMPORARY', value: 'CONTEMPORARY' },
  { name: 'IDEAS.STYLES.ECLECTIC', value: 'ECLECTIC' },
  { name: 'IDEAS.STYLES.MEDITERRANEAN', value: 'MEDITERRANEAN' },
  { name: 'IDEAS.STYLES.MODERN_COUNTRY', value: 'MODERN_COUNTRY' },
  { name: 'IDEAS.STYLES.INDUSTRIAL', value: 'INDUSTRIAL' },
  { name: 'IDEAS.STYLES.NEW_SCANDINAVIAN', value: 'NEW_SCANDINAVIAN' },
  { name: 'IDEAS.STYLES.VINTAGE', value: 'VINTAGE' },
  { name: 'IDEAS.STYLES.WABI_SABI', value: 'WABI_SABI' },
];
