<app-options-page
    [header]="'USER_FLOW.LABELS.RECONSTRUCT_CORRECT'"
    [subHeader]="'USER_FLOW.LABELS.RECONSTRUCT'"
    [options]="options"
    [img]="img$ |async"
    [imgIsRender]="true"
    [imageChange]="false"
    [inputType]="inputType"
    [scrollOnRender]="true"
    (update)="onChange($event)"
></app-options-page>
