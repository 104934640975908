import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs';
import { ResolutionService } from 'src/app/user-flow/services/resolution.service';
import { IProject } from 'src/models/project';

@UntilDestroy()
@Component({
  selector: 'app-projects-list',
  templateUrl: './projects-list.component.html',
  styleUrls: ['./projects-list.component.scss'],
})
export class ProjectsListComponent implements OnInit {
  public searchInput: FormControl = new FormControl();
  @Input() public size: number;
  @Input() public pageIndex: number;
  @Input() public totalElements: number;
  @Input() public projects: IProject[];
  @Input() public isAdminView: boolean;
  @Output() public openProjectEvent = new EventEmitter<IProject>();
  @Output() public removeProjectEvent = new EventEmitter<string>();
  @Output() public updateProjects = new EventEmitter<{ page: number; size: number; name: string }>();
  @ViewChild(MatPaginator)
  public paginator: MatPaginator;
  public pages: number;

  constructor(public resolutionService: ResolutionService) {}

  public ngOnInit(): void {
    this.pages = this.totalElements / this.size;
    this.searchInput.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(value => {
          this.updateProjects.emit({ page: this.paginator?.pageIndex, size: this.paginator?.pageSize, name: value });
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  public onPageEvent = ($event): void => {
    this.updateProjects.emit({ page: $event.pageIndex, size: $event.pageSize, name: null });
  };

  public openProject(project: IProject): void {
    this.openProjectEvent.emit(project);
  }

  public removeProject(id: string): void {
    this.removeProjectEvent.emit(id);
  }
}
