import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { fabric } from 'fabric';
import { distinctUntilChanged, filter, from, map, Observable, switchMap, take, tap } from 'rxjs';
import { Notification } from 'src/app/notifications/model/notification';
import { NotificationsService } from 'src/app/notifications/services/notifications.service';
import { addImage, CLEAR_IMAGE, deleteSelection, updateImage } from 'src/app/store/actions/image.actions';
import { setOverlayLoadingSpinner, setUnsavedChanges, updateActiveProject } from 'src/app/store/actions/shared.actions';
import { isPicwishUpdate, newImage } from 'src/app/store/selectors/image.selectors';
import { activeProjectFile } from 'src/app/store/selectors/shared.selector';
import { clearUndoRedoState, redoAction, undoAction } from 'src/app/undo-redo/undo-redo.actions';
import { getCurrentAction } from 'src/app/undo-redo/undo-redo.selectors';
import { IProjectAdditionalInfo } from 'src/models/project';

import { MAX_HEIGHT, MAX_HEIGHT_MOBILE_REMOVE_STEP, MAX_WIDTH, MAX_WIDTH_MOBILE } from '../const/windows-dimentions';
import { RefreshNavigationService } from '../services/refresh-navigation.service';
import { ResolutionService } from '../services/resolution.service';

@UntilDestroy()
@Component({
  selector: 'app-remove-editor',
  templateUrl: './remove-editor.component.html',
  styleUrls: ['./remove-editor.component.scss'],
})
export class RemoveEditorComponent implements OnInit, AfterViewInit {
  public projectInfo: IProjectAdditionalInfo = {};
  private _finalAspectRatio: number;
  private imageFile: File;

  public drawWidth: FormControl = new FormControl<number>(10);
  private drawing = false;
  public canvas = <HTMLCanvasElement>this._document.getElementById('editor');
  public context: CanvasRenderingContext2D;
  public drawCanvas = <HTMLCanvasElement>this._document.getElementById('result');
  public drawContext: CanvasRenderingContext2D;
  private restoreArray = [];
  private index = -1;
  private blockScroll = false;

  constructor(
    public resolutionService: ResolutionService,
    private _router: Router,
    private _store: Store,
    private _notificationsService: NotificationsService,
    private _translateService: TranslateService,
    private _refreshNavigationService: RefreshNavigationService,
    @Inject(DOCUMENT) private _document: Document
  ) {}

  public ngOnInit(): void {
    this._store.dispatch(clearUndoRedoState());
    this._store
      .select(getCurrentAction)
      .pipe(untilDestroyed(this))
      .subscribe(current => {
        if (current) {
          switch (current.action.name) {
            case CLEAR_IMAGE:
              if (current.command === 'undo') {
                this._store.dispatch(updateImage({ src: current.action.data.prevSrc }));
              } else {
                this._store.dispatch(updateImage({ src: current.action.data.nextSrc }));
              }
              break;
          }
        }
      });
    this._store
      .select(newImage)
      .pipe(
        distinctUntilChanged(),
        filter(data => !!data),
        tap(() => this._store.dispatch(setOverlayLoadingSpinner({ status: true }))),
        switchMap(res => this._loadBgImage(res)),
        switchMap(src =>
          from(fetch(src).then(r => r.blob())).pipe(
            switchMap(blob =>
              this._store.select(isPicwishUpdate).pipe(
                take(1),
                distinctUntilChanged(),
                map(isPicwish => ({ blob, isPicwish }))
              )
            ),
            tap(data => {
              if (data.isPicwish) {
                this._store.dispatch(
                  addImage({
                    data: {
                      prevSrc: URL.createObjectURL(
                        this.projectInfo.handledBackgroundFile ? this.projectInfo.handledBackgroundFile : this.imageFile
                      ),
                      nextSrc: src,
                    },
                  })
                );
              }
              this.projectInfo.handledBackgroundFile = new File([data.blob], 'handledBackgroundFile.jpg');
            }),
            map(() => src)
          )
        ),
        untilDestroyed(this)
      )
      .subscribe((src: string) => {
        this.projectInfo.src = src;
        this._store.dispatch(setOverlayLoadingSpinner({ status: false }));
        this._notificationsService.addNotification(
          new Notification({
            title: this._translateService.instant('NOTIFICATIONS.TITLE.SUCCESS'),
            text: this._translateService.instant('NOTIFICATIONS.MESSAGES.SUCCESSFULLY_CLEARED'),
            level: 'success',
            options: { timeout: 2 },
          })
        );
      });
    this._store
      .select(activeProjectFile)
      .pipe(
        take(1),
        filter(file => !!file),
        switchMap(file => this._showImage(file)),
        untilDestroyed(this)
      )
      .subscribe();

    this._refreshNavigationService.refreshPageEvent$.pipe(untilDestroyed(this)).subscribe(() => this._router.navigate(['choose-file']));
  }

  public ngAfterViewInit(): void {
    this.canvas = <HTMLCanvasElement>this._document.getElementById('editor');
    this.context = this.canvas.getContext('2d');
    this.drawCanvas = <HTMLCanvasElement>this._document.getElementById('result');
    this.drawContext = this.drawCanvas.getContext('2d');
  }

  public forward(): void {
    this._store.dispatch(redoAction());
  }

  public backward(): void {
    this._store.dispatch(undoAction());
  }

  public next(): void {
    this._store.dispatch(updateActiveProject({ projectInfo: this.projectInfo }));
    this._store.dispatch(setUnsavedChanges({ state: true }));
    this._router.navigate(['reconstruct-preview']);
  }

  public back(): void {
    this._router.navigate(['remove-preview']);
  }

  public async deleteSelection(): Promise<void> {
    const mask = await fetch(this._createMask().toDataURL('image/jpeg')).then(r => r.blob());
    const image = this.projectInfo.handledBackgroundFile ? this.projectInfo.handledBackgroundFile : this.imageFile;
    this._store.dispatch(deleteSelection({ image, mask }));
  }

  public clearCanvas(): void {
    this.drawContext.clearRect(0, 0, this.drawCanvas.width, this.drawCanvas.height);
    this.restoreArray = [];
    this.index = -1;
  }

  public undoLast(): void {
    if (this.index <= 0) {
      this.clearCanvas();
    } else {
      this.index -= 1;
      this.restoreArray.pop();
      this.drawContext.putImageData(this.restoreArray[this.index], 0, 0);
    }
  }

  private _showImage(imageFile: File): Observable<any> {
    this.imageFile = imageFile;
    return new Observable<any>(subscriber => {
      const imageObj = new Image();
      imageObj.src = URL.createObjectURL(imageFile);
      imageObj.onload = (): void => {
        const image = new fabric.Image(imageObj);
        [this.projectInfo.width, this.projectInfo.height, this._finalAspectRatio] = this._resizeImage(imageObj.width, imageObj.height);
        image.scale(this._finalAspectRatio);
        if (!this.resolutionService.isMobileResolution) {
          const canvases = this._document.querySelectorAll('canvas');

          for (let i = 0; i < canvases.length; i++) {
            canvases[i].style.left = `calc(50% - ${this.projectInfo.width / 2}`;
          }
        } else {
          const actions = this._document.getElementsByClassName('actions-mobile') as HTMLCollectionOf<HTMLElement>;
          actions[0].style.marginTop = `${this.projectInfo.height}px`;
        }

        this.canvas.width = this.resolutionService.isMobileResolution ? this.projectInfo.width - 40 : this.projectInfo.width;
        this.canvas.height = this.projectInfo.height;
        this.drawCanvas.width = this.resolutionService.isMobileResolution ? this.projectInfo.width - 40 : this.projectInfo.width;
        this.drawCanvas.height = this.projectInfo.height;

        this.context.drawImage(
          imageObj,
          0,
          0,
          this.resolutionService.isMobileResolution ? this.projectInfo.width - 40 : this.projectInfo.width,
          this.projectInfo.height
        );

        this._addEventListeners();
        subscriber.next();
      };
    });
  }

  private _resizeImage(realWidth = 600, realHeight = 600): number[] {
    const widthAspectRatio = (this.resolutionService.isMobileResolution ? MAX_WIDTH_MOBILE : MAX_WIDTH) / realWidth;
    const heightAspectRatio = (this.resolutionService.isMobileResolution ? MAX_HEIGHT_MOBILE_REMOVE_STEP : MAX_HEIGHT) / realHeight;

    const finalAspectRatio = Math.min(widthAspectRatio, heightAspectRatio);
    const imageHeight = realHeight * finalAspectRatio;
    const imageWidth = realWidth * finalAspectRatio;

    return [imageWidth, imageHeight, finalAspectRatio];
  }

  private _loadBgImage(src: string): Observable<any> {
    return new Observable<any>(subscriber => {
      const imageObj = new Image();
      imageObj.src = src;
      imageObj.onload = (): void => {
        this.clearCanvas();
        this.drawContext.clearRect(0, 0, this.canvas.width, this.canvas.height);
        const image = new fabric.Image(imageObj);
        [this.projectInfo.width, this.projectInfo.height, this._finalAspectRatio] = this._resizeImage(imageObj.width, imageObj.height);
        this.canvas.height = this.projectInfo.height || 600;
        this.canvas.width = this.projectInfo.width || 600;
        image.scale(this._finalAspectRatio);
        this.context.drawImage(imageObj, 0, 0, this.projectInfo.width, this.projectInfo.height);
        subscriber.next(src);
      };
    });
  }

  private _continueStroke(event): void {
    if (this.drawing) {
      const radius = this.drawWidth.value;
      const mouseOnCanvas = this._getMouse(event);
      this.drawContext.moveTo(mouseOnCanvas.x + radius, mouseOnCanvas.y);
      this.drawContext.arc(mouseOnCanvas.x, mouseOnCanvas.y, radius, 0, 2 * Math.PI);
      this.drawContext.fill();
      this.drawContext.stroke();
    }
  }

  private _mouseMove(event: MouseEvent | TouchEvent): void {
    if (this.blockScroll) {
      event.preventDefault();
    }
    if (!this.drawing) {
      return;
    }
    this._continueStroke(event);
  }

  private _mouseDown(event: MouseEvent | TouchEvent): void {
    if (this.resolutionService.isMobileResolution) {
      this.blockScroll = true;
    }
    if (this.drawing) {
      return;
    }
    const radius = this.drawWidth.value;

    const mouseOnCanvas = this._getMouse(event);

    this.drawing = true;
    this.drawContext.beginPath();
    this.drawContext.lineWidth = this.drawWidth.value;
    this.drawContext.strokeStyle = 'white';
    this.drawContext.fillStyle = 'white';
    this.drawContext.lineJoin = 'round';
    this.drawContext.lineCap = 'round';
    this.drawContext.moveTo(mouseOnCanvas.x + radius, mouseOnCanvas.y);
    this.drawContext.arc(mouseOnCanvas.x, mouseOnCanvas.y, radius, 0, 2 * Math.PI);
    this.drawContext.fill();
  }

  private _endStroke(event: MouseEvent | TouchEvent): void {
    this.blockScroll = false;
    if (!this.drawing) {
      return;
    }
    this.drawing = false;
    event.currentTarget.removeEventListener('mousemove', this._mouseMove, false);
    if (event.type !== 'mouseout') {
      this.restoreArray.push(this.drawContext.getImageData(0, 0, this.drawCanvas.width, this.drawCanvas.height));
      this.index += 1;
    }
  }

  private _getMouse(event: MouseEvent | TouchEvent): { x: number; y: number } {
    let coordinates: { clientX: number; clientY: number };
    const r = this.drawCanvas.getBoundingClientRect();

    if (this.resolutionService.isMobileResolution) {
      const mobileEvent = event as TouchEvent;
      coordinates = {
        clientX: mobileEvent.changedTouches[0].clientX,
        clientY: mobileEvent.changedTouches[0].clientY,
      };
    } else {
      const clickEvent = event as MouseEvent;
      coordinates = {
        clientX: clickEvent.clientX,
        clientY: clickEvent.clientY,
      };
    }
    const x = coordinates.clientX - r.left;
    const y = coordinates.clientY - r.top;
    return { x, y };
  }

  private _addEventListeners(): void {
    this.drawCanvas.addEventListener('touchstart', this._mouseDown.bind(this), false);
    this.drawCanvas.addEventListener('touchend', this._endStroke.bind(this), false);
    this.drawCanvas.addEventListener('touchcancel', this._endStroke.bind(this), false);
    this.drawCanvas.addEventListener('touchmove', this._mouseMove.bind(this), false);

    this.drawCanvas.addEventListener('mousedown', this._mouseDown.bind(this), false);
    this.drawCanvas.addEventListener('mouseup', this._endStroke.bind(this), false);
    this.drawCanvas.addEventListener('mousemove', this._mouseMove.bind(this), false);
  }

  private _createMask(): HTMLCanvasElement {
    const exportCanvas = this._document.createElement('canvas');
    exportCanvas.width = this.drawCanvas.width;
    exportCanvas.height = this.drawCanvas.height;
    const exportContext = exportCanvas.getContext('2d');
    exportContext.fillStyle = 'black';
    exportContext.fillRect(0, 0, this.drawContext.canvas.width, this.drawContext.canvas.height);
    exportContext.drawImage(this.drawCanvas, 0, 0);
    return exportCanvas;
  }
}
