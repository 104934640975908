import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { catchError, Observable, throwError } from 'rxjs';
import { addGlobalError, setOverlayLoadingSpinner } from 'src/app/store/actions/shared.actions';
import { SharedState } from 'src/app/store/shared.state';

import { ERROR_SKIP_INTERCEPT } from '../../../services/context-tokens/error.context-token';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private _store: Store<SharedState>,
    private _router: Router
  ) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (!request.context.has(ERROR_SKIP_INTERCEPT)) {
          this._store.dispatch(setOverlayLoadingSpinner({ status: false }));
          this._store.dispatch(addGlobalError({ data: error }));
          if (!request.url.includes('/alternatives')) {
            this._router.navigateByUrl('/result', { state: { bypassFormGuard: true } });
          }
        }
        return throwError(error);
      })
    );
  }
}
