import {
  IAdvancedSearchCriteria,
  IBed,
  ICarpet,
  IChair,
  ICurtain,
  IDiningTable,
  IEasyChair,
  ILamp,
  IPaining,
  IPlant,
  ISeatCushion,
  IShelf,
  ISideTable,
  ISofa,
  ITable,
} from 'src/models/proposal';

const table: ITable = {
  diameterInCm: null,
  heightInCm: null,
  isHardConstraint: false,
  lengthInCm: null,
  mainColors: null,
  proposalSlotId: 'DINING_TABLE',
  type: 'NO_TYPE',
  widthInCm: null,
};

const chair: IChair = {
  depthInCm: null,
  heightInCm: null,
  isHardConstraint: false,
  mainColors: null,
  proposalSlotId: 'DINING_TABLE',
  shape: 'NO_TYPE',
  type: 'NO_TYPE',
  widthInCm: null,
};

export const sofa: ISofa = {
  depthInCm: null,
  heightInCm: null,
  isHardConstraint: false,
  mainColors: null,
  orientation: 'UNDEFINED',
  proposalSlotId: 'SOFA',
  type: 'NO_TYPE',
  widthInCm: null,
};

export const easyChair: IEasyChair = {
  depthInCm: null,
  heightInCm: null,
  isHardConstraint: false,
  mainColors: null,
  proposalSlotId: 'EASY_CHAIR',
  shape: 'NO_TYPE',
  widthInCm: null,
};

export const sideTable: ISideTable = {
  diameterInCm: null,
  heightInCm: null,
  lengthInCm: null,
  isHardConstraint: false,
  mainColors: null,
  proposalSlotId: 'SIDE_TABLE',
  type: 'NO_TYPE',
  widthInCm: null,
};

const lamp: ILamp = {
  diameterInCm: null,
  heightInCm: null,
  lengthInCm: null,
  isHardConstraint: false,
  mainColors: null,
  proposalSlotId: 'LAMP',
  shape: 'NO_TYPE',
  type: 'NO_TYPE',
  widthInCm: null,
};

const plant: IPlant = {
  heightInCm: null,
  isHardConstraint: false,
  location: 'NO_LOCATION',
  maintenance: 'NO_DIFFICULTY',
  potDiameterInCm: null,
  proposalSlotId: 'PLANT',
};

const seatCushion: ISeatCushion = {
  diameterInCm: null,
  lengthInCm: null,
  isHardConstraint: false,
  mainColors: null,
  proposalSlotId: 'SEAT_CUSHION',
  shape: 'NO_TYPE',
  widthInCm: null,
};

const carpet: ICarpet = {
  diameterInCm: null,
  lengthInCm: null,
  isHardConstraint: false,
  mainColors: null,
  proposalSlotId: 'CARPET',
  shape: 'NO_TYPE',
  widthInCm: null,
};

const curtain: ICurtain = {
  heightInCm: null,
  isHardConstraint: false,
  mainColors: null,
  proposalSlotId: 'CURTAIN',
  widthInCm: null,
};

const shelf: IShelf = {
  depthInCm: null,
  heightInCm: null,
  isHardConstraint: false,
  mainColors: null,
  proposalSlotId: 'SHELF',
  widthInCm: null,
};

export const bed: IBed = {
  depthInCm: null,
  heightInCm: null,
  isHardConstraint: false,
  mainColors: null,
  proposalSlotId: 'BED',
  widthInCm: null,
};

export const diningTable: IDiningTable = {
  depthInCm: null,
  heightInCm: null,
  isHardConstraint: false,
  mainColors: null,
  proposalSlotId: 'DINING_TABLE',
  widthInCm: null,
};

const painting: IPaining = {
  heightInCm: null,
  isHardConstraint: false,
  mainColors: null,
  proposalSlotId: 'PAINTING',
  widthInCm: null,
};

export const advancedSearchCriteria: IAdvancedSearchCriteria = {
  wallPaints: null,
  primaryColors: null,
  table: [table],
  chair: [chair],
  sofa: [sofa],
  easyChair: [easyChair],
  sideTable: [sideTable],
  pouf: [],
  lamp: [lamp],
  plant: [plant],
  seatCushion: [seatCushion],
  carpet: [carpet],
  curtain: [curtain],
  shelf: [shelf],
  bed: [bed],
  diningTable: [diningTable],
  closet: [],
  tableLamp: [],
  wardrobe: [],
  officeTable: [],
  officeChair: [],
  painting: [painting],
};
