import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SafePipeModule } from 'safe-pipe';

import { CreateIdeaComponent } from './components/create-idea/create-idea.component';
import { IdeaPageComponent } from './components/idea-page/idea-page.component';
import { IdeasPageComponent } from './components/ideas-page/ideas-page.component';
import { IdeasTableComponent } from './components/ideas-table/ideas-table.component';
import { KiServicesHeaderComponent } from './components/ki-services-header/ki-services-header.component';
import { StyleSwitchComponent } from './components/style-switch/style-switch.component';
import { StylePipe } from './pipes/style.pipe';
import { TypePipe } from './pipes/type.pipe';
import { CropImageService } from './services/crop-image.service';
import { IdeasHttpService } from './services/ideas-http.service';
import { IdeasService } from './services/ideas.service';
import { ProductPageComponent } from '../shared/components/product-page/product-page.component';
import { InterceptorsModule } from '../shared/interceptors/interceptors.module';
import { FileSizeService } from '../shared/services/file-size.service';
import { SharedComponentsModule } from '../shared/shared-components.module';
import { IdeasEffects } from '../store/effects/ideas.effects';
import { ideasReducer } from '../store/reducers/ideas.reducers';

const routes: Routes = [
  {
    path: 'product-page/:type',
    component: ProductPageComponent,
  },
  { path: 'ideas', component: IdeasTableComponent },
];

@NgModule({
  declarations: [
    IdeasTableComponent,
    IdeaPageComponent,
    TypePipe,
    StylePipe,
    IdeasPageComponent,
    CreateIdeaComponent,
    StyleSwitchComponent,
    KiServicesHeaderComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    InterceptorsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatPaginatorModule,
    MatMenuModule,
    ReactiveFormsModule,
    SafePipeModule,
    MatSidenavModule,
    MatExpansionModule,
    MatIconModule,
    SharedComponentsModule,
    StoreModule.forFeature('ideas', ideasReducer),
    EffectsModule.forFeature([IdeasEffects]),
    RouterModule.forChild(routes),
  ],
  providers: [CropImageService, IdeasHttpService, FileSizeService, IdeasService],
  exports: [IdeasTableComponent, IdeasPageComponent],
})
export class IdeasModule {}
