<div class="catalog-container">
  <div class="catalog">
    <app-idea-furniture
      *ngIf="pageType === leftPanelStates.FURNITURE_LIST"
      (closePanel)="closePanel()"
      (openDetailsEvent)="openDetailsEvent()"
    >
    </app-idea-furniture>
    <app-shared-catalog-details
      *ngIf="pageType === leftPanelStates.DETAILS_PANEL"
      (openFurnitureList)="openFurnitureList()"
    >
    </app-shared-catalog-details>
  </div>
</div>
