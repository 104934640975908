import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, from, map, Observable, startWith } from 'rxjs';
import { CatalogData } from 'src/services/model/proposal';

import { CATALOG_OPTIONS } from '../../const/catalog-options';
import { CatalogOption } from '../../interfaces/catalog-option';
import { ImportService } from '../../services/import.service';
import { KeyboardStateService } from '../../services/keyboard-state.service';

@Component({
  selector: 'app-catalog-categories',
  templateUrl: './catalog-categories.component.html',
  styleUrls: ['./catalog-categories.component.scss'],
})
export class CatalogCategoriesComponent implements OnInit {
  public dragAreaClass = true;
  public dropAreaClass = false;
  @HostListener('dragover', ['$event']) public onDragOver(event: DragEvent): void {
    this.dragAreaClass = false;
    this.dropAreaClass = true;
    event.preventDefault();
  }
  @HostListener('dragenter', ['$event']) public onDragEnter(event: DragEvent): void {
    this.dragAreaClass = false;
    this.dropAreaClass = true;
    event.preventDefault();
  }
  @HostListener('dragend', ['$event']) public onDragEnd(event: DragEvent): void {
    this.dragAreaClass = true;
    this.dropAreaClass = false;
    event.preventDefault();
  }
  @HostListener('dragleave', ['$event']) public onDragLeave(event: DragEvent): void {
    this.dragAreaClass = true;
    this.dropAreaClass = false;
    event.preventDefault();
  }
  @HostListener('drop', ['$event']) public onDrop(event: DragEvent): void {
    this.dragAreaClass = true;
    this.dropAreaClass = false;
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files) {
      const file: File = event.dataTransfer.files[0];
      this._importService.handleFile(file);
    }
  }
  public catalogData$: Observable<CatalogData[]>;
  public type = new FormControl<string | CatalogOption>(null);
  public filteredOptions: Observable<CatalogOption[]> = from([]);
  public readonly types: CatalogOption[] = CATALOG_OPTIONS;
  @Output() public categoryChange: EventEmitter<string> = new EventEmitter();

  constructor(
    private _translateService: TranslateService,
    private _importService: ImportService,
    private _keyboardService: KeyboardStateService
  ) {}

  public ngOnInit(): void {
    this.filteredOptions = this.type.valueChanges.pipe(
      debounceTime(500),
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.viewValue;
        return name ? this._filter(name as string) : this.types.slice();
      })
    );
  }

  public displayFn(option: CatalogOption): string {
    return option && option.viewValue ? this._translateService?.instant(option.viewValue) : '';
  }

  public openCategory(option: CatalogOption): void {
    if (option.value) {
      this.categoryChange.emit(option.value);
    } else {
      this._importService.loadModel();
    }
  }

  private _filter(name: string): CatalogOption[] {
    const filterValue = this._translateService.instant(name).toLowerCase();

    return this.types.filter(option => this._translateService.instant(option.viewValue).toLowerCase().includes(filterValue));
  }

  public searchStart(): void {
    this._keyboardService.keyboardEnabled = false;
  }

  public searchEnd(): void {
    this._keyboardService.keyboardEnabled = true;
  }
}
