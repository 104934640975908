import { PositioningElement } from './positioning-element.model';

export const leftCorner: PositioningElement = {
  type: 'LEFT_CORNER',
};

export const rightCorner: PositioningElement = {
  type: 'LEFT_CORNER',
};

export const roomHeight: PositioningElement = {
  type: 'ROOM_HEIGHT',
};

export const roomCenter: PositioningElement = {
  type: 'ROOM_CENTER',
};

export const sceneWindow: PositioningElement = {
  type: 'SCENE_WINDOW',
};

export interface RoomSchema {
  elements: PositioningElement[];
}
