import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormFieldComponent {
  public get formControl(): FormControl {
    return this.abstractControl as FormControl;
  }

  @Input() public name: string;
  @Input() public label: string;
  @Input() public abstractControl: AbstractControl;
  @Input() public formType: 'input' | 'textarea' = 'input';
  @Input() public type: string;

  constructor(private translateService: TranslateService) {}

  public getError(): string {
    if (this.formControl.getError('notSame')) {
      return this.translateService.instant('AUTH.ERRORS.NOT_EQUAL');
    }

    if (this.formControl.getError('email')) {
      return this.translateService.instant('AUTH.ERRORS.EMAIL_FORMAT');
    }

    if (this.formControl.getError('required')) {
      return `${this.translateService.instant(this.label)} ${this.translateService.instant('AUTH.ERRORS.REQUIRED')}`;
    }

    return '';
  }
}
