<div class="page-container">
    <div class="choose-file-header">
        <p class="header-text title m-40" *ngIf="!resolutionService.isMobileResolution">{{ "USER_FLOW.CHOOSE_FILE.CREATE_PROJECT" | translate }}</p>
        <p class="header-text" [ngClass]="{'r-24': !resolutionService.isMobileResolution, 'r-20': resolutionService.isMobileResolution}">{{ "USER_FLOW.CHOOSE_FILE.DESCRIPTION" | translate }}</p>
    </div>
    <div class="container">
        <div *ngIf="!resolutionService.isMobileResolution, else mobileUpload" class="choose-file-container" draggable="true" ngClass="{{dragAreaClass}}">
            <div class="error m-25" *ngIf="showWarning">{{ "USER_FLOW.CHOOSE_FILE.UNSUPPORTED_FORMAT" | translate }}</div>
            <div class="error m-25" *ngIf="fileSizeService.showError | async">{{ "SHARED.FILE_MAX_SIZE" | translate }}</div>
            <div class="upload-container">
                <img class="upload-photo-icon" src="./../../../assets/icons/share-green.svg" alt="Upload photo">
                <p class="m-25" *ngIf="resolutionService.isMobileResolution">{{ "USER_FLOW.CHOOSE_FILE.LOAD_PHOTO" | translate }}</p>
            </div>
            
            <p class="m-20" *ngIf="!resolutionService.isMobileResolution">{{ "USER_FLOW.CHOOSE_FILE.LOAD_PHOTO" | translate }}</p>
    
            <label *ngIf="!resolutionService.isMobileResolution" class="custom-file-upload">
                <input #fileInput id="fileInput" type="file" class="hidden file" accept=".heic,.jpeg,.png,.jpg,.heif" (change)="onFileChange($event)"/>
                <span>{{ "USER_FLOW.CHOOSE_FILE.START" | translate }}</span>
            </label>
        </div>
        <div class="image-container">
            <img alt="Image Upload" src="./../../../assets/project-3d-images/Stylefinder_Image_Upload.jpg" class="image">
        </div>
    </div>
</div>
<ng-template #mobileUpload>
        <label class="choose-file-container choose-file-button-mobile">

            <input type="file" class="hidden file" accept=".heic,.jpeg,.png,.jpg,.heif" (change)="onFileChange($event)"/>
            <div class="upload-container">
                <img class="upload-photo-icon" src="./../../../assets/icons/share-green.svg" alt="Upload photo">
                <p class="m-25" *ngIf="resolutionService.isMobileResolution">{{ "USER_FLOW.CHOOSE_FILE.LOAD_PHOTO" | translate }}</p>
            </div>
            
            
            <p class="m-20" *ngIf="!resolutionService.isMobileResolution">{{ "USER_FLOW.CHOOSE_FILE.LOAD_PHOTO" | translate }}</p>

            <div class="warning" *ngIf="showWarning">{{ "USER_FLOW.CHOOSE_FILE.UNSUPPORTED_FORMAT" | translate }}</div>

            <div class="error" *ngIf="fileSizeService.showError | async">{{ "SHARED.FILE_MAX_SIZE" | translate }}</div>
            
        </label>
</ng-template>