<ng-container *ngIf="catalogData$ | async as catalogData">
  <div *ngIf="!selectedVariant" class="details-page-content">
    <div class="details-header">
      <a
        *ngIf="currentData.BasicArticleInformation | link"
        class="title"
        [ngClass]="{ 'm-18': resolutionService.isMobileResolution, 'r-16': !resolutionService.isMobileResolution }"
        [href]="currentData.BasicArticleInformation | link"
        target="_blank"
        >{{ currentData.BasicArticleInformation.Title }}</a
      >
      <p
        *ngIf="!(currentData.BasicArticleInformation | link)"
        class="title"
        [ngClass]="{ 'm-18': resolutionService.isMobileResolution, 'r-16': !resolutionService.isMobileResolution }"
      >
        <b>{{ currentData.BasicArticleInformation.Title }}</b>
      </p>
      <p class="type r-12">{{ 'USER_FLOW.CATALOG.' + currentData.BasicArticleInformation.Type | translate | uppercase }}</p>
    </div>

    <div class="image-wrapper furniture-container" *ngIf="currentData.BasicArticleInformation.PackshotImages">
      <img
        class="icon"
        *ngIf="checkModelLinkExist(currentData.BasicArticleInformation)"
        src="./../../../../assets/icons/noun-ar-2300882.svg"
        alt="3D"
      />
      <img class="image" [src]="activeImage | safe: 'url'" alt="image" />
      <div *ngIf="currentData.BasicArticleInformation.PackshotImages?.length > 1">
        <button
          class="icon-button previous"
          [disabled]="imageIndex - 1 < 0"
          (click)="previous(currentData.BasicArticleInformation.PackshotImages)"
        >
          <img *ngIf="imageIndex - 1 >= 0; else leftDisabled" class="arrow" src="../../../assets/icons/Gruppe 3005.svg" alt="previous" />
        </button>
        <button
          class="icon-button next"
          [disabled]="imageIndex + 1 >= currentData.BasicArticleInformation.PackshotImages.length"
          (click)="next(currentData.BasicArticleInformation.PackshotImages)"
        >
          <img
            *ngIf="imageIndex + 1 < currentData.BasicArticleInformation.PackshotImages.length; else rightDisabled"
            class="arrow transform"
            src="../../../assets/icons/Gruppe 3005.svg"
            alt="next"
          />
        </button>
      </div>
    </div>

    <ng-container class="action-buttons">
      <div class="action-buttons">
        <div class="button-wrapper">
          <button mat-icon-button (click)="remove(catalogData)">
            <img mat-icon class="icon-circle" src="./../../../assets/icons/Frame 817 (1).svg" alt="delete" />
          </button>
          <p class="button-label r-14">{{ 'BUTTONS.DELETE' | translate }}</p>
        </div>
        <div class="button-wrapper circle-button-wrapper">
          <button class="circle-button" mat-mini-fab (click)="replaceObjectAuto(catalogData)">
            <img class="refresh-button" src="./../../../assets/icons/adjust-proposal.png" alt="next" />
          </button>
          <p class="label r-14 refresh-label">{{ 'BUTTONS.REPLACE' | translate }}</p>
        </div>
        <div class="button-wrapper">
          <button mat-icon-button (click)="lockObject(catalogData)">
            <img
              mat-icon
              class="icon-circle"
              *ngIf="!!(articleLock$ | async) === false; else locked"
              src="./../../../../assets/icons/Gruppe 3135.svg"
              alt="active"
            />
          </button>
          <p class="button-label r-14">{{ 'BUTTONS.FIX' | translate }}</p>
        </div>
        <div class="button-wrapper">
          <button mat-icon-button (click)="cloneObject(catalogData)">
            <img mat-icon class="icon-circle" src="./../../../../assets/icons/Komponente 10 – 35.svg" alt="fix" />
          </button>
          <p class="button-label r-14">{{ 'BUTTONS.DUPLICATE' | translate }}</p>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="!resolutionService.isMobileResolution; then changeObject"></ng-container>

    <mat-expansion-panel #detailsPanel hideToggle="true" class="expansion-panel" *ngIf="currentData.BasicArticleInformation.SKU">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <p class="m-18">{{ 'USER_FLOW.DETAILS_PAGE.DETAILS' | translate }}</p>
        </mat-panel-title>
        <mat-panel-description>
          <img *ngIf="!detailsPanel.expanded" src="./../../../../assets/icons/Gruppe 3096.svg" alt="collapsed" />
          <img *ngIf="detailsPanel.expanded" src="./../../../../assets/icons/Gruppe 3096.svg" alt="expanded" />
        </mat-panel-description>
      </mat-expansion-panel-header>

      <p class="r-16">{{ description$ | async }}</p>
      <div>
        <p class="no-bottom-offset m-16">{{ 'USER_FLOW.DETAILS_PAGE.SKU' | translate }}</p>
        <p class="no-top-offset r-16">{{ currentData.BasicArticleInformation?.SKU }}</p>
      </div>

      <div>
        <p class="no-bottom-offset m-16">{{ 'USER_FLOW.DETAILS_PAGE.COLOR' | translate }}</p>
        <p class="no-top-offset r-16">{{ currentData.BasicArticleInformation | color }}</p>
      </div>

      <div>
        <p class="no-bottom-offset m-16">{{ 'USER_FLOW.DETAILS_PAGE.SIZE' | translate }}</p>
        <p class="no-top-offset r-16">{{ currentData.BasicArticleInformation | size }}</p>
      </div>
    </mat-expansion-panel>

    <ng-container *ngIf="isLightEditor && (isUserAdmin$ | async)">
      <mat-expansion-panel #lightEditorPanel hideToggle="true" class="expansion-panel" (afterExpand)="onLightEditorExpand()">
        <mat-expansion-panel-header>
          <mat-panel-title><p class="m-18">Light settings</p></mat-panel-title>
          <mat-panel-description>
            <img *ngIf="!lightEditorPanel.expanded" src="./../../../../assets/icons/Gruppe 3096.svg" alt="collapsed" />
            <img *ngIf="lightEditorPanel.expanded" src="./../../../../assets/icons/Gruppe 3096.svg" alt="expanded" />
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="light-settings">
          <mat-slide-toggle class="debug-toggle" [checked]="isLightHelperEnabled" (change)="onSlideChange($event)"
            >Debug light</mat-slide-toggle
          >
          <form [formGroup]="spotLightSettingsForm" class="light-settings__form">
            <p class="m-18">Angle {{ angleDegrees }}</p>
            <mat-slider [max]="maxAngle" min="0" step="0.01">
              <input matSliderThumb formControlName="angle" />
            </mat-slider>
            <p class="m-18">Decay {{ decay }}</p>
            <mat-slider max="2" min="1" step="0.001">
              <input matSliderThumb formControlName="decay" />
            </mat-slider>
            <p class="m-18">Distance {{ distance }}</p>
            <mat-slider max="10" min="0" step="0.00001">
              <input matSliderThumb formControlName="distance" />
            </mat-slider>
            <p class="m-18">Penumbra {{ penumbra }}</p>
            <mat-slider max="1" min="0" step="0.001">
              <input matSliderThumb formControlName="penumbra" />
            </mat-slider>
            <p class="m-18">Power {{ power }}</p>
            <mat-slider max="8000" min="0" step="0.0001">
              <input matSliderThumb formControlName="power" />
            </mat-slider>
            <div class="power-buttons">
              <button
                class="power-button"
                mat-button
                longPress
                (click)="onSlideButtonsClick('decrement')"
                (mouseLongPress)="onSlideButtonsClick('decrement')"
              >
                -
              </button>
              <button
                class="power-button"
                mat-button
                longPress
                (click)="onSlideButtonsClick('increment')"
                (mouseLongPress)="onSlideButtonsClick('increment')"
              >
                +
              </button>
            </div>
            <mat-checkbox formControlName="castShadow">Cast shadow</mat-checkbox>
          </form>
        </div>
      </mat-expansion-panel>
    </ng-container>

    <mat-expansion-panel #variantsPanel hideToggle="true" class="expansion-panel" *ngIf="currentData.BasicArticleInformation.SKU">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <p class="m-18">{{ 'USER_FLOW.DETAILS_PAGE.VARIANTS' | translate }}</p>
        </mat-panel-title>
        <mat-panel-description>
          <img *ngIf="!variantsPanel.expanded" src="./../../../../assets/icons/Gruppe 3096.svg" alt="collapsed" />
          <img *ngIf="variantsPanel.expanded" src="./../../../../assets/icons/Gruppe 3096.svg" alt="expanded" />
        </mat-panel-description>
      </mat-expansion-panel-header>

      <div class="variant-block">
        {{ 'USER_FLOW.DETAILS_PAGE.COLOR' | translate }}:
        <span *ngIf="currentData">{{ currentData.BasicArticleInformation | color }}</span>
      </div>

      <div class="variants">
        <div *ngFor="let variant of currentData.Variants" class="image-wrapper" (click)="setActiveVariant(variant)" aria-hidden="true">
          <img
            class="image"
            [src]="variant.BasicArticleInformation.PackshotImages[0] | safe: 'url'"
            [ngClass]="{ active: variant.BasicArticleInformation.SKU === currentData?.BasicArticleInformation.SKU }"
            alt="image"
          />
        </div>
      </div>
    </mat-expansion-panel>

    <div *ngIf="sizes?.length > 1">
      <p class="m-18">{{ 'USER_FLOW.DETAILS_PAGE.SIZE' | translate }}</p>
      <mat-form-field class="select">
        <mat-select [(value)]="defaultSize">
          <mat-option *ngFor="let size of sizes" [value]="size">
            <p class="m-18">{{ size | translate }}</p>
          </mat-option>
        </mat-select>
        <img matSuffix src="../../../../assets//icons/Gruppe 3096.svg" alt="arrow down" />
      </mat-form-field>
    </div>

    <div *ngIf="materials?.length > 1">
      <p class="m-18">{{ 'USER_FLOW.DETAILS_PAGE.MATERIAL' | translate }}</p>
      <mat-form-field class="select">
        <mat-select [(value)]="defaultMaterial">
          <mat-option *ngFor="let material of materials" [value]="material">
            <p class="m-18">{{ material | translate }}</p>
          </mat-option>
        </mat-select>
        <img matSuffix src="../../../../assets//icons/Gruppe 3096.svg" alt="arrow down" />
      </mat-form-field>
    </div>

    <div class="alternatives">
      <div *ngIf="alternatives$ | async as alternatives">
        <p class="alternatives-title m-18">{{ 'USER_FLOW.ALTERNATIVES' | translate }}</p>
        <div class="alternatives-container">
          <div *ngFor="let alternative of alternatives" class="alternative" (click)="setActiveVariant(alternative)" aria-hidden="true">
            <div class="image-wrapper alternative-wrapper">
              <img class="image" [src]="alternative.BasicArticleInformation.PackshotImages[0] | safe: 'url'" alt="image" />
            </div>
            <div class="description">
              <p class="r-12">{{ alternative.BasicArticleInformation.Title }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-variant-details *ngIf="selectedVariant" [variant]="selectedVariant" (back)="backFromVariant($event)"></app-variant-details>
</ng-container>

<ng-template #changeObject>
  <mat-expansion-panel
    *ngIf="checkModelLinkExist(currentData.BasicArticleInformation) || checkIsManuallyUploaded(currentData.BasicArticleInformation)"
    #moveObject
    hideToggle="true"
    class="expansion-panel"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>
        <p class="m-18">{{ 'USER_FLOW.DETAILS_PAGE.MOVE_OBJECT' | translate }}</p>
      </mat-panel-title>
      <mat-panel-description>
        <img *ngIf="!moveObject.expanded" src="./../../../../assets/icons/Gruppe 3096.svg" alt="collapsed" />
        <img *ngIf="moveObject.expanded" src="./../../../../assets/icons/Gruppe 3096.svg" alt="expanded" />
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div class="options">
      <div class="option-setting" *ngFor="let option of options">
        <button class="option-button" mat-button longPress (click)="option.actionNegative()" (mouseLongPress)="option.actionNegative()">
          -
        </button>
        <img class="icon" src="{{ option.iconSrc }}" alt="{{ option.name }}" />
        <button class="option-button" mat-button longPress (click)="option.actionPositive()" (mouseLongPress)="option.actionPositive()">
          +
        </button>
      </div>
    </div>

    <div class="option-setting">
      <span class="m-15">{{ 'USER_FLOW.DETAILS_PAGE.ADJUST_OBJECT' | translate }}</span>
    </div>
    <div class="options">
      <div class="option-setting" *ngFor="let option of advancedOptions">
        <button class="option-button" mat-button longPress (click)="option.actionNegative()" (mouseLongPress)="option.actionNegative()">
          -
        </button>
        <img class="icon" src="{{ option.iconSrc }}" alt="{{ option.name }}" />
        <button class="option-button" mat-button longPress (click)="option.actionPositive()" (mouseLongPress)="option.actionPositive()">
          +
        </button>
      </div>
      <div class="option-setting">
        <button class="option-button" mat-button (click)="flip()">
          {{ 'USER_FLOW.DETAILS_PAGE.FLIP' | translate }}
        </button>
      </div>
    </div>
  </mat-expansion-panel>
</ng-template>

<ng-template #locked>
  <img mat-icon class="icon-circle" src="./../../../../assets/icons/Gruppe 3289.svg" alt="locked" />
</ng-template>

<ng-template #leftDisabled>
  <img class="arrow" src="../../../assets/icons/Gruppe 3005 disabled.svg" alt="left arrow Disabled" />
</ng-template>

<ng-template #rightDisabled>
  <img class="arrow transform" src="../../../assets/icons/Gruppe 3005 disabled.svg" alt="right arrow Disabled" />
</ng-template>
