import { createAction, props } from '@ngrx/store';

export const DELETE_SELECTION = '[Image Step] delete selection';
export const DELETE_SELECTION_SUCCESS = '[Image Step] delete selection Success';
export const FILE_UPDATED = '[Image Step] file updated';
export const UPDATE_CLEAR_BUTTON_STATE = '[Image Step] update clear button state';
export const CLEAR_IMAGE_DATA = '[Image Step]  set initial data';
export const CLEAR_IMAGE = '[Remove Editor] add image';
export const UPDATE_IMAGE = '[Remove Editor] update image';

export const deleteSelection = createAction(DELETE_SELECTION, props<{ image: Blob; mask: Blob }>());

export const deleteSelectionSuccess = createAction(DELETE_SELECTION_SUCCESS, props<{ src: string }>());

export const fileUpdated = createAction(FILE_UPDATED);

export const updateClearButtonState = createAction(UPDATE_CLEAR_BUTTON_STATE, props<{ state: boolean }>());

export const clearImageState = createAction(CLEAR_IMAGE_DATA);

export const addImage = createAction(CLEAR_IMAGE, props<{ data: { prevSrc: string; nextSrc: string } }>());

export const updateImage = createAction(UPDATE_IMAGE, props<{ src: string }>());
