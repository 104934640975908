<div 
    class="back" 
    (click)="back()" 
    *ngIf="!resolutionService.isMobileResolution"
    aria-hidden="true"
>
    <img class="icon" src="./../../../../assets/icons/arrow-left-slide.png" alt="arrow-left">
    <span class="r-16">{{ 'IDEAS.FURNITURE_LIST.BACK' | translate}}</span>
</div>

<div class="idea-furniture">
    <div class="idea-furniture-header">
        <p class="r-16">{{ 'IDEAS.FURNITURE_LIST.TITLE' | translate}}</p>
    </div>
    <div *ngIf="!(ideaFurniture$ | async)?.length" class="no-data r-16">{{ "USER_FLOW.CATALOG.NO_DATA" | translate}}</div>
    <div class="idea-furniture-content">
        <div 
            class="article-item" 
            (click)="viewDetails(furniture)"
            *ngFor="let furniture of (ideaFurniture$ | async); let i = index"
            aria-hidden="true"
        >
            <div class="image-wrapper" *ngIf="furniture.BasicArticleInformation.PackshotImages">
                <img 
                    class="box" 
                    [src]="furniture.BasicArticleInformation.PackshotImages[0] | safe: 'url'" 
                    alt="image"
                />
            </div>
            <div class="article-description">
                <div class="article-text r-14">{{ furniture.BasicArticleInformation.Title }}</div>
            </div>
        </div>
    </div>
</div>