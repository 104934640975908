<div class="page-container">
  <div *ngIf="resolutionService.isMobileResolution">
    <img
      class="back-arrow"
      src="./../../../assets/icons/arrow-left-short.svg"
      alt="Go back"
      (click)="back()"
      aria-hidden="true"
    />
  </div>
  <div class="actions" *ngIf="!resolutionService.isMobileResolution">
    <div class="action-buttons action-buttons-left">
      <img
        class="back-arrow"
        src="./../../../assets/icons/arrow-left-short.svg"
        alt="Go back"
        (click)="back()"
        aria-hidden="true"
      />
    </div>
    <div class="action-buttons action-buttons-right">
      <div class="button-wrapper icon-button" (click)="startButton.click()" aria-hidden="true">
        <div class="background">
          <img
            class="icon start"
            src="assets/manual-reconstruction/neustart.svg"
            alt="start reconstruction"
          />
        </div>
        <button
          #startButton
          class="button r-14"
          (click)="restartManualReconstruct()"
        >
          {{ "BUTTONS.START" | translate }}
        </button>
      </div>
      <div
        class="button-wrapper icon-button"
        [ngClass]="{
          'icon-button_active': this.clickType === CLICK_TYPES.MOVE_WALL_POINT
        }"
        (click)="moveCornersButton.click()"
        aria-hidden="true"
      >
        <div class="background">
          <img class="icon" src="assets/manual-reconstruction/ecken.svg" alt="ecken" />
        </div>
        <button
          #moveCornersButton
          class="button r-14"
          [disabled]="!hasEnoughCornersForMoveCorners"
          (click)="moveCornersEnable()"
        >
          {{ "BUTTONS.MOVE_CORNERS" | translate }}
        </button>
      </div>
      <div
        class="button-wrapper icon-button"
        [ngClass]="{
          'icon-button_active': this.clickType === CLICK_TYPES.PLACE_DOOR
        }"
        (click)="doorsButton.click()"
        aria-hidden="true"
      >
        <div class="background">
          <img class="icon" src="assets/manual-reconstruction/tur.svg" alt="tur"/>
        </div>
        <button
          #doorsButton
          [disabled]="!hasReconstruction"
          (click)="placeDoor()"
          class="button r-14"
        >
          {{ "BUTTONS.PLACE_DOOR" | translate }}
        </button>
      </div>
      <div
        class="button-wrapper icon-button"
        [ngClass]="{
          'icon-button_active': this.clickType === CLICK_TYPES.PLACE_WINDOW
        }"
        (click)="windowsButton.click()"
        aria-hidden="true"
      >
        <div class="background">
          <img class="icon" src="assets/manual-reconstruction/fenster.svg" alt="fenster"/>
        </div>
        <button
          #windowsButton
          [disabled]="!hasReconstruction"
          (click)="placeWindow()"
          class="button r-14"
        >
          {{ "BUTTONS.PLACE_WINDOW" | translate }}
        </button>
      </div>
      <div class="button-wrapper icon-button" (click)="deleteButton.click()" aria-hidden="true">
        <div class="background">
          <img class="big-icon" src="./../../../assets/icons/3070.svg" alt="delete" />
        </div>
        <button
          #deleteButton
          [disabled]="!hasReconstruction"
          (click)="deleteHole()"
          class="button r-14"
        >
          {{ "BUTTONS.DELETE_HOLE" | translate }}
        </button>
      </div>
      <button
        class="button button-ellipse m-25"
        mat-stroked-button
        [disabled]="!hasEnoughCornersForReconstruction || hasReconstruction"
        (click)="completeManualReconstruct()"
      >
        {{ "BUTTONS.RECONSTRUCT" | translate }}
      </button>
      <button
        class="button button-last m-25"
        mat-raised-button
        color="primary"
        [disabled]="!hasReconstruction"
        (click)="next()"
      >
        {{ "BUTTONS.NEXT" | translate }}
      </button>
    </div>
  </div>
  <div class="content">
    <div class="instruction-container">
      <app-manual-reconstruct-instruction
        *ngIf="!resolutionService.isMobileResolution"
        class="instruction"
      >
      </app-manual-reconstruct-instruction>
    </div>
    <div class="render-container">
      <app-render
        #render
        [clickType]="clickType"
        [canScrollThrow]="false"
        (doorPlace)="onDoorPlace()"
        (windowPlace)="onWindowPlace()"
        class="render"
      >
      </app-render>
    </div>
  </div>
  <div class="actions-mobile">
    <div class="actions-block">
      <div class="button-wrapper circle-button-wrapper">
        <button
          class="circle-button"
          mat-icon-button
          (click)="restartManualReconstruct()"
        >
          <img
            mat-icon
            class="icon-mobile"
            src="./../../../assets/manual-reconstruction/neustart2.svg"
            alt="restart"
          />
          <p class="label r-14">{{ "BUTTONS.START" | translate }}</p>
        </button>
      </div>
      <div class="button-wrapper circle-button-wrapper">
        <button
          class="circle-button"
          mat-icon-button
          [disabled]="!hasReconstruction"
          (click)="placeWindow()"
        >
          <img
            mat-icon
            class="icon-mobile"
            src="./../../../assets/icons/Gruppe 3045 (1).svg"
            alt="place window"
          />
        </button>
        <p class="label r-14">{{ "BUTTONS.PLACE_WINDOW" | translate }}</p>
      </div>
      <div class="button-wrapper circle-button-wrapper">
        <button
          class="circle-button"
          mat-icon-button
          [disabled]="!hasReconstruction"
          (click)="placeDoor()"
        >
          <img
            mat-icon
            class="icon-mobile"
            src="./../../../assets/icons/Gruppe 3046 (1).svg"
            alt="place door"
          />
        </button>
        <p class="label r-14">{{ "BUTTONS.PLACE_DOOR" | translate }}</p>
      </div>
      <div class="button-wrapper circle-button-wrapper">
        <button
          class="circle-button"
          mat-icon-button
          [disabled]="!hasReconstruction"
          (click)="deleteHole()"
        >
          <img
            mat-icon
            class="icon-mobile"
            src="./../../../assets/icons/3070.svg"
            alt="delete hole"
          />
        </button>
        <p class="label r-14">{{ "BUTTONS.DELETE_HOLE" | translate }}</p>
      </div>
      <div class="button-wrapper">
        <button
          class="circle-button"
          mat-icon-button
          (click)="openInstructionDialog()"
        >
          <img
            mat-icon
            class="icon-mobile"
            src="./../../../assets/icons/Icon Question_1.svg"
            alt="change width"
          />
        </button>
      </div>
    </div>
    <mat-expansion-panel class="panel">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ "BUTTONS.MOVE_CORNERS_MOBILE" | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="panel-content">
        <div *ngFor="let option of mobileOptions" class="option-setting">
          <button
            class="option-button disable-double-touch-zoom"
            longPress
            mat-stroked-button
            (click)="option.actionNegative()"
            (mouseLongPress)="option.actionNegative()"
          >
            -
          </button>
          <img
            mat-icon
            class="icon-mobile"
            [src]="option.iconSrc"
            alt="change width"
          />
          <button
            class="option-button disable-double-touch-zoom"
            longPress
            mat-stroked-button
            (click)="option.actionPositive()"
            (mouseLongPress)="option.actionPositive()"
          >
            +
          </button>
        </div>
        <div class="panel-toggle_container">
          <mat-slide-toggle
            labelPosition="before"
            class="panel-toggle"
            [checked]="toggleChecked()"
            (change)="toggleMoveCorners($event)"
          >
            {{ "USER_FLOW.MANUAL_RECONSTRUCTION.CHOOSE" | translate }}
          </mat-slide-toggle>
        </div>
      </div>
    </mat-expansion-panel>
    <div class="bottom-buttons">
      <button
        class="button button-ellipse button-bottom"
        mat-raised-button
        color="white"
        [disabled]="!hasEnoughCornersForReconstruction || hasReconstruction"
        (click)="completeManualReconstruct()"
      >
        {{ "BUTTONS.RECONSTRUCT" | translate }}
      </button>
    </div>
  </div>
  <div class="next-wrapper" *ngIf="resolutionService.isMobileResolution">
    <button
      class="button button-bottom"
      mat-raised-button
      color="primary"
      [disabled]="!hasReconstruction"
      (click)="next()"
    >
      {{ "BUTTONS.NEXT" | translate }}
    </button>
  </div>
</div>
