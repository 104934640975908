<div *ngIf="(furniture$ | async) && !selectedVariant " class="details-page-content">
    <div 
        class="back" 
        (click)="back()"
        aria-hidden="true"
    >
        <img class="icon" src="./../../../../assets/icons/arrow-left-slide.png" alt="next">
        <span class="r-16">{{ 'IDEAS.FURNITURE_LIST.TITLE' | translate}}</span>
    </div>
    <div class="details-header">
        <a *ngIf="currentData.BasicArticleInformation | link" class="title r-16" [href]="currentData.BasicArticleInformation | link" target="_blank">{{ currentData.BasicArticleInformation.Title }}</a>
        <p *ngIf="!(currentData.BasicArticleInformation | link)" class="title r-16"><b>{{ currentData.BasicArticleInformation.Title }}</b></p>
        <p class="type r-12">{{ "USER_FLOW.CATALOG." + currentData.BasicArticleInformation.Type | translate | uppercase }}</p>
    </div>

    <div class="image-wrapper furniture-container" *ngIf="currentData.BasicArticleInformation.PackshotImages">
        <img 
            class="image" 
            [src]="activeImage | safe: 'url'" 
            alt="image"
        />
        <div *ngIf="currentData.BasicArticleInformation.PackshotImages?.length > 1">
            <button 
                class="icon-button previous" 
                [disabled]="imageIndex-1<0" 
                (click)="previous(currentData.BasicArticleInformation.PackshotImages)"
            >
                <img 
                    *ngIf="imageIndex-1>=0;else leftDisabled" 
                    class="arrow" 
                    src="../../../assets/icons/Gruppe 3005.svg"
                    alt="back"
                >
            </button>
            <button 
                class="icon-button next" 
                [disabled]="imageIndex+1>=currentData.BasicArticleInformation.PackshotImages.length" 
                (click)="next(currentData.BasicArticleInformation.PackshotImages)"
            >
                <img 
                    *ngIf="imageIndex+1<currentData.BasicArticleInformation.PackshotImages.length;else rightDisabled" 
                    class="arrow transform" 
                    src="../../../assets/icons/Gruppe 3005.svg"
                    alt="next"
                >
            </button>
        </div>
    </div>
    <button class="button button-secondary buy-button" mat-button (click)="openCatalog(currentData.BasicArticleInformation)">{{'BUTTONS.BUY' | translate| uppercase}}</button>

    <mat-expansion-panel #detailsPanel hideToggle="true" class="expansion-panel" *ngIf="currentData.BasicArticleInformation.SKU">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <p class="m-18">{{ "USER_FLOW.DETAILS_PAGE.DETAILS" | translate }}</p>
            </mat-panel-title>
            <mat-panel-description>
                <img 
                    *ngIf="!detailsPanel.expanded" 
                    src="./../../../../assets/icons/Gruppe 3096.svg"
                    alt="collapsed"
                >
                <img 
                    *ngIf="detailsPanel.expanded"  
                    src="./../../../../assets/icons/Gruppe 3096.svg"
                    alt="expanded"
                >
              </mat-panel-description>
        </mat-expansion-panel-header>
        

        <p class="r-16">{{ description$ | async }}</p>
        <div>
            <p class="no-bottom-offset m-16">{{ "USER_FLOW.DETAILS_PAGE.SKU" | translate }}</p>
            <p class="no-top-offset r-16">{{ currentData.BasicArticleInformation?.SKU }}</p>
        </div>

        <div>
            <p class="no-bottom-offset m-16">{{ "USER_FLOW.DETAILS_PAGE.COLOR" | translate }}</p>
            <p class="no-top-offset r-16">{{ currentData.BasicArticleInformation | color }}</p>
        </div>

        <div>
            <p class="no-bottom-offset m-16">{{ "USER_FLOW.DETAILS_PAGE.SIZE" | translate }}</p>
            <p class="no-top-offset r-16">{{ currentData.BasicArticleInformation | size }}</p>
        </div>

    </mat-expansion-panel>

    <mat-expansion-panel #variantsPanel hideToggle="true" class="expansion-panel" *ngIf="currentData.BasicArticleInformation.SKU">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <p class="m-18">{{ "USER_FLOW.DETAILS_PAGE.VARIANTS" | translate }}</p>
            </mat-panel-title>
            <mat-panel-description>
                <img 
                    *ngIf="!variantsPanel.expanded" 
                    src="./../../../../assets/icons/Gruppe 3096.svg"
                    alt="collapsed"
                >
                <img 
                    *ngIf="variantsPanel.expanded"  
                    src="./../../../../assets/icons/Gruppe 3096.svg"
                    alt="expanded"
                >
              </mat-panel-description>
        </mat-expansion-panel-header>

        <div class="variant-block">
            {{ "USER_FLOW.DETAILS_PAGE.COLOR" | translate }}:
            <span *ngIf="currentData">{{ currentData.BasicArticleInformation | color }}</span>
        </div>

        <div class="variants">
            <div 
                *ngFor="let variant of currentData.Variants" 
                class="image-wrapper" 
                (click)="setActiveVariant(variant)"
                aria-hidden="true"
            >
                <img 
                    class="image" 
                    [src]="variant.BasicArticleInformation.PackshotImages[0] | safe: 'url'"
                    alt="variant"
                    [ngClass]="{'active': variant.BasicArticleInformation.SKU === currentData?.BasicArticleInformation.SKU}"
                />
            </div>
        </div>
    </mat-expansion-panel>
</div>

<app-idea-variant-details *ngIf="selectedVariant" [variant]="selectedVariant" (back)="backFromVariant($event)"></app-idea-variant-details>

<ng-template #leftDisabled>
    <img 
        class="arrow" 
        src="../../../assets/icons/Gruppe 3005 disabled.svg"
        alt="left arrow disabled"
    >
</ng-template>

<ng-template #rightDisabled>
    <img 
        class="arrow transform" 
        src="../../../assets/icons/Gruppe 3005 disabled.svg"
        alt="right arrow disabled"
        >
</ng-template>