<h2 mat-dialog-title>{{ "USER_FLOW.LABELS.SET_HEIGHT_DIALOG_TITLE" | translate }}</h2>
<mat-dialog-content>
  <mat-form-field>
    <mat-label>{{ "USER_FLOW.LABELS.HEIGHT" | translate }}</mat-label>
    <input matInput [formControl]="heightControl" type="number">
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button [disabled]="heightControl.invalid" (click)="close()">{{ "BUTTONS.CONFIRM" | translate}}</button>
</mat-dialog-actions>
