import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'style',
})
export class StylePipe implements PipeTransform {
  public transform(value: string): string {
    return `IDEAS.STYLES.${value}`;
  }
}
