export const TYPES: { name: string; value: string }[] = [
  { name: 'IDEAS.TYPES.LIVING_ROOM', value: 'LIVING_ROOM' },
  { name: 'IDEAS.TYPES.DINING_ROOM', value: 'DINING_ROOM' },
  { name: 'IDEAS.TYPES.SLEEPING_ROOM', value: 'SLEEPING_ROOM' },
  { name: 'IDEAS.TYPES.CHILDREN_ROOM', value: 'CHILDREN_ROOM' },
  { name: 'IDEAS.TYPES.OFFICE', value: 'OFFICE' },
  { name: 'IDEAS.TYPES.BATHROOM', value: 'BATHROOM' },
  { name: 'IDEAS.TYPES.TERRACE', value: 'TERRACE' },
  { name: 'IDEAS.TYPES.BALCONY', value: 'BALCONY' },
];
