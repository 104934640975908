import { AfterViewInit, Directive, Host, Input, Optional, Renderer2, Self, ViewContainerRef } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatPaginator } from '@angular/material/paginator';

interface PageObject {
  length: number;
  pageIndex: number;
  pageSize: number;
  previousPageIndex: number;
}

@Directive({
  selector: '[stylePaginator]',
})
export class StylePaginatorDirective implements AfterViewInit {
  private _pageGapTxt = '...';
  private _rangeStart: number;
  private _rangeEnd: number;
  private _buttons = [];
  private _curPageObj: PageObject = {
    length: 0,
    pageIndex: 0,
    pageSize: 0,
    previousPageIndex: 0,
  };

  @Input()
  public get showTotalPages(): number {
    return this._showTotalPages;
  }
  public set showTotalPages(value: number) {
    this._showTotalPages = value % 2 == 0 ? value + 1 : value;
  }
  private _showTotalPages = 3;

  public get inc(): number {
    return this._showTotalPages % 2 == 0 ? this.showTotalPages / 2 : (this.showTotalPages - 1) / 2;
  }

  public get numOfPages(): number {
    return this.matPag.getNumberOfPages();
  }

  public get lastPageIndex(): number {
    return this.matPag.getNumberOfPages() - 1;
  }

  constructor(
    @Host() @Self() @Optional() private readonly matPag: MatPaginator,
    private vr: ViewContainerRef,
    private ren: Renderer2
  ) {}

  private buildPageNumbers(): void {
    this.vr.element.nativeElement.querySelector('div.mat-mdc-paginator-range-label')?.remove();
    this.vr.element.nativeElement.querySelector('div.mat-mdc-paginator-page-size')?.remove();
    const actionContainer = this.vr.element.nativeElement.querySelector('div.mat-mdc-paginator-range-actions');
    const nextPageNode = this.vr.element.nativeElement.querySelector('button.mat-mdc-paginator-navigation-next');

    if (this._buttons.length > 0) {
      this._buttons.forEach(button => {
        this.ren.removeChild(actionContainer, button);
      });

      this._buttons.length = 0;
    }

    if (this._buttons.length == 0) {
      const nodeArray = this.vr.element.nativeElement.childNodes[0].childNodes[0].childNodes[0].childNodes;
      setTimeout(() => {
        for (let i = 0; i < nodeArray.length; i++) {
          if (nodeArray[i].nodeName === 'BUTTON') {
            if (nodeArray[i].innerHTML.length > 100 && nodeArray[i].disabled) {
              this.ren.setStyle(nodeArray[i], 'background-color', '#C0C0C0');
              this.ren.setStyle(nodeArray[i], 'color', 'white');
              this.ren.setStyle(nodeArray[i], 'margin', '.5%');
            } else if (nodeArray[i].innerHTML.length > 100 && !nodeArray[i].disabled) {
              this.ren.setStyle(nodeArray[i], 'background-color', '#00796B');
              this.ren.setStyle(nodeArray[i], 'color', 'white');
              this.ren.setStyle(nodeArray[i], 'margin', '.5%');
            } else if (nodeArray[i].disabled) {
              this.ren.setStyle(nodeArray[i], 'background-color', 'lightgray');
            }
          }
        }
      });
    }

    for (let i = 0; i < this.numOfPages; i++) {
      if (i >= this._rangeStart && i <= this._rangeEnd) {
        this.ren.insertBefore(actionContainer, this.createButton(i, this.matPag.pageIndex), nextPageNode);
      }

      if (i == this._rangeEnd) {
        this.ren.insertBefore(actionContainer, this.createButton(this._pageGapTxt, this._rangeEnd), nextPageNode);
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private createButton(i: any, pageIndex: number): MatButton {
    const linkBtn: MatButton = this.ren.createElement('button');
    this.ren.setAttribute(linkBtn, 'mat-mdc-mini-fab', '');
    this.ren.addClass(linkBtn, 'mat-mdc-mini-fab');
    this.ren.setStyle(linkBtn, 'width', '36px');
    this.ren.setStyle(linkBtn, 'height', '36px');
    this.ren.setStyle(linkBtn, 'margin', '1%');
    this.ren.setStyle(linkBtn, 'background-color', 'white');
    this.ren.setStyle(linkBtn, 'border', '1px solid #C0C0C0');

    const pagingTxt = isNaN(i) ? this._pageGapTxt : +(i + 1);
    const text = this.ren.createText(pagingTxt + '');

    this.ren.addClass(linkBtn, 'mat-mdc-custom-page');
    switch (i) {
      case pageIndex:
        this.ren.setAttribute(linkBtn, 'disabled', 'disabled');
        break;
      case this._pageGapTxt:
        let newIndex = this._curPageObj.pageIndex + this._showTotalPages;

        if (newIndex >= this.numOfPages) newIndex = this.lastPageIndex;

        if (pageIndex != this.lastPageIndex) {
          this.ren.listen(linkBtn, 'click', () => {
            this.switchPage(newIndex);
          });
        }

        if (pageIndex == this.lastPageIndex) {
          this.ren.setAttribute(linkBtn, 'disabled', 'disabled');
        }
        break;
      default:
        this.ren.listen(linkBtn, 'click', () => {
          this.switchPage(i);
        });
        break;
    }

    this.ren.appendChild(linkBtn, text);
    this._buttons.push(linkBtn);
    return linkBtn;
  }

  private initPageRange(): void {
    const middleIndex = (this._rangeStart + this._rangeEnd) / 2;

    this._rangeStart = this.calcRangeStart(middleIndex);
    this._rangeEnd = this.calcRangeEnd(middleIndex);

    this.buildPageNumbers();
  }

  private calcRangeStart(middleIndex: number): number {
    switch (true) {
      case this._curPageObj.pageIndex == 0 && this._rangeStart != 0:
        return 0;
      case this._curPageObj.pageIndex > this._rangeEnd:
        return this._curPageObj.pageIndex + this.inc > this.lastPageIndex
          ? this.lastPageIndex - this.inc * 2
          : this._curPageObj.pageIndex - this.inc;
      case this._curPageObj.pageIndex > this._curPageObj.previousPageIndex &&
        this._curPageObj.pageIndex > middleIndex &&
        this._rangeEnd < this.lastPageIndex:
        return this._rangeStart + 1;
      case this._curPageObj.pageIndex < this._curPageObj.previousPageIndex &&
        this._curPageObj.pageIndex < middleIndex &&
        this._rangeStart > 0:
        return this._rangeStart - 1;
      default:
        return this._rangeStart;
    }
  }

  private calcRangeEnd(middleIndex: number): number {
    switch (true) {
      case this._curPageObj.pageIndex == 0 && this._rangeEnd != this._showTotalPages:
        return this._showTotalPages - 1;
      case this._curPageObj.pageIndex > this._rangeEnd:
        return this._curPageObj.pageIndex + this.inc > this.lastPageIndex ? this.lastPageIndex : this._curPageObj.pageIndex + 1;
      case this._curPageObj.pageIndex > this._curPageObj.previousPageIndex &&
        this._curPageObj.pageIndex > middleIndex &&
        this._rangeEnd < this.lastPageIndex:
        return this._rangeEnd + 1;
      case this._curPageObj.pageIndex < this._curPageObj.previousPageIndex &&
        this._curPageObj.pageIndex < middleIndex &&
        this._rangeStart >= 0 &&
        this._rangeEnd > this._showTotalPages - 1:
        return this._rangeEnd - 1;
      default:
        return this._rangeEnd;
    }
  }

  private switchPage(i: number): void {
    const previousPageIndex = this.matPag.pageIndex;
    this.matPag.pageIndex = i;
    this.matPag['_emitPageEvent'](previousPageIndex);
    this.initPageRange();
  }

  public ngAfterViewInit(): void {
    this._rangeStart = 0;
    this._rangeEnd = this._showTotalPages - 1;
    this._curPageObj.length = this.matPag.getNumberOfPages();
    this._curPageObj.pageIndex = this.matPag.pageIndex;
    this._curPageObj.pageSize = this.matPag.pageSize;
    this.initPageRange();
    this.matPag.page.subscribe((e: PageObject) => {
      setTimeout(() => {
        if (this._curPageObj.pageSize != e.pageSize && this._curPageObj.pageIndex != 0) {
          e.pageIndex = 0;
          this._rangeStart = 0;
          this._rangeEnd = this._showTotalPages - 1;
        }
        this._curPageObj = e;

        this.initPageRange();
      }, 1000);
    });
  }
}
