import { Location } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import {
  catchError,
  combineLatest,
  concatMap,
  filter,
  forkJoin,
  from,
  map,
  mergeMap,
  Observable,
  of,
  Subject,
  switchMap,
  take,
  takeUntil,
} from 'rxjs';
import { Mode } from 'src/app/shared/enums/mode.enum';
import {
  AmbientLight,
  Float32BufferAttribute,
  Group,
  Mesh,
  Object3D,
  ObjectLoader,
  PCFSoftShadowMap,
  PerspectiveCamera,
  Scene,
  SpotLight,
  TextureLoader,
  Vector2,
  Vector3,
  WebGLRenderer,
} from 'three';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass';
import { CSS2DRenderer } from 'three/examples/jsm/renderers/CSS2DRenderer';
import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader';

import { Action, RenderInputData } from './render-input-data.model';
import { RotationDialogComponent } from './rotation-dialog/rotation-dialog.component';
import { clearProposal, makeProposal, runUserFlow } from '../../store/actions/shared.actions';
import { updateVersion } from '../../store/actions/versions.actions';
import { reconstructionResult } from '../../store/selectors/shared.selector';
import { redoAction, undoAction } from '../../undo-redo/undo-redo.actions';
import { ClickType } from '../enum/click.enum';
import { RoomType } from '../enum/room-type.enum';
import { PointType } from '../manual-reconstruct-editor/holes.enum';
import { WallPointGroup } from '../services/class/wall-point-group.class';
import { WallPoint } from '../services/class/wall-point.class';
import { CornersControlsService } from '../services/corners-controls.service';
import { ExportService } from '../services/export.service';
import { HighlightedObjectsService } from '../services/highlighted-objects.service';
import { ImportService } from '../services/import.service';
import { KeyboardStateService } from '../services/keyboard-state.service';
import { LightObjectsService } from '../services/light-objects.service';
import { ManualReconstructionService } from '../services/manual-reconstruction.service';
import { MovingObject, MovingObjectSave } from '../services/model/dto/moving-object.model';
import { RoomPlane, RoomPlaneSaveLoad } from '../services/model/dto/plane.model';
import { MovingObjectsService } from '../services/moving-objects.service';
import { OriginalImageSizeService } from '../services/original-image-size.service';
import { PhotorealisticRenderService } from '../services/photorealistic-render.service';
import { PlanesService } from '../services/planes.service';
import { ResolutionService } from '../services/resolution.service';
import { RoomParamsService } from '../services/room-params.service';
import { RoomService } from '../services/room.service';
import { VersionService } from '../services/version.service';

@UntilDestroy()
@Component({
  selector: 'app-render',
  templateUrl: './render.component.html',
  styleUrls: ['./render.component.scss'],
})
export class RenderComponent implements OnDestroy, OnChanges {
  @ViewChild('canvas') private canvasRef!: ElementRef;
  @ViewChild('labels') private labelsRef!: ElementRef;

  @Output() public windowPlace = new EventEmitter<void>();
  @Output() public doorPlace = new EventEmitter<void>();
  @Output() public wallClick = new EventEmitter<RoomPlane>();

  @Input() public clickType: ClickType;
  @Input() public canScrollThrow = false;
  @Input() public debugOutlineEnabled = false;

  private _mode: Mode;
  private _renderInputData: RenderInputData;
  private _labelRender: CSS2DRenderer;

  private get canvas(): HTMLCanvasElement {
    return this.canvasRef?.nativeElement;
  }

  private get labels(): HTMLElement {
    return this.labelsRef?.nativeElement;
  }

  private _camera!: PerspectiveCamera;
  private _renderer!: WebGLRenderer;

  private _composer!: EffectComposer;
  private _renderPass!: RenderPass;
  private outlinePass!: OutlinePass;
  private _effectFXAA!: ShaderPass;
  private _scene: Scene = new Scene();

  private unsubscribe$ = new Subject<void>();

  constructor(
    private _exportService: ExportService,
    private _importService: ImportService,
    private _keyboardService: KeyboardStateService,
    private _movingObjectsService: MovingObjectsService,
    private _lightObjectsService: LightObjectsService,
    private _photorealisticRenderService: PhotorealisticRenderService,
    private _planesService: PlanesService,
    private _originalImageSizeService: OriginalImageSizeService,
    private _roomParamsService: RoomParamsService,
    private _roomService: RoomService,
    private _highlightedObjectsService: HighlightedObjectsService,
    private _cornersControlsService: CornersControlsService,
    private _manualReconstructionService: ManualReconstructionService,
    private _store: Store,
    private _resolutionService: ResolutionService,
    private _versionService: VersionService,
    private _dialog: MatDialog,
    private _location: Location
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['canScrollThrow']) {
      this._updateScrolling();
    }
  }

  public run(mode: Mode, renderInputData: RenderInputData): Observable<void> {
    this._mode = mode;
    this._renderInputData = renderInputData;

    return this._prepareScene().pipe(
      switchMap(() =>
        this._renderInputData.actions ? this.makeActions(this._renderInputData.actions, this._renderInputData.type) : of(null)
      )
    );
  }

  public makeActions(actions: Action[], roomType?: RoomType): Observable<void> {
    return actions?.length > 0 ? from(actions.map(action => this._getAction(action, roomType))).pipe(concatMap(req => req)) : of(null);
  }

  private _getAction(action: Action, roomType: RoomType): Observable<void> {
    return new Observable(subscriber => {
      switch (action) {
        case Action.FINISH_MANUAL_RECONSTRUCT:
          this._roomService.completeManualReconstruction();
          this._roomService.setSavedWindows().subscribe(() => {
            subscriber.next();
            subscriber.complete();
          });
          break;
        case Action.HIDE_RECONSTRUCTION_COLOR:
          this._roomService.hideReconstructionColor();
          subscriber.next();
          subscriber.complete();
          break;
        case Action.MAKE_AUTO_RECONSTRUCT:
          this._roomService.reconstructAuto().subscribe(() => {
            subscriber.next();
            subscriber.complete();
          });
          break;
        case Action.LOAD_AUTO_RECONSTRUCT:
          this._store
            .select(reconstructionResult)
            .pipe(
              filter(data => !!data),
              take(1),
              untilDestroyed(this)
            )
            .subscribe(data => {
              const copy = _.cloneDeep(data);
              this._roomService.handleInteriorResults(copy);
              subscriber.next();
              subscriber.complete();
            });
          break;
        case Action.CLEAR_SCENE:
          this._roomService.clearScene();
          subscriber.next();
          subscriber.complete();
          break;
        case Action.CLEAR_MANUAL_RECONSTRUCTION:
          this._manualReconstructionService.clearReconstruction();
          subscriber.next();
          subscriber.complete();
          break;
        case Action.REFRESH_SCENE:
          this._roomService.refreshReconstruction();
          subscriber.next();
          subscriber.complete();
          break;
        case Action.CHANGE_ROOM_HEIGHT:
          this._roomParamsService
            .setRoomHeightDialog(true)
            .pipe(untilDestroyed(this))
            .subscribe(() => {
              subscriber.next();
              subscriber.complete();
            });
          break;
        case Action.BACKWARD:
          this._store.dispatch(undoAction());
          subscriber.next();
          subscriber.complete();
          break;
        case Action.FORWARD:
          this._store.dispatch(redoAction());
          subscriber.next();
          subscriber.complete();
          break;
        case Action.SAVE_VERSION:
          this._versionService
            .saveVersion()
            .pipe(
              switchMap(({ version }) => this._photorealisticRenderService.updateRenderedProject({ ...version, needUpdateVersion: false }))
            )
            .subscribe(projectAdditionalInfo => {
              this._location.replaceState(
                window.location.pathname,
                new URLSearchParams({ projectId: projectAdditionalInfo.id, versionId: projectAdditionalInfo.versionId }).toString()
              );

              subscriber.next();
              subscriber.complete();
            });
          break;
        case Action.UPDATE_VERSION:
          this._store.dispatch(updateVersion());
          subscriber.next();
          subscriber.complete();
          break;
        case Action.MAKE_PROPOSAL:
          this._store.dispatch(makeProposal());
          subscriber.next();
          subscriber.complete();
          break;
        case Action.CLEAR_FURNITURE:
          this._store.dispatch(clearProposal());
          subscriber.next();
          subscriber.complete();
          break;
        case Action.MAKE_PROPOSAL_AND_RENDER:
          this._store.dispatch(runUserFlow({ roomType }));
          subscriber.next();
          subscriber.complete();
          break;
        case Action.START_RENDER:
          this._photorealisticRenderService.render(this, true);
          subscriber.next();
          subscriber.complete();
          break;
        case Action.SHOW_RENDER:
          this._photorealisticRenderService.openRendersWindow();
          subscriber.next();
          subscriber.complete();
          break;
      }
    });
  }

  private _prepareScene(): Observable<void> {
    return of(this._renderInputData?.sceneSaveData).pipe(
      switchMap(hasScene => (hasScene ? this._loadScene() : this._createScene())),
      map(() => this._startEditor(this._renderInputData.width, this._renderInputData.height))
    );
  }

  public ngOnDestroy(): void {
    this._photorealisticRenderService.setInitialState();
    this._lightObjectsService.removeAllHelpers();

    this._renderer?.dispose();
    this._composer?.dispose();
    this._renderPass?.dispose();
    this.outlinePass?.dispose();
    this._effectFXAA?.dispose();

    this._scene.clear();

    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private _configureRender(width: number, height: number): void {
    this._renderer = new WebGLRenderer({ canvas: this.canvas, antialias: true, premultipliedAlpha: true });
    this._renderer.setPixelRatio(devicePixelRatio);
    this._renderer.setSize(width, height);
    this._renderer.shadowMap.type = PCFSoftShadowMap;
    this._renderer.shadowMap.enabled = true;

    // postprocessing
    this._composer = new EffectComposer(this._renderer);
    this._renderPass = new RenderPass(this._scene, this._camera);
    this._composer.addPass(this._renderPass);

    this.outlinePass = new OutlinePass(new Vector2(width, height), this._scene, this._camera);

    // config for outline
    this.outlinePass.edgeStrength = 5;
    this.outlinePass.edgeGlow = 0;
    this.outlinePass.edgeThickness = 1;
    this.outlinePass.pulsePeriod = 0;
    this.outlinePass.visibleEdgeColor.set('#ffffff');
    this.outlinePass.hiddenEdgeColor.set('#ff0000');

    this._composer.addPass(this.outlinePass);

    // outline subscription
    this._highlightedObjectsService.objects.pipe(untilDestroyed(this)).subscribe(objects => {
      this.outlinePass.selectedObjects = [...objects];
      this._roomService.highlightedObjectsIds = [...objects.map(mo => mo.uuid)];
    });

    // effect fxaa
    this._effectFXAA = new ShaderPass(FXAAShader);
    this._effectFXAA.uniforms['resolution'].value.set(1 / width, 1 / height);
    this._composer.addPass(this._effectFXAA);

    this._labelRender = new CSS2DRenderer({ element: this.labels });
    this._labelRender.setSize(width, height);
  }

  private _startEditor(width: number, height: number): void {
    this._addEventListeners();
    this._configureRender(width, height);
    this._configureServices();
    this._addControls();
    this._startRender();
    this._updateScrolling();
  }

  private _addControls(): void {
    this._roomService.addControls(this._renderer.domElement);
  }

  private _loadScene(): Observable<void> {
    return combineLatest([
      this._parse3dObject(this._renderInputData.sceneSaveData.roomData.scene),
      this._parse3dObject(this._renderInputData.sceneSaveData.roomData.camera),
    ]).pipe(
      take(1),
      mergeMap(([scene, camera]) => {
        this._scene = scene as Scene;
        this._camera = camera as PerspectiveCamera;
        this._scene.background = new TextureLoader().load(this._renderInputData.src);

        return this._createMovingObjects(this._scene, this._renderInputData.sceneSaveData.movingObjectsData.objects);
      }),
      map(movingObjects => {
        const loadedMovingObjectsServiceConfig = {
          ...this._renderInputData.sceneSaveData.movingObjectsData.config,
          scene: this._scene,
        };
        const loadedPlanesServiceConfig = {
          scene: this._scene,
          camera: this._camera,
        };
        const loadedRoomParamsServiceConfig = {
          scene: this._scene,
        };
        const loadedLightObjectsServiceConfig = {
          scene: this._scene,
        };

        const planes = this._createPlanes(this._renderInputData.sceneSaveData.planesData.planes);
        const roomParams = this._renderInputData.sceneSaveData.roomParams;

        this._lightObjectsService.configure(loadedLightObjectsServiceConfig);
        this._movingObjectsService.configure(loadedMovingObjectsServiceConfig, movingObjects);
        this._planesService.configure(loadedPlanesServiceConfig, planes);
        this._roomParamsService.configure(loadedRoomParamsServiceConfig, roomParams);
      }),
      takeUntil(this.unsubscribe$)
    );
  }

  private _getObject(uuid: string): Object3D {
    return this._scene.getObjectByProperty('uuid', uuid);
  }

  private _createPlanes(planesSave: RoomPlaneSaveLoad[]): RoomPlane[] {
    return planesSave
      .map(saveObject => {
        const mesh = this._getObject(saveObject.meshUUID) as Mesh;

        if (saveObject.quaternionBack && saveObject.z) {
          const position: Float32BufferAttribute = mesh.geometry.attributes['position'] as Float32BufferAttribute;
          for (let i = 0; i < position.array.length; i++) {
            position.setZ(i, saveObject.z);
          }
          position.needsUpdate = true;
          mesh.geometry.applyQuaternion(saveObject.quaternionBack);
        }

        const corners = saveObject.cornersUUIDs.map(uuid => this._scene.getObjectByProperty('uuid', uuid) as Mesh) ?? [];
        const windows =
          saveObject.windowsUUIDs.map(points => points.map(uuid => this._scene.getObjectByProperty('uuid', uuid) as WallPoint)) ?? [];
        const doors =
          saveObject.doorsUUIDs.map(points => points.map(uuid => this._scene.getObjectByProperty('uuid', uuid) as WallPoint)) ?? [];

        if (mesh) {
          const result = {
            ...saveObject,
            mesh,
            windows,
            doors,
            corners,
          };
          delete result.meshUUID;
          return result;
        }
        return null;
      })
      .filter(object => !!object);
  }

  private _createMovingObjects(scene: Scene, movingObjectsSave: MovingObjectSave[]): Observable<MovingObject[]> {
    const objectsGroup = scene.getObjectByName('objectsGroup') as Group;

    const models$: Observable<{ model: Group; saveObject: MovingObjectSave; light: Object3D }>[] = [];

    movingObjectsSave.forEach(saveObject => {
      if (saveObject?.SKU) {
        const model$ = saveObject.manuallyLoaded
          ? this._importService.loadUserModel(saveObject.manuallyLoadedFile)
          : this._importService.loadLQModel(saveObject.links, true);

        const mapModel$ = model$.pipe(
          take(1),
          catchError(() => of(null)),
          map(model => {
            if (model) {
              const light = saveObject.lightUUID ? this._scene.getObjectByProperty('uuid', saveObject.lightUUID) : null;
              return { model, saveObject, light };
            }
            return null;
          })
        );

        models$.push(mapModel$);
      }
    });

    if (models$.length === 0) {
      return of([]);
    }

    return forkJoin(models$).pipe(
      take(1),
      map(objects => {
        return objects
          .filter(object => !!object)
          .map(object => {
            objectsGroup.add(object.model);
            objectsGroup.updateMatrixWorld();

            object.model.name = object.saveObject.name;
            object.model.uuid = object.saveObject.id;
            object.model.position.set(object.saveObject.position.x, object.saveObject.position.y, object.saveObject.position.z);
            object.model.rotation.set(object.saveObject.rotation.x, object.saveObject.rotation.y, object.saveObject.rotation.z);

            if (object.light && object.saveObject.lightPosition) {
              object.light.position.set(
                object.saveObject.lightPosition.x,
                object.saveObject.lightPosition.y,
                object.saveObject.lightPosition.z
              );

              if (object.light instanceof SpotLight) {
                object.light.target.position.copy(
                  new Vector3(
                    object.saveObject.lightTargetPosition.x,
                    object.saveObject.lightTargetPosition.y,
                    object.saveObject.lightTargetPosition.z
                  )
                );

                object.light.target.updateMatrix();
                object.light.target.updateMatrixWorld();
                object.light.updateMatrixWorld();
                object.light.updateMatrix();
              }
            }

            let scale: Vector3;

            if (object.saveObject.scale) {
              scale = new Vector3(object.saveObject.scale.x, object.saveObject.scale.y, object.saveObject.scale.z);
              object.model.scale.set(object.saveObject.scale.x, object.saveObject.scale.y, object.saveObject.scale.z);
            }

            return {
              ...object.saveObject,
              object3D: object.model,
              light: object.light,
              scale,
            };
          });
      })
    );
  }

  private _parse3dObject(json: object): Observable<Object3D> {
    return new Observable<Object3D>(subscriber => {
      const loader = new ObjectLoader();

      loader.parse(json, object => {
        subscriber.next(object);
        subscriber.complete();
      });
    });
  }

  private _getMouse(event: MouseEvent | TouchEvent): { x: number; y: number } {
    let coordinates: { clientX: number; clientY: number };
    if (this._resolutionService.isMobileResolution) {
      const mobileEvent = event as TouchEvent;
      coordinates = {
        clientX: mobileEvent.changedTouches[0].clientX,
        clientY: mobileEvent.changedTouches[0].clientY,
      };
    } else {
      const clickEvent = event as MouseEvent;
      coordinates = clickEvent;
    }
    const rect = this._renderer.domElement.getBoundingClientRect();
    const rightEventX = coordinates.clientX - rect.left;
    const rightEventY = coordinates.clientY - rect.top;
    const x = (rightEventX / this.canvas.clientWidth) * 2 - 1;
    const y = -(rightEventY / this.canvas.clientHeight) * 2 + 1;
    return { x, y };
  }

  private _createScene(): Observable<void> {
    return new Observable(subscriber => {
      this._addCamera(this._renderInputData.width, this._renderInputData.height);
      this._addGroups();
      this._scene.background = new TextureLoader().load(this._renderInputData.src);
      subscriber.next();
    });
  }

  private _addCamera(width: number, height: number): void {
    const aspectRatio: number = width / height;
    this._camera = new PerspectiveCamera(90, aspectRatio);
    this._camera.position.set(0, 0, 0);
  }

  private _addGroups(): void {
    const objectsGroup = new Group();
    objectsGroup.name = 'objectsGroup';

    const planesGroup = new Group();
    planesGroup.name = 'planesGroup';

    const shadowGroup = new Group();
    shadowGroup.name = 'shadowGroup';

    const wallCornersGroup = new WallPointGroup();
    wallCornersGroup.name = 'wallCornersGroup';

    const lightPointsGroup = new Group();
    lightPointsGroup.name = 'lightPointsGroup';

    const autoPositionGroup = new Group();
    autoPositionGroup.name = 'autoPositionGroup';

    const lightHelpersGroup = new Group();
    lightHelpersGroup.name = 'lightHelpersGroup';

    this._scene.add(objectsGroup);
    this._scene.add(planesGroup);
    this._scene.add(shadowGroup);
    this._scene.add(wallCornersGroup);
    this._scene.add(lightPointsGroup);
    this._scene.add(autoPositionGroup);
    this._scene.add(lightHelpersGroup);
    this._scene.add(new AmbientLight(0xffffff, 0.5));
  }

  private _render(): void {
    this._renderer.render(this._scene, this._camera);
    this._labelRender.render(this._scene, this._camera);
  }

  private _composerCall(): void {
    this._composer.render();
  }

  private _update(): void {
    this._movingObjectsService.handleMoving();
  }

  private _handleAddPointLightClick(event: MouseEvent): void {
    const mouseOnCanvas = this._getMouse(event);
    this._roomService.addLight(mouseOnCanvas);
  }

  private _handleSelectPlaneClick(event: MouseEvent): void {
    const mouseOnCanvas = this._getMouse(event);
    this._roomService.selectPlane(mouseOnCanvas);
  }

  private _configureServices(): void {
    if (this._mode !== Mode.EDIT) {
      this._movingObjectsService.configure({
        scene: this._scene,
        moveSpeed: 1,
        rotateSpeed: 0.01,
        scaleSpeed: 0.1,
      });
      this._planesService.configure({
        scene: this._scene,
        camera: this._camera,
      });
      this._roomParamsService.configure({
        scene: this._scene,
      });
    }
    this._exportService.configure({
      scene: this._scene,
      renderer: this._renderer,
      camera: this._camera,
    });
    this._originalImageSizeService.size.subscribe(({ width, height }) => {
      this._photorealisticRenderService.configure({
        camera: this._camera,
        originalWidth: width,
        originalHeight: height,
      });
    });
    this._roomService.configure({
      camera: this._camera,
      scene: this._scene,
      domElement: this._renderer.domElement,
      roomType: this._renderInputData.type as RoomType,
    });
  }

  private _rightClick(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    if (ClickType.MOVE_MODEL_MOUSE) {
      this._handleRotateMovingModelsMouse(event);
    }
  }

  private _mouseUp(): void {
    switch (this.clickType) {
      case ClickType.MOVE_MODEL_MOUSE:
        this._handleDeselectMoveModelMouse();
        break;
      case ClickType.MOVE_WINDOW_DOOR:
        this._handleMoveCornerMouseUp();
        break;
    }
  }

  private _mouseDown(event: MouseEvent): void {
    switch (this.clickType) {
      case ClickType.MOVE_WALL_POINT:
        this._handleMoveWallPointClick(event);
        break;
      case ClickType.MOVE_WINDOW_DOOR:
        this._handleMoveWindowDoorClick(event);
        break;
      case ClickType.MOVE_MODEL_MOUSE:
        {
          this._handleSelectMoveModelMouse(event);

          if (!this._resolutionService.isMobileResolution) {
            this._handleViewFurnitureClick(event);
          }
        }
        break;
      case ClickType.VIEW_FURNITURE_CLICK:
        this._handleViewFurnitureClick(event);
        break;
      case ClickType.ADD_LIGHT_POINT:
        this._handleAddPointLightClick(event);
        break;
      case ClickType.DELETE_LIGHT_POINT:
        this._handleDeleteLightPoint(event);
        break;
      case ClickType.ADD_WALL_POINT:
        this._handleAddWallPointClick(event);
        break;
      case ClickType.DELETE_WALL_POINT:
        this._handleDeleteWallPoint(event);
        break;
      case ClickType.SELECT_PLANE:
        this._handleSelectPlaneClick(event);
        break;
      case ClickType.SELECT_NEW_PLANE_POINT:
        this._handleSelectNewPlanePointClick(event);
        break;
      case ClickType.SET_CEILING_MANUAL:
        this._handleSetCeilingClick(event);
        break;
      case ClickType.RECONSTRUCT_MANUAL:
        this._handleReconstructManualClick(event);
        break;
      case ClickType.PLACE_DOOR:
        this._handlePlaceHoleObjectClick(event, PointType.DOOR);
        break;
      case ClickType.PLACE_WINDOW:
        this._handlePlaceHoleObjectClick(event, PointType.WINDOW);
        break;
    }
    if (this.clickType !== ClickType.MOVE_WALL_POINT) {
      this._cornersControlsService.detachCornersControls();
    }
  }

  private _handleReconstructManualClick(event: MouseEvent): void {
    const mouseOnCanvas = this._getMouse(event);
    this._manualReconstructionService.handleClick(event, mouseOnCanvas);
  }

  private _handleSelectNewPlanePointClick(event: MouseEvent): void {
    const mouseOnCanvas = this._getMouse(event);
    this._roomService.selectAddWallPoint(mouseOnCanvas);
  }

  private _handleDeleteLightPoint(event: MouseEvent): void {
    const mouseOnCanvas = this._getMouse(event);
    this._roomService.deleteSelectedLightPoint(mouseOnCanvas);
  }

  private _handleMoveWallPointClick(event: MouseEvent): void {
    const mouseOnCanvas = this._getMouse(event);
    this._roomService.selectMoveWallPoint(mouseOnCanvas);
  }

  private _handleRotateMovingModelsMouse(event: MouseEvent): void {
    const mouseOnCanvas = this._getMouse(event);
    const result = this._roomService.getRotationConfig(mouseOnCanvas);

    // relative space for canvas
    const canvasTop = 200;
    const canvasRight = 80;

    if (result) {
      this._dialog
        .open(RotationDialogComponent, {
          backdropClass: 'rotation-dialog__backdrop',
          panelClass: 'rotation-dialog__panel',
          hasBackdrop: true,
          position: {
            top: `${canvasTop + result.top}px`,
            right: `${canvasRight + result.right - 150}px`,
          },
          data: {
            movingObject: result.movingObject,
          },
          disableClose: false,
        })
        .afterClosed()
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this._roomService.updateObjectRotation(result.movingObject);
        });
    }
  }

  private _handleSelectMoveModelMouse(event: MouseEvent): void {
    const mouseOnCanvas = this._getMouse(event);

    const objectWasSelected = this._roomService.selectMoveModel(mouseOnCanvas, event.ctrlKey);

    if (objectWasSelected) {
      this._highlightedObjectsService.setHighlightedObjects([...this._roomService.selectedMovingObjects]);
    } else {
      this._highlightedObjectsService.clearHighlightedObjects();
    }
  }

  private _handleViewFurnitureClick(event: MouseEvent): void {
    const mouseOnCanvas = this._getMouse(event);
    const selectModelResult = this._roomService.selectMoveModel(mouseOnCanvas, event.ctrlKey);

    if (!selectModelResult) {
      const wall = this._roomService.selectWall(mouseOnCanvas);

      if (wall) {
        this.wallClick.next(wall);
      }
    } else {
      this._roomService.viewFurniture();
    }
  }

  private _handleDeselectMoveModelMouse(): void {
    this._roomService.deselectMoveModel();
  }

  private _handleAddWallPointClick(event: MouseEvent): void {
    const mouseOnCanvas = this._getMouse(event);
    this._roomService.addWallPoint(mouseOnCanvas);
  }

  private _handleDeleteWallPoint(event: MouseEvent): void {
    const mouseOnCanvas = this._getMouse(event);
    this._roomService.deleteWallPoint(mouseOnCanvas);
  }

  private _handleSetCeilingClick(event: MouseEvent): void {
    const mouseOnCanvas = this._getMouse(event);
    this._roomService.createCeiling(mouseOnCanvas);
  }

  private _handlePlaceHoleObjectClick(event: MouseEvent, type: PointType): void {
    const mouseOnCanvas = this._getMouse(event);
    const result = this._roomService.placeHole(mouseOnCanvas, type);

    if (result) {
      switch (type) {
        case PointType.WINDOW:
          this.windowPlace.next();
          break;
        case PointType.DOOR:
          this.doorPlace.next();
          break;
      }
    }
  }

  private _addEventListeners(): void {
    if (this._resolutionService.isMobileResolution) {
      this.canvas.addEventListener('touchstart', this._mouseDown.bind(this));
      this.canvas.addEventListener('touchend', this._mouseUp.bind(this));
      this.canvas.addEventListener('touchmove', this._mouseMove.bind(this));
    } else {
      this.canvas.addEventListener('contextmenu', this._rightClick.bind(this));
      this.canvas.addEventListener('mouseup', this._mouseUp.bind(this));
      this.canvas.addEventListener('mousedown', this._mouseDown.bind(this));
      this.canvas.addEventListener('mousemove', this._mouseMove.bind(this));
    }
  }

  private _mouseMove(event: MouseEvent): void {
    switch (this.clickType) {
      case ClickType.MOVE_WINDOW_DOOR:
        this._roomService.updateCornerMouse(this._getMouse(event));
        break;
      case ClickType.MOVE_MODEL_MOUSE:
        this._roomService.updateMouse(this._getMouse(event));
        break;
    }
  }

  private _startRender(): void {
    requestAnimationFrame(this._startRender.bind(this));
    this._render.call(this);
    this.debugOutlineEnabled && this._composerCall.call(this);
    this._update.call(this);
  }

  private _handleMoveCornerMouseUp(): void {
    this._roomService.handleMoveCornerMouseUp();
  }

  private _handleMoveWindowDoorClick(event: MouseEvent): void {
    const mouse = this._getMouse(event);
    this._roomService.moveWindowDoor(mouse);
  }

  private _updateScrolling(): void {
    if (this.canvas && this.labels) {
      this.canvas.style.touchAction = this.canScrollThrow ? 'auto' : 'none';
      this.labels.style.pointerEvents = this.canScrollThrow ? 'auto' : 'none';
    }
  }
}
