<div class="dialog">
    <button class="close-button" mat-icon-button aria-label="close dialog" (click)="tryClose()">
        <mat-icon>close</mat-icon>
    </button>
    <app-catalog-details
      #catalogDetails
      *ngIf="data.from === leftPanelStates.CATALOG_DETAILS"
      (closeDialog)="close()"
    ></app-catalog-details>
    <app-scene-details
      #sceneDetails
      *ngIf="data.from === leftPanelStates.SCENE_DETAILS"
      (closeDialog)="close()"
    ></app-scene-details>
</div>

