import { createAction, props } from '@ngrx/store';
import { IList } from 'src/app/shared/interfaces/list';
import { IVersion } from 'src/models/project';

export const LOAD_ALL_VERSIONS = '[Project Page] load all Versions for Project';
export const ALL_VERSIONS_LOADED = '[Project Page] all Versions loaded';
export const VERSION_SRC_LOADED = '[Projects list] version Src Loaded';
export const LOAD_VERSION = '[Projects list] load version';
export const VERSION_LOADED = '[Projects list] version loaded';
export const REMOVE_VERSION = '[Project Page] remove Version in Project';
export const REMOVE_VERSION_SUCCESS = '[Project Page] remove Version Success';
export const SAVE_VERSION = '[Render Component] save version';
export const SAVE_VERSION_SUCCESS = '[Render Component] save version success';
export const UPDATE_VERSION = '[Render Component] update version';
export const UPDATE_VERSION_SUCCESS = '[Render Component] update version success';
export const CLEAR_NEED_TO_UPDATE_VERSION = '[Render Component] clear need to update version';

export const loadAllVersions = createAction(LOAD_ALL_VERSIONS, props<{ id: string; pageIndex: number; pageSize: number; name?: string }>());

export const allVersionsLoaded = createAction(ALL_VERSIONS_LOADED, props<{ id: string; versions: IList<IVersion> }>());

export const versionSrcLoaded = createAction(VERSION_SRC_LOADED, props<{ data: { projectId: string; id: string; src: string } }>());

export const loadVersion = createAction(LOAD_VERSION, props<{ versionId: string }>());

export const versionLoaded = createAction(VERSION_LOADED, props<{ id: string; version: IVersion }>());

export const removeVersion = createAction(
  REMOVE_VERSION,
  props<{ versionId: string; projectId: string; pageIndex: number; pageSize: number }>()
);

export const removeVersionSuccess = createAction(REMOVE_VERSION_SUCCESS, props<{ versionId: string; projectId: string }>());

export const saveVersion = createAction(SAVE_VERSION);

export const saveVersionSuccess = createAction(SAVE_VERSION_SUCCESS);

export const updateVersion = createAction(UPDATE_VERSION);

export const updateVersionSuccess = createAction(UPDATE_VERSION_SUCCESS);

export const clearNeedToUpdateVersion = createAction(CLEAR_NEED_TO_UPDATE_VERSION, props<{ projectId: string; versionId: string }>());
