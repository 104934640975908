import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { Observable } from 'rxjs';

import { Notification } from '../model/notification';
import { NotificationsService } from '../services/notifications.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  animations: [
    trigger('flyInOut', [
      state('in', style({ transform: 'translateX(0)' })),
      transition('void => *', [style({ transform: 'translateX(-100%)' }), animate('100ms ease-in-out')]),
      transition('* => void', [animate('200ms ease-in-out', style({ opacity: 0 }))]),
    ]),
  ],
})
export class NotificationsComponent {
  public notification$: Observable<Notification>;

  constructor(private _notificationsService: NotificationsService) {
    this.notification$ = this._notificationsService.getNotification();
  }

  public trackById(index, item): number {
    return item.id;
  }
}
