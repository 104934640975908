import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';

import { RoomPlane } from '../services/model/dto/plane.model';

export interface WallColorDialogData {
  wall: RoomPlane;
}

@Component({
  selector: 'app-wall-color-dialog',
  templateUrl: './wall-color-dialog.component.html',
  styleUrls: ['./wall-color-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WallColorDialogComponent {
  constructor(
    private _dialogRef: MatDialogRef<WallColorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: WallColorDialogData,
    private _store: Store
  ) {}

  public close(): void {
    this._dialogRef.close();
  }
}
