import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

import { LeftPanelState } from './left-panel-state.enum';
import { RoomPlane } from '../../services/model/dto/plane.model';
import { ResolutionService } from '../../services/resolution.service';

@UntilDestroy()
@Component({
  selector: 'app-left-panel-container',
  templateUrl: './left-panel-container.component.html',
  styleUrls: ['./left-panel-container.component.scss'],
})
export class LeftPanelContainerComponent implements OnChanges {
  @Output() public openLeftPanel = new EventEmitter<boolean>(false);
  @Input() public catalogOpenedMobile = false;
  @Input() public leftPanelStateMobile: LeftPanelState;

  public leftPanelState: LeftPanelState = LeftPanelState.CATALOG_CATEGORIES;
  public leftPanelStates = LeftPanelState;

  public mainWall: RoomPlane;

  public furnitureType: string;
  public uuid: string;

  constructor(public resolutionService: ResolutionService) {}

  public ngOnChanges(): void {
    if (this.leftPanelStateMobile) {
      this.leftPanelState = this.leftPanelStateMobile;
    }
  }

  public onCategoryChange(category: string): void {
    this.leftPanelState = LeftPanelState.CATALOG_CATEGORY;
    this.furnitureType = category;
  }

  public openCategories(): void {
    this.leftPanelState = LeftPanelState.CATALOG_CATEGORIES;
  }

  public openDetailsPanel(): void {
    this.leftPanelState = LeftPanelState.CATALOG_DETAILS;
  }

  public backToCategories(): void {
    this.leftPanelState = LeftPanelState.CATALOG_CATEGORY;
  }

  public handleWallClick(plane: RoomPlane): void {
    this.mainWall = plane;
    this.openLeftPanel.emit(!!plane);
    this.leftPanelState = LeftPanelState.CURRENT_COLOR;
  }

  public close(): void {
    this.openLeftPanel.emit(false);
    this.leftPanelState = LeftPanelState.CATALOG_CATEGORIES;
  }
}
