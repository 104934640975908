import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from 'src/app/store/interfaces/user';
import { environment } from 'src/environments/environment';
import { AUTH_INTERCEPT } from 'src/services/context-tokens/intercept.context-token';

@Injectable()
export class UserService {
  constructor(private _http: HttpClient) {}

  public getUser(): Observable<User> {
    const context = new HttpContext();
    context.set(AUTH_INTERCEPT, true);
    return this._http.get<User>(environment.API_URL + 'users/me', { context });
  }

  public setLanguage(lang: string): Observable<User> {
    const formData = new FormData();
    formData.append('value', lang);
    const context = new HttpContext();
    context.set(AUTH_INTERCEPT, true);
    return this._http.post<User>(environment.API_URL + 'users/language', formData, { context });
  }

  public getSubscriptionsLink(): Observable<string> {
    const context = new HttpContext();
    context.set(AUTH_INTERCEPT, true);
    return this._http.get(environment.API_URL + 'subscriptions/customer-portal', { context, responseType: 'text' });
  }

  public completeFirstLogin(): Observable<void> {
    const context = new HttpContext();
    context.set(AUTH_INTERCEPT, true);
    return this._http.post<void>(environment.API_URL + 'users/first-login', {}, { context });
  }
}
