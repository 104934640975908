import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Notification } from 'src/app/notifications/model/notification';
import { NotificationsService } from 'src/app/notifications/services/notifications.service';

import { ForgotPasswordForm } from '../../model/forgot-password.model';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent {
  public form: FormGroup<ForgotPasswordForm> = new FormGroup<ForgotPasswordForm>({
    email: new FormControl(null, [Validators.required, Validators.email]),
  });

  constructor(
    private _authService: AuthService,
    private _router: Router,
    private _translateService: TranslateService,
    private _notificationsService: NotificationsService
  ) {}

  public submit(): void {
    this.form.updateValueAndValidity();
    if (this.form.valid) {
      this._authService.forgot(this.form.getRawValue()).subscribe({
        next: () => {
          this._notificationsService.addNotification(
            new Notification({
              title: this._translateService.instant('NOTIFICATIONS.TITLE.SUCCESS'),
              text: this._translateService.instant('NOTIFICATIONS.MESSAGES.CHECK_EMAIL'),
              level: 'success',
              options: { timeout: 2 },
            })
          );
          this._router.navigate(['login']);
        },
        error: (error: HttpErrorResponse) =>
          this._notificationsService.addNotification(
            new Notification({
              title: this._translateService.instant('NOTIFICATIONS.TITLE.ERROR'),
              text: error.error['detail'],
              level: 'error',
              options: { timeout: 2 },
            })
          ),
      });
    }
  }

  public toLogIn(): void {
    this._router.navigate(['login']);
  }
}
