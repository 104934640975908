import { DirectionalLightHelper, Event, HemisphereLightHelper, Light, Mesh, Object3D, PointLightHelper, SpotLightHelper } from 'three';

import { MovingObject } from './model/dto/moving-object.model';

export function disposeLight(light: Object3D, lightHelper?: Object3D): void {
  if (light instanceof Light) {
    light.dispose();
  }

  if (
    lightHelper instanceof SpotLightHelper ||
    lightHelper instanceof PointLightHelper ||
    lightHelper instanceof HemisphereLightHelper ||
    lightHelper instanceof DirectionalLightHelper
  ) {
    lightHelper.dispose();
  }
}

export function recursivelyDisposeObjectMeshes(object: Object3D<Event>): void {
  if (object instanceof Mesh) {
    object.material.dispose();
    object.geometry.dispose();
  }

  object.children.forEach(child => recursivelyDisposeObjectMeshes(child));
}

export function disposeMovingObjects(objects: MovingObject[]): void {
  objects.forEach(obj => {
    if (obj.light) {
      disposeLight(obj.light);
    }

    recursivelyDisposeObjectMeshes(obj.object3D);
  });
}
