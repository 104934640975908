// constants that for light point config in Blender

export const PEDANT_LAMP_LIGHT = 'PEDANT_LAMP_LIGHT';
export const FLOOR_LAMP_LIGHT = 'FLOOR_LAMP_LIGHT';
export const TABLE_LAMP_LIGHT = 'TABLE_LAMP_LIGHT';
export const WALL_LAMP_LIGHT = 'WALL_LAMP_LIGHT';
export const LIGHT_TARGET = 'LIGHT_TARGET';
export const MAIN_WINDOW_LIGHT = 'MAIN_WINDOW_LIGHT';
export const SUP_WINDOW_LIGHT = 'SUP_WINDOW_LIGHT';
export const SHADOW_WALL_BASIC = 'SHADOW_WALL_BASIC';
export const SHADOW_WALL_WINDOW = 'SHADOW_WALL_WINDOW';
export const COLOR_WALL = 'COLOR_WALL';
