export enum FurnitureType {
  SOFA = 'SOFA',
  BED = 'BED',
  DINING_TABLE_CHAIR = 'DINING_TABLE_CHAIR',
  EASY_CHAIR = 'EASY_CHAIR',
  BAR_STOOL = 'BAR_STOOL',
  STOOL = 'STOOL',
  OFFICE_CHAIR = 'OFFICE_CHAIR',
  OTTOMAN = 'OTTOMAN',
  POUF = 'POUF',
  BENCH = 'BENCH',
  DINING_TABLE = 'DINING_TABLE',
  HIGH_TABLE = 'HIGH_TABLE',
  SIDE_TABLE = 'SIDE_TABLE',
  OFFICE_TABLE = 'OFFICE_TABLE',
  CLOSET = 'CLOSET',
  SHELF = 'SHELF',
  WARDROBE = 'WARDROBE',
  SHOE_RACK = 'SHOE_RACK',
  SIDE_BOARD = 'SIDE_BOARD',
  KEY_STORAGE = 'KEY_STORAGE',
  HIGH_TABLE_OUTDOOR = 'HIGH_TABLE_OUTDOOR',
  SIDE_TABLE_OUTDOOR = 'SIDE_TABLE_OUTDOOR',
  GARDEN_TABLE = 'GARDEN_TABLE',
  OUTDOOR_BAR_STOOL = 'OUTDOOR_BAR_STOOL',
  SIDE_CHAIR_OUTDOOR = 'SIDE_CHAIR_OUTDOOR',
  GARDEN_CHAIR = 'GARDEN_CHAIR',
  GARDEN_SOFA = 'GARDEN_SOFA',
  GARDEN_BENCH = 'GARDEN_BENCH',
  DECK_CHAIR = 'DECK_CHAIR',
  GRILL = 'GRILL',
  HAMMOCK = 'HAMMOCK',
  CURTAIN = 'CURTAIN',
  OUTDOOR_ACCESSOIRE = 'OUTDOOR_ACCESSOIRE',
  OUTDOOR_LIGHTING = 'OUTDOOR_LIGHTING',
  LAMP = 'LAMP',
  FLOOR_LAMP = 'FLOOR_LAMP',
  WALL_LAMP = 'WALL_LAMP',
  TABLE_LAMP = 'TABLE_LAMP',
  KITCHEN_UTENSILS = 'KITCHEN_UTENSILS',
  DINING_TABLE_UTENSILS = 'DINING_TABLE_UTENSILS',
  DISHES = 'DISHES',
  BATHING_EQUIPMENT = 'BATHING_EQUIPMENT',
  OFFICE_AND_STORAGE = 'OFFICE_AND_STORAGE',
  PAINTING = 'PAINTING',
  PILLOW = 'PILLOW',
  SEAT_CUSHION = 'SEAT_CUSHION',
  SHOWER_CURTAIN = 'SHOWER_CURTAIN',
  CARPET = 'CARPET',
  CANDLE = 'CANDLE',
  BATH_TOWEL = 'BATH_TOWEL',
  HOUSEHOLD = 'HOUSEHOLD',
  MIRROR = 'MIRROR',
  DECORATION = 'DECORATION',
  WALLPAPER = 'WALLPAPER',
  BLANKET_LIVING = 'BLANKET_LIVING',
  PLANT = 'PLANT',
}
