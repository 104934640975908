import { DOCUMENT, Location } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { fabric } from 'fabric';
import * as moment from 'moment';
import {
  BehaviorSubject,
  combineLatest,
  concatMap,
  debounceTime,
  distinctUntilChanged,
  filter,
  first,
  map,
  Observable,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { DetectedFurniture, DetectedFurnitureState } from 'src/app/ideas/interfaces/idea';
import {
  clearActiveIdea,
  clearActiveIdeaFurniture,
  loadIdeaSimilarFurniture,
  viewIdeaFurniture,
} from 'src/app/store/actions/ideas.actions';
import { activeIdea, ideaFurniture } from 'src/app/store/selectors/ideas.selectors';
import { rendersResult, userPlan, userRole } from 'src/app/store/selectors/shared.selector';
import { MAX_HEIGHT_MOBILE, MAX_WIDTH_MOBILE } from 'src/app/user-flow/const/windows-dimentions';
import { ExportType } from 'src/app/user-flow/enum/export.enum';
import { Resolution } from 'src/app/user-flow/export-settings/resolution.class';
import { DEVICES_RESOLUTIONS } from 'src/app/user-flow/export-settings/resolutions.const';
import { RefreshNavigationService } from 'src/app/user-flow/services/refresh-navigation.service';
import { ResolutionService } from 'src/app/user-flow/services/resolution.service';
import { SingleRenderResultType } from 'src/models/project';

import { ProductImage } from './product-image';
import { ProductPageConfig } from './product-page.config';
import { Plans } from '../../enums/plans.enum';
import { Roles } from '../../enums/roles.enum';
import { IdeaFurnitureMobileComponent } from '../idea-furniture/idea-furniture-mobile/idea-furniture-mobile.component';
import { LeftPanelState } from '../idea-furniture/left-panel-state.enum';

// import { SingleRenderResultType } from 'src/models/project';

@UntilDestroy()
@Component({
  selector: 'app-product-page',
  templateUrl: './product-page.component.html',
  styleUrls: ['./product-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductPageComponent implements OnInit, AfterViewInit, OnDestroy {
  public products: ProductImage[] = [];
  public activeProductIndex = 0;
  public exportSettingsForm: FormGroup;

  public freeResolutions: Resolution[] = [];
  public payedResolution: Resolution[] = [];

  public isHighQualityDownloadDisabled$ = combineLatest(this._store.select(userPlan), this._store.select(userRole)).pipe(
    filter(([plan, role]) => !!plan && !!role),
    map(([plan, role]) => plan === Plans.FREE && role === Roles.USER),
    take(1)
  );

  public leftPanelOpen = false;
  public leftPanelPage: LeftPanelState;

  public date: string;
  public name: string;
  public translateLabel = false;

  private _activeProduct$: BehaviorSubject<ProductImage> = new BehaviorSubject(null);

  private _config: ProductPageConfig;

  private _detectedFurniture: DetectedFurnitureState[] = [];

  private _width: number;
  private _height: number;
  private _finalAspectRatio: number;

  private _canvas: fabric.Canvas = new fabric.Canvas('editor', {
    backgroundColor: '#ccc',
    selectionColor: '#FC6E6E',
  });

  private _bgImage: fabric.Image;
  private _filename: string;

  @ViewChild('pageContent', { static: false })
  private _pageContent: ElementRef;

  public get config(): ProductPageConfig {
    return this._config;
  }

  constructor(
    public resolutionService: ResolutionService,
    @Inject(DOCUMENT) private _document: Document,
    private _dialog: MatDialog,
    private _refreshNavigationService: RefreshNavigationService,
    private _location: Location,
    private _activatedRoute: ActivatedRoute,
    private _store: Store,
    private _cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this._canvas = new fabric.Canvas('editor', {
      selection: false,
      hoverCursor: 'pointer',
    });

    this._refreshNavigationService.refreshPageEvent$.pipe(untilDestroyed(this)).subscribe(() => this._location.back());

    this._activatedRoute.paramMap
      .pipe(
        map(value => value.get('type')),
        distinctUntilChanged(),
        take(1),
        first(),
        tap((type: string) => {
          this._config = {
            is3d: type !== 'idea',
            brightnessSliderConfig: {
              defaultValue: 0,
              min: -0.75,
              max: 0.75,
              step: 0.01,
            },
            exportTypes: [
              { value: ExportType.JPG, viewValue: 'Jpg' },
              { value: ExportType.PNG, viewValue: 'Png' },
            ],
          };
          this.exportSettingsForm = new FormGroup({
            brightness: new FormControl(this._config.brightnessSliderConfig.defaultValue),
            resolution: new FormControl(null),
            format: new FormControl(ExportType.JPG),
          });
        }),
        switchMap(() => {
          if (this._config.is3d) {
            return this._store.select(rendersResult).pipe(
              filter(data => !!data && !!data.renders),
              tap(renderData => {
                this.products = [
                  ...renderData.renders.map(render => ({
                    ...render,
                    date: render.renderDate,
                    src: render.src!,
                  })),
                ];
              }),
              map(() => this.products.at(0))
            );
          } else {
            return this._store.select(activeIdea).pipe(
              filter(data => !!data && !!data.src && !!data.blob && !!data.creationDate),
              map(data => {
                const label = data?.defaultDesc?.style ? `IDEAS.STYLES.${data?.defaultDesc?.style}` : data?.styleswapDesc?.name;
                this.translateLabel = !!data?.defaultDesc?.style ?? false;

                return {
                  name: label,
                  src: data.src,
                  blob: data.blob,
                  date: data.creationDate,
                  detectedFurniture: data.detectedFurniture,
                };
              })
            );
          }
        }),
        tap((product: ProductImage) => {
          this._activeProduct$.next(this.products.length > 0 ? this.products[this.activeProductIndex] : product);
        }),
        untilDestroyed(this)
      )
      .subscribe(() => {});

    this._activeProduct$
      .pipe(
        distinctUntilChanged((prev, current) => prev.src === current.src),
        filter(currentProduct => !!currentProduct),
        switchMap(product => {
          this._canvas.clear();

          const filename = (this._config.is3d ? 'render_' : 'idea_').concat(moment(product.date.toString()).format('YYYY-MM-DD_HH:mm:ss'));

          this.name = product.name;
          this.date = moment(product.date.toString()).format('MMM DD YYYY');

          return this._loadBgImageWithName({ src: product.src, blob: product.blob }, filename);
        }),
        concatMap(() => this._getResolutions()),
        map(() => {
          // update dots to canvas aspect ratio
          const product = this._activeProduct$.getValue();

          if (
            this._config.is3d &&
            (product.type === SingleRenderResultType.IMPROVED || product.type === SingleRenderResultType.REGULAR) &&
            product.targetRenderHeight &&
            product.targetRenderWidth
          ) {
            const originalImageWidth = product.targetRenderWidth;
            const originalImageHeight = product.targetRenderHeight;

            const newImageWidth = this.payedResolution.length > 0 ? this.payedResolution.at(0).width : this.freeResolutions.at(0).width;
            const newImageHeight = this.payedResolution.length > 0 ? this.payedResolution.at(0).height : this.freeResolutions.at(0).height;

            const scalingX = newImageWidth / originalImageWidth;
            const scalingY = newImageHeight / originalImageHeight;

            const correctedFurniture: DetectedFurniture[] = product.detectedFurniture.map(furniture => ({
              boundBoxX1: scalingX * furniture.boundBoxX1,
              boundBoxX2: scalingX * furniture.boundBoxX2,
              boundBoxY1: scalingY * furniture.boundBoxY1,
              boundBoxY2: scalingY * furniture.boundBoxY2,
              skus: furniture.skus,
            }));

            return this._findDots(correctedFurniture);
          }

          return this._findDots(product.detectedFurniture);
        }),
        tap((dots: DetectedFurnitureState[]) => {
          this._detectedFurniture = [...dots];
          this._showDots(dots);
        }),
        untilDestroyed(this)
      )
      .subscribe(() => {});

    if (this.resolutionService.isMobileResolution) {
      this._mobileSubscribeToSelectedMovingObjects();
    }
  }

  public ngAfterViewInit(): void {
    this.exportSettingsForm
      .get('brightness')
      .valueChanges.pipe(debounceTime(100), untilDestroyed(this))
      .subscribe(brightness => {
        if (this._bgImage) {
          this._bgImage.filters[0]['brightness'] = brightness;
          this._bgImage.applyFilters();
          this._canvas.renderAll();
        }
      });
  }

  public ngOnDestroy(): void {
    this.products.forEach(productImage => {
      URL.revokeObjectURL(productImage.src);
    });

    this._store.dispatch(clearActiveIdeaFurniture());
  }

  public back(): void {
    this._store.dispatch(clearActiveIdea());
    this._location.back();
  }

  public previous(): void {
    if (this.activeProductIndex - 1 >= 0) {
      this._activeProduct$.next(this.products[--this.activeProductIndex]);
      this.exportSettingsForm.patchValue({
        brightness: this._config.brightnessSliderConfig.defaultValue,
        resolution: null,
        format: ExportType.JPG,
      });
      this.exportSettingsForm.updateValueAndValidity();
    }
  }

  public next(): void {
    if (this.activeProductIndex + 1 < this.products.length) {
      this._activeProduct$.next(this.products[++this.activeProductIndex]);
      this.exportSettingsForm.patchValue({
        brightness: this._config.brightnessSliderConfig.defaultValue,
        resolution: null,
        format: ExportType.JPG,
      });
      this.exportSettingsForm.updateValueAndValidity();
    }
  }

  public openLeftPanel(status: boolean): void {
    this.leftPanelOpen = status;
  }

  public resetBrightness(): void {
    this.exportSettingsForm.get('brightness').setValue(this._config.brightnessSliderConfig.defaultValue);
  }

  public downloadImage(): void {
    const brightness = this.exportSettingsForm.get('brightness').value;
    let format: string;

    switch (this.exportSettingsForm.get('format').value) {
      case ExportType.JPG:
        format = '.jpg';
        break;
      case ExportType.PNG:
        format = '.png';
        break;
    }

    const selectedResolution = this.exportSettingsForm.get('resolution').getRawValue();

    const exportCanvas = this._document.createElement('canvas');
    const fabricCanvas = new fabric.Canvas(exportCanvas);
    fabric.Image.fromURL(this._bgImage.getSrc(), img => {
      const brightnessFilter = new fabric.Image.filters.Brightness({
        brightness: brightness,
      });
      img.filters.push(brightnessFilter);
      img.applyFilters();

      const { width, height } = selectedResolution ?? img;
      exportCanvas.width = width;
      exportCanvas.height = height;
      fabricCanvas.setWidth(width);
      fabricCanvas.setHeight(height);
      fabricCanvas.setZoom(1);
      fabricCanvas.add(img.scaleToWidth(width));
      fabricCanvas.renderAll();

      const fileName: string = this._filename;
      const a: HTMLAnchorElement = this._document.createElement('a');
      a.href = fabricCanvas.toDataURL();
      a.download = fileName.concat(format);
      a.click();
    });
  }

  public updatePageType(state: LeftPanelState): void {
    this.leftPanelPage = state;
  }

  private _findDots(detectedFurniture: DetectedFurniture[]): DetectedFurnitureState[] {
    return detectedFurniture
      .filter(detected => detected.skus.length > 0)
      .map(furniture => {
        const cropWidth = furniture.boundBoxX2 - furniture.boundBoxX1;
        const cropHeight = furniture.boundBoxY2 - furniture.boundBoxY1;
        const centerX = (cropWidth / 2 + furniture.boundBoxX1) * this._finalAspectRatio;
        const centerY = (cropHeight / 2 + furniture.boundBoxY1) * this._finalAspectRatio;
        return {
          centerX,
          centerY,
          skus: furniture.skus,
        };
      });
  }

  private _mobileSubscribeToSelectedMovingObjects(): void {
    this._store
      .select(ideaFurniture)
      .pipe(
        filter(data => !!data && !!data.length),
        untilDestroyed(this)
      )
      .subscribe(data => {
        if (data.length === 1) {
          this._store.dispatch(viewIdeaFurniture({ data: data[0] }));
        }

        this._dialog.open(IdeaFurnitureMobileComponent, {
          position: { bottom: '0' },
          maxWidth: '100vw',
          maxHeight: '93vh',
          panelClass: 'mobile-dialog',
          data: { furnitureCount: data.length },
        });
      });
  }

  private _showDots(detectedFurniture: DetectedFurnitureState[]): void {
    detectedFurniture.forEach(furniture => {
      fabric.loadSVGFromURL('../../../../assets/icons/Ellipse 455.svg', (objects, options) => {
        const obj = fabric.util.groupSVGElements(objects, options);
        obj.set({
          top: furniture.centerY,
          left: furniture.centerX,
          width: 20,
          height: 20,
          originX: 'center',
          originY: 'center',
          lockMovementX: true,
          lockMovementY: true,
          hasControls: false,
          hasBorders: false,
        });
        obj.on('mousedown', e => {
          const idx = this._detectedFurniture.findIndex(
            el => el.centerX.toFixed(2) === e.target.left.toFixed(2) && el.centerY.toFixed(2) === e.target.top.toFixed(2)
          );

          if (idx !== -1) {
            const ids = this._detectedFurniture[idx].skus;
            this._store.dispatch(loadIdeaSimilarFurniture({ ids: ids }));
            this.openLeftPanel(true);
            this._store
              .select(ideaFurniture)
              .pipe(
                filter(data => !!data && !!data.length),
                untilDestroyed(this)
              )
              .subscribe(data => {
                if (data.length === 1) {
                  this.leftPanelPage = LeftPanelState.DETAILS_PANEL;
                  this._store.dispatch(viewIdeaFurniture({ data: data[0] }));
                } else {
                  this.leftPanelPage = LeftPanelState.FURNITURE_LIST;
                }
                this._cdr.markForCheck();
              });
          }
        });

        this._canvas.add(obj).renderAll();
      });
    });
  }

  private _getResolutions(): Observable<boolean> {
    const image = new Image();
    image.src = this._activeProduct$.getValue().src;
    return new Observable(observer => {
      image.onload = (): void => {
        const originalResolution: Resolution = new Resolution(image.width, image.height, 'Original');

        const relatedResolutions = originalResolution.relateWith(DEVICES_RESOLUTIONS);

        this.freeResolutions = [relatedResolutions.length > 0 ? relatedResolutions[relatedResolutions.length - 1] : originalResolution];

        this.payedResolution = relatedResolutions.length > 0 ? [originalResolution, ...relatedResolutions.slice(0, -1)] : [];

        this.exportSettingsForm.get('resolution').setValue(this.freeResolutions.at(0));

        observer.next(true);
        observer.complete();
      };
    });
  }

  private _loadBgImageWithName(
    bgImage: { src: string; blob: Blob },
    filename: string
  ): Observable<{
    file: File;
    src: string;
    width: number;
    height: number;
    blob: Blob;
  }> {
    return new Observable<{
      file: File;
      src: string;
      width: number;
      height: number;
      blob: Blob;
    }>(subscriber => {
      const imageObj = new Image();
      imageObj.src = bgImage.src;
      imageObj.onload = (): void => {
        this._canvas.clear();
        const image = new fabric.Image(imageObj);
        const result = this._resizeImage(imageObj.width, imageObj.height);
        [this._width, this._height, this._finalAspectRatio] = result;

        this._canvas.setHeight(this._height || 600);
        this._canvas.setWidth(this._width || 600);

        image.scale(this._finalAspectRatio);
        image.filters.push(
          new fabric.Image.filters.Brightness({
            brightness: 0,
          })
        );

        this._bgImage = image;
        this._filename = filename;

        this._canvas.setBackgroundImage(image, this._canvas.renderAll.bind(this._canvas));

        subscriber.next({
          file: new File([bgImage.blob], filename),
          src: bgImage.src,
          width: this._width,
          height: this._height,
          blob: bgImage.blob,
        });

        subscriber.complete();
      };
    });
  }

  private _resizeImage(realWidth = 600, realHeight = 600): number[] {
    const height = this._pageContent?.nativeElement.clientHeight;
    const width = this._pageContent?.nativeElement.clientWidth;
    const widthAspectRatio = (this.resolutionService.isMobileResolution ? MAX_WIDTH_MOBILE - 40 : width - 500) / realWidth;

    const heightAspectRatio = (this.resolutionService.isMobileResolution ? MAX_HEIGHT_MOBILE : height) / realHeight;

    const finalAspectRatio = Math.min(widthAspectRatio, heightAspectRatio);
    const imageHeight = realHeight * finalAspectRatio;
    const imageWidth = realWidth * finalAspectRatio;

    return [imageWidth, imageHeight, finalAspectRatio];
  }
}
