import { NestedTreeControl } from '@angular/cdk/tree';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/auth/services/auth.service';
import { changeLanguage, clearUser, getUser, setLanguage } from 'src/app/store/actions/shared.actions';
import { userName, userRole } from 'src/app/store/selectors/shared.selector';
import { ResolutionService } from 'src/app/user-flow/services/resolution.service';

import { MenuNode, MOBILE_TREE_DATA_ADMIN, MOBILE_TREE_DATA_USER } from '../../const/mobile-menu-tree';
import { Roles } from '../../enums/roles.enum';
import { UserService } from '../../services/user.service';

@UntilDestroy()
@Component({
  selector: 'app-project-header',
  templateUrl: './project-header.component.html',
  styleUrls: ['./project-header.component.scss'],
})
export class ProjectHeaderComponent implements OnInit {
  public userName$: Observable<string>;
  public userRole$: Observable<Roles>;

  public userRoles = Roles;
  public isMenuOpen = false;
  public treeControl = new NestedTreeControl<MenuNode>(node => node.children);
  public dataSource = new MatTreeNestedDataSource<MenuNode>();

  public get resultMenuActive(): boolean {
    return location.href.includes('/result') && !location.href.includes('email');
  }

  public get createMenuActive(): boolean {
    return location.href.includes('/create');
  }

  constructor(
    public authService: AuthService,
    private _router: Router,
    private _store: Store,
    public resolutionService: ResolutionService,
    public translateService: TranslateService,
    private _userService: UserService,
    @Inject(DOCUMENT) private _document: Document
  ) {}

  public ngOnInit(): void {
    const token = localStorage.getItem('token');
    if (token) {
      this._store.dispatch(getUser());
    }
    this.userName$ = this._store.select(userName);
    this.userRole$ = this._store.select(userRole);
    this.userRole$
      .pipe(untilDestroyed(this))
      .subscribe(role => (this.dataSource.data = role === Roles.USER ? MOBILE_TREE_DATA_USER : MOBILE_TREE_DATA_ADMIN));
  }

  public hasChild = (_: number, node: MenuNode): boolean => node?.children?.length > 0;

  public useLanguage(language: string): void {
    this.translateService.use(language);
    localStorage.setItem('lang', language);
    if (this.authService.isLoggedIn()) {
      this._store.dispatch(changeLanguage({ lang: language }));
    } else {
      this._store.dispatch(setLanguage({ lang: language }));
    }
  }

  public onClickedOutside(e: Event): void {
    const target = e.target as HTMLElement;
    this.isMenuOpen = target.classList.contains('close');
  }

  public logOut(): void {
    this.authService.logout();
    this._store.dispatch(clearUser());
    this.isMenuOpen = false;
  }

  public logIn(): void {
    this._router.navigate(['login']);
    this.isMenuOpen = false;
  }

  public toggleMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;
  }

  public goToPage(path: string): void {
    this._router.navigate([path]);
    this.isMenuOpen = false;
  }

  public goToChild(path1: string, path2: string): void {
    this._router.navigate([path1, path2]);
    this.isMenuOpen = false;
  }

  public openSubscriptions(): void {
    this._userService
      .getSubscriptionsLink()
      .pipe(untilDestroyed(this))
      .subscribe(link => (this._document.location.href = link));
  }

  public get isLoggedIn(): boolean {
    return this.authService.isLoggedIn();
  }

  public get isLoggedOut(): boolean {
    return this.authService.isLoggedOut();
  }

  public onNodeClick(node: MenuNode): void {
    if (!node.parent) {
      if (node.name.includes('CONTACT')) {
        this.goToPage('/request');
      } else if (node.name.includes('SUBSCRIPTION')) {
        this.openSubscriptions();
      } else if (node.name.includes('USERS')) {
        this.goToPage('/admin');
      } else {
        this.logOut();
      }
    } else {
      const childUrl = node.name.includes('NO_FURNITURE')
        ? 'no-furniture'
        : node.name.split('.')[node.name.split('.').length - 1].toLocaleLowerCase();
      this.goToChild(node.parent, childUrl);
    }
  }
}
