<div class="product-page">
  <div class="actions">
    <img
      class="back-arrow"
      src="./../../../../assets/icons/arrow-left-short.svg"
      alt="Go back"
      (click)="back()"
      aria-hidden="true"
    />
    <button
      *ngIf="!resolutionService.isMobileResolution"
      [matMenuTriggerFor]="afterMenu"
      class="button"
      color="primary"
      class="button__menu-trigger r-18"
      mat-raised-button
    >
      {{ "BUTTONS.DOWNLOAD" | translate }}
    </button>
    <mat-menu
      #afterMenu="matMenu"
      xPosition="after"
      yPosition="below"
      class="download-menu"
      [overlapTrigger]="false"
    >
      <ng-container *ngTemplateOutlet="form"></ng-container>
    </mat-menu>
  </div>
  <div class="page-content" #pageContent>
    <div class="left-panel-container">
      <app-idea-furniture-container
        [pageType]="leftPanelPage"
        [ngClass]="{
          'show-left-panel': leftPanelOpen,
          'hide-left-panel': !leftPanelOpen
        }"
        #leftPanel
        (openLeftPanel)="openLeftPanel($event)"
        (pageTypeUpdated)="updatePageType($event)"
        *ngIf="!resolutionService.isMobileResolution; else canvas"
        class="catalog"
      ></app-idea-furniture-container>
    </div>
    <div
      class="page-content__description"
      *ngIf="resolutionService.isMobileResolution"
    >
      <div class="name">{{ translateLabel ? (name | translate) : name }}</div>
      <div class="date">{{ date }}</div>
    </div>
    <div class="image-container" #imageContainer>
      <div class="wrapper">
        <canvas id="editor"></canvas>
        <div *ngIf="products?.length > 1">
          <button
            class="icon-button previous"
            [disabled]="activeProductIndex - 1 < 0"
            (click)="previous()"
          >
            <img
              *ngIf="activeProductIndex - 1 >= 0; else leftDisabled"
              class="arrow"
              src="./../../../../assets/icons/Gruppe 3005.svg"
              alt="previous"
            />
          </button>
          <button
            class="icon-button next"
            [disabled]="activeProductIndex + 1 >= products.length"
            (click)="next()"
          >
            <img
              *ngIf="
                activeProductIndex + 1 < products.length;
                else rightDisabled
              "
              class="arrow transform"
              src="../../../assets/icons/Gruppe 3005.svg"
              alt="next"
            />
          </button>
          <span class="image-count"
            >{{ activeProductIndex + 1 }}/{{ products.length }}</span
          >
        </div>
      </div>
    </div>

    <div *ngIf="resolutionService.isMobileResolution" class="form__wrapper">
      <ng-container *ngTemplateOutlet="form"></ng-container>
    </div>
  </div>
</div>

<ng-template #canvas>
  <div class="image-container">
    <canvas id="editor"></canvas>
  </div>
</ng-template>

<ng-template #leftDisabled>
  <img
    class="arrow"
    src="./../../../../assets/icons/Gruppe 3005 disabled.svg"
    alt="left arrow disabled"
  />
</ng-template>

<ng-template #rightDisabled>
  <img
    class="arrow transform"
    src="./../../../../assets/icons/Gruppe 3005 disabled.svg"
    alt="right arrow disabled"
  />
</ng-template>

<ng-template #form>
  <form
    (click)="$event.stopPropagation()"
    [formGroup]="exportSettingsForm"
    class="export-settings-form"
    aria-hidden="true"
  >
    <div class="form-field slider">
      <p class="title no-margins r-18">
        {{ "USER_FLOW.EXPORT_PAGE.BRIGHTNESS" | translate }}
      </p>
      <mat-slider
        class="slider"
        [max]="config.brightnessSliderConfig.max"
        [min]="config.brightnessSliderConfig.min"
        [step]="config.brightnessSliderConfig.step"
      >
        <input matSliderThumb formControlName="brightness" />
      </mat-slider>
      <button
        class="button reset__button"
        mat-stroked-button
        (click)="resetBrightness()"
      >
        {{ "BUTTONS.RESET" | translate }}
      </button>
    </div>
    <mat-form-field class="select-resolution form-field">
      <p class="title no-margins r-18">
        {{ "USER_FLOW.EXPORT_PAGE.RESOLUTION" | translate }}
      </p>
      <mat-select
        class="selector"
        formControlName="resolution"
        (click)="$event.stopPropagation(); ($event.preventDefault)"
      >
        <mat-option
          *ngFor="let resolution of payedResolution"
          [value]="resolution"
          [disabled]="isHighQualityDownloadDisabled$ | async"
          >{{ resolution.name }}</mat-option
        >
        <mat-option
          *ngFor="let resolution of freeResolutions"
          [value]="resolution"
        >
          {{ resolution.name }}</mat-option
        >
      </mat-select>
    </mat-form-field>
    <mat-form-field class="select-format form-field">
      <p class="title no-margins r-18">
        {{ "USER_FLOW.EXPORT_PAGE.FORMAT" | translate }}
      </p>
      <mat-select
        class="selector"
        formControlName="format"
        (click)="$event.stopPropagation(); ($event.preventDefault)"
      >
        <mat-option
          *ngFor="let format of config.exportTypes"
          [value]="format.value"
        >
          {{ format.viewValue }}</mat-option
        >
      </mat-select>
    </mat-form-field>

    <button
      mat-raised-button
      class="button download-button"
      color="primary"
      (click)="downloadImage()"
    >
      {{
        this.resolutionService.isMobileResolution
          ? ("BUTTONS.DOWNLOAD_PHOTO" | translate)
          : ("BUTTONS.DOWNLOAD" | translate)
      }}
    </button>
  </form>
</ng-template>
