import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { combineLatest, filter, Observable, take } from 'rxjs';
import {
  addStyle,
  getProposedFurniture,
  getStyleFinderIdeas,
  setOverlayLoadingSpinner,
  setStyles,
  updateActiveProject,
} from 'src/app/store/actions/shared.actions';
import {
  activeProjectSelectedStyles,
  activeProjectType,
  lastViewedIdeaImages,
  lastViewedIdeaImagesLoaded,
  styles,
  uploadedMoodImagesCount,
} from 'src/app/store/selectors/shared.selector';
import { environment } from 'src/environments/environment';
import { IProjectAdditionalInfo } from 'src/models/project';

import { STYLES } from '../const/styles';
import { RoomType } from '../enum/room-type.enum';
import { Style } from '../enum/style.enum';
import { StylefinderTypes } from '../enum/stylefinder-types';
import { StylefinderItem } from '../interfaces/stylefinder';
import { IStyle } from '../interfaces/styles';
import { RefreshNavigationService } from '../services/refresh-navigation.service';
import { ResolutionService } from '../services/resolution.service';

@UntilDestroy()
@Component({
  selector: 'app-style',
  templateUrl: './style.component.html',
  styleUrls: ['./style.component.scss'],
})
export class StyleComponent implements OnInit {
  public uploadedMoodImagesCount$: Observable<number> = this._store.select(uploadedMoodImagesCount);
  public styles: IStyle[] = [];
  public selectedNumber = 0;
  public selectedStyles: IStyle[] = [];
  public projectInfo: IProjectAdditionalInfo = {};

  constructor(
    private _store: Store,
    private _router: Router,
    private _refreshNavigationService: RefreshNavigationService,
    public resolutionService: ResolutionService
  ) {}

  public ngOnInit(): void {
    this._store.dispatch(getStyleFinderIdeas());
    this._store.dispatch(setOverlayLoadingSpinner({ status: true }));
    combineLatest(
      this._store.select(activeProjectType),
      this._store.select(activeProjectSelectedStyles),
      this._store.select(lastViewedIdeaImages),
      this._store.select(lastViewedIdeaImagesLoaded),
      this._store.select(styles)
    )
      .pipe(
        filter(data => data[3]),
        take(1),
        untilDestroyed(this)
      )
      .subscribe(data => {
        this.styles = [];
        if (data[1]) {
          this.selectedStyles = [...data[1]];
        }
        if (data[4]) {
          this.styles = data[4];
        } else {
          let predefinedSet: IStyle[] = [];
          switch (data[0]) {
            case RoomType.LIVING:
              predefinedSet = STYLES.filter(el => el.roomType === RoomType.LIVING);
              break;
            case RoomType.DINING:
              predefinedSet = STYLES.filter(el => el.roomType === RoomType.DINING);
              break;
            case RoomType.SLEEPING:
              predefinedSet = STYLES.filter(el => el.roomType === RoomType.LIVING);
              break;
            case RoomType.UNSET:
              predefinedSet = STYLES.filter(el => el.roomType === RoomType.LIVING);
              break;
          }
          let ideaIndex = 0;
          predefinedSet.forEach(item => {
            this.styles.push(item);
            if (ideaIndex < data[2].length) {
              const idea = data[2][ideaIndex];
              this.styles.push({
                id: idea.id,
                image: idea.resizedSrc,
                style: Style.NO_STYLE,
                roomType: RoomType.UNSET,
                type: StylefinderTypes.IDEA,
                selected: false,
                link: idea.link,
              });
              ideaIndex++;
            }
          });
          this._store.dispatch(setStyles({ styles: this.styles }));
        }
        this._setSelectedStyles();
        this._store.dispatch(setOverlayLoadingSpinner({ status: false }));
      });

    this._refreshNavigationService.refreshPageEvent$.pipe(untilDestroyed(this)).subscribe(() => this._router.navigate(['choose-file']));
  }

  public next(): void {
    const data = this._prepareData();
    this._store.dispatch(getProposedFurniture({ data }));
    this.projectInfo.style = this.calcStyle();
    this.projectInfo.selectedStyles = this.selectedStyles;
    this._store.dispatch(updateActiveProject({ projectInfo: this.projectInfo }));
    this._router.navigate(['budget']);
  }

  private _prepareData(): StylefinderItem[] {
    return this.selectedStyles.map(style => {
      switch (style.type) {
        case StylefinderTypes.PREDEFINED: {
          return {
            type: StylefinderTypes.PREDEFINED,
            url: environment.url + style.image.split('../').filter(str => str.length)[0],
          };
        }
        case StylefinderTypes.IDEA: {
          return {
            type: StylefinderTypes.IDEA,
            link: style.link,
          };
        }
        case StylefinderTypes.BASE64: {
          return {
            type: StylefinderTypes.BASE64,
            base64: style.base64,
          };
        }
      }
    });
  }

  public back(): void {
    this.projectInfo.style = this.calcStyle();
    this.projectInfo.selectedStyles = this.selectedStyles;
    this._store.dispatch(updateActiveProject({ projectInfo: this.projectInfo }));
    this._router.navigate(['room-type']);
  }

  public async fileUploaded(file: File): Promise<void> {
    const url = await this.imageUrlToBase64(URL.createObjectURL(file));
    const base64 = (url as string).split(';base64,')[1];
    const style = {
      id: Math.round(Math.random() * 100) + 50,
      image: URL.createObjectURL(file),
      style: Style.NO_STYLE,
      roomType: RoomType.UNSET,
      type: StylefinderTypes.BASE64,
      selected: false,
      base64,
    };
    this.styles.unshift(style);
    this._store.dispatch(addStyle({ style }));
  }

  public updateClass(style: IStyle): void {
    if ((this.selectedStyles.length < 5 && style.selected === false) || style.selected === true) {
      this.styles = this.styles.map(el => {
        const updatedValue = { ...el };
        if (el.image === style.image) {
          updatedValue.selected = !updatedValue.selected;

          if (updatedValue.selected) {
            this.selectedStyles.push(updatedValue);
          } else {
            this.selectedStyles = this.selectedStyles.filter(item => el.image !== item.image);
          }
        }
        return updatedValue;
      });
    }
  }

  public positionCount(style: IStyle): number {
    return this.selectedStyles.findIndex(el => el.image === style.image) + 1;
  }

  private _setSelectedStyles(): void {
    if (this.selectedStyles) {
      this.styles = this.styles.map(el => {
        if (this.selectedStyles.findIndex(selected => selected.id === el.id) !== -1) {
          return { ...el, selected: true };
        } else {
          return { ...el, selected: false };
        }
      });
    } else {
      this.styles = this.styles.map(el => {
        el.selected = false;
        return el;
      });
    }
  }

  private calcStyle(): string {
    const styleCounter = this.selectedStyles.reduce((obj, el) => {
      if (!obj[el.style]) {
        obj[el.style] = { count: 1 };
      } else {
        obj[el.style].count++;
      }
      return obj;
    }, {});
    const entries = Object.keys(styleCounter);
    let majority = null;

    entries.forEach(key => {
      styleCounter[key].percent = Number(styleCounter[key].count) / this.selectedStyles.length;
      if (styleCounter[key].percent >= 0.5) {
        majority = key;
      }
    });
    if (majority) {
      return majority;
    } else if (styleCounter[Style.BOHO_CHIC]?.percent + styleCounter[Style.INDUSTRIAL]?.percent >= 0.5) {
      return Style.INDUSTRIAL;
    } else if (styleCounter[Style.NEW_SCANDINAVIAN]?.percent + styleCounter[Style.ASIA]?.percent >= 0.5) {
      return Style.NEW_SCANDINAVIAN;
    } else if (styleCounter[Style.NEW_SCANDINAVIAN]?.percent + styleCounter[Style.WABI_SABI]?.percent >= 0.5) {
      return Style.NEW_SCANDINAVIAN;
    } else if (styleCounter[Style.ASIA]?.percent + styleCounter[Style.MODERN_COUNTRY]?.percent >= 0.5) {
      return Style.ASIA;
    } else if (styleCounter[Style.WABI_SABI]?.percent + styleCounter[Style.MODERN_COUNTRY]?.percent >= 0.5) {
      return Style.WABI_SABI;
    } else {
      return Style.NO_STYLE;
    }
  }

  private async imageUrlToBase64(url: string): Promise<string | ArrayBuffer> {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = (): void => {
        const base64data = reader.result;
        resolve(base64data);
      };
      reader.onerror = reject;
    });
  }
}
