<div class="page-container">
  <div class="header">
    <p class="title">{{ "AUTH.REGISTRATION_PAGE.TITLE" | translate}}</p>
    <p class="subtitle">{{ "AUTH.REGISTRATION_PAGE.SUBTITLE" | translate}}</p>
  </div>
  <section class="page-content">
    <form [formGroup]="form">
      <p>
        <app-form-field
          name="firstName"
          label="AUTH.REGISTRATION_PAGE.FIRST_NAME"
          type="text"
          [abstractControl]="form.get('firstName')"
        ></app-form-field>
      </p>
      <p>
        <app-form-field
          name="last name"
          label="AUTH.REGISTRATION_PAGE.LAST_NAME"
          type="text"
          [abstractControl]="form.get('lastName')"
        ></app-form-field>
      </p>
      <p>
        <app-form-field
          name="email"
          label="AUTH.REGISTRATION_PAGE.EMAIL"
          type="text"
          [abstractControl]="form.get('email')"
        ></app-form-field>
      </p>
      <p>
        <app-form-field
          name="password"
          label="AUTH.REGISTRATION_PAGE.PASSWORD"
          type="password"
          [abstractControl]="form.get('password')"
        ></app-form-field>
      </p>
      <p>
        <app-form-field
          name="repeatPassword"
          label="AUTH.REGISTRATION_PAGE.REPEAT_PASSWORD"
          type="password"
          [abstractControl]="form.get('repeatPassword')"
        ></app-form-field>
      </p>
      <div>
        <button class="button" color="primary" type="submit" mat-raised-button [disabled]="form.invalid" (click)="submit()">{{ "AUTH.REGISTRATION_PAGE.REGISTRATION" | translate}}</button>
        <div class="create">
          <span>{{ "AUTH.REGISTRATION_PAGE.ACCOUNT_EXIST" | translate}}</span>
          <a class="link" routerLink="/login">{{ "AUTH.LOGIN_PAGE.LOGIN" | translate}}</a>
        </div>
      </div>
    </form>
  </section>
</div>
