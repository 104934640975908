import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MathUtils } from 'three';

import { MovingObject } from '../../services/model/dto/moving-object.model';

@UntilDestroy()
@Component({
  selector: 'app-rotation-dialog',
  templateUrl: './rotation-dialog.component.html',
  styleUrls: ['./rotation-dialog.component.scss'],
})
export class RotationDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { movingObject: MovingObject },
    private _fb: FormBuilder
  ) {}

  public get currentValueDegrees(): number {
    return Math.floor(this.rotateControl.value);
  }

  public rotateControl: FormControl<number> = this._fb.control(0);
  public currentSelectedAxis: 'x' | 'y' | 'z' = 'y';

  private _degRotationX: number = MathUtils.RAD2DEG * this.data.movingObject.object3D.rotation.x;
  private _degRotationY: number = MathUtils.RAD2DEG * this.data.movingObject.object3D.rotation.y;
  private _degRotationZ: number = MathUtils.RAD2DEG * this.data.movingObject.object3D.rotation.z;

  public ngOnInit(): void {
    switch (this.currentSelectedAxis) {
      case 'x':
        this.rotateControl.setValue(this._degRotationX);
        break;
      case 'y':
        this.rotateControl.setValue(this._degRotationY);
        break;
      case 'z':
        this.rotateControl.setValue(this._degRotationZ);
        break;
    }

    this.rotateControl.valueChanges.pipe(untilDestroyed(this)).subscribe(angle => {
      const radians = MathUtils.DEG2RAD * angle;
      switch (this.currentSelectedAxis) {
        case 'x':
          this.xChangeAxis(radians);
          break;
        case 'y':
          this.yChangeAxis(radians);
          break;
        case 'z':
          this.zChangeAxis(radians);
          break;
      }
    });
  }

  public resetDegrees(): void {
    switch (this.currentSelectedAxis) {
      case 'x':
        this.rotateControl.setValue(this._degRotationX);
        break;
      case 'y':
        this.rotateControl.setValue(this._degRotationY);
        break;
      case 'z':
        this.rotateControl.setValue(this._degRotationZ);
        break;
    }
  }

  public selectAxis(axis: 'x' | 'y' | 'z'): void {
    this.currentSelectedAxis = axis;

    switch (this.currentSelectedAxis) {
      case 'x':
        this.rotateControl.setValue(MathUtils.RAD2DEG * this.data.movingObject.object3D.rotation.x, { emitEvent: false });
        break;
      case 'y':
        this.rotateControl.setValue(MathUtils.RAD2DEG * this.data.movingObject.object3D.rotation.y, { emitEvent: false });
        break;
      case 'z':
        this.rotateControl.setValue(MathUtils.RAD2DEG * this.data.movingObject.object3D.rotation.z, { emitEvent: false });
        break;
    }
  }

  public xChangeAxis(angle: number): void {
    this.data.movingObject.object3D.rotation.order = 'XYZ';
    this.data.movingObject.object3D.rotation.x = angle;
  }

  public yChangeAxis(angle: number): void {
    this.data.movingObject.object3D.rotation.order = 'YZX';
    this.data.movingObject.object3D.rotation.y = angle;
  }

  public zChangeAxis(angle: number): void {
    this.data.movingObject.object3D.rotation.order = 'ZXY';
    this.data.movingObject.object3D.rotation.z = angle;
  }
}
