<div class="content">
  <div class="header">
    <div class="name text-title">
      {{ name }}
    </div>
    <div class="code text-subtitle">
      {{ code }}
    </div>
    <div class="actions">
      <button class="button" mat-stroked-button (click)="clearSelectedColor()">
        <img class="icon" src="./../../../../../assets/icons/trash-bin-green.svg" alt="trash-bin">
        {{'BUTTONS.DELETE' | translate}}
      </button>
      <button class="button" mat-stroked-button [ngClass]="{'inactive': this.colorData?.colorFixed}" (click)="fixSelectedColor()">
        <img class="icon" src="./../../../../../assets/icons/fixed-icon.svg" alt="fix">
        {{'BUTTONS.FIX' | translate}}
      </button>
    </div>
  </div>
  <div class="color" *ngIf="code">
    <div class="rectangle" [style.background-color]="hex"></div>
  </div>
  <div class="variants">
    <div class="variants-header text-base">{{ "USER_FLOW.COLOR_PAGE.VARIANTS_TEXT" | translate }}</div>
    <div class="variants-list">
      <div
        *ngFor="let variant of variants"
        class="variant"
        [ngClass]="{
          'variant__no-color': variant.Group === colorGroups.NO_COLOR,
          'variant__active': variant.Code === code
        }"
        [style.background-color]="variant.Hex"
        (click)="setColor(variant)"
        aria-hidden="true"
      >
      </div>
    </div>
  </div>
  <button class="button-next text-base" (click)="navigateToAllColors()">{{ "USER_FLOW.COLOR_PAGE.TO_ALL_COLORS" | translate }}</button>
</div>
