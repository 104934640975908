import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ObjectGoogle } from 'src/services/model/detect-objects-google';

@Injectable()
export class CropImageService {
  constructor(@Inject(DOCUMENT) private _document: Document) {}

  public cropImage(coord: ObjectGoogle, src: string, width: number, height: number): Observable<Blob> {
    return new Observable(subscriber => {
      const image = new Image();
      image.src = src;
      const canvas = this._document.createElement('canvas');
      const context = canvas.getContext('2d');
      const top = coord.y_min * height;
      const left = coord.x_min * width;
      const imgWidth = (coord.x_max - coord.x_min) * width;
      const imgHeight = (coord.y_max - coord.y_min) * height;
      canvas.width = imgWidth;
      canvas.height = imgHeight;
      image.onload = function (): void {
        context.drawImage(image, left, top, imgWidth, imgHeight, 0, 0, imgWidth, imgHeight);

        canvas.toBlob(result => {
          subscriber.next(result);
          subscriber.complete();
        });
      };
    });
  }
}
