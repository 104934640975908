<app-versions-list
    [size]="size"
    [projectId]="projectId"
    [totalElements$]="totalElements$"
    [versions$]="versions$"
    (editVersionEvent)="editVersion($event)"
    (removeVersionEvent)="removeVersion($event)"
    (updateVersions)="getVersions($event)"
    (backVersionEvent)="back()"
></app-versions-list>
