import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpContext } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { catchError, filter, forkJoin, map, mergeMap, Observable, of, switchMap, tap } from 'rxjs';
import { Notification } from 'src/app/notifications/model/notification';
import { NotificationsService } from 'src/app/notifications/services/notifications.service';
import { furnitureDetailsArticle } from 'src/app/store/selectors/shared.selector';
import { AR, BasicArticleInformation, CatalogData, Links } from 'src/services/model/proposal';
import { Group, Mesh, Object3D } from 'three';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { TDSLoader } from 'three/examples/jsm/loaders/TDSLoader';

import { getObjectSize } from './autopositioning.function';
import { getStickiness } from './const/stickness-to-furniture';
import { MovingObject, MovingObjectInfo, ValueType } from './model/dto/moving-object.model';
import { SceneSaveData } from './model/saveData/scene-save-data.model';
import { MovingObjectsService } from './moving-objects.service';
import { ResolutionService } from './resolution.service';
import { RoomParamsService } from './room-params.service';
import { ProposalArticleReplaceByType, ProposalArticleReplaceResponse, ProposalState } from '../../../models/proposal';
import { ERROR_SKIP_INTERCEPT } from '../../../services/context-tokens/error.context-token';
import { FurnitureHttpService } from '../../../services/furniture-http.service';
import { ResourcesHttpService } from '../../../services/resources-http.service';
import { viewFurniture } from '../../store/actions/shared.actions';
import { LeftPanelState } from '../catalog/left-panel-container/left-panel-state.enum';
import { FurnitureType } from '../enum/furniture-types.enum';
import { LoadModelDialogComponent } from '../load-model-dialog/load-model-dialog.component';

import MaterialCreator = MTLLoader.MaterialCreator;

@UntilDestroy()
@Injectable()
export class ImportService {
  private _gltfLoader: GLTFLoader = new GLTFLoader();
  private _fbxLoader: FBXLoader = new FBXLoader();
  private _objLoader: OBJLoader = new OBJLoader();
  private _mtlLoader: MTLLoader = new MTLLoader();
  private _tdsLoader: TDSLoader = new TDSLoader();

  private _currentCatalogData: CatalogData | null = null;

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private _dialog: MatDialog,
    private _http: HttpClient,
    private _movingObjectsService: MovingObjectsService,
    private _notificationsService: NotificationsService,
    private _translateService: TranslateService,
    private _roomParamsService: RoomParamsService,
    private _store: Store,
    private _furnitureHttpService: FurnitureHttpService,
    private _resourcesHttpService: ResourcesHttpService,
    private _resolutionService: ResolutionService
  ) {
    this._store
      .select(furnitureDetailsArticle)
      .pipe(untilDestroyed(this))
      .subscribe(catalogData => {
        this._currentCatalogData = catalogData ?? null;
      });
  }

  public loadModel(object?: MovingObjectInfo): void {
    if (this._roomParamsService.canAutoscale) {
      const input: HTMLInputElement = this._document.createElement('input');
      input.type = 'file';
      input.multiple = true;
      input.accept = '.glb,.gltf,.fbx,.obj,.3ds';
      input.onchange = (event: any): void => {
        if (event.target.files.length > 0) {
          const file = event.target.files[0];
          this.handleFile(file, object);
        }
      };
      input.click();
    } else {
      this._notificationsService.addNotification(
        new Notification({
          title: this._translateService.instant('NOTIFICATIONS.TITLE.INFO'),
          text: this._translateService.instant('NOTIFICATIONS.MESSAGES.NEED_RECONSTRUCTION'),
          level: 'success',
          options: { timeout: 2 },
        })
      );
    }
  }

  public loadUserModel(filename: string): Observable<Group> {
    return this._resourcesHttpService.downloadPublicResource(filename).pipe(
      switchMap(blob => {
        const format = filename.split('.').pop();
        const file = new File([blob], filename);
        return this._handleFormat(format, file);
      }),
      map(data => data.group)
    );
  }

  public handleFile(file: File, object?: MovingObjectInfo): void {
    const format = file.name.split('.').pop();

    this._movingObjectsService.disableKeyboard();
    this._handleFormat(format, file)
      .pipe(
        switchMap(({ file, group }) => this._loadUserModelFile(file).pipe(map(({ id }) => ({ id, group })))),
        switchMap(({ id, group }) => {
          this._placeNewModel(group, true, object);
          return this._configureMovingObject(group, true, undefined, id);
        }),
        untilDestroyed(this)
      )
      .subscribe({
        next: object => this.viewFurniture(object),
        complete: () => this._movingObjectsService.enableKeyboard(),
      });
  }

  private _handleFormat(format: string, file: File): Observable<{ group: Group; file: File }> {
    let formatHandler: Observable<Group>;

    switch (format) {
      case 'gltf':
      case 'glb':
        formatHandler = this._handleGltf(file);
        break;
      case 'fbx':
        formatHandler = this._handleFbx(file);
        break;
      case 'obj':
        formatHandler = this._loadMtl().pipe(mergeMap(materialCreator => this._handleObj(file, materialCreator)));
        break;
      case '3ds':
        formatHandler = this._handleTds(file);
        break;
      default:
        formatHandler = this._handleGltf(file);
    }

    return formatHandler.pipe(map(group => ({ group, file })));
  }

  public calculateFormate(ar: AR): string {
    if (ar.GLB) {
      return 'glb';
    } else if (ar.FBX) {
      return 'fbx';
    }
    return null;
  }

  public loadLQModel(links: Links, hideError: boolean): Observable<Group> {
    const context = new HttpContext();
    context.set(ERROR_SKIP_INTERCEPT, true);
    return this._http.get(links.lowGLB, { responseType: 'blob', context }).pipe(
      switchMap(blob => this._handleGltf(blob)),
      catchError(() => {
        if (links.GLB) {
          return this._tryGLBModel(links, hideError);
        } else if (links.FBX) {
          return this._tryFBXModel(links, hideError);
        }
        return of(null);
      })
    );
  }

  private _tryGLBModel(links: Links, hideError: boolean): Observable<Group> {
    const context = new HttpContext();
    context.set(ERROR_SKIP_INTERCEPT, true);
    return this._http.get(links.GLB, { responseType: 'blob', context }).pipe(
      switchMap(blob => this._handleGltf(blob)),
      catchError(() => {
        if (links.FBX) {
          return this._tryFBXModel(links, hideError);
        }
        return of(null);
      })
    );
  }

  private _tryFBXModel(links: Links, hideError: boolean): Observable<Group> {
    const context = new HttpContext();
    if (hideError) {
      context.set(ERROR_SKIP_INTERCEPT, true);
    }
    return this._http.get(links.FBX, { responseType: 'blob', context }).pipe(
      switchMap(blob => this._handleFbx(blob)),
      catchError(() => {
        return of(null);
      })
    );
  }

  private _placeNewModel(group: Object3D, touchStore: boolean, object?: MovingObjectInfo): void {
    if (object) {
      this._movingObjectsService.deleteFurniture(object.id, touchStore);
    }

    const position = object?.position ? object.position.clone() : this._roomParamsService.get().objectStartPosition.clone();
    const rotation = object?.rotation ? object.rotation.clone() : group.rotation.clone();
    group.position.set(position.x, position.y, position.z);
    group.rotation.set(rotation.x, rotation.y, rotation.z);
  }

  private _calculateLink(model: BasicArticleInformation): Links {
    const links: Links = {} as Links;
    const lowGlb = model.MetaData.find(el => el.Key === '3D_GLB_LOW_ELI');
    if (lowGlb && lowGlb.Value?.length > 0) {
      links.lowGLB = lowGlb.Value;
    }
    if (model.AR.GLB?.length > 0) {
      links.GLB = model.AR.GLB;
    }
    if (model.AR.FBX?.length > 0) {
      links.FBX = model.AR.FBX;
    }
    return links;
  }

  private _tryOtherFurnitureProposal(
    replaceByType: ProposalArticleReplaceByType,
    touchStore: boolean,
    movingObjectInfo?: MovingObjectInfo
  ): Observable<{ movingObject: MovingObject; model: BasicArticleInformation }> {
    return this._furnitureHttpService.proposalReplaceType(replaceByType).pipe(
      switchMap(repeatResponse => {
        const newArticle = repeatResponse.UpdatedProposalTO.Articles.find(
          article => article.Article.BasicArticleInformation.SKU === repeatResponse.NewArticleSku
        );
        return this.loadFromArticle(newArticle.Article.BasicArticleInformation, touchStore, movingObjectInfo, replaceByType);
      }),
      catchError(() => of(null))
    );
  }

  private _tryOtherFurnitureProposalMultiple(
    replaceByType: ProposalArticleReplaceByType,
    touchStore: boolean,
    movingObjectsInfo?: MovingObjectInfo[]
  ): Observable<{ movingObjects: MovingObject[]; model: BasicArticleInformation; repeatResponse?: ProposalArticleReplaceResponse }> {
    return this._furnitureHttpService.proposalReplaceType(replaceByType).pipe(
      switchMap(repeatResponse => {
        const newArticle = repeatResponse.UpdatedProposalTO.Articles.find(
          article => article.Article.BasicArticleInformation.SKU === repeatResponse.NewArticleSku
        );
        return this.loadFromArticleMultiple(
          newArticle.Article.BasicArticleInformation,
          touchStore,
          movingObjectsInfo,
          replaceByType,
          repeatResponse
        );
      }),
      catchError(() => {
        return of(null);
      })
    );
  }

  public loadFromArticle(
    model: BasicArticleInformation,
    touchStore: boolean,
    object?: MovingObjectInfo,
    replaceByType?: ProposalArticleReplaceByType,
    proposalStateAfter?: ProposalState
  ): Observable<{ movingObject: MovingObject; model: BasicArticleInformation }> {
    if (this._roomParamsService.canAutoscale) {
      return this.loadLQModel(this._calculateLink(model), true).pipe(
        switchMap(group => {
          if (group) {
            this._placeNewModel(group, touchStore, object);
            return this._configureMovingObject(group, touchStore, model, null, proposalStateAfter);
          }

          return of(null);
        }),
        switchMap(data => (!data ? this._tryOtherFurnitureProposal(replaceByType, touchStore, object) : of({ movingObject: data, model })))
      );
    } else {
      this._notificationsService.addNotification(
        new Notification({
          title: this._translateService.instant('NOTIFICATIONS.TITLE.INFO'),
          text: this._translateService.instant('NOTIFICATIONS.MESSAGES.NEED_RECONSTRUCTION'),
          level: 'success',
          options: { timeout: 2 },
        })
      );
      return of(null);
    }
  }

  // tries to load requested model(catalog item)
  // if model links does not exist, request another model and tries again
  // if model has links and was successfully loaded first time => no need to receive new response, so repeatedResponse is still undefined
  // else will make request until there is model with existing links and return final response which will be used in effect after this method executes
  public loadFromArticleMultiple(
    model: BasicArticleInformation,
    touchStore: boolean,
    objects?: MovingObjectInfo[],
    replaceByType?: ProposalArticleReplaceByType,
    repeatedResponse?: ProposalArticleReplaceResponse
  ): Observable<{ movingObjects: MovingObject[]; model: BasicArticleInformation; repeatedResponse?: ProposalArticleReplaceResponse }> {
    if (this._roomParamsService.canAutoscale) {
      return this.loadLQModel(this._calculateLink(model), true).pipe(
        switchMap(group => {
          if (group) {
            const configurations$: Observable<MovingObject>[] = [];
            for (let idx = 0; idx < objects.length; idx++) {
              const groupClone = group.clone(true);
              this._placeNewModel(groupClone, touchStore, objects[idx]);
              configurations$.push(this._configureMovingObject(groupClone, touchStore, model));
            }

            return forkJoin(configurations$);
          }
          return of(null);
        }),
        switchMap(data => {
          if (!data || data.length === 0) {
            return this._tryOtherFurnitureProposalMultiple(replaceByType, touchStore, objects);
          } else {
            return of({ movingObjects: data, model, repeatedResponse });
          }
        })
      );
    } else {
      this._notificationsService.addNotification(
        new Notification({
          title: this._translateService.instant('NOTIFICATIONS.TITLE.INFO'),
          text: this._translateService.instant('NOTIFICATIONS.MESSAGES.NEED_RECONSTRUCTION'),
          level: 'success',
          options: { timeout: 2 },
        })
      );
      return of(null);
    }
  }

  public loadScene(blob: Blob): Observable<SceneSaveData> {
    return new Observable<SceneSaveData>(subscriber => {
      blob.text().then(jsonString => {
        subscriber.next(JSON.parse(jsonString) as SceneSaveData);
        subscriber.complete();
      });
    });
  }

  private _loadMtl(): Observable<MaterialCreator> {
    return new Observable(subscriber => {
      const input: HTMLInputElement = this._document.createElement('input');
      input.type = 'file';
      input.multiple = true;
      input.accept = '.mtl';
      input.onchange = (event: any): void => {
        if (event.target.files.length > 0) {
          const file = event.target.files[0];

          this._handleMtl(file).subscribe(materialCreator => {
            subscriber.next(materialCreator);
            subscriber.complete();
          });
        }
      };
      input.click();
    });
  }

  private _handleTds(file: File): Observable<Group> {
    return new Observable(subscriber => {
      const url: string = URL.createObjectURL(file);
      this._tdsLoader.load(url, group => {
        this._handleMainObject(group);
        subscriber.next(group);
        subscriber.complete();
      });
    });
  }

  private _handleMtl(file: File): Observable<MaterialCreator> {
    return new Observable(subscriber => {
      const url: string = URL.createObjectURL(file);
      this._mtlLoader.load(url, materialCreator => {
        subscriber.next(materialCreator);
        subscriber.complete();
      });
    });
  }

  private _handleFbx(file: File | Blob): Observable<Group> {
    return new Observable(subscriber => {
      const url: string = URL.createObjectURL(file);
      this._fbxLoader.load(url, group => {
        this._handleMainObject(group);
        subscriber.next(group);
        subscriber.complete();
      });
    });
  }

  private _handleGltf(file: File | Blob): Observable<Group> {
    return new Observable(subscriber => {
      const url: string = URL.createObjectURL(file);
      this._gltfLoader.load(
        url,
        gltf => {
          this._handleMainObject(gltf.scene);
          subscriber.next(gltf.scene);
          subscriber.complete();
        },
        () => {},
        () => {
          subscriber.next(null);
          subscriber.complete();
        }
      );
    });
  }

  private _handleMainObject(group: Group): void {
    group.userData['isMainObject'] = true;
    group.traverse((node: Object3D) => {
      if (node instanceof Mesh) {
        node.receiveShadow = true;
      }
    });
  }

  private _handleObj(file: File, materials: MaterialCreator): Observable<Group> {
    return new Observable(subscriber => {
      const url: string = URL.createObjectURL(file);
      this._objLoader.setMaterials(materials);
      this._objLoader.load(url, group => {
        this._handleMainObject(group);
        subscriber.next(group);
        subscriber.complete();
      });
    });
  }

  private _getMovingObjectFromArticle(group: Object3D, article: BasicArticleInformation): Observable<MovingObject> {
    const autoscaleParams = this._getAutoscaleParams(article);
    const movingObject: MovingObject = {
      id: group.uuid,
      SKU: article.SKU,
      name: article.Type,
      dimensions: article.Dimensions,
      valueMeters: autoscaleParams.valueMeters,
      pixelsSize: getObjectSize(group),
      valueType: autoscaleParams.valueType,
      object3D: group,
      type: article.Type,
      sticknessType: getStickiness(article.Type),
      lock: false,
      links: this._calculateLink(article),
      price: article.Price,
      preview: article.PackshotImages[0],
      format: this.calculateFormate(article.AR),
      description: article.Description,
      size: {
        width: article.Dimensions.WidthInCm,
        height: article.Dimensions.HeightInCm,
        length: article.Dimensions.LengthInCm,
      },
      title: article.Title,
      color: article.Options.find(el => el.Name === 'Farbe')?.Value,
      sizes: article.Options.find(el => el.Name === 'Grösse')?.Value,
      manuallyLoaded: false,
      manuallyLoadedFile: null,
      underCategory: article.UnderCategory,
    };

    return of(movingObject);
  }

  private _configureMovingObject(
    group: Group,
    touchStore: boolean,
    article?: BasicArticleInformation,
    filename?: string,
    proposalStateAfter?: ProposalState
  ): Observable<MovingObject> {
    const movingObject$ = article ? this._getMovingObjectFromArticle(group, article) : this._createEmptyMovingObject(group, filename);

    return movingObject$.pipe(
      map(movingObject => {
        this._movingObjectsService.addObject(movingObject, touchStore, null, proposalStateAfter);
        this._movingObjectsService.autoscaleModel(movingObject);
        return movingObject;
      })
    );
  }

  public getObjectInfo(object: MovingObject): Observable<CatalogData> {
    const emptyArticle: CatalogData = {
      BasicArticleInformation: {
        AR: null,
        Dimensions: null,
        Options: [],
        PackshotImages: null,
        Price: 0,
        SKU: null,
        Title: object?.name,
        Type: object?.type,
        Vendor: null,
        Description: null,
        MetaData: null,
        IsChosen: false,
        UnderCategory: null,
        NumberOfExemplars: 0,
      },
      Variants: null,
    };

    return object.manuallyLoaded ? of(emptyArticle) : this._furnitureHttpService.getSingleItem(object.SKU).pipe(map(data => data.Article));
  }

  public viewFurniture(object: MovingObject): void {
    this._movingObjectsService.select(object);

    if (!this._currentCatalogData || object.SKU !== this._currentCatalogData?.BasicArticleInformation.SKU) {
      this.getObjectInfo(object)
        .pipe(untilDestroyed(this))
        .subscribe(data => {
          this._store.dispatch(
            viewFurniture({
              data: {
                article: data,
                from: LeftPanelState.SCENE_DETAILS,
                translateDetails: data.BasicArticleInformation.Description ? true : false,
              },
            })
          );
        });
    } else {
      this._store.dispatch(
        viewFurniture({ data: { article: this._currentCatalogData, from: LeftPanelState.SCENE_DETAILS, translateDetails: false } })
      );
    }
  }

  private _getAutoscaleParams(model: BasicArticleInformation): { valueMeters: number; valueType: ValueType } {
    let valueMeters: number;
    let valueType: ValueType;
    switch (model.Type) {
      case FurnitureType.CARPET:
        if (model.Dimensions.LengthInCm) {
          valueMeters = model.Dimensions.LengthInCm / 100;
          valueType = ValueType.DEPTH;
        } else if (model.Dimensions.DiameterInCm) {
          valueMeters = model.Dimensions.DiameterInCm / 100;
          valueType = ValueType.WIDTH;
        }
        break;
      case FurnitureType.LAMP:
        valueMeters = (model.Dimensions.DiameterInCm ?? model.Dimensions.WidthInCm ?? model.Dimensions.LengthInCm) / 100;
        valueType = ValueType.WIDTH;
        break;
      case FurnitureType.BED:
        if (model.Dimensions.LengthInCm) {
          valueMeters = model.Dimensions.LengthInCm / 100;
          valueType = ValueType.DEPTH;
        }
        break;
      case FurnitureType.PAINTING:
        {
          if (model.Dimensions.WidthInCm) {
            valueMeters = Math.max(model.Dimensions.WidthInCm / 100, 0.5);
            valueType = ValueType.WIDTH;
          } else if (model.Dimensions.HeightInCm) {
            valueMeters = Math.max(model.Dimensions.LengthInCm / 100, 0.5);
            valueType = ValueType.HEIGHT;
          }
        }
        break;
      case FurnitureType.SIDE_TABLE:
        {
          valueMeters = Math.min(model.Dimensions.HeightInCm / 100, 0.6);
          valueType = ValueType.HEIGHT;
        }
        break;
      default:
        valueMeters = model.Dimensions.HeightInCm / 100;
        valueType = ValueType.HEIGHT;
    }
    return { valueMeters, valueType };
  }

  private _createEmptyMovingObject(object: Object3D, filename?: string): Observable<MovingObject> {
    const dialogRef = this._dialog.open(LoadModelDialogComponent);

    const split = filename.split('.');
    const sku = split[0];
    const format = split[1];

    return dialogRef.afterClosed().pipe(
      filter(result => !!result),
      tap(result => (object.name = result.name)),
      map(result => ({
        id: object.uuid,
        name: result.name,
        valueMeters: result.value,
        dimensions: null,
        pixelsSize: getObjectSize(object),
        valueType: result.valueType,
        object3D: object,
        type: result.type,
        sticknessType: result.sticknessType,
        lock: false,
        price: 0,
        links: null,
        preview: null,
        format: format ?? null,
        SKU: sku ?? null,
        description: null,
        size: {
          width: null,
          height: null,
          length: null,
        },
        title: null,
        color: null,
        sizes: null,
        manuallyLoaded: true,
        manuallyLoadedFile: filename,
        underCategory: null,
      }))
    );
  }

  private _loadUserModelFile(file: File): Observable<{ id: string }> {
    return this._resourcesHttpService.uploadPublicResource(file);
  }
}
