import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable, tap } from 'rxjs';
import { Vector3 } from 'three';

import { PlaneType } from './enum/plane-type.enum';
import { RoomParamsServiceConfig } from './model/config/room-params-service-comfig.model';
import { RoomParams } from './model/dto/room-params.model';
import { SetHeightDialogComponent } from '../set-height-dialog/set-height-dialog.component';

@Injectable()
export class RoomParamsService {
  public get canAutoscale(): boolean {
    return !!this._roomParams.height3D && !!this._roomParams.heightMeters;
  }

  private _config: RoomParamsServiceConfig;
  private _roomParams: RoomParams = {
    heightMeters: 0,
    height3D: 0,
    width3D: 0,
    depth3D: 0,
    objectStartPosition: new Vector3(0, 0, -2),
  };

  private _roomBoundaries: {
    yMax: number;
    yMin: number;
    xMax: number;
    xMin: number;
    zMin: number;
    zMax: number;
  };

  public roomHeightControl = new FormControl<number>(this._roomParams.heightMeters);

  public get roomBoundaries(): { yMax: number; yMin: number; xMax: number; xMin: number; zMin: number; zMax: number } {
    return this._roomBoundaries;
  }

  constructor(private _dialog: MatDialog) {}

  public setInitialState(): void {
    this._roomParams = {
      heightMeters: 0,
      height3D: 0,
      width3D: 0,
      depth3D: 0,
      objectStartPosition: new Vector3(0, 0, -2),
    };

    this._roomBoundaries = {
      xMin: 0,
      xMax: 0,
      yMin: 0,
      yMax: 0,
      zMin: 0,
      zMax: 0,
    };
    this._config = null;
    this.roomHeightControl = new FormControl<number>(this._roomParams.heightMeters);
  }

  public get(): RoomParams {
    return this._roomParams;
  }

  public configure(config: RoomParamsServiceConfig, roomParams?: RoomParams): void {
    if (roomParams) {
      this._roomParams = roomParams;
      this._roomParams.objectStartPosition = new Vector3(
        this._roomParams.objectStartPosition.x,
        this._roomParams.objectStartPosition.y,
        this._roomParams.objectStartPosition.z
      );
    }

    this._config = config;

    const wallCorners = this._config.scene.getObjectByName('wallCornersGroup').children;
    const floor = wallCorners
      .filter(corner => corner.userData['planes'].find(plane => plane.type === PlaneType.FLOOR))
      .map(mesh => mesh.position);

    const ceiling = wallCorners
      .filter(corner => corner.userData['planes'].find(plane => plane.type === PlaneType.CEILING))
      .map(mesh => mesh.position);

    this._calculateRoomBoundaries(floor, ceiling);
  }

  public setParams(params: RoomParams): void {
    this._roomParams = params;
    this.roomHeightControl.setValue(this._roomParams.heightMeters, { emitEvent: false });
  }

  public setRoomHeightDialog(current?: boolean): Observable<number> {
    const data = current ? { current: this._roomParams.heightMeters } : null;
    return this._dialog
      .open(SetHeightDialogComponent, { data, disableClose: true })
      .afterClosed()
      .pipe(
        tap(height => {
          this._roomParams.heightMeters = height;
          this.roomHeightControl.setValue(this._roomParams.heightMeters, { emitEvent: false });
        })
      );
  }

  public setRoomHeightInput(value: number): void {
    if (value) {
      this._roomParams.heightMeters = value;
      this.roomHeightControl.setValue(this._roomParams.heightMeters, { emitEvent: false });
    }
  }

  public get3dFromMeter(meters: number): number {
    const pixelPerMeter = this._roomParams.height3D / this._roomParams.heightMeters;
    return meters ? meters * pixelPerMeter : null;
  }

  public getMeterFrom3D(pixels: number): number {
    const meterPerPixel = this._roomParams.heightMeters / this._roomParams.height3D;
    return pixels * meterPerPixel;
  }

  public calculateParams(): void {
    const wallCorners = this._config.scene.getObjectByName('wallCornersGroup').children;

    const floorPoints = wallCorners
      .filter(corner => corner.userData['planes'].find(plane => plane.type === PlaneType.FLOOR))
      .map(mesh => mesh.position);

    const ceilingPoints = wallCorners
      .filter(corner => corner.userData['planes'].find(plane => plane.type === PlaneType.CEILING))
      .map(mesh => mesh.position);

    this._calcRoomHeight3D(floorPoints, ceilingPoints);
    this._calcRoomWidth3D(floorPoints);
    this._calcRoomDepth3D(floorPoints);
    this._calcSpawnPosition(floorPoints);
  }

  private _calculateRoomBoundaries(floor: Vector3[], ceiling: Vector3[]): void {
    const ceilingY = ceiling.map(point => point.y);
    const yMax: number = ceilingY.reduce((a, b) => a + b, 0) / ceilingY.length;

    const floorY = floor.map(point => point.y);
    const yMin: number = floorY.reduce((a, b) => a + b, 0) / floorY.length;

    const floorX = floor.map(point => point.x);
    const xMax: number = Math.max(...floorX);
    const xMin: number = Math.min(...floorX);

    const floorPointsZ = floor.map(point => point.z);
    const zMax: number = Math.max(...floorPointsZ);
    const zMin: number = Math.min(...floorPointsZ);

    this._roomBoundaries = {
      yMax,
      yMin,
      xMax,
      xMin,
      zMax,
      zMin,
    };
  }

  private _calcRoomHeight3D(floorPoints: Vector3[], ceilingPoints: Vector3[]): void {
    const ceilingPointsY = ceilingPoints.map(point => point.y);
    const averageMaxY: number = ceilingPointsY.reduce((a, b) => a + b, 0) / ceilingPointsY.length;
    const floorPointsY = floorPoints.map(point => point.y);
    const averageMinY: number = floorPointsY.reduce((a, b) => a + b, 0) / floorPointsY.length;

    this._roomBoundaries.yMax = averageMaxY;
    this._roomBoundaries.yMin = averageMinY;

    this._roomParams.height3D = averageMaxY - averageMinY;
  }

  private _calcRoomWidth3D(floorPoints: Vector3[]): void {
    const floorPointsX = floorPoints.map(point => point.x);
    const averageMaxX: number = Math.max(...floorPointsX);
    const averageMinX: number = Math.min(...floorPointsX);

    this._roomBoundaries.xMax = averageMaxX;
    this._roomBoundaries.xMin = averageMinX;

    this._roomParams.width3D = averageMaxX - averageMinX;
  }

  private _calcRoomDepth3D(floorPoints: Vector3[]): void {
    const floorPointsZ = floorPoints.map(point => point.z);
    const averageMaxZ: number = Math.max(...floorPointsZ);
    const averageMinZ: number = Math.min(...floorPointsZ);

    this._roomBoundaries.zMax = averageMaxZ;
    this._roomBoundaries.zMin = averageMinZ;

    this._roomParams.depth3D = averageMaxZ - averageMinZ;
  }

  private _calcSpawnPosition(floorPoints: Vector3[]): void {
    if (floorPoints.length === 3) {
      const p01 = this._getPointBetween(floorPoints[0], floorPoints[1], floorPoints[0].distanceTo(floorPoints[1]) / 2);
      this._roomParams.objectStartPosition = this._getPointBetween(p01, floorPoints[2], p01.distanceTo(floorPoints[2]) / 2);
    } else if (floorPoints.length >= 4) {
      const p01 = this._getPointBetween(floorPoints[0], floorPoints[1], floorPoints[0].distanceTo(floorPoints[1]) / 2);
      const p23 = this._getPointBetween(floorPoints[2], floorPoints[3], floorPoints[2].distanceTo(floorPoints[3]) / 2);
      this._roomParams.objectStartPosition = this._getPointBetween(p01, p23, p01.distanceTo(p23) / 2);
    }
  }

  private _getPointBetween(startPoint: Vector3, endPoint: Vector3, distance: number): Vector3 {
    return startPoint.clone().add(endPoint.clone().sub(startPoint).normalize().multiplyScalar(distance));
  }
}
