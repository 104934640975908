import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, take, tap } from 'rxjs';
import { IdeaTypes } from 'src/app/shared/enums/idea-type.enum';
import { getIdeaImage, getIdeas, removeIdea, updateIdea } from 'src/app/store/actions/ideas.actions';
import { ideas, ideasPages, ideasPageSize, ideasTotalElements } from 'src/app/store/selectors/ideas.selectors';
import { ideasPageIndex } from 'src/app/store/selectors/shared.selector';
import { ResolutionService } from 'src/app/user-flow/services/resolution.service';

import { Idea } from '../../interfaces/idea';

@UntilDestroy()
@Component({
  selector: 'app-ideas-table',
  templateUrl: './ideas-table.component.html',
  styleUrls: ['./ideas-table.component.scss'],
})
export class IdeasTableComponent implements OnInit {
  public type: IdeaTypes;
  public email: string;
  public ideas$: Observable<Idea[]>;
  public totalElements$: Observable<number>;
  public size$: Observable<number>;
  public pages$: Observable<number>;
  public pageIndex$: Observable<number>;

  @ViewChild(MatPaginator)
  public paginator: MatPaginator;

  constructor(
    private _store: Store,
    private _router: Router,
    public resolutionService: ResolutionService,
    private _route: ActivatedRoute
  ) {
    this.type = _route.snapshot.data['type'] as IdeaTypes;
    this._route.queryParams.subscribe(params => {
      this.email = params['email'];
    });
  }

  public ngOnInit(): void {
    this.pageIndex$ = this._store.select(ideasPageIndex(!!this.email, this.type));
    this.pageIndex$
      .pipe(
        take(1),
        tap(index => {
          this._store.dispatch(getIdeas({ pageIndex: index, pageSize: 6, ideaType: this.type, email: this.email }));
        }),
        untilDestroyed(this)
      )
      .subscribe(() => {});
    this.ideas$ = this._store.select(ideas);
    this.totalElements$ = this._store.select(ideasTotalElements);
    this.size$ = this._store.select(ideasPageSize);
    this.pages$ = this._store.select(ideasPages);
  }

  public onPageEvent = ($event): void => {
    this._store.dispatch(getIdeas({ pageIndex: $event.pageIndex, pageSize: $event.pageSize, ideaType: this.type, email: this.email }));
    this.ideas$ = this._store.select(ideas);
    this.totalElements$ = this._store.select(ideasTotalElements);
  };

  public getIdeas(): void {
    this._store.dispatch(
      getIdeas({ pageIndex: this.paginator?.pageIndex, pageSize: this.paginator?.pageSize, ideaType: this.type, email: this.email })
    );
    this.ideas$ = this._store.select(ideas);
    this.totalElements$ = this._store.select(ideasTotalElements);
  }

  public openIdea(idea: Idea): void {
    if (this._needUpdate(idea.lastDetectionDate)) {
      this._store.dispatch(updateIdea({ id: idea.id, link: idea.link }));
    } else {
      this._store.dispatch(getIdeaImage({ link: idea.link, download: false }));
    }
  }

  private _needUpdate(detectionDate: string): boolean {
    const currDate = new Date();
    currDate.setMonth(currDate.getMonth() - 2);
    const date = new Date(detectionDate);
    return date < currDate ? true : false;
  }

  public removeIdea(id: string): void {
    combineLatest([this.size$, this.pageIndex$, this.totalElements$])
      .pipe(take(1), untilDestroyed(this))
      .subscribe(data => {
        this._store.dispatch(removeIdea({ id, size: data[0], index: data[1], total: data[2], ideaType: this.type, email: this.email }));
      });
  }

  public downloadIdea(link: string): void {
    this._store.dispatch(getIdeaImage({ link, download: true }));
  }

  public createIdeas(): void {
    const route = this.type === IdeaTypes.NO_FURNITURE ? 'no-furniture' : `${this.type.toLocaleLowerCase()}`;
    this._router.navigate(['create', route]);
  }
}
