import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, of, switchMap } from 'rxjs';

import { ProjectsVersionsHttpService } from '../../../services/projects-versions-http.service';
import { Mode } from '../../shared/enums/mode.enum';
import { setMode, updateActiveProject } from '../../store/actions/shared.actions';

export const reloadResolver: ResolveFn<boolean> = (route: ActivatedRouteSnapshot) => {
  const versionId = route.queryParamMap.get('versionId');
  const projectId = route.queryParamMap.get('projectId');

  if (versionId && projectId) {
    const store = inject(Store);
    const projectVersionService = inject(ProjectsVersionsHttpService);

    return combineLatest([projectVersionService.getProject(projectId), projectVersionService.getVersion(versionId)]).pipe(
      switchMap(([project, version]) => {
        const projectInfo = {
          ...project,
          ...version,
          id: projectId,
          versionId: versionId,
        };

        store.dispatch(setMode({ mode: Mode.EDIT }));
        store.dispatch(updateActiveProject({ projectInfo }));

        return of(true);
      })
    );
  }

  return of(false);
};
