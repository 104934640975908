<div class="page-container">
  <div class="background">
    <div class="login-container">
      <div class="header">
        <div class="title m-45">{{ "AUTH.LOGIN_PAGE.LOGIN" | translate}}</div>
        <div class="subtitle r-16">{{ "AUTH.LOGIN_PAGE.SUBTITLE" | translate}}</div>
      </div>

      <section class="page-content">
        <form [formGroup]="form" class="login-form">
          <div class="mobile-text m-25">{{ "AUTH.LOGIN_PAGE.LOGIN_FIELD" | translate }}</div>
          <p>
            <app-form-field
            class="login-form-field"
              name="email"
              label="AUTH.REGISTRATION_PAGE.EMAIL"
              type="text"
              [abstractControl]="form.get('email')"
            ></app-form-field>
          </p>
          <div class="mobile-text mobile-text__second m-25">{{ "AUTH.LOGIN_PAGE.PASSWORD_FIELD" | translate }}</div>
          <p class="password-filed">
            <app-form-field
              name="password"
              label="AUTH.REGISTRATION_PAGE.PASSWORD"
              type="password"
              [abstractControl]="form.get('password')"
            >
            </app-form-field>
            <a class="inline-link link" routerLink="/forgot-password">{{ "AUTH.LOGIN_PAGE.FORGOT" | translate}}</a>
          </p>
          <div class="actions">
            <button class="button" color="primary" type="submit" mat-raised-button (click)="submit()">{{ "AUTH.LOGIN_PAGE.LOGIN" | translate}}</button>
            <div class="create">
              <span>{{ "AUTH.LOGIN_PAGE.NEW_CUSTOMER" | translate}}</span>
              <a class="link" routerLink="/register">{{ "AUTH.LOGIN_PAGE.CREATE_ACCOUNT" | translate}}</a>
            </div>
          </div>
        </form>
      </section>
    </div>
  </div>
</div>

