export interface ClearedImage {
  image: string;
  updated: boolean;
}
export interface ImageState {
  clearedImage: ClearedImage;
}

export const initialImageState: ImageState = {
  clearedImage: {
    image: null,
    updated: false,
  },
};
