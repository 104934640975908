import { createAction, props } from '@ngrx/store';
import { Idea } from 'src/app/ideas/interfaces/idea';
import { IdeaTypes } from 'src/app/shared/enums/idea-type.enum';
import { IList } from 'src/app/shared/interfaces/list';
import { CatalogData } from 'src/services/model/proposal';

export const GENERATION_START = '[Ideas] generation start';
export const GENERATION_IMAGES_START = '[Ideas] generation start success';
export const GENERATION_FINISHED = '[Ideas] generation finished';
export const GENERATION_FINISHED_SUCCESS = '[Ideas] generation finished success';
export const GENERATION_STYLE_SWITCH_START = '[Ideas] generation style switch start';
export const GET_IDEAS = '[Ideas] get ideas';
export const GET_IDEAS_SUCCESS = '[Ideas] get ideas success';
export const SET_IDEA_IMAGES_SUCCESS = '[Ideas] set idea images success';
export const GET_IDEA_IMAGE = '[Ideas] get idea image';
export const GET_IDEA_IMAGE_SUCCESS = '[Ideas] get idea image success';
export const CLEAR_ACTIVE_IDEA = '[Ideas] clear active idea';
export const CLEAR_IDEAS_TABLE = '[Ideas] clear ideas table';
export const FIND_FURNITURE_IN_CATALOG = '[Ideas] find furniture in catalog';
export const FIND_FURNITURE_IN_CATALOG_SUCCESS = '[Ideas] find furniture in catalog success';
export const CLEAR_ACTIVE_IDEA_FURNITURE = '[Ideas] clear active idea furniture';
export const LOAD_IDEA_SIMILAR_FURNITURE = '[Ideas Image] load idea similar furniture';
export const LOAD_IDEA_SIMILAR_FURNITURE_SUCCESS = '[Ideas Image] load idea similar furniture success';
export const VIEW_IDEA_FURNITURE = '[Ideas Image] view idea furniture';
export const VIEW_IDEA_FURNITURE_SUCCESS = '[Ideas Image] view idea furniture success';
export const UPDATE_IDEA = '[Ideas Page] update idea';
export const UPDATE_IDEA_SUCCESS = '[Ideas Page] update idea success';
export const REMOVE_IDEA = '[Ideas Table] delete idea';
export const REMOVE_IDEA_SUCCESS = '[Ideas Table] delete idea success';
export const TRANSLATE_IDEA_FURNITURE_DESCRIPTION = '[Ideas] translate idea furniture description';
export const TRANSLATE_IDEA_FURNITURE_DESCRIPTION_SUCCESS = '[Ideas] translate idea furniture description success';

export const generationStart = createAction(
  GENERATION_START,
  props<{ tabType: IdeaTypes; image: File; roomType: string; style: string; specialWishes: string }>()
);

export const generationStartSuccess = createAction(GENERATION_IMAGES_START, props<{ id: string; generationType: IdeaTypes }>());

export const generationFinished = createAction(GENERATION_FINISHED, props<{ generationType: IdeaTypes }>());

export const generationFinishedSuccess = createAction(GENERATION_FINISHED_SUCCESS);

export const generationStyleSwitchStart = createAction(GENERATION_STYLE_SWITCH_START, props<{ roomImage: File; styleImage: File }>());

export const getIdeas = createAction(GET_IDEAS, props<{ pageIndex: number; pageSize: number; ideaType: IdeaTypes; email?: string }>());

export const getIdeasSuccess = createAction(GET_IDEAS_SUCCESS, props<{ ideas: IList<Idea> }>());

export const setIdeaImageSuccess = createAction(SET_IDEA_IMAGES_SUCCESS, props<{ data: { id: string; src: string } }>());

export const getIdeaImage = createAction(GET_IDEA_IMAGE, props<{ link: string; download: boolean }>());

export const getIdeaImageSuccess = createAction(GET_IDEA_IMAGE_SUCCESS, props<{ data: { link: string; src: string; blob: Blob } }>());

export const clearActiveIdea = createAction(CLEAR_ACTIVE_IDEA);

export const clearIdeasTable = createAction(CLEAR_IDEAS_TABLE);

export const clearActiveIdeaFurniture = createAction(CLEAR_ACTIVE_IDEA_FURNITURE);

export const loadIdeaSimilarFurniture = createAction(LOAD_IDEA_SIMILAR_FURNITURE, props<{ ids: string[] }>());

export const loadIdeaSimilarFurnitureSuccess = createAction(LOAD_IDEA_SIMILAR_FURNITURE_SUCCESS, props<{ data: CatalogData[] }>());

export const viewIdeaFurniture = createAction(VIEW_IDEA_FURNITURE, props<{ data: CatalogData }>());

export const viewIdeaFurnitureSuccess = createAction(VIEW_IDEA_FURNITURE_SUCCESS, props<{ data: CatalogData }>());

export const translateIdeaFurnitureDescription = createAction(TRANSLATE_IDEA_FURNITURE_DESCRIPTION, props<{ description: string }>());

export const translateIdeaFurnitureDescriptionSuccess = createAction(
  TRANSLATE_IDEA_FURNITURE_DESCRIPTION_SUCCESS,
  props<{ description: string }>()
);

export const updateIdea = createAction(UPDATE_IDEA, props<{ id: string; link: string }>());

export const updateIdeaSuccess = createAction(UPDATE_IDEA_SUCCESS, props<{ idea: Idea }>());

export const removeIdea = createAction(
  REMOVE_IDEA,
  props<{ id: string; size: number; index: number; total: number; ideaType: IdeaTypes; email: string }>()
);

export const removeIdeaSuccess = createAction(REMOVE_IDEA_SUCCESS, props<{ id: string }>());
