import { HttpErrorResponse } from '@angular/common/http';
import { IProjectAdditionalInfo, SingleRenderResult } from 'src/models/project';
import { ProposalState } from 'src/models/proposal';
import { InteriorResult } from 'src/services/model/detect-floor-result.model';
import { CatalogData } from 'src/services/model/proposal';

import { User } from './interfaces/user';
import { Idea } from '../ideas/interfaces/idea';
import { Mode } from '../shared/enums/mode.enum';
import { LeftPanelState } from '../user-flow/catalog/left-panel-container/left-panel-state.enum';
import { StylefinderResponse } from '../user-flow/interfaces/stylefinder';
import { IStyle } from '../user-flow/interfaces/styles';

export interface SharedState {
  showOverlayLoading: boolean;
  showRenderLoading: { status: boolean; text: string };
  activeProject: IProjectAdditionalInfo;
  rendersResult: SingleRenderResult[];
  mode: Mode;
  changes: boolean;
  savedObjectDetectionResult: InteriorResult;
  proposalData: any;
  proposalResult: any;
  reconstructResult: InteriorResult;
  catalogData: CatalogData[];
  catalogOpen: boolean;
  leftPanelOpen: boolean;
  furnitureDetails: { article: CatalogData; descriptionTranslate: string; from: LeftPanelState };
  user: User;
  selectedMovingObject: string;
  error: HttpErrorResponse;
  uploadedMoodImages: File[];
  lastViewedIdeaImages: Idea[];
  ideasLoaded: boolean;
  styles: IStyle[];
  proposedFurnitureColors: {
    data: StylefinderResponse;
    loaded: boolean;
  };
  proposal: ProposalState;
  alternatives: CatalogData[];
  userIdeas: {
    defaultPageIndex: number;
    styleswapPageIndex: number;
    noFurniturePageIndex: number;
  };
  adminIdeas: {
    defaultPageIndex: number;
    styleswapPageIndex: number;
    noFurniturePageIndex: number;
  };
  activeSku: string;
  lang: string;
}

export const initialState: SharedState = {
  showOverlayLoading: false,
  showRenderLoading: { status: false, text: '' },
  activeProject: null,
  rendersResult: null,
  mode: Mode.UNSET,
  changes: false,
  savedObjectDetectionResult: null,
  proposalData: null,
  proposalResult: null,
  reconstructResult: null,
  catalogData: null,
  catalogOpen: false,
  leftPanelOpen: false,
  furnitureDetails: null,
  user: null,
  selectedMovingObject: null,
  error: null,
  uploadedMoodImages: [],
  lastViewedIdeaImages: null,
  ideasLoaded: false,
  styles: null,
  proposedFurnitureColors: {
    data: null,
    loaded: false,
  },
  proposal: null,
  alternatives: null,
  userIdeas: {
    defaultPageIndex: null,
    styleswapPageIndex: null,
    noFurniturePageIndex: null,
  },
  adminIdeas: {
    defaultPageIndex: null,
    styleswapPageIndex: null,
    noFurniturePageIndex: null,
  },
  activeSku: null,
  lang: null,
};
