import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { EffectsModule } from '@ngrx/effects';
import { RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { DetectObjHttpService } from 'src/services/detect-obj-http.service';

import { AdminModule } from './admin/admin.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { AuthInterceptor } from './auth/interceptors/auth.interceptor';
import { AccessGuard } from './guards/access.guard';
import { TranslationLoaderGuard } from './guards/translation-loader.guard';
import { I18nModule } from './i18n/i18n.module';
import { NotificationsModule } from './notifications/notifications.module';
import { ProjectsIdeasModule } from './projects-ideas/projects-ideas.module';
import { Roles } from './shared/enums/roles.enum';
import { InterceptorsModule } from './shared/interceptors/interceptors.module';
import { SharedComponentsModule } from './shared/shared-components.module';
import { reducers } from './store';
import { undoredoMeta } from './undo-redo/undo-redo.metareducer';
import { UndoRedoModule } from './undo-redo/undo-redo.module';
import { UserFlowModule } from './user-flow/user-flow.module';

const routes: Routes = [
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canActivate: [AccessGuard],
    data: {
      role: Roles.ADMIN,
    },
  },
  { path: '**', redirectTo: 'result', canActivateChild: [TranslationLoaderGuard] },
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    AdminModule,
    AuthModule,
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    FontAwesomeModule,
    I18nModule,
    InterceptorsModule,
    ProjectsIdeasModule,
    RouterModule.forRoot(routes),
    SharedComponentsModule,
    UndoRedoModule,
    UserFlowModule,
    StoreModule.forRoot(reducers, {
      metaReducers: [undoredoMeta],
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    StoreDevtoolsModule.instrument({ maxAge: 25 }),
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot({
      stateKey: 'router',
      routerState: RouterState.Minimal,
    }),
    NotificationsModule,
  ],
  providers: [
    AccessGuard,
    DetectObjHttpService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
