import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { filter, take } from 'rxjs';
import { UserService } from 'src/app/shared/services/user.service';
import { setOverlayLoadingSpinner, userFirstLogin } from 'src/app/store/actions/shared.actions';
import { userHasLoggedIn } from 'src/app/store/selectors/shared.selector';
import { ResolutionService } from 'src/app/user-flow/services/resolution.service';

@UntilDestroy()
@Component({
  selector: 'app-choose-plan',
  templateUrl: './choose-plan.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChoosePlanComponent implements OnInit {
  constructor(
    public resolutionService: ResolutionService,
    @Inject(DOCUMENT) private _document: Document,
    private _userService: UserService,
    private _store: Store
  ) {}

  public ngOnInit(): void {
    this._store
      .select(userHasLoggedIn)
      .pipe(
        filter(data => !data),
        take(1),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this._store.dispatch(userFirstLogin());
      });
  }

  public choosePlan(): void {
    this._store.dispatch(setOverlayLoadingSpinner({ status: true }));
    this._userService
      .getSubscriptionsLink()
      .pipe(untilDestroyed(this))
      .subscribe(link => {
        this._store.dispatch(setOverlayLoadingSpinner({ status: false }));
        this._document.location.href = link;
      });
  }
}
