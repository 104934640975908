export enum ColorGroup {
  CUSTOM = 'Custom',
  NO_COLOR = 'No_Color',
  RED = 'Red',
  ORANGE = 'Orange',
  YELLOW = 'Yellow',
  GREEN = 'Green',
  BLUE = 'Blue',
  VIOLET = 'Violet',
  BROWN = 'Brown',
  GRAY = 'Gray',
  WHITE = 'White',
}
