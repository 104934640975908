import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { getUserProjects, removeProject } from 'src/app/store/actions/admin.actions';
import { updateActiveProject } from 'src/app/store/actions/shared.actions';
import {
  userProjects,
  userProjectsPageIndex,
  userProjectsPageSize,
  userProjectsTotalElements,
} from 'src/app/store/selectors/admin.selectors';
import { IProject } from 'src/models/project';

@UntilDestroy()
@Component({
  selector: 'app-user-projects',
  templateUrl: './user-projects.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserProjectsComponent implements OnInit {
  private _userEmail: string;

  public size = 10;
  public projects$: Observable<IProject[]>;
  public totalElements$: Observable<number>;
  public size$: Observable<number>;
  public pageIndex$: Observable<number>;

  constructor(
    private _route: ActivatedRoute,
    private _store: Store,
    private _router: Router
  ) {}

  public ngOnInit(): void {
    this._route.queryParams.pipe(untilDestroyed(this)).subscribe(params => {
      this._userEmail = params['email'];
      this.getProjects({ page: 0, size: 0, name: null });
    });
    this.totalElements$ = this._store.select(userProjectsTotalElements);
    this.size$ = this._store.select(userProjectsPageSize);
    this.pageIndex$ = this._store.select(userProjectsPageIndex);
  }

  public getProjects(data: { page: number; size: number; name: string }): void {
    if (data.name) {
      this._store.dispatch(getUserProjects({ email: this._userEmail, pageIndex: data.page, pageSize: data.size, name: data.name }));
    } else {
      this._store.dispatch(getUserProjects({ email: this._userEmail, pageIndex: data.page, pageSize: data.size, name: null }));
    }
    this.projects$ = this._store.select(userProjects);
    this.totalElements$ = this._store.select(userProjectsTotalElements);
  }

  public handleOpen(project: IProject): void {
    this._store.dispatch(updateActiveProject({ projectInfo: project }));
    this._router.navigate(['project/', project.id], { queryParams: { email: this._userEmail } });
  }

  public handleRemove(id: string): void {
    this._store.dispatch(removeProject({ id }));
    this.projects$ = this._store.select(userProjects);
  }
}
