import { ColorGroup } from './color-group.enum';
import { NcsColor } from './ncs-color.model';
import blueGroupItems from '../../../../assets/ncs-colors/blue.json';
import brownGroupItems from '../../../../assets/ncs-colors/brown.json';
import grayGroupItems from '../../../../assets/ncs-colors/gray.json';
import greenGroupItems from '../../../../assets/ncs-colors/green.json';
import orangeGroupItems from '../../../../assets/ncs-colors/orange.json';
import redGroupItems from '../../../../assets/ncs-colors/red.json';
import violetGroupItems from '../../../../assets/ncs-colors/violet.json';
import whiteGroupItems from '../../../../assets/ncs-colors/white.json';
import yellowGroupItems from '../../../../assets/ncs-colors/yellow.json';

export const colorGroups: { [p: string]: NcsColor[] } = {
  [ColorGroup.RED]: redGroupItems as NcsColor[],
  [ColorGroup.ORANGE]: orangeGroupItems as NcsColor[],
  [ColorGroup.YELLOW]: yellowGroupItems as NcsColor[],
  [ColorGroup.GREEN]: greenGroupItems as NcsColor[],
  [ColorGroup.BLUE]: blueGroupItems as NcsColor[],
  [ColorGroup.VIOLET]: violetGroupItems as NcsColor[],
  [ColorGroup.BROWN]: brownGroupItems as NcsColor[],
  [ColorGroup.GRAY]: grayGroupItems as NcsColor[],
  [ColorGroup.WHITE]: whiteGroupItems as NcsColor[],
};
