import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';

export class ComponentCanDeactivate {
  public hasChanges: () => Observable<boolean>;
  public showDeactivateDialog: () => Observable<boolean>;
}

@Injectable()
export class HasUnsavedChangesGuard {
  constructor(private _router: Router) {}
  public canDeactivate(component: ComponentCanDeactivate): Observable<boolean> {
    return component.hasChanges().pipe(
      switchMap(hasChanges => {
        if (this._router.getCurrentNavigation()?.extras?.state?.['bypassFormGuard']) {
          return of(true);
        }
        return hasChanges ? component.showDeactivateDialog() : of(true);
      })
    );
  }
}
