import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { AUTH_CONTEXT } from './context-tokens/intercept.context-token';

@Injectable({
  providedIn: 'root',
})
export class ResourcesHttpService {
  constructor(private _http: HttpClient) {}

  public uploadResource(file: File): Observable<{ id: string }> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this._http.post<{ id: string }>(environment.API_URL + 'resources', formData, { context: AUTH_CONTEXT });
  }

  public uploadPublicResource(file: File): Observable<{ id: string }> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this._http.post<{ id: string }>(environment.API_URL + 'resources/public', formData, { context: AUTH_CONTEXT });
  }

  public downloadPublicResource(id: string): Observable<Blob> {
    return this._http.get(environment.API_URL + 'resources/public/' + id, { responseType: 'blob', context: AUTH_CONTEXT });
  }

  public loadResource(id: string): Observable<Blob> {
    return this._http.get(environment.API_URL + `resources/${id}`, { responseType: 'blob', context: AUTH_CONTEXT });
  }

  public loadResizedResource(id: string, width: number, height: number): Observable<Blob> {
    const params = new HttpParams().append('width', width).append('height', height);
    return this._http.get(environment.API_URL + `resources/${id}/resized`, { params, responseType: 'blob', context: AUTH_CONTEXT });
  }

  public loadCroppedResource(id: string, width: number, height: number): Observable<Blob> {
    const params = new HttpParams().append('width', width).append('height', height);
    return this._http.get(environment.API_URL + `resources/${id}/cropped`, { params, responseType: 'blob', context: AUTH_CONTEXT });
  }
}
