import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { filter, switchMap, take } from 'rxjs';
import { Vector3 } from 'three';

import { ManualReconstructInstructionComponent } from './manual-reconstruct-instruction/manual-reconstruct-instruction.component';
import { Mode } from '../../shared/enums/mode.enum';
import { activeProject } from '../../store/selectors/shared.selector';
import { Option } from '../catalog/catalog-details/option.model';
import { MAX_HEIGHT_MOBILE_MANUAL_RECONSTRUCT } from '../const/windows-dimentions';
import { ClickType } from '../enum/click.enum';
import { RoomType } from '../enum/room-type.enum';
import { Action } from '../render/render-input-data.model';
import { RenderComponent } from '../render/render.component';
import { ImageFileParamsService } from '../services/image-file-params.service';
import { ManualReconstructionService } from '../services/manual-reconstruction.service';
import { PlanesService } from '../services/planes.service';
import { ReconstructionStateService } from '../services/reconstruction-state.service';
import { RefreshNavigationService } from '../services/refresh-navigation.service';
import { ResolutionService } from '../services/resolution.service';
import { RoomService } from '../services/room.service';

@UntilDestroy()
@Component({
  selector: 'app-manual-reconstruct-editor',
  templateUrl: './manual-reconstruct-editor.component.html',
  styleUrls: ['./manual-reconstruct-editor.component.scss'],
})
export class ManualReconstructEditorComponent implements AfterViewInit, OnInit {
  public readonly CLICK_TYPES = ClickType;
  public changeHeightButtonActive = false;

  public mobileOptions: Option[] = [
    {
      name: 'USER_FLOW.MANUAL_RECONSTRUCTION.HORIZONTAL',
      iconSrc: 'assets/manual-reconstruction/manual-movement-horizontal.svg',
      actionPositive: () => this._handleWallCornerMoving(0, 1, 0),
      actionNegative: () => this._handleWallCornerMoving(0, -1, 0),
    },
    {
      name: 'USER_FLOW.MANUAL_RECONSTRUCTION.VERTICAL',
      iconSrc: 'assets/manual-reconstruction/manual-movement-vertical.svg',
      actionPositive: () => this._handleWallCornerMoving(1, 0, 0),
      actionNegative: () => this._handleWallCornerMoving(-1, 0, 0),
    },
    {
      name: 'USER_FLOW.MANUAL_RECONSTRUCTION.DEPTH',
      iconSrc: 'assets/manual-reconstruction/manual-movement-depth.svg',
      actionPositive: () => this._handleWallCornerMoving(0, 0, 1),
      actionNegative: () => this._handleWallCornerMoving(0, 0, -1),
    },
  ];

  public get hasEnoughCornersForReconstruction(): boolean {
    return this._manualReconstructionService.cornersLength > 2;
  }

  public get hasEnoughCornersForMoveCorners(): boolean {
    return this._manualReconstructionService.cornersLength > 0;
  }

  public get hasReconstruction(): boolean {
    return this._planesService.hasPlanes;
  }

  public clickType: ClickType = ClickType.RECONSTRUCT_MANUAL;

  @ViewChild('render', { read: RenderComponent })
  private _renderComponent: RenderComponent;
  @ViewChild('renderContainer', { read: ElementRef })
  private _pageContent = this._document.getElementsByClassName('render-container')[0];

  constructor(
    public resolutionService: ResolutionService,
    private _router: Router,
    private _store: Store,
    private _imageFileParamsService: ImageFileParamsService,
    private _manualReconstructionService: ManualReconstructionService,
    private _planesService: PlanesService,
    private _reconstructionStateService: ReconstructionStateService,
    private _roomService: RoomService,
    private _refreshNavigationService: RefreshNavigationService,
    @Inject(DOCUMENT) private _document: Document,
    private _dialog: MatDialog
  ) {}

  public ngOnInit(): void {
    this._refreshNavigationService.refreshPageEvent$.pipe(untilDestroyed(this)).subscribe(() => this._router.navigate(['choose-file']));
  }

  public ngAfterViewInit(): void {
    this._pageContent = this._document.getElementsByClassName('render-container')[0];
    if (this._pageContent && this._pageContent.clientWidth && this._pageContent.clientHeight) {
      this._store
        .select(activeProject)
        .pipe(
          filter(data => !!data),
          take(1),
          switchMap(project =>
            this._imageFileParamsService
              .getImageParams(
                project.file,
                project.file.name,
                this.resolutionService.isMobileResolution ? this._pageContent.clientWidth - 40 : this._pageContent.clientWidth - 40,
                this.resolutionService.isMobileResolution ? MAX_HEIGHT_MOBILE_MANUAL_RECONSTRUCT : this._pageContent.clientHeight - 80
              )
              .pipe(
                switchMap(imageParams =>
                  this._renderComponent.run(Mode.CREATE, {
                    src: project.src,
                    width: imageParams.width,
                    height: imageParams.height,
                    type: project.type as RoomType,
                    actions: [Action.CLEAR_SCENE],
                  })
                )
              )
          ),
          untilDestroyed(this)
        )
        .subscribe(() => this.startManualReconstruct());
    }
  }

  public next(): void {
    this._reconstructionStateService.save().subscribe(() => {
      this._router.navigate(['reconstruct-editor']);
    });
  }

  public back(): void {
    this._router.navigate(['reconstruct-preview']);
  }

  public completeManualReconstruct(): void {
    this._renderComponent
      .makeActions([Action.FINISH_MANUAL_RECONSTRUCT])
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.clickType = ClickType.MOVE_WINDOW_DOOR;
        this._roomService.setSelectedCorner(null);
      });
  }

  public startManualReconstruct(): void {
    this._manualReconstructionService.setFromAutoReconstruct();
    this.clickType = ClickType.MOVE_WALL_POINT;
  }

  public restartManualReconstruct(): void {
    this._renderComponent.makeActions([Action.CLEAR_MANUAL_RECONSTRUCTION, Action.CLEAR_SCENE]).pipe(untilDestroyed(this)).subscribe();
    this.clickType = ClickType.RECONSTRUCT_MANUAL;
  }

  public changeRoomHeight(): void {
    this.changeHeightButtonActive = true;
    this._renderComponent
      .makeActions([Action.CHANGE_ROOM_HEIGHT])
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.changeHeightButtonActive = false;
      });
  }

  public moveCornersEnable(): void {
    this.clickType = this.clickType === ClickType.MOVE_WALL_POINT ? ClickType.MOVE_WINDOW_DOOR : ClickType.MOVE_WALL_POINT;
  }

  public backward(): void {
    this._renderComponent.makeActions([Action.BACKWARD]).pipe(untilDestroyed(this)).subscribe();
  }

  public forward(): void {
    this._renderComponent.makeActions([Action.FORWARD]).pipe(untilDestroyed(this)).subscribe();
  }

  public save(): void {
    this._renderComponent.makeActions([Action.UPDATE_VERSION]).pipe(untilDestroyed(this)).subscribe();
  }

  public placeDoor(): void {
    this.clickType = this.clickType === ClickType.PLACE_DOOR ? ClickType.MOVE_WINDOW_DOOR : ClickType.PLACE_DOOR;
  }

  public placeWindow(): void {
    this.clickType = this.clickType === ClickType.PLACE_WINDOW ? ClickType.MOVE_WINDOW_DOOR : ClickType.PLACE_WINDOW;
  }

  public deleteHole(): void {
    this._roomService.deleteHole();
  }

  public onWindowPlace(): void {
    this.clickType = ClickType.MOVE_WINDOW_DOOR;
  }

  public onDoorPlace(): void {
    this.clickType = ClickType.MOVE_WINDOW_DOOR;
  }

  public openInstructionDialog(): void {
    this._dialog
      .open(ManualReconstructInstructionComponent, {
        position: { bottom: '0' },
        maxWidth: '100vw',
        maxHeight: '93vh',
        panelClass: 'mobile-dialog',
      })
      .afterClosed()
      .subscribe(() => {
        this._document.defaultView.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      });
  }

  private _handleWallCornerMoving(vertical: number, horizontal: number, depth: number): void {
    const meters = 0.02;
    const deltaMovement = new Vector3(horizontal, vertical, depth);
    this._roomService.moveWallPoint(meters, deltaMovement);
  }

  public toggleChecked(): boolean {
    return this.clickType === ClickType.MOVE_WALL_POINT;
  }

  public toggleMoveCorners(event: MatSlideToggleChange): void {
    this.clickType = event.checked ? ClickType.MOVE_WALL_POINT : ClickType.MOVE_WINDOW_DOOR;

    if (this.clickType === ClickType.MOVE_WINDOW_DOOR) {
      this._roomService.setSelectedCorner(null);
    }
  }
}
