import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import heic2any from 'heic2any';
import { Notification } from 'src/app/notifications/model/notification';
import { NotificationsService } from 'src/app/notifications/services/notifications.service';
import { FileSizeService } from 'src/app/shared/services/file-size.service';
import { addMoodImage, setOverlayLoadingSpinner } from 'src/app/store/actions/shared.actions';

import { ResolutionService } from '../services/resolution.service';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadFileComponent {
  public dragAreaClass = 'dragarea';
  public showWarning = false;
  public showError = false;
  @Output() public fileUploaded: EventEmitter<File> = new EventEmitter<File>();
  @HostListener('dragover', ['$event']) public onDragOver(event: DragEvent): void {
    this.dragAreaClass = 'droparea';
    event.preventDefault();
  }
  @HostListener('dragenter', ['$event']) public onDragEnter(event: DragEvent): void {
    this.dragAreaClass = 'droparea';
    event.preventDefault();
  }
  @HostListener('dragend', ['$event']) public onDragEnd(event: DragEvent): void {
    this.dragAreaClass = 'dragarea';
    event.preventDefault();
  }
  @HostListener('dragleave', ['$event']) public onDragLeave(event: DragEvent): void {
    this.dragAreaClass = 'dragarea';
    event.preventDefault();
  }
  @HostListener('drop', ['$event']) public onDrop(event: DragEvent): void {
    this.dragAreaClass = 'dragarea';
    event.preventDefault();
    event.stopPropagation();
    this.showWarning = false;
    if (event.dataTransfer.files && event.dataTransfer.files[0] && this.fileSizeService.fileSizeLess10MB(event.dataTransfer.files[0])) {
      if (this._acceptableFormatCheck(event.dataTransfer.files[0])) {
        this._setFile(event.dataTransfer.files[0]);
      } else {
        this.showWarning = true;
      }
    }
  }

  constructor(
    public fileSizeService: FileSizeService,
    private _store: Store,
    private _notificationsService: NotificationsService,
    private _translateService: TranslateService,
    public resolutionService: ResolutionService
  ) {}

  public onFileChange(event: Event): void {
    const selectedfile = (event.target as HTMLInputElement).files || ({} as FileList);
    if (selectedfile.length > 0 && this.fileSizeService.fileSizeLess10MB(selectedfile[0])) {
      this._setFile(selectedfile[0]);
    }
  }

  private _acceptableFormatCheck(file: File): boolean {
    if (
      file.name.toLowerCase().includes('.heic') ||
      file.name.toLowerCase().includes('.heif') ||
      file.name.toLowerCase().includes('.jpeg') ||
      file.name.toLowerCase().includes('.png') ||
      file.name.toLowerCase().includes('.jpg')
    ) {
      return true;
    }
    return false;
  }

  private async _setFile(file: File): Promise<void> {
    if (file.name.toLowerCase().includes('.heif')) {
      this._store.dispatch(setOverlayLoadingSpinner({ status: true }));
    }
    if (file.name.toLowerCase().includes('.heic') || file.name.toLowerCase().includes('.heif')) {
      const blob = await fetch(URL.createObjectURL(file)).then(res => res.blob());
      heic2any({ blob, toType: 'image/jpeg' })
        .then(result => {
          file = new File([result as Blob], 'background.jpeg', { type: 'image/jpeg' });
          this._store.dispatch(addMoodImage({ file }));
          this.fileUploaded.emit(file);
        })
        .catch(() => {
          this._notificationsService.addNotification(
            new Notification({
              title: this._translateService.instant('NOTIFICATIONS.TITLE.ERROR'),
              text: this._translateService.instant('NOTIFICATIONS.MESSAGES.WRONG_IMAGE_TYPE'),
              level: 'error',
              options: { timeout: 2 },
            })
          );
        })
        .finally(() => this._store.dispatch(setOverlayLoadingSpinner({ status: false })));
    } else {
      this._store.dispatch(addMoodImage({ file }));
      this.fileUploaded.emit(file);
    }
  }
}
