import { DetectedFurniture } from 'src/app/ideas/interfaces/idea';
import { IStyle } from 'src/app/user-flow/interfaces/styles';
import { CatalogDataAccessory } from 'src/services/model/proposal';

export interface IProject {
  id: string;
  name: string;
  preview: string;
  creationDate: Date;
  lastRenders: Render[];
  src?: string;
  versions?: IVersion[];
  versionTotalElements?: number;
}

export interface IVersion {
  name?: string;
  budget?: number;
  creationDate?: Date;
  id?: string;
  image?: string;
  model?: string;
  style?: string;
  type?: string;
  src?: string;
  width?: number;
  height?: number;
  file?: File;
  renders?: Render[];
  renderJobId?: string;
  needUpdateVersion?: boolean;
}
export interface IProjectAdditionalInfo extends IVersion {
  versionName?: string;
  versionId?: string;
  handledBackgroundFile?: File;
  selectedStyles?: IStyle[];
  accessories?: CatalogDataAccessory[];
}

export interface Render {
  alternativeIdea: string;
  alternativeIdeaDetectedFurniture: DetectedFurniture[];
  creationDate: Date;
  image: string;
  imageDetectedFurniture: DetectedFurniture[];
  improvedImage: string;
}

export interface SingleRenderResult {
  name: string;
  type: SingleRenderResultType;
  targetRenderWidth?: number;
  targetRenderHeight?: number;
  renderDate: Date;
  blob: Blob;
  src?: string;
  detectedFurniture: DetectedFurniture[];
}

export enum SingleRenderResultType {
  IMPROVED = 'IMPROVED',
  ALTERNATIVE = 'ALTERNATIVE',
  REGULAR = 'REGULAR',
  // for ideas on product page
  OTHER = 'OTHER',
}
