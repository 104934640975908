import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Idea } from 'src/app/ideas/interfaces/idea';
import { environment } from 'src/environments/environment';
import { AUTH_CONTEXT } from 'src/services/context-tokens/intercept.context-token';

import { StylefinderItem, StylefinderResponse } from '../interfaces/stylefinder';

@Injectable()
export class StyleFinderService {
  constructor(private _http: HttpClient) {}

  public getIdeas(): Observable<Idea[]> {
    return this._http.get<Idea[]>(environment.API_URL + 'ideas/stylefinder', { context: AUTH_CONTEXT });
  }

  public getProposedFurniture(data: StylefinderItem[]): Observable<StylefinderResponse> {
    return this._http.post<StylefinderResponse>(environment.API_URL + 'stylefinder', data, { context: AUTH_CONTEXT });
  }
}
