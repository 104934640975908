import { ActionReducer } from '@ngrx/store';
import { cloneDeep, get, pick, set } from 'lodash';

import { UndoRedoActions } from './undo-redo.action-types';
import { add } from './undo-redo.actions';
import { UndoableState } from './undo-redo.state';
import { UNDOABLE_OPERATIONS } from './undoable-operations';
import { IAppState } from '../app.interface';

const PERSISTENT_KEYS = ['render'];

export function undoredoMeta(reducer: ActionReducer<any>): ActionReducer<any> {
  let states: UndoableState = {
    past: [],
    present: reducer(undefined, { type: '__INIT__' }),
    future: [],
  };

  return (state, action) => {
    const { past, present, future } = states;

    function onUndo(): ActionReducer<IAppState> {
      if (past.length === 0) {
        return state;
      }
      const previous = past[past.length - 1];
      const newPast = past.slice(0, past.length - 1);
      states = {
        past: newPast,
        present: { ...previous, undoredo: state.undoredo },
        future: [present, ...future],
      };
      return reducer(mergeStates(state, states.present), action);
    }

    function onRedo(): ActionReducer<IAppState> {
      if (future.length === 0) {
        return state;
      }
      const next = future[0];
      const newFuture = future.slice(1);
      states = {
        past: [...past, present],
        present: { ...next, undoredo: state.undoredo },
        future: newFuture,
      };
      return reducer(mergeStates(state, states.present), action);
    }

    switch (action.type) {
      case UndoRedoActions.UNDO:
        return onUndo();
      case UndoRedoActions.REDO:
        return onRedo();
      default:
        // Delegate handling the action to the passed reducer
        const persistentAction = UNDOABLE_OPERATIONS.find(item => item.type === action.type);
        if (persistentAction) {
          const newPresent = reducer(state, action);
          if (present === newPresent) {
            return state;
          }
          states = {
            past: [...past, present],
            present: extractState(newPresent),
            future: [],
          };

          if (action['data']) {
            return reducer(newPresent, add({ payload: { name: action.type, data: action['data'] } }));
          } else {
            return reducer(newPresent, add({ payload: { name: persistentAction.hint || persistentAction.type, data: {} } }));
          }
        } else {
          const newState = reducer(state, action);
          states = { ...states, present: extractState(newState) };
          return newState;
        }
    }
  };
}

/**
 * Retrieves persistable part of the state.
 * @param state application state
 */
function extractState(state: IAppState): Partial<IAppState> {
  return pick(state, PERSISTENT_KEYS);
}

/**
 * Merge appstate with undoable part
 * @param state app state
 * @param undoablePart part of the state that was persisted
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function mergeStates(state: IAppState, undoablePart: any): IAppState {
  const newState = cloneDeep(state);
  PERSISTENT_KEYS.forEach(key => set(newState, key, get(undoablePart, key)));
  return newState;
}
