import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, Observable, of, switchMap, take } from 'rxjs';
import { activeProjectFile } from 'src/app/store/selectors/shared.selector';

@Injectable()
export class OriginalImageSizeService {
  constructor(private _store: Store) {}

  public get size(): Observable<{ width: number; height: number }> {
    return this._store.select(activeProjectFile).pipe(
      filter((file: File) => !!file),
      take(1),
      switchMap((file: File) => {
        return file
          ? new Observable<{ width: number; height: number }>(subscriber => {
              const image = new Image();
              image.src = URL.createObjectURL(file);
              image.onload = (): void =>
                subscriber.next({
                  width: image.width,
                  height: image.height,
                });
            })
          : of(null as any);
      })
    );
  }

  public getImageSize(blob: Blob): Observable<{ width: number; height: number }> {
    const imageUrl = URL.createObjectURL(blob);
    const img = new Image();

    return new Observable(observer => {
      img.onload = (): void => {
        observer.next({ width: img.width, height: img.height });
        observer.complete();
        URL.revokeObjectURL(imageUrl);
      };

      img.src = imageUrl;
    });
  }

  public getImageParamsSrc(src: string): Observable<{ width: number; height: number }> {
    const image = new Image();
    image.src = src;
    return new Observable(observer => {
      image.onload = (): void => {
        observer.next({ width: image.width, height: image.height });
        observer.complete();
      };
    });
  }
}
