import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { viewIdeaFurniture } from 'src/app/store/actions/ideas.actions';
import { ideaFurniture } from 'src/app/store/selectors/ideas.selectors';
import { ResolutionService } from 'src/app/user-flow/services/resolution.service';
import { CatalogData } from 'src/services/model/proposal';

@Component({
  selector: 'app-idea-furniture',
  templateUrl: './furniture-list.component.html',
  styleUrls: ['./furniture-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FurnitureListComponent {
  @Output() public openDetailsEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() public closePanel: EventEmitter<void> = new EventEmitter<void>();
  public ideaFurniture$: Observable<CatalogData[]> = this._store.select(ideaFurniture);

  constructor(
    public resolutionService: ResolutionService,
    private _store: Store
  ) {}

  public viewDetails(data: CatalogData): void {
    this._store.dispatch(viewIdeaFurniture({ data }));
    this.openDetailsEvent.emit();
  }

  public back(): void {
    this.closePanel.emit();
  }
}
