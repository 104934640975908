import { PlaneType } from '../enum/plane-type.enum';

export const PLANE_COLORS = {
  [PlaneType.WALL]: 0x000000,
  [PlaneType.FLOOR]: 0xffffff,
  [PlaneType.FLOOR_CONTINUE]: 0xffffff,
  [PlaneType.CEILING]: 0xffffff,
  [PlaneType.CEILING_CONTINUE]: 0xffffff,
  ['Selected Wall']: 0x0000ff,
};

export const PLACE_OPACITY = {
  [PlaneType.WALL]: 0.4,
  [PlaneType.FLOOR]: 0.4,
  [PlaneType.FLOOR_CONTINUE]: 0.4,
  [PlaneType.CEILING]: 0.4,
  [PlaneType.CEILING_CONTINUE]: 0.4,
};

export const OUTLINE_COLOR = 0xe8c37f;
export const ACTIVE_CORNER_COLOR = 16725504;
export const WINDOW = { color: 0xffffff, opacity: 0.8 };
