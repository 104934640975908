<div class="page-container">
    <app-ki-services-header
    title="IDEAS.TABS.STYLESWAP.TITLE"
    subtitle="IDEAS.TABS.STYLESWAP.SUBTITLE"
    ></app-ki-services-header>
    <form [formGroup]="userInputForm" class="form-container">
        <div class="choose-file">
            <div class="choose-file-container"  draggable="true" ngClass="{{dragAreaClassFirst}}" [ngClass]="{'selected': userInputForm.get('roomImage').valid}">
                <input #roomImageFile id="roomImageFile" type="file" class="hidden-input" accept=".heic,.jpeg,.png,.jpg,.heif" (change)="onRoomImageFileChange($event)"/>
                <div class="choose-file-line" [ngClass]="{'padding': userInputForm.get('roomImage').valid && !resolutionService.isMobileResolution}">
                    <ng-container *ngIf="resolutionService.isMobileResolution; then uploadRoomImage" ></ng-container>
                    <p class="m-18 choose-file-title">{{ "IDEAS.TABS.STYLESWAP.UPLOAD_ROOM_IMAGE" | translate }}</p>
                    <ng-container *ngIf="!resolutionService.isMobileResolution; then uploadRoomImage" ></ng-container>
                </div>
            </div>
            <div 
                class="choose-file-container no-radius"  
                draggable="true" 
                ngClass="{{dragAreaClassSecond}}" 
                [ngClass]="{'selected': userInputForm.get('styleImage').valid}"
                >
                <input #styleImageFile id="styleImageFile" type="file" class="hidden-input" accept=".heic,.jpeg,.png,.jpg,.heif" (change)="onStyleImageFileChange($event)"/>
                <div class="choose-file-line"  [ngClass]="{'padding': userInputForm.get('styleImage').valid && !resolutionService.isMobileResolution}">
                    <ng-container *ngIf="resolutionService.isMobileResolution; then uploadStyleImage" ></ng-container>
                    <p class="m-18 choose-file-title">{{ "IDEAS.TABS.STYLESWAP.UPLOAD_STYLE_IMAGE" | translate }}</p>
                    <ng-container *ngIf="!resolutionService.isMobileResolution; then uploadStyleImage" ></ng-container>
                </div>
                
            </div>
            <div class="button-container">
                <button class="button submit-button" mat-raised-button
                color="primary" (click)="onSubmit()">{{ "BUTTONS.CHANGE_STYLE" | translate }}</button>
                <mat-error  *ngIf="incorrectTypeError">{{ "IDEAS.INCORRECT_TYPE" | translate }}</mat-error>
            </div>
        </div>
        <ng-container *ngIf="!resolutionService.isMobileResolution; then imagesTemplate"></ng-container>
    </form>
    <div *ngIf="resolutionService.isMobileResolution; then imagesTemplate"></div>
</div>

<ng-template #imagesTemplate>
    <div class="image-container">
        <img 
            #image 
            class="image" 
            [src]="activeImage | safe:'url'"
            alt="image"
        >
        <button class="icon-button previous" [disabled]="index-1<0" (click)="previous()">
            <img 
                *ngIf="index-1>=0;else leftDisabled" 
                class="arrow" 
                src="../../../assets/icons/Gruppe 3005.svg"
                alt="previous"
            >
        </button>
        <button class="icon-button next" [disabled]="index+1>=images.length" (click)="next()">
            <img 
                *ngIf="index+1<images.length;else rightDisabled" 
                class="arrow transform" 
                src="../../../assets/icons/Gruppe 3005.svg"
                alt="next"
            >
        </button>
    </div>
</ng-template>

<ng-template #leftDisabled>
    <img 
        class="arrow" 
        src="../../../assets/icons/Gruppe 3005 disabled.svg"
        alt="left arrow disabled"
    >
</ng-template>

<ng-template #rightDisabled>
    <img 
        class="arrow transform" 
        src="../../../assets/icons/Gruppe 3005 disabled.svg"
        alt="right arrow disabled"
    >
</ng-template>

<ng-template #uploadRoomImage>
    <img 
        class="upload-photo-icon" 
        [src]="userInputForm.get('roomImage').valid ? './../../../../assets/icons/white-upload.svg' : './../../../../assets/icons/share-green.svg'"
        alt="Upload photo"
    >
</ng-template>

<ng-template #uploadStyleImage>
    <img 
        class="upload-photo-icon" 
        [src]="userInputForm.get('styleImage').valid ? './../../../../assets/icons/white-upload.svg' : './../../../../assets/icons/share-green.svg'"
        alt="Upload photo"
    >
</ng-template>