import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { LeftPanelState } from '../left-panel-state.enum';

@Component({
  selector: 'app-idea-furniture-container',
  templateUrl: './idea-furniture-container.component.html',
  styleUrls: ['./idea-furniture-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IdeaFurnitureContainerComponent {
  @Output() public openLeftPanel = new EventEmitter<boolean>(false);
  @Input() public pageType: LeftPanelState;
  @Output() public pageTypeUpdated = new EventEmitter<LeftPanelState>();
  public leftPanelStates = LeftPanelState;

  public closePanel(): void {
    this.openLeftPanel.emit(false);
  }

  public openDetailsEvent(): void {
    this.pageType = LeftPanelState.DETAILS_PANEL;
    this.pageTypeUpdated.emit(LeftPanelState.DETAILS_PANEL);
  }

  public openFurnitureList(): void {
    this.pageType = LeftPanelState.FURNITURE_LIST;
    this.pageTypeUpdated.emit(LeftPanelState.FURNITURE_LIST);
  }
}
