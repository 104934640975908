import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import {
  ADD_MODEL,
  addModel,
  DELETE_MODEL,
  DELETE_MODELS,
  deleteModel,
  deleteModels,
  LOCK_MODEL,
  lockModel,
  MOVE_MODEL,
  moveModel,
  RENAME_MODEL,
  renameModel,
} from 'src/app/store/actions/render.actions';
import { setProposal } from 'src/app/store/actions/shared.actions';
import { proposal } from 'src/app/store/selectors/shared.selector';
import { getCurrentAction } from 'src/app/undo-redo/undo-redo.selectors';
import { ProposalState } from 'src/models/proposal';
import { DataStoreService } from 'src/services/data-store.service';
import { Box3, Clock, Object3D, PerspectiveCamera, SpotLight, Vector3 } from 'three';

import { FLOOR_LAMP_LIGHT, PEDANT_LAMP_LIGHT, TABLE_LAMP_LIGHT, WALL_LAMP_LIGHT } from './const/blender.const';
import { disposeMovingObjects } from './dispose.utils';
import { KeyboardStateService } from './keyboard-state.service';
import { getSpotLightSource, patchSpotLightTargetPosition } from './light.utils';
import { MovingObjectsConfig } from './model/config/moving-objects-config.model';
import { MovingObject, ValueType } from './model/dto/moving-object.model';
import { FurnitureRenderInfo } from './model/params/camera-render-params.model';
import { MovingObjectsSaveLoadData } from './model/saveData/moving-objects-save-data.model';
import { PlanesService } from './planes.service';
import { MetersSize } from './positioning-rules/positioning-element.model';
import { RoomParamsService } from './room-params.service';
import { environment } from '../../../environments/environment';
import { FurnitureType } from '../enum/furniture-types.enum';
import { Current } from '../interfaces/current';

@UntilDestroy()
@Injectable()
export class MovingObjectsService {
  public get movingObjects(): MovingObject[] {
    return this._movingObjects;
  }

  private get _selectedObject3D(): Object3D {
    if (this._movingObjects.length > 0 && this._selectedObjectIndex < this._movingObjects.length && this._selectedObjectIndex > -1) {
      return this._movingObjects[this._selectedObjectIndex].object3D;
    }
    return null;
  }

  private _selectedObjectIndex = -1;
  private _movingObjects: MovingObject[] = [];

  private _clock: Clock = new Clock();

  private _config!: MovingObjectsConfig;

  private _proposalState: ProposalState;

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private _dataStoreService: DataStoreService,
    private _keyboardStateService: KeyboardStateService,
    private _roomParamsService: RoomParamsService,
    private _planeService: PlanesService,
    private _store: Store
  ) {
    this._document.addEventListener('keydown', this._switchSelectedObject.bind(this));
    this._store
      .select(proposal)
      .pipe(untilDestroyed(this))
      .subscribe(proposal => {
        this._proposalState = proposal;
      });
  }

  public setInitialState(): void {
    this.clearObjects();
    this._movingObjects = [];
    this._unsetSelectedObject();
    this._config = null;
  }

  public configure(config: MovingObjectsConfig, movingObjects?: MovingObject[]): void {
    if (movingObjects) {
      this._movingObjects = movingObjects;
    }
    this._config = config;

    this._store
      .select(getCurrentAction)
      .pipe(untilDestroyed(this))
      .subscribe(current => {
        if (current) {
          switch (current.action.name) {
            case ADD_MODEL:
              this._addModel(current);
              break;
            case DELETE_MODEL:
              this._deleteModel(current);
              break;
            case DELETE_MODELS:
              this._deleteModels(current);
              break;
            case MOVE_MODEL:
              this._moveModel(current);
              break;
            case RENAME_MODEL:
              this._renameModel(current);
              break;
            case LOCK_MODEL:
              this._lockModel(current);
              break;
          }
        }
      });
  }

  public autoscaleModel(movingObject: MovingObject): void {
    const metersInPixel: number = this._roomParamsService.get().heightMeters / this._roomParamsService.get().height3D;

    let currentSize: number;
    switch (movingObject.valueType) {
      case ValueType.HEIGHT:
        currentSize = movingObject.pixelsSize.y * metersInPixel;
        break;
      case ValueType.WIDTH:
        currentSize = movingObject.pixelsSize.x * metersInPixel;
        break;
      case ValueType.DEPTH:
        currentSize = movingObject.pixelsSize.z * metersInPixel;
        break;
    }

    if (movingObject.scale) {
      movingObject.object3D.scale.set(movingObject.scale.x, movingObject.scale.y, movingObject.scale.z);
    } else {
      movingObject.object3D.scale.set(
        movingObject.valueMeters / currentSize,
        movingObject.valueMeters / currentSize,
        movingObject.valueMeters / currentSize
      );
      movingObject.scale = movingObject.object3D.scale.clone();

      const metersSize: MetersSize = {
        width: this._roomParamsService.getMeterFrom3D(movingObject.pixelsSize.x),
        height: this._roomParamsService.getMeterFrom3D(movingObject.pixelsSize.y),
        depth: this._roomParamsService.getMeterFrom3D(movingObject.pixelsSize.z),
      };
      const roomHeight = this._roomParamsService.get().heightMeters;

      let positioningScale: Vector3;
      switch (movingObject.type) {
        case FurnitureType.CURTAIN: {
          positioningScale = new Vector3(0.4 / metersSize.width, roomHeight / metersSize.height, 0.1 / metersSize.depth);
          break;
        }
      }

      if (positioningScale) {
        movingObject.object3D.scale.set(positioningScale.x, positioningScale.y, positioningScale.z);
        movingObject.scale = positioningScale.clone();
      }
    }
  }

  public getFurnitureInfoForRendering(widthToScale: number, heightToScale: number, camera: PerspectiveCamera): FurnitureRenderInfo[] {
    return this._movingObjects
      .map(mo => {
        const links = mo.links ?? {
          lowGLB: environment.API_URL + 'resources/public/' + mo.manuallyLoadedFile,
          GLB: environment.API_URL + 'resources/public/' + mo.manuallyLoadedFile,
          FBX: environment.API_URL + 'resources/public/' + mo.manuallyLoadedFile,
        };
        const projection = this._calculateMaxBoundingBoxProjection(mo, widthToScale, heightToScale, camera);
        return {
          links,
          type: mo.type,
          underCategory: mo.underCategory,
          boundBoxX1: projection.xMin,
          boundBoxX2: projection.xMax,
          boundBoxY1: projection.yMin,
          boundBoxY2: projection.yMax,
          position: {
            x: mo.object3D.position.x,
            y: mo.object3D.position.y,
            z: mo.object3D.position.z,
          },
          scale: {
            x: mo.object3D.scale.x,
            y: mo.object3D.scale.y,
            z: mo.object3D.scale.z,
          },
          rotation: {
            x: mo.object3D.quaternion.x,
            y: mo.object3D.quaternion.y,
            z: mo.object3D.quaternion.z,
            w: mo.object3D.quaternion.w,
          },
        };
      })
      .filter(fri =>
        this._isBoundingBoxInsideOfPicture(fri.boundBoxX1, fri.boundBoxX2, fri.boundBoxY1, fri.boundBoxY2, widthToScale, heightToScale)
      );
  }

  private _isBoundingBoxInsideOfPicture(x1: number, x2: number, y1: number, y2: number, width: number, height: number): boolean {
    if (x1 < 0 || x1 > width || x2 < 0 || x2 > width || x1 === x2) return false;

    if (y1 < 0 || y1 > height || y2 < 0 || y2 > height || y1 === y2) return false;

    return true;
  }

  private _calculateMaxBoundingBoxProjection(
    mo: MovingObject,
    width: number,
    height: number,
    camera: PerspectiveCamera
  ): {
    xMax: number;
    yMax: number;
    xMin: number;
    yMin: number;
  } {
    const boundingBox = new Box3().setFromObject(mo.object3D, true);
    const low = boundingBox.min;
    const high = boundingBox.max;

    // corners of bounding box
    const corner1 = new Vector3(low.x, low.y, low.z);
    const corner2 = new Vector3(high.x, low.y, low.z);
    const corner3 = new Vector3(low.x, high.y, low.z);
    const corner4 = new Vector3(low.x, low.y, high.z);
    const corner5 = new Vector3(high.x, high.y, low.z);
    const corner6 = new Vector3(high.x, low.y, high.z);
    const corner7 = new Vector3(low.x, high.y, high.z);
    const corner8 = new Vector3(high.x, high.y, high.z);

    const convertedCorner1 = this.convertToTwoDimensions(corner1.clone(), width, height, camera);
    const convertedCorner2 = this.convertToTwoDimensions(corner2.clone(), width, height, camera);
    const convertedCorner3 = this.convertToTwoDimensions(corner3.clone(), width, height, camera);
    const convertedCorner4 = this.convertToTwoDimensions(corner4.clone(), width, height, camera);
    const convertedCorner5 = this.convertToTwoDimensions(corner5.clone(), width, height, camera);
    const convertedCorner6 = this.convertToTwoDimensions(corner6.clone(), width, height, camera);
    const convertedCorner7 = this.convertToTwoDimensions(corner7.clone(), width, height, camera);
    const convertedCorner8 = this.convertToTwoDimensions(corner8.clone(), width, height, camera);

    const xMax = Math.round(
      Math.max(
        ...[
          convertedCorner1.x,
          convertedCorner2.x,
          convertedCorner3.x,
          convertedCorner4.x,
          convertedCorner5.x,
          convertedCorner6.x,
          convertedCorner7.x,
          convertedCorner8.x,
        ]
      )
    );

    const xMin = Math.round(
      Math.min(
        ...[
          convertedCorner1.x,
          convertedCorner2.x,
          convertedCorner3.x,
          convertedCorner4.x,
          convertedCorner5.x,
          convertedCorner6.x,
          convertedCorner7.x,
          convertedCorner8.x,
        ]
      )
    );

    const yMax = Math.round(
      Math.max(
        ...[
          convertedCorner1.y,
          convertedCorner2.y,
          convertedCorner3.y,
          convertedCorner4.y,
          convertedCorner5.y,
          convertedCorner6.y,
          convertedCorner7.y,
          convertedCorner8.y,
        ]
      )
    );

    const yMin = Math.round(
      Math.min(
        ...[
          convertedCorner1.y,
          convertedCorner2.y,
          convertedCorner3.y,
          convertedCorner4.y,
          convertedCorner5.y,
          convertedCorner6.y,
          convertedCorner7.y,
          convertedCorner8.y,
        ]
      )
    );

    return {
      xMax: xMax >= width ? width : xMax,
      yMax: yMax >= height ? height : yMax,
      xMin,
      yMin,
    };
  }

  public convertToTwoDimensions(pos3D: Vector3, width: number, height: number, camera: PerspectiveCamera): { x: number; y: number } {
    camera.updateMatrixWorld();
    const result = pos3D.project(camera);
    result.x = ((result.x + 1) / 2) * width;
    result.y = (-(result.y - 1) / 2) * height;

    result.x = result.x < 0 ? 0 : result.x;
    result.y = result.y < 0 ? 0 : result.y;
    return result;
  }

  public getSaveData(): MovingObjectsSaveLoadData {
    const saveConfig = { ...this._config };
    delete saveConfig.scene;

    return {
      config: saveConfig,
      objects: this._movingObjects.map(movingObject => {
        const result = {
          ...movingObject,
          position: {
            x: movingObject.object3D.position.x,
            y: movingObject.object3D.position.y,
            z: movingObject.object3D.position.z,
          },
          rotation: {
            x: movingObject.object3D.rotation.x,
            y: movingObject.object3D.rotation.y,
            z: movingObject.object3D.rotation.z,
          },
          objectUUID: movingObject.object3D.uuid,
          lightUUID: movingObject.light?.uuid,
          lightPosition: movingObject.light?.position,
          lightTargetPosition: movingObject.light instanceof SpotLight ? movingObject.light?.target.position : new Vector3(),
          scale: {
            x: movingObject.object3D.scale.x,
            y: movingObject.object3D.scale.y,
            z: movingObject.object3D.scale.z,
          },
        };
        delete result.object3D;
        return result;
      }),
    };
  }

  public load(config: MovingObjectsConfig, objects: MovingObject[]): void {
    this._config = config;
    this._movingObjects = objects;
  }

  public detectCollisions(group: Object3D[]): boolean {
    if (this._selectedObject3D) {
      const boxes: Box3[] = group.map(object => new Box3().setFromObject(object));
      const selectedObjectBox: Box3 = new Box3().setFromObject(this._selectedObject3D);
      return boxes.some(box => selectedObjectBox.intersectsBox(box));
    }

    return false;
  }

  public deleteFurnitureBySKU(sku: string, touchStore: boolean, proposalStateAfter: ProposalState): void {
    this._unsetSelectedObject();
    const movingObjects: MovingObject[] = this._movingObjects.filter(object => object.SKU === sku);
    this.removeObjects(movingObjects, touchStore, proposalStateAfter);
  }

  public deleteFurniture(id: string, touchStore: boolean): void {
    this._unsetSelectedObject();
    const movingObject: MovingObject = this._movingObjects.find(object => object.id === id);
    this.removeObject(movingObject, touchStore);
  }

  public removeObject(object: MovingObject, touchStore: boolean, proposalStateAfter?: ProposalState): void {
    const index: number = this._movingObjects.indexOf(object);
    if (object) {
      this._movingObjects.splice(index, 1);
      const objectsGroup = this._config.scene.getObjectByName('objectsGroup');
      objectsGroup.remove(object.object3D);
      this._dataStoreService.addMovingObject(object);

      if (object.light) {
        this._config.scene.remove(object.light);
      }

      if (touchStore) {
        const proposalSnapshotAfter = proposalStateAfter ?? {
          ...this._proposalState,
          ProposedProposal: {
            ...this._proposalState.ProposedProposal,
            Articles: this._proposalState.ProposedProposal.Articles.map(a =>
              a.Sku === object.SKU
                ? {
                    ...a,
                    NumberOfExemplars: a.NumberOfExemplars - 1,
                  }
                : a
            ),
          },
        };

        this._store.dispatch(
          deleteModel({
            data: {
              id: object.id,
              sku: object.SKU,
              proposalSnapshotBefore: this._proposalState,
              proposalSnapshotAfter: proposalSnapshotAfter,
            },
          })
        );
      }
    }
  }

  public removeObjects(objects: MovingObject[], touchStore: boolean, proposalStateAfter: ProposalState): void {
    const objectsGroup = this._config.scene.getObjectByName('objectsGroup');
    const removedObjectsIds = objects.map(obj => obj.id);
    this._movingObjects = [...this._movingObjects.filter(mo => !removedObjectsIds.includes(mo.id))];
    objects.forEach(object => {
      objectsGroup.remove(object.object3D);
      this._dataStoreService.addMovingObject(object);

      if (object.light) {
        this._config.scene.remove(object.light);
      }
    });

    if (touchStore) {
      this._store.dispatch(
        deleteModels({
          data: {
            ids: removedObjectsIds,
            proposalSnapshotAfter: proposalStateAfter,
            proposalSnapshotBefore: this._proposalState,
          },
        })
      );
    }
  }

  public clone(id: string, touchStore: boolean): MovingObject {
    this._unsetSelectedObject();

    const movingObject: MovingObject = this._movingObjects.find(object => object.id === id);
    const countOfSameSkus = this._movingObjects.filter(obj => movingObject.SKU === obj.SKU).length;
    const object3DClone = movingObject.object3D.clone();

    object3DClone.position.add(new Vector3(0.2, 0, 0));

    const clone = {
      id: object3DClone.uuid,
      name: movingObject.name + ` (copy)${countOfSameSkus}`,
      valueMeters: movingObject.valueMeters,
      dimensions: movingObject.dimensions,
      pixelsSize: movingObject.pixelsSize,
      valueType: movingObject.valueType,
      type: movingObject.type,
      sticknessType: movingObject.sticknessType,
      object3D: object3DClone,
      lock: false,
      links: movingObject.links,
      price: movingObject.price,
      preview: movingObject.preview,
      format: movingObject.format,
      SKU: movingObject.SKU,
      description: movingObject.description,
      size: {
        width: movingObject.size.width,
        height: movingObject.size.height,
        length: movingObject.size.length,
      },
      title: movingObject.title,
      color: movingObject.color,
      sizes: movingObject.sizes,
      manuallyLoaded: movingObject.manuallyLoaded,
      manuallyLoadedFile: movingObject.manuallyLoadedFile,
      underCategory: movingObject.underCategory,
    };
    this.addObject(clone, touchStore);
    return clone;
  }

  public rename(id: string, newName: string, touchStore: boolean): void {
    const movingObject: MovingObject = this._movingObjects.find(object => object.id === id);
    const oldName = movingObject.name;

    if (movingObject) {
      movingObject.name = newName;
    }

    if (touchStore) {
      this._store.dispatch(renameModel({ data: { newName, oldName, id: movingObject.id } }));
    }
  }

  public lock(id: string, touchStore: boolean): void {
    const movingObject: MovingObject = this._movingObjects.find(object => object.id === id);

    if (movingObject) {
      movingObject.lock = !movingObject.lock;
    }

    if (touchStore) {
      this._store.dispatch(lockModel({ data: { id: movingObject.id } }));
    }
  }

  public getLockState(id: string): boolean {
    return this._movingObjects.find(object => object.id === id)?.lock;
  }

  public deleteAllFurniture(isRefreshProposal: boolean, proposalStateAfter?: ProposalState): MovingObject[] {
    const backup = this._movingObjects.slice();

    const ids = this._movingObjects.filter(object => !(object.lock && object.manuallyLoaded)).map(object => object.id);

    ids.forEach(id => {
      this.deleteFurniture(id, false);
    });

    if (!isRefreshProposal && proposalStateAfter) {
      this._store.dispatch(
        deleteModels({ data: { ids, proposalSnapshotAfter: proposalStateAfter, proposalSnapshotBefore: this._proposalState } })
      );
    }

    return backup;
  }

  public enableKeyboard(): void {
    this._keyboardStateService.keyboardEnabled = true;
  }

  public disableKeyboard(): void {
    this._keyboardStateService.keyboardEnabled = false;
  }

  public move(id: string, deltaMovement: Vector3): void {
    const delta = this._calcDeltaMeters(0.02);

    const movingObject = this._movingObjects.find(movingObject => movingObject.id === id);
    const deltaVector = deltaMovement.clone().multiplyScalar(delta);

    if (movingObject) {
      movingObject.object3D.position.add(deltaVector.clone());
      if (movingObject.light) {
        movingObject.light.position.add(deltaVector.clone());

        patchSpotLightTargetPosition(movingObject.light);
      }
    }
  }

  public scale(id: string, deltaScale: Vector3): void {
    const movingObject = this._movingObjects.find(movingObject => movingObject.id === id);
    if (!movingObject) return;

    const delta = this._calcDeltaMeters(0.02) * movingObject.scale.y;

    if (movingObject.object3D.scale.x < 0) {
      deltaScale.x = -deltaScale.x;
    }

    if (movingObject.object3D.scale.y < 0) {
      deltaScale.y = -deltaScale.y;
    }

    if (movingObject.object3D.scale.z < 0) {
      deltaScale.z = -deltaScale.z;
    }

    movingObject.object3D.scale.add(deltaScale.clone().multiplyScalar(delta));
  }

  public rotate(id: string, deltaRotate: number): void {
    const delta = this._calcDeltaRadians(deltaRotate > 0 ? 2 : -2);

    const movingObject = this._movingObjects.find(movingObject => movingObject.id === id);
    if (!movingObject) return;

    movingObject.object3D.rotation.y += delta;
  }

  public select(movingObject: MovingObject): void {
    this._selectedObjectIndex = this._movingObjects.findIndex(mo => mo.id === movingObject.id);
  }

  private _calcDeltaRadians(degrees: number): number {
    return (degrees * Math.PI) / 180;
  }

  private _calcDeltaMeters(meters: number): number {
    return this._roomParamsService.get3dFromMeter(meters);
  }

  public addObject(movingObject: MovingObject, touchStore: boolean, id?: string, proposalStateAfter?: ProposalState): void {
    if (movingObject) {
      let lightName = '';

      switch (movingObject.type) {
        case FurnitureType.LAMP:
          lightName = PEDANT_LAMP_LIGHT;
          break;
        case FurnitureType.FLOOR_LAMP:
          lightName = FLOOR_LAMP_LIGHT;
          break;
        case FurnitureType.WALL_LAMP:
          lightName = WALL_LAMP_LIGHT;
          break;
        case FurnitureType.TABLE_LAMP:
          lightName = TABLE_LAMP_LIGHT;
          break;
      }

      this._movingObjects.push(movingObject);
      const objectsGroup = this._config.scene.getObjectByName('objectsGroup');

      objectsGroup.add(movingObject.object3D);

      if (id) {
        movingObject.id = id;
      }
      movingObject.object3D.userData['isMainObject'] = true;

      if (
        movingObject.type === FurnitureType.TABLE_LAMP ||
        movingObject.type === FurnitureType.FLOOR_LAMP ||
        movingObject.type === FurnitureType.WALL_LAMP
      ) {
        movingObject.light = getSpotLightSource(movingObject.object3D.position, lightName, this._roomParamsService.roomBoundaries.yMin);
        this._config.scene.add(movingObject.light);
      }

      if (movingObject.type === FurnitureType.LAMP) {
        // if added from catalog => object start position:
        if (movingObject.object3D.position.equals(this._roomParamsService.get().objectStartPosition)) {
          movingObject.object3D.position.addVectors(this._planeService.getRoomCenter(), new Vector3(0, 2, 0));
        }

        movingObject.light = getSpotLightSource(movingObject.object3D.position, lightName, this._roomParamsService.roomBoundaries.yMin);
        this._config.scene.add(movingObject.light);
      }

      if (touchStore) {
        this._dataStoreService.addMovingObject(movingObject);

        let proposalSnapshotAfter: ProposalState;

        if (this._proposalState) {
          proposalSnapshotAfter = proposalStateAfter ?? {
            ...this._proposalState,
            ProposedProposal: {
              ...this._proposalState.ProposedProposal,
              Articles: this._proposalState.ProposedProposal.Articles.map(a =>
                a.Sku === movingObject.SKU
                  ? {
                      ...a,
                      NumberOfExemplars: a.NumberOfExemplars + 1,
                    }
                  : a
              ),
            },
          };
        }

        this._store.dispatch(
          addModel({
            data: {
              id: movingObject.id,
              sku: movingObject.SKU,
              proposalSnapshotBefore: this._proposalState,
              proposalSnapshotAfter: proposalSnapshotAfter,
            },
          })
        );
      }
    }
  }

  public updateRotationSelectedObject3D(rotation: Vector3): void {
    this._selectedObject3D.rotation.x = rotation.x;
    this._selectedObject3D.rotation.y = rotation.y;
    this._selectedObject3D.rotation.z = rotation.z;
  }

  public updatePositionSelectedObject3D(position: Vector3): void {
    this._selectedObject3D.position.x = position.x;
    this._selectedObject3D.position.y = position.y;
    this._selectedObject3D.position.z = position.z;
  }

  public updateScaleSelectedObject3D(scale: Vector3): void {
    this._selectedObject3D.scale.x = scale.x;
    this._selectedObject3D.scale.y = scale.y;
    this._selectedObject3D.scale.z = scale.z;
  }

  public handleMoving(): void {
    const delta: number = this._clock.getDelta();
    const moveDistance: number = (this._config?.moveSpeed ?? 1) * delta;
    const rotateAngle: number = (Math.PI / 2) * (this._config?.rotateSpeed ?? 0.01);

    if (this._selectedObject3D) {
      if (this._keyboardStateService.pressed('Q')) this._pressedRotateButton(rotateAngle, 'Q');
      if (this._keyboardStateService.pressed('A')) this._pressedRotateButton(rotateAngle, 'A');
      if (this._keyboardStateService.pressed('W')) this._pressedRotateButton(rotateAngle, 'W');
      if (this._keyboardStateService.pressed('S')) this._pressedRotateButton(rotateAngle, 'S');
      if (this._keyboardStateService.pressed('E')) this._pressedRotateButton(rotateAngle, 'E');
      if (this._keyboardStateService.pressed('D')) this._pressedRotateButton(rotateAngle, 'D');
      if (this._keyboardStateService.pressed('left')) this._pressedPositionButton(moveDistance, 'left');
      if (this._keyboardStateService.pressed('right')) this._pressedPositionButton(moveDistance, 'right');
      if (this._keyboardStateService.pressed('up')) this._pressedPositionButton(moveDistance, 'up');
      if (this._keyboardStateService.pressed('down')) this._pressedPositionButton(moveDistance, 'down');
      if (this._keyboardStateService.pressed('Z')) this._pressedPositionButton(moveDistance, 'Z');
      if (this._keyboardStateService.pressed('X')) this._pressedPositionButton(moveDistance, 'X');
      if (this._keyboardStateService.pressed('N')) this._pressedScaleButton('N');
      if (this._keyboardStateService.pressed('M')) this._pressedScaleButton('M');
    }
  }

  public clearObjects(): void {
    disposeMovingObjects(this.movingObjects);
  }

  private _addModel(current: Current): void {
    const id = current.action.data.id;
    let stateToRestore: ProposalState;
    if (current.command === 'undo') {
      stateToRestore = current.action.data.proposalSnapshotBefore;
      this.deleteFurniture(id, false);
    } else {
      stateToRestore = current.action.data.proposalSnapshotAfter;
      const movingObject = this._dataStoreService.getMovingObject(id);
      this.addObject(movingObject, false, id);
    }

    this._store.dispatch(setProposal({ data: stateToRestore }));
  }

  private _deleteModel(current: Current): void {
    const id = current.action.data.id;
    let stateToRestore: ProposalState;
    if (current.command === 'redo') {
      stateToRestore = current.action.data.proposalSnapshotAfter;
      this.deleteFurniture(id, false);
    } else {
      stateToRestore = current.action.data.proposalSnapshotBefore;
      const movingObject = this._dataStoreService.getMovingObject(id);
      this.addObject(movingObject, false, id);
    }

    this._store.dispatch(setProposal({ data: stateToRestore }));
  }

  private _deleteModels(current: Current): void {
    let stateToRestore: ProposalState;
    const ids = current.action.data.ids;
    if (current.command === 'redo') {
      stateToRestore = current.action.data.proposalSnapshotAfter;

      ids.forEach(id => {
        this.deleteFurniture(id, false);
      });
    } else {
      stateToRestore = current.action.data.proposalSnapshotBefore;

      ids.forEach(id => {
        const movingObject = this._dataStoreService.getMovingObject(id);
        this.addObject(movingObject, false, id);
      });
    }

    this._store.dispatch(setProposal({ data: stateToRestore }));
  }

  private _unsetSelectedObject(): void {
    this._selectedObjectIndex = -1;
  }

  private _moveModel(current: Current): void {
    if (current.command === 'undo') {
      this._moveModelUndo(current);
    } else {
      this._moveModelRedo(current);
    }
  }

  private _moveModelUndo(current: Current): void {
    const start = current.action.data.start;
    const moveType = current.action.data.moveType;
    this._move(moveType, start);
  }

  private _moveModelRedo(current: Current): void {
    const end = current.action.data.end;
    const moveType = current.action.data.moveType;
    this._move(moveType, end);
  }

  private _move(moveType: string, point: Vector3): void {
    switch (moveType) {
      case 'rotation':
        this.updateRotationSelectedObject3D(point);
        break;
      case 'position':
        this.updatePositionSelectedObject3D(point);
        break;
      case 'scale':
        this.updateScaleSelectedObject3D(point);
        break;
    }
  }

  private _renameModel(current: Current): void {
    if (current.command === 'undo') {
      const oldName = current.action.data.oldName;
      const id = current.action.data.id;
      this.rename(id, oldName, false);
    } else {
      const newName = current.action.data.newName;
      const id = current.action.data.id;
      this.rename(id, newName, false);
    }
  }

  private _lockModel(current: Current): void {
    const id = current.action.data.id;
    this.lock(id, false);
  }

  private _pressedPositionButton(moveDistance: number, buttonName: string): void {
    const position = this._selectedObject3D.position;
    const start = new Vector3(position.x, position.y, position.z);
    switch (buttonName) {
      case 'left':
        this._selectedObject3D.position.x -= moveDistance;
        break;
      case 'right':
        this._selectedObject3D.position.x += moveDistance;
        break;
      case 'up':
        this._selectedObject3D.position.z -= moveDistance;
        break;
      case 'down':
        this._selectedObject3D.position.z += moveDistance;
        break;
      case 'Z':
        this._selectedObject3D.position.y -= moveDistance;
        break;
      case 'X':
        this._selectedObject3D.position.y += moveDistance;
        break;
    }
    const end = new Vector3(position.x, position.y, position.z);
    this._store.dispatch(moveModel({ data: { start, end, moveType: 'position' } }));
  }

  private _pressedRotateButton(rotateAngle: number, buttonName: string): void {
    const rotation = this._selectedObject3D.rotation;
    const start = new Vector3(rotation.x, rotation.y, rotation.z);
    switch (buttonName) {
      case 'Q':
        this._selectedObject3D.rotation.y += rotateAngle;
        break;
      case 'A':
        this._selectedObject3D.rotation.y -= rotateAngle;
        break;
      case 'W':
        this._selectedObject3D.rotation.x += rotateAngle;
        break;
      case 'S':
        this._selectedObject3D.rotation.x -= rotateAngle;
        break;
      case 'E':
        this._selectedObject3D.rotation.z += rotateAngle;
        break;
      case 'D':
        this._selectedObject3D.rotation.z -= rotateAngle;
        break;
    }
    const end = new Vector3(rotation.x, rotation.y, rotation.z);
    this._store.dispatch(moveModel({ data: { start, end, moveType: 'rotation' } }));
  }

  private _pressedScaleButton(buttonName: string): void {
    const scale = this._selectedObject3D.scale;
    const start = new Vector3(scale.x, scale.y, scale.z);
    switch (buttonName) {
      case 'N':
        this._selectedObject3D.scale.x -= this._config.scaleSpeed;
        this._selectedObject3D.scale.y -= this._config.scaleSpeed;
        this._selectedObject3D.scale.z -= this._config.scaleSpeed;
        break;
      case 'M':
        this._selectedObject3D.scale.x += this._config.scaleSpeed;
        this._selectedObject3D.scale.y += this._config.scaleSpeed;
        this._selectedObject3D.scale.z += this._config.scaleSpeed;
        break;
    }
    const end = new Vector3(scale.x, scale.y, scale.z);
    this._store.dispatch(moveModel({ data: { start, end, moveType: 'scale' } }));
  }

  private _switchSelectedObject(event: KeyboardEvent): void {
    if (this._keyboardStateService.keyboardEnabled) {
      if (event.key === '1') {
        this._prev();
      }
      if (event.key === '2') {
        this._next();
      }
    }
  }

  private _next(): void {
    if (this._selectedObjectIndex < this._movingObjects.length - 1) {
      this._selectedObjectIndex++;
    }
  }

  private _prev(): void {
    if (this._selectedObjectIndex > 0) {
      this._selectedObjectIndex--;
    }
  }

  public flip(objectUUID: string): void {
    const movingObject = this._movingObjects.find(movingObject => movingObject.id === objectUUID);

    if (movingObject) {
      movingObject.object3D.scale.x *= -1;
    }
  }
}
