import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { setRoomHeight } from 'src/app/store/actions/render.actions';

@Component({
  selector: 'app-set-height-dialog',
  templateUrl: './set-height-dialog.component.html',
  styleUrls: ['./set-height-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SetHeightDialogComponent {
  public heightControl: FormControl = new FormControl(null, Validators.required);

  constructor(
    private _dialogRef: MatDialogRef<SetHeightDialogComponent>,
    private _store: Store
  ) {}

  public close(): void {
    this._store.dispatch(setRoomHeight({ height: this.heightControl.value }));
    this._dialogRef.close(this.heightControl.value);
  }
}
