import { Mesh, MeshBasicMaterial } from 'three';

import { PointType } from '../../manual-reconstruct-editor/holes.enum';
import { ACTIVE_CORNER_COLOR, OUTLINE_COLOR } from '../const/colors.const';
import { PointPlace } from '../enum/point-place.type';
import { PointPlane } from '../model/dto/plane.model';

export class WallPoint extends Mesh {
  public override userData: {
    planes: PointPlane[];
    type: PointType;
    place: PointPlace;
  };

  public enableColorHighlight(): void {
    this.coloredMesh.color.setHex(ACTIVE_CORNER_COLOR);
  }

  public disableColorHighlight(): void {
    this.coloredMesh.color.setHex(OUTLINE_COLOR);
  }

  private get coloredMesh(): MeshBasicMaterial {
    return this.children[0]['material'] as MeshBasicMaterial;
  }
}
