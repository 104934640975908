<div *ngIf="(catalogData$ | async) && !selectedVariant" class="details-page-content">
    <div 
        class="back" 
        (click)="back()"
        aria-hidden="true"
    >
        <img class="icon" src="./../../../../assets/icons/arrow-left-slide.png" alt="arrow-left">
        <span class="r-16">{{ 'USER_FLOW.CATALOG.TITLE_DETAILS_PAGE' | translate: { type: 'USER_FLOW.CATALOG.' + currentData?.BasicArticleInformation?.Type | translate } }}</span>
    </div>
    <div class="details-header">
        <a *ngIf="currentData.BasicArticleInformation | link" class="title r-16" [href]="currentData.BasicArticleInformation | link" target="_blank">{{ currentData.BasicArticleInformation.Title }}</a>
        <p *ngIf="!(currentData.BasicArticleInformation | link)" class="title r-16"><b>{{ currentData.BasicArticleInformation.Title }}</b></p>
        <p class="type r-12">{{ "USER_FLOW.CATALOG." + currentData.BasicArticleInformation.Type | translate | uppercase }}</p>
    </div>

    <div class="image-wrapper furniture-container" *ngIf="currentData.BasicArticleInformation.PackshotImages">
        <img 
            class="icon" 
            *ngIf="checkModelLinkExist(currentData.BasicArticleInformation)" 
            src="./../../../../assets/icons/noun-ar-2300882.svg"
            alt="3D"
        >
        <img 
            class="image" 
            [src]="activeImage | safe: 'url'" 
            alt="image"
        />
        <div *ngIf="currentData.BasicArticleInformation.PackshotImages?.length > 1">
            <button class="icon-button previous" [disabled]="imageIndex-1<0" (click)="previous(currentData.BasicArticleInformation.PackshotImages)">
                <img 
                    *ngIf="imageIndex-1>=0;else leftDisabled" 
                    class="arrow" src="../../../assets/icons/Gruppe 3005.svg"
                    alt="previous"
                >
            </button>
            <button class="icon-button next" [disabled]="imageIndex+1>=currentData.BasicArticleInformation.PackshotImages.length" (click)="next(currentData.BasicArticleInformation.PackshotImages)">
                <img 
                    *ngIf="imageIndex+1<currentData.BasicArticleInformation.PackshotImages.length;else rightDisabled" 
                    class="arrow transform" src="../../../assets/icons/Gruppe 3005.svg"
                    alt="next"
                    >
            </button>
        </div>
    </div>

    <button class="button button-secondary add-button" mat-button (click)="addModel(currentData)">{{ isAlreadyOnScene ? ('BUTTONS.DUPLICATE' | translate | uppercase) : ('BUTTONS.ADD' | translate) }}</button>

    <mat-expansion-panel #detailsPanel hideToggle="true" class="expansion-panel" *ngIf="currentData.BasicArticleInformation.SKU">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <p class="m-18">{{ "USER_FLOW.DETAILS_PAGE.DETAILS" | translate }}</p>
            </mat-panel-title>
            <mat-panel-description>
                <img 
                    *ngIf="!detailsPanel.expanded" 
                    src="./../../../../assets/icons/Gruppe 3096.svg"
                    alt="collapsed"
                >
                <img 
                    *ngIf="detailsPanel.expanded"  
                    src="./../../../../assets/icons/Gruppe 3096.svg"
                    alt="expanded"
                >
              </mat-panel-description>
        </mat-expansion-panel-header>
        

        <p class="r-16">{{ description$ | async }}</p>
        <div>
            <p class="no-bottom-offset m-16">{{ "USER_FLOW.DETAILS_PAGE.SKU" | translate }}</p>
            <p class="no-top-offset r-16">{{ currentData.BasicArticleInformation?.SKU }}</p>
        </div>

        <div>
            <p class="no-bottom-offset m-16">{{ "USER_FLOW.DETAILS_PAGE.COLOR" | translate }}</p>
            <p class="no-top-offset r-16">{{ currentData.BasicArticleInformation | color }}</p>
        </div>

        <div>
            <p class="no-bottom-offset m-16">{{ "USER_FLOW.DETAILS_PAGE.SIZE" | translate }}</p>
            <p class="no-top-offset r-16">{{ currentData.BasicArticleInformation | size }}</p>
        </div>

    </mat-expansion-panel>

    <mat-expansion-panel #variantsPanel hideToggle="true" class="expansion-panel" *ngIf="currentData.BasicArticleInformation.SKU">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <p class="m-18">{{ "USER_FLOW.DETAILS_PAGE.VARIANTS" | translate }}</p>
            </mat-panel-title>
            <mat-panel-description>
                <img 
                    *ngIf="!variantsPanel.expanded" 
                    src="./../../../../assets/icons/Gruppe 3096.svg"
                    alt="collapsed"
                >
                <img 
                    *ngIf="variantsPanel.expanded"  
                    src="./../../../../assets/icons/Gruppe 3096.svg"
                    alt="expanded"
                >
              </mat-panel-description>
        </mat-expansion-panel-header>

        <div class="variant-block">
            {{ "USER_FLOW.DETAILS_PAGE.COLOR" | translate }}:
            <span *ngIf="currentData">{{ currentData.BasicArticleInformation | color }}</span>
        </div>

        <div class="variants">
            <div 
                *ngFor="let variant of currentData.Variants" 
                class="image-wrapper" 
                (click)="setActiveVariant(variant)"
                aria-hidden="true"
            >
                <img 
                    class="image" 
                    [src]="variant.BasicArticleInformation.PackshotImages[0] | safe: 'url'"
                    [ngClass]="{'active': variant.BasicArticleInformation.SKU === currentData?.BasicArticleInformation.SKU}"
                    alt="image"
                />
            </div>
        </div>
    </mat-expansion-panel>

    <div *ngIf="sizes?.length > 1">
        <p class="m-18">{{ "USER_FLOW.DETAILS_PAGE.SIZE" | translate }}</p>
        <mat-form-field class="select">
            <mat-select [(value)]="defaultSize">
                <mat-option *ngFor="let size of sizes" [value]="size">
                    <p class="m-18">{{ size | translate }}</p>
                </mat-option>
            </mat-select>
            <img 
                matSuffix 
                src="../../../../assets//icons/Gruppe 3096.svg"
                alt="arrow down"
            >
        </mat-form-field>
    </div>
  
      <div *ngIf="materials?.length > 1">
        <p class="m-18">{{ "USER_FLOW.DETAILS_PAGE.MATERIAL" | translate }}</p>
        <mat-form-field class="select">
            <mat-select [(value)]="defaultMaterial">
                <mat-option *ngFor="let material of materials" [value]="material">
                    <p class="m-18">{{ material | translate }}</p>
                </mat-option>
            </mat-select>
            <img 
                matSuffix 
                src="../../../../assets//icons/Gruppe 3096.svg"
                alt="arrow down"
            >
        </mat-form-field>
    </div>

</div>

<app-variant-details *ngIf="selectedVariant" [variant]="selectedVariant" (back)="backFromVariant($event)"></app-variant-details>


<ng-template #leftDisabled>
    <img 
        class="arrow" 
        src="../../../assets/icons/Gruppe 3005 disabled.svg" 
        alt="left arrow Disabled"
    >
</ng-template>

<ng-template #rightDisabled>
    <img 
        class="arrow transform" 
        src="../../../assets/icons/Gruppe 3005 disabled.svg" 
        alt="right arrow Disabled"
    >
</ng-template>