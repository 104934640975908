import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { activeProjectSrc } from 'src/app/store/selectors/shared.selector';

import { InputType } from '../enum/input-types.enum';
import { Option } from '../interfaces/option';
import { RefreshNavigationService } from '../services/refresh-navigation.service';

@UntilDestroy()
@Component({
  selector: 'app-remove-preview',
  templateUrl: './remove-preview.component.html',
})
export class RemovePreviewComponent implements OnInit {
  public options: Option[] = [
    { label: 'USER_FLOW.LABELS.NO', checked: true, value: '2' },
    { label: 'USER_FLOW.LABELS.YES', checked: false, value: '1', beta: true },
  ];
  public img$: Observable<string> = this._store.select(activeProjectSrc);
  public selected = new FormControl();
  public inputType: InputType = InputType.RADIO;

  constructor(
    private _router: Router,
    private _store: Store,
    private _refreshNavigationService: RefreshNavigationService
  ) {}

  public ngOnInit(): void {
    this._refreshNavigationService.refreshPageEvent$.pipe(untilDestroyed(this)).subscribe(() => this._router.navigate(['choose-file']));
  }

  public onChange(event): void {
    if (event.action === 'back') {
      this._router.navigate(['budget']);
    } else {
      if (event.value === '1') {
        this._router.navigate(['remove-editor']);
      } else {
        this._router.navigate(['reconstruct-preview']);
      }
    }
  }
}
