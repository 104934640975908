
<div class="page-container">
    <div>
        <div *ngIf="resolutionService.isMobileResolution">
            <img 
                class="back-arrow" 
                src="./../../../assets/icons/arrow-left-short.svg" 
                alt="Go back" 
                (click)="back()" 
                aria-hidden="true"
            >
        </div>
        <div class="page-header">
            <p class="subheader" [ngClass]="{'r-24': !resolutionService.isMobileResolution, 'r-16': resolutionService.isMobileResolution}">{{ subHeader | translate }}</p>
            <p class="header" [ngClass]="{'m-40': !resolutionService.isMobileResolution, 'm-25': resolutionService.isMobileResolution}">{{ header | translate }}</p>
        </div>
    </div>
    <div class="content-wrapper">
        <div class="navigation-buttons">
            <img 
                class="back-arrow" 
                src="./../../../assets/icons/arrow-left-short.svg" 
                alt="Go back" (click)="back()" 
                aria-hidden="true"
            >
            <button class="button" mat-raised-button color="primary" [disabled]="selected.invalid" (click)="next()">{{'BUTTONS.NEXT' | translate}}</button>
        </div>
        <div class="grid">
            <div class="info-container">

                <div class="page-info">

                    <ng-container *ngIf="resolutionService.isMobileResolution && !imageChange, then staticImage"></ng-container>

                    <ng-container [ngSwitch]="inputType">
                        <div class="options" *ngSwitchCase="types.RADIO">
                            <div *ngFor="let option of options" class="option-container">
                                <div 
                                    [ngClass]="{'selected': selected.value === option.value, 'small-margin': options.length > 2, 'big-margin': options.length === 2, 'unavailable': (option.notAvailable | async)}"
                                     class="option" 
                                     (click)="selectOption(option.value)"
                                     aria-hidden="true">
                                    <span class="m-20">{{option.label | translate}}</span>
                                </div>
                                <span class="beta m-25" *ngIf="option.beta">{{ "BETA" | translate }}</span>
                            </div>
                        </div>
                        <div *ngSwitchCase="types.INPUT" class="input-container">
                            <label class="m-20">{{ "USER_FLOW.LABELS.PROJECT_NAME" | translate }}</label>
                            <mat-form-field class="input-field">
                                <input matInput [formControl]="selected">
                            </mat-form-field>
                        </div>

                    </ng-container>

                </div>
            </div>
            <ng-container *ngIf="!resolutionService.isMobileResolution">
                <div class="image-container" *ngIf="imageChange; else staticImage">
                    <div [ngSwitch]="selected.value">
                        <ng-container *ngFor="let option of options; let i">
                            <img class="background-image" *ngSwitchCase="option.value" alt="Project name page" [src]="option.img">
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </div>

        
    </div>
    <div class="button-wrapper">
        <button *ngIf="resolutionService.isMobileResolution" class="button" mat-raised-button color="primary" [disabled]="selected.invalid" (click)="next()">{{'BUTTONS.NEXT' | translate}}</button>
    </div>
</div>

<ng-template #staticImage>
    <ng-container [ngSwitch]="imgIsRender">
        <app-render
          #render
          *ngSwitchCase="true"
          [clickType]="clickType"
          [canScrollThrow]="scrollOnRender"
          class="render"
        ></app-render>
        <div class="image-container">
            <img 
                *ngSwitchCase="false" 
                [src]="img | safe: 'url'" 
                class="background-image inline-image"
                alt="image"
            >
        </div>
      </ng-container>
</ng-template>
