import { ChangeDetectionStrategy, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import heic2any from 'heic2any';
import { map, Observable } from 'rxjs';
import { Notification } from 'src/app/notifications/model/notification';
import { NotificationsService } from 'src/app/notifications/services/notifications.service';
import { Mode } from 'src/app/shared/enums/mode.enum';
import { FileSizeService } from 'src/app/shared/services/file-size.service';
import { ImportantNotificationsService } from 'src/app/shared/services/important-notifications.service';
import { fileUpdated } from 'src/app/store/actions/image.actions';
import {
  clearActiveProject,
  clearReconstructResult,
  setMode,
  setOverlayLoadingSpinner,
  setUnsavedChanges,
  updateActiveProject,
} from 'src/app/store/actions/shared.actions';
import { userRoleRemainingProjects } from 'src/app/store/selectors/shared.selector';
import { IProjectAdditionalInfo } from 'src/models/project';

import { ImageFileParamsService } from '../services/image-file-params.service';
import { ResolutionService } from '../services/resolution.service';

@UntilDestroy()
@Component({
  selector: 'app-choose-file',
  templateUrl: './choose-file.component.html',
  styleUrls: ['./choose-file.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChooseFileComponent implements OnInit {
  @ViewChild('fileInput', { static: true }) public fileInput: ElementRef;
  public userRoleRemainingProjects$: Observable<{ role: string; remainingProjects: number }> =
    this._store.select(userRoleRemainingProjects);
  public projectInfo: IProjectAdditionalInfo;
  public dragAreaClass = 'dragarea';
  public showWarning = false;
  public showError = false;
  @HostListener('dragover', ['$event']) public onDragOver(event: DragEvent): void {
    this.dragAreaClass = 'droparea';
    event.preventDefault();
  }
  @HostListener('dragenter', ['$event']) public onDragEnter(event: DragEvent): void {
    this.dragAreaClass = 'droparea';
    event.preventDefault();
  }
  @HostListener('dragend', ['$event']) public onDragEnd(event: DragEvent): void {
    this.dragAreaClass = 'dragarea';
    event.preventDefault();
  }
  @HostListener('dragleave', ['$event']) public onDragLeave(event: DragEvent): void {
    this.dragAreaClass = 'dragarea';
    event.preventDefault();
  }
  @HostListener('drop', ['$event']) public onDrop(event: DragEvent): void {
    this.dragAreaClass = 'dragarea';
    event.preventDefault();
    event.stopPropagation();
    this.showWarning = false;
    this.userRoleRemainingProjects$
      .pipe(
        map(roleRemainingProjects => {
          if (roleRemainingProjects?.role === 'USER' && roleRemainingProjects.remainingProjects <= 0) {
            this._importantNotificationsService.limitNotification();
            this.fileInput.nativeElement.value = null;
          } else {
            if (event.dataTransfer.files && event.dataTransfer.files[0] && this._acceptableFormatCheck(event.dataTransfer.files[0])) {
              if (this.fileSizeService.fileSizeLess10MB(event.dataTransfer.files[0])) {
                this._setFile(event.dataTransfer.files[0]);
              }
            } else {
              this.showWarning = true;
            }
          }
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  constructor(
    public fileSizeService: FileSizeService,
    private _store: Store,
    private _router: Router,
    private _imageFileParamsService: ImageFileParamsService,
    private _notificationsService: NotificationsService,
    private _translateService: TranslateService,
    private _importantNotificationsService: ImportantNotificationsService,
    public resolutionService: ResolutionService
  ) {}

  public ngOnInit(): void {
    window.scrollTo(0, 1);
    this._store.dispatch(setMode({ mode: Mode.CREATE }));
    this._store.dispatch(clearActiveProject());
    this._store.dispatch(clearReconstructResult());
  }

  public onFileChange(event): void {
    const selectedfile = (event.target as HTMLInputElement).files || ({} as FileList);
    this.userRoleRemainingProjects$
      .pipe(
        map(roleRemainingProjects => {
          if (roleRemainingProjects?.role === 'USER' && roleRemainingProjects.remainingProjects <= 0) {
            this._importantNotificationsService.limitNotification();
            (<HTMLInputElement>document.getElementById('fileInput')).value = '';
          } else {
            if (selectedfile.length > 0 && this.fileSizeService.fileSizeLess10MB(selectedfile[0])) {
              this._setFile(selectedfile[0]);
            }
          }
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private _acceptableFormatCheck(file: File): boolean {
    if (
      file.name.toLowerCase().includes('.heic') ||
      file.name.toLowerCase().includes('.heif') ||
      file.name.toLowerCase().includes('.jpeg') ||
      file.name.toLowerCase().includes('.png') ||
      file.name.toLowerCase().includes('.jpg')
    ) {
      return true;
    }
    return false;
  }

  private async _setFile(file: File): Promise<void> {
    if (file.name.toLowerCase().includes('.heif')) {
      this._store.dispatch(setOverlayLoadingSpinner({ status: true }));
    }
    if (file.name.toLowerCase().includes('.heic') || file.name.toLowerCase().includes('.heif')) {
      const blob = await fetch(URL.createObjectURL(file)).then(res => res.blob());
      heic2any({ blob })
        .then(result => {
          file = new File([result as Blob], 'background.jpg');
          this._collectInStore(file);
        })
        .catch(() => {
          this._notificationsService.addNotification(
            new Notification({
              title: this._translateService.instant('NOTIFICATIONS.TITLE.ERROR'),
              text: this._translateService.instant('NOTIFICATIONS.MESSAGES.WRONG_IMAGE_TYPE'),
              level: 'error',
              options: { timeout: 2 },
            })
          );
        })
        .finally(() => this._store.dispatch(setOverlayLoadingSpinner({ status: false })));
    } else {
      this._collectInStore(file);
    }
  }

  private _collectInStore(file: File): void {
    this._imageFileParamsService.getImageParams(file, file.name).subscribe(params => {
      this._store.dispatch(fileUpdated());
      this.projectInfo = {
        src: params.src,
        width: params.width,
        height: params.height,
        file: params.file,
      };
      this._store.dispatch(updateActiveProject({ projectInfo: this.projectInfo }));
      this._store.dispatch(setUnsavedChanges({ state: true }));
      this._router.navigate(['project-name']);
      this._store.dispatch(setOverlayLoadingSpinner({ status: false }));
    });
  }
}
