import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { Group, Material, Mesh, Object3D, Scene } from 'three';
import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter';
import { USDZExporter } from 'three/examples/jsm/exporters/USDZExporter';
import { Line2 } from 'three/examples/jsm/lines/Line2';

import { COLOR_WALL } from './const/blender.const';
import { PlaneType } from './enum/plane-type.enum';
import { ExportConfig } from './model/config/export-config.model';
import { SceneSaveData } from './model/saveData/scene-save-data.model';

@Injectable()
export class ExportService {
  private _config!: ExportConfig;

  private _gltfExporter: GLTFExporter = new GLTFExporter();

  public configure(config: ExportConfig): void {
    this._config = config;
  }

  public createSceneFile(sceneSaveData: SceneSaveData): Observable<File> {
    return new Observable<File>(subscriber => {
      const resultString: string = JSON.stringify(sceneSaveData);
      const file = new File([resultString], 'scene.json');
      subscriber.next(file);
    });
  }

  public getSceneFileBuffer(scene: Scene): Observable<ArrayBuffer> {
    return new Observable(subscriber => {
      this._gltfExporter.parse(
        scene,
        gltf => {
          subscriber.next(gltf as ArrayBuffer);
          subscriber.complete();
        },
        () => {}
      );
    });
  }

  private _disposeLine(parent: Object3D, line: Line2): void {
    line.geometry.dispose();
    line.material.dispose();
    parent.remove(line);
  }

  private _clearLinesInObjects(objects: Object3D[]): void {
    objects.forEach(object => {
      const lines: Line2[] = object.getObjectsByProperty('isLine2', true) as Line2[];
      lines.forEach(line => this._disposeLine(object, line));
    });
  }

  private _getLineObjects(scene: Scene): Object3D[] {
    const planesGroup = scene.getObjectByName('planesGroup') as Group;
    return planesGroup.children.slice().filter(child => child.userData['hasOutline']);
  }

  private _prepareSceneForExport(lt: Object3D, removeFurniture = false, removeWalls = false): Scene {
    const controls = this._config.scene.children.filter(child => child['isTransformControls']);
    this._config.scene.children = this._config.scene.children.filter(child => !child['isTransformControls']);
    const sceneClone: Scene = _.cloneDeep(this._config.scene);
    controls.forEach(control => this._config.scene.add(control));

    const objectsGroup = sceneClone.getObjectByName('objectsGroup') as Group;

    if (removeFurniture) objectsGroup.clear();

    const lineObjects = this._getLineObjects(sceneClone);
    this._clearLinesInObjects(lineObjects);

    if (!lt) return sceneClone;

    objectsGroup.add(lt);

    const wallCornersGroup = sceneClone.getObjectByName('wallCornersGroup') as Group;
    const lightPointsGroup = sceneClone.getObjectByName('lightPointsGroup') as Group;
    const autopositionGroup = sceneClone.getObjectByName('autoPositionGroup') as Group;

    let planesGroup = sceneClone.getObjectByName('planesGroup') as Group;
    let shadowGroup = sceneClone.getObjectByName('shadowGroup') as Group;

    if (removeWalls) {
      return sceneClone.remove(shadowGroup).remove(wallCornersGroup).remove(lightPointsGroup).remove(planesGroup).remove(autopositionGroup);
    }

    const shadowExpansions = [];
    for (const child of shadowGroup.children) {
      if (child.userData['type'] === PlaneType.FLOOR_CONTINUE || child.userData['type'] === PlaneType.CEILING_CONTINUE) {
        shadowExpansions.push(child);
      }
    }

    shadowGroup = shadowGroup.remove(...shadowExpansions);

    const planesExpansions = [];
    for (const child of planesGroup.children) {
      if (child.userData['type'] === PlaneType.FLOOR_CONTINUE || child.userData['type'] === PlaneType.CEILING_CONTINUE) {
        planesExpansions.push(child);
      }
    }

    planesGroup = planesGroup.remove(...planesExpansions);

    return sceneClone;
  }

  public getExportedScene(lightTarget?: Object3D): Scene {
    const controls = this._config.scene.children.filter(child => child['isTransformControls']);
    this._config.scene.children = this._config.scene.children.filter(child => !child['isTransformControls']);

    const sceneClone: Scene = _.cloneDeep(this._config.scene);

    controls.forEach(control => this._config.scene.add(control));

    const objectsGroup = sceneClone.getObjectByName('objectsGroup') as Group;
    objectsGroup.clear();

    const lineObjects = this._getLineObjects(sceneClone);
    this._clearLinesInObjects(lineObjects);

    if (!lightTarget) {
      return sceneClone;
    }

    objectsGroup.add(lightTarget);
    const planesGroup = sceneClone.getObjectByName('planesGroup') as Group;
    const wallCornersGroup = sceneClone.getObjectByName('wallCornersGroup') as Group;
    const lightPointsGroup = sceneClone.getObjectByName('lightPointsGroup') as Group;
    const autopositionGroup = sceneClone.getObjectByName('autoPositionGroup') as Group;

    let shadowGroup = sceneClone.getObjectByName('shadowGroup') as Group;

    const expansions = [];
    for (const child of shadowGroup.children) {
      if (child.userData['type'] === PlaneType.FLOOR_CONTINUE || child.userData['type'] === PlaneType.CEILING_CONTINUE) {
        expansions.push(child);
      }
    }

    shadowGroup = shadowGroup.remove(...expansions);

    shadowGroup.children.forEach(child => {
      const material: Material = (child as Mesh).material as Material;

      if (child.name.includes(COLOR_WALL) && material.colorWrite) {
        const shadow = shadowGroup.children.find(
          secondChild => child.userData['id'] === secondChild.userData['id'] && secondChild.name !== COLOR_WALL
        );

        if (shadow) {
          shadowGroup.remove(shadow);
        }
      }

      if (child.name.includes(COLOR_WALL) && !material.colorWrite) {
        shadowGroup.remove(child);
      }
    });

    return sceneClone.remove(wallCornersGroup).remove(lightPointsGroup).remove(planesGroup).remove(autopositionGroup);
  }

  public exportSceneUSDZ(): void {
    const exporter = new USDZExporter();
    exporter.parse(this._config.scene).then(arrayBuffer => {
      const blob = new Blob([arrayBuffer], { type: 'application/octet-stream' });

      const link = document.createElement('a');
      link.style.display = 'none';
      document.body.appendChild(link);
      link.href = URL.createObjectURL(blob);
      link.download = 'scene.usdz';
      link.click();
    });
  }

  public exportSceneGlb(lt: Object3D): void {
    const scene = this._prepareSceneForExport(lt, false, true);

    this._gltfExporter.parse(
      scene,
      gltf => {
        const output = JSON.stringify(gltf, null, 2);
        const blob: Blob = new Blob([output]);
        const link = document.createElement('a');
        link.style.display = 'none';
        document.body.appendChild(link);

        link.href = URL.createObjectURL(blob);
        link.download = 'scene.gltf';
        link.click();
      },
      () => {}
    );
  }

  public exportSceneWithWalls(lt: Object3D): void {
    const scene = this._prepareSceneForExport(lt, false, false);
    this._gltfExporter.parse(
      scene,
      gltf => {
        const output = JSON.stringify(gltf, null, 2);
        const blob: Blob = new Blob([output]);
        const link = document.createElement('a');
        link.style.display = 'none';
        document.body.appendChild(link);

        link.href = URL.createObjectURL(blob);
        link.download = 'scene.gltf';
        link.click();
      },
      () => {}
    );
  }

  public exportScene(lt?): void {
    this._gltfExporter.parse(
      this.getExportedScene(lt),
      gltf => {
        const output = JSON.stringify(gltf, null, 2);
        const blob: Blob = new Blob([output]);
        const link = document.createElement('a');
        link.style.display = 'none';
        document.body.appendChild(link);

        link.href = URL.createObjectURL(blob);
        link.download = 'scene.gltf';
        link.click();
      },
      () => {}
    );
  }
}
