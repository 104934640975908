<div class="details-page-content">
  <mat-expansion-panel expanded="true"
  #moveObject hideToggle="true" class="expansion-panel">
   <mat-expansion-panel-header>
     <mat-panel-title>
       <p class="m-18">{{ "USER_FLOW.DETAILS_PAGE.MOVE_OBJECT" | translate }}</p>
     </mat-panel-title>
     <mat-panel-description>
       <img 
        *ngIf="!moveObject.expanded" 
        src="./../../../../assets/icons/Gruppe 3096.svg"
        alt="collapsed"
      >
       <img 
        *ngIf="moveObject.expanded" 
        src="./../../../../assets/icons/Gruppe 3096.svg"
        alt="expanded"
      >
     </mat-panel-description>
   </mat-expansion-panel-header>
   <div class="options">
     <div class="option-setting" *ngFor="let option of options">
       <button class="option-button disable-double-touch-zoom" mat-button longPress (click)="option.actionNegative()" (mouseLongPress)="option.actionNegative()">
         -
       </button>
       <img class="icon" src="{{option.iconSrc}}" alt="{{option.name}}">
       <button class="option-button disable-double-touch-zoom" mat-button longPress (click)="option.actionPositive()" (mouseLongPress)="option.actionPositive()">
         +
       </button>
     </div>
   </div>
   
   <div class="option-header">
     <span class="m-15">{{ "USER_FLOW.DETAILS_PAGE.ADJUST_OBJECT" | translate }}</span>
   </div>
   <div class="options">
     <div class="option-setting" *ngFor="let option of advancedOptions">
       <button class="option-button disable-double-touch-zoom" mat-button longPress (click)="option.actionNegative()" (mouseLongPress)="option.actionNegative()">
         -
       </button>
       <img class="icon" src="{{option.iconSrc}}" alt="{{option.name}}">
       <button class="option-button disable-double-touch-zoom" mat-button longPress (click)="option.actionPositive()" (mouseLongPress)="option.actionPositive()">
         +
       </button>
     </div>
     <div class="option-setting">
       <button class="option-button disable-double-touch-zoom" mat-button (click)="flip()">
         {{ "USER_FLOW.DETAILS_PAGE.FLIP" | translate }}
       </button>
     </div>
   </div>
  </mat-expansion-panel>
</div>
