import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, take, tap } from 'rxjs';
import { IAppState } from 'src/app/app.interface';
import { clearImageState } from 'src/app/store/actions/image.actions';

import { IProject } from '../../../models/project';
import { loadAllProjects, removeProject } from '../../store/actions/projects.actions';
import { projectsPageIndex, projectsPageSize, selectAllProjects, totalElements } from '../../store/selectors/projects.selector';

@UntilDestroy()
@Component({
  selector: 'app-current-user-projects-list',
  templateUrl: './current-user-projects-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrentUserProjectsListComponent implements OnInit {
  public projects$: Observable<IProject[]>;
  public totalElements$: Observable<number>;
  public size$: Observable<number>;
  public pageIndex$: Observable<number>;

  constructor(
    private _router: Router,
    private _store: Store<IAppState>
  ) {}

  public ngOnInit(): void {
    this._store.dispatch(clearImageState());
    this.projects$ = this._store.select(selectAllProjects);
    this.totalElements$ = this._store.select(totalElements);
    this.size$ = this._store.select(projectsPageSize);
    this.pageIndex$ = this._store.select(projectsPageIndex);
    this.pageIndex$
      .pipe(
        tap(index => this.getProjects({ page: index, size: 6, name: null })),
        untilDestroyed(this)
      )
      .subscribe();
  }

  public handleRemove(id: string): void {
    combineLatest([this.size$, this.pageIndex$, this.totalElements$])
      .pipe(take(1), untilDestroyed(this))
      .subscribe(data => {
        this._store.dispatch(removeProject({ id, size: data[0], index: data[1], total: data[2] }));
      });

    this.projects$ = this._store.select(selectAllProjects);
  }

  public handleOpen(project: IProject): void {
    this._router.navigate(['project/', project.id]);
  }

  public getProjects(data: { page: number; size: number; name: string }): void {
    if (data.name) {
      this._store.dispatch(loadAllProjects({ pageIndex: data.page, pageSize: data.size, name: data.name }));
    } else {
      this._store.dispatch(loadAllProjects({ pageIndex: data.page, pageSize: data.size }));
    }
    this.projects$ = this._store.select(selectAllProjects);
    this.totalElements$ = this._store.select(totalElements);
  }

  public createProjects(): void {
    this._router.navigate(['create', 'projects']);
  }
}
