import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Object3D } from 'three';

@Injectable()
export class HighlightedObjectsService {
  private _objects: BehaviorSubject<Object3D<THREE.Event>[]> = new BehaviorSubject([]);
  public objects = this._objects.asObservable();

  public setHighlightedObjects(objects: Object3D<THREE.Event>[]): void {
    this._objects.next(objects);
  }

  public clearHighlightedObjects(): void {
    this._objects.next([]);
  }
}
