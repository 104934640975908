<div *ngIf="variant" class="details-page-content">
  <div class="details-header">
    <a *ngIf="variant.BasicArticleInformation | link" class="title r-16" [href]="variant.BasicArticleInformation | link" target="_blank">{{ variant.BasicArticleInformation.Title }}</a>
    <p *ngIf="!(variant.BasicArticleInformation | link)" class="title r-16"><b>{{ variant.BasicArticleInformation.Title }}</b></p>
    <p class="type r-12">{{ "USER_FLOW.CATALOG." + variant.BasicArticleInformation.Type | translate }}</p>
  </div>

  <div class="image-wrapper furniture-container" *ngIf="variant.BasicArticleInformation.PackshotImages">
    <img 
      class="icon" 
      *ngIf="checkModelLinkExist(variant.BasicArticleInformation)"
      src="./../../../../assets/icons/noun-ar-2300882.svg"
      alt="3D"
    >
    <img class="image" [src]="activeImage | safe: 'url'" alt="active image" />
    <div *ngIf="variant.BasicArticleInformation.PackshotImages?.length > 1">
      <button class="icon-button previous" [disabled]="imageIndex-1<0"
        (click)="previous(variant.BasicArticleInformation.PackshotImages)">
        <img 
          *ngIf="imageIndex-1>=0;else leftDisabled" 
          class="arrow" 
          src="../../../assets/icons/Gruppe 3005.svg"
          alt="previous"
        >
      </button>
      <button class="icon-button next"
        [disabled]="imageIndex+1>=variant.BasicArticleInformation.PackshotImages.length"
        (click)="next(variant.BasicArticleInformation.PackshotImages)">
        <img 
          *ngIf="imageIndex+1<variant.BasicArticleInformation.PackshotImages.length;else rightDisabled"
          class="arrow transform" 
          src="../../../assets/icons/Gruppe 3005.svg"
          alt="next"
        >
      </button>
    </div>
  </div>

  <button class="button button-secondary add-button" mat-button (click)="setVariant(variant)">{{'BUTTONS.APPLY_VARIANT' | translate}}</button>
  <button class="button button-secondary add-button" mat-button (click)="addModel(variant)">{{ isAlreadyOnScene ? ('BUTTONS.DUPLICATE' | translate | uppercase) : ('BUTTONS.ADD' | translate) }}</button>

  <mat-expansion-panel #detailsPanel hideToggle="true" class="expansion-panel"
      *ngIf="variant.BasicArticleInformation.SKU">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <p class="m-18">{{ "USER_FLOW.DETAILS_PAGE.DETAILS" | translate }}</p>
        </mat-panel-title>
        <mat-panel-description>
          <img 
            *ngIf="!detailsPanel.expanded" 
            src="./../../../../assets/icons/Gruppe 3096.svg"
            alt="collapsed"
          >
          <img 
            *ngIf="detailsPanel.expanded" 
            src="./../../../../assets/icons/Gruppe 3096.svg"
            alt="expanded"
          >
        </mat-panel-description>
      </mat-expansion-panel-header>


      <p class="r-16">{{ description$ | async }}</p>
      <div>
        <p class="no-bottom-offset m-16">{{ "USER_FLOW.DETAILS_PAGE.SKU" | translate }}</p>
        <p class="no-top-offset r-16">{{ variant.BasicArticleInformation?.SKU }}</p>
      </div>

      <div>
        <p class="no-bottom-offset m-16">{{ "USER_FLOW.DETAILS_PAGE.COLOR" | translate }}</p>
        <p class="no-top-offset r-16">{{ variant.BasicArticleInformation | color }}</p>
      </div>

      <div>
        <p class="no-bottom-offset m-16">{{ "USER_FLOW.DETAILS_PAGE.SIZE" | translate }}</p>
        <p class="no-top-offset r-16">{{ variant.BasicArticleInformation | size }}</p>
      </div>

    </mat-expansion-panel>


  <button *ngIf="!this.resolutionService.isMobileResolution" class="close-button" (click)="close()">
    <mat-icon>close</mat-icon>
  </button>
</div>


<ng-template #leftDisabled>
  <img 
    class="arrow" 
    src="../../../assets/icons/Gruppe 3005 disabled.svg" 
    alt="left arrow disabled"
    >
</ng-template>

<ng-template #rightDisabled>
  <img 
    class="arrow transform" 
    src="../../../assets/icons/Gruppe 3005 disabled.svg"
    alt="right arrow disabled">
</ng-template>