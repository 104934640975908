<div class="page-container">
  <div class="header">
    <p class="title">{{ "AUTH.RESET_PASSWORD.TITLE" | translate}}</p>
  </div>
    <form [formGroup]="form" class="page-content">
      <p>
        <app-form-field
          name="newPassword"
          label="AUTH.RESET_PASSWORD.LABEL"
          type="text"
          [abstractControl]="form.get('newPassword')"
        ></app-form-field>
      </p>
      <div class="button-wrapper">
        <button class="button" color="primary" type="submit" mat-raised-button (click)="submit()">{{ "AUTH.RESET_PASSWORD.CHANGE" | translate}}</button>
      </div>
    </form>
</div>