import { routerReducer } from '@ngrx/router-store';
import { ActionReducerMap, MetaReducer } from '@ngrx/store';

import { sharedReducer } from './reducers/shared.reducers';
import { SHARED_STATE_NAME } from './selectors/shared.selector';
import { IAppState } from '../app.interface';

export const reducers: ActionReducerMap<IAppState> = {
  router: routerReducer,
  [SHARED_STATE_NAME]: sharedReducer,
};

export const metaReducers: MetaReducer<IAppState>[] = [];
