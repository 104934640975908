import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as NCSColor from 'ncs-color';
import { catchError, combineLatest, filter, forkJoin, lastValueFrom, map, Observable, of, switchMap, take, tap } from 'rxjs';
import { getCurrentAction } from 'src/app/undo-redo/undo-redo.selectors';
import { Style } from 'src/app/user-flow/enum/style.enum';
import { StylefinderResponse } from 'src/app/user-flow/interfaces/stylefinder';
import { HighlightedObjectsService } from 'src/app/user-flow/services/highlighted-objects.service';
import { LightObjectsService } from 'src/app/user-flow/services/light-objects.service';
import { DataStoreService } from 'src/services/data-store.service';
import { Article, BasicArticleInformation, CatalogData, ProposalResponse } from 'src/services/model/proposal';
import { Vector3 } from 'three';
import { v4 as uuidv4 } from 'uuid';

import { IProjectAdditionalInfo } from '../../../models/project';
import {
  IAdvancedSearchCriteria,
  IColor,
  IProposal,
  ProposalArticle,
  ProposalArticleReplaceByType,
  ProposalArticleReplaceResponse,
  ProposalState,
  ProposedProposal,
  SearchCriteria,
  UpdatedProposalTO,
  UpdateProposal,
} from '../../../models/proposal';
import { DetectObjHttpService } from '../../../services/detect-obj-http.service';
import { FurnitureHttpService } from '../../../services/furniture-http.service';
import { ISaveVersion } from '../../../services/model/project-version';
import { ISaveProject, ProjectsVersionsHttpService } from '../../../services/projects-versions-http.service';
import { ResourcesHttpService } from '../../../services/resources-http.service';
import { clearUndoRedoState } from '../../undo-redo/undo-redo.actions';
import { ColorGroup } from '../../user-flow/catalog/color-select-container/color-group.enum';
import { colorGroups } from '../../user-flow/catalog/color-select-container/colors';
import { NcsColor } from '../../user-flow/catalog/color-select-container/ncs-color.model';
import { LeftPanelState } from '../../user-flow/catalog/left-panel-container/left-panel-state.enum';
import { FurnitureType } from '../../user-flow/enum/furniture-types.enum';
import { RoomType } from '../../user-flow/enum/room-type.enum';
import { advancedSearchCriteria } from '../../user-flow/render/constants/advanced-options';
import { BEDROOM_SET, BEDROOM_SMALL_SET } from '../../user-flow/render/constants/bedroom-set';
import { DINING_ROOM_SET, DINING_ROOM_SMALL_SET } from '../../user-flow/render/constants/dining-room-set';
import { LIVING_ROOM_FULL_SET, LIVING_ROOM_MEDIUM_SET } from '../../user-flow/render/constants/living-room-set';
import { PlaneType } from '../../user-flow/services/enum/plane-type.enum';
import { ExportService } from '../../user-flow/services/export.service';
import { ImportService } from '../../user-flow/services/import.service';
import { MovingObject, MovingObjectInfo } from '../../user-flow/services/model/dto/moving-object.model';
import { MovingObjectsService } from '../../user-flow/services/moving-objects.service';
import { PhotorealisticRenderService } from '../../user-flow/services/photorealistic-render.service';
import { PlanesService } from '../../user-flow/services/planes.service';
import { ReconstructionStateService } from '../../user-flow/services/reconstruction-state.service';
import { RoomService } from '../../user-flow/services/room.service';
import { SharedActions } from '../action-types';
import { updateProjects } from '../actions/projects.actions';
import { changeWallColor } from '../actions/render.actions';
import {
  addAccessory,
  clearAlternatives,
  clearFurnitureDetails,
  deleteAccessory,
  getAlternativesSuccess,
  getUser,
  makeProposalSuccess,
  reconstructSuccess,
  refreshProposal,
  refreshType,
  replaceAccessory,
  replaceTypeSuccess,
  replaceWithVariantSuccess,
  saveProjectSuccess,
  setActiveSKU,
  setCatalogData,
  setLeftPanelState,
  setOverlayLoadingSpinner,
  setProposal,
  setRenderLoadingSpinner,
  setUnsavedChanges,
  updateActiveProject,
  updateCatalogData,
  userFlowSuccess,
  viewFurniture,
} from '../actions/shared.actions';
import {
  activeProjectFile,
  activeProjectHandledBackgroundFile,
  activeProjectId,
  activeProjectName,
  activeProjectProposalData,
  activeProjectRender,
  proposal,
  proposedFurnitureColors,
  proposedFurnitureColorsLoaded,
} from '../selectors/shared.selector';

@UntilDestroy()
@Injectable()
export class RenderGUIEffects {
  public runUserFlow$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SharedActions.runUserFlow),
      tap(value => {
        const text =
          value.roomType === RoomType.UNSET ? 'USER_FLOW.LABELS.RUN_USER_FLOW_LOADER_EMPTY_ROOM' : 'USER_FLOW.LABELS.RUN_USER_FLOW_LOADER';
        this._store.dispatch(setRenderLoadingSpinner({ status: true, text }));
      }),
      switchMap(() => this._proposalPart(true)),
      switchMap(value => this._saveProjectPart().pipe(map(() => value))),
      map(({ shouldAutoPosition, state }) =>
        shouldAutoPosition
          ? {
              positioningResult: this._roomService.handleAutopositioning(),
              state,
            }
          : { positioningResult: false, state }
      ),
      tap(({ state }) => this._store.dispatch(setProposal({ data: this._syncMovingObjectsWithProposal(state) }))),
      switchMap(({ positioningResult }) =>
        this._saveVersionPart().pipe(
          map(() => {
            if (positioningResult) {
              this._photorealisticRenderService.render(this);
            }
            this._store.dispatch(setRenderLoadingSpinner({ status: false, text: '' }));
            return userFlowSuccess();
          })
        )
      )
    )
  );

  public reconstruct$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SharedActions.reconstruct),
      tap(() => this._store.dispatch(setOverlayLoadingSpinner({ status: true }))),
      switchMap(() =>
        this._store.select(activeProjectFile).pipe(
          filter(data => !!data),
          take(1)
        )
      ),
      switchMap((file: File) => this._detectObjHttpService.detectInterior(file)),
      switchMap(data => {
        this._store.dispatch(setUnsavedChanges({ state: true }));
        this._roomService.handleInteriorResults(data);
        return this._detectObjPart().pipe(map(() => reconstructSuccess({ data })));
      }),
      tap(() => this._store.dispatch(setOverlayLoadingSpinner({ status: false })))
    )
  );

  public makeProposal$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SharedActions.makeProposal),
      tap(() =>
        this._store.dispatch(
          setRenderLoadingSpinner({
            status: true,
            text: 'USER_FLOW.LABELS.PROPOSAL_UPDATE',
          })
        )
      ),
      switchMap(() => this._proposalPart(false)),
      map(({ data, shouldAutoPosition, state, oldProposal, oldMovingObjects }) => {
        if (shouldAutoPosition) {
          this._roomService.handleAutopositioning();
        }

        const syncedState = this._syncMovingObjectsWithProposal(state);
        this._store.dispatch(setProposal({ data: syncedState }));

        const keyBefore = uuidv4();
        const keyAfter = uuidv4();

        this._dataStoreService.addProposalMovingObjects(keyBefore, [...oldMovingObjects]);
        this._dataStoreService.addProposalMovingObjects(keyAfter, [...this._movingObjectsService.movingObjects]);

        this._store.dispatch(
          refreshProposal({
            data: {
              proposalSnapshotAfter: syncedState,
              proposalSnapshotBefore: oldProposal,
              keyForFurnitureAfter: keyAfter,
              keyForFurnitureBefore: keyBefore,
            },
          })
        );

        // closes left panel and clears furniture details
        this._store.dispatch(clearFurnitureDetails());
        this._store.dispatch(setLeftPanelState({ state: false }));

        this._store.dispatch(setRenderLoadingSpinner({ status: false, text: '' }));
        this._store.dispatch(setUnsavedChanges({ state: true }));
        return makeProposalSuccess({ data });
      })
    )
  );

  public _saveProjectPart(): Observable<ISaveProject> {
    return this._store.select(activeProjectName).pipe(
      take(1),
      switchMap(name => this._projectsVersionsHttpService.saveProject(name)),
      tap(() => this._store.dispatch(updateProjects())),
      tap(() => this._store.dispatch(getUser())),
      tap(data => this._store.dispatch(saveProjectSuccess({ name: data.name, id: data.id })))
    );
  }

  private _saveVersionPart(): Observable<any> {
    return this._store.select(activeProjectHandledBackgroundFile).pipe(
      take(1),
      switchMap((file: File) =>
        !file
          ? this._store.select(activeProjectFile).pipe(
              filter((file: File) => !!file),
              take(1)
            )
          : of(file)
      ),
      switchMap((file: File) => {
        return this._reconstructionStateService.getSaveData().pipe(map(data => ({ data, file })));
      }),
      switchMap(({ file, data }) => {
        const result1$ = this._resourcesHttpService.uploadResource(file);
        const result2$ = this._exportService.createSceneFile(data).pipe(
          switchMap((file: File) => {
            return this._resourcesHttpService.uploadResource(file);
          })
        );
        const stylefinder$ = this._store.select(activeProjectProposalData);
        const render$ = this._store.select(activeProjectRender);
        const projectId$ = this._store.select(activeProjectId);

        return combineLatest([result1$, result2$, stylefinder$, render$, projectId$]).pipe(take(1));
      }),
      switchMap((res: any) => {
        const data: ISaveVersion = {
          image: res[0].id,
          model: res[1].id,
          type: res[2].type,
          style: res[2].style,
          budget: res[2].budget,
          name: res[2].versionName,
        };
        return this._projectsVersionsHttpService.saveVersion(data, res[4]).pipe(map(version => ({ version, projectId: res[4] })));
      }),
      tap(data => {
        const projectInfo: IProjectAdditionalInfo = {};
        projectInfo.id = data.projectId;
        projectInfo.versionId = data.version.id;
        this._location.replaceState(
          window.location.pathname,
          new URLSearchParams({
            projectId: data.projectId,
            versionId: data.version.id,
          }).toString()
        );
        this._store.dispatch(updateActiveProject({ projectInfo }));
        this._store.dispatch(clearUndoRedoState());
        this._dataStoreService.clearAll();
      })
    );
  }

  private _detectObjPart(): Observable<any> {
    return this._store.select(activeProjectFile).pipe(
      filter((file: File) => !!file),
      take(1),
      switchMap((file: File) => this._detectObjHttpService.detectObj(file, 'clarifai')),
      switchMap(detectObjData => this._roomService.handleWindowsResult(detectObjData, false)),
      tap(detectObjData => {
        this._roomService.saveWindowsResult(detectObjData);
        this._store.dispatch(setUnsavedChanges({ state: true }));
      })
    );
  }

  private _getProposalRequest(): Observable<{
    type: string;
    style: string;
    budget: number;
  }> {
    return this._store.select(activeProjectProposalData).pipe(
      take(1),
      filter(data => !!data)
    );
  }

  private _updateProposalRequest(): Observable<[{ type: string; style: string; budget: number }, ProposalState]> {
    return combineLatest(this._store.select(activeProjectProposalData), this._store.select(proposal)).pipe(
      take(1),
      filter(data => !!data[0] && !!data[1])
    );
  }

  private _updateProposalDto(search: IAdvancedSearchCriteria, stylefinderResponse?: StylefinderResponse): Observable<UpdateProposal> {
    return this._updateProposalRequest().pipe(
      map(data => {
        const proposalData: UpdateProposal = {
          proposalState: data[1],
          searchCriteria: {
            basicSearchCriteria: {
              availableBudget: data[0].budget,
              floorType: 'OAK_BRIGHT',
              numberOfChairs: null,
              preferredDeliveryTimeOption: 'NO_CONSTRAINTS',
              preferredDesignStyle: data[0].style as Style,
            },
            advancedSearchCriteria: {
              ...search,
              primaryColors: stylefinderResponse ? this._preparePrimaryColors(stylefinderResponse.dominantColors) : null,
            },
          },
          updateProposalTO: {
            IsWallPaintChosen: false,
            ChoosenArticleSkus: [],
          },
        };
        return proposalData;
      })
    );
  }

  private _getProposalDto(
    articleTypes: string[],
    search: IAdvancedSearchCriteria,
    stylefinderResponse?: StylefinderResponse
  ): Observable<IProposal> {
    return this._getProposalRequest().pipe(
      map(data => {
        const proposalData: IProposal = {
          existingArticleSkus: [],
          newProposalTO: {
            DesiredArticleTypes: articleTypes,
          },
          searchCriteria: {
            basicSearchCriteria: {
              availableBudget: data.budget,
              floorType: 'OAK_BRIGHT',
              numberOfChairs: null,
              preferredDeliveryTimeOption: 'NO_CONSTRAINTS',
              preferredDesignStyle: data.style as Style,
            },
            advancedSearchCriteria: {
              ...search,
              primaryColors: stylefinderResponse ? this._preparePrimaryColors(stylefinderResponse.dominantColors) : null,
            },
          },
        };
        return proposalData;
      })
    );
  }

  private _preparePrimaryColors(dominantColors: string[]): IColor[] {
    return dominantColors.map(el => {
      return {
        Hue: el,
        IsColorGroup: false,
        Material: null,
        Nuance: null,
      };
    });
  }

  private _calcLockedState(item: Article, lockedItems: ProposalArticle[], includedSku?: string): boolean {
    if (includedSku && item.Article.BasicArticleInformation.SKU === includedSku) {
      return false;
    }
    return lockedItems?.find(el => el.Sku === item.Article.BasicArticleInformation.SKU) ? true : false;
  }

  private _prepareProposalStateData(proposal: ProposalResponse, oldProposal?: ProposalState, includedSku?: string): ProposalState {
    const lockedItems = oldProposal?.ProposedProposal?.Articles?.filter(el => el.IsLocked);
    const articlesInfo = proposal.Articles.map(item => {
      return {
        IsExternal: false,
        IsLocked: this._calcLockedState(item, lockedItems, includedSku),
        NumberOfExemplars: item.Article.BasicArticleInformation.NumberOfExemplars,
        ProposalSlotId: item.ProposalSlotId,
        Sku: item.Article.BasicArticleInformation.SKU,
        Type: item.Article.BasicArticleInformation.Type,
      };
    });

    const proposedProposal: ProposedProposal = {
      Articles: articlesInfo,
      Floor: proposal.Floor,
      PrimaryColors: proposal.PrimaryColors,
      ProposalId: proposal.ProposalId,
      WallPaint: proposal.WallPaint,
    };
    return {
      ProposedProposal: proposedProposal,
      ExternalArticlesMetaData: [],
    };
  }

  private _prepareSearchCriteria(stylefinderResponse?: StylefinderResponse): SearchCriteria {
    return {
      basicSearchCriteria: {
        availableBudget: null,
        floorType: 'OAK_BRIGHT',
        numberOfChairs: null,
        preferredDeliveryTimeOption: 'NO_CONSTRAINTS',
        preferredDesignStyle: 'NO_STYLE' as Style,
      },
      advancedSearchCriteria: {
        ...advancedSearchCriteria,
        primaryColors: stylefinderResponse ? this._preparePrimaryColors(stylefinderResponse.dominantColors) : null,
      },
    };
  }

  private _newProposal(
    articleTypes: string[],
    search: IAdvancedSearchCriteria
  ): Observable<{ newProposal: ProposalResponse; oldProposal: ProposalState }> {
    return this._store.select(proposedFurnitureColorsLoaded).pipe(
      filter(loaded => !!loaded),
      switchMap(() => this._store.select(proposedFurnitureColors)),
      take(1),
      switchMap(data =>
        this._getProposalDto(articleTypes, search, data).pipe(map(proposalData => ({ proposalData, proposedFurniture: data })))
      ),
      switchMap(data =>
        this._furnitureHttpService.getNewProposal(data.proposalData).pipe(
          map(proposal => ({
            proposal,
            proposedFurniture: data.proposedFurniture,
          }))
        )
      ),
      switchMap(async data => {
        const typesSkus = data.proposal.Articles.map(article => ({
          type: article.Article.BasicArticleInformation.Type as string,
          sku: article.Article.BasicArticleInformation.SKU,
        }));
        const proposalStateData = this._prepareProposalStateData(data.proposal);
        const searchCriteria = this._prepareSearchCriteria(data.proposedFurniture);

        const fullResponse: ProposalResponse = data.proposal;
        for (const item of data.proposedFurniture.articles) {
          const replaceItem = typesSkus.find(el => el.type === item.type);

          if (replaceItem && replaceItem.sku !== item.sku) {
            const replaceResult = await lastValueFrom(
              this._furnitureHttpService.proposalReplaceSKU({
                articleSkuToReplace: replaceItem.sku,
                newArticleSku: item.sku,
                proposalState: proposalStateData,
                searchCriteria,
              })
            );

            fullResponse.Articles = replaceResult.UpdatedProposalTO.Articles;

            proposalStateData.ProposedProposal.Articles = replaceResult.UpdatedProposalTO.Articles.map(item => ({
              IsExternal: false,
              IsLocked: false,
              NumberOfExemplars: item.Article.BasicArticleInformation.NumberOfExemplars,
              ProposalSlotId: item.ProposalSlotId,
              Sku: item.Article.BasicArticleInformation.SKU,
              Type: item.Article.BasicArticleInformation.Type,
            }));
          }
        }

        return { newProposal: fullResponse, oldProposal: null };
      })
    );
  }

  private _refreshProposal(search: IAdvancedSearchCriteria): Observable<{ newProposal: ProposalResponse; oldProposal: ProposalState }> {
    return this._store.select(proposedFurnitureColors).pipe(
      filter(data => !!data),
      take(1),
      switchMap(data => this._updateProposalDto(search, data)),
      switchMap(proposalData =>
        this._furnitureHttpService.updateProposal(proposalData).pipe(
          map(newProposal => ({
            newProposal,
            oldProposal: proposalData.proposalState,
          }))
        )
      )
    );
  }

  private _getRoomArticleTypes(hasWindows?: boolean): Observable<{
    articleTypes: string[];
    search: IAdvancedSearchCriteria;
    type: RoomType;
  }> {
    return this._store.select(activeProjectProposalData).pipe(
      take(1),
      filter(data => !!data),
      map(data => {
        const mainWallWidth = this._planesService.chooseMainWall();
        const sideTable =
          data.type === 'LIVING_ROOM'
            ? {
                ...advancedSearchCriteria.sideTable[0],
                diameterInCm: 70,
                lengthInCm: 100,
                isHardConstraint: false,
              }
            : {
                ...advancedSearchCriteria.sideTable[0],
                diameterInCm: 60,
                lengthInCm: 60,
                widthInCm: 60,
                isHardConstraint: true,
              };

        const search = mainWallWidth
          ? {
              ...advancedSearchCriteria,
              sofa: [
                {
                  ...advancedSearchCriteria.sofa[0],
                  widthInCm: mainWallWidth * 100 * 0.75,
                  isHardConstraint: mainWallWidth < 2.5 ? true : false,
                },
              ],
              carpet: [
                {
                  ...advancedSearchCriteria.carpet[0],
                  diameterInCm: mainWallWidth * 100 * 0.7,
                  widthInCm: mainWallWidth * 100 * 0.7,
                  lengthInCm: mainWallWidth * 100 * 0.7,
                  isHardConstraint: mainWallWidth < 2.5 ? true : false,
                },
              ],
              diningTable: [
                {
                  ...advancedSearchCriteria.diningTable[0],
                  diameterInCm: mainWallWidth * 100 * 0.7,
                  widthInCm: mainWallWidth * 100 * 0.7,
                  lengthInCm: mainWallWidth * 100 * 0.7,
                  isHardConstraint: mainWallWidth < 2.5 ? true : false,
                },
              ],
              sideTable: [sideTable],
              painting: [
                {
                  ...advancedSearchCriteria.painting[0],
                  heightInCm: 70,
                  isHardConstraint: false,
                },
                {
                  ...advancedSearchCriteria.painting[0],
                  heightInCm: 70,
                  isHardConstraint: false,
                },
              ],
            }
          : advancedSearchCriteria;

        const isSmallRoom = mainWallWidth * 100 < 200;
        const isMediumRoom = mainWallWidth * 100 > 200 && mainWallWidth * 100 < 250;

        let articleTypes: string[] = [];

        if (hasWindows) {
          articleTypes = [FurnitureType.CURTAIN];
        }

        switch (data.type) {
          case 'LIVING_ROOM': {
            if (isMediumRoom || isSmallRoom) {
              articleTypes = [...articleTypes, ...LIVING_ROOM_MEDIUM_SET];
            } else {
              articleTypes = [...articleTypes, ...LIVING_ROOM_FULL_SET];
            }
            break;
          }
          case 'DINING_ROOM': {
            if (isSmallRoom || isMediumRoom) {
              articleTypes = [...articleTypes, ...DINING_ROOM_SMALL_SET];
            } else {
              articleTypes = [...articleTypes, ...DINING_ROOM_SET];
            }
            break;
          }
          case 'SLEEPING_ROOM': {
            if (isSmallRoom || isMediumRoom) {
              articleTypes = [...articleTypes, ...BEDROOM_SMALL_SET];
            } else {
              articleTypes = [...articleTypes, ...BEDROOM_SET];
            }
            break;
          }
          default: {
            articleTypes = [];
            break;
          }
        }

        if (data.accessories?.length) {
          const accessoriesTypes = data.accessories
            .filter(accessory => !accessory.lock)
            .map(accessory => accessory.BasicArticleInformation.Type);
          articleTypes = [...articleTypes, ...accessoriesTypes];
        }

        articleTypes = articleTypes.filter(
          articleType => !this._movingObjectsService.movingObjects.find(mo => mo.type === articleType)?.lock
        );

        return { articleTypes, search, type: data.type as RoomType };
      })
    );
  }

  private _proposalPart(isNewProposal: boolean): Observable<{
    data: ProposalResponse;
    shouldAutoPosition: boolean;
    state: ProposalState;
    oldProposal: ProposalState;
    oldMovingObjects: MovingObject[];
  }> {
    const hasWindows = this._planesService.getWindows().length > 0;
    return this._getRoomArticleTypes(hasWindows).pipe(
      switchMap(({ articleTypes, search, type }) => {
        const proposal$ = isNewProposal ? this._newProposal(articleTypes, search) : this._refreshProposal(search);

        return proposal$.pipe(
          map(response => {
            let oldToNewSKUs: { [p: string]: string };
            let newSKUCount: { [p: string]: number };

            let proposalResponse: ProposalResponse;

            if (response.oldProposal) {
              oldToNewSKUs = {};
              newSKUCount = {};

              const oldArticlesSorted = response.oldProposal.ProposedProposal.Articles.slice().sort((a1, a2) =>
                a1.Type > a2.Type ? 1 : -1
              );
              const newArticlesSorted = response.newProposal.Articles.slice().sort((a1, a2) =>
                a1.Article.BasicArticleInformation.Type > a2.Article.BasicArticleInformation.Type ? 1 : -1
              );

              const oldArticlesLocked: ProposalArticle[] = [];
              const oldArticles: ProposalArticle[] = [];

              for (const oldArticle of oldArticlesSorted) {
                oldArticle.IsLocked ? oldArticlesLocked.push(oldArticle) : oldArticles.push(oldArticle);
              }

              const lockedItemsSku = oldArticlesLocked.map(item => item.Sku);

              const newArticles: Article[] = [];
              const newArticlesLocked: Article[] = [];

              for (const newArticle of newArticlesSorted) {
                lockedItemsSku.includes(newArticle.Article.BasicArticleInformation.SKU)
                  ? newArticlesLocked.push(newArticle)
                  : newArticles.push(newArticle);
              }

              for (let i = 0; i < newArticles.length; i++) {
                const oldSku = oldArticles[i].Sku;
                const newSku = newArticles[i].Article.BasicArticleInformation.SKU;
                oldToNewSKUs[oldSku] = newSku;
                newSKUCount[newSku] = oldArticles[i].NumberOfExemplars;
              }

              for (let i = 0; i < newArticlesLocked.length; i++) {
                const oldSku = oldArticlesLocked[i].Sku;
                const newSku = newArticlesLocked[i].Article.BasicArticleInformation.SKU;
                oldToNewSKUs[oldSku] = newSku;
                newSKUCount[newSku] = oldArticlesLocked[i].NumberOfExemplars;
              }
            }

            // resets count of refreshed items
            if (newSKUCount) {
              proposalResponse = {
                ...response.newProposal,
                Articles: response.newProposal.Articles.map(a => {
                  return {
                    ...a,
                    Article: {
                      ...a.Article,
                      BasicArticleInformation: {
                        ...a.Article.BasicArticleInformation,
                        NumberOfExemplars: newSKUCount[a.Article.BasicArticleInformation.SKU],
                      },
                    },
                  };
                }),
              };
            }

            proposalResponse = proposalResponse ?? response.newProposal;

            const proposalState = this._prepareProposalStateData(proposalResponse, response.oldProposal);

            return {
              response: proposalResponse,
              roomType: type,
              oldToNewSKUs,
              proposalState,
              oldProposal: response.oldProposal,
            };
          })
        );
      }),
      switchMap(({ response, roomType, oldToNewSKUs, proposalState, oldProposal }) => {
        const oldMovingObjects = this._movingObjectsService.deleteAllFurniture(true);
        const searchCriteria = this._prepareSearchCriteria();
        const requests$: Observable<{
          movingObject: MovingObject;
          model: BasicArticleInformation;
          initialModel: BasicArticleInformation;
        }>[] = [];
        const requestsToReplaceMultiple$: Observable<{
          movingObjects: MovingObject[];
          model: BasicArticleInformation;
          initialModel: BasicArticleInformation;
        }>[] = [];

        response?.Articles.forEach(article => {
          const newSku = article.Article.BasicArticleInformation.SKU;
          const replaceByType: ProposalArticleReplaceByType = {
            articleSkuToReplace: newSku,
            viewedArticles: [
              {
                ArticleSku: newSku,
                VariantSkus: article.Article.Variants.map(el => el.BasicArticleInformation.SKU),
              },
            ],
            searchCriteria,
            proposalState: this._prepareProposalStateData(response),
          };

          if (oldToNewSKUs) {
            // each of moving objects is replaced by one type
            const movingObjectsInfoToReplace = oldMovingObjects
              .slice()
              .filter(mo => oldToNewSKUs[mo.SKU] === newSku)
              .map(o => ({
                id: o.id,
                position: o.object3D.position.clone(),
                rotation: new Vector3(o.object3D.rotation.x, o.object3D.rotation.y, o.object3D.rotation.z),
              }));

            if (movingObjectsInfoToReplace.length > 0) {
              requestsToReplaceMultiple$.push(
                this._loadModelForObjects(article.Article.BasicArticleInformation, false, replaceByType, movingObjectsInfoToReplace)
              );
            }
          } else {
            const request$ = this._loadModel(article.Article.BasicArticleInformation, false, replaceByType);

            requests$.push(request$);
          }
        });

        if (roomType !== RoomType.UNSET) {
          if (!isNewProposal) {
            this._colorColoredWalls(response);
          }
        }

        // requests$ array length > 0 => new proposal
        // requestsToReplaceMultiple$ array length > 0 => refresh proposal
        if (requestsToReplaceMultiple$.length > 0) {
          return forkJoin(requestsToReplaceMultiple$).pipe(
            map(replacingResult => {
              return {
                data: response,
                shouldAutoPosition: roomType !== RoomType.UNSET,
                state: this._replaceSkusForRecursivelyUpdatedArticles(proposalState, replacingResult),
                oldProposal,
                oldMovingObjects,
              };
            })
          );
        }

        return requests$.length > 0
          ? forkJoin(requests$).pipe(
              map(replacingResult => {
                return {
                  data: response,
                  shouldAutoPosition: roomType !== RoomType.UNSET,
                  state: this._replaceSkusForRecursivelyUpdatedArticles(proposalState, replacingResult),
                  oldProposal,
                  oldMovingObjects,
                };
              })
            )
          : of({
              oldProposal,
              data: response,
              shouldAutoPosition: roomType !== RoomType.UNSET,
              state: proposalState,
              oldMovingObjects,
            });
      })
    );
  }

  private _replaceSkusForRecursivelyUpdatedArticles(
    state: ProposalState,
    replacingResult: {
      model: BasicArticleInformation;
      initialModel: BasicArticleInformation;
    }[]
  ): ProposalState {
    // we need to update skus values for proposalState
    // because loadModel is recursively getting new sku in case we could not load model from initial proposal
    const changedSkusModels = replacingResult.filter(item => item.initialModel.SKU !== item.model.SKU);
    const alteredSkus = changedSkusModels.map(item => item.initialModel.SKU);
    const updatedArticles: ProposalArticle[] = [];
    for (const article of state.ProposedProposal.Articles) {
      if (alteredSkus.includes(article.Sku)) {
        const updatedSku = changedSkusModels.find(item => item.initialModel.SKU === article.Sku).model.SKU;
        updatedArticles.push({ ...article, Sku: updatedSku });
      } else {
        updatedArticles.push(article);
      }
    }
    state.ProposedProposal.Articles = [...updatedArticles];
    return state;
  }

  private _syncMovingObjectsWithProposal(state: ProposalState): ProposalState {
    const newState = { ...state };
    const skuCount: { [sku: string]: number } = {};
    this._movingObjectsService.movingObjects.forEach(mo => {
      if (skuCount[mo.SKU]) {
        skuCount[mo.SKU] = skuCount[mo.SKU] + 1;
      } else {
        skuCount[mo.SKU] = 1;
      }
    });

    newState.ProposedProposal.Articles = newState.ProposedProposal.Articles.map(article => ({
      ...article,
      NumberOfExemplars: skuCount[article.Sku],
    }));

    return newState;
  }

  private _colorColoredWalls(response: ProposalResponse): void {
    const ncs = this._getColor(response);

    this._planesService
      .getAll()
      .filter(plane => plane.type === PlaneType.WALL)
      .forEach(plane => {
        if (plane.colorData?.color && plane.colorData.color.Group !== ColorGroup.NO_COLOR && !plane.colorData?.colorFixed) {
          this._planesService.colorWalls(plane, {
            color: ncs,
            colorFixed: false,
          });
        }
      });
  }

  private _getColor(response: ProposalResponse): NCSColor {
    const b = response.WallPaint.Ncs.Nuance.Blackness;
    const c = response.WallPaint.Ncs.Nuance.Chromaticness;
    const hue = response.WallPaint.Ncs.Hue;

    let ncs: NcsColor;
    const code = `${b < 10 ? `0${b}` : b}${c < 10 ? `0${c}` : c}-${hue}`;
    const group = Object.keys(colorGroups).find(group => !!colorGroups[group].find(color => color.Code === code));

    if (group) {
      ncs = colorGroups[group].find(color => color.Code === code);
    } else {
      ncs = {
        Code: code,
        Group: ColorGroup.CUSTOM,
        Hex: NCSColor.hex(code),
      };
    }

    return ncs;
  }

  private _colorMainWall(response: ProposalResponse): void {
    const ncs = this._getColor(response);

    this._planesService.chooseMainWall();

    const mainWall = this._planesService.getMainWall();

    if (mainWall) {
      this._store.dispatch(
        changeWallColor({
          data: { id: mainWall.id, from: mainWall.colorData.color, to: ncs },
        })
      );
      this._planesService.colorWalls(mainWall, {
        color: ncs,
        colorFixed: false,
      });
    }
  }

  public replaceType$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SharedActions.replaceType),
      tap(() => this._store.dispatch(setOverlayLoadingSpinner({ status: true }))),
      switchMap(action =>
        this._store.select(proposal).pipe(
          take(1),
          map(proposal => ({ proposal, data: action.data }))
        )
      ),
      switchMap(data => {
        const searchCriteria = this._prepareSearchCriteria();
        const replaceByType: ProposalArticleReplaceByType = {
          articleSkuToReplace: data.data.articleSkuToReplace,
          viewedArticles: data.data.viewedArticles,
          searchCriteria,
          proposalState: data.proposal,
        };
        return this._furnitureHttpService.proposalReplaceType(replaceByType).pipe(
          map(proposal => ({
            proposal,
            object: data.data,
            replaceByType,
            oldProposal: data.proposal,
          }))
        );
      }),
      switchMap(result => {
        const newArticle = result.proposal.UpdatedProposalTO.Articles.find(
          article => article.Article.BasicArticleInformation.SKU === result.proposal.NewArticleSku
        );
        const oldMovingObjects: MovingObject[] = this._movingObjectsService.movingObjects.filter(
          obj => obj.SKU === result.object.articleSkuToReplace
        );

        const objectsToReplaceInfo: MovingObjectInfo[] = oldMovingObjects.slice().map(o => ({
          id: o.id,
          position: o.object3D.position.clone(),
          rotation: new Vector3(o.object3D.rotation.x, o.object3D.rotation.y, o.object3D.rotation.z),
        }));

        return this._loadModelForObjects(
          newArticle.Article.BasicArticleInformation,
          false,
          result.replaceByType,
          objectsToReplaceInfo
        ).pipe(
          map(data => {
            return {
              oldObjects: oldMovingObjects,
              newObjects: data.movingObjects,
              newModel: data.model,
              proposal: data.repeatedResponse ?? result.proposal,
              oldProposal: result.oldProposal,
            };
          })
        );
      }),
      map(data => {
        const newSku = data.proposal.NewArticleSku;
        const article = data.proposal.UpdatedProposalTO.Articles.find(article => article.Article.BasicArticleInformation.SKU === newSku);
        const updatedProposal: UpdatedProposalTO = {
          ...data.proposal.UpdatedProposalTO,
          Articles: [
            ...data.proposal.UpdatedProposalTO.Articles.filter(article => article.Article.BasicArticleInformation.SKU !== newSku),
            {
              ...article,
              Article: {
                ...article.Article,
                BasicArticleInformation: {
                  ...article.Article.BasicArticleInformation,
                  NumberOfExemplars: data.newObjects.length,
                },
              },
            },
          ],
        };

        const proposal = this._prepareProposalStateData(updatedProposal, data.oldProposal);

        article.Article.BasicArticleInformation.NumberOfExemplars = data.newObjects.length;

        this._store.dispatch(setProposal({ data: proposal }));

        const keyBefore = uuidv4();
        const keyAfter = uuidv4();

        this._dataStoreService.addProposalMovingObjects(keyBefore, [...data.oldObjects]);
        this._dataStoreService.addProposalMovingObjects(keyAfter, [...data.newObjects]);

        this._store.dispatch(
          refreshType({
            data: {
              proposalSnapshotAfter: proposal,
              proposalSnapshotBefore: data.oldProposal,
              keyForFurnitureAfter: keyAfter,
              keyForFurnitureBefore: keyBefore,
            },
          })
        );

        this._roomService.changeSelectedMovingObject(data.newObjects.at(0).id);

        data.newObjects.forEach(mo => this._lightObjectsService.adjustLightPosition(mo));

        this._store.dispatch(
          viewFurniture({
            data: {
              article: {
                ...article?.Article,
                BasicArticleInformation: { ...article.Article.BasicArticleInformation },
              },
              from: LeftPanelState.SCENE_DETAILS,
              translateDetails: true,
            },
          })
        );

        this._store.dispatch(setOverlayLoadingSpinner({ status: false }));
        this._store.dispatch(setUnsavedChanges({ state: true }));
      }),
      map(() => replaceTypeSuccess())
    )
  );

  public replaceWithVariant$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SharedActions.replaceWithVariant),
      tap(() => this._store.dispatch(setOverlayLoadingSpinner({ status: true }))),
      switchMap(data => {
        const oldSku = data.movingObjectInfo.articleSkuToReplace;
        const oldModels = this._movingObjectsService.movingObjects.filter(obj => obj.SKU === oldSku);

        const objectsToReplaceInfo = oldModels.map(obj => ({
          id: obj.id,
          position: obj.object3D.position.clone(),
          rotation: new Vector3(obj.object3D.rotation.x, obj.object3D.rotation.y, obj.object3D.rotation.z),
        }));

        const request$: Observable<{
          movingObjects: MovingObject[];
          model: BasicArticleInformation;
          initialModel: BasicArticleInformation;
        }> = this._loadModelForObjects(data.article.BasicArticleInformation, false, undefined, objectsToReplaceInfo);

        return request$.pipe(
          map(results => ({
            replacingResult: results,
            article: data.article,
            newModel: results.model,
            oldMovingObject: data.movingObjectInfo,
            oldMovingObjectsBackup: oldModels,
          }))
        );
      }),
      switchMap(data =>
        this._store.select(proposal).pipe(
          filter(proposal => !!proposal),
          take(1),
          switchMap(proposal =>
            this._furnitureHttpService
              .proposalReplaceSKU({
                articleSkuToReplace: data.oldMovingObject.articleSkuToReplace,
                newArticleSku: data.article.BasicArticleInformation.SKU,
                proposalState: proposal,
                searchCriteria: this._prepareSearchCriteria(),
              })
              .pipe(
                map(newProposal => ({
                  proposal: newProposal,
                  object: data,
                  oldProposal: proposal,
                  oldMovingObjectsBackup: data.oldMovingObjectsBackup,
                }))
              )
          )
        )
      ),
      tap(data => {
        const articleFromResponse = data.proposal.UpdatedProposalTO.Articles.find(
          article => article.Article.BasicArticleInformation.SKU === data.proposal.NewArticleSku
        );

        const updatedProposal: UpdatedProposalTO = {
          ...data.proposal.UpdatedProposalTO,
          Articles: [
            ...data.proposal.UpdatedProposalTO.Articles.filter(
              article => article.Article.BasicArticleInformation.SKU != data.proposal.NewArticleSku
            ),
            {
              ...articleFromResponse,
              Article: {
                ...articleFromResponse.Article,
                BasicArticleInformation: {
                  ...articleFromResponse.Article.BasicArticleInformation,
                  NumberOfExemplars: data.object.replacingResult.movingObjects.length,
                  SKU: data.object.replacingResult.model.SKU,
                },
              },
            },
          ],
        };

        const exactReplacedObject = data.object.replacingResult.movingObjects.find(mo =>
          mo.object3D.position.equals(data.object.oldMovingObject.position)
        );

        const newProposal = this._prepareProposalStateData(updatedProposal, data.oldProposal);

        this._store.dispatch(
          setProposal({
            data: newProposal,
          })
        );

        const keyBefore = uuidv4();
        const keyAfter = uuidv4();

        this._dataStoreService.addProposalMovingObjects(keyBefore, [...data.oldMovingObjectsBackup]);
        this._dataStoreService.addProposalMovingObjects(keyAfter, [...data.object.replacingResult.movingObjects]);
        this._store.dispatch(
          refreshType({
            data: {
              proposalSnapshotAfter: newProposal,
              proposalSnapshotBefore: data.oldProposal,
              keyForFurnitureAfter: keyAfter,
              keyForFurnitureBefore: keyBefore,
            },
          })
        );

        this._roomService.changeSelectedMovingObject(exactReplacedObject.id);

        data.object.replacingResult.movingObjects.forEach(mo => this._lightObjectsService.adjustLightPosition(mo));

        this._store.dispatch(
          viewFurniture({
            data: {
              article: {
                ...articleFromResponse.Article,
                BasicArticleInformation: { ...articleFromResponse.Article.BasicArticleInformation },
              },
              from: LeftPanelState.SCENE_DETAILS,
              translateDetails: true,
            },
          })
        );
        this._store.dispatch(setOverlayLoadingSpinner({ status: false }));
        this._store.dispatch(setUnsavedChanges({ state: true }));
      }),
      map(() => replaceWithVariantSuccess())
    )
  );

  public replaceAccessoryType$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SharedActions.replaceAccessoryType),
      tap(() => this._store.dispatch(setOverlayLoadingSpinner({ status: true }))),
      switchMap(action =>
        this._store.select(proposal).pipe(
          take(1),
          map(proposal => ({ proposal, data: action.data }))
        )
      ),
      switchMap(data => {
        const searchCriteria = this._prepareSearchCriteria();
        return this._furnitureHttpService
          .proposalReplaceType({
            articleSkuToReplace: data.data.articleSkuToReplace,
            viewedArticles: data.data.viewedArticles,
            searchCriteria,
            proposalState: data.proposal,
          })
          .pipe(map(proposal => ({ proposal, oldProposal: data.proposal })));
      }),
      map(data => {
        const proposal = this._prepareProposalStateData(data.proposal.UpdatedProposalTO, data.oldProposal);
        this._store.dispatch(setProposal({ data: proposal }));
        this._store.dispatch(setOverlayLoadingSpinner({ status: false }));
        this._store.dispatch(setUnsavedChanges({ state: true }));
        const newArticle = data.proposal.UpdatedProposalTO.Articles[data.proposal.UpdatedProposalTO.Articles.length - 1].Article;
        return replaceAccessory({
          data: newArticle,
          replaceSKU: data.proposal.OldArticleSku,
        });
      })
    )
  );

  public includeSku$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SharedActions.includeSku),
      tap(() => this._store.dispatch(setOverlayLoadingSpinner({ status: true }))),
      switchMap(action =>
        this._store.select(proposal).pipe(
          take(1),
          map(proposal => ({ proposal, sku: action.sku, is3d: action.is3d }))
        )
      ),
      switchMap(data => {
        const searchCriteria = this._prepareSearchCriteria();
        return this._furnitureHttpService
          .proposalIncludeSKU({
            articleSkuToInclude: data.sku,
            searchCriteria,
            proposalState: data.proposal,
          })
          .pipe(
            map(proposal => ({
              proposal,
              is3d: data.is3d,
              oldProposal: data.proposal,
              sku: data.sku,
            }))
          );
      }),
      switchMap(result => {
        const newArticle = result.proposal.Articles[result.proposal.Articles.length - 1].Article;
        const newProposal = this._prepareProposalStateData(result.proposal, result.oldProposal, result.sku);
        if (result.is3d) {
          return this._importService
            .loadFromArticle(newArticle.BasicArticleInformation, true, null, null, newProposal)
            .pipe(catchError(() => of(null)))
            .pipe(map(data => ({ data, newProposal, sku: result.sku })));
        } else {
          this._store.dispatch(addAccessory({ data: newArticle }));
          return of({ newProposal, sku: result.sku });
        }
      }),
      map(data => {
        this._store.dispatch(setOverlayLoadingSpinner({ status: false }));
        this._store.dispatch(setUnsavedChanges({ state: true }));
        return setProposal({ data: data.newProposal });
      })
    )
  );

  public excludeSku$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SharedActions.excludeSku),
      tap(() => this._store.dispatch(setOverlayLoadingSpinner({ status: true }))),
      switchMap(action =>
        this._store.select(proposal).pipe(
          take(1),
          map(proposal => ({
            proposal,
            sku: action.sku,
            is3d: action.is3d,
            uuid: action.uuid,
            type: action.excludeType,
          }))
        )
      ),
      switchMap(data => {
        const searchCriteria = this._prepareSearchCriteria();
        return this._furnitureHttpService
          .proposalExclude({
            articleSkuToExclude: data.sku,
            searchCriteria,
            proposalState: data.proposal,
          })
          .pipe(
            map(proposal => ({
              proposal,
              excludeSku: data.sku,
              is3d: data.is3d,
              uuid: data.uuid,
              type: data.type,
              oldProposal: data.proposal,
            }))
          );
      }),
      switchMap(result => {
        const newProposal = this._prepareProposalStateData(result.proposal, result.oldProposal);
        if (result.is3d) {
          this._movingObjectsService.deleteFurnitureBySKU(result.excludeSku, true, newProposal);
        } else {
          this._store.dispatch(deleteAccessory({ data: result.excludeSku }));
        }
        return of({ newProposal });
      }),
      map(data => {
        this._store.dispatch(setOverlayLoadingSpinner({ status: false }));
        this._store.dispatch(setUnsavedChanges({ state: true }));
        return setProposal({ data: data.newProposal });
      })
    )
  );

  public clearProposal$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SharedActions.clearProposal),
      tap(() => this._store.dispatch(setOverlayLoadingSpinner({ status: true }))),
      switchMap(() => this._store.select(proposal).pipe(take(1))),
      switchMap(async data => {
        const searchCriteria = this._prepareSearchCriteria();
        let proposalStateData = data;
        for (const item of data.ProposedProposal.Articles) {
          const excludeResult = await lastValueFrom(
            this._furnitureHttpService.proposalExclude({
              articleSkuToExclude: item.Sku,
              proposalState: proposalStateData,
              searchCriteria,
            })
          );

          proposalStateData = this._prepareProposalStateData(excludeResult);
        }

        this._movingObjectsService.deleteAllFurniture(false, proposalStateData);
        this._planesService.clearAllWalls();

        return proposalStateData;
      }),
      map(data => {
        this._store.dispatch(setOverlayLoadingSpinner({ status: false }));
        this._store.dispatch(setUnsavedChanges({ state: true }));
        return setProposal({ data });
      })
    )
  );

  private _loadModel(
    articleInformation: BasicArticleInformation,
    touchStore: boolean,
    replaceByType?: ProposalArticleReplaceByType,
    movingObjectInfo?: MovingObjectInfo
  ): Observable<{
    movingObject: MovingObject;
    model: BasicArticleInformation;
    initialModel: BasicArticleInformation;
  }> {
    return this._importService
      .loadFromArticle(articleInformation, touchStore, movingObjectInfo, replaceByType)
      .pipe(map(result => ({ ...result, initialModel: articleInformation })));
  }

  private _loadModelForObjects(
    articleInformation: BasicArticleInformation,
    touchStore: boolean,
    replaceByType?: ProposalArticleReplaceByType,
    movingObjectsInfo?: MovingObjectInfo[]
  ): Observable<{
    movingObjects: MovingObject[];
    model: BasicArticleInformation;
    initialModel: BasicArticleInformation;
    repeatedResponse?: ProposalArticleReplaceResponse;
  }> {
    return this._importService
      .loadFromArticleMultiple(articleInformation, touchStore, movingObjectsInfo, replaceByType)
      .pipe(map(result => ({ ...result, initialModel: articleInformation })));
  }

  private _restoreProposal(key: string, proposal: ProposalState): void {
    this._store.dispatch(setRenderLoadingSpinner({ status: true, text: '' }));

    this._movingObjectsService.deleteAllFurniture(true);

    const elements = this._dataStoreService.getProposalMovingObjects(key);
    elements.forEach(furniture => this._movingObjectsService.addObject(furniture, false));

    this._store.dispatch(setProposal({ data: proposal }));

    this._store.dispatch(setLeftPanelState({ state: false }));
    this._highlightedObjectsService.clearHighlightedObjects();
    this._store.dispatch(setRenderLoadingSpinner({ status: false, text: '' }));
  }

  private _replaceFurniture(keyFurnitureRemove: string, keyFurnitureAdd: string, proposal: ProposalState): void {
    this._store.dispatch(setRenderLoadingSpinner({ status: true, text: '' }));

    const toRemove = this._dataStoreService.getProposalMovingObjects(keyFurnitureRemove);
    toRemove.forEach(mo => this._movingObjectsService.removeObject(mo, false));

    const toAdd = this._dataStoreService.getProposalMovingObjects(keyFurnitureAdd);
    toAdd.forEach(mo => this._movingObjectsService.addObject(mo, false));

    this._store.dispatch(setProposal({ data: proposal }));

    this._store.dispatch(setLeftPanelState({ state: false }));
    this._highlightedObjectsService.clearHighlightedObjects();
    this._store.dispatch(setRenderLoadingSpinner({ status: false, text: '' }));
  }

  public catalogData$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SharedActions.getCatalogData),
      switchMap(action =>
        this._furnitureHttpService
          .getCatalogData(action.furnitureType, action.limit, action.start)
          .pipe(map((data: CatalogData[]) => ({ data, mode: action.mode })))
      ),
      map(({ data, mode }) => {
        if (mode === 'new') {
          return setCatalogData({ data });
        } else {
          return updateCatalogData({ data });
        }
      })
    )
  );

  public getAlternatives$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SharedActions.getAlternatives),
      tap(() => this._store.dispatch(clearAlternatives())),
      tap(action => this._store.dispatch(setActiveSKU({ sku: action.sku }))),
      switchMap(action =>
        this._store.select(proposal).pipe(
          take(1),
          filter(proposal => !!proposal?.ProposedProposal?.Articles?.find(el => el.Sku === action.sku)),
          map(proposal => ({ proposal, sku: action.sku }))
        )
      ),
      switchMap(data => {
        const searchCriteria = this._prepareSearchCriteria();
        return this._furnitureHttpService
          .getAlternatives({
            articleSkuToReplace: data.sku,
            numberOfAlternatives: 4,
            searchCriteria,
            proposalState: data.proposal,
          })
          .pipe(map(alternatives => ({ alternatives, sku: data.sku })));
      }),
      map(data => {
        return getAlternativesSuccess({
          alternatives: data.alternatives,
          sku: data.sku,
        });
      })
    )
  );

  private _subscribeUndoRedo(): void {
    this._store
      .select(getCurrentAction)
      .pipe(
        filter(data => !!data && [SharedActions.REFRESH_PROPOSAL, SharedActions.REFRESH_TYPE].includes(data.action.name)),
        untilDestroyed(this)
      )
      .subscribe(action => {
        switch (action.action.name) {
          case SharedActions.REFRESH_PROPOSAL: {
            if (action.command === 'undo') {
              this._restoreProposal(action.action.data['keyForFurnitureBefore'], action.action.data['proposalSnapshotBefore']);
            } else {
              this._restoreProposal(action.action.data['keyForFurnitureAfter'], action.action.data['proposalSnapshotAfter']);
            }
            break;
          }
          case SharedActions.REFRESH_TYPE: {
            if (action.command === 'undo') {
              this._replaceFurniture(
                action.action.data['keyForFurnitureAfter'],
                action.action.data['keyForFurnitureBefore'],
                action.action.data['proposalSnapshotBefore']
              );
            } else {
              this._replaceFurniture(
                action.action.data['keyForFurnitureBefore'],
                action.action.data['keyForFurnitureAfter'],
                action.action.data['proposalSnapshotAfter']
              );
            }
            break;
          }
        }
      });
  }

  constructor(
    private _location: Location,
    private _actions$: Actions,
    private _detectObjHttpService: DetectObjHttpService,
    private _furnitureHttpService: FurnitureHttpService,
    private _importService: ImportService,
    private _roomService: RoomService,
    private _store: Store,
    private _movingObjectsService: MovingObjectsService,
    private _dataStoreService: DataStoreService,
    private _photorealisticRenderService: PhotorealisticRenderService,
    private _resourcesHttpService: ResourcesHttpService,
    private _reconstructionStateService: ReconstructionStateService,
    private _exportService: ExportService,
    private _projectsVersionsHttpService: ProjectsVersionsHttpService,
    private _planesService: PlanesService,
    private _highlightedObjectsService: HighlightedObjectsService,
    private _lightObjectsService: LightObjectsService
  ) {
    this._subscribeUndoRedo();
  }
}
