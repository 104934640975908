import { FurnitureType } from '../enum/furniture-types.enum';
import { CatalogOption } from '../interfaces/catalog-option';

export const CATALOG_OPTIONS: CatalogOption[] = [
  {
    value: FurnitureType.SOFA,
    viewValue: 'USER_FLOW.CATALOG.SOFA',
    image: './../../../assets/catalog-categories/SOFA.jpg',
    draggable: false,
  },
  {
    value: FurnitureType.BED,
    viewValue: 'USER_FLOW.CATALOG.BED',
    image: './../../../assets/catalog-categories/BED.jpg',
    draggable: false,
  },
  {
    value: FurnitureType.DINING_TABLE_CHAIR,
    viewValue: 'USER_FLOW.CATALOG.DINING_TABLE_CHAIR',
    image: './../../../assets/catalog-categories/DINING_TABLE_CHAIR.jpg',
    draggable: false,
  },
  {
    value: FurnitureType.EASY_CHAIR,
    viewValue: 'USER_FLOW.CATALOG.EASY_CHAIR',
    image: './../../../assets/catalog-categories/EASY_CHAIR.jpg',
    draggable: false,
  },
  {
    value: FurnitureType.BAR_STOOL,
    viewValue: 'USER_FLOW.CATALOG.BAR_STOOL',
    image: './../../../assets/catalog-categories/BAR_STOOL.jpg',
    draggable: false,
  },
  {
    value: FurnitureType.STOOL,
    viewValue: 'USER_FLOW.CATALOG.STOOL',
    image: './../../../assets/catalog-categories/STOOL.jpg',
    draggable: false,
  },
  {
    value: FurnitureType.OFFICE_CHAIR,
    viewValue: 'USER_FLOW.CATALOG.OFFICE_CHAIR',
    image: './../../../assets/catalog-categories/OFFICE_CHAIR.jpg',
    draggable: false,
  },
  {
    value: FurnitureType.OTTOMAN,
    viewValue: 'USER_FLOW.CATALOG.OTTOMAN',
    image: './../../../assets/catalog-categories/OTTOMAN.jpg',
    draggable: false,
  },
  {
    value: FurnitureType.POUF,
    viewValue: 'USER_FLOW.CATALOG.POUF',
    image: './../../../assets/catalog-categories/POUF.jpg',
    draggable: false,
  },
  {
    value: FurnitureType.BENCH,
    viewValue: 'USER_FLOW.CATALOG.BENCH',
    image: './../../../assets/catalog-categories/BENCH.jpg',
    draggable: false,
  },
  {
    value: FurnitureType.DINING_TABLE,
    viewValue: 'USER_FLOW.CATALOG.DINING_TABLE',
    image: './../../../assets/catalog-categories/DINING_TABLE.jpg',
    draggable: false,
  },
  {
    value: FurnitureType.HIGH_TABLE,
    viewValue: 'USER_FLOW.CATALOG.HIGH_TABLE',
    image: './../../../assets/catalog-categories/HIGH_TABLE.jpg',
    draggable: false,
  },
  {
    value: FurnitureType.SIDE_TABLE,
    viewValue: 'USER_FLOW.CATALOG.SIDE_TABLE',
    image: './../../../assets/catalog-categories/SIDE_TABLE.jpg',
    draggable: false,
  },
  {
    value: FurnitureType.OFFICE_TABLE,
    viewValue: 'USER_FLOW.CATALOG.OFFICE_TABLE',
    image: './../../../assets/catalog-categories/OFFICE_TABLE.jpg',
    draggable: false,
  },
  {
    value: FurnitureType.CLOSET,
    viewValue: 'USER_FLOW.CATALOG.CLOSET',
    image: './../../../assets/catalog-categories/CLOSET.jpg',
    draggable: false,
  },
  {
    value: FurnitureType.SHELF,
    viewValue: 'USER_FLOW.CATALOG.SHELF',
    image: './../../../assets/catalog-categories/SHELF.jpg',
    draggable: false,
  },
  {
    value: FurnitureType.WARDROBE,
    viewValue: 'USER_FLOW.CATALOG.WARDROBE',
    image: './../../../assets/catalog-categories/WARDROBE.jpg',
    draggable: false,
  },
  {
    value: FurnitureType.SHOE_RACK,
    viewValue: 'USER_FLOW.CATALOG.SHOE_RACK',
    image: './../../../assets/catalog-categories/SHOE_RACK.jpg',
    draggable: false,
  },
  {
    value: FurnitureType.SIDE_BOARD,
    viewValue: 'USER_FLOW.CATALOG.SIDE_BOARD',
    image: './../../../assets/catalog-categories/SIDE_BOARD.jpg',
    draggable: false,
  },
  {
    value: FurnitureType.KEY_STORAGE,
    viewValue: 'USER_FLOW.CATALOG.KEY_STORAGE',
    image: './../../../assets/catalog-categories/KEY_STORAGE.jpg',
    draggable: false,
  },
  {
    value: FurnitureType.HIGH_TABLE_OUTDOOR,
    viewValue: 'USER_FLOW.CATALOG.HIGH_TABLE_OUTDOOR',
    image: './../../../assets/catalog-categories/HIGH_TABLE_OUTDOOR.jpg',
    draggable: false,
  },
  {
    value: FurnitureType.SIDE_TABLE_OUTDOOR,
    viewValue: 'USER_FLOW.CATALOG.SIDE_TABLE_OUTDOOR',
    image: './../../../assets/catalog-categories/SIDE_TABLE_OUTDOOR.jpg',
    draggable: false,
  },
  {
    value: FurnitureType.GARDEN_TABLE,
    viewValue: 'USER_FLOW.CATALOG.GARDEN_TABLE',
    image: './../../../assets/catalog-categories/GARDEN_TABLE.jpg',
    draggable: false,
  },
  {
    value: FurnitureType.OUTDOOR_BAR_STOOL,
    viewValue: 'USER_FLOW.CATALOG.OUTDOOR_BAR_STOOL',
    image: './../../../assets/catalog-categories/OUTDOOR_BAR_STOOL.jpg',
    draggable: false,
  },
  {
    value: FurnitureType.SIDE_CHAIR_OUTDOOR,
    viewValue: 'USER_FLOW.CATALOG.SIDE_CHAIR_OUTDOOR',
    image: './../../../assets/catalog-categories/SIDE_CHAIR_OUTDOOR.jpg',
    draggable: false,
  },
  {
    value: FurnitureType.GARDEN_CHAIR,
    viewValue: 'USER_FLOW.CATALOG.GARDEN_CHAIR',
    image: './../../../assets/catalog-categories/GARDEN_CHAIR.jpg',
    draggable: false,
  },
  {
    value: FurnitureType.GARDEN_SOFA,
    viewValue: 'USER_FLOW.CATALOG.GARDEN_SOFA',
    image: './../../../assets/catalog-categories/GARDEN_SOFA.jpg',
    draggable: false,
  },
  {
    value: FurnitureType.GARDEN_BENCH,
    viewValue: 'USER_FLOW.CATALOG.GARDEN_BENCH',
    image: './../../../assets/catalog-categories/GARDEN_BENCH.jpg',
    draggable: false,
  },
  {
    value: FurnitureType.DECK_CHAIR,
    viewValue: 'USER_FLOW.CATALOG.DECK_CHAIR',
    image: './../../../assets/catalog-categories/DECK_CHAIR.jpg',
    draggable: false,
  },
  {
    value: FurnitureType.GRILL,
    viewValue: 'USER_FLOW.CATALOG.GRILL',
    image: './../../../assets/catalog-categories/GRILL.jpg',
    draggable: false,
  },
  {
    value: FurnitureType.HAMMOCK,
    viewValue: 'USER_FLOW.CATALOG.HAMMOCK',
    image: './../../../assets/catalog-categories/HAMMOCK.jpg',
    draggable: false,
  },
  {
    value: FurnitureType.CURTAIN,
    viewValue: 'USER_FLOW.CATALOG.CURTAIN',
    image: './../../../assets/catalog-categories/CURTAIN.jpg',
    draggable: false,
  },
  {
    value: FurnitureType.OUTDOOR_ACCESSOIRE,
    viewValue: 'USER_FLOW.CATALOG.OUTDOOR_ACCESSOIRE',
    image: './../../../assets/catalog-categories/OUTDOOR_ACCESSOIRE.jpg',
    draggable: false,
  },
  {
    value: FurnitureType.OUTDOOR_LIGHTING,
    viewValue: 'USER_FLOW.CATALOG.OUTDOOR_LIGHTING',
    image: './../../../assets/catalog-categories/OUTDOOR_LIGHTING.jpg',
    draggable: false,
  },
  {
    value: FurnitureType.LAMP,
    viewValue: 'USER_FLOW.CATALOG.LAMP',
    image: './../../../assets/catalog-categories/PendantLamp.jpg',
    draggable: false,
  },
  {
    value: FurnitureType.FLOOR_LAMP,
    viewValue: 'USER_FLOW.CATALOG.FLOOR_LAMP',
    image: './../../../assets/catalog-categories/FLOOR_LAMP.jpg',
    draggable: false,
  },
  {
    value: FurnitureType.WALL_LAMP,
    viewValue: 'USER_FLOW.CATALOG.WALL_LAMP',
    image: './../../../assets/catalog-categories/WALL_LAMP.jpg',
    draggable: false,
  },
  {
    value: FurnitureType.TABLE_LAMP,
    viewValue: 'USER_FLOW.CATALOG.TABLE_LAMP',
    image: './../../../assets/catalog-categories/TABLE_LAMP.jpg',
    draggable: false,
  },
  {
    value: FurnitureType.KITCHEN_UTENSILS,
    viewValue: 'USER_FLOW.CATALOG.KITCHEN_UTENSILS',
    image: './../../../assets/catalog-categories/KITCHEN_UTENSILS.jpg',
    draggable: false,
  },
  {
    value: FurnitureType.DINING_TABLE_UTENSILS,
    viewValue: 'USER_FLOW.CATALOG.DINING_TABLE_UTENSILS',
    image: './../../../assets/catalog-categories/DINING_TABLE_UTENSILS.jpg',
    draggable: false,
  },
  {
    value: FurnitureType.DISHES,
    viewValue: 'USER_FLOW.CATALOG.DISHES',
    image: './../../../assets/catalog-categories/DISHES.jpg',
    draggable: false,
  },
  {
    value: FurnitureType.BATHING_EQUIPMENT,
    viewValue: 'USER_FLOW.CATALOG.BATHING_EQUIPMENT',
    image: './../../../assets/catalog-categories/BATHING_EQUIPMENT.jpg',
    draggable: false,
  },
  {
    value: FurnitureType.OFFICE_AND_STORAGE,
    viewValue: 'USER_FLOW.CATALOG.OFFICE_AND_STORAGE',
    image: './../../../assets/catalog-categories/OFFICE_AND_STORAGE.jpg',
    draggable: false,
  },
  {
    value: FurnitureType.PAINTING,
    viewValue: 'USER_FLOW.CATALOG.PAINTING',
    image: './../../../assets/catalog-categories/PAINTING.jpg',
    draggable: false,
  },
  {
    value: FurnitureType.PILLOW,
    viewValue: 'USER_FLOW.CATALOG.PILLOW',
    image: './../../../assets/catalog-categories/PILLOW.jpg',
    draggable: false,
  },
  {
    value: FurnitureType.SEAT_CUSHION,
    viewValue: 'USER_FLOW.CATALOG.SEAT_CUSHION',
    image: './../../../assets/catalog-categories/SEAT_CUSHION.jpg',
    draggable: false,
  },
  {
    value: FurnitureType.SHOWER_CURTAIN,
    viewValue: 'USER_FLOW.CATALOG.SHOWER_CURTAIN',
    image: './../../../assets/catalog-categories/SHOWER_CURTAIN.jpg',
    draggable: false,
  },
  {
    value: FurnitureType.CARPET,
    viewValue: 'USER_FLOW.CATALOG.CARPET',
    image: './../../../assets/catalog-categories/CARPET.jpg',
    draggable: false,
  },
  {
    value: FurnitureType.CANDLE,
    viewValue: 'USER_FLOW.CATALOG.CANDLE',
    image: './../../../assets/catalog-categories/CANDLE.jpg',
    draggable: false,
  },
  {
    value: FurnitureType.BATH_TOWEL,
    viewValue: 'USER_FLOW.CATALOG.BATH_TOWEL',
    image: './../../../assets/catalog-categories/BATH_TOWEL.jpg',
    draggable: false,
  },
  {
    value: FurnitureType.HOUSEHOLD,
    viewValue: 'USER_FLOW.CATALOG.HOUSEHOLD',
    image: './../../../assets/catalog-categories/Household.jpg',
    draggable: false,
  },
  {
    value: FurnitureType.MIRROR,
    viewValue: 'USER_FLOW.CATALOG.MIRROR',
    image: './../../../assets/catalog-categories/MIRROR.jpg',
    draggable: false,
  },
  {
    value: FurnitureType.DECORATION,
    viewValue: 'USER_FLOW.CATALOG.DECORATION',
    image: './../../../assets/catalog-categories/DECORATION.jpg',
    draggable: false,
  },
  {
    value: FurnitureType.WALLPAPER,
    viewValue: 'USER_FLOW.CATALOG.WALLPAPER',
    image: './../../../assets/catalog-categories/WALLPAPER.jpg',
  },
  {
    value: FurnitureType.BLANKET_LIVING,
    viewValue: 'USER_FLOW.CATALOG.BLANKET_LIVING',
    image: './../../../assets/catalog-categories/BLANKET_LIVING.jpg',
    draggable: false,
  },
  {
    value: FurnitureType.PLANT,
    viewValue: 'USER_FLOW.CATALOG.PLANT',
    image: './../../../assets/catalog-categories/PLANT.jpg',
    draggable: false,
  },
  {
    value: null,
    viewValue: 'USER_FLOW.CATALOG.MANUAL_UPLOAD',
    image: './../../../../assets/icons/share-green.svg',
    draggable: true,
  },
];
