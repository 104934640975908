import { RoomType } from '../enum/room-type.enum';
import { SceneSaveData } from '../services/model/saveData/scene-save-data.model';

export interface RenderInputData {
  src: string;
  width: number;
  height: number;
  type: RoomType;
  sceneSaveData?: SceneSaveData;
  actions?: Action[];
}

export enum Action {
  MAKE_AUTO_RECONSTRUCT = 'MAKE_AUTO_RECONSTRUCT',
  LOAD_AUTO_RECONSTRUCT = 'LOAD_AUTO_RECONSTRUCT',
  CLEAR_SCENE = 'CLEAR_SCENE',
  CLEAR_MANUAL_RECONSTRUCTION = 'CLEAR_MANUAL_RECONSTRUCTION',
  REFRESH_SCENE = 'REFRESH_SCENE',
  CHANGE_ROOM_HEIGHT = 'CHANGE_ROOM_HEIGHT',
  BACKWARD = 'BACKWARD',
  FORWARD = 'FORWARD',
  SAVE_VERSION = 'SAVE_VERSION',
  UPDATE_VERSION = 'UPDATE_VERSION',
  MAKE_PROPOSAL = 'MAKE_PROPOSAL',
  CLEAR_FURNITURE = 'CLEAR_FURNITURE',
  START_RENDER = 'START_RENDER',
  MAKE_PROPOSAL_AND_RENDER = 'MAKE_PROPOSAL_AND_RENDER',
  SHOW_RENDER = 'SHOW_RENDER',
  FINISH_MANUAL_RECONSTRUCT = 'FINISH_MANUAL_RECONSTRUCT',
  HIDE_RECONSTRUCTION_COLOR = 'HIDE_RECONSTRUCTION_COLOR',
}
