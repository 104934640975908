import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ColorGroup } from '../color-group.enum';
import { NcsColor } from '../ncs-color.model';

@Component({
  selector: 'app-current-color',
  templateUrl: './current-color.component.html',
  styleUrls: ['./current-color.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrentColorComponent {
  public readonly colorGroups = ColorGroup;
  public readonly variants: NcsColor[] = [
    {
      Code: undefined,
      Hex: undefined,
      Group: ColorGroup.NO_COLOR,
    },
    {
      Code: '1002-G',
      Hex: '#e7f1eb',
      Group: ColorGroup.GRAY,
    },
    {
      Code: '2005-Y30R',
      Hex: '#d6cfc3',
      Group: ColorGroup.ORANGE,
    },
    {
      Code: '5040-b80G',
      Hex: '#3f8676',
      Group: ColorGroup.GREEN,
    },
  ];

  @Output()
  public nextButtonClicked = new EventEmitter<void>();

  @Output()
  public colorChanged = new EventEmitter<{ color: NcsColor; colorFixed: boolean }>();

  @Input()
  public colorData: { color: NcsColor; colorFixed: boolean } = { color: this.variants[0], colorFixed: false };

  public get hex(): string {
    return this.colorData?.color?.Hex ?? '';
  }

  public get name(): string {
    if (this.colorData) {
      const groupPart = this._translateService.instant('USER_FLOW.COLOR_PAGE.' + this.colorData?.color?.Group?.toUpperCase());
      const wallColorPart = this._translateService.instant('USER_FLOW.COLOR_PAGE.WALL_COLOR');
      return `${wallColorPart} ${groupPart}`;
    }

    return '';
  }

  public get code(): string {
    return this.colorData?.color?.Code;
  }

  @ViewChild('rectangle')
  private _colorRectangle: ElementRef;

  constructor(private _translateService: TranslateService) {}

  public clearSelectedColor(): void {
    this.colorData = {
      color: {
        Code: undefined,
        Hex: undefined,
        Group: ColorGroup.NO_COLOR,
      },
      colorFixed: false,
    };
    this.colorChanged.next(this.colorData);
  }

  public setColor(variant: NcsColor): void {
    this.colorData = { color: variant, colorFixed: false };
    this.colorChanged.next(this.colorData);
  }

  public navigateToAllColors(): void {
    this.nextButtonClicked.next();
  }

  public fixSelectedColor(): void {
    this.colorData = { ...this.colorData, colorFixed: !this.colorData.colorFixed };
    this.colorChanged.next(this.colorData);
  }
}
