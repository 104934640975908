export class Resolution {
  public name: string;
  public postfix: string;
  public width: number;
  public height: number;
  public area: number;

  constructor(width: number, height: number, postfix?: string) {
    this.width = width;
    this.height = height;
    this.name = `${width} x ${height}`;
    this.area = width * height;

    if (postfix) {
      this.postfix = postfix;
      this.name += ` (${postfix})`;
    }
  }

  public relateWith(resolutions: Resolution[]): Resolution[] {
    const sortedResolutions = resolutions.sort((first, second) => {
      const firstAspectRatio = this._getAspectRatio(first, this);
      const secondAspectRatio = this._getAspectRatio(second, this);

      return firstAspectRatio < secondAspectRatio ? 1 : -1;
    });

    let fittedIndex = -1;

    sortedResolutions.forEach((resolution, index) => {
      const aspectRatio = this._getAspectRatio(resolution, this);
      if (aspectRatio < 1) {
        fittedIndex = index;
        return;
      }
    });

    const result = [];

    if (fittedIndex > -1) {
      const fittedResolutions = sortedResolutions.slice(fittedIndex - 1);

      fittedResolutions.forEach(resolution => result.push(this._scaleTo(resolution, resolution.postfix)));
    }

    return result;
  }

  public scaleToWithFlip(targetResolution: Resolution): Resolution {
    const currentAspectRatio = this.width / this.height;
    const desiredAspectRatio = targetResolution.width / targetResolution.height;

    let scaleWidth: number, scaleHeight: number;

    if (currentAspectRatio < 1 && desiredAspectRatio > 1) {
      // original is vertical, desired is horizontal
      scaleWidth = targetResolution.height / this.width;
      scaleHeight = scaleWidth;
    } else if (currentAspectRatio > 1 && desiredAspectRatio < 1) {
      // original is horizontal, desired is vertical
      scaleHeight = targetResolution.width / this.height;
      scaleWidth = scaleHeight;
    } else if (currentAspectRatio > desiredAspectRatio) {
      // scale vertical
      scaleHeight = targetResolution.height / this.height;
      scaleWidth = scaleHeight;
    } else {
      // scale horizontal
      scaleWidth = targetResolution.width / this.width;
      scaleHeight = scaleWidth;
    }

    return new Resolution(Math.round(this.width * scaleWidth), Math.round(this.height * scaleHeight), targetResolution.postfix);
  }

  private _scaleTo(resolution: Resolution, postfix?: string): Resolution {
    const aspectRatio = this._getAspectRatio(resolution, this);
    return new Resolution(Math.round(this.width * aspectRatio), Math.round(this.height * aspectRatio), postfix);
  }

  private _getAspectRatio(first: Resolution, second: Resolution): number {
    const widthAspectRatio = first.width / second.width;
    const heightAspectRatio = first.height / second.height;
    return Math.min(widthAspectRatio, heightAspectRatio);
  }
}
