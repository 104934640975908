import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, filter, Subject, switchMap, tap } from 'rxjs';
import { lang } from 'src/app/store/selectors/shared.selector';
import { TranslateResponse } from 'src/app/user-flow/interfaces/translate';
import { ResolutionService } from 'src/app/user-flow/services/resolution.service';
import { BasicArticleInformation, CatalogData } from 'src/services/model/proposal';
import { GoogleTranslateService } from 'src/services/translate.service';

@UntilDestroy()
@Component({
  selector: 'app-idea-variant-details',
  templateUrl: './idea-variant-details.component.html',
  styleUrls: ['./idea-variant-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IdeaVariantDetailsComponent implements OnInit, OnChanges {
  @Input() public variant: CatalogData;

  @Output() public closeDialog: EventEmitter<void> = new EventEmitter<void>();
  @Output() public back: EventEmitter<CatalogData> = new EventEmitter<CatalogData>();

  public description$: Subject<string> = new Subject();

  public activeImage: string;
  public imageIndex = 0;

  constructor(
    public resolutionService: ResolutionService,
    @Inject(DOCUMENT) private _document: Document,
    private _store: Store,
    private _googleTranslateService: GoogleTranslateService
  ) {}

  public ngOnChanges(): void {
    this._store
      .select(lang)
      .pipe(
        distinctUntilChanged(),
        tap(lang => {
          if (lang.toLocaleLowerCase() === 'de') {
            this.description$.next(this.variant.BasicArticleInformation.Description);
          }
        }),
        filter(lang => lang && lang.toLocaleLowerCase() !== 'de'),
        switchMap(lang => this._googleTranslateService.translate([this.variant.BasicArticleInformation.Description], lang)),
        untilDestroyed(this)
      )
      .subscribe((res: TranslateResponse) => {
        this.description$.next(res.translatedContents);
      });
  }

  public ngOnInit(): void {
    this.activeImage = this.variant.BasicArticleInformation.PackshotImages[0];
  }

  public close(): void {
    this.back.emit(null);
  }

  public openCatalog(basicArticleInformation: BasicArticleInformation): void {
    this._document.defaultView.open(basicArticleInformation?.MetaData?.find(data => data.Key === 'Shopify_Product_Link').Value, '_blank');
  }

  public previous(images: string[]): void {
    if (this.imageIndex - 1 >= 0) {
      this.activeImage = images[--this.imageIndex];
    }
  }

  public next(images: string[]): void {
    if (this.imageIndex + 1 < images.length) {
      this.activeImage = images[++this.imageIndex];
    }
  }
}
