import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Group, SpotLight, SpotLightHelper, Vector3 } from 'three';

import { getObjectSize } from './autopositioning.function';
import { patchSpotLightTargetPosition } from './light.utils';
import { LightObjectsServiceConfig } from './model/config/light-objects-service-config.model';
import { MovingObject } from './model/dto/moving-object.model';
import { MovingObjectsService } from './moving-objects.service';
import { FurnitureType } from '../enum/furniture-types.enum';

export interface ISpotLightSettings {
  // in radians between 0 and PI/2
  angle: number;
  // between 1 and 2
  decay: number;
  // between 0 and 200+, 0 = infinity
  distance: number;
  // between 0 and 1
  penumbra: number;
  // measured in lumens
  // Watts = Lumens / 80
  power: number;
  // cast shadow
  castShadow: boolean;
}

export interface SpotLightSettingsForm {
  angle: FormControl<number>;
  decay: FormControl<number>;
  distance: FormControl<number>;
  penumbra: FormControl<number>;
  power: FormControl<number>;
  castShadow: FormControl<boolean>;
}

@UntilDestroy()
@Injectable()
export class LightObjectsService {
  private _config: LightObjectsServiceConfig;
  private _lightHelpersGroup: Group;

  private get movingObjectLight(): MovingObject[] {
    return this.moService.movingObjects.filter(mo => !!mo.light);
  }

  constructor(private readonly moService: MovingObjectsService) {}

  public configure(config: LightObjectsServiceConfig): void {
    this._config = config;
    this._lightHelpersGroup = this._config.scene.getObjectByName('lightHelpersGroup') as Group;
  }

  public getLightSettings(uuid: string): ISpotLightSettings | null {
    const light = this.movingObjectLight.find(mo => mo.id === uuid)?.light as SpotLight;

    return light
      ? {
          angle: light.angle,
          decay: light.decay,
          distance: light.distance,
          penumbra: light.penumbra,
          power: light.power,
          castShadow: light.castShadow,
        }
      : null;
  }

  public changeLightSettingsFor(uuid: string, newSettings: ISpotLightSettings): void {
    const light = this.movingObjectLight.find(mo => mo.id === uuid)?.light as SpotLight;
    const lightHelper = this._lightHelpersGroup?.children.find(helper => helper.userData['movingObjectId'] === uuid) as SpotLightHelper;
    light.angle = newSettings.angle;
    light.decay = newSettings.decay;
    light.distance = newSettings.distance;
    light.penumbra = newSettings.penumbra;
    light.power = newSettings.power;
    light.castShadow = newSettings.castShadow;

    if (lightHelper) {
      lightHelper.update();
      light.updateMatrix();
      light.updateMatrixWorld();
    }
  }

  public isHelperExistForObject(uuid: string): boolean {
    const helper = this._lightHelpersGroup?.children.find(helper => helper.userData['movingObjectId'] === uuid) as SpotLightHelper;
    return !!helper;
  }

  public addLightHelper(uuid: string): void {
    const light = this.movingObjectLight.find(mo => mo.id === uuid).light as SpotLight;
    const lightHelper = new SpotLightHelper(light);
    lightHelper.userData['movingObjectId'] = uuid;

    if (!this._lightHelpersGroup) {
      const lightHelpersGroup = new Group();
      lightHelpersGroup.name = 'lightHelpersGroup';
      this._config.scene.add(lightHelpersGroup);
      this._lightHelpersGroup = lightHelpersGroup;
    }

    this._lightHelpersGroup?.add(lightHelper);
  }

  public removeLightHelper(uuid: string): void {
    const helper = this._lightHelpersGroup?.children.find(helper => helper.userData['movingObjectId'] === uuid) as SpotLightHelper;
    if (helper) {
      this._lightHelpersGroup.remove(helper);
      helper.clear();
      helper.dispose();
    }
  }

  public removeAllHelpers(): void {
    if (!this._lightHelpersGroup) return;

    this._lightHelpersGroup.children.forEach(child => {
      const helper = child as SpotLightHelper;
      helper.clear();
      helper.dispose();
    });

    this._lightHelpersGroup.clear();
  }

  public moveLightWithinObject(object: MovingObject, delta: Vector3, initialPosition: Vector3): void {
    if (!object.light) return;

    const moSizes = getObjectSize(object.object3D);

    if (object.type === FurnitureType.FLOOR_LAMP || object.type === FurnitureType.TABLE_LAMP) {
      const heightOfObject = moSizes.y;

      // move light
      object.light.position.add(delta.clone());
      // adjust light height
      object.light.position.setY(initialPosition.y);
      // move to the top of the lamp
      object.light.position.add(new Vector3(0, heightOfObject * 0.9, 0));

      // if light is SPOT_LIGHT
    } else if (object.type === FurnitureType.LAMP || object.type === FurnitureType.WALL_LAMP) {
      object.light.position.add(delta.clone());
    }

    patchSpotLightTargetPosition(object.light);
  }

  public synchronizeLightsWithObjects(): void {
    const moLights = this.moService.movingObjects.filter(mo => !!mo.light);
    moLights.forEach(mo => this.adjustLightPosition(mo));
  }

  public adjustLightPosition(movingObject: MovingObject): void {
    if (!movingObject.light) return;

    const moPosition = movingObject.object3D.position.clone();
    const objectSize = getObjectSize(movingObject.object3D);

    // position of object as reference point
    movingObject.light.position.copy(moPosition);

    if (movingObject.type === FurnitureType.FLOOR_LAMP || movingObject.type === FurnitureType.TABLE_LAMP) {
      const heightPixels = objectSize.y;
      movingObject.light.position.add(new Vector3(0, heightPixels * 0.9, 0));
    } else if (movingObject.type === FurnitureType.LAMP) {
      movingObject.light.position.copy(moPosition.clone());
    } else if (movingObject.type === FurnitureType.WALL_LAMP) {
      const objectQuaternion = movingObject.object3D.quaternion;
      const endDirection = new Vector3(0, 0, 1).applyQuaternion(objectQuaternion);
      const adjustedPosition = movingObject.object3D.position.clone().addScaledVector(endDirection, objectSize.x / 2);
      movingObject.light.position.copy(adjustedPosition);
    }

    patchSpotLightTargetPosition(movingObject.light);
  }
}
