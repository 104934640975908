import { ViewedArticle } from 'src/models/proposal';
import { Object3D, Vector3 } from 'three';

import { Dimensions, Links } from '../../../../../services/model/proposal';
import { FurnitureType } from '../../../enum/furniture-types.enum';
import { SticknessType } from '../../enum/stickness-type.enum';

export interface MovingObject extends ViewFurnitureDetails {
  id: string;
  name: string;
  valueMeters: number;
  pixelsSize: {
    x: number;
    y: number;
    z: number;
  };
  valueType: ValueType;
  sticknessType: SticknessType;
  object3D: Object3D;
  lock: boolean;
  light?: Object3D;
  scale?: Vector3;
}

export enum ValueType {
  HEIGHT = 'Height',
  WIDTH = 'Width',
  DEPTH = 'Depth',
}

export interface MovingObjectInfo {
  id: string;
  position: Vector3;
  rotation: Vector3;
  articleSkuToReplace?: string;
  viewedArticles?: ViewedArticle[];
}

export interface ReplaceTypeInfo {
  articleSkuToReplace: string;
  viewedArticles: ViewedArticle[];
}

export interface ViewFurnitureDetails {
  price: number;
  preview: string;
  format: string;
  dimensions: Dimensions;
  SKU: string;
  description: string;
  size: Size;
  title: string;
  type: FurnitureType;
  color: string;
  sizes: string;
  links: Links;
  manuallyLoaded: boolean;
  manuallyLoadedFile: string;
  underCategory: string;
}

export interface Size {
  width: number;
  height: number;
  length: number;
}

export interface MovingObjectSave extends ViewFurnitureDetails {
  id: string;
  name: string;
  valueMeters: number;
  pixelsSize: {
    x: number;
    y: number;
    z: number;
  };
  position: {
    x: number;
    y: number;
    z: number;
  };
  rotation: {
    x: number;
    y: number;
    z: number;
  };
  valueType: ValueType;
  type: FurnitureType;
  sticknessType: SticknessType;
  lock: boolean;
  objectUUID: string;
  manuallyLoaded: boolean;
  manuallyLoadedFile: string;
  lightUUID?: string;
  lightPosition?: {
    x: number;
    y: number;
    z: number;
  };
  lightTargetPosition?: {
    x: number;
    y: number;
    z: number;
  };
  scale?: { x: number; y: number; z: number };
}
