import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import heic2any from 'heic2any';
import { catchError, from, map, Observable, of } from 'rxjs';
import { setOverlayLoadingSpinner } from 'src/app/store/actions/shared.actions';

@Injectable()
export class IdeasService {
  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private _store: Store
  ) {}

  public resize(file: File, base64name: string, fileName: string): Observable<{ blob: Blob; filename: string }> {
    return new Observable<{ blob: Blob; filename: string }>(subscriber => {
      const image = new Image();
      image.src = URL.createObjectURL(file);
      image.onload = (): void => {
        const canvas = this._document.createElement('canvas');
        const smallSide = 512;
        let height, width;
        if (image.height > image.width) {
          height = Math.round((image.height / image.width) * smallSide);
          width = smallSide;
        } else {
          width = Math.round((image.width / image.height) * smallSide);
          height = smallSide;
        }

        canvas.width = width;
        canvas.height = height;
        const context = canvas.getContext('2d');

        context.drawImage(image, 0, 0, width, height);

        const base64 = canvas.toDataURL('image/jpeg').split(';base64,')[1];

        localStorage.setItem(`${base64name}`, base64);
        localStorage.setItem(`${fileName}`, file.name);

        canvas.toBlob(result => {
          subscriber.next({ blob: result, filename: file.name });
          subscriber.complete();
        });
      };
    });
  }

  public convertFromHeicOrHeif(file: File): Observable<File> {
    if (file.name.toLocaleLowerCase().includes('.heif') || file.name.toLocaleLowerCase().includes('.heic')) {
      this._store.dispatch(setOverlayLoadingSpinner({ status: true }));
      return from(heic2any({ blob: new Blob([file]) })).pipe(
        map((result: Blob) => {
          this._store.dispatch(setOverlayLoadingSpinner({ status: false }));
          const newFileName = file.name.split('.').at(0) + '.png';
          return new File([result], newFileName, { type: result.type });
        }),
        catchError(() => {
          return of(file);
        })
      );
    } else {
      return of(file);
    }
  }

  public _acceptableFormatCheck(file: File): boolean {
    if (
      file.name.toLowerCase().includes('.heic') ||
      file.name.toLowerCase().includes('.heif') ||
      file.name.toLowerCase().includes('.jpeg') ||
      file.name.toLowerCase().includes('.png') ||
      file.name.toLowerCase().includes('.jpg')
    ) {
      return true;
    }
    return false;
  }
}
