import { Object3D, Vector3 } from 'three';

import { curtainsAlgorithm } from './common';
import { PositioningElement } from './positioning-element.model';
import { roomCenter, RoomSchema } from './schema.model';
import { FurnitureType } from '../../enum/furniture-types.enum';
import { getObjectSize, getPointBetween, getRotation, moveModel } from '../autopositioning.function';
import { MovingObject } from '../model/dto/moving-object.model';

// constants
export const SLEEPING_ROOM_DOUBLE_BED_WIDTH = 1.5;
const LAMP_ABOVE_FLOOR = 1.9;

const bed: PositioningElement = {
  type: FurnitureType.BED,
  rules: [
    {
      action: 'SET_CUSTOM',
      callback: (objects, { wallCorners, roomCenter }, meterPerPixel): void => {
        if (objects?.length > 0) {
          const wallLength = wallCorners[1].distanceTo(wallCorners[0]) * meterPerPixel;

          const bed = objects[0].object3D;
          const bedSize = getObjectSize(bed);
          const bedMetersSize = {
            width: bedSize.x * meterPerPixel,
            height: bedSize.y * meterPerPixel,
            depth: bedSize.z * meterPerPixel,
          };

          const position =
            bedMetersSize.width > SLEEPING_ROOM_DOUBLE_BED_WIDTH
              ? getPointBetween(wallCorners[0], wallCorners[1], wallLength / 2)
              : getPointBetween(wallCorners[0], wallCorners[1], bedMetersSize.width / 2 + 0.05);

          bed.position.set(position.x, position.y, position.z);

          moveModel(bed, bedMetersSize, { forward: () => bedMetersSize.depth / 2 + 0.05 }, wallCorners, roomCenter, 1 / meterPerPixel);

          const rotation = getRotation(bed.position, wallCorners).forward;
          bed.lookAt(rotation);
        }
      },
    },
  ],
};

const image: PositioningElement = {
  type: FurnitureType.PAINTING,
  rules: [
    {
      action: 'MOVE',
      index: 0,
      relatedObject: { inAction: bed },
      right: (size, relatedSize) => relatedSize.width / 8,
      backward: (size, relatedSize) => relatedSize.depth / 2 + 0.05,
    },
    {
      action: 'MOVE',
      index: 1,
      relatedObject: { inAction: 0 },
      right: (size, relatedSize) => relatedSize.width / 2 + size.width / 2 + 0.1,
      up: () => 0.1,
    },
    {
      action: 'SET_CUSTOM',
      callback: (images, { heightMeters }): void => {
        if (images && heightMeters) {
          images.forEach(image => image.object3D.position.add(new Vector3(0, heightMeters / 2, 0)));
        }
      },
    },
    {
      action: 'LOOK_AT',
      value: 'FORWARD',
    },
  ],
};

const pedantLamp: PositioningElement = {
  type: FurnitureType.LAMP,
  rules: [
    {
      action: 'MOVE',
      relatedObject: { inAction: roomCenter },
      up: size => size.height / 2,
    },
    {
      action: 'SET_CUSTOM',
      callback: (pedantLamps, { floorY, heightMeters }, meterPerPixel): void => {
        if (pedantLamps?.length > 0 && floorY && heightMeters) {
          const newLampY = LAMP_ABOVE_FLOOR / meterPerPixel + floorY;
          pedantLamps[0].object3D.position.setY(newLampY);
        }
      },
    },
  ],
};

const bedroomSideTable: PositioningElement = {
  type: FurnitureType.SIDE_TABLE,
  rules: [
    {
      action: 'SET_CUSTOM',
      callback: (
        objects,
        userData: {
          beds: MovingObject[];
          wallCorners: [Vector3, Vector3];
          roomCenter: Vector3;
        },
        meterPerPixel
      ): void => {
        if (objects?.length > 0 && userData?.beds?.length > 0) {
          const tableSize = getObjectSize(objects[0].object3D);
          const tableMetersSize = {
            width: tableSize.x * meterPerPixel,
            height: tableSize.y * meterPerPixel,
            depth: tableSize.z * meterPerPixel,
          };
          const bed: Object3D = userData.beds[0].object3D;
          const bedSize = getObjectSize(bed);
          const bedMetersSize = {
            width: bedSize.x * meterPerPixel,
            height: bedSize.y * meterPerPixel,
            depth: bedSize.z * meterPerPixel,
          };

          if (bedMetersSize.width > SLEEPING_ROOM_DOUBLE_BED_WIDTH) {
            const firstTable = objects[0].object3D;
            const secondTable = objects[1].object3D;

            firstTable.position.set(bed.position.x, bed.position.y, bed.position.z);
            secondTable.position.set(bed.position.x, bed.position.y, bed.position.z);

            moveModel(
              firstTable,
              tableMetersSize,
              {
                right: () => tableMetersSize.width / 2 + bedMetersSize.width / 2 + 0.02,
                backward: () => bedMetersSize.depth / 2 - tableMetersSize.depth / 2 - 0.05,
              },
              userData.wallCorners,
              userData.roomCenter,
              1 / meterPerPixel
            );
            moveModel(
              secondTable,
              tableMetersSize,
              {
                left: () => tableMetersSize.width / 2 + bedMetersSize.width / 2 + 0.02,
                backward: () => bedMetersSize.depth / 2 - tableMetersSize.depth / 2 - 0.05,
              },
              userData.wallCorners,
              userData.roomCenter,
              1 / meterPerPixel
            );

            const firstRotation = getRotation(firstTable.position, userData.wallCorners);
            const secondRotation = getRotation(secondTable.position, userData.wallCorners);

            firstTable.lookAt(firstRotation.forward);
            secondTable.lookAt(secondRotation.forward);
          } else {
            const firstTable = objects[0].object3D;
            firstTable.position.set(bed.position.x, bed.position.y, bed.position.z);

            moveModel(
              firstTable,
              tableMetersSize,
              {
                right: () => tableMetersSize.width / 2 + bedMetersSize.width / 2,
                backward: () => bedMetersSize.depth / 2 - tableMetersSize.depth / 2,
              },
              userData.wallCorners,
              userData.roomCenter,
              1 / meterPerPixel
            );

            const firstRotation = getRotation(firstTable.position, userData.wallCorners);
            firstTable.lookAt(firstRotation.forward);
          }
        }
      },
    },
  ],
};

const tableLamp: PositioningElement = {
  type: FurnitureType.TABLE_LAMP,
  rules: [
    {
      action: 'SET_CUSTOM',
      callback: (
        objects,
        userData: {
          tables: MovingObject[];
          wallCorners: [Vector3, Vector3];
          roomCenter: Vector3;
        },
        meterPerPixel
      ): void => {
        if (objects?.length > 0 && userData?.tables?.length > 0) {
          userData.tables.forEach((table, index) => {
            const tableSize = getObjectSize(table.object3D);
            const tableMetersSize = {
              width: tableSize.x * meterPerPixel,
              height: tableSize.y * meterPerPixel,
              depth: tableSize.z * meterPerPixel,
            };
            const tablePosition = table.object3D.position;

            const lamp = objects[index].object3D;
            const lampSize = getObjectSize(lamp);
            const lampMetersSize = {
              width: lampSize.x * meterPerPixel,
              height: lampSize.y * meterPerPixel,
              depth: lampSize.z * meterPerPixel,
            };

            lamp.position.set(tablePosition.x, tablePosition.y, tablePosition.z);

            moveModel(
              lamp,
              lampMetersSize,
              {
                up: () => tableMetersSize.height / 2 + lampMetersSize.height / 2,
              },
              userData.wallCorners,
              userData.roomCenter,
              1 / meterPerPixel
            );

            const rotation = getRotation(lamp.position, userData.wallCorners);
            lamp.lookAt(rotation.forward);
          });
        }
      },
    },
  ],
};

const curtain: PositioningElement = {
  type: FurnitureType.CURTAIN,
  rules: [
    {
      action: 'SET_CUSTOM',
      callback: curtainsAlgorithm,
    },
  ],
};

export const SLEEPING_ROOM_SCHEMA: RoomSchema = {
  elements: [bed, image, pedantLamp, bedroomSideTable, tableLamp, curtain],
};
