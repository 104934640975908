import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { catchError, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ERROR_SKIP_INTERCEPT } from 'src/services/context-tokens/error.context-token';
import { AUTH_CONTEXT, AUTH_INTERCEPT } from 'src/services/context-tokens/intercept.context-token';

@Injectable()
export class DeleteObjHttpService {
  constructor(
    private _http: HttpClient,
    private _store: Store
  ) {}

  public deleteObj(image: Blob, mask: Blob): Observable<any> {
    const formData = new FormData();
    formData.append('image', image);
    formData.append('mask', mask);
    return this._http.post(environment.API_URL + '3rdparty/picwish/tasks/visual/inpaint', formData, { context: AUTH_CONTEXT });
  }

  public deleteObjCloudify(image: Blob, queryParams: string, mask: Blob): Observable<any> {
    const context = new HttpContext().set(ERROR_SKIP_INTERCEPT, true).set(AUTH_INTERCEPT, true);
    const formData = new FormData();
    formData.append('file', image);
    const params = new HttpParams().append('params', queryParams);
    return this._http.post(environment.API_URL + '3rdparty/cloudinary', formData, { params, context, responseType: 'blob' }).pipe(
      catchError(() => {
        return this.deleteObj(image, mask);
      })
    );
  }

  public deleteObjClipdrop(image: Blob, mask: Blob): Observable<any> {
    const context = new HttpContext().set(ERROR_SKIP_INTERCEPT, true).set(AUTH_INTERCEPT, true);
    const formData = new FormData();
    formData.append('image', image);
    formData.append('mask', mask);
    return this._http
      .post(environment.API_URL + '3rdparty/clipdrop-api/cleanup/v1', formData, { context, responseType: 'arraybuffer' })
      .pipe(catchError(() => this.deleteObj(image, mask)));
  }

  public deleteObjDeeplab(image: Blob, categories: string): Observable<Blob> {
    const params = new HttpParams().append('categoryIds', categories);
    const formData = new FormData();
    formData.append('file', image);
    return this._http.post(environment.API_URL + 'segmentation/deeplab', formData, { context: AUTH_CONTEXT, params, responseType: 'blob' });
  }
}
