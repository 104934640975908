import { Box3, Object3D, Vector3 } from 'three';

import { MetersSize, MoveAction } from './positioning-rules/positioning-element.model';

export function getObjectSize(object: Object3D): Vector3 {
  const clone = object.clone();
  clone.rotation.set(0, 0, 0);
  return new Box3().setFromObject(clone).getSize(new Vector3());
}

export function getRotation(position: Vector3, wallPoints: [Vector3, Vector3]): { forward: Vector3; backward: Vector3 } {
  const cameraPos = new Vector3();
  const lineDirection = wallPoints[1].clone().sub(wallPoints[0]);
  const rotateDir = lineDirection
    .clone()
    .cross(new Vector3(0, 1, 0))
    .normalize();
  const firstVariant = position.clone().add(rotateDir);
  const secondVariant = position.clone().sub(rotateDir);
  return firstVariant.distanceTo(cameraPos) < secondVariant.distanceTo(cameraPos)
    ? { forward: firstVariant, backward: secondVariant }
    : { forward: secondVariant, backward: firstVariant };
}

export function getRightDirection(wallCorners: [Vector3, Vector3]): Vector3 {
  return wallCorners[1].clone().sub(wallCorners[0]).normalize();
}

export function getPointBetween(startPoint: Vector3, endPoint: Vector3, distance: number): Vector3 {
  return startPoint.clone().add(endPoint.clone().sub(startPoint).normalize().multiplyScalar(distance));
}

export function getForwardDirection(
  position: Vector3,
  wallPoints: [Vector3, Vector3],
  roomCenter: Vector3
): { vector: Vector3; orientation: 'left' | 'right' } {
  const lineDirection = wallPoints[1].clone().sub(wallPoints[0]);
  const rotateDir = lineDirection
    .clone()
    .cross(new Vector3(0, 1, 0))
    .normalize();
  const firstVariant = position.clone().add(rotateDir);
  const secondVariant = position.clone().sub(rotateDir);
  return firstVariant.distanceTo(roomCenter) < secondVariant.distanceTo(roomCenter)
    ? { vector: rotateDir, orientation: 'left' }
    : { vector: rotateDir.negate(), orientation: 'right' };
}

export function moveModel(
  model: Object3D,
  modelSize: MetersSize,
  movements: {
    forward?: MoveAction;
    backward?: MoveAction;
    left?: MoveAction;
    right?: MoveAction;
    up?: MoveAction;
    down?: MoveAction;
  },
  wallCorners: [Vector3, Vector3],
  roomCenter: Vector3,
  pixelPerMeter: number,
  relatedModel?: Object3D,
  relatedModelSize?: MetersSize
): void {
  const sceneMovements = {
    forward: get3dFromMeter(movements.forward ? movements.forward(modelSize, relatedModelSize) : null, pixelPerMeter),
    backward: get3dFromMeter(movements.backward ? movements.backward(modelSize, relatedModelSize) : null, pixelPerMeter),
    left: get3dFromMeter(movements.left ? movements.left(modelSize, relatedModelSize) : null, pixelPerMeter),
    right: get3dFromMeter(movements.right ? movements.right(modelSize, relatedModelSize) : null, pixelPerMeter),
    up: get3dFromMeter(movements.up ? movements.up(modelSize, relatedModelSize) : null, pixelPerMeter),
    down: get3dFromMeter(movements.down ? movements.down(modelSize, relatedModelSize) : null, pixelPerMeter),
  };

  if (relatedModel) {
    model.position.set(relatedModel.position.x, relatedModel.position.y, relatedModel.position.z);
  }

  if (sceneMovements.right) {
    model.position.add(getRightDirection(wallCorners).multiplyScalar(sceneMovements.right));
  }

  if (sceneMovements.left) {
    model.position.add(getRightDirection(wallCorners).negate().multiplyScalar(sceneMovements.left));
  }

  if (sceneMovements.forward) {
    model.position.add(getForwardDirection(model.position, wallCorners, roomCenter).vector.multiplyScalar(sceneMovements.forward));
  }

  if (sceneMovements.backward) {
    model.position.add(
      getForwardDirection(model.position, wallCorners, roomCenter).vector.negate().multiplyScalar(sceneMovements.backward)
    );
  }

  if (sceneMovements.up) {
    model.position.add(new Vector3(0, 1, 0).multiplyScalar(sceneMovements.up));
  }

  if (sceneMovements.down) {
    model.position.add(new Vector3(0, -1, 0).multiplyScalar(sceneMovements.down));
  }
}

export function get3dFromMeter(meters: number, pixelPerMeter: number): number {
  return meters ? meters * pixelPerMeter : null;
}

export function rotateModel(model: Object3D, lookAt?: Vector3, wallCorners?: [Vector3, Vector3]): void {
  if (lookAt) {
    model.lookAt(lookAt);
  } else {
    model.lookAt(getRotation(model.position, wallCorners).forward);
  }
}
