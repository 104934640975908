import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { filter, map, Observable, tap } from 'rxjs';
import { ideaFurnitureDetails, ideaFurnitureDetailsDescription } from 'src/app/store/selectors/ideas.selectors';
import { ResolutionService } from 'src/app/user-flow/services/resolution.service';
import { BasicArticleInformation, CatalogData } from 'src/services/model/proposal';

@UntilDestroy()
@Component({
  selector: 'app-shared-catalog-details',
  templateUrl: './details-panel.component.html',
  styleUrls: ['./details-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailsPanelComponent implements OnInit {
  @Output() public openFurnitureList: EventEmitter<void> = new EventEmitter<void>();
  @Output() public closeDialog: EventEmitter<void> = new EventEmitter<void>();
  public description$: Observable<string> = this._store.select(ideaFurnitureDetailsDescription);
  public furniture$: Observable<CatalogData> = this._store.select(ideaFurnitureDetails).pipe(
    tap(data => (this.activeImage = data.article.BasicArticleInformation.PackshotImages[0])),
    map(data => data.article)
  );
  public selectedVariant: CatalogData = null;
  public currentData: CatalogData = null;
  public activeImage: string;
  public imageIndex = 0;

  constructor(
    private _store: Store,
    @Inject(DOCUMENT) private _document: Document,
    public resolutionService: ResolutionService,
    private _cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.furniture$
      .pipe(
        filter(data => !!data),
        untilDestroyed(this)
      )
      .subscribe((catalogData: CatalogData) => {
        this.currentData = catalogData;
      });
  }

  public setActiveVariant(data: CatalogData): void {
    this.selectedVariant = data;
  }

  public back(): void {
    this.openFurnitureList.emit();
  }

  public openCatalog(basicArticleInformation: BasicArticleInformation): void {
    this._document.defaultView.open(basicArticleInformation?.MetaData?.find(data => data.Key === 'Shopify_Product_Link').Value, '_blank');
  }

  public backFromVariant(variant: CatalogData): void {
    if (variant) {
      this.currentData = variant;

      if (this.resolutionService.isMobileResolution) {
        this.closeDialog.emit();
      }
    }

    this.selectedVariant = null;
    this._cdr.markForCheck();
  }

  public previous(images: string[]): void {
    if (this.imageIndex - 1 >= 0) {
      this.activeImage = images[--this.imageIndex];
    }
  }

  public next(images: string[]): void {
    if (this.imageIndex + 1 < images.length) {
      this.activeImage = images[++this.imageIndex];
    }
  }
}
